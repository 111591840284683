import React, { useState, useEffect } from "react";
import PopupHeader from "./popupHeader/PopupHeader";
import SearchPost from "./searchPost/SearchPost";
import SelectPostTable from "./selectPostTable/SelectPostTable";
import ShimmerTableRows from "./shimmerTableRows/ShimmerTableRows";
import "./SelectPostPopup.css";
import UploadFile from "./uploadfile/uploadfile";

const SelectPostPopup = ({
  setIsPopupVisible,
  getPosts,
  postsToShow,
  cardsToShow,
  setCardsToShow,
  setSearchPostQuery,
  searchPostQuery,
  editCardPosition,
  setEditCardPosition,
  setDataToSubmit,
  removeCardAtEdit,
  showCreateCardSteps,
  setCardsStepPost,
  dataToSubmit,
  showImageCard,
  showVideoCard,
  showCarouselCard,
  isCarouselPopUp,
  carouselPopVisible,
  carouselMediaId,
  carouselCards,
  updateCarouselInternalData,
  campaignObjective,
  campaignPlatform,
}) => {
  const [postsType, setPostsType] = useState(
    !isCarouselPopUp &&
      campaignObjective !== "LEAD" &&
      !(campaignObjective === "TRAFFIC" && campaignPlatform === "MESSENGER")
      ? "facebook"
      : "uploadImage"
  );

  const [addClass, handleAddClass] = useState(0);

  // Disable background scroll when popup opens and set to auto when closes
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  useEffect(
    () => () => {
      document.body.style.overflow = "auto";
    },
    []
  );

  // Code till here to disable background scroll

  useEffect(() => {
    const postType = decidePostType();
    if (postType === 1) {
      setSearchPostQuery("");
      getPosts(postType);
    }
  }, [postsType]);

  const decidePostType = () => {
    switch (postsType) {
      case "facebook":
        return 1;
      case "instagram":
        return 2;
      case "branded":
        return 3;
      case "uploadImage":
        return 4;
      default:
        return 1;
    }
  };

  const onSearchSubmit = () => {
    const postType = decidePostType();
    getPosts(postType);
  };
  return (
    <React.Fragment>
      <div className={`selectpostpopup__container`}>
        <PopupHeader
          postsType={postsType}
          setPostsType={setPostsType}
          isCarouselPopUp={isCarouselPopUp}
          campaignObjective={campaignObjective}
          campaignPlatform={campaignPlatform}
        />
        {postsType !== "uploadImage" && (
          <SearchPost
            setSearchPostQuery={setSearchPostQuery}
            onSearchSubmit={onSearchSubmit}
            searchPostQuery={searchPostQuery}
          />
        )}
        {postsType === "facebook" && !postsToShow && <ShimmerTableRows />}
        {postsType === "uploadImage" && (
          <UploadFile
            showImageCard={showImageCard}
            addClass={addClass}
            setIsPopupVisible={setIsPopupVisible}
            showVideoCard={showVideoCard}
            showCarouselCard={showCarouselCard}
            handleAddClass={handleAddClass}
            isCarouselPopUp={isCarouselPopUp}
            carouselPopVisible={carouselPopVisible}
            carouselMediaId={carouselMediaId}
            carouselCards={carouselCards}
            setCardsStepPost={setCardsStepPost}
            updateCarouselInternalData={updateCarouselInternalData}
            campaignObjective={campaignObjective}
          />
        )}
        {postsType != "uploadImage" &&
          (postsToShow && postsToShow.length) === 0 && (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "300px",
              }}
            >
              There are no posts for this page !
            </div>
          )}
        {postsType === "facebook" && postsToShow && postsToShow.length > 0 && (
          <SelectPostTable
            postsToShow={postsToShow}
            setIsPopupVisible={setIsPopupVisible}
            cardsToShow={cardsToShow}
            setCardsToShow={setCardsToShow}
            editCardPosition={editCardPosition}
            setEditCardPosition={setEditCardPosition}
            setDataToSubmit={setDataToSubmit}
            removeCardAtEdit={removeCardAtEdit}
            showCreateCardSteps={showCreateCardSteps}
            setCardsStepPost={setCardsStepPost}
            dataToSubmit={dataToSubmit}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectPostPopup;
