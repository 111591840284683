import { GET_CAMPAIGN_DETAILS_CONSTANTS } from "../constants";
import { GET_CAMPAIGN_DETAILS_URL } from "../routes";
import http from "../../shared/http-request";
import { BASE_URL } from "../../StaticArray/StaticArray";
import { onGetCampaignDetails } from "../../utils/network/lib/campaign";

export const getCampaignDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_CAMPAIGN_DETAILS_CONSTANTS.GET_CAMPAIGN_DETAILS_STARTED,
    });

    return onGetCampaignDetails(payload)
      .then((res) => {
        const { status, data } = res;
        return dispatch({
          type: GET_CAMPAIGN_DETAILS_CONSTANTS.GET_CAMPAIGN_DETAILS_SUCCESS,
          payload: { status, data },
        });
      })
      .catch((error) => {
        if (error.toString() === "TypeError: Network request failed") {
          return dispatch({
            type: GET_CAMPAIGN_DETAILS_CONSTANTS.GET_CAMPAIGN_DETAILS_NET_FAILED,
            payload: { status: 500, data: { message: error.toString() } },
          });
        }
        return dispatch({
          type: GET_CAMPAIGN_DETAILS_CONSTANTS.GET_CAMPAIGN_DETAILS_FAILED,
          payload: { ...error },
        });
      });
  };
};
