import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/lead"

export function onUpdateQualification(id, payload) {
    return http.put(prefix + "/" + id, payload)
}

export function onDeleteValue(id) {
    return http.delete(prefix + "/" + id)
}

export function onQualificationChange(id, payload) {
    return http
        .put(prefix + "/" + id, payload)
}

export function onLeadSavedClicked(id, payload) {
    return http
        .put(prefix + "/" + id, payload)
}

export function onGetLead(id, payload) {
    return http
        .get(prefix + "/" + id)
}

export function onUpdateLead(id, payload) {
    return http
        .put(prefix + "/" + id, payload)
}

export function onImportLeads(payload) {
    return http
        .post(base + "/import/leads", payload)
}

export function onExportLeads(requestUri) {
    return http
        .get(base + "/export/leads" + requestUri)
}