import React from "react";

const FeaturesTableHeader = () => {
  return (
    <React.Fragment>
      <th>Feature ID</th>
      <th>Feature Name</th>
      <th>Path</th>
      <th>Select</th>
    </React.Fragment>
  );
};

export default FeaturesTableHeader;
