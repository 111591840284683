
import * as types from "../actions/actionTypes";

const initialState = {
  user: null,
  fetched: false,
  error: false,
  resp: {},
  emailError:null,
  isAuthenticated:false,
  passwordErr:null
};

export function loginReducer(state = initialState, { type, payload }) {
  console.log(payload,"LLLLLLogin reducer");
  switch (type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        fetched: true,
        error: false,
        isAuthenticated:true,
        user: payload.data
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        fetched: false,
        error: true,
        resp: payload
      };
      case types.LOGIN_ERROR_MESSAGE:
        return {
          ...state,
          fetched: false,
          error: true,
          ...payload
        };

      case types.SET_CURRENT_USER:
        return {
          ...state,
          isAuthenticated: Object.keys(payload).length>0?true:false,
          user: payload
        };
    default:
      return state;
  }
}