import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";
import axios from "axios";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/package"
const shortBase = VERSION_PREFIX + "/validate"

const permissionPrefix = base + "/permissionPackage"


export function onGetAllPackages() {
    return axios.get(prefix)
}

export function onGetAllPackageById(id) {
    return axios.get(prefix + "/" + id)
}

export function onAddPackageById(payload) {
    return axios.get(prefix, payload)
}


export function onUpdatePackageById(id, payload) {
    return axios.put(prefix + "/" + id, payload)
}


export function onDeletePackageById(id) {
    return axios.delete(prefix + "/" + id)
}


export function onAddPermissionPackage(payload) {
    return axios.post(permissionPrefix, payload)
}

export function onUpdatePermissionPackageById(id, payload) {
    return axios.put(permissionPrefix + "/" + id, payload)
}


export function onDeletePermissionPackageById(id) {
    return axios.delete(permissionPrefix + "/" + id)
}

export function onGetPermissionPackages() {
    return axios.get(permissionPrefix)
}

export function onGetPermissionPackageById(id) {
    return axios.get(permissionPrefix + "/" + id)
}
