import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";

export default class DatepickerCustom extends Component {
  render() {
    const dateState =
      this.props.state_type == "start"
        ? this.props.value.value.start_date
        : this.props.value.value.end_date;
    const focused =
      this.props.state_type == "start"
        ? this.props.value.thisstate.focused_start
        : this.props.value.thisstate.focused_end;

    return (
      <div>
        <SingleDatePicker
          readOnly
          inputIconPosition="before"
          small={true}
          block={false}
          numberOfMonths={1}
          date={dateState}
          onDateChange={(date) =>
            this.props.handleChange(date, this.props.state_type)
          }
          focused={focused}
          onFocusChange={({ focused }) =>
            this.props.handleFocused(focused, this.props.state_type)
          }
          openDirection="down"
          hideKeyboardShortcutsPanel={true}
        />
      </div>
    );
  }
}
