import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { toast } from "react-toastify";
import TagManager from "react-gtm-module";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import CheckDialog from "../../CheckDialog";
import PeasyApi from "../../../utils/peasy.api";
import { onCheckFbTos } from "../../../utils/network/lib/validate";
import { useEffect } from "react";

const args = {
  dataLayer: {
    event_custom: "brand_profile_create_fb_page",
    /* can pass more variables here if needed */
  },
};

const CreateBrandSelectFbPage = ({
  fbAllPages,
  onChange,
  nextPageOnEnter,
  value,
  prevStep,
  backSomeSteps,
  isFacebookAccessible,
  step,
  checkFunnelType,
}) => {
  const { t } = useTranslation();
  const [openCheck, setOpenCheck] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [target, setTarget] = useState("");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    console.log("fb brand selection", fbAllPages);
    if (Array.isArray(fbAllPages.data) && fbAllPages.data.length == 0) {
      window.$peasy.triggerEvent({
        event_name: "warning",
        event_params: { id: "1" },
      });
    }
  }, []);

  const checkTerms = async () => {
    setDisabled(true);
    let data;
    try {
      const resp = await onCheckFbTos({
        fb_promoted_id: value,
      });
      if (
        resp &&
        resp.lead_tos.status === false &&
        checkFunnelType() === "LEAD_GENERATION"
      ) {
        data = resp.lead_tos;
        setUrl(data.callback_url);
        setMessage(data.message);
        setButtonText(data.callback_button_text);
        setTarget(data.target);
        setOpenCheck(true);
        setDisabled(false);
      } else if (
        resp &&
        resp.custom_audience_tos.status === false
        // && checkFunnelType() === "LEAD_GENERATION"
      ) {
        data = resp.custom_audience_tos;
        setUrl(data.callback_url);
        setMessage(data.message);
        setButtonText(data.callback_button_text);
        setTarget(data.target);
        setOpenCheck(true);
        setDisabled(false);
      } else {
        validation();
      }
    } catch (error) {
      // window.$peasy.triggerEvent({
      //   event_name: "error",
      //   event_params: { id: "3", type: "external" },
      // });

      console.log(error);
      toast.error(t("brand_somethingWrongWhileAction"));
      setDisabled(false);
    }
  };

  TagManager.dataLayer(args);
  const getFbPageName = () => {
    console.log("inside get fb page name", value);
    for (let i = 0; i < fbAllPages.data.length; i++) {
      if (fbAllPages.data[i].id === value) {
        return fbAllPages.data[i].name;
      }
    }
  };

  const onBackClick = () => {
    if (isFacebookAccessible) return backSomeSteps(2);
    else prevStep();
  };

  const validation = () => {
    if (value.length > 0) return nextPageOnEnter();
    else return toast.error(t("brand_selectPage"));
  };

  return (
    <>
      <Box className="createBrand-box">
        <CheckDialog
          // this is a check dialog for checking account validity
          open={openCheck}
          setOpen={setOpenCheck}
          url={url}
          message={message}
          srcImg="/images/assets/Failure.svg"
          target={target}
          buttonText={buttonText}
        />
        <div className="tab-pane col-12 px-0 active" id="Step6">
          <div className="form-group brand-custom-form brand-custom-dropdown mt-4">
            <label>{t("brand_selectFbPage")}</label>

            <button
              type="button"
              className="form-control brand-dropdown"
              data-toggle="collapse"
              data-target="#optimization"
            >
              {value.length > 0 ? getFbPageName() : t("brand_FbPageList")}
              <i className="fa fa-caret-down float-right" />
            </button>
            <div id="optimization" className="collapse">
              <ul className="list-unstyled">
                {" "}
                {fbAllPages.data.map((fb_pages) => (
                  <li
                    data-toggle="collapse"
                    data-target="#optimization"
                    onClick={() => {
                      onChange("fb_promoted_id", fb_pages.id);
                      // nextPageOnEnter();
                    }}
                    className="custom-control custom-radio dropdown-item"
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      id={"fb_promoted" + fb_pages.id}
                      name="fb_promoted_id"
                      value={fb_pages.id}
                      checked={value && fb_pages.id === value}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"fb_promoted" + fb_pages.id}
                    >
                      {fb_pages.name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Box>

      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              onClick={() => prevStep()}
              className="brandprofilecard__btn--white"
            >
              {t("brand_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              disabled={disabled}
              className="brandoverviewleftsection__btn--purple"
              onClick={() => checkTerms()}
            >
              {t("brand_next")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBrandSelectFbPage;
