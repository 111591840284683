import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import {
  onGetFormFieldMap,
  onGetFormQuestions,
  onUpsertLeadMap,
} from "../../../utils/network/lib/misc";
import { EVENT_TYPE_EXTERNAL } from "../../../utils/eventConstants";

const FormFieldMapping = ({ prevStep, nextStep, values, step_next }) => {
  const { t } = useTranslation();

  const [fieldMapArray, setfFieldMapArray] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [formField, setFormField] = useState({
    phone: "",
    name: "",
    email: "",
  });

  const gobackprevious = () => {
    prevStep();
  };

  const saveAndContinue = () => {
    if (formField.phone == "") {
      setButtonDisable(false);
      toast.error(t("campaign_phoneFieldNoneerror"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
    saveFormData();
  };

  const getFormFieldMap = async () => {
    try {
      const { data, status } = await onGetFormQuestions(values.form_id);
      if (status == 200) {
        setfFieldMapArray(data);
      } else {
        if (Array.is(data) && data.length < 3) {
          if (data.length == 0) {
            window.$peasy.triggerEvent({
              event_name: "info",
              event_params: { id: "7" },
            });
          } else {
            window.$peasy.triggerEvent({
              event_name: "error",
              event_params: { id: "8", type: EVENT_TYPE_EXTERNAL },
            });
          }
        }
        toast.error(t("campaign_formFieldMappingfailed"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {
      console.log(" error", err);
    }
  };

  const getData = async () => {
    try {
      const { data, status } = await onGetFormFieldMap(values.form_id);
      if (status == 200) {
        if (data) {
          let d = data.data;
          setFormField({ ...formField, ...d });
        }
      } else {
        toast.error(t("campaign_formFieldMappingfailed"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const saveFormData = async () => {
    let ff = {};
    Object.keys(formField).forEach(function (key) {
      if (formField[key] != "") ff[key] = formField[key];
    });

    try {
      const { data, status } = await onUpsertLeadMap({
        lead_form_id: values.form_id,
        data: ff,
      });
      if (status == 200) {
        nextStep();
      } else {
        toast.error(t("campaign_formFieldSaveError"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    getFormFieldMap();
    getData();
  }, []);

  return (
    <div className="w-100">
      <div
        className="active col-12 px-0 tab-pane border-rounded p-3 mb-2"
        id="step12"
      >
        <div className="form-group brand-custom-form mt-2">
          <label>{t("campaign_mapFormHeading")}</label>
        </div>
        <p className="question-description mb-3">
          {t("campaign_mapFormFieldsText")}
        </p>

        <div className="row cus-calender">
          <div className="col-md-4">
            <div className="card-body p-0">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {t("campaign_phone")}
                  </span>
                </div>
                <i className="fa fa-address-card input-group-text"></i>
                <select
                  className="form-control border-none"
                  style={{ border: "none" }}
                  value={formField.phone}
                  onChange={(event) =>
                    setFormField({ ...formField, phone: event.target.value })
                  }
                >
                  <option value="">{t("campaign_none")}</option>
                  {fieldMapArray.map((fieldMap) => (
                    <option key={fieldMap.key} value={fieldMap.key}>
                      {fieldMap.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-body p-0">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">{t("campaign_name")}</span>
                </div>
                <i className="fa fa-user input-group-text"></i>
                <select
                  className="form-control border-none"
                  style={{ border: "none" }}
                  value={formField.name}
                  onChange={(event) =>
                    setFormField({ ...formField, name: event.target.value })
                  }
                >
                  <option value="">{t("campaign_none")}</option>
                  {fieldMapArray.map((fieldMap) => (
                    <option key={fieldMap.key} value={fieldMap.key}>
                      {fieldMap.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-body p-0">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {t("campaign_email")}
                  </span>
                </div>
                <i className="fa fa-envelope input-group-text"></i>
                <select
                  className="form-control border-none"
                  style={{ border: "none" }}
                  value={formField.email}
                  onChange={(event) =>
                    setFormField({ ...formField, email: event.target.value })
                  }
                >
                  <option value="">{t("campaign_none")}</option>
                  {fieldMapArray.map((fieldMap) => (
                    <option key={fieldMap.key} value={fieldMap.key}>
                      {fieldMap.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              className="brandprofilecard__btn--white"
              onClick={gobackprevious}
            >
              {t("campaign_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              disabled={buttonDisable}
              className="brandoverviewleftsection__btn--purple"
              onClick={() => {
                setButtonDisable(true);
                saveAndContinue();
              }}
            >
              {t("campaign_next")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormFieldMapping;
