import React, {Component} from 'react'
import {toast} from 'react-toastify';
import {InfiniteScroll} from '../../../shared/InfiniteScroll/InfiniteScroll';
import http from '../../../shared/http-request';

export default class ExisingCampaign extends Component {

    constructor(props) {
        super(props);
        this.saveAndContinue = this.saveAndContinue.bind(this);
        this.gobackprevious = this.gobackprevious.bind(this);
        this.MarkAsSelected = this.MarkAsSelected.bind(this);
        this.renderElement = this.renderElement.bind(this);
        this.get_Existing_campaigns = this.get_Existing_campaigns.bind(this);
        this.fetchMore = this.fetchMore.bind(this);
        this.state = {
            list: [],
            firstChunkLoaded: false,
            choosed_campaigns: [],
            user_curreny: 'MYR',
            loading: true,
            campaignsLengthRead: 0,
            chunkLength: 4,
            totalCount: 0
        };
    }

    MarkAsSelected(campaign_id, account_id) {
        console.log(campaign_id + ' Selected Campaign');
        console.log(account_id + ' Selected account_id');
        this.props.setExistingCampaigns(campaign_id, account_id);
        console.log('SetteD selected Campaign');
        console.log(this);
    }

    saveAndContinue() {
        const {selected_campaigns} = this.props.values;
        console.log(Object.keys(selected_campaigns).length + ' Length');
        if (Object.keys(selected_campaigns).length < 1) {
            toast.error("Please Select Minimum 1 Campaign !", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return false;
        }

        //This means length more than 3
        if (Object.keys(selected_campaigns).length > 3) {
            toast.error("You Can Select Maximum 3 Campaigns !", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return false;
        } else {
            this.props.nextStep();
        }
    }

    gobackprevious() {
        this.props.prevStep()
    }

    componentDidMount() {
        this.setState({totalCount: (this.props.values.existing_campaigns || []).length}, () => this.get_Existing_campaigns());
    }

    get_Existing_campaigns() {
        console.log('get existing campaign...', {...this.state});
        const {campaignsLengthRead, chunkLength, totalCount} = this.state;
        if (campaignsLengthRead < totalCount) {
            console.log('count is less');
            this.setState({loading: true});

            const newReadLength = campaignsLengthRead + chunkLength;
            const campaignIds = (this.props.values.existing_campaigns || []).slice(campaignsLengthRead, newReadLength);

            const bodyParameters = {
                Objective: this.props.CheckObjective(),
                pixelEvent: this.props.values.pixel_event,
                campaignIds
            };

            console.log('requesting http', bodyParameters);
            http.post(this.props.BASE_URL + 'api/get-existing-campaigns',
                bodyParameters
            ).then(response => {
                console.log(response);
                const {campaigns, user_curreny} = response.data;
                this.setState(({list: oldList}) => ({
                    list: {...oldList, ...campaigns},
                    user_curreny,
                    firstChunkLoaded: true,
                    loading: false,
                    campaignsLengthRead: newReadLength
                }));
            }).catch(err => {
                console.log(err);
                this.setState({loading: false});
            });
        }
    }

    fetchMore() {
        if (!this.state.loading) {
            this.get_Existing_campaigns();
        }
    };

    renderElement() {
        if (!this.state.firstChunkLoaded) {
            return <tr>
                <td>
                    <div className="w-100 grey_bg loading">
                        dsfsdfsdfsdf
                    </div>
                    <div className="w-25 grey_bg loading">
                        sdfsdfsdfsdfsdfdsfsdfsd
                    </div>
                </td>
            </tr>
        } else {
            return null;
        }
    }

    render() {
        console.log('render', {...this.state});
        const {list, loading, campaignsLengthRead, totalCount} = this.state;
        const arr = [];
        for (const key in list) {
            arr.push(list[key]);
        }

        const that = this;
        const items = arr.map(function (item, key) {

            // if(item.status == 'ACTIVE')
            // {
            return <tr key={key}>
                  <td><p>{item.name}</p><span>{item.objective}</span></td>
                  <td><p className="color-gray">Total Spent</p><span>{item.total_cost}</span></td>
                  <td><p className="color-gray">Conversions</p><span>{item.total_action}</span></td>
                  <td><p className="color-gray">CPA({that.state.user_curreny})</p><span>{item.average_conversion_cost}</span></td>
                  
                  <td>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id={key}/>
                        <label className="custom-control-label" htmlFor={key}
                               onClick={() => that.MarkAsSelected(item.id, item.account_id)}/>
                    </div>
                </td>
            </tr>
            // }
        });

        return (<div className="w-100">
            <div className="active col-12 px-0 tab-pane" id="step8">
                <div className="form-group brand-custom-form brand-custom-dropdown mt-5">
                    <label className="mb-2">Choose your existing campaigns </label>
                    <span className="cus-label-span d-block w-100 mb-4">Choose up to 3 campaign that you think outperform the others</span>

                    <InfiniteScroll scrolled={this.fetchMore} class={'table-responsive table-responsive-campaigns finiteHeight'} offset={loading ? 50 : 10}>
                        <table className="table">
                            <tbody>

                            {this.renderElement()}
                            {this.renderElement()}
                            {this.renderElement()}

                            {items}

                            </tbody>
                        </table>
                        {(campaignsLengthRead < totalCount) && <div className={'infiniteLoaderWrapper'}>
                            <div className="small-loader">
                                <div className="lds-ring">
                                    <div/>
                                    <div/>
                                    <div/>
                                    <div/>
                                </div>
                            </div>
                        </div>}
                    </InfiniteScroll>

                </div>
            </div>


            <div className="navination_bar">
                <div className="bar_search d-flex">
                    <div className="above_navigation ml-auto">
                        <button className="btn go_previous" onClick={this.gobackprevious}>
                            <img src="/images/down-arrow.png" alt="down"
                                 className="previous_process rotate "/>
                        </button>
                    </div>
                    <div className="below_navigation">
                        <button className="btn go_next dsfsdf" onClick={this.saveAndContinue}>
                            <img src="/images/down-arrow.png" alt="down" className="next_process"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>);
    }
}
