import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles({
  root: {
    maxWidth: 240,
    margin: "10px",
    flex: "1",
  },
  media: {
    height: 220,
  },
});

export default function MediaCard({ title, link, description }) {
  const [image, setImage] = useState("/images/dashboard-article.png");
  useEffect(() => {
    getImage();
  }, []);

  const getImage = () => {
    const html = ReactHtmlParser(description);
    if (html && html[0] && html[0].type == "img" && html[0].props.src) {
      setImage(html[0].props.src);
    }
  };
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia className={classes.media} image={image} />
        <CardContent>
          <Typography>{title.slice(0, 30)}...</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <a href={link} target="_blank" className="w-100">
          <Button
            size="small"
            color="primary"
            fullWidth
            variant="outlined"
            style={{ fontWeight: "600" }}
          >
            <img
              className="m-1"
              style={{ width: "25px" }}
              src="/images/dashboard-articles-icon.svg"
              alt=""
            />
            {t("dashboard_readMore")}
          </Button>
        </a>
      </CardActions>
    </Card>
  );
}
