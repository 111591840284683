import { Box } from "@material-ui/core";
import { array } from "prop-types";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const CreateBrandCatalogs = ({
  catalogs,
  catalog_id,
  onChange,
  nextPageOnEnter,
  prepaid_fb_account_id,
  prevStep,
}) => {
  const { t } = useTranslation();

  const [selectedCatalogIds, setSelectedCatalogIds] = useState([]);

  useEffect(() => {
    console.log(catalogs);
    let array = [];
    Object.keys(catalogs).map((catalog, index) => {
      array = [...array, [catalogs[catalog].name, catalogs[catalog].id]];
    });
    console.log(array);
    setSelectedCatalogIds(array);
  }, [catalogs]);

  const getCatalogName = () => {
    console.log("selectedCatalogIds",selectedCatalogIds)
    let string = "";
    var selected = selectedCatalogIds.map((catalog) => {
      if (catalog[1] === catalog_id) {
        // string = string + `${catalog[0] + " " + catalog[1]},`;
        return catalog[0];
      }
    });
    return selected;
  };

  const selectCatalog = (catalog) => {
    onChange("catalog", catalog);
  };

  const onNextClick = () => {
    if (catalog_id.length === 0) return toast.error(t("brand_selectCatalog"));
    nextPageOnEnter();
  };

  return (
    <React.Fragment>
      <Box className="createBrand-box">
        <div
          style={{ minHeight: "60px" }}
          className="tab-pane col-12 px-0 active "
          id="Step2"
        >
          <div className="form-group brand-custom-form brand-custom-dropdown mt-4">
            <label>{t("brand_catalogEvent")}</label>
            <p className="question-description mb-2"></p>

            <button
              type="button"
              className="form-control brand-dropdown"
              data-toggle="collapse"
              data-target="#catefory"
            >
              {catalog_id && catalog_id.length > 0
                ? getCatalogName()
                : t("brand_catalogBrand")}
              <i className="fa fa-caret-down float-right" />
            </button>
            <div id="catefory" className="collapse">
              <ul className="list-unstyled" id="facebook_adds">
                {selectedCatalogIds.length > 0 &&
                  selectedCatalogIds.map((val) => (
                    <li
                      onClick={() => {
                        selectCatalog(val);
                        nextPageOnEnter();
                      }}
                      className="custom-control custom-radio dropdown-item"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        value={catalog_id}
                        checked={catalog_id && val[1] === catalog_id}
                      />
                      <label className="custom-control-label">
                        {/* {val[0] + " " + val[1]} */}
                        {val[0]}
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Box>

      <div style={{ left: "90%" }} className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              onClick={() => prevStep()}
              className="brandprofilecard__btn--white"
            >
              {t("brand_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              className="brandoverviewleftsection__btn--purple"
              onClick={() => onNextClick()}
            >
              {t("brand_next")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateBrandCatalogs;
