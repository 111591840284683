import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";
import axios from "axios";

const base = BASE_URL + VERSION_PREFIX;
const shortBase = VERSION_PREFIX;

export function onGetBrandsById(id) {
  return http.get(base + "/getBrands/" + id);
}

export function onCreateBrand() {
  return http.get(base + "/createbrand");
}

export function getCatalogs(payload) {
  return http.post(base + "/getFBCatalogs", payload);
}

export function onPostBrand(payload) {
  return http.post(base + "/saveBrand", payload);
}

export function onGetBrandData(payload) {
  return PeasyApi.post(shortBase + "/get-brand-data", payload);
}

export function onGetBrandProfileList() {
  return PeasyApi.get(shortBase + "/get-brand-profile-list");
}

export function onGetBrandLimit(userid) {
  return PeasyApi.get(shortBase + "/checkBrandLimit/" + userid);
}

export function onGetDummyBrandProfile() {
  return PeasyApi.get(shortBase + "/dummyBrandProfile");
}

export function onGetBandLeads(brand_profile_id) {
  return http.get(base + "/brand/leads?brand_profile_id=" + brand_profile_id);
}
