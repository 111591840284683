import React, { Component } from "react";
import Select from "react-select";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";

class SelectFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  SelectOptions = [
    {
      value: "campaign_name",
      label: this.props.t("clients_campaignName"),
    },
    {
      value: "adset_name",
      label: this.props.t("clients_adSetName"),
    },
    {
      value: "campaign_id",
      label: this.props.t("clients_campaignId"),
    },
    {
      value: "adset_id",
      label: this.props.t("clients_addSetId"),
    },

    {
      value: "registered_before",
      label: this.props.t("clients_registeredBetween"),
    },
    {
      value: "prediction_score",
      label: this.props.t("clients_predictionScore"),
    },
    {
      value: "qualification",
      label: this.props.t("clients_qualificationStatus"),
    },
  ];
  setFilterOptionsByValue = (value) => {
    this.props.setFilterOptionsByValue(value);
  };

  onSelectChangeHandler = (value) => {
    this.setState({ value });

    this.setFilterOptionsByValue(value.value);
  };
  render() {
    return (
      <div>
        <Select
          value={this.state.value}
          options={this.SelectOptions}
          onChange={this.onSelectChangeHandler}
        />
      </div>
    );
  }
}

export default withTranslation()(SelectFilter);
