import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/notifications"

export function onGetNotifications() {
    return http.get(prefix)
}

export function onGetNotificationByMessageId(id) {
    return http.get(prefix + "/" + id)
}
