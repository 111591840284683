import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { getAllPackagePermissions } from '../../../redux/actions/permissionActions';
import { connect } from 'react-redux';

const FeatureAddModal = (props) => {
    const {
        toggle,
        className,
        isOpen,
        handleAddFeaure,
        permissions: { allPermissions }
    } = props;
    const [inputs, setInputs] = useState({ name: '', path: '' })

    const handleInputChange = ({ target: { name, value } }) => {
        setInputs(inputs => ({ ...inputs, [name]: value }))
    }
    useEffect(() => {
        props.getAllPackagePermissions()
    }, [])
    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Add Feature</ModalHeader>
                        <Form onSubmit={(e) => handleAddFeaure(e, inputs)}>
                            <ModalBody>
                                <FormGroup>
                                    <Label for="name">Feature name</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        onChange={handleInputChange}
                                        value={inputs.name}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="path">Path</Label>
                                    <Input
                                        type="text"
                                        name="path"
                                        value={inputs.path}
                                        id="path"
                                        required
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggle}>Cancel</Button>
                                <Button color="primary" type="submit">Add</Button>{' '}
                            </ModalFooter>
                        </Form>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ permissions }) => (
    {
        permissions
    }
)

export default connect(mapStateToProps, { getAllPackagePermissions })(FeatureAddModal);