import { Box, Typography } from "@material-ui/core";
import React from "react";
import { toast } from "react-toastify";
import TagManager from "react-gtm-module";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const args = {
  dataLayer: {
    event_custom: "brand_profile_create_category",
    /* can pass more variables here if needed */
  },
};

const CreateBrandCategory = ({
  value,
  onChange,
  nextPageOnEnter,
  category,
  prevStep,
  step,
}) => {
  TagManager.dataLayer(args);
  const { t } = useTranslation();

  const validate = () => {
    if (value.length > 0) return nextPageOnEnter();
    else return toast.error(t("brand_selectCategoryToContinue"));
  };

  return (
    <>
      <Box className="createBrand-box">
        <div
          // style={{ minHeight: "60vh" }}
          className="tab-pane col-12 px-0 active "
          id="Step2"
        >
          <div className="form-group brand-custom-form brand-custom-dropdown mt-4">
            <label>{t("brand_descNature")}</label>
            <p className="mb-4 question-description">
              {t("brand_questionDesc")}
            </p>
            <button
              type="button"
              className="form-control brand-dropdown"
              data-toggle="collapse"
              data-target="#catefory"
            >
              {value.length > 0 ? value : t("brand_brandCategory")}
              <i className="fa fa-caret-down float-right" />
            </button>
            <div id="catefory" className="collapse">
              <ul className="list-unstyled" id="facebook_adds">
                {category.length > 0 &&
                  category.map((val) => (
                    <li
                      onClick={() => {
                        onChange("product_category", val.name);
                        nextPageOnEnter();
                      }}
                      className="custom-control custom-radio dropdown-item"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id={"catid" + val.id}
                        name="product_category"
                        value={val.id}
                        placeholder={value.name}
                        checked={val.name === value}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"catid" + val.id}
                      >
                        {val.name}
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Box>

      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              onClick={() => prevStep()}
              className="brandprofilecard__btn--white"
            >
              {t("brand_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              className="brandoverviewleftsection__btn--purple"
              onClick={() => validate()}
            >
              {t("brand_next")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBrandCategory;
