import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import VideoThumbnail from "react-video-thumbnail";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Carousel.css";
import PhotoComponent from "../../photoComponent/PhotoComponent";
import { BASE_URL } from "../../../../StaticArray/StaticArray";
import PopupAlert from "../../../../common/popupAlert/PopupAlert";
import SmallErrorText from "../../../../common/smallErrorText/SmallErrorText";
import AddPostCard from "../../addPostCard/AddPostCard";

const CarouselContainer = ({
  setIsPopupVisible,
  carouselData,
  deleteMediaCard,
  updateCariuselData,
  ctaList,
  audiences,
  carouselPopVisible,
  carousalId,
  isErrorPopUp,
  errorArrayIndex,
  handleUpdateCarousalMedia,
  carouselCardIds,
  dataFromCarouselCard,
  changeCarousalArray,
  updateCarouselIndividualData,
  setErrorPopUp,
  setErrorIndex,
  setIsThumbnail,
  updateCarouselCtaData,
}) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [deleteMediaIndex, setDeleteMediaIndex] = useState("");

  const [isThumbnail, setIsPopThumbnail] = useState(true);

  const [itemSelected, setItemSelected] = useState(null);

  const [showCtaOptions, setShowCtaOptions] = useState(false);

  const [itemData, setDataArray] = useState([]);

  // Disable background scroll when popup opens and set to auto when closes
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  // useEffect(() => {
  //   setItemSelectedProp(itemSelected);
  // }, [itemSelected]);

  useEffect(() => {
    let newArray = [];
    carouselData.map((data, i) => {
      newArray = [
        ...newArray,
        {
          ...data,
          id: `${data.unique_id}`,
          content: `image ${data.unique_id}`,
        },
      ];
    });

    setDataArray(newArray);
  }, [carouselData]);

  // Code fires when length is changed of carousel data
  useEffect(() => {
    let newArray = [];
    carouselData.map((data, i) => {
      newArray = [
        ...newArray,
        {
          ...data,
          id: `${data.unique_id}`,
          content: `image ${data.unique_id}`,
        },
      ];
    });

    setDataArray(newArray);
  }, [carouselData.length]);

  useEffect(
    () => () => {
      document.body.style.overflow = "auto";
    },
    []
  );

  // Code till here to disable background scroll

  let dragData = [];
  carouselData.length &&
    carouselData.map((data, i) => {
      dragData = [
        ...dragData,
        {
          ...data,
          id: `${data.unique_id}`,
          content: `Image ${data.unique_id}`,
        },
      ];
    });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      dragData,
      result.source.index,
      result.destination.index
    );

    dragData = items;
    updateCariuselData(dragData);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? "#a5c76b" : "#f1f1f1",
    height: "27.5rem",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // Handle Image Edit click
  const onEditClick = (type, selectedIndex, item) => {
    setItemSelected(item);

    // Find index of edit click
    let editIndex = dragData.findIndex((r) => r.unique_id === selectedIndex);
    type === 1
      ? document
          .getElementsByClassName("input__file--carousel-image")[0]
          .click()
      : document
          .getElementsByClassName("input__file--carousel-video")[0]
          .click();
  };

  // Handle File change
  const onFileChange = (file, type) => {
    if (type === 1) {
      let isValid = false;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          let height = this.height;
          let width = this.width;
          let widthRatio = width / height;
          let heightRatio = height / width;
          if (
            (widthRatio >= 1.91 && width >= 1200 && height >= 628) ||
            (width === height && width >= 1080)
          ) {
            isValid = true;
          } else {
            isValid = false;
          }

          if (isValid) {
            const obj = {
              ...itemSelected,
              path: "",
              file_object: file,
              width,
              height,
              widthRatio,
              heightRatio,
            };
            let newArray = [];
            newArray = [...dragData];

            let newObjIndex = newArray.findIndex(
              (r) => r.unique_id === obj.unique_id
            );
            newArray.splice(newObjIndex, 1, obj);

            changeCarousalArray(carousalId, newArray);
          } else {
            toast.error(
              `Image ${file.name} ratio should be in between 1.91:1 to 1:1`
            );
          }
        };
      };
    } else {
      var oReader = new FileReader();
      oReader.readAsArrayBuffer(file);
      oReader.onload = (e) => {
        console.log("e.target.result", e.target.result);
        let buffer = e.target.result;
        let videoBlob = new Blob([new Uint8Array(buffer)], {
          type: "video/mp4",
        });
        let url = window.URL.createObjectURL(videoBlob);
        if (
          file.type == "video/mp4" ||
          file.type == "video/ogg" ||
          file.type == "video/webm"
        ) {
          var videoElement = document.createElement("video");
          videoElement.preload = "metadata";

          videoElement.addEventListener("loadedmetadata", () => {
            if (videoElement.duration <= 3600) {
              var timer = setInterval(() => {
                if (videoElement.readyState === 4) {
                  const obj = {
                    ...itemSelected,
                    path: "",
                    thumbnail_path: "",
                    file_object: file,
                    duration: videoElement.duration,
                  };

                  let newArray = [];
                  newArray = [...dragData];

                  let newObjIndex = newArray.findIndex(
                    (r) => r.unique_id === obj.unique_id
                  );

                  newArray.splice(newObjIndex, 1, obj);
                  // const index = videoCardIds.indexOf(cardData.unique_id);
                  changeCarousalArray(carousalId, newArray);
                  setIsPopThumbnail(false);
                  setIsThumbnail(false);
                  setTimeout(() => {
                    setIsPopThumbnail(true);
                    setIsThumbnail(true);
                  }, 200);

                  clearInterval(timer);
                }
              });
            } else {
              toast.error(
                `Video ${file.name} duration should be less than 60 minutes.`
              );
            }
          });
          videoElement.src = url;
        }
      };
    }
  };

  // Handle Delete click
  const onDeleteClick = () => {
    const index = carouselData.findIndex(
      (r) => r.unique_id === deleteMediaIndex
    );
    deleteMediaCard(index);
  };

  const handleCarousalPopOver = () => {
    carouselPopVisible(true);
    handleUpdateCarousalMedia(carousalId);
  };

  // Handle ChangeDateBlur events
  const changeMainDataOnBlur = (object) => {
    console.log("changeMainDataOnBlur =>", object);
    if (object) updateCarouselIndividualData(carousalId, object);
  };

  // Handle Cta options
  const changeCtaValue = (cta) => {
    let updatedValue = cta.replace("_", " ");
    return updatedValue;
  };

  // Handle onChange function
  const onChange = (name, value, item) => {
    const obj = { ...item, [name]: value };
    let newArray = [...itemData];
    let arrayIndex = newArray.findIndex((r) => r.unique_id === item.unique_id);
    newArray.splice(arrayIndex, 1, obj);
    setDataArray(newArray);
  };

  // Handle data Url to file
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  return (
    <React.Fragment>
      {showDeletePopup && (
        <PopupAlert
          heading="Are You Sure ?"
          body="Are you sure you want to delete this post?"
          onNoClick={() => setShowDeletePopup(false)}
          onYesClick={() => {
            onDeleteClick();
            setShowDeletePopup(false);
          }}
        />
      )}

      {/* input file for editing the image. visibility hidden */}
      <input
        type="file"
        className="input__file--carousel-image"
        accept="image/png, image/jpeg"
        onChange={(e) => onFileChange(e.target.files[0], 1)}
        style={{ display: "none" }}
      />

      {/* input file for editing the image. visibility hidden */}
      <input
        type="file"
        className="input__file--carousel-video"
        accept="video/mp4, video/mkv"
        onChange={(e) => onFileChange(e.target.files[0], 2)}
        style={{ display: "none" }}
      />

      <div className={`selectpostpopup__container`}>
        <div className="selectpostpopup__heading">
          Carousal Media
          <i
            className="fas fa-times selectpostpopup__icon--close"
            onClick={() => {
              setIsPopupVisible(false);
              setErrorPopUp(false);
              setErrorIndex([]);
            }}
          />
        </div>
        <div className="carousal-content">
          <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {dragData.map((item, index) => {
                    let isError = "";

                    if (isErrorPopUp) {
                      isError = errorArrayIndex.find((r) => r.index == index);
                    }

                    console.log("isError isError", isError);

                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="drag-content">
                              {item.type === 1 && item.file_object ? (
                                <div>
                                  <PhotoComponent
                                    url={URL.createObjectURL(item.file_object)}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {item.type === 1 && item.path ? (
                                <PhotoComponent
                                  url={`${BASE_URL}thumbnail/adimage?path=${item.path}`}
                                />
                              ) : (
                                ""
                              )}
                              {item.type === 2 && (
                                <img
                                  className="videocard__button--play"
                                  src="/images/play-button.png"
                                  alt=""
                                />
                              )}
                              {item.type === 2 &&
                              item.file_object &&
                              isThumbnail ? (
                                <VideoThumbnail
                                  key={item.unique_id + "thumb"}
                                  videoUrl={window.URL.createObjectURL(
                                    item.file_object
                                  )}
                                  thumbnailHandler={(preview) => {
                                    const thumbnail = dataURLtoFile(
                                      preview,
                                      "thumbnail"
                                    );
                                    const newObj = {
                                      ...item,
                                      thumbnail_object: thumbnail,
                                    };
                                    const index =
                                      carouselCardIds.indexOf(carousalId);
                                    dataFromCarouselCard(index, newObj);
                                  }}
                                />
                              ) : item.path ? (
                                ""
                              ) : (
                                "loading"
                              )}
                              {item.type === 2 && item.path && (
                                <PhotoComponent
                                  url={`${BASE_URL}thumbnail/adimage?path=${item.thumbnail}`}
                                />
                              )}{" "}
                              <div
                                id="carouselcard-layover"
                                className="carouselcard__layover"
                              >
                                <button
                                  onClick={() =>
                                    onEditClick(item.type, item.unique_id, item)
                                  }
                                  className="carouselcard__btn--edit"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => {
                                    carouselData.length === 3
                                      ? toast.warning(
                                          "Cannot delete media as carousel must contains atleat 3 media content."
                                        )
                                      : setShowDeletePopup(true);
                                    setDeleteMediaIndex(item.unique_id);
                                  }}
                                  style={{ top: "61%" }}
                                  className="carouselcard__btn--edit"
                                >
                                  Delete
                                </button>{" "}
                              </div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#E9EBED",
                                paddingBottom: "1px",
                                position: "relative",
                                height: isErrorPopUp ? "140px" : "10rem",
                              }}
                            >
                              <div className="d-flex">
                                {/* <span className="imagecard__url">
                                  <input
                                    value={
                                      itemData[index]
                                        ? itemData[index].final_url
                                        : ""
                                    }
                                    type="text"
                                    className="imagecard__input--headline"
                                    placeholder="Enter URL"
                                    onChange={(e) =>
                                      onChange(
                                        "final_url",
                                        e.target.value,
                                        item
                                      )
                                    }
                                    onBlur={() => {
                                      changeMainDataOnBlur(itemData[index]);
                                    }}
                                    style={{
                                      backgroundColor: "#E9EBED",
                                      width: "71%",
                                    }}
                                  />
                                </span>

                                {dragData.errors && dragData.errors.url && (
                                  <SmallErrorText
                                    style={{
                                      display: "block",
                                      marginTop: "10px",
                                      backgroundColor: "#faebd7",
                                      width: "fit-content",
                                      marginLeft: "5%",
                                      fontWeight: 500,
                                    }}
                                    text={dragData.errors.url}
                                  />
                                )} */}

                                <div
                                  className="imagecard__btn--cta--container imagecard__btn--cta--container--carousel"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "1.5rem",
                                  }}
                                >
                                  <button
                                    className="imagecard__btn--cta"
                                    style={{
                                      fontSize: "0.7rem",
                                    }}
                                    onClick={() => {
                                      setDeleteMediaIndex(item.unique_id);

                                      if (showCtaOptions) {
                                        setItemSelected(item);
                                        setShowCtaOptions(false);
                                        changeMainDataOnBlur(item);
                                      } else setShowCtaOptions(true);
                                    }}
                                  >
                                    {item.cta
                                      ? changeCtaValue(item.cta)
                                      : "Select CTA"}
                                  </button>

                                  {showCtaOptions &&
                                    deleteMediaIndex === item.unique_id && (
                                      <div>
                                        <div className="imagecard__ctaoptions--container carasoulpopup__imagecard--ctaoptions">
                                          {ctaList &&
                                            ctaList.map((ctaa) => (
                                              <span
                                                onClick={() => {
                                                  let cta = ctaa.value;
                                                  const object = {
                                                    ...item,
                                                    cta,
                                                  };
                                                  setItemSelected(object);
                                                  setShowCtaOptions(false);
                                                  changeMainDataOnBlur(object);
                                                  updateCarouselCtaData(cta);
                                                }}
                                              >
                                                {changeCtaValue(ctaa.label)}
                                              </span>
                                            ))}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>

                              {dragData.errors && dragData.errors.cta && (
                                <SmallErrorText
                                  style={{
                                    display: "block",
                                    marginTop: "10px",
                                    backgroundColor: "rgb(250, 235, 215)",
                                    fontSize: "12px",
                                    color: "rgb(145, 45, 43)",
                                    width: "fit-content",
                                    position: "absolute",
                                    right: "0%",
                                    top: "40px",
                                    fontWeight: 500,
                                  }}
                                  text={dragData.errors.cta}
                                />
                              )}

                              <span className="imagecard__span--message">
                                <input
                                  className="imagecard__input--headline"
                                  value={
                                    itemData[index]
                                      ? itemData[index].message
                                      : ""
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    onChange("message", e.target.value, item)
                                  }
                                  onBlur={() => {
                                    changeMainDataOnBlur(itemData[index]);
                                  }}
                                  style={{
                                    backgroundColor: "#E9EBED",
                                    width: "8rem",
                                  }}
                                  placeholder="Enter Headline"
                                />

                                {dragData.errors && dragData.errors.message && (
                                  <SmallErrorText
                                    style={{
                                      display: "block",
                                      marginTop: "10px",
                                      backgroundColor: "#faebd7",
                                      width: "fit-content",
                                      fontWeight: 500,
                                    }}
                                    text={dragData.errors.message}
                                  />
                                )}
                              </span>
                              <span
                                className="imagecard__span--message"
                                style={{
                                  position: "absolute",
                                  top: "35px",
                                  left: 0,
                                }}
                              >
                                <input
                                  className="imagecard__input--headline"
                                  value={
                                    itemData[index]
                                      ? itemData[index].description
                                      : ""
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    onChange(
                                      "description",
                                      e.target.value,
                                      item
                                    )
                                  }
                                  onBlur={() => {
                                    changeMainDataOnBlur(itemData[index]);
                                  }}
                                  style={{
                                    backgroundColor: "#E9EBED",
                                    width: "8rem",
                                  }}
                                  placeholder="Enter Description"
                                />

                                {dragData.errors &&
                                  dragData.errors.description && (
                                    <SmallErrorText
                                      style={{
                                        display: "block",
                                        marginTop: "10px",
                                        backgroundColor: "#faebd7",
                                        width: "fit-content",
                                        fontWeight: 500,
                                      }}
                                      text={dragData.errors.description}
                                    />
                                  )}
                              </span>
                              {isErrorPopUp ? (
                                <>
                                  {isError && isError.type === 1 ? (
                                    <div className="carousel-error-check">
                                      Instagram Image validation, Ration: 1:1 or
                                      1080X1080
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {isError && isError.type === 2 ? (
                                    <div className="carousel-error-check">
                                      Instagram video validation, Maximum
                                      duration 2 minutes
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {dragData.length !== 10 ? (
              <AddPostCard
                showSmall={true}
                setIsPopupVisible={setIsPopupVisible}
                audiences={audiences}
                carousel={true}
                handleCarousalPopOver={handleCarousalPopOver}
              />
            ) : (
              ""
            )}
          </DragDropContext>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarouselContainer;
