import React, { Component } from "react";
import HighCharts from "../../../../common/chart/ColumnBarChart";
import SliderContainer from "../../../../common/Slider";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  centerPadding: "20px",
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    }
  ]
};

let chartOptions = {
  chart: {
    type: "bar"
  },
  title: {
    text: "",
    x: -20 //center
  },
  xAxis: {
    categories: [""],
    title: {
      text: "Comparision"
    }
  },
  yAxis: {
    min: 0,
    labels: {
      overflow: "justify"
    }
  },
  tooltip: {
    formatter: function() {
      return this.series.name + " :" + this.y;
    }
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true
      }
    }
  },
  series: []
};

let LineChartOptions = {
  chart: {
    renderTo: "container",
    type: "spline"
  },
  title: {
    text: "",
    x: -20 //center
  },
  xAxis: {
    categories: []
  },
  yAxis: {
    title: {
      text: "Range"
    },
    min: 0
  },
  tooltip: {
    formatter: function() {
      return (
        "Value for " +
        this.series.name +
        " on" +
        "</b><br/>" +
        this.x +
        ": " +
        this.y
      );
    }
  },
  series: []
};

class ChartContainer extends Component {
  state = {
    lineChartData: [],
    cpaComparisionData: [],
    salesVolumeData: []
  };
  // Handle state and props change
  shouldComponentUpdate(nextProps, nextState) {
    const { campaignMetricData } = nextProps;

    // Handle state change
    if (nextState !== this.state) return true;

    // Handle campaign metric data
    if (campaignMetricData !== this.props.campaignMetricData) {
      // Handle line chart data
      let lineChartOptions = LineChartOptions;
      lineChartOptions = {
        ...lineChartOptions,
        xAxis: {
          categories: this.getCategories(campaignMetricData.data.chart_data)
        },
        series: this.getSeries(campaignMetricData.data.chart_data)
      };

      // Handle cpa camparision bar chart data
      let barCpaComparisionData = chartOptions;
      barCpaComparisionData = {
        ...barCpaComparisionData,
        // xAxis: {
        //   categories: this.getBarChartCategories(
        //     campaignMetricData.data.cpa_comparison_data
        //   )
        // },
        series: this.getBarSeries(campaignMetricData.data.cpa_comparison_data)
      };

      // Handle sales volumn camparision bar chart data
      let salesVolData = chartOptions;
      salesVolData = {
        ...salesVolData,
        // xAxis: {
        //   categories: this.getBarChartCategories(
        //     campaignMetricData.data.cpa_comparison_data
        //   )
        // },
        series: this.getBarSeries(campaignMetricData.data.sales_vol_data)
      };

      this.setState({
        lineChartData: lineChartOptions,
        salesVolumeData: salesVolData,
        cpaComparisionData: barCpaComparisionData
      });

      return true;
    }

    // default return false
    return false;
  }

  getChartDataKeys = chartData => {
    return chartData ? Object.keys(chartData) : [];
  };

  // Get line chart data categories
  getCategories = chartData => {
    const chartDataKeys = chartData ? Object.keys(chartData) : [];
    return (
      chartData &&
      chartDataKeys.length &&
      Object.keys(chartData[chartDataKeys[0]])
    );
  };

  // Get bar chart data categories
  getBarChartCategories = barData => {
    let categoryName = [];
    barData.length &&
      barData.map(val => {
        categoryName = [...categoryName, val.name];
      });
    return categoryName;
  };

  // Get Barchart data series
  getBarSeries = barData => {
    let seriesData = [];
    barData.length &&
      barData.map(val => {
        seriesData = [...seriesData, { name: val.name, data: [val.value] }];
      });
    return seriesData;
  };

  // Get line chart data series
  getSeries = chartData => {
    const chartDataKeys = chartData ? Object.keys(chartData) : [];
    let categories =
      chartData &&
      chartDataKeys.length &&
      Object.keys(chartData[chartDataKeys[0]]);
    let series = [];
    series =
      Array.isArray(chartDataKeys) &&
      chartDataKeys.map(chartKey => {
        let item = {
          name: chartKey,
          data:
            Array.isArray(categories) &&
            categories.map(key => chartData[chartKey][key])
        };
        return item;
      });
    return series;
  };

  render() {
    const { lineChartData, salesVolumeData, cpaComparisionData } = this.state;
    return (
      <>
        <div className="show_desktop">
          <div className="row">
            <div className="col-md-12">
              <div className="parentWrap">
                <div className="graph_col full_width">
                  <h4 className="text-center">
                    Historical Campaign ROI over month
                  </h4>
                  <div>
                    <HighCharts options={lineChartData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="parentWrap">
                <div className="graph_col full_width">
                  <h4 className="text-center">CPA Camparison</h4>
                  <div>
                    <HighCharts options={cpaComparisionData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="parentWrap">
                <div className="graph_col full_width">
                  <h4 className="text-center">Sale Volume Comparision</h4>
                  <div>
                    <HighCharts options={salesVolumeData} />
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
        <div className="graph_row width100 mob_view">
          <SliderContainer config={settings}>
            <div className="parentWrap">
              <div className="graph_col" style={{ width: "385px" }}>
                <h4 className="text-center">
                  Historical Campaign ROI over month
                </h4>
                <div>
                  <HighCharts options={lineChartData} />
                </div>
              </div>
            </div>
            <div className="parentWrap">
              <div className="graph_col" style={{ width: "385px" }}>
                <h4 className="text-center">CPA Camparison</h4>
                <div>
                  <HighCharts options={cpaComparisionData} />
                </div>
              </div>
            </div>
            <div className="parentWrap">
              <div className="graph_col" style={{ width: "385px" }}>
                <h4 className="text-center">Sale Volume Comparision</h4>
                <div>
                  <HighCharts options={salesVolumeData} />
                </div>
              </div>
            </div>
          </SliderContainer>
        </div>
      </>
    );
  }
}

export default ChartContainer;
