import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";
import axios from "axios";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/user"
const shortBase = VERSION_PREFIX + "/user"

export function onEditUser(id, payload) {
    return http.put(prefix + '/' + id, payload)
}

export function onUserAdminSwitch(id, payload) {
    return PeasyApi.post(shortBase + '/adminSwitch', payload)
}

export function onUserRemoveActivateAccountFb() {
    return http.delete(prefix + '/removeActivateAccountFb')
}

export function onUserActivateAccountFb(payload) {
    return http.post(prefix + '/activateAccountFb', payload)
}

export function onUserFbLogin(payload) {
    return http.post(prefix + '/fbLogin', payload)
}

export function onUserRemoveActivateAccountAdw() {
    return http.delete(prefix + '/removeActivateAccountAdw')
}

export function onUserActivateAccountAdw(payload) {
    return http.post(prefix + '/activateAccountFb', payload)
}


export function onUserCheckFb(id) {
    return axios.get(base + '/check/user/' + id)
}

export function onCreateUserSubscription(payload) {
    return axios.post(base + '/create/userSubscription', payload)
}

export function onCreateUser(payload) {
    return axios.post(base + '/create/user', payload)
}


export function onGetUserBalance(payload) {
    return http.post(base + '/check/user/balance', payload)
}

