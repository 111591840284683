import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Sidebar from "../../SideBar";
import http from "../../../../shared/http-request";
import { BASE_URL } from "../../../../StaticArray/StaticArray";
import FbAdsTable from "./fbAdsTable/FbAdsTable";
import GoogleAdsTable from "./googleAdsTable/GoogleAdsTable";
import "./ConnectionSetup.css";
import { Grid } from "@material-ui/core";
import "../../../../translations/i18n";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

const ConnectionSetup = () => {
  const [accountData, setAccountData] = useState({});

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    if (
      JSON.parse(localStorage.getItem("user_data")).account_type === "prepaid"
    ) {
      history.push("/dashboard");
    }
    getAccountDetails();
  }, []);

  const getAccountDetails = () => {
    http
      .get(`${BASE_URL}api/get-account-meta`)
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          setAccountData(res.data.data);
        } else
          toast.error(res.data.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="connection-setup-box">
      <div className="connection-setup-table-container">
        <h2 style={{ marginLeft: "1.5%" }}>{t("settings_connectionSetup")}</h2>

        <Grid container>
          <Grid item xs={12} md={6}>
            <FbAdsTable
              getAccountDetails={getAccountDetails}
              accountData={accountData}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GoogleAdsTable
              getAccountDetails={getAccountDetails}
              accountData={accountData}
              loading={loading}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ConnectionSetup;
