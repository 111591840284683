import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import axios from "axios";
import PeasyApi from "../../peasy.api";


const base = BASE_URL + VERSION_PREFIX


export function onFbLogin(payload) {
    return axios.post(base + '/fb/login', payload)
}



