import React, { useState } from "react";
import { toast } from "react-toastify";
import validator from "validator";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import TagManager from "react-gtm-module";
import { Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { onValidateRegisterFields } from "../../../utils/network/lib/validate";
import { onCreateUser } from "../../../utils/network/lib/user";
import { EVENT_TYPE_EXTERNAL } from "../../../utils/eventConstants";
// import { attributeHelpers } from "../../../utils/simplifierHelper";
import { useSelector } from "react-redux";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function RegistrationDetails({
  setForm,
  formData,
  navigation,
  isFreeUser,
  isExternalRoute,
  globalId,
}) {
  let query = useQuery();
  const queryObj = Object.fromEntries(query);
  const { t } = useTranslation();
  const {
    email,
    fullName,
    mobileNo,
    password,
    confirmPassword,
    fbId,
    currency,
    accesstoken,
  } = formData;
  // console.log(formData);
  const [loading, setLoading] = useState(false);
  const { next } = navigation;

  // computed
  const checkParams = () =>{
    const isSourceGrant = queryObj.source === 'grant';
    const isEmailInParams = queryObj.email ? true : false;
    const isExternalGrantWIthEmail = isSourceGrant && isEmailInParams && isExternalRoute;
    return {
      isSourceGrant,
      isEmailInParams,
      isExternalGrantWIthEmail,
    }
  }

  const checkUser = async () => {
    let validate = false;
    try {
      const { data, status } = await onValidateRegisterFields({
        email: email,
      });
      if (status === 200) {
        validate = true;
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
    return validate;
  };

  const saveUser = async () => {
    try {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      const {ps_access_token,ps_account_id,stripe_customer_id} = params
      const newUser = {
        fullName: fullName,
        email: email,
        mobileNo: mobileNo,
        password: password,
        fbId: fbId,
        currency: currency,
        access_token: accesstoken,
        ps_access_token,
        ps_account_id,
        stripe_customer_id
      }
      if (checkParams().isSourceGrant) {
        newUser.meta_data = {source: 'grant'};
      }
      
      // console.log(newUser,"before createUser");
      const { data, status } = await onCreateUser({...newUser});
      // console.log("Register response ",data);
      if (status === 200) {
        setForm({ target: { name: "id", value: data.id } });

        /*
        // START: update onboard_class attribute for grant user 
        if (checkParams().isExternalGrantWIthEmail) {
          const {peasy_marketing} = attributeHelpers.onboardClassValues;
          await attributeHelpers.update_onboard_class_value(newUser.email,newUser.fullName,peasy_marketing.signUpWithSpecialLink);
        }
        // END: update onboard_class attribute for grant user 
        */

        /**Gist register new user */
        if (globalId) {
          window.gist.identify(data.id, {
            email: globalId,
            Regemail: email,
            grant: true,
            name: fullName,
            phone_number: mobileNo,
          });
        } else {
          window.gist.identify(data.id, {
            email: email,
            name: fullName,
            phone_number: mobileNo,
          });
        }
        window.gist.track("Sign Up", { email: email });

        /**Tagmanger */
        const args = {
          dataLayer: {
            event_custom: "register_account",
            user_email: email,
            /* can pass more variables here if needed */
          },
        };
        TagManager.dataLayer(args);
        next();
        return;
      }
    } catch (err) {
      try {
        // console.log("Register calling setUser");
        window.customSetUser(email, fullName);
        window.$peasy.triggerEvent({
          event_name: "error",
          event_params: { id: "1", type: EVENT_TYPE_EXTERNAL },
        });
      } catch (err2) {
        console.log("Err setting id ", email, fullName);
      }
    } finally {
    }
    toast.error("Failed to register user.");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (email.trim().length === 0) {
      return toast.error("Please provide the email.");
    } else if (!validator.isEmail(email)) {
      return toast.error("Please provide valid email.");
    } else if (!(await checkUser())) {
      return toast.error("User is already register with the given email.");
    } else if (fullName.trim().length === 0) {
      return toast.error("Please provide the name.");
    } else if (mobileNo.trim().length < 10 || !validator.isNumeric(mobileNo)) {
      return toast.error("Please provide valid mobile number.");
    } else if (password.trim().length == 0) {
      return toast.error("Please provide password.");
    } else if (password.trim().length < 5) {
      return toast.error("Password should have atleast 5 characters.");
    } else if (password.trim() !== confirmPassword.trim()) {
      return toast.error("Confirm password not matching.");
    } else if (currency.trim().length == 0) {
      return toast.error("Please provide Curency.");
    }
    saveUser();
  };

  return (
    <form>
      <div style={{ textAlign: "center" }}>
        <h2>Register your free Account</h2>
        <br />
        <h3>
          {isFreeUser && "Peasy is Free Forever."} No Credit Card Required.
        </h3>
        <br />
      </div>

      <div className="webflow-register">
        <label className="caption register">
          Name
          <span className="star-required">*</span>
        </label>
        <input
          type="text"
          className="text-field w-input"
          placeholder="e.g Andrew Smith"
          name="fullName"
          value={fullName}
          onChange={setForm}
        />

        <label className="caption register">
          Whatsapp No.
          <span className="star-required">*</span>
        </label>
        <input
          type="text"
          className="text-field w-input"
          placeholder="e.g 9876543210"
          name="mobileNo"
          value={mobileNo}
          onChange={setForm}
        />

        <label className="caption register">
          Email
          <span className="star-required">*</span>
        </label>
        <input
          type="email"
          autoComplete="new-password"
          className="text-field w-input"
          maxLength="256"
          placeholder="e.g Name@businessname.com"
          name="email"
          value={email}
          onChange={setForm}
          disabled={checkParams().isExternalGrantWIthEmail}
        />
        <label className="caption register">
          Currency
          <span className="star-required">*</span>
        </label>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currency}
          label="Currency"
          className="text-field w-input"
          onChange={(e) =>
            setForm({ target: { name: "currency", value: e.target.value } })
          }
        >
          <MenuItem value="IDR">{t("settings_indonesiaRupiah")}</MenuItem>
          <MenuItem value="MYR">{t("settings_malaysiaRinggit")}</MenuItem>
          <MenuItem value="SGD">{t("settings_singaporeDollar")}</MenuItem>
          <MenuItem value="USD">{t("settings_USDollar")} </MenuItem>
          <MenuItem value="VND">{t("settings_vietnamDong")}</MenuItem>
          <MenuItem value="JPY">{t("settings_japaneseYen")}</MenuItem>
        </Select>
        <label className="caption register">
          Password
          <span className="star-required">*</span>
        </label>
        <input
          type="password"
          autoComplete="new-password"
          className="text-field w-input"
          name="password"
          value={password}
          onChange={setForm}
        />

        <label className="caption register">
          Confirm Password
          <span className="star-required">*</span>
        </label>
        <input
          type="password"
          className="text-field w-input"
          name="confirmPassword"
          value={confirmPassword}
          onChange={setForm}
        />
        <br />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <span>
            By creating an account, you agree to the Peasy Customer Terms of
            Services and Privacy Policy
          </span>
          <button
            type="submit"
            className="btn btn-outline-primary"
            onClick={handleFormSubmit}
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
}

export default RegistrationDetails;
