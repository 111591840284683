import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPackages,
  addPackage,
  deletePackage,
  deleteFilterPackage,
  updatePackage,
} from "../../../redux/actions/packageAction";
import "./Features.css";
import PackageFeatureRow from "./PackageFeatureRow";
import PackageAddModal from "./PackageAddModal";
import DeleteConf from "../../../common/DeleteConfModal";
import { getAllPackagePermissions } from "../../../redux/actions/permissionActions";
import { Spinner } from "reactstrap";

const PackageSettings = (props) => {
  const mockRow = {
    permission: {},
    parameters: "",
    feature: {},
    id: "",
  };
  const [activePackage, setActivePackage] = useState("hidden");
  const [rows, setRows] = useState([mockRow]);
  const [checkedItems, setCheckedItems] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [deleteConf, setDeleteConf] = useState(false);
  const [perErr, setPerErr] = useState(false);
  const [selectedId, setSelectedId] = useState({});
  const [packagesData, setPackagesData] = useState([]);
  const packages = useSelector((state) => state.packages.allPackages);
  const loading = useSelector((state) => state.packages.loading);
  const features = useSelector((state) => state.features.allFeatures);
  const permissions = useSelector((state) => state.permissions.allPermissions);
  const { addFilter, dataUpdate } = useSelector((state) => state.packages);
  const dispatch = useDispatch();
  useEffect(() => {
    setPackagesData(packages);
    console.log("packagesData", packages);
  }, [loading]);
  useEffect(() => {
    dispatch(getAllPackages());
    setActivePackage(null);
  }, [addFilter]);
  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleDelete = (toggle) => {
    const selectedPackages = Object.keys(checkedItems).filter(
      (data) => checkedItems[data] === true
    );
    console.log("selectedPackages", selectedPackages);

    if (selectedPackages.length === 0) {
      return;
    } else {
      if (toggle) {
        setDeleteConf(!deleteConf);
      } else {
        selectedPackages.map((item) => {
          dispatch(deletePackage(item));
        });
        setDeleteConf(!deleteConf);
      }
    }
  };

  const addMoreRows = () => {
    setRows([...rows, mockRow]);
  };

  const removeRows = (key, id) => {
    const arr = rows.filter((item, idx) => {
      return item.id !== id;
    });
    const list = packagesData.filter((item) => {
      if (item.id === selectedId.id) {
        const arr = item.permission_packages.filter((perPac) => {
          return perPac.id !== id;
        });
        item.permission_packages = arr;
      }
      return item;
    });
    setRows(arr);
    setPackagesData(list);
    // setActivePackage(null);
    dispatch(deleteFilterPackage(id));
  };

  const toggleAdd = (_) => {
    setIsAdd((isAdd) => !isAdd);
  };

  const handleAddPackage = (e, packagePayload) => {
    e.preventDefault();
    if (!!packagePayload.permission_ids) {
      setIsAdd(false);
      dispatch(addPackage(packagePayload));
    } else {
      setPerErr(!perErr);
    }
  };

  const handleAccordian = (data) => {
    // console.log("data", !activePackage[`accordian-${data.id}`])
    if (activePackage !== `accordian-${data.id}`) {
      setActivePackage(`accordian-${data.id}`);
    } else {
      setActivePackage(null);
    }
    setSelectedId(data);
    if (data.permission_packages && data.permission_packages.length > 0) {
      let arr = [mockRow];
      data.permission_packages.map((item) => {
        arr = arr.concat(item);
      });
      setRows(arr);
    } else {
      setRows([mockRow]);
    }
  };

  const handleOnchange = ({ target: { name, value } }, id) => {
    const packagesList = packagesData.map((item) => {
      if (item.id === id) return { ...item, [name]: value };
      return item;
    });
    setPackagesData(packagesList);
  };

  const handleUpdate = (event, id) => {
    const packagesList = packagesData.filter((item) => item.id === id);
    if (packagesList.length === 0) {
      return;
    }
    dispatch(updatePackage(packagesList[0], id));
  };
  return (
    <>
      <button
        style={{ float: "right", marginBottom: "20px" }}
        className="btn btn-primary"
        onClick={toggleAdd}
      >
        Add
      </button>
      <button
        style={{ float: "right", marginBottom: "20px" }}
        className="btn btn-primary mr-10"
        onClick={() => handleDelete(true)}
      >
        Delete
      </button>
      <div
        className="wrapper"
        style={{ padding: "0px", maxWidth: "800px", overflow: "auto" }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <table className="tableWrapper">
            <thead>
              <tr>
                <th>Package ID</th>
                <th>Package Name</th>
                <th>Price</th>
                <th>Maximum Spent</th>
                <th>Max Data</th>
                <th>Max Retention</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {packagesData.map((item) => {
                return (
                  <tr>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAccordian(item)}
                    >
                      {item.id}
                    </td>
                    <td>
                      <input
                        onChange={(e) => handleOnchange(e, item.id)}
                        onBlur={(e) => handleUpdate(e, item.id)}
                        type="text"
                        name="name"
                        value={item.name}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => handleOnchange(e, item.id)}
                        onBlur={(e) => handleUpdate(e, item.id)}
                        type="text"
                        name="price"
                        value={`${item.price}`}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => handleOnchange(e, item.id)}
                        onBlur={(e) => handleUpdate(e, item.id)}
                        type="text"
                        name="ad_spend_limit"
                        value={item.ad_spend_limit}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => handleOnchange(e, item.id)}
                        onBlur={(e) => handleUpdate(e, item.id)}
                        type="text"
                        name="max_data"
                        value={item.max_data}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => handleOnchange(e, item.id)}
                        onBlur={(e) => handleUpdate(e, item.id)}
                        type="text"
                        name="max_retention"
                        value={item.max_retention}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => handleOnchange(e, item.id)}
                        onBlur={(e) => handleUpdate(e, item.id)}
                        type="text"
                        name="name"
                        type="checkbox"
                        name={item.id}
                        checked={checkedItems[item.id]}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                );
              })}

              {activePackage === `accordian-${selectedId.id}` && (
                <tr>
                  <td colspan="8">
                    <table className="accordion">
                      <thead>
                        <tr>
                          <th>Feature ID</th>
                          <th>Feature Name</th>
                          <th>Permission</th>
                          <th>Parameter</th>
                          <th>Action</th>
                          <th>
                            <button
                              style={{ float: "right" }}
                              className="btn btn-primary"
                              onClick={addMoreRows}
                            >
                              {" "}
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <PackageFeatureRow
                            key={index}
                            id={selectedId.id}
                            features={features}
                            permissions={permissions}
                            row={row}
                            removeRows={removeRows}
                            data={selectedId}
                          />
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <PackageAddModal
          isOpen={isAdd}
          toggle={toggleAdd}
          handleAddPackage={handleAddPackage}
          className="packageAdd"
          perErr={perErr}
        />
        <DeleteConf
          isOpen={deleteConf}
          toggle={() => setDeleteConf((deleteConf) => !deleteConf)}
          onConfirm={() => handleDelete(false)}
        />
      </div>
    </>
  );
};

export default PackageSettings;
