import { Http } from "@material-ui/icons";
import { BASE_URL } from "../StaticArray/StaticArray";
import http from "../shared/http-request";

const get = async (url) => {
  try {
    const { data, status } = await http.get(BASE_URL + url);
    if (status === 200) {
      return data;
    } else {
      throw Error("GET-Request:: Bad Response", status, data);
    }
  } catch (err) {
    throw Error("GET-Request::", err);
  }
};

const post = async (url, object) => {
  try {
    const { data, status } = await http.post(BASE_URL + url, object);
    if (status === 200) {
      return data;
    } else {
      throw Error("POST-Request:: Bad Response", status, data);
    }
  } catch (err) {
    throw err;
  }
};

const put = async (url, object) => {
  try {
    const { data, status } = await http.put(BASE_URL + url, object);
    if (status === 200) {
      return data;
    } else {
      throw Error("PUT-Request:: Bad Response", status, data);
    }
  } catch (err) {
    throw Error("PUT-Request::", err);
  }
};

const del = async (url, object) => {
  try {
    const { data, status } = await http.delete(BASE_URL + url, object);
    if (status === 200) {
      return data;
    } else {
      throw Error("DELETE-Request:: Bad Response", status, data);
    }
  } catch (err) {
    throw err;
  }
};


const PeasyApi = {
  get,
  post,
  put,
  del,
  };

export default PeasyApi;
