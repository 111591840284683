import React, { useState } from "react";
import "./Description.css";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const Description = ({
  text,
  lessLimit,
  isEditable,
  onEditClick,
  onChange,
  onBlur,
  commonStyle,
}) => {
  const { t } = useTranslation();

  const [showFullDescription, setShowFullDescription] = useState(false);

  if (!showFullDescription && !isEditable)
    return (
      <span
        onClick={() => onEditClick()}
        style={commonStyle}
        className="description__span"
      >
        {!text && "Enter Primary Text"}
        {text && text.substr(0, lessLimit || 20)}
        {text && text.length > 40 && (
          <span
            onClick={() => setShowFullDescription(true)}
            className="description__span--more"
          >
            . . . {t("campaign_seeMore")}
            {/* {console.log(text)} */}
          </span>
        )}{" "}
      </span>
    );

  if (!showFullDescription && isEditable) {
    return (
      <input
        autoFocus={true}
        className="description__textarea"
        value={text}
        type="text"
        placeholder={t("campaign_enterPrimaryText")}
        onChange={(e) => onChange("headline", e.target.value)}
        onBlur={() => {
          onBlur();
        }}
        style={{ width: "90%", marginLeft: "5%", marginTop: "10px" }}
      />
    );
  }

  if (showFullDescription)
    return (
      <span style={commonStyle} className="description__span">
        {isEditable ? (
          <React.Fragment>
            <textarea
              className="description__textarea"
              value={text}
              type="text"
              onChange={(e) => onChange("headline", e.target.value)}
              onBlur={() => {
                onBlur();
              }}
              style={{ width: "90%", marginLeft: "5%", marginTop: "10px" }}
            />
          </React.Fragment>
        ) : (
          <span onClick={() => onEditClick()}>
            {/* <i
              style={{
                position: "absolute",
                fontSize: "10px",
                right: "-15px",
                cursor: "pointer"
              }}
              className="far fa-edit"
              onClick={() => onEditClick()}
            /> */}
            {text}
            <span
              onClick={() => setShowFullDescription(false)}
              className="description__span--more"
            >
              . . . {t("campaign_seeLess")}
            </span>{" "}
          </span>
        )}
      </span>
    );
};

export default Description;
