import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/prepaidSetting"
const prepaidPrefix = base + "/prepaid"

export function onGetPrepaidSettings() {
    return http.get(prefix)
}

export function onPrepaidSettingsDeleteRow(id) {
    return http.delete(prefix + "/" + id)
}

export function onUpdatePrepaidSetting(id, payload) {
    return http.put(prefix + "/" + id, payload)
}

export function onPostPrepaidSetting(payload) {
    return http.put(prefix, payload)
}

export function onGetPrepaidSettingsActiveAccounts(id) {
    return http.get(prefix + "/" + id)
}

export function onGetPrepaidFbAccounts() {
    return http.get(prepaidPrefix + "/fbAdAccounts")
}

export function onPostPrepaidAdConnection(payload) {
    return http.post(base + "/prepaidAdConnection", payload)
}
export function onDeletePrepaidAdConnection(id, activeAccountId) {
    return http.delete(base + "/prepaidAdConnection/" + id + "/" + activeAccountId)
}

export function onGetPrepaidUnusedCurrency() {
    return http.get(prepaidPrefix + "/unusedCurrency")
}
