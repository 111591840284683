import React, { useState, useEffect } from "react";
import Select from "react-select";
import http from "../../../shared/http-request";
import CustomSelectOption from "../customSelectOption/CustomSelectOption";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import { onAudienceSearchInterest } from "../../../utils/network/lib/audience";

const GoogleAudienceAffinity = ({
  getSelectValue,
  onSelectChange,
  selectStyles,
  interestsList,
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState();

  useEffect(() => {
    setOptions(interestsList);
  }, [interestsList]);

  let timer = 500;

  const onInputChange = (query) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      if (query.length === 0) return;
      const res = await onAudienceSearchInterest({
        params: {
          q: query,
        },
      });
      if (res.status === 200) {
        let array = [];
        res.data.data.map((interest) => {
          const object = {};
          const values = interest.value.split("|");
          object.value = values[0];
          object.label = values[1];
          array = [...array, object];
        });
        setOptions(array);
      }
    }, 500);
  };

  return (
    <div style={{ marginTop: "40px" }} className="fbincludeaudience__container">
      <div className="fbincludeaudience__heading--container">
        <div className="fbincludeaudience__heading">
          <img
            src="/images/GDN_1GDN.png"
            style={{ width: "30px", height: "30px", marginRight: "2%" }}
            alt=""
          />
          {t("campaign_googleAffinity")}
        </div>
      </div>
      <Select
        isMulti
        options={options}
        components={{ Option: CustomSelectOption }}
        className="fbincludeaudience__input--select"
        placeholder={t("campaign_narrowAud")}
        value={getSelectValue("affinity")}
        onChange={(e) => {
          onSelectChange("affinity", e);
        }}
        onMenuClose={() => setOptions(interestsList)}
        onInputChange={(e) => onInputChange(e)}
        styles={selectStyles}
      />

      <p className="fbincludeaudience__para">
        {t("campaign_typeInInterest")} <strong>{t("campaign_must")}</strong>{" "}
        {t("campaign_metToNarrowAud")}
      </p>
    </div>
  );
};

export default GoogleAudienceAffinity;
