export const TRANSLATIONS_EN = {
  dashboard: "Dashboard",
  settings: "Settings",
  ecommerce: "E-Commerce",
  campaigns: "Campaigns",
  contacts: "Contacts",
  help: "Help",
  translate: "Language",

  // toast.errors
  brand_selectToContinue: "Please select audience(s) to continue",
  brand_selectCategoryToContinue: "Please select a category to continue",
  brand_reachNotGreater: "Reach cannot exceed 100",
  brand_qualifiedNotGreater: "Qualified cannot exceed 100",
  brand_salesNotGreater: "Sales cannot exceed 100",
  brand_QualNotGrtrThanRch: "Qualified should not be greater than Reach",
  brand_salesNotGrtrThanQual: "Sales should not be greater than Qualified",
  brand_brandName: "Please enter your business name to continue",
  brand_selectPixel: "Please select pixel(s) to continue",
  brand_selectCatalog: "Please select catalog to continue",
  brand_selectPage: "Please select a page to continue",
  brand_notValid: "This is not a valid url.",
  brand_entrUrl: "Please enter a url to continue",
  brand_entrValidUrl: "Please enter a valid url",
  brand_enterDistributn: "Please enter distribution Method",

  // Common keywords
  brand_back: "Back",
  brand_continue: "Continue",
  brand_next: "Next",
  brand_connect: "Connect",
  brand_enterContinue: "Press Enter to continue",
  brand_update: "Update",
  brand_cancel: "Cancel",
  brand_error: "Opps! Something went wrong while performing this action.",

  campaign_back: "Back",
  campaign_next: "Next",
  campaign_none: "None",
  campaign_of: "of",
  campaign_enterAmount: "Please specify your Campaign’s Budget",

  settings_back: "Back",
  settings_save: "Save",
  settings_submit: "Submit",
  settings_cancel: "Cancel",

  // Add Audience
  brand_selectAud: "Select Audiences for this business profile ",

  // CreateBrandCategory
  brand_descNature: "How would you describe the nature of your business?",
  brand_questionDesc:
    "Please select an industry that best describes your product and services. We assign different strategies, benchmark and recommendations customised for each industry. Our recommendations are personalized based on your past campaigns, Facebook pages and website data. If you do not have sufficient data for our AI, we will augment the generic recommendations from industry peers.Peasy has a growing pool of collective learning data for 26 different industries.",
  brand_brandCategory: "Select a category which best describe this business",

  // CreateFbconnect
  brand_adHeading: "Let’s Connect your Ads account",
  brand_adtext:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla massa dolor, condimentum a bibendu at, varius vel orci. Aenean nulla felis, convallis eget magna at",
  brand_vidIntro: "Video introduction",
  brand_connectBtn: "Click the button below to connect your account",
  brand_fbAd: "Facebook Ads",
  brand_googleAd: "Google Ads",

  // CreateBrandLastStep
  brand_aiTypo: "We are in the midst of creating and training your own AI",
  brand_alertDialog:
    "If you have ran campaigns previously, please wait while our AI accumulate and analyzes your past campaign data. Peasy will use these learnings to personalize recommendations for your campaigns. The learning is complete when AI meter in your business profile increases from 0mb onwards. That meter indicates data amount ingested to create and train your personal AI",
  brand_articleHeading:
    "For more information on funnel analysis and recommended optimization, please check out our article ",
  brand_heading: "Based on your details",
  brand_dataHeading: "Your Data",
  brand_formTitle: "Tell us a bit about your offline sales",
  brand_averageTitle: "On average for every 100 leads generated, how many:",
  brand_salesDataHeadng: "Offline Sales Data",
  brand_backToBrand: "Back to Business Profile Setup",
  brand_benchmark: "Benchmark for",
  brand_popupHelperTextReach:
    "Leads that are contactable (valid working phone number or email)",
  brand_popupHelperTextQualified:
    "Leads that matches your target profile and have genuine interest",
  brand_popupHelperTextSales: "Leads which convert into paying customers.",
  brand_updateText: "Update Business Profile",
  brand_createText: "Create New Business Profile",

  //  createBrandName
  brand_BPName: "Business Profile Name",
  brand_questionDescription:
    "Create a distinct name to identify this business profile. You can use your company’s registered name (Acme Sdn Bhd) or the product division (Acme’s Distributor Business).",
  brand_typeBrand: "Type in your business name here...",

  // CreatebrandPixels
  brand_pixelEvent: "Select a Pixel Event",
  brand_pixelBrand: "Select an Pixel event for this business",

  // CreatebrandPixels
  brand_catalogEvent: "Select a Catalog",
  brand_catalogBrand: "Select a Catalog for this business",

  // createBrandSelectFbPage
  brand_selectFbPage: "Select your business’ Facebook page",
  brand_FbPageList: "Available Facebook Pages",

  // createBrandWebsite
  brand_webUrl: "Please key in your Website URL.",
  brand_questionDescriptionWebsite:
    "Don’t have a website? Don’t worry, you can use a competition or product review website, our AI will scan for relevant keywords and use that to personalize recommendations for your campaigns.",

  // createDistributionMethod
  brand_selectcustomer: "Select one target customer for this business profile",
  brand_businessToBusiness: "Business to Business",
  brand_otherBusiness: "I want to sell to other businesses.",
  brand_businessToDistributor: "Business to Distributor",
  brand_newDistributors: "I want to recruit new distributors and agents",
  brand_businessToConsumer: "Business to Consumer",
  brand_newCustomer: "I want to acquire new customers.",

  // CAMPAIGN

  // toast.errors
  campaign_endDateAfterStartDate: "End Date Must Be After Start Date !",
  campaign_MoreThanFourdays: "Duration Must Be More Than 4 Days !",
  campaign_StartDateLessThanEndDate:
    "Start Date Should Be Less Than End Date !",
  campaign_selectLocation: "Please Select Atleast One Location !",
  campaign_name: "Please Enter Campaign Name !",
  campaign_nameMorethanTenChar:
    "Campaign Name Should Be More Than 10 Characters !",
  campaign_selectChoice: "Please select a choice",
  campaign_phoneFieldNoneerror: "Phone field cannot be none.",
  campaign_formFieldMappingfailed: "Failed to get form field mapping.",
  campaign_formFieldSaveError: "Failed to save form field values.",
  campaign_noListData: "Opps! Lead list data not found",
  campaign_gettingAccIdFailed: "Failed to get account id, check with admin",
  campaign_selectOnePixelevent: "Please select a pixel event !",
  campaign_enterTargetValue: "Please enter the target value",
  campaign_selectRecommendatnMethod: "Please select a recommendation method",
  campaign_budgetMinimumError: "Minimum budget should be ",
  campaign_budgetMoreThanBalance: "Campaign budget is more than your balance",
  campaign_selectChoice: "Please select a choice",
  campaign_defaultNotificatn: "Default Notification !",
  campaign_successNotificatn: "Success Notification !",
  campaign_errorNotificatn: "Error Notification !",
  campaign_warningNotificatn: "Warning Notification !",
  campaign_infoNotificatn: "Info Notification !",
  campaign_customNotificatn: "Custom Style Notification with css class!",
  campaign_selectObjective: "Please select an objective!",
  campaign_minBudget: "Minimum budget should be ",
  campaign_budgetMoreThanBalance:
    "Campaign budget exceed available ad spent limit",
  campaign_fillAccurateTarget: "Please set an accurate target",
  campaign_selectPlatform: "Please select a Platform !",
  campaign_selectPixelEvent: "Please select a pixel event !",

  // CampaignEndDate
  campaign_campaignDate: "What's your campaign end date?",
  campaign_startDate: "Start Date",
  campaign_endDate: "End Date",

  // campaignLocation
  campaign_targetLocation: "Campaign Target Location?",
  campaign_creatingCampaignText: "Creating Campaign...",
  campaign_createCampaignText: "Create Campaign",
  campaign_enterTargetLocation: "Enter Campaign Target Location...",

  //   CampaignName
  campaign_headingCampaignName: "Campaign Name",
  campaign_enterCampaign: "Enter Campaign Name...",

  // selectFunnelType
  campaign_currentFunnelLabel: "Your Current Funnel:",
  campaign_explanatnLabel: "Explanation:",
  campaign_fullFunnelExplanation:
    "Full-funnel marketing takes into account your current funnel (leakages, breadth) against your sales target. Using the full funnel option, Peasy may automatically create multiple campaigns with different objectives, each targeting different phases of the funnel. Peasy will automatically distribute your marketing budget across these campaigns to ensure maximum sales and effectiveness. Using the full-funnel approach, you save time from manual campaign planning and ensure a greater probability of achieving your sales target.",
  campaign_strategyLabel: "How would you like Peasy to create your strategy?",
  campaign_fullFunnelHeading: "Full Funnel ",
  campaign_fullFunnelcardDescription:
    "AI may create multiple COLD, WARM and HOT campaigns. Each targeting a different phase within your funnel. Our AI will plan and create a digital strategy.",
  campaign_singleFunnelHeading: "Single Campaign",
  campaign_singleFunnelCardDescription:
    "Create a single campaign with a user-determined objective. AI does not take upper funnel conditions into consideration during prediction. Recommended only for advanced user.",

  // formFieldMapping
  campaign_mapFormHeading: "Map your form fields",
  campaign_phone: "Phone",
  campaign_name: "Name",
  campaign_email: "Email",

  // LeadForm
  campaign_selectExistingForm: "Select an existing Lead Form",
  campaign_createNewform: "Create New form",
  campaign_leadFormsList: "Available lead forms",

  // Method
  campaign_recommendText: "I would like Peasy to recommend based on",
  campaign_selectMethod: "Select a method",
  campaign_target: "Target",
  campaign_budget: "Budget",
  campaign_fillIn: "Fill in",
  campaign_minSpent: "The minimum spent is",

  // TargetCategory
  campaign_wantToTarget: "I want to target:",
  campaign_newCustomers: "New customers",
  campaign_setToFindCustomers: "Set my campaign to find new customers",
  campaign_driveRepeatSalesOrUpsell: "Drive repeat sales or upsell",
  campaign_setToExistingCustomers:
    "Set my campaign to target existing customers",
  campaign_both: "Both",
  campaign_setToNewExistingCustomers:
    "Set my campaign to target new and existing customers",

  // objectives
  campaign_reachImpression: "Reach & Impression",
  campaign_awarenessBrand: "I want more awareness and brand recall",
  campaign_clicks: "Clicks",
  campaign_moreClicks: "I want to get more clicks on my ads",
  campaign_trafficOrReply: "Traffic/Reply",
  campaign_moreVisitOrreplies:
    "I want more people to visit my website or reply my messenger",
  campaign_conversion: "Conversion",
  campaign_valuableActions:
    "I want people to take valuable actions in my website",
  campaign_engagement: "Engagement",
  campaign_lead: "Leads",
  campaign_valuableLeads: "I want valuable leads for my product",
  campaign_chooseObjectives: "Choose your objectives",

  // spendCampaign
  campaign_amountforcampaign:
    "How much would you like to invest on this campaign",
  campaign_minspent: "The minimum spent is",
  campaign_typeAmount: "Type [ amount ] ...",

  // targetCampaign
  campaign_targetLabel: "What is your target ",
  campaign_enterTarget: "Enter your target value",
  campaign_typeNumberOf: "Type the number of ",
  campaign_here: " here",

  // FbMessenger
  campaign_whereToTakeBusiness:
    "Do you want your ad to lead to a website or FB messenger?",
  campaign_fbMessenger: "Fb Messenger",
  campaign_website: "Website",

  // pixel_event
  campaign_selectPixelEvent: "Select a Pixel event",
  campaign_pixelEventList: "Available pixel events",

  // SETTINGS

  // toast.errors
  settings_userProfileEdited: "User profile edited successfully",
  settings_accDeactivatnSuccessful: "Account deactivated successfully",
  settings_accActivatnsuccessful: "Account activated successfully",
  settings_userUnsupported: "User unsupported for this functionality",
  settings_copied: "Copied!",
  settings_smthngWentWrong: "Oops! An error has occurred.",
  settings_APIKeyGenerated: "Successfully generated the API key",
  settings_addedAPIInList: "Successfully added this API in list",
  settings_noBusinessProfile: "No Business Profiles Present",
  settings_selectProfileFirst: "Please select a profile first",

  // SideBar
  settings_userProfile: "User Profile",
  settings_connectnSetup: "Connection Setup",
  settings_usersAndTeam: "Users & Team",
  settings_billing: "Billing",
  settings_apiKey: "API Key",

  // UserProfile
  settings_phoneNoLength: "Phone number must be 10 digits long",
  settings_enterValidPhoneNo: "Please enter a valid phone number",
  settings_enterPhoneNo: "Please enter a phone number",
  settings_mismatchedPasswords: "Passwords don't match",
  settings_confirmPassword: "Please confirm your Password",
  settings_passwordFieldNotBlank: "Password can't be blank",
  settings_nameNotBlank: "Name can't be blank",
  settings_enterValidEmail: "Please enter a valid email",
  settings_emailCantBeBlank: "Email can't be blank",
  settings_userProfileHeading: "User Profile",
  settings_phoneNumber: "Phone Number",
  settings_emailAddr: "Email Address",
  settings_currency: "Currency",
  settings_indonesiaRupiah: "IDR Indonesia Rupiah",
  settings_malaysiaRinggit: "MYR Malaysia Ringgit",
  settings_singaporeDollar: "SGD Singapore Dollar",
  settings_USDollar: "USD United State Dollar",
  settings_vietnamDong: "VND Vietnam Dong",
  settings_japaneseYen: "JPY Japanese Yen",
  settings_confirmPassword: "Confirm password",
  settings_password: "Password",

  // ConnectionSetup
  settings_connectionSetup: "Connection Setup",

  // FbAdsTable and  googleAdsTable
  settings_fbAds: "Facebook ads",
  settings_logout: "Logout",
  settings_connect: "Connect",
  settings_fbConnectText:
    "Connect your Facebook advertising account to start using Peasy. If you don't have a Facebook advertising account or want to learn more",
  settings_clickHere: "click here",
  settings_accID: "Account ID",
  settings_accName: "Account name",
  settings_currency: "Currency",
  settings_action: "Action",
  settings_toggle: "Toggle",
  settings_activeAdAcc: "Your Ad Account Must Be Active",
  settings_notEligible: "Not eligible",
  settings_connectAccLabel:
    "You do not have an active and eligible Facebook Ads Account connected to your Facebook profile. Please create a new Facebook Ads account or ask your company or your client to grant you access to a Facebook Ads Account",
  settings_fbConnectLabel:
    "You are connected to a temporary Facebook Ads account. Please disconnect this account and connect to your personal Facebook Ads account",

  settings_googleAds: "Google Ads",
  settings_googleConnectText:
    "Connect your Google Ad account to start using Google Ads through Peasy. If you don't have a Google Ad ad account or want to learn more",
  settings_googleConnectLabel:
    "You are connected to a temporary Google Ads Account. Please create a new Google Ads account or ask your company or your client to grant you access to a Google Ads Account",
  settings_connectGoogleAccLabel:
    "You do not have an active and eligible Google Ads Account connected to your Google profile. Please create a new Google Ads Account or ask your company or your client to grant you access to a Google Ads Account",

  // UserTeam
  settings_inviteUsers: "Invite Users",
  settings_usersAndTeam: "Users & Team",
  settings_totalBalance: "Total Balance",
  settings_user: "User",
  settings_quota: "Quota",
  settings_balanceMonth: "Balance this Month",
  settings_status: "Status",
  settings_loginAs: "LOGIN AS",
  settings_revoke: "Revoke",

  // billing
  settings_unsubscribe: "Unsubscribe",
  settings_subscribe: "Subscribe",
  settings_makePayment: "Make Payment",
  settings_upgrade: "Upgrade",
  settings_switch: "Switch",
  settings_downgrade: "Downgrade",
  settings_billingScreen: "Billing Screen",
  settings_billedMonthly: "Billed Monthly",
  settings_billedYearly: "Billed Yearly",
  settings_adSpentLimit: "Ad Spend Limit/month.",
  settings_renewsEveryMonth: "Renews every month.",
  settings_noOfUserAcc: "Number of user account",
  settings_maxDataStorage: "Max data storage",
  settings_GB: "gb",
  settings_maxRetentionPeriod: "Max data retention period",
  settings_month: "month",
  settings_availCredit: "Available credit",
  settings_topUp: "Top up",
  settings_date: "Date",
  settings_transaction: "Transaction",
  settings_amount: "Amount",
  settings_credit: "Credit",
  settings_balance: "Balance",
  settings_loadMoreTransactns: "Load more transactions",

  // APIKey
  settings_businessAPIKey: "Business Profile API Key",
  settings_addNew: "Add New",
  settings_ApiKey: "ApiKey",
  settings_businessProfileName: "Business Profile Name",
  settings_integratns: "Integrations",
  settings_integratn: "Integration",
  settings_type: "Type",
  settings_apiKey: "API Key",
  settings_webhook: "Webhook",
  settings_noIntegratnAvail: "No integrations available to present",
  settings_addIntegratn: "Add Integration",
  settings_integratnName: "Integration Name",
  settings_webhookUrl: "Webhook URL",
  settings_type: "Type",
  settings_peasysales: "Peasy Sales",
  settings_others: "Others",
  settings_profiles: "Profiles",
  settings_status: "Status",
  settings_selectBusinessProfile: "Select Business Profile",
  settings_generate: "Generate",

  // DASHBOARD

  dashboard_business_profile_text: "Business Profile",
  dashboard_profiles: "Profiles",
  dashboard_conversionFunnel: "Sales Funnel",
  dashboard_ad_spend: "Available balance: ",
  dashboard_noProfilesText: "No Business Profiles. Create One",
  dashboard_createCampaign: "+ Create Campaign",
  dashboard_campaignReport: "Campaign Report",
  dashboard_funnelReport: "Funnel Report",
  dashboard_ecommerce: "E-Commerce",
  dashboard_metric: "Metric",
  dashboard_metric7days: "Last 7 Days",
  dashboard_metric15days: "Last 15 Days",
  dashboard_metric30days: "Last 30 Days",
  dashboard_campaign: "Campaign",
  dashboard_noCampaigns: "No Campaigns",
  dashboard_campaignList: "Campaign List",
  dashboard_Status: "Status",
  dashboard_activeCampaign: "Active Campaigns",
  dashboard_reviewCampaign: "Review Campaigns",
  dashboard_pausedCampaign: "Paused Campaigns",
  dashboard_archivedCampaign: "Archived Campaigns",
  dashboard_noCampaignsFound: "No Campaign Found !!",
  dashboard_deleteCamapaign: "Are you sure you want to delete this campaign ?",
  dashboard_brandLimit: "You cannot create more brand",

  // toast.errors
  dashboard_errorWhilefetchingProfiles:
    "Opps! something went wrong while fetching the Business Profiles. Please refresh the page",
  dashboard_somethingWentWrong: "Opps! Something went wrong",
  dashboard_somethingWrongWhilePerformingAction:
    "Opps! Something went wrong while performing the action",

  clients_somethngWrongWhileUpdating: "Something Went wrong while updating",
  clients_notifyWhenLeadsImported:
    "You will be notified via email once the contacts are completely imported",
  clients_somethngWrongWhileImportingLeads:
    "Something went wrong while importing the contacts",
  clients_errorWhileDownloadingFile: "Error while downloading the file",
  cients_errorWhileFetchingFromServer:
    "Error while fetching the file from server",

  clients_notifiedViaEmail:
    "You will be notified via email once the leads are completely imported", //NEW
  clients_somethingWrongWhileImportingLeads:
    "Something went wrong while importing the leads", //NEW
  clients_errorWhileServer: "Error while fetching the file from server", //NEW

  cAudience_deletedSuccessfully: "Custom audience deleted successfully.",

  cAudience_cannotContain: "cannot contain",
  cAudience_valuesCantBeEmpty: "Sorry, but values can't be empty",
  cAudience_fieldsCantBeBlank: "Fields cannot be blank.",
  cAudience_factIDCantHaveSpace: "Fact id cannot have space in it.",
  cAudience_idAsQualificatnCantBeCreated:
    "Fact with id as qualification cannot be created.",
  cAudience_factWithIdAs: "Fact with id as ",
  cAudience_cantBeCreated: " cannot be created.",
  cAudience_alreadyExists: " already exists.",
  cAudience_failedToCreateFact: "Failed to create fact.",

  // Dashboard
  dashboard_campaignMetric: "Campaign Metric",
  dashboard_range: "Range",
  dashboard_businessProfile: "BUSINESS PROFILE",
  dashboard_AIKnowsYouWell: "Our AI knows you well:",
  dashboard_dataCollectedAndTrained: "of data collected and trained",
  dashboard_NoProfilesCreatedYet: "No Profiles created yet",
  dashboard_useDummyData: "No worries! You can experience with dummy data",
  dashboard_experienceNow: "Experience Now",
  dashboard_editFunnel: "Edit Funnel",
  dashboard_contacts: "Contacts",
  dashboard_customAudience: "Custom Audience",
  dashboard_articles: "Articles",
  dashboard_validating: "Validating",
  dashboard_ecommerceChartMetric: "Ecommerce Chart Metric",
  dashboard_woocommerce_connected: "Woocommerce is not Connected",
  dashboard_woocommerce: "Woocommerce",
  dashboard_rmfReport: "RMF Report",

  // Articles
  dashboard_readMore: "Read More",

  // StackedBarChart
  dashboard_noLeadMetricText: "No Lead Metrics available.",

  //  LoginPage
  login_dontHaveAccount: "Don’t have an account",
  login_getPeasyFree: "Get Peasy Free",
  login_applyForGrant: "Apply For The Grant Now.",
  login_willConnectShortly: "We’ll contact you shortly",
  login_signIn: "Sign In",
  login_emailAddress: "E-Mail Address",
  login_password: "Password",
  loginHeading: "Login",
  login_forgotPassword: "Forgot Your Password?",

  // Clients
  clients_from: "From",
  clients_to: "To",
  clients_apply: "Apply",
  clients_listing: "Contact’s Listing",
  clients_cancel: "Cancel",
  clients_reset: "Reset",
  clients_customAudience: "Custom Audience",
  clients_import: "Import",
  clients_noOfLeads: "Number Of Contacts :",
  clients_export: "Export",

  // ClientListingTable
  clients_noDataToDisplay: "No Data To Display",
  clients_email: "EMAIL",
  clients_phone: "PHONE",
  clients_predictedScore: "PREDICTED SCORE",
  clients_qualification: "QUALIFICATION",
  clients_campaign: "CAMPAIGN",
  clients_date: "DATE",
  clients_details: "DETAILS",

  // CustomAudienceTable
  cAudience_pleaseRemove: "Please remove",
  cAudience_lookalikeBeforeDeletingAudience:
    "lookalike audience(s) from these campaign’s targeting before deleting this audience.",
  cAudience_sureYouWantToDelete: "Are you sure you want to delete?",
  cAudience_customAudience: "Custom Audience",
  cAudience_deletingCustomAudience: "Deleting custom audience!",
  cAudience_cancel: "Cancel",
  cAudience_delete: "Delete",
  cAudience_createCustomAudience: "Create Custom Audience",
  cAudience_sNo: "S. No.",
  cAudience_name: "Name",
  cAudience_creationDate: "Creation Date",
  cAudience_validLeads: "Valid Contacts",
  cAudience_inValidLeads: "Invalid Contacts",
  cAudience_associatedCampaigns: "Associated Campaigns",
  cAudience_action: "Action",
  cAudience_noCustomAudienceAvailable: "No custom audience available",

  // CustomAudienceBuilder
  cAudience_createNewCustomAudience: "Create New Custom Audience",
  cAudience_customAudienceName: "Custom Audience Name",
  cAudience_addFact: "Add Fact",
  cAudience_factType: "Fact Type",
  cAudience_string: "String",
  cAudience_integer: "Integer",
  cAudience_double: "Double",
  cAudience_boolean: "Boolean",
  cAudience_datetime: "Datetime",
  cAudience_date: "Date",
  cAudience_time: "Time",
  cAudience_factID: "Fact ID",
  cAudience_factLabel: "Fact Label",
  cAudience_cancel: "Cancel",
  cAudience_save: "Save",
  cAudience_getEstimatedLeads: "Get Estimated Contacts Count",
  cAudience_leads: "Contacts",
  cAudience_Create: "Create",

  // RightViewMoreModalRender
  clients_noDataToDisplay: "No Data To Display",
  clients_adName: "AD NAME",
  clients_date: "DATE",
  clients_clientDetails: "Contact Details",
  clients_predictedScore: "PREDICTED SCORE:",
  clients_qualification: "QUALIFICATION",
  clients_close: "Close",

  // RightViewMoreModal
  clients_unableToFetchData: "Unable to Fetch Data",
  clients_updatedSuccessfully: "Updated Successfully",
  clients_somethingWrongWhileUpdating: "Something Went wrong while updating",
  clients_viewMore: "View More",

  // SelectFilter
  clients_campaignName: "Campaign Name",
  clients_adSetName: "Ad Set Name",
  clients_campaignId: "Campaign Id",
  clients_addSetId: "Add Set Id",
  clients_registeredBetween: "Registered Between",
  clients_predictionScore: "Prediction Score",
  clients_qualificationStatus: "Qualification Status",

  // StepWrapper
  campaign_question: "Question",
  campaign_start: "Start",
  campaign_predictResult: "Predict the result",
  campaign_targetLabel: "What is your target ",

  // brandOverview
  dashboard_newestFirst: "Newest First",
  dashboard_oldestFirst: "Oldest First",
  dashboard_objective: "Objective",

  // Dashboard
  dashboard_edit: "Edit",
  dashboard_delete: "Delete",
  dashboard_deploy: "Redeploy",

  // Dashboard
  dashboard_noCampaignMetricText:
    "There aren't any active campaign during this period of time. Start one now",
  dashboard_leads: "Contacts",

  // Cohort
  dashboard_noMetricText: "Sorry! no lead metrics available",

  // UserTeam
  campaign_packageDoesNotAllowSubuser:
    "Sorry, Your subscription plan does not allow subuser. Contact us to upgrade to a new plan",

  // Clients
  clients_clientListing: "Client Listing",
  clients_noOfLeads: "Number Of Contacts:",
  clients_somethingWentWrong: "Oops! Something went wrong",

  // BrandCreate
  brand_updatedSuccessfully: "Business Profile updated successfully!",

  // toast.errors
  brand_somethingWrongWhileAction:
    "Opps! Something went wrong while performing the action.",
  dashboard_somethingWentWrong: "Oops! something went wrong!Please try again.",
  dashboard_somethingWentWrongWhileFetchingData:
    "Something went wrong while fetching the campaign data. Please try again.",
  dashboard_campaignPaused: "Campaign paused successfully!",
  dashboard_couldntPausedCampaign: "Couldn't pause campaign. Please try again",
  dashboard_campaignStarted: "Campaign started successfully!",
  dashboard_couldntStartCampaign: "Couldn't start campaign. Please try again",
  dashboard_campaignRedeployed: "Campaign redeployed successfully!",

  campaign_dontHaveValidPixel:
    "Opps! You do not have a valid pixel to choose this objective",
  campaign_inactiveAccount: "Sorry your account is not active",
  campaign_somethingWrongWhileAction:
    "Opps! Something went wrong while performing the action",

  settings_somethingWentWrong:
    "Opps! Something went wrong while performing the action",

  // Common Keywords
  brand_here: "here.",

  // CreateBrandSelectFbPage
  brand_whereWillAudienceLand:
    "Where will audience land after clicking on your ad?",

  // BrandCreate
  brand_question: "Question",

  // CreateBrandLastStep
  brand_reach: "Reach",
  brand_qualified: "Qualified",
  brand_sales: "Sales",

  // CreateBrandPixels
  brand_pixelDomainEventText:
    "Please ensure that your pixel, domain and event are verified and setup properly. Unverified and incorrectly setup pixel, domain or event can cause deployment error or inaccurate targeting and reporting. For more information read",

  // BrandOverview
  dashboard_campaignHaltText:
    "Your campaign has been stopped due to manual changes made on Facebook and/or Google Ads Manager",
  dashboard_campaignNotDeployed:
    "We are not able to deploy your campaign, please check the notification to see the issue",
  dashboard_budget: "Budget",
  dashboard_target: "Target",
  dashboard_all: "All",

  // CampaignEndDate
  campaign_sevenDaysToDeliverResults:
    "Minimum 4 days.We recommend at least 7 days for our AI to deliver optimum results.",

  // CampaignLocation
  campaign_specifyingMultipleLocations:
    ' You can specify more than 1 location, but please ensure that these locations does not overlap. For example, you can\'t choose both "Kuala Lumpur "and "Malaysia "because Kuala Lumpur is part of Malaysia.',

  // CampaignName
  campaign_nameThatHelpIdentifyCampaign:
    "Anything goes, choose a name which will help you identify this campaign.",
  campaign_minTenCharacters: "Minimum 10 characters",

  // FormFieldMapping
  campaign_mapFormFieldsText:
    "Map the form fields, you will need to perform this action once for every new form. Peasy will be using these identifier data to create and update custom audience.",

  // LeadForm
  campaign_chooseExistingFbLeadFormText:
    'Choose an existing and published Facebook Lead Form. If there isn’t any option within the drop down list, please click on "Create Form "to create a new Facebook Lead Form. You will need Facebook Page Admin access to create and publish a new lead form. For more information read ',
  campaign_Here: "here.",

  // Method
  campaign_recommendCampaignStrategyText:
    "Peasy can recommend the campaign strategy based on target or budget. If you set the budget constraint, Peasy will forecast what's the best possible target based on your budget. If you set the target, Peasy will recommend the budget necessary to achieve your target",
  campaign_howManyXText:
    "How many X do you want this campaign to achieve? Peasy will provide its own forecast based on time, budget and audience data.",

  // SpendCampaign
  campaign_budgetCalculationText:
    "Minimum budget is calculated based on Facebook and Google minimum spend requirement and campaign duration. Campaign budget is not a firm commitment, you can still pause or reduce the campaign budget later. We recommend that you set a higher budget first, and reduce the amount in the planning and forecast section later.",

  // Sidebar
  dashboard_logout: "Logout",
  dashboard_questionaire: "Questionaire",
  dashboard_editCampaign: "Edit Campaign",
  dashboard_settings: "Settings",
  dashboard_campaignReport: "Campaign Report",
  dashboard_RMF: "RMF",
  
  // NEW

  // toast.errors
  campaign_divideRatioInPerfectManager:
    "Please, divide the ratio in perfect manager",
  campaign_somethingWentWrong: "Oops! Something went wrong. Please try again",
  campaign_errorGettingPosts: "Error getting posts !",
  campaing_selectSomeAudience: "Please select some audiences",
  campaign_errorUploadingCarouselAd:
    "Error uploading Carousel Ad. Please try again",
  campaign_completeImageAd: "Please complete your Image ad(s) before saving",
  campaign_errorUploadingImagePost:
    "Error uploading image post. Please try again",
  campaign_completeYourVideoAd:
    "Please complete your Video ad(s) before saving",
  campaign_errorUploadingVideo: "Error uploading video. Please try again",
  campaign_cantUploadMoreThanTenMediaFiles:
    "User Cannot upload more than 10 media files in A Carousel",
  campaign_postsSaved: "Posts saved successfully!",
  campaign_deployedSuccessfully: "Campaign deployed successfully!",
  campaign_recommendAudienceAddedSuccessfully:
    "Recommended audience added successfully!",
  campaign_errorRecommendingAudience:
    "Error recommending audience. Please try again",

  // Common Keywords
  campaign_selectCTA: "Select CTA",
  campaign_areYouSure: "Are You Sure ?",
  campaign_sureYouWantToDeletePost:
    "Are you sure you want to delete this post?",
  campaign_sponsored: "Sponsored",
  campaign_editCreative: "Edit Creative",
  campaign_editCreativeCanva: "Edit Creative on Canva",
  campaign_createCanva: "Create on Canva",

  campaign_delPost: "Delete Post",
  campaign_enterURL: "Enter URL",
  campaign_comment: "Comment",
  campaign_comments: "Comments",
  campaign_shares: "Shares",
  campaign_like: "Like",
  campaign_share: "Share",
  campaign_targeting: "Targeting",
  campaign_noAudience: "No Audience for this post",
  campaign_audience: "Audience",
  campaign_loading: "loading...",
  campaign_enterCaption: "Enter Caption",
  campaign_enterDescription: "Enter Description",

  // postBoostChartComponent
  campaign_comparison: "Comparison",
  campaign_range: "Range",
  campaign_ROIOverMonth: "Historical Campaign ROI over month",
  campaign_failedToLoad: "Failed to load Historical Campaign ROI over month",
  campaign_CPAComparison: "CPA Camparison",
  campaign_failedToLoadCPAComparison: "Failed to load CPA Camparison",
  campaign_saleVolumeComparison: "Sale Volume Comparision",
  campaign_failedToLoadSaleVolumeComparison:
    "Failed to load Sale Volume Comparision",
  campaign_historicalCampaignROI: "Historical Campaign ROI over month",
  campaign_valueFor: "Value for ",
  campaign_on: " on",

  // PredictionStats
  campaign_estimatedToProduce: "This campaign is estimated to produce",
  campaign_addToCart: "Add to Cart at",
  campaign_perAddToCartWithin: "per Add to Cart within",
  campaign_toAchieve: "to achieve",
  campaign_sales: "sales.",
  campaign_landingPageViewAt: "Landing Page View at",
  campaign_LandingPageViewWithin: "per Landing Page View within",
  campaign_initiateCheckoutAt: "Initiate Checkout at",
  campaign_perInitiateCheckoutWithin: "per Initiate Checkout within",
  campaign_per: "per",
  campaign_within: "within",
  campaign_predictn: "Prediction", //NEW
  campaign_budgetTargetCampaignAreGood:
    "Your budget, target and campaign period are all good! Ready to skyrocket your ",
  campaign_uploadAdCreativeAndClick:
    "Upload your ad’s creative and click “Save & Publish” to start the campaign.",

  // AddPostCard
  campaign_letsUploadCreative: "Lets upload your creative",
  campaign_addNewPost: "Add New Post",

  //  PostBoost
  campaign_headlineCantBeEmpty: "Headline can't be empty!",
  campaign_descriptionCantBeBlank: "Description can't be blank!",
  campaign_enterValidURL: "Please enter a valid url!",
  campaign_enterURL: "Please enter a url!",
  campaign_selectSomeAudiences: "Please select some audiences!",
  campaign_selectCTAError: "Please select a CTA!",
  campaign_enterCaptionForImageCard:
    "Please enter a caption for the image card!",
  campaign_deploymentLoadingMsg:
    "This campaign will be submitted for Facebook or Google review. Please ensure that your campaign comply with the respective platform policy. Compliance failure can lead to page and ad account suspension",
  campaign_deploymentLoadingMsg_prepaid:
    "This campaign will be submitted to Peasy for review. Please ensure that your campaign comply with the respective platform policy. Compliance failure can lead to page and ad account suspension",
  campaign_connectSocialMedia:
    "You are not authorised to publish, Please connect your social media account",
  campaign_opps: "Opps!",
  campaign_cantBePublishedText:
    "Campaign cannot be published until creative assignment is complete. Please assign at least one creative to these following audiences:",
  campaign_sudienceSegmentText:
    "This audience segment utilizes Google Display Networ (GDN) ads. GDN can only support still image, and not video and carousel. Please create at least one still image creative for this segment OR set the segment's GDN's budget split to 0.",
  campaign_okay: "Okay",
  campaign_saveAndPublish: "Save & Publish",
  campaign_save: "Save",

  campaign_current: "Current Campaign", //New
  campaign_previousCampaign: "Previous Campaign", //New
  campaign_nextCampaign: "Next Campaign", //New

  // ImageCard
  campaign_instaImageValidation: "Instagram Image Validation",
  campaign_imageSizeError:
    "This image need to be 1080x1080 to be deployed on Instagram. Please change this image or assign 0% budget to Instagram",

  // VideoCard
  campaign_instaVdoValidatn: "Instagram Video Validation",
  campaign_vdoDuratnError:
    "This video maximum duration must be 2 minutes or less to be deployed on Instagram. Please change this video or assign 0% budget to Instagram",
  camaign_editThumbnail: "Edit Thumbnail",

  // PredictionForm
  campaign_startDateInPastCantChange: "Start date in past can't be changed",
  campaign_startCantBeAfterEndDate: "Start date can't be after end date",
  campaign_endDateCantBeforeStartDate: "End date can't be before start date",
  campaign_budget: "Budget",
  campaign_startDate: "Start Date",
  campaign_endDate: "End Date",
  campaign_target: "Target",

  // NEW

  // toast.errors
  campaign_budgetSplitMustBeHundred: "Total budget split must be 100%",
  campaign_audienceUpdatedSuccessfully: "Audiences updated successfully!",
  campaing_selAudienceBeforeDel:
    "Please select some audiences before clicking delete",
  campaign_audienceDeletedSuccessfully: "Audience deleted successfully",
  campaign_couldntFetchAud: "Couldn't fetch audience. Please try again",
  campaign_couldntFetchAudList:
    "Couldn't fetch audience list. Please try again",
  campaign_couldntUpdateAud:
    "Couldn't update audience. Please complete the form",
  campaign_errorUpdatingAud: "Error updating audience. Please try again",
  campaign_couldntAddNewAud:
    "Couldn't add new audience. Please complete the form",
  campaign_audienceAddedSuccessfully: "Audience Added Successfully!",
  campaign_errorAddingAud: "Error adding audience. Please try again",
  campaign_couldntFetchLang: "Couldn't fetch languages. Please try again",

  // PostBoost
  campaign_yes: "Yes",
  campaign_cancel: "Cancel",
  campaign_recommending: "Recommending...",
  campaign_budget: "Budget",
  campaign_deploying: "Deploying...",
  amount_without_tax: "*Tax is not included",
  campaign_thisWillRewriteChanges:
    "This will rewrite all changes you have previously made with Recommendations from our AI",

  // SelectAudiences
  campaign_select: "Select",
  campaign_audienceName: "Audience Name",
  campaign_budgetHeading: "Budget",
  campaign_channel: "Channel",
  campaign_estPopulatn: "Est. Population",
  campaign_estCPA: "Est. CPA",
  campaign_estVolume: "Est. Volume",
  campaign_description: "Description",
  campaign_noAudToDisplay: "There are no audiences to display!",
  campaign_recommendAud: "Recommend Audience",
  campaign_del: "Delete",
  campaign_update: "Update",
  campaign_add: "Add",

  // SingleAudience
  campaign_budgetPercCantLessThan: "Budget percentage can't be less than",
  campaign_minChannelPercCantLessThan:
    "Minimum channel percentage can't be less than",
  campaign_changingAudienceBudgetText:
    "Changing the audience distribution budget will change/affect the estimation, KPI and overall result that might work best for you.",
  campaign_changingChannelText:
    "Changing the channel will show you the distibution of budget across other channels",
  campaign_changingChannelWillAffectVolumeAndCPA:
    "Changing the channel percentage will affect the volume and",
  campaign_showDetails: "Show Details",
  campaign_hideDetails: "Hide Details",
  campaign_yearsOld: "years old",

  // EditAudiencePopup
  campaign_enterName: "Please enter a Name for this audience segment",
  campaign_selGender:
    "Please select atleast one gender for this audience segment",
  campaign_minAgeCantGreaterThnMax:
    "Minimum age can't be greater than maximum age",
  campaign_minAgeCantLessThan18: "Minimum age can't be less than 18",
  campaign_maxAgeCantLessThanMin: "Maximum age can't be less than min age",
  campaign_minAgeCantMoreThan64: "Minimum age can't be more than 64",
  campaign_maxAgeCantMoreThan65: "Maximum age can't be more than 65",
  campaign_maxAgeCantLessThan14: "Maximum age can't be less than 14",
  campaign_fillIncludeAudField: "Please fill the include audience field",
  campaign_selLocatn:
    "Please select atleast one location for this audience segment",
  campaign_selLang:
    "Please select atleast one language for this audience segment",
  campaign_selDevice:
    "Please select atleast one device for this audience segment",
  campaign_AvgConversionMustGreaterThan:
    "Average conversion must be greater than",
  campaign_AvgConversionCostCantMoreThan:
    "Average conversion cost can't be more than",
  campaign_fillAudAgeGroup: "Please fill the audience age group",
  campaign_updateAud: "Update Audience",
  campaign_addAud: "Add Audience",

  // EditAudienceForm
  campaign_nameAudSegment: "Name this audience segment",
  campaign_enterSegmentName: "Enter your segment name",
  campaign_gender: "Gender",
  campaign_male: "Male",
  campaign_female: "Female",
  campaign_unknown: "Unknown",
  campaign_ageGroup: "Age group",
  campaign_age: "Age",
  campaign_to: "to",
  campaign_location: "Location",
  campaign_canTypeTyxt:
    "You can type city, region, country, postal code and other type of location. You can add maximum of 25",
  campaign_language: "Language",
  campaign_typeAudLang: "Type audience language",
  campaign_ProfileLang:
    "At least one of your audience profile language e.g English, Hindi",
  campaign_adAppearInTheseDevices: "Your ad will appear in these devices",
  campaign_mobile: "Mobile",
  campaign_desktop: "Desktop",
  campaign_tablet: "Tablet",
  campaign_targetCostForConversion: "Target cost for every conversion",
  campaign_bidForThisTargetSegmentText:
    "The bid to compete for this target segment. Facebook & Google may spend up to 30% higher than the bid you have set. We recommend that you set a higher bid and let Peasy gradually reduce the bid during optimization",

  // MergeAudienceDialog
  campaign_imgHere: "Image here",
  campaign_overlapDetected: "Overlap detected!",
  campaign_overlapFixed: "Overlap fixed",
  campaign_processing: "Processing...",
  campaign_audienceOverlapText:
    "Audience overlap when left unfix may cause erratic fluctuations in your campaign performance, reduced results and higher cost. Would you like Peasy to fix the overlap?",
  campaign_congrats: "Congrats your audience overlap has been fixed.",
  campaign_fixIt: "Fix it",
  campaign_noThanks: "No thanks",
  campaign_okay: "Okay",

  // GoogleAudienceAffinity
  campaign_googleAffinity: "Google Affinity",
  campaign_narrowAud: "Audiences you want to narrow it down to",
  campaign_typeInInterest:
    "Type in the interest, demographic, or behaviour of audience that",
  campaign_must: "MUST",
  campaign_metToNarrowAud: "be met to narrow your audience.",

  // edit Audience popup
  excludeAudience: "Exclude Audience",
  savedAudience: "Saved Audience",
  excludeAudiencePlaceHolder: "Audiences you want to narrow it down to",
  excludeAudiencePara: `Type in the interest, demographic, or behaviour of audience that you
  DON'T want to reach.`,
  includeAudience: "Include Audience",
  includeAudiencePlaceHolder: "Audiences you want to narrow it down to",
  includeAudiencePara: `Eg. travel or demographic, eg. parents, or behaviour, eg. console gamer,
  that you WANT to reach.`,
  narrowAudience: "Narrow Audience",
  narrowAudiencePlaceHolder: "Audiences you want to narrow it down to",
  narrowAudiencePara: `Type in the interest, demographic, or behaviour of audience that MUST be met to narrow your audience.`,

  // uploadFile
  campaign_uploadMin3:
    "Upload minimum 3 or maximum 10 images or video for carousel",
  campaign_formatNotAccepted: ".MOV format not accepted!",
  campaign_cantAddMoreThan1: "You cannot add more than one post",
  campaign_uploadMin3Or10:
    "Upload minimum 3 or maximum 10 images or video for carousel",
  campaign_dropFilesHere: "Drop files here...",
  campaign_dragAndDrop: "Drag and drop to upload",
  campaign_or: "or",
  campaign_browse: "Browse",
  campaign_toChooseFile: "to choose a file",
  campaign_imgAds: "Image Ads",
  campaign_ratioAndMin: "ratio and minimum dimensions:",
  campaign_vdoAds: "Video Ads",
  campaign_recommendMaxDuratn:
    "16:9,9:16 ratio and 15 sec recommended (IG) max duration 120 sec.",
  campaign_maxDuration: "max duration 60 mins",
  campaign_carousalAds: "Carousel Ads",
  campaign_IgRequiresRatio: "IG requires all image and video to have 1:1 ratio",
  campaign_lessThan60Min: ` duration should be less than 60 minutes.`,
  campaign_ratioBetween: "ratio should be in between 1.91:1 to 1:1",

  // FbCardDescription
  campaign_seeMore: "see more",
  campaign_seeLess: "see less",

  // Description
  campaign_enterPrimaryText: "Enter Primary Text", //new

  //  SelectPostTable
  campaign_FbPost: "Facebook Post",
  campaign_PostHasNoMsg: "This post has no message",
  campaign_postID: "Post ID",
  campaign_dataCreated: "Date Created",
  campaign_createdTime: "createdTime",
  campaign_likes: "Likes",
  campaign_mediaType: "Media Type",
  campaign_uploadImage: "upload image",
  campaign_instaPost: "Instagram Post",
  campaign_postHasNoCaptn: "This post has no caption",
  campaign_selAnotherPst:
    "Already selected post can't be selected. Please select another post.",
  campaign_continue: "Continue",

  // PopupHeader
  campaign_fbPage: "Facebook Page",
  campaign_uploadCreative: "Upload Creative",

  // FbCardDescription
  postboost_tab_graph: "Graph",
  postboost_tab_campaign_details: "Campaign Details",
  postboost_tab_audiences: "Audiences",
  postboost_tab_creative: "Creative",
  cohort_days: "Days",
  cohort_total: "Total",

  // Dashboard //new
  dashboard_brandDataError:
    "Opps! Something went wrong while fetching the funnel data",
  topup_success: "Your Topup payment is successfull",
  topup_failure: "Your Topup payment failed, retry or contact admin",
  otherAmount_failure: "Your Amount must be greater than order amount",
  invoice_order_amount: "Order Amount",
  invoice_transaction_charges: "Transaction Charge",
  invoice_total: "Total Amount",
  continue: "Continue",
  //CheckoutForm
  base_amount: "Base Amount",
  tax_amount: "Tax Amount:",
  amount_with_tax: "Total Amount:",
};

// {t("selectAud")}
// {this.props.t("campaign_next")}
