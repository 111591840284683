import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import EditAudienceForm from "../editAudienceForm/EditAudienceForm";
import FbIncludeAudience from "../fbIncludeAudience/FbIncludeAudience";
import FbNarrowAudience from "../fbNarrowAudience/FbNarrowAudience";
import FbExcludeAudience from "../fbExcludeAudience/FbExcludeAudience";
import GoogleAudienceAffinity from "../googleAudienceAffinity/GoogleAudienceAffinity";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

import "./EditAudiencePopup.css";
import PeasyApi from "../../../utils/peasy.api";
import {
  getListAudience,
  getListInterest,
  onAddAudience,
  onGetAudienceData,
  onUpdateAudience,
} from "../../../utils/network/lib/audience";

const EditAudiencePopup = ({
  getMediaDetails,
  setShowEditAudiencePopup,
  editAudienceId,
  campaignData,
  editAudienceFields,
  getAudienceList,
  setCampaignStep4,
  setAudienceMergeDialogOpened,
  setTimeoutCount,
}) => {
  const { t } = useTranslation();

  // Disable background scroll when popup opens and set to auto when closes
  useEffect(() => {
    document.body.style.overflow = "hidden";
    // setSelectCSS();
  }, []);

  useEffect(
    () => () => {
      document.body.style.overflow = "auto";
    },
    []
  );

  // get audience using id
  useEffect(() => {
    if (editAudienceId && editAudienceId > 0) {
      getAudienceData(editAudienceId);
    } else setNewAudienceDataForm();
    getListedAudience();
    getInterestsList();
  }, [editAudienceId]);

  const [activeMedia, setActiveMedia] = useState("fb");

  const [audienceData, setAudienceData] = useState({});

  // state set after list is fetched
  const [audienceList, setAudienceList] = useState([]);

  // state set after interests list is fetched
  const [interestsList, setInterestsList] = useState([]);

  const [errors, setErrors] = useState({});

  const [buttonLoading, setButtonLoading] = useState(false);

  //state suitable for react-select modified from audience list
  const [audienceListOptions, setAudienceListOptions] = useState([]);

  // Effects

  useEffect(() => {
    if (audienceList.length > 0) makeOptionsAudienceList();
  }, [audienceList]);

  // Functions

  const replaceComma = (name, value) => {
    let originalValue = value[name];
    console.log("replaceComma -> originalValue", originalValue);

    if (originalValue.includes(",")) {
      console.log(originalValue);
      let arr = originalValue.split('","');
      console.log(arr);
      originalValue = arr.join("><");
      value[name] = originalValue;
      console.log("replaceComma -> value", value);
      return value;
    } else {
      return value;
    }
  };

  const getAudienceData = async (id) => {
    const res = await onGetAudienceData(id);
    if (res.status === 200) {
      let audienceList = res.data.data;
      audienceList = replaceComma("location", audienceList);
      console.log("getAudienceData -> audienceList", audienceList);

      setAudienceData(audienceList);
    } else toast.error(t("campaign_couldntFetchAud"));
  };

  const getListedAudience = async () => {
    const res = await getListAudience();

    if (res.status === 200) {
      setAudienceList(res.data.data);
    } else toast.error(t("campaign_couldntFetchAudList"));
  };

  const getInterestsList = async () => {
    try {
      const res = await getListInterest();
      if (res.status === 200) {
        let array = [];
        res.data.data.map((interest) => {
          const object = {};
          object.value = interest.id;
          object.label = `${interest.name}|${interest.type}`;
          array = [...array, object];
        });
        setInterestsList(array);
      }
    } catch (error) {
      if (error.response.data.message) {
        return toast.error(error.response.data.message);
      } else return toast.error(t("campaign_somethingWentWrong"));
    }
  };

  const setNewAudienceDataForm = () => {
    const object = {};
    object.campaign_id = window.location.href.split("/").pop();
    object.name = "";
    object.gender = "MALE><FEMALE><ALL";
    object.age_min = 18;
    object.age_max = 40;
    object.location = campaignData.location.slice(
      2,
      campaignData.location.length - 2
    );
    object.lang = "";
    object.device = "MOBILE><DESKTOP><ALL";
    object.avg_conversion_cost = editAudienceFields.default_audience_bid;
    object.interest = "";
    object.narrow = "";
    object.exclude = "";
    object.affinity = "";
    setAudienceData(object);
  };

  const makeOptionsAudienceList = () => {
    let array = [];
    audienceList.map((audience) => {
      const object = {};
      const values = audience.value.split("|");
      object.value = values[0];
      object.label = `${values[1]}|${audience.type}`;
      array = [...array, object];
    });
    setAudienceListOptions(array);
  };

  const onChange = (name, value) => {
    const object = { ...audienceData, [name]: value };
    setAudienceData(object);
  };

  const onCheckChange = (name, value) => {
    const attributes = audienceData[name].split(/(?:<|>)+/);
    const index = attributes.indexOf(value);
    if (index !== -1) {
      attributes.splice(index, 1);
    } else attributes.push(value);

    let newArray = [];

    attributes.map((string, index) => {
      let dummy = "";
      // if single value don't alter string
      if (attributes.length === 1) {
        dummy = string;
      } else {
        // alter only if multiple values
        if (index === 0) dummy = `${string}>`;
        else if (index === attributes.length - 1) dummy = `<${string}`;
        else dummy = `<${string}>`;
      }
      newArray = [...newArray, dummy];
    });
    const newString = newArray.join("");
    const object = { ...audienceData, [name]: newString };
    setAudienceData(object);
  };

  const getSelectValue = (name) => {
    if (audienceData[name] && audienceData[name].length > 0) {
      const values = audienceData[name].replace(/","/g, "><").split(/(?:<|>)+/);
      let array = [];
      values.map((value, key) => {
        const object = {};
        const dummy = value.split("|");
        object.value = dummy[0];
        object.label = dummy[1];
        if (dummy[2]) {
          object.label = `${object.label}|${dummy[2]}`;
        }
        array = [...array, object];
      });
      return array;
    }
  };

  const onSelectChange = (name, value) => {
    let array = [];
    if (value) {
      value.map((object) => {
        const string = `${object.value}|${object.label}`;
        array = [...array, string];
      });
    }

    let newArray = [];
    array.map((string, index) => {
      let dummy = "";
      // if single value don't alter string
      if (array.length === 1) {
        dummy = string;
      } else {
        // alter only if multiple values
        if (index === 0) dummy = `${string}>`;
        else if (index === array.length - 1) dummy = `<${string}`;
        else dummy = `<${string}>`;
      }
      newArray = [...newArray, dummy];
    });
    const result = newArray.join("");
    const object = { ...audienceData, [name]: result };
    setAudienceData(object);
  };

  const validateOnBlur = (name) => {
    const object = { ...errors };
    switch (name) {
      case "name":
        if (audienceData.name.length === 0) {
          object.name = t("campaign_enterName");
        } else object.name = null;
        break;

      case "gender":
        if (audienceData.gender.length === 0) {
          object.gender = t("campaign_selGender");
        } else object.gender = null;
        break;

      case "age_min":
        if (
          audienceData.age_min &&
          audienceData.age_max &&
          audienceData.age_min > audienceData.age_max
        ) {
          object.age_min = t("campaign_minAgeCantGreaterThnMax");
          break;
        } else {
          object.age_min = "";
          object.age_max = "";
        }
        if (audienceData.age_min < 18) {
          object.age_min = t("campaign_minAgeCantLessThan18");
        }

        if (audienceData.age_min > 64) {
          object.age_min = t("campaign_minAgeCantMoreThan64");
        }

        if (audienceData.age_min >= 18 && audienceData.age_min < 64)
          object.age_min = null;
        break;

      case "age_max":
        if (
          audienceData.age_min &&
          audienceData.age_max &&
          audienceData.age_min > audienceData.age_max
        ) {
          object.age_max = t("campaign_maxAgeCantLessThanMin");
          break;
        } else {
          object.age_min = "";
          object.age_max = "";
        }
        if (audienceData.age_max > 65) {
          object.age_max = t("campaign_maxAgeCantMoreThan65");
        }

        if (audienceData.age_max < 14) {
          object.age_max = t("campaign_maxAgeCantLessThan14");
        }

        if (audienceData.age_max < 65 && audienceData.age_max > 14) {
          object.age_max = null;
        }
        break;

      case "interest":
        if (audienceData.interest.length === 0) {
          object.interest = t("campaign_fillIncludeAudField");
        } else object.interest = null;
        break;

      case "location":
        if (audienceData.location.length === 0) {
          object.location = t("campaign_selLocatn");
        } else object.location = null;
        break;

      case "lang":
        if (audienceData.lang.length === 0) {
          object.lang = t("campaign_selLang");
        } else object.lang = null;
        break;

      case "device":
        if (audienceData.device.length === 0) {
          object.device = t("campaign_selDevice");
        } else object.device = null;
        break;

      case "avg_conversion_cost":
        const number = Number(audienceData.avg_conversion_cost);
        if (number < editAudienceFields.min_bid) {
          object.avg_conversion_cost =
            t("campaign_AvgConversionMustGreaterThan") +
            ` ${editAudienceFields.min_bid}`;
        }

        if (number > editAudienceFields.max_bid) {
          console.log(editAudienceFields);
          object.avg_conversion_cost =
            t("campaign_AvgConversionCostCantMoreThan") +
            ` ${editAudienceFields.max_bid}`;
        }

        if (
          number > editAudienceFields.min_bid &&
          number < editAudienceFields.max_bid
        ) {
          object.avg_conversion_cost = null;
        }
        break;

      default:
        return null;
    }
    setErrors(object);
  };
  const validateAudience = () => {
    const object = {};
    let validated = true;
    if (audienceData.name.length === 0) {
      validated = false;
      object.name = t("campaign_enterName");
    }
    if (audienceData.gender.length === 0) {
      validated = false;
      object.gender = t("campaign_selGender");
    }

    if (audienceData.age_min < 18) {
      validated = false;
      object.age_min = t("campaign_minAgeCantLessThan18");
    }

    if (audienceData.age_min > 64) {
      object.age_min = t("campaign_minAgeCantMoreThan64");
    }

    if (audienceData.age_max > 65) {
      validated = false;
      object.age_max = t("campaign_maxAgeCantMoreThan65");
    }

    if (audienceData.age_max < 14) {
      validated = false;
      object.age_max = t("campaign_maxAgeCantLessThan14");
    }

    if (!audienceData.age_min || !audienceData.age_max) {
      validated = false;
      object.age = t("campaign_fillAudAgeGroup");
    }
    if (
      audienceData.age_min &&
      audienceData.age_max &&
      audienceData.age_min > audienceData.age_max
    ) {
      validated = false;
      object.age_min = t("campaign_minAgeCantGreaterThnMax");
    }

    if (audienceData.interest.length === 0) {
      validated = false;
      object.interest = t("campaign_fillIncludeAudField");
    }

    if (audienceData.location.length === 0) {
      validated = false;
      object.location = t("campaign_selLocatn");
    }
    if (audienceData.lang.length === 0) {
      validated = false;
      object.lang = t("campaign_selLang");
    }
    if (audienceData.device.length === 0) {
      validated = false;
      object.device = t("campaign_selDevice");
    }

    const number = Number(audienceData.avg_conversion_cost);

    if (number < editAudienceFields.min_bid) {
      validated = false;
      object.avg_conversion_cost =
        t("campaign_AvgConversionMustGreaterThan") +
        ` ${editAudienceFields.min_bid}`;
    }

    if (number > editAudienceFields.max_bid) {
      console.log(editAudienceFields);
      validated = false;
      object.avg_conversion_cost =
        t("campaign_AvgConversionCostCantMoreThan") +
        `Average conversion cost can't be more than ${editAudienceFields.max_bid}`;
    }

    return [validated, object];
  };
  // this function will be called on the time of audience add and update
  // and will return true or false according to the response.
  // Edit or add popup will not be closed if the response of check function overlap
  // is false and if true the audiences will be add or update
  const runLocationOverlapFunction = async () => {
    const obj = getSelectValue("location");
    let locationArray = obj.map((l) => l.label);
    let locations = { locations: locationArray };
    try {
      const res = await PeasyApi.post("api/check-function-overlap", locations);
      if (res && res.error) {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateAudience = async () => {
    let audience = replaceComma("location", audienceData);
    setAudienceData(audience);
    setButtonLoading(true);
    const validation = validateAudience();
    if (!validation[0]) {
      setErrors(validation[1]);
      setButtonLoading(false);
      return toast.warn(t("campaign_couldntUpdateAud"));
    }
    if (!(await runLocationOverlapFunction())) {
      setButtonLoading(false);
      return;
    }
    try {
      // Setting default status Draft
      audienceData.status = "DRAFT";
      const res = await onUpdateAudience(editAudienceId, audienceData);
      if (res.status === 200) {
        // setAudienceMergeDialogOpened(true);
        // setCampaignStep4(0);
        // setTimeoutCount(5000);
        setShowEditAudiencePopup(false);
        getAudienceList();
        getMediaDetails();
        return toast.success(t("campaign_audienceUpdatedSuccessfully"));
      }
    } catch (err) {
      setButtonLoading(false);
      if (err.response.data.message)
        return toast.error(err.response.data.message);
      else toast.error(t("campaign_errorUpdatingAud"));
    }
  };

  const addAudience = async () => {
    let audience = replaceComma("location", audienceData);
    setAudienceData(audience);
    setButtonLoading(true);
    const validation = validateAudience();
    if (!validation[0]) {
      setButtonLoading(false);
      setErrors(validation[1]);
      return toast.warn(t("campaign_couldntAddNewAud"));
    }
    if (!(await runLocationOverlapFunction())) {
      setButtonLoading(false);
      return;
    }
    try {
      const res = await onAddAudience(audienceData);

      if (res.status === 200) {
        // setAudienceMergeDialogOpened(true);
        // setCampaignStep4(0);
        // setTimeoutCount(5000);
        toast.success(t("campaign_audienceAddedSuccessfully"));
        setShowEditAudiencePopup(false);
        getAudienceList();
        getMediaDetails();
      } else throw Error;
    } catch (err) {
      setButtonLoading(false);
      if (err.response.data.message) {
        return toast.error(err.response.data.message);
      } else {
        return toast.error(t("campaign_errorAddingAud"));
      }
    }
  };

  // Declarative objects

  const selectStyles = {
    // multiValue: (base, state) => ({
    //   ...base,
    //   backgroundColor: "#A7E62F",
    //   borderRadius: "20px",
    //   fontSize: "16px",
    //   fontWeight: 500
    // }),
    indicatorSeparator: () => null,
    dropdownIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    control: (base, state) => ({
      ...base,
      border: "1px solid base.borderColor",
      boxShadow: state.isFocused ? "0px 0px 9px 0.5px #A1C8E8" : "none",
      borderColor: state.isFocused ? "#7EB7E4" : base.borderColor,
    }),
  };

  const buttonStyles = {
    // width: "20%",
  };

  console.log("audience data inside popup", audienceData);

  return (
    <React.Fragment>
      <div className="editaudience__disable" />

      <div
        // style={{ height: "80vh" }}
        className="selectpostpopup__container"
        id="selectpostpopup__container"
        style={{ padding: "0px", minHeight: "unset" }}
      >
        <div className="selectpostpopup__heading">
          {editAudienceId ? "Edit Audience" : "Add Audience"}
          <i
            onClick={() => setShowEditAudiencePopup(false)}
            className="fas fa-times selectpostpopup__icon--close"
          />
        </div>

        {/* This div gives scroll ability */}
        <div className="editaudience__form--container">
          <EditAudienceForm
            audienceData={audienceData}
            onChange={onChange}
            onCheckChange={onCheckChange}
            onSelectChange={onSelectChange}
            getSelectValue={getSelectValue}
            errors={errors}
            isEditMode={!!editAudienceId}
            validateOnBlur={validateOnBlur}
          />

          <div className="editaudiencepopup__btn--container">
            {editAudienceFields.facebook_connect && (
              <button
                onClick={() => setActiveMedia("fb")}
                style={buttonStyles}
                className={
                  activeMedia === "fb"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary"
                }
              >
                Facebook
              </button>
            )}

            {/* {console.log("campaign data inside edit popup", campaignData)}

            {console.log("edit audience fields", editAudienceFields)} */}

            {editAudienceFields.google_connect &&
              (campaignData.objective === "CLICK" ||
                campaignData.objective === "IMPRESSION") && (
                <button
                  style={{ ...buttonStyles, marginLeft: "5%" }}
                  onClick={() => setActiveMedia("google")}
                  className={
                    activeMedia === "google"
                      ? "btn btn-primary"
                      : "btn btn-outline-primary"
                  }
                >
                  Google
                </button>
              )}
          </div>

          {activeMedia === "fb" && (
            <FbIncludeAudience
              getSelectValue={getSelectValue}
              onSelectChange={onSelectChange}
              errors={errors}
              selectStyles={selectStyles}
              audienceListOptions={audienceListOptions}
              interestsList={interestsList}
              audienceData={audienceData}
            />
          )}

          {activeMedia === "fb" && (
            <FbNarrowAudience
              audienceList={audienceList}
              getSelectValue={getSelectValue}
              onSelectChange={onSelectChange}
              selectStyles={selectStyles}
              audienceListOptions={audienceListOptions}
              interestsList={interestsList}
              audienceData={audienceData}
            />
          )}

          {activeMedia === "fb" && (
            <FbExcludeAudience
              audienceList={audienceList}
              getSelectValue={getSelectValue}
              onSelectChange={onSelectChange}
              selectStyles={selectStyles}
              audienceListOptions={audienceListOptions}
              interestsList={interestsList}
              audienceData={audienceData}
            />
          )}

          {activeMedia === "google" && (
            <GoogleAudienceAffinity
              audienceList={audienceList}
              getSelectValue={getSelectValue}
              onSelectChange={onSelectChange}
              selectStyles={selectStyles}
              interestsList={interestsList}
            />
          )}
        </div>
        <div
          className="d-flex justify-content-end"
          style={{
            padding: "10px 10px 10px 0px",
            borderTop: "1px solid #efefef",
          }}
        >
          {editAudienceId && editAudienceId > 0 ? (
            <button
              className="btn btn-primary"
              onClick={() => (buttonLoading ? null : updateAudience())}
            >
              {buttonLoading ? (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src="/images/assets/loading-button.gif"
                  alt=""
                />
              ) : (
                t("campaign_updateAud")
              )}
            </button>
          ) : (
            <button
              onClick={() => (buttonLoading ? null : addAudience())}
              className="btn btn-primary"
            >
              {buttonLoading ? (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src="/images/assets/loading-button.gif"
                  alt=""
                />
              ) : (
                t("campaign_addAud")
              )}
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditAudiencePopup;
