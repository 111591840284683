import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SingleAudience from "./singleAudience/SingleAudience";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import { toast } from "react-toastify";
import { InfiniteScrollLoader } from "../../../shared/InfiniteScroll/InfiniteScroll";
import EditAudiencePopup from "../../EditAudience/editAudiencePopup/EditAudiencePopup";
import "./SelectAudiences.css";
import { array } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "react-toastify/dist/ReactToastify.css";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import {
  onDeleteAudiences,
  onSendAudiences,
} from "../../../utils/network/lib/campaign";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  customTable: {
    borderRadius: "10px",
    boxShadow: "0px 14px 30px rgb(0 0 0 / 8%)",
    marginTop: "20px",
  },
});

toast.configure({
  position: toast.POSITION.BOTTOM_LEFT,
});

const SelectAudiences = ({
  audiences,
  campaignData,
  getAudienceList,
  setFinalBudgetPercentages,
  setTotalVolume,
  setPredictionVolume,
  minBudgetPercentage,
  myr3,
  isBudgetDivideEqually,
  editAudienceFields,
  getUpatemediaCreatives,
  setShowRecommendAudiencePopup,
  updateAssignAudience,
  nextCampaignId,
  setCampaignId,
  setCampaignStep4,
  setAudienceMergeDialogOpened,
  setTimeoutCount,
  getMediaDetails,
  setNotification,
  notification,
}) => {
  //States
  // const [selectedAudiences, setSelectedAudiences] = useState([]);
  const { t } = useTranslation();

  const history = useHistory();
  const classes = useStyles();

  const [selectedAudienceIds, setSelectedAudienceIds] = useState([]);

  const [totalBudget, setTotalBudget] = useState(0);

  const [budgetPercentages, setBudgetPercentages] = useState({});

  const [allAudiences, setAllAudiences] = useState({});

  const [indexesForBudgetError, setIndexesForBudgetError] = useState([]);

  const [toastErrors, setToastErrors] = useState({});

  const [showEditAudiencePopup, setShowEditAudiencePopup] = useState(false);

  const [editAudienceId, setEditAudienceId] = useState("");

  // Effects
  useEffect(() => {
    if (campaignData) {
      setTotalBudget(campaignData.budget);
    }
  }, [campaignData]);

  useEffect(() => {
    console.log("audience change is detected");
    if (audiences && audiences.length > 0) setInitialPercentages();
    setInitialAudiences();
  }, [audiences]);

  useEffect(() => {
    setFinalBudgetPercentages(budgetPercentages);
  }, [budgetPercentages]);

  // clear edit audience id whenever popup close
  useEffect(() => {
    if (!showEditAudiencePopup) return setEditAudienceId("");
  }, [showEditAudiencePopup]);

  // Functions

  const setInitialAudiences = () => {
    console.log("set initial audiences is called");
    let array = [];
    if (audiences) {
      audiences.map((audience) => {
        const object = {};
        object.channel_budget = Object.values(
          decideChannelPercentages(audience)
        );
        object.id = audience.id;
        object.budget_percentage = audience.budget_percentage;
        array = [...array, object];
      });
      console.log("inside set initial audienece result", array);
      const isSplitOk = checkBudgetSplit(array);
      console.log("inside set initial audiences", isSplitOk);
      if (!isSplitOk) {
        setIndexesForBudgetError([array.length - 1]);
      }
    }
    return setAllAudiences(array);
  };

  const decideChannelPercentages = (audience) => {
    const object = {};
    if (audience.media_channel[4]) {
      object.fb = {
        channel_id: 4,
        budget_split: audience.media_channel[4].budget_split,
      };
    }
    if (audience.media_channel[5]) {
      object.ig = {
        channel_id: 5,
        budget_split: audience.media_channel[5].budget_split,
      };
    }
    if (audience.media_channel[7]) {
      object.g = {
        channel_id: 7,
        budget_split: audience.media_channel[7].budget_split,
      };
    }
    return object;
  };

  const setInitialPercentages = () => {
    console.log("set initial budget percentages is called");
    let object = {};
    audiences.map((audience) => {
      object[audience.id] = audience.budget_percentage;
    });
    console.log("initial budget percentages", object);
    setBudgetPercentages(object);
  };

  const updateAudiences = (index, oldAudience) => {
    let array = [...allAudiences];
    array.splice(index, 1, oldAudience);
    setAllAudiences(array);
    const isSplit100 = checkBudgetSplit(array);
    isBudgetDivideEqually(!isSplit100);
    if (isSplit100) {
      setIndexesForBudgetError([]);
    } else {
      const newIndexes = [...indexesForBudgetError, index];
      setIndexesForBudgetError(newIndexes);
    }
  };

  const checkBudgetSplit = (array) => {
    console.log("inside check budget split", array);
    let totalPercent = [];
    array.map(
      (audience) =>
        (totalPercent = [...totalPercent, audience.budget_percentage])
    );
    if (
      totalPercent.length > 0 &&
      parseInt(totalPercent.reduce((a, b) => Number(a) + Number(b))) === 100
    )
      return true;
    else return false;
  };

  const sendAudiences = () => {
    const isSplitOk = checkBudgetSplit(allAudiences);

    if (!isSplitOk) return toast.error(t("campaign_budgetSplitMustBeHundred"));

    onSendAudiences({
      audience_list: allAudiences,
    })
      .then((res) => {
        if (res.data.data) getAudienceList();
        window.$peasy.triggerEvent({
          event_name: "info",
          event_params: { id: "10" },
        });
        return toast.success(t("campaign_audienceUpdatedSuccessfully"));
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const deleteAudiences = () => {
    if (selectedAudienceIds.length === 0) {
      return toast.info(t("campaing_selAudienceBeforeDel"));
    }

    onDeleteAudiences({
      audience_ids: selectedAudienceIds,
    })
      .then((res) => {
        toast.success(t("campaign_audienceDeletedSuccessfully"));
        window.$peasy.triggerEvent({
          event_name: "info",
          event_params: { id: "10" },
        });
        getUpatemediaCreatives();
        // updateAdCardAudience;
        return getAudienceList();
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const getSetVolume = (index, volume) => {
    setTotalVolume((array) => {
      let newArray = [...array];
      newArray.splice(index, 0, volume);
      return newArray;
    });
    setPredictionVolume((array) => {
      let newArray = [...array];
      newArray.splice(index, 1, volume);
      return newArray;
    });
  };

  const getCpaText = () => {
    if (campaignData) {
      switch (campaignData.objective.toLowerCase()) {
        case "impression":
          return "Est. CPM ";
        case "click":
          return "Est. CPC";
        case "post_engagement":
          return "Est. CPE";
        case "traffic":
          return "Est. CPT";
        case "conversion":
          return "Est. CPA";
        case "sales":
          return "Est. CPA";
        case "lead":
          return "Est. CPL";
        default:
          return "Est. CPE";
      }
    } else return "Est. CPE";
  };

  const editAudience = (audience) => {
    setEditAudienceId(audience.id);
    setShowEditAudiencePopup(true);
  };

  // console.log("all audiences", allAudiences);

  return (
    <React.Fragment>
      <div className="selectaudiences__container">
        <TableContainer component={Paper} className={classes.customTable}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">{t("campaign_select")}</TableCell>
                <TableCell align="left">{t("campaign_audienceName")}</TableCell>
                <TableCell align="left">
                  {t("campaign_budgetHeading")}%
                </TableCell>
                <TableCell align="center">{t("campaign_channel")}</TableCell>
                <TableCell align="left">{t("campaign_estPopulatn")}</TableCell>
                <TableCell align="left">{t("campaign_estCPA")}</TableCell>
                <TableCell align="left">{t("campaign_estVolume")}</TableCell>
                <TableCell align="left">{t("campaign_description")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {audiences &&
                audiences.length > 0 &&
                audiences.map((audience, index) => (
                  <SingleAudience
                    totalBudget={totalBudget}
                    audience={audience}
                    key={audience.id}
                    setBudgetPercentages={setBudgetPercentages}
                    budgetPercentages={budgetPercentages}
                    selectedAudienceIds={selectedAudienceIds}
                    setSelectedAudienceIds={setSelectedAudienceIds}
                    updateAudiences={updateAudiences}
                    campaignData={campaignData}
                    getSetVolume={getSetVolume}
                    index={index}
                    indexesForBudgetError={indexesForBudgetError}
                    minBudgetPercentage={minBudgetPercentage}
                    myr3={myr3}
                    toastErrors={toastErrors}
                    setToastErrors={setToastErrors}
                    editAudience={editAudience}
                    getCpaText={getCpaText}
                    setNotification={setNotification}
                    notification={notification}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!audiences && (
          <div className="d-flex align-items-center justify-content-center">
            <InfiniteScrollLoader
              style={{ marginTop: "10px", marginBottom: "50px" }}
            />
          </div>
        )}
        {audiences && audiences.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "100px",
              boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.06)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            {t("campaign_noAudToDisplay")}
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <button
            onClick={() => {
              setShowRecommendAudiencePopup(true);
            }}
            className="btn btn-primary"
            style={{ marginLeft: "10px", fontSize: "12px" }}
          >
            {t("campaign_recommendAud")}
          </button>
          <button
            style={{ marginLeft: "10px", fontSize: "12px" }}
            className="btn btn-outline-primary"
            onClick={() => deleteAudiences()}
          >
            {t("campaign_del")}
          </button>
          <button
            style={{ marginLeft: "10px", fontSize: "12px" }}
            className="btn btn-outline-primary"
            onClick={() => sendAudiences()}
          >
            {t("campaign_update")}
          </button>
          <button
            onClick={() => {
              setShowEditAudiencePopup(true);

              window.$peasy.triggerEvent({
                event_name: "info",
                event_params: { id: "10" },
              });
            }}
            style={{ marginLeft: "10px", fontSize: "12px" }}
            className="btn btn-primary"
          >
            {t("campaign_add")}
          </button>
        </div>
      </div>

      {showEditAudiencePopup && (
        <EditAudiencePopup
          setShowEditAudiencePopup={setShowEditAudiencePopup}
          editAudienceId={editAudienceId}
          campaignData={campaignData}
          editAudienceFields={editAudienceFields}
          getAudienceList={getAudienceList}
          setCampaignStep4={setCampaignStep4}
          setAudienceMergeDialogOpened={setAudienceMergeDialogOpened}
          setTimeoutCount={setTimeoutCount}
          getMediaDetails={getMediaDetails}
        />
      )}
    </React.Fragment>
  );
};

export default SelectAudiences;
