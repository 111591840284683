import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/campaign"
const audiencePrefix = prefix + "/audience"
const audiencesPrefix = prefix + "/audiences"

const balancePrefix = prefix + "/balance"
const notificationPrefix = prefix + "/notifications"

export function onGetLeadFormsList(payload) {
    return http.get(prefix + '/leadForms', payload);
}

export function onPauseCampaign(payload) {
    return http.get(prefix + '/pause', payload);
}

export function onSendAudiences(payload) {
    return http.post(audiencePrefix + '/update', payload);
}

export function onDeleteAudiences(payload) {
    return http.post(audiencePrefix + '/delete', payload);
}

export function onStartCampaign(payload) {
    return http.get(prefix + '/start', payload);
}

export function onRedeployCampaign(payload) {
    return http.get(prefix + '/deploy', payload);
}

export function onCheckCampaignBalance(type) {
    return http.get(balancePrefix + "/" + type);
}

export function onGetNotifications(campaign_id) {
    return PeasyApi.get("api/v1/campaign/notifications/" + campaign_id);
}

export function onGetAudienceList(payload) {
    return http
        .get(audiencesPrefix, payload)
}

export function onEditCampaignData(campaign_id, predictionFormData) {
    return http
        .put(prefix + `/edit/${campaign_id}`, predictionFormData)
}

export function onDeployCampaign(payload) {
    return http
        .get(prefix + `/deploy`, payload)
}

export function onSavePosts(campaign_id, payload) {
    return http
        .post(prefix + `/media/${campaign_id}`, payload)
}

export function onGetCampaignDetails(payload) {
    return http
        .get(prefix + '/details' + payload)
}

export function onConfirmDeleteCampaign(deleteCampaignId) {
    return http
        .delete(base + `/campaign-delete/${deleteCampaignId}`)
}



export function onGetCampaignMetrics(payload) {
    return http
        .get(`${base}/campaignmetric${payload}`)
}

