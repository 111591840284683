import React, { useState } from "react";
import { toast } from "react-toastify";
import { Traffic } from "../../../StaticArray/StaticArray";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const FbMessenger = (props) => {
  const { t } = useTranslation();

  const [collapse_open, setCollapseOpen] = useState();

  const collapse = () => {
    setCollapseOpen(!collapse_open);
  };

  const gobackprevious = () => {
    props.prevStep();
  };

  const saveAndContinue = () => {
    const { platform } = props.values;
    //   if (platform) props.nextStep();
    //   else toast.error("Please Select a Platform !",{
    //     position: toast.POSITION.BOTTOM_LEFT
    // });
    if (platform) {
      if (props.objective === Traffic) props.nextStep(1);
      else props.nextStep();
    } else
      toast.error(t("campaign_selectPlatform"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const submitPlatform = (val) => {
    props.handleChange("platform", val);
    collapse();
  };

  const listStyles = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "3%",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const displayPlatformName = (name) => {
    if (name === "WEBSITE") return "Website";
    if (name === "MESSENGER") return "Messenger";
  };

  return (
    <div className="outer_div w-100 animated">
      <div className="col-12 px-0 tab-pane border-rounded p-3 m-2" id="step6">
        <div className="form-group brand-custom-form brand-custom-dropdown mt-2">
          <label>{t("campaign_whereToTakeBusiness")} </label>
          <p className="question-description mb-2">
            {t("brand_whereWillAudienceLand")}
          </p>

          <button
            type="button"
            className="form-control brand-dropdown"
            onClick={() => collapse()}
          >
            {displayPlatformName(props.values.platform)}
            <i className="fa fa-caret-down float-right"></i>
          </button>
          <div
            id="catefory"
            className={collapse_open == true ? "collapse show" : "collapse"}
          >
            <ul className="list-unstyled">
              <li
                style={listStyles}
                onClick={() => submitPlatform("MESSENGER")}
              >
                {t("campaign_fbMessenger")}
              </li>
              <li style={listStyles} onClick={() => submitPlatform("WEBSITE")}>
                {t("campaign_website")}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              className="brandprofilecard__btn--white"
              onClick={gobackprevious}
            >
              {t("campaign_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              className="brandoverviewleftsection__btn--purple"
              onClick={saveAndContinue}
            >
              {t("campaign_next")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FbMessenger;
