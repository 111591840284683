export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_ERROR_MESSAGE = "LOGIN_ERROR_MESSAGE";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const GET_ALL_PACKAGE_PERMISSIONS = "GET_ALL_PACKAGE_PERMISSIONS";
export const GET_ALL_PACKAGE_PERMISSIONS_SUCCSESS = "GET_ALL_PACKAGE_PERMISSIONS_SUCCSESS";
export const CURRENT_PACKAGE_PERMISSIONS_SUCCESS = "CURRENT_PACKAGE_PERMISSIONS_SUCCESS";
export const ADD_NEW_PERMISSION_SUCCESS = "ADD_NEW_PERMISSION_SUCCESS";
export const UPDATE_NEW_PERMISSION_SUCCESS = "UPDATE_NEW_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";


export const GET_ALL_PACKAGES_SUCCESS = "GET_ALL_PACKAGES_SUCCESS";
export const GET_CURRENT_PACKAGE_SUCCESS = "GET_CURRENT_PACKAGE_SUCCESS";
export const ADD_NEW_PACKAGE_SUCCESS = "ADD_NEW_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const PACKAGE_LOADING = 'PACKAGE_LOADING';

export const GET_ALL_FEATURES_SUCCESS = "GET_ALL_FEATURES_SUCCESS";
export const GET_CURRENT_FEATURE_SUCCESS = "GET_CURRENT_FEATURE_SUCCESS";
export const ADD_NEW_FEATURE_SUCCESS = "ADD_NEW_FEATURE_SUCCESS";
export const UPDATE_FEATURE_SUCCESS = "UPDATE_FEATURE_SUCCESS";
export const DELETE_FEATURE_SUCCESS = "DELETE_FEATURE_SUCCESS";
export const FILTER_PACKAGE = "FILTER_PACKAGE";

export const ADD_FILTER_PACKAGE_SUCCESS = "ADD_FILTER_PACKAGE_SUCCESS";