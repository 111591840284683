import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import http from "../../../shared/http-request";
import Axios from "axios";
import { SaveCampaign } from "../campaignCreateFunctions";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";

class CampaignLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getLocation: "",
      suggestion_list: "",
      locations: "",
      selected_location: this.props.values.locationSelectd,
      position_dropdown: false,
      typing: false,
      typingTimeout: 0,
      loading: false,
    };
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.GetSuggestionList = this.GetSuggestionList.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.CloseDropdown = this.CloseDropdown.bind(this);
    this.removeLocation = this.removeLocation.bind(this);
    this.ProcessNext = this.ProcessNext.bind(this);
    this.cancel = ""; //to cancel previous request
    // this.checkLocationOverLapping = this.checkLocationOverLapping.bind(this);
    this.TriggerStopUserTyping = this.TriggerStopUserTyping.bind(this);
    this.createCampaignClick = this.createCampaignClick.bind(this);
    this.handleErrorLoading = this.handleErrorLoading.bind(this);
  }

  //In Drop Down when we select one of the options then this function will be trigger
  selectLocation(text, value) {
    console.log("called Selected location");
    const { selected_location } = this.state;
    const newarray = [];
    for (const key in selected_location) {
      newarray[key] = selected_location[key];
    }
    newarray[value] = text;
    // this.checkLocationOverLapping(newarray);

    this.setState({
      selected_location: newarray,
      locations: "",
    });

    this.props.handleChange("locationSelectd", newarray);
  }

  //Check Overlapping of location
  /*checkLocationOverLapping(locations) {
        let token = '';
        try {
            token = this.props.values.api_token;
        } catch (err) {
            console.log(err);
            this.props.RefreshToken();
            this.checkLocationOverLapping(locations);
        }

        let config = {
            headers: {'Authorization': "Bearer " + token}
        };

        let bodyParameters = {
            locations: locations
        };

        Axios.post(this.props.BASE_URL + 'api/check-function-overlap',
            bodyParameters,
            config
        ).then(response => {
            console.log(response);
            return false;
        }).catch(err => console.log(err))
    }*/

  //on Typing in input box we are triggerring this function
  getLocation(val) {
    // let val = event.target.value;
    let token = "";
    try {
      token = this.props.values.api_token;
    } catch (err) {
      console.log(err);
      this.props.RefreshToken();
      this.getLocation();
    }
    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = Axios.CancelToken.source();

    let config = {
      headers: { Authorization: "Bearer " + token },
      cancelToken: this.cancel.token,
    };

    let bodyParameters = {
      value: val,
    };

    Axios.post(
      this.props.BASE_URL + "api/get-locations",
      bodyParameters,
      config
    )
      .then((response) => {
        if (response.data.status === "Token is Expired") {
          this.props.RefreshToken();
          this.getLocation(val);
        } else {
          this.setState({
            suggestion_list: response.data,
            position_dropdown: true,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  TriggerStopUserTyping(event) {
    let val = event.target.value;

    this.setState({
      locations: val,
    });

    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      typing: false,
      typingTimeout: setTimeout(function () {
        console.log("Called");
        self.getLocation(val);
      }, 500),
    });
  }

  // On removing locations from selected locations
  removeLocation(val) {
    const { selected_location } = this.state;
    const newarray = [];
    for (const key in selected_location) {
      if (val != key) {
        newarray[key] = selected_location[key];
      }
    }
    this.setState(
      {
        selected_location: newarray,
      },
      () => this.props.handleChange("locationSelectd", newarray)
    );
  }

  //After Typing as per word we are getting suggestion and convert it into HTML list
  GetSuggestionList(SuggestionList) {
    const that = this;
    return (
      <ul className="list_event_list">
        {Object.keys(SuggestionList).map((k) => (
          <li
            onClick={() =>
              that.selectLocation(
                SuggestionList[k].text,
                SuggestionList[k].value
              )
            }
            key={k}
          >
            {SuggestionList[k].text}
          </li>
        ))}
      </ul>
    );
  }

  //After selecting one of the location are made a list of Selected location so that we can use it in future and client can see it
  GetSelectedLocationList(selected_location) {
    console.log("selected location list", selected_location);
    return (
      <div>
        {Object.keys(selected_location).map((k) => (
          <li key={k}>
            {selected_location[k]}
            <span onClick={() => this.removeLocation(k, selected_location[k])}>
              <i className="fa fa-times" />
            </span>
          </li>
        ))}
      </div>
    );
  }

  //TO close Drop of suggestion
  CloseDropdown() {
    console.log("close drop down");
    this.setState({
      position_dropdown: false,
      suggestion_list: "",
    });
  }

  ProcessNext(event) {
    if (event.key === "Enter") {
      this.saveAndContinue();
    }
  }

  saveAndContinue() {
    const { selected_location } = this.state;
    if (Object.keys(selected_location).length === 0) {
      toast.error(this.props.t("campaign_selectLocation"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      let locations = [];
      for (const key in selected_location) {
        console.log("to add - ", selected_location[key]);
        locations = [...locations, selected_location[key]];
        console.log("after add", locations);
      }
      console.log("when sendling", locations);
      http
        .post(this.props.BASE_URL + "api/check-function-overlap", {
          locations,
        })
        .then((response) => {
          console.log("response - ", response);
          if (response.data.error) {
            toast.error(response.data.error, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else {
            this.props.handleChange("locationSelectd", selected_location);
            this.props.nextStep();
            toast.dismiss();
          }
        })
        .catch((err) => console.log(err));
    }
  }

  gobackprevious() {
    if (this.props.funnel_type === 2) {
      return this.props.goBack();
    }
    this.props.prevStep();
  }

  handleErrorLoading() {
    this.setState({ loading: false });
  }

  createCampaignClick() {
    const { selected_location } = this.state;
    if (Object.keys(selected_location).length === 0) {
      toast.error(this.props.t("campaign_selectLocation"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      let locations = [];
      for (const key in selected_location) {
        locations = [...locations, selected_location[key]];
      }
      http
        .post(this.props.BASE_URL + "api/check-function-overlap", {
          locations,
        })
        .then((response) => {
          if (response.data.error) {
            toast.error(response.data.error, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else {
            console.log("inside else of overlap");
            this.setState({ loading: true });
            SaveCampaign(
              this.props.values,
              this.props.RefreshToken,
              this.props.CheckObjective,
              this.props.handleChange,
              () => console.log("save campaign from campaign location called"),
              this.handleErrorLoading,
              this.props.history
            );
          }
        })
        .catch((err) => console.log(err));
    }
  }

  render() {
    const { suggestion_list } = this.state;
    const { selected_location } = this.state;
    const Locations_Suggestions = this.GetSuggestionList(suggestion_list);
    const already_selected = this.props.values.locationSelectd;

    let selected_locations_list =
      this.GetSelectedLocationList(selected_location);
    if (Object.keys(already_selected).length !== 0) {
      selected_locations_list = this.GetSelectedLocationList(already_selected);
    }
    console.log("to show list", selected_locations_list);

    return (
      <div className="outer_div w-100" onClick={this.CloseDropdown}>
        <div className="col-12 px-0 border-rounded p-3 mb-2" id="step12">
          <div className="form-group brand-custom-form mt-2">
            <label>{this.props.t("campaign_targetLocation")}</label>
            <p className="question-description mb-3">
            {this.props.t("campaign_specifyingMultipleLocations")}
             
            </p>
            <div className="custom_Wrapper_Selectajax">
              <input
                type="text"
                className="form-control"
                placeholder={this.props.t("campaign_enterTargetLocation")}
                onKeyPress={this.ProcessNext}
                onChange={this.TriggerStopUserTyping}
                value={this.state.locations}
              />
              <div className="selected_location_wrapper">
                <ul>{selected_locations_list}</ul>
              </div>
              <div
                className={
                  this.state.position_dropdown
                    ? "suggesstion_list_location active_opened"
                    : "suggesstion_list_location"
                }
              >
                {Locations_Suggestions}
              </div>
            </div>
          </div>
        </div>

        <div className="navination_bar">
          <div className="bar_search d-flex">
            <div className="above_navigation_box ml-auto">
              <button
                className="brandprofilecard__btn--white"
                onClick={this.gobackprevious}
              >
                {this.props.t("campaign_back")}
              </button>
            </div>
            <div className="below_navigation_box">
              {this.state.loading ? (
                <button
                  type="button"
                  className="btn go_next brandoverviewleftsection__btn--purple"
                >
                  <span
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {" "}
                    {this.props.t("campaign_creatingCampaignText")}
                  </span>
                  <img
                    src="/images/assets/loading-button.gif"
                    alt=""
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "10px",
                    }}
                  />
                </button>
              ) : (
                <button
                  disable={this.state.loading}
                  className="btn go_next dsfsdf brandoverviewleftsection__btn--purple"
                  onClick={this.createCampaignClick}
                >
                  {" "}
                  {this.props.t("campaign_createCampaignText")}
                </button>
              )}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CampaignLocation));
