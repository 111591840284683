import React, { Component, Fragment } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import "./uploadTextFiles.css";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@material-ui/core";
import { onFileUpload } from "../../../utils/network/lib/misc";
import { onImportLeads } from "../../../utils/network/lib/lead";
export default class UploadTextFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      helperMessage: "",
      path: "",
      isLoading: false,
      fileUpload: false,
    };
  }

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      this.setState({
        files: acceptedFiles,
      });
    } else {
      this.setState({ helperMessage: "* Only Csv files are allowed" });
    }
  };

  onImport = async () => {
    const { files } = this.state;
    let acceptedFiles = files;
    let file_object = new FormData();
    if (acceptedFiles.length > 0) {
      file_object.append("file_object", acceptedFiles[0]);
      onFileUpload(file_object).then((response) => {
        debugger;
        if (response.data.status === true) {
          toast.success("File Upload successfull", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          this.setState({
            fileUpload: true,
            path: response.data.path,
            helperMessage: "",
          });
        }
      });
    } else {
      this.setState({ helperMessage: "Please select a file before Importing" });
    }
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });

    const { files } = this.state;
    let acceptedFiles = files;
    let file_object = new FormData();
    if (acceptedFiles.length > 0) {
      file_object.append("file_object", acceptedFiles[0]);
      onFileUpload(file_object).then((response) => {
        if (response.data.status === true) {
          let request_data = new FormData();
          let path = response.data.path;
          request_data.append("path", path);
          request_data.append("brand_profile_id", this.props.brand_profile_id);

          if (path.length === 0) {
            this.setState({
              helperMessage: "* Please upload a file first to import",
              isLoading: false,
            });
          } else {
            onImportLeads(request_data).then((response) => {
              this.setState({ isLoading: false });
              if (response.data.status) {
                this.props.onSuccessImport(
                  response.data.message,
                  response.data.status
                );
              }
            });
          }
        } else {
          toast.error("Error While uploading file ,Please try again", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          this.setState({ isLoading: false });
        }
      });
    } else {
      this.setState({
        helperMessage: "Please select a file before Importing",
        isLoading: false,
      });
    }
  };

  downloadSample = () => {
    http
      .get(BASE_URL + `file/download?path=output/sample-lead-import.csv`)
      .then((response) => {
        if (response.status == 200) {
          let data = new Blob([response.data], { type: "text/csv" });
          let csvUrl = window.URL.createObjectURL(data);
          let tempLink = document.createElement("a");
          tempLink.href = csvUrl;
          tempLink.setAttribute("download", "sample-lead-import.csv");
          tempLink.click();
          this.setState({ isExportLoading: false });
        } else {
          toast.error("Oops! Something went wrong", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      })
      .catch((err) => {
        toast.error("Oops! Something went wrong", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
  removeFile = () => {
    this.setState({ files: [], helperMessage: "" });
  };
  render() {
    const { isLoading } = this.state;
    const files = this.state.files.map((file) => (
      <li key={file.name} style={{ fontSize: "16px" }}>
        {file.name} &nbsp; &nbsp;
        <span onClick={this.removeFile} style={{ cursor: "pointer" }}>
          <i className="fas fa-times" style={{ color: "#8d00fa" }}></i>
        </span>
      </li>
    ));
    return (
      <Fragment>
        <div className="uploadFilePopup__backdrop"></div>

        <div className="selectpostpopup__container__uploadText">
          {this.state.files.length == 0 && (
            <Dropzone
              onDrop={this.onDrop}
              accept=".csv"
              styles={{
                dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
                inputLabel: (files, extra) =>
                  extra.reject ? { color: "red" } : {},
              }}
            >
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    {...getRootProps()}
                    className={classNames("dropzone", {
                      "dropzone--isActive": isDragActive,
                    })}
                    style={{
                      // height: "142px !important",
                      textAlign: "center",
                      // border: "1px solid black",
                      margin: "14px auto auto auto",
                      // width: "98%",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p
                        style={{
                          marginBottom: "45px",
                          marginTop: "45px",
                        }}
                      >
                        Drop files here...
                      </p>
                    ) : (
                      <>
                        <div className="uploadContent">
                          <div className="dragIcon">
                            <svg
                              width={78}
                              height={78}
                              viewBox="0 0 78 78"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M62.8875 32.63C60.6775 21.4175 50.83 13 39 13C29.6075 13 21.45 18.33 17.3875 26.13C7.605 27.17 0 35.4575 0 45.5C0 56.2575 8.7425 65 19.5 65H61.75C70.72 65 78 57.72 78 48.75C78 40.17 71.3375 33.215 62.8875 32.63ZM45.5 42.25V55.25H32.5V42.25H22.75L39 26L55.25 42.25H45.5Z"
                                fill="#8D00FA"
                              />
                            </svg>
                          </div>
                          <h6>Drag and drop CSV file here</h6>
                          <p>
                            or <span className="highLight">Browse</span> to
                            choose a csv file
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          )}
          {this.state.files.length == 0 && (
            <div className="uploadContent__header">
              <br />
              <p>
                Download Sample Format{" "}
                <a href="#" onClick={this.downloadSample}>
                  <span className="highLight"> here ..</span>
                </a>{" "}
              </p>
            </div>
          )}
          {this.state.files.length > 0 && (
            <div style={{ padding: "25px" }}>
              <label>
                <h2>Uploaded File </h2>
              </label>
              <ul style={{ padding: "1rem" }}>{files}</ul>
            </div>
          )}
          {this.state.helperMessage && (
            <p style={{ padding: "25px" }}>{this.state.helperMessage}</p>
          )}
          <ul className="modalFooterBtn">
            <li>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  this.props.onImportbuttonClicked();
                }}
                // className="btn btn-outline-primary"
              >
                Cancel
              </Button>
            </li>
            <li>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={this.handleSubmit}
                // className="btn btn-primary"
                disabled={isLoading}
              >
                {isLoading ? "Importing..." : "Import"}
              </Button>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}
