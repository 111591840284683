import React, { useEffect, useState } from "react";
import { components } from "react-select";

const CustomSelectOption = ({
  audienceData,
  parentComponent,
  children,
  data,
  ...props
}) => {
  const values = children.split("|");
  const [loading, setLoading] = useState(true);
  const [audienceInterestIdList, setAudienceInterestIdList] = useState([]);
  const [audienceNarrowIdList, setAudienceNarrowIdList] = useState([]);
  const [audienceExcludeIdList, setAudienceExcludeIdList] = useState([]);
  let label = "";
  let type = "";

  if (values[0]) {
    label = values[0];
  }

  if (values[1]) {
    type = values[1];
  }

  const audienceId = data.value;

  // const interestList = audienceData.interest.split("><");
  // interestList.map((interest) => {});

  useEffect(() => {
    setLoading(true);
    const interestList = audienceData.interest
      ? audienceData.interest.split("><")
      : [];
    let audienceInterestIds = interestList.map(
      (interest) => interest.split("|")[0]
    );
    setAudienceInterestIdList(audienceInterestIds);
    const narrowList = audienceData.narrow
      ? audienceData.narrow.split("><")
      : [];
    let audienceNarrowIds = narrowList.map((narrow) => narrow.split("|")[0]);
    setAudienceNarrowIdList(audienceNarrowIds);
    const excludeList = audienceData.exclude
      ? audienceData.exclude.split("><")
      : [];
    let audienceExludeIds = excludeList.map((exclude) => exclude.split("|")[0]);
    setAudienceExcludeIdList(audienceExludeIds);
    setLoading(false);
  }, [audienceData]);

  if (loading) {
    return null;
  }

  if (parentComponent && Object.keys(audienceData).length > 0) {
    switch (parentComponent) {
      case "fbNarrow":
        if (audienceInterestIdList.includes(audienceId)) return null;
        if (audienceExcludeIdList.includes(audienceId)) return null;
        break;
      case "fbInclude":
        if (audienceNarrowIdList.includes(audienceId)) return null;
        if (audienceExcludeIdList.includes(audienceId)) return null;
        break;
      case "fbExclude":
        if (audienceInterestIdList.includes(audienceId)) return null;
        if (audienceNarrowIdList.includes(audienceId)) return null;
        break;
      default:
        return null;
    }
  }

  return (
    <components.Option {...props}>
      <span>{label}</span>
      <span style={{ float: "right" }}>{type.split("_").join(" ")}</span>
    </components.Option>
  );
};

export default CustomSelectOption;
