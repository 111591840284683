import React, { Component } from "react";
import { toast } from "react-toastify";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../../utils/peasy.api";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";
import CheckDialog from "../../CheckDialog";
import {
  onGetCampaignDetails,
  onGetLeadFormsList,
} from "../../../utils/network/lib/campaign";
import { onCheckLeadTOS } from "../../../utils/network/lib/misc";
import "./steps.css";

class LeadForm extends Component {
  constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
    this.collapse = this.collapse.bind(this);
    this.submitvalue = this.submitvalue.bind(this);
    this.getLiHtml = this.getLiHtml.bind(this);
    this.getLeadFormsList = this.getLeadFormsList.bind(this);
    // this.openLeadFormDialog = this.openLeadFormDialog.bind(this);
    this.state = {
      lead_form_list: null,
      form_id: 0,
      collapse_open: false,
      load_list: false,
      brand_id: this.props.state.brand_id,
      page_id: this.props.state.page_id,
      account_id: this.props.state.account_id,
      open: false,
      url: "",
      message: "",
      srcImg: "/images/assets/Failure.svg",
      target: "",
      buttonText: "",
      disabled: false,
    };
  }

  getLeadFormsList() {
    const { brand_id } = this.state;
    onGetLeadFormsList({
      params: {
        brand_profile_id: brand_id,
      },
    })
      .then((res) => {
        this.setState({
          lead_form_list: res.data,
        });
        if (res.data.length === 0) {
          toast.error(this.props.t("campaign_noListData"), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  }

  loadFBApp() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "544831322621153",
        status: true,
        xfbml: true,
        version: "v10.0", // or v2.6, v2.5, v2.4, v2.3
      });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src =
        "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.8&appId=544831322621153";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
  componentWillMount() {
    this.loadFBApp();
  }
  componentDidMount() {
    this.getLeadFormsList();
  }
/*
  openLeadFormDialog() {
    console.log("Lead form dialog", this.state);
    var data = this.state.account_id.split("_");
    if (data[1]) {
      var numActId = data[1];
      window.FB.ui(
        {
          account_id: numActId,
          display: "popup",
          method: "lead_gen",
          page_id: this.state.page_id,
        },
        (response) => {
          this.getLeadFormsList();
        }
      );
    } else {
      toast.error(this.props.t("campaign_gettingAccIdFailed"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }
*/

  collapse() {
    this.setState({
      collapse_open: !this.state.collapse_open,
    });
  }

  saveAndContinue = async () => {
    const { brand_id } = this.state;
    try {
      const tosData = await onCheckLeadTOS({
        brand_profile_id: brand_id,
      });
      if (tosData.lead_tos.status === false) {
        let leadTosData = tosData.lead_tos;
        this.setState({
          open: true,
          url: leadTosData.callback_url,
          message: leadTosData.message,
          target: leadTosData.target,
          buttonText: leadTosData.callback_button_text,
        });
        this.setState({ disabled: false });
        return;
      }
      this.setState({ disabled: false });
    } catch (e) {
      toast.error(this.props.t("campaign_somethingWrongWhileAction"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      this.setState({ disabled: false });
    }

    const { form_id } = this.props.state;
    if (form_id == 0) {
      toast.error(this.props.t("campaign_selectOnePixelevent"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      this.setState({ disabled: false });
    } else {
      this.props.nextStep();
      toast.dismiss();
    }
  };

  gobackprevious() {
    this.props.prevStep();
  }

  submitvalue(event, value, dropdowntext) {
    this.props.handleChange(event, value);
    this.props.handleChange("lead_form_text", dropdowntext);

    //Close Drop down
    this.setState({
      collapse_open: !this.state.collapse_open,
    });

    //Set value
    this.setState({
      form_id: value,
    });
  }

  getLiHtml(leadFormList) {
    var that = this;
    return (
      <div className="list_event_list">
        <ul>
          {leadFormList
            ? Object.keys(leadFormList)
                .filter((leadForm) => {
                  if (leadFormList[leadForm].status === "ACTIVE") {
                    //here we allowed only those leadforms that have status of archived then applied map
                    return true;
                  }
                })
                .map((k) => {
                  return (
                    <li
                      className="custom-control custom-radio dropdown-item"
                      key={leadFormList[k].id + leadFormList[k].name}
                    >
                      <input
                        type="radio"
                        name="form_id"
                        className="custom-control-input"
                        id={leadFormList[k].id + leadFormList[k].name}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={leadFormList[k].id + leadFormList[k].name}
                        onClick={() =>
                          that.submitvalue(
                            "form_id",
                            leadFormList[k].id,
                            leadFormList[k].name
                          )
                        }
                      >
                        {leadFormList[k].name}
                      </label>
                    </li>
                  );
                })
            : ""}
        </ul>
      </div>
    );
  }

  render() {
    const { lead_form_list } = this.state;
    const formList = lead_form_list;
    // var pixelList = JSON.parse(pixel_event_list.replace(/&quot;/g,'"'));
    const items = this.getLiHtml(formList);

    return (
      <div className="outer_div w-100 animated">
        <CheckDialog
          // this is a check dialog for checking account validity
          open={this.state.open}
          setOpen={() => {
            this.setState({ open: false });
          }}
          url={this.state.url}
          message={this.state.message}
          srcImg="/images/assets/Failure.svg"
          target={this.state.target}
          buttonText={this.state.buttonText}
        />
        <div
          className="col-12 px-0 tab-pane border-rounded p-3 mb-2"
          id="step6"
        >
          <div className="form-group brand-custom-form brand-custom-dropdown mt-2">
            <label>{this.props.t("campaign_selectExistingForm")}</label>
            <p className="question-description mb-2">
              {this.props.t("campaign_chooseExistingFbLeadFormText")}{" "}
              <a
                target="_blank"
                href="https://peasy.ai/how-to-setup-your-facebook-lead-form/"
                style={{ color: "#6f1ddf" }}
              >
                {this.props.t("campaign_Here")}
              </a>
            </p>
          
            {/* 
            <button
              type="button"
              className="brandoverviewleftsection__btn--purple"
              onClick={this.openLeadFormDialog}
              style={{ marginBottom: "21px", marginLeft: "0px" }}
            >
              {this.props.t("campaign_createNewform")}
            </button> 
            */}
         
            <a 
              style={{ marginBottom: "21px", marginLeft: "0px", display:"inline-block" }} 
              className="brandoverviewleftsection__btn--purple create_fb_form_btn" href="https://business.facebook.com/latest/instant_forms/forms/" 
              target="_blank" 
              rel="noopener noreferrer"
            >
                {this.props.t("campaign_createNewform")}
            </a>
            <button
              type="button"
              className="form-control brand-dropdown"
              onClick={this.collapse}
            >
              {this.props.state.lead_form_text == ""
                ? this.props.t("campaign_leadFormsList")
                : this.props.state.lead_form_text}
              <i className="fa fa-caret-down float-right"></i>
            </button>
            <div
              id="catefory"
              className={
                this.state.collapse_open == true ? "collapse show" : "collapse"
              }
            >
              <ul className="list-unstyled">{items}</ul>
            </div>
          </div>
        </div>

        <div className="navination_bar">
          <div className="bar_search d-flex">
            <div className="above_navigation_box ml-auto">
              <button
                className="brandprofilecard__btn--white"
                onClick={this.gobackprevious}
              >
                {this.props.t("campaign_back")}
              </button>
            </div>
            <div
              className="below_navigation_box"
              style={{ marginRight: "0px" }}
            >
              <button
                className="brandoverviewleftsection__btn--purple"
                disabled={this.state.disabled}
                onClick={() => {
                  this.setState({ disabled: true });
                  this.saveAndContinue();
                }}
              >
                {this.props.t("campaign_next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LeadForm);
