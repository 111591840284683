import React, { useEffect, useState, useRef } from "react";
import TagManager from "react-gtm-module";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, Link, useLocation } from "react-router-dom";
import http from "../../shared/http-request";
import { BASE_URL } from "../../StaticArray/StaticArray";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import "./Navbar.css";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import ReactReadMoreReadLess from "react-read-more-read-less";
import IconPopup from "react-icon-popup";
import {
  onGetNotificationByMessageId,
  onGetNotifications,
} from "../../utils/network/lib/notifications";
import { onUserLogout } from "../../utils/network/lib/auth";
import { EVENT_TYPE_CONTACT } from "../../utils/eventConstants";

const userData = JSON.parse(localStorage.getItem("user_data")) || {};
let alreadySent = JSON.parse(localStorage.getItem("alreadySent")) || null;

// const tagManagerArgs = {
//   gtmId: "GTM-TX74NMK",
//   userId: userData.id || "",
//   userName: userData.name || "",
//   userEmail: userData.email || "",
//   packageId: userData.active_package ? userData.active_package.id || 0 : 0,
//   packageName: userData.active_package
//     ? userData.active_package.name || "None"
//     : "None",
//   jobRole: userData.job || "None", //jobRole value
//   country: userData.country || "None", //country value
//   phoneNumber: userData.phone || "None", //phoneNumber value
//   currency: userData.currency_type || "None", //currency value
//   mediaSpend: userData.media_spend || "None", //mediaSpend value
//   haveAccount: userData.account_type || "None", //haveAccount value
// };

//TagManager.initialize(tagManagerArgs);

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const location = useLocation();

  // useEffect(() => {
  //   try {
  //     window.customSetUser();
  //   } catch (e) {
  //     console.log("Error Setting user", e);
  //   }
  // }, [window.dataLayer, window.$peasy, location.pathname]);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  useInterval(() => {
    // put your interval code here.
    getNotifications();
  }, 1000 * 20);

  const getNotifications = () => {
    onGetNotifications()
      .then((res) => {
        setNotifications(res.data);
        // console.log("notifications:", res);
        if (Array.isArray(res.data)) {
          let notificationItems = res.data;

          notificationItems.forEach((notificationItem) => {
            let id = null;
            let result = null;
            let msg = "";
            let notificationId = null;
            try {
              notificationId = notificationItem.id;

              id = notificationItem.params[0]?.id;
              result = notificationItem.result;
              msg = notificationItem.message;
              result = result?.toLowerCase();
            } catch (er) {
              console.log("NOTIFICATION_EVENT error", er);
            }

            if (result && notificationId) {
              if (alreadySent) {
                // console.log("alreadySent", alreadySent);
                if (alreadySent.includes(notificationId)) {
                  return;
                }
              } else {
                alreadySent = [];
              }

              if (id) {
                if (result === "error") {
                  if (["4", "5", "6", "7"].includes(id)) {
                    console.log("NOTIFICATION_EVENT called for", id);
                    window.$peasy.triggerEvent({
                      event_name: "error",
                      event_params: {
                        id: id,
                        type: EVENT_TYPE_CONTACT,
                        error_msg: msg,
                      },
                    });
                    alreadySent.push(notificationId);
                  }
                }
                if (result === "info") {
                  if (["16", "17", "18", "19", "20", "21"].includes(id)) {
                    console.log("NOTIFICATION_EVENT called for", id);

                    window.$peasy.triggerEvent({
                      event_name: "info",
                      event_params: {
                        id: id,
                      },
                    });
                    alreadySent.push(notificationId);
                  }
                }
              }

              if (result === "newlead") {
                console.log("NOTIFICATION_EVENT called for", result);
                window.$peasy.triggerEvent({
                  event_name: "newlead",
                  event_params: {},
                });
                alreadySent.push(notificationId);
              }

              if (result === "campaignEnd") {
                let firstParam = notificationItem.params[0];
                if (firstParam && userData) {
                  let plan_value = userData.active_package?.name || "" || "";
                  let total_spent_value =
                    userData.user_current_spend?.total_spend_amount || "";
                  let prepaid_balance_value = userData.prepaid_balance || "";
                  let campaign_id_value = firstParam.campaign_id || "";
                  let campaign_objective_value =
                    firstParam.campaign_objective || "";
                  let campaign_name_value = firstParam.campaign_name || "";

                  console.log("NOTIFICATION_EVENT called for", result);
                  window.$peasy.triggerEvent({
                    event_name: "campaignEnd",
                    event_params: {
                      plan: plan_value,
                      total_spent: total_spent_value,
                      prepaid_balance: prepaid_balance_value,
                      campaign_id: campaign_id_value,
                      campaign_objective: campaign_objective_value,
                      campaign_name: campaign_name_value,
                    },
                  });
                  alreadySent.push(notificationId);
                }
              }
              localStorage.setItem("alreadySent", JSON.stringify(alreadySent));
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const getNotification = (msg) => {
    onGetNotificationByMessageId(msg.id)
      .then((res) => {
        getNotifications();
        setOpen(false);
        history.push(`/${msg.redirect_url}`);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNotifications();
    addGistIdentify();
    window.customSetUser();
  }, []);

  const logout = () => {
    onUserLogout()
      .then((res) => {
        if (res.status === 204) {
          localStorage.removeItem("token_auth");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user_data");
          localStorage.removeItem("opened");
          localStorage.removeItem("status");
          localStorage.removeItem("objective");
          localStorage.removeItem("profile");
          localStorage.removeItem("alreadySent");
          window.location.href = "https://app.peasy.ai/login";
          // window.$peasy.reset();
        }
      })
      .catch((err) => {
        toast.error("Oops! Something went wrong", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const addGistIdentify = () => {
    window.gist.identify(
      localStorage.getItem("user_data") &&
        JSON.parse(localStorage.getItem("user_data")).id,
      {
        email: JSON.parse(localStorage.getItem("user_data")).email,
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid container-fluid-negative-margin">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Link to="/" className="nav nav-item">
            <div className="navbar-brand">
              {/* <img src="/images/assets/logo.png" alt="logo" /> */}
            </div>
          </Link>
          {/* <!-- React Menu Bar Component --> */}

          <div id="menubar"></div>

          <div className="navbar-nav ml-auto">
            <div className="tooltipBoundary">
              <IconPopup
                popupTriggerer={
                  <IconButton style={{ color: "black" }}>
                    <Badge badgeContent={notifications.length} color="primary">
                      <NotificationsIcon
                        style={{ marginBottom: "10px" }}
                        fontSize="large"
                      />
                    </Badge>
                  </IconButton>
                }
                arrowStyle={{ marginTop: "20px" }}
                popupMaxHeightInPx={400}
                popupStyle={{
                  overflow: "hidden",
                  marginTop: "20px",
                }}
                popupWidthInPx={500}
                popupContent={
                  <>
                    <Typography
                      variant="h5"
                      align="center"
                      style={{
                        color: "black",
                        borderBottom: "1px solid  #F0F0F0",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      Notifications
                    </Typography>
                    <div
                      style={{
                        height: "400px",
                        overflowY: "scroll",
                      }}
                    >
                      {notifications.length > 0 ? (
                        notifications.map((msg) => {
                          var date1 = new Date(msg.created_at);
                          var date2 = new Date();
                          let delta = Math.abs(date1 - date2) / 1000;
                          const days = Math.floor(delta / 86400);
                          delta -= days * 86400;
                          const hours = Math.floor(delta / 3600) % 24;
                          delta -= hours * 3600;
                          const minutes = Math.floor(delta / 60) % 60;

                          return (
                            <div
                              className="msg"
                              onClick={() => {
                                getNotification(msg);
                                //history.push(`/${msg.redirect_url}`);
                              }}
                            >
                              <h3>
                                <ReactReadMoreReadLess charLimit={50}>
                                  {msg.message}
                                </ReactReadMoreReadLess>
                              </h3>
                              <h5 style={{ marginTop: "5px" }}>
                                {`${days}d ${hours}h ${minutes}m `}
                              </h5>
                            </div>
                          );
                        })
                      ) : (
                        <div className="msg" onClick={() => {}}>
                          <h3>No new notifications</h3>
                        </div>
                      )}
                    </div>
                    {/* <Button
                      style={{
                        textAlign: "center",
                        width: "498px",
                        marginLeft: "-10px",
                        paddingTop: "10px",
                        borderTop: "1px solid #F0F0F0",
                      }}
                      variant="text"
                      color="primary"
                      onClick={() => setNotifications([])}
                    >
                      Mark All Read
                    </Button> */}
                  </>
                }
              />
            </div>

            <ul
              className="nav nav-item"
              style={{ marginRight: "50px", marginTop: "10px" }}
            >
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle navbar__btn--user"
                  id="navbarDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-user-circle username-icon" />
                  {JSON.parse(localStorage.getItem("user_data")).name || "User"}
                  <span className="caret"></span>
                </button>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {/* <a
                    className="dropdown-item"
                    target="_blank"
                    href="http://peasy.ai/docs/peasymarketing"
                  >
                    Help
                  </a> */}
                  <span
                    className="dropdown-item mt-2 mb-2"
                    onClick={() => logout()}
                    style={{ cursor: "pointer" }}
                  >
                    Logout
                  </span>
                  {/* <!-- <a className="dropdown-item" href="{{ url('user/setting/integration') }}">Integration</a> --> */}
                  {/* <Link
                    to="/session/auth/user-profile"
                    className="dropdown-item"
                  >
                    Settings
                  </Link> */}
                  {JSON.parse(localStorage.getItem("user_data")).role &&
                    JSON.parse(localStorage.getItem("user_data")).role ===
                      "superadmin" && (
                      <Link className="dropdown-item" to="/admin">
                        Admin Dashboard
                      </Link>
                    )}
                  {/* @endhasrole */}
                </div>
              </li>
            </ul>
          </div>

          {/* React User Menu Bar Component */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
