import React, { Component } from "react";
import moment from "moment";
import DatepickerCustom from "../../../common/DatepickerCustom";
import { toast } from "react-toastify";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";

class CampaignEndDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment(),
      end: moment(),
      focused_start: null,
      focused_end: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFocused = this.handleFocused.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
  }

  handleFocused(focus, state_type) {
    if (state_type == "start") {
      this.setState({
        focused_end: null,
      });
      this.setState({
        focused_start: focus,
      });
      try {
        const account_type =
          JSON.parse(localStorage.getItem("user_data"))["account_type"] || "";
        if (account_type == "prepaid") {
          window.$peasy.triggerEvent({
            event_name: "warning",
            event_params: { id: "2" },
          });
        }
      } catch (e) {
        console.log("start date select event call error", e);
      }
    } else {
      this.setState({
        focused_start: null,
      });

      this.setState({
        focused_end: focus,
      });
    }
  }

  handleChange(date, state_type) {
    if (state_type == "start") {
      this.setState({
        start: date,
      });

      this.props.handleChange("start_date", date);
    } else {
      this.setState({
        end: date,
      });
      this.props.handleChange("end_date", date);
    }
  }

  saveAndContinue() {
    const { start } = this.state;
    const { end } = this.state;

    if (start.isSame(end)) {
      toast.error(this.props.t("campaign_endDateAfterStartDate"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (end.diff(start, "days") + 1 < 4) {
      toast.error(this.props.t("campaign_MoreThanFourdays"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (start > end) {
      toast.error(this.props.t("campaign_StartDateLessThanEndDate"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      if (this.props.funnel_type === 2) {
        this.props.goForward();
      } else {
        this.props.nextStep();
        toast.dismiss();
      }
    }
  }

  gobackprevious() {
    if (this.props.funnel_type === 2) {
      return this.props.goBack();
    }
    toast.dismiss();
    // console.log('values in campaign end date', this.props.values);
    switch (this.props.values.objective) {
      case 1:
      case 2:
      case 6:
        return this.props.prevStep(2);
        break;
      case 3:
        return this.props.prevStep(1);
        break;
      case 4:
      case 5:
        return this.props.prevStep();
        break;
    }
  }

  componentDidMount() {
    const { start_date } = this.props.values;
    const { end_date } = this.props.values;

    this.setState({
      start: start_date,
      end: end_date,
    });
  }

  render() {
    const value = this.props.values;
    const thisstate = this.state;

    const values = { value, thisstate };

    return (
      <div className="w-100">
        <div
          className="active col-12 px-0 tab-pane border-rounded p-3 mb-2"
          id="step12"
        >
          <div className="form-group brand-custom-form mt-2">
            <label>{this.props.t("campaign_campaignDate")}</label>
          </div>
          <p className="question-description mb-3">
            {this.props.t("campaign_sevenDaysToDeliverResults")}
          </p>

          <div className="row cus-calender">
            <div className="col-md-4">
              <div className="card-body p-0">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      {this.props.t("campaign_startDate")}
                    </span>
                  </div>
                  <i className="fa fa-calendar input-group-text"></i>
                  <DatepickerCustom
                    state_type="start"
                    handleChange={this.handleChange}
                    handleFocused={this.handleFocused}
                    value={values}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-body p-0">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      {this.props.t("campaign_endDate")}
                    </span>
                  </div>
                  <i className="fa fa-calendar input-group-text"></i>
                  <DatepickerCustom
                    state_type="end"
                    handleChange={this.handleChange}
                    handleFocused={this.handleFocused}
                    value={values}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="navination_bar">
          <div className="bar_search d-flex">
            <div className="above_navigation_box ml-auto">
              <button
                className="brandprofilecard__btn--white"
                onClick={this.gobackprevious}
              >
                {this.props.t("campaign_back")}
              </button>
            </div>
            <div
              className="below_navigation_box"
              style={{ marginRight: "0px" }}
            >
              <button
                className="brandoverviewleftsection__btn--purple"
                onClick={this.saveAndContinue}
              >
                {this.props.t("campaign_next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CampaignEndDate);
