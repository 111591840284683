import React, {PureComponent} from 'react';
import './PageLoader.css';

export default class PageLoader extends PureComponent {
    render() {
        return (<div className={'loader'}>
            <div className={'lds-ring'}>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>);
    }
}
