import React from 'react'
import PropTypes from 'prop-types'

export class Button extends React.PureComponent {
  render () {
    const style={
      ...this.props.style,
      display:this.props.permission?this.props.permission==='hidden'? 'none' : 'block' :'block'

    }
    const disabled=this.props.disabled?this.props.disabled:this.props.permission==='notClickable' ? true : false
    const label = this.props.label ? this.props.label : this.props.children

    if (this.props.href) {
      return (
        <a
          style={style}
          className={this.props.className}
          href={this.props.href}
        >
          {label}
        </a>
      )
    }
    return (
      <button
        style={style}
        type={this.props.type}
        className={this.props.className}
        onClick={this._handleClick}
        title={this.props.title}
        disabled={disabled}
      >
        {label}
      </button>
    )
  }

  static propTypes = {
    href: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    label: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.oneOf([
      'sm',
      'xs'
    ]),
    permission: PropTypes.oneOf([
      'clickable',
      'notClickable',
      'visible',
      'hidden'
    ]),
    active: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    onClick: () => {},
    type: 'button',
    color: 'secondary'
  }

  _handleClick = (e) => {
    this.props.onClick(e)
  }
}
