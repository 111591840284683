import React, { useState, useEffect } from "react";
import Axios from "axios";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import { toast } from "react-toastify";
import TagManager from "react-gtm-module";
import {
  onAddUserFbAccount,
  onPostFbAdAccounts,
} from "../../../utils/network/lib/misc";

function AdsAccount({
  setForm,
  formData,
  navigation,
  fbData,
  isFreeUser,
  globalId,
}) {
  const { id } = formData;

  const { next } = navigation;

  const [accountData, setAccountData] = useState([]);
  const [activeId, setActiveId] = useState("");
  const [currency, setCurrency] = useState("");

  const [loading, setLoading] = useState(true);

  const getAccountDetails = async () => {
    onPostFbAdAccounts({
      access_token: fbData.accessToken,
      user_id: id,
    })
      .then((res) => {
        setAccountData(res.data.ad_accounts);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const saveAdAccountDetails = async () => {
    try {
      const { data, status } = await onAddUserFbAccount({
        accesstoken: accountData.length === 0 ? "" : fbData.accessToken,
        adAccountId: activeId,
        addAccountCurrency: currency,
        user_id: id,
      });
      if (status == 200) {
        const args = {
          dataLayer: {
            event_custom: "register_ad_account",
            user_ad_account: activeId,
            user_currency: currency,
            user_id: id,
            /* can pass more variables here if needed */
          },
        };
        TagManager.dataLayer(args);
        if (globalId) {
          window.gist.identify(data.user_id, {
            email: globalId,
            Regemail: formData.email || "",
            qualification: data.qualification || "",
          });
        } else {
          window.gist.identify(data.user_id, {
            email: formData.email || "",
            qualification: data.qualification || "",
          });
        }
        next();
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
    toast.error("Failed to add ad account.");
  };

  useEffect(() => {
    if (isFreeUser == false) getAccountDetails();
  }, []);

  useEffect(() => {
    if (isFreeUser == true) {
      next();
    }
  }, isFreeUser);

  const onToggle = (fbAccount) => {
    if (activeId === fbAccount.id) {
      setActiveId("");
      setCurrency("");
    } else {
      setCurrency(fbAccount.currency);
      setActiveId(fbAccount.id);
    }
  };

  const renderUI = () => {
    if (loading) {
      return (
        <div
          style={{
            height: "150px",
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, .7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "10",
          }}
        >
          <img
            style={{ width: "100px", height: "100px" }}
            src="/images/assets/loading-deploy.gif"
            alt=""
          />
        </div>
      );
    } else {
      if (accountData.length > 0) {
        return (
          <>
            <h4 style={{ textAlign: "center", lineHeight: "25px" }}>
              Peasy create and publish campaigns using your own Facebook Ad
              Account. Our AI personalizes recommendations by analysing past
              campaigns from your ad account.
              <br />
              <br />
              Choose 1 Ad Account.
              <br />
            </h4>
            <div className="connect-acc-wrapper">
              <div style={{ position: "relative" }} className="connect-acc-box">
                <div className="connect-acc-list title-label">
                  <div>
                    <div className="acc-id">Account ID</div>
                  </div>
                  <div className="acc-name">Account name</div>
                  <div className="acc-currency">Currency</div>
                  <div className="acc-action">Action</div>
                </div>
                {accountData.map((fbAdAccount) => (
                  <div key={fbAdAccount.id} className="connect-acc-list">
                    <div className="acc-id">{fbAdAccount.id}</div>
                    <div className="acc-name">{fbAdAccount.name}</div>
                    <div className="acc-currency">{fbAdAccount.currency}</div>
                    {fbAdAccount.account_status === 1 ? (
                      <div className="acc-toggle w-embed">
                        <input
                          className="fbtoggle"
                          name="fbtoggleActive"
                          type="checkbox"
                          id={fbAdAccount.id}
                          checked={fbAdAccount.id === activeId}
                          data-currency={fbAdAccount.currency}
                          onChange={() => onToggle(fbAdAccount)}
                        />
                        <label className="toggle" for={fbAdAccount.id}>
                          Toggle
                        </label>
                      </div>
                    ) : (
                      <div
                        className="acc-action not-eligible"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Your Ad Account Must Be Active"
                      >
                        Not eligible
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      }
      return (
        <>
          <br />
          <div style={{ textAlign: "center" }}>
            <h3>You need to have a Facebook Ad Account to use Peasy</h3>
            <br />
          </div>
          <div className="connect-acc-list title-label">
            <div>
              Peasy publishes your Facebook and Instagram campaigns on your own
              ad account, so you retain absolute ownership over your ads and
              data.
            </div>
          </div>
          <br />
          <div className="connect-acc-list title-label">
            <div>
              We recommend that you create a Facebook Ad Account to enjoy the
              full benefit of the onboarding process. Or you can continue to
              create your Peasy account first.
            </div>
          </div>
          <br />
        </>
      );
    }
  };

  const renderStep = () => {
    if (loading) return <></>;
    return (
      <>
        <br />
        <div>
          {accountData.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                href="https://business.facebook.com"
                target="_blank"
                className="btn btn-primary"
              >
                Create a FB Ad Account Now
              </a>
            </div>
          )}

          {accountData.length > 0 && (
            <div style={{ float: "right" }}>
              <button
                disabled={accountData.length > 0 && activeId === ""}
                className="btn btn-outline-primary"
                onClick={() => {
                  saveAdAccountDetails();
                }}
              >
                Next
                <br />
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h2>Choose a Facebook Ad Account</h2>
        <br />
      </div>
      {renderUI()}
      {renderStep()}
    </>
  );
}

export default AdsAccount;
