import { IconButton } from "@material-ui/core";
import { MoreHorizRounded } from "@material-ui/icons";
import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../StaticArray/StaticArray";
import "../../";
import { useTranslation } from "react-i18next";

const BrandOverviewAction = ({
  val,
  handleDeleteCampaignId,
  ChannelDeploymentStatusSchema,
  redeployCampaign,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const isDeleteBtnShown = () =>{
    // hide delete btn when planId = 10 OR prepaid 
    const  userData = JSON.parse(localStorage.getItem("user_data"));
    if ((userData.account_type === 'prepaid') || (userData.active_package === 10)) {
      return false;
    }
    return true;
  }
  return (
    <div>
      <IconButton
        style={{ outline: "none" }}
        aria-controls={`simple-menu`}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizRounded size="small" fontSize="large" color="primary" />
      </IconButton>
      <Menu
        id={`simple-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to={`/campaign/edit/${val.id}`}>
          <MenuItem onClick={handleClose}>{t("dashboard_edit")}</MenuItem>
        </Link>
        
        {
          isDeleteBtnShown() && (
          <MenuItem
            onClick={() => {
              handleClose();
              handleDeleteCampaignId(val.id);
            }}
          >
            {t("dashboard_delete")}
          </MenuItem>
          )
        }

        {val.deployment_status === ChannelDeploymentStatusSchema.ERROR && (
          <MenuItem onClick={() => redeployCampaign(val.id)}>
            <a
              className="redeploy"
              // href={BASE_URL + "campaign/redeploy1?id=" + val.id}
            >
              {t("dashboard_deploy")}
            </a>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default BrandOverviewAction;
