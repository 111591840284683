import React from "react";
import "./QualificationDropdown.scss";
import { QUALIFICATION_FILTERS } from "../../clientConstants/Constant";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const QualificationDropdown = ({
  id,
  qualification,
  onChangeHandler,
  isLarge,
}) => {
  const handleChange = (event) => {
    onChangeHandler(id, event.target.value);
  };

  return (
    <div className="qualification-dropdown">
      <FormControl>
        <Select
          value={qualification}
          onChange={handleChange}
          displayEmpty
          disableUnderline
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          style={isLarge ? { fontSize: "2.1875rem" } : { fontSize: "0.875rem" }}
        >
          {QUALIFICATION_FILTERS.map((filter) => (
            <MenuItem
              id={filter.value}
              key={filter.value}
              value={filter.value}
              name={filter.value}
            >
              {filter.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default QualificationDropdown;

// export default class QualificationDropdown extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {};
//   }

//   onClickHandler = (data) => {
//     this.props.onChangeHandler(this.props.id, data);
//   };

//   renderQualificationFilter() {
//     let renderObj = QUALIFICATION_FILTERS.map((filter) => {
//       return (
//         <span
//           id={filter.value}
//           key={filter.value}
//           className="dropdown-item qualification_hover"
//           onClick={(e) => this.onClickHandler(filter.value)}
//           name={filter.value}
//         >
//           {filter.label}
//         </span>
//       );
//     });

//     return renderObj;
//   }

//   render() {
//     return (
//       <React.Fragment>
//         <button
//           type="button"
//           className="btn clientlisting_btn"
//           data-toggle="dropdown"
//           aria-expanded="false"
//           id="dropdownMenuButton"
//           aria-haspopup="true"
//         >
//           <i className="fa fa-chevron-down" aria-hidden="true"></i>
//         </button>
//         <div
//           className="dropdown-menu qualificationdropdown"
//           aria-labelledby="dropdownMenuButton"
//         >
//           <span className="dropdown-menu-arrow-qualification"></span>
//           {this.renderQualificationFilter()}
//         </div>
//       </React.Fragment>
//     );
//   }
// }
