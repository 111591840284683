import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import {  ArrowDropDown,  ArrowDropUp,  SearchOutlined,  UnfoldMore,Edit} from "@material-ui/icons";
import { CircularProgress, Grid,IconButton } from "@material-ui/core";
import PeasyApi from "../../../utils/peasy.api";
import { useParams } from "react-router-dom";
import ProductEditModal from "./ProductEditModal";
import { onGetRMFproductListByActiveBox } from "../../../utils/network/lib/rmf";

const demoRows = [
    {
        availability: "in stock",
        brand: "Peasy",
        catalog_id: 13,
        condition: "new",
        created_at: null,
        currency: "MYR",
        description: "Suitable for mid-size retail or sales team: Include both Peasy Marketing O2O Starter and Peasy Sales O2O Starter",
        id: 166,
        image_link: "https://scontent-iad3-2.xx.fbcdn.net/v/t4",
        integrations: {
          facebook: {}
        },
        item_group_id: "20pwpm0w9m",
        link: "https://app.peasy.ai/external?buy_now_plan_id=price_1LuAzjIB8fU21ThgKeLgBTQ4",
        other_attributes: null,
        price: "RM2,400.00",
        quantity: 0,
        shipping: null,
        shipping_weight: "0",
        title: "Peasy O2O Premium",
        updated_at: "2023-01-05T11:37:27.000000Z"
    },
];

async function productImageTester(products=[],placeholderUrl="") {
    
    const updatedRows = await Promise.all(products.map(async (product) => {
      try {
        const response = await fetch(product.image_link);
        if (!response.ok) {
          throw new Error("Image not found");
        }
      } catch (error) {
        product.image_link = placeholderUrl;
      }
      
      return product;
    }));
    
    return updatedRows;
}
  
/*
function descendingComparator(a, b, productBy) {
  if (b[productBy] < a[productBy]) {
    return -1;
  }
  if (b[productBy] > a[productBy]) {
    return 1;
  }
  return 0;
}
*/
/*
function getComparator(product, productBy) {
  return product === "desc"
    ? (a, b) => descendingComparator(a, b, productBy)
    : (a, b) => -descendingComparator(a, b, productBy);
}
*/
/*
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const product = comparator(a[0], b[0]);
    if (product !== 0) return product;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
*/
const headCells = [
//   { id: "image_link", numeric: false, disablePadding: false, label: "IMAGE" },
  { id: "title", numeric: false, disablePadding: false, label: "TITLE" },
  { id: "description", numeric: true, disablePadding: false, label: "DESCRIPTION" },
  { id: "price",numeric: false, disablePadding: false, label: "PRICE",},
  { id: "availability", numeric: false, disablePadding: false, label: "AVAILABILITY",},
  { id: "action", numeric: false, disablePadding: false,label: "Action",},
];

function EnhancedTableHead({classes, onSelectAllClick, product, productBy, onRequestSort}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={productBy === headCell.id ? product : false}
          >
            <div onClick={createSortHandler(headCell.id)}>
              {headCell.label}{" "}
              <span className="sort-indicator">
                {productBy !== headCell.id && <UnfoldMore />}
                {productBy === headCell.id && product === "desc" && (
                  <ArrowDropDown />
                )}
                {productBy === headCell.id && product === "asc" && (
                  <ArrowDropUp />
                )}
              </span>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  product: PropTypes.oneOf(["asc", "desc"]).isRequired,
  productBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));


const EnhancedTableToolbar = ({ numSelected, search, setSearch }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container>
        <Grid item md={6} lg={4}>
          <div className="input-container">
            <SearchOutlined className="img" />
            <input
              className="input"
              type="text"
              placeholder="Search by product"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const ProductListActiveBox = ({ activeBox, loading }) => {
  const { brandId } = useParams();
  const classes = useStyles();
  const [product, setProduct] = React.useState("asc");
  const [productBy, setProductBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const [temp, setTemp] = React.useState();
  const [rows,setRows] = useState([]);
  const [editProductInfo,setEditProductInfo] = useState({});
  
//   console.log(activeBox,"activeBox");
  useEffect(() => {
    setSearch("");
    setPage(0);
    // fetch the productlist
    if (brandId && activeBox) {
      fetchActiveProductList(brandId,activeBox);
    }
  }, [brandId,activeBox]);
// console.log(search);

  // work on here to change the product rows value
  const fetchActiveProductList = async (brand_id,rmf_activeBox_id) => {
    try {
      // setLoading(true);
      // const response = await PeasyApi.get(`api/v1/rmf/products?brand_profile_id=${brand_id}&rmf=${rmf_activeBox_id}`);
      const response = await onGetRMFproductListByActiveBox(brand_id,rmf_activeBox_id);
      console.log(response,"through");
      
      if (Array.isArray(response.data)) {
        // check if image url is expired then add placeholder
            const placeholderImg_url = 'https://png.pngtree.com/png-vector/20210604/ourmid/pngtree-gray-network-placeholder-png-image_3416659.jpg'
            const productsWithImgLink = await productImageTester(response.data,placeholderImg_url);
          setRows(productsWithImgLink);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = productBy === property && product === "asc";
    setProduct(isAsc ? "desc" : "asc");
    setProductBy(property);
  };
  
 /*
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.product);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  */
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const isSelected = (product) => selected.indexOf(product) !== -1;

  const emptyRows =  rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleProductEdit = (product) => {
    // handle edit button click
    setEditProductInfo(product);
    console.log(product);
  };
  
 /*
  useEffect(() => {
    setTemp(
      stableSort(rows, getComparator(product, productBy)).filter((r) =>
        r.product.toLowerCase().includes(search)
      )
    );
  }, [search]);
  */
 
  if (activeBox === 0){
    return <h4 className="text-center">Please Select a field to fetch product list.</h4>
  }  else if (loading){
    return <h4 className="d-flex align-items-center justify-content-center mt-2"><CircularProgress /></h4>
  }
  return (
    <>
      <div style={{ padding: "15px" }}>
        <div className={classes.root}>

          <Paper className={classes.paper}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              search={search}
              setSearch={setSearch}
            />

            <TableContainer styles={{ padding: "100px" }}>
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  product={product}
                  productByf={productBy}
                //   onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {
                    rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                        console.log(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={false}
                          tabIndex={-1}
                          key={index}
                          selected={false}
                        >
                          <TableCell
                            // style={{display:"flex",alignItems:"center"}}
                            component="tleadRowsh"
                            id={labelId}
                            scope="row"
                          >
                            <span className=" d-flex flex-column flex-md-row align-items-center flex-nowrap text-center">
                                <img src={row.image_link} width={48} alt={row.title} />
                                <span className="ml-3 text-nowrap">{row.title}</span>
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            {row.description?.substr(0,20)}
                          </TableCell>
                          <TableCell align="left">
                            {row.price}
                          </TableCell>
                          <TableCell align="left">
                            {row.availability}
                          </TableCell>
                          <TableCell sx={{color:"blue"}} className="view-btn" align="left"><IconButton onClick={() => handleProductEdit(row)}><Edit /></IconButton></TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody> 
              </Table>
            </TableContainer> 
              
            {/* 
                <TablePagination
                className="page"
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={5000}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
             */}
          </Paper>
        </div>
        {
            editProductInfo.id && <div>
                <ProductEditModal editProductInfo={editProductInfo} setEditProductInfo={setEditProductInfo} />
            </div>
        }
        
      </div>
    </>
  );
};
export default ProductListActiveBox;




/*
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

const products = [
  {
    availability: "in stock",
    brand: "Peasy",
    catalog_id: 13,
    condition: "new",
    created_at: null,
    currency: "MYR",
    description: "Suitable for mid-size retail or sales team: Include both Peasy Marketing O2O Starter and Peasy Sales O2O Starter",
    id: 166,
    image_link: "https://scontent-iad3-2.xx.fbcdn.net/v/t4",
    integrations: {
      facebook: {  }
    },
    item_group_id: "20pwpm0w9m",
    link: "https://app.peasy.ai/external?buy_now_plan_id=price_1LuAzjIB8fU21ThgKeLgBTQ4",
    other_attributes: null,
    price: "RM2,400.00",
    quantity: 0,
    shipping: null,
    shipping_weight: "0",
    title: "Peasy O2O Premium",
    updated_at: "2023-01-05T11:37:27.000000Z"
  },
  // ...more products
];

const rowsPerPageOptions = [5, 10, 25];

const ProductListActiveBox = ({activeBox,rows,loading}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => setPage(newPage);
 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickEdit = (productId) => {
    // handle edit button click
  };

  const displayProducts = products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Price (MYR)</TableCell>
            <TableCell>Availability</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayProducts.map((product) => (
            <TableRow key={product.id}>
              <TableCell><img src={product.image_link} alt={product.title} /></TableCell>
              <TableCell>{product.title}</TableCell>
              <TableCell>{product.description}</TableCell>
              <TableCell>{product.price}</TableCell>
              <TableCell>{product.availability}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleClickEdit(product.id)}>
                  <Edit />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Table>
    </TableContainer>
  );
};

export default ProductListActiveBox;

*/
