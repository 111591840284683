import React, { Component } from "react";
import Create from "./Create.jsx";
import { ToastContainer } from "react-toastify";
import { AppBar, Hidden, Typography } from "@material-ui/core";
import "../../translations/i18n";
import { withTranslation } from "react-i18next";

class StepWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width_progress: 10,
      slides: 10,
      questionNumber: 1,
    };

    this.UpdateProgressBar = this.UpdateProgressBar.bind(this);
    this.fullFunnelProgress = this.fullFunnelProgress.bind(this);
    this.handleQuestionNumber = this.handleQuestionNumber.bind(this);
  }

  handleQuestionNumber(value) {
    this.setState({ questionNumber: value });
  }
  UpdateProgressBar(current_step, type) {
    let current_step_type = type == "next" ? current_step : current_step;
    const { slides } = this.state;
    const percentage = 100 / slides;
    const finalpercent = current_step_type * percentage;
    this.setState({
      width_progress: finalpercent,
    });
  }

  fullFunnelProgress(type) {
    // debugger;
    var value =
      type === "next"
        ? this.state.width_progress + 15
        : this.state.width_progress - 15;
    this.setState({
      width_progress: value,
    });
  }

  render() {
    const { width_progress } = this.state;
    const width_progress_style = {
      width: width_progress + "%",
    };

    return (
      <div className="w-100 campaign-create-container">
        <div className="d-block mb-2 mt-3 row mx-0">
          <div className="brand-custom-form">
            <label>{this.props.t("campaign_question")} {this.state.questionNumber}</label>
          </div>
          <div className="progress ml-2 mr-2">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="0"
              style={width_progress_style}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <span className="float-left progress-span">{this.props.t("campaign_start")}</span>
          <span className="float-right progress-span">{this.props.t("campaign_predictResult")}</span>
        </div>

        <div className="tab-content brand-custom-form-prt mt-4 row roboto-400 create-campaign mb-5">
          <Create
            UpdateProgressBar={this.UpdateProgressBar}
            fullFunnelProgress={this.fullFunnelProgress}
            handleQuestionNumber={this.handleQuestionNumber}
            questionNumber={this.state.questionNumber}
          />
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default withTranslation()(StepWrapper);
