import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import {
  ArrowDropDown,
  ArrowDropUp,
  SearchOutlined,
  UnfoldMore,
} from "@material-ui/icons";
import { CircularProgress, Grid } from "@material-ui/core";
import PeasyApi from "../../../utils/peasy.api";
import { useParams } from "react-router-dom";
import { onGetRMFproductListByActiveBox } from "../../../utils/network/lib/rmf";

const demoRows = [
  {
    id: "1",
    product: "Apple",
    model: "ipad pro 12.9",
    price: "RM 3599",
    unit: "2",
  },
  {
    id: "2",
    product: "Samsung",
    model: "Samsung Galaxy",
    price: "RM 4499",
    unit: "4",
  },
  {
    id: "3",
    product: "Samsung",
    model: "Samsung Note",
    price: "RM 4599",
    unit: "4",
  },
];

function descendingComparator(a, b, productBy) {
  if (b[productBy] < a[productBy]) {
    return -1;
  }
  if (b[productBy] > a[productBy]) {
    return 1;
  }
  return 0;
}

function getComparator(product, productBy) {
  return product === "desc"
    ? (a, b) => descendingComparator(a, b, productBy)
    : (a, b) => -descendingComparator(a, b, productBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const product = comparator(a[0], b[0]);
    if (product !== 0) return product;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "product", numeric: false, disablePadding: false, label: "PRODUCT" },
  { id: "id", numeric: false, disablePadding: false, label: "ID" },
  { id: "model", numeric: true, disablePadding: false, label: "MODEL" },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "PRICE",
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    label: "UNIT",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    product,
    productBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={productBy === headCell.id ? product : false}
          >
            <div onClick={createSortHandler(headCell.id)}>
              {headCell.label}{" "}
              <span className="sort-indicator">
                {productBy !== headCell.id && <UnfoldMore />}
                {productBy === headCell.id && product === "desc" && (
                  <ArrowDropDown />
                )}
                {productBy === headCell.id && product === "asc" && (
                  <ArrowDropUp />
                )}
              </span>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  product: PropTypes.oneOf(["asc", "desc"]).isRequired,
  productBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, search, setSearch } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container>
        <Grid item md={6} lg={4}>
          <div className="input-container">
            <SearchOutlined className="img" />
            <input
              className="input"
              type="text"
              placeholder="Search by product"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const ProductList = ({ activeBox, loading }) => {
  const { brandId } = useParams();
  const classes = useStyles();
  const [product, setProduct] = React.useState("asc");
  const [productBy, setProductBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [temp, setTemp] = React.useState();
  const [rows,setRows] = useState(demoRows);
  console.log(activeBox,"activeBox");
  useEffect(() => {
    setSearch("");
    setPage(0);
    // fetch the productlist
    fetchActiveProductList(activeBox);
  }, [activeBox]);

  // work on here to change the product rows value
  const fetchActiveProductList = async (id) => {
    try {
      // setLoading(true);
      // const data = await PeasyApi.get(`api/v1/rmf/products?brand_profile_id=${brandId}&rmf=${id}`);
      const data = await onGetRMFproductListByActiveBox(brandId,id);
      // setRows(data?.data??[]);
      console.log(data.data);
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = productBy === property && product === "asc";
    setProduct(isAsc ? "desc" : "asc");
    setProductBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.product);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (product) => selected.indexOf(product) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {
    setTemp(
      stableSort(rows, getComparator(product, productBy)).filter((r) =>
        r.product.toLowerCase().includes(search)
      )
    );
  }, [search]);

  if (activeBox === 0)
    return (
      <h4 className="text-center">
        Please Select a field to fetch product list.
      </h4>
    );
  else if (loading)
    return (
      <h4 className="d-flex align-items-center justify-content-center mt-2">
        <CircularProgress />
      </h4>
    );
  return (
    <>
      <div style={{ padding: "15px" }}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              search={search}
              setSearch={setSearch}
            />
            <TableContainer styles={{ padding: "100px" }}>
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  product={product}
                  productByf={productBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(product, productBy))
                    .filter((r) => r.product.toLowerCase().includes(search))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.product);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="tleadRowsh"
                            id={labelId}
                            scope="row"
                          >
                            {row.product.length > 0 ? row.product : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {row.model.length > 0 ? row.model : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {row.id.length > 0 ? row.id : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {row.price ? row.price : "N/A"}
                          </TableCell>
                          <TableCell align="left">{row.unit}</TableCell>
                          <TableCell sx={{color:"blue"}} className="view-btn" align="left">View</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="page"
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={search ? temp.length : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      );
    </>
  );
};
export default ProductList;
