import { GET_CAMPAIGN_METRIC_CONSTANTS } from "../constants";

export default function reducer(state = { campaignMetrics: null }, action) {
  switch (action.type) {
    case GET_CAMPAIGN_METRIC_CONSTANTS.GET_CAMPAIGN_METRIC_STARTED: {
      return { ...state, loading: true };
    }
    case GET_CAMPAIGN_METRIC_CONSTANTS.GET_CAMPAIGN_METRIC_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaignMetrics: action.payload
      };
    }
    case GET_CAMPAIGN_METRIC_CONSTANTS.GET_CAMPAIGN_METRIC_FAILED: {
      return {
        ...state,
        loading: false,
        campaignMetrics: action.payload
      };
    }
    case GET_CAMPAIGN_METRIC_CONSTANTS.GET_CAMPAIGN_METRIC_NET_FAILED: {
      return {
        ...state,
        loading: false,
        campaignMetrics: action.payload
      };
    }
    case GET_CAMPAIGN_METRIC_CONSTANTS.FILTER_CAMPAIGN_METRIC_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaignMetrics: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
