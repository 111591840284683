import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FacebookLogin from "react-facebook-login";
import http from "../../../../../shared/http-request";
import { BASE_URL, SERVER_URL } from "../../../../../StaticArray/StaticArray";
import "../../../../../translations/i18n";
import { useTranslation } from "react-i18next";
import {
  onUserActivateAccountFb,
  onUserFbLogin,
  onUserRemoveActivateAccountFb,
} from "../../../../../utils/network/lib/user";
import { userDetails } from "../../../Axios/ApiRequests";

const FbAdsTable = ({ accountData, getAccountDetails, loading }) => {
  const [activeId, setActiveId] = useState("");
  const [userId, setUserId] = useState("");
  // Configuring notifications library
  const { t } = useTranslation();

  toast.configure({
    position: toast.POSITION.BOTTOM_LEFT,
  });
  
  useEffect(() => {
    if (Object.keys(accountData).length > 0 && accountData.fbActiveAccountId) {
      setActiveId(accountData.fbActiveAccountId);
    }
  }, [accountData]);

  useEffect(() => {
    if (localStorage.getItem("user_data")) {
      let userDataObj = JSON.parse(localStorage.getItem("user_data"));
      setUserId(userDataObj.id);
    }
  }, []);

  const getUserDetails = async() => {
    const user_details = await userDetails();
    console.log("user_details info", user_details);
    localStorage.setItem("user_data", JSON.stringify(user_details));
    return user_details;
  }

  const onToggle = (id) => {
    if (activeId === id) {
      setActiveId("");
      onUserRemoveActivateAccountFb()
        .then(async(res) => {
          toast.success(t("settings_accDeactivatnSuccessful"));
          await  getUserDetails();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          getAccountDetails();
        });
    } else {
      setActiveId(id);
      onUserActivateAccountFb({
        account_id: id,
      })
      .then(async(res) => {
        toast.success(t("settings_accActivatnsuccessful"));
        // refresh the user information on with upgraded_prepaid _sh_
          await  getUserDetails();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          getAccountDetails();
        });
    }
  };

  const logoutFbAccount = () => {
    onUserRemoveActivateAccountFb()
      .then(() => null)
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => getAccountDetails());
  };

  const responseFacebook = (response) => {
    console.log("response", response);
    if (response) fbLogin(response);
  };

  const fbLogin = (response) => {
    const accountType = JSON.parse(
      localStorage.getItem("user_data")
    ).account_type;
    let channel = "";
    switch (accountType) {
      case "prepaid":
        channel = "CHANNEL_FACEBOOK_USER";
        break;
      case "postpaid":
        channel = "CHANNEL_FACEBOOK";
        break;
      default:
        channel = null;
        break;
    }

    if (channel) {
      onUserFbLogin({
        access_token: response.accessToken,
        channel,
      })
        .then((res) => {
          if (res.data.status === "success") return getAccountDetails();
        })
        .catch((err) => toast.error(err.response.data.message));
    } else return toast.error(t("settings_userUnsupported"));
  };

  return (
    <React.Fragment>
      <div className="connect-acc-wrapper">
        <div style={{ position: "relative" }} className="connect-acc-box">
          <div className="div-block-73">
            <div className="group-horizontal">
              <img
                src={`${BASE_URL}images/facebook_1facebook.png`}
                width="20"
                height="20"
                alt=""
                className="icon-small"
              />
              <h4 className="connect-acc-label">{t("settings_fbAds")}</h4>
            </div>
            {accountData &&
            (accountData.isFbAccessible === 0 ||
              accountData.facebookFallbackAccount === 1) ? (
              <button
                id="btnDisconnectFb"
                className="button connect-saved-btn w-button"
                onClick={() => logoutFbAccount()}
              >
                {t("settings_logout")}
              </button>
            ) : (
              //   <FacebookLogin
              //   appId="381147659455069"
              //   autoLoad={false}
              //   fields="name,email,picture"
              //   scope="ads_management,ads_read,business_management,manage_pages,pages_show_list"
              //   cssClass="button connect-saved-btn w-button"
              //   callback={responseFacebook}
              //   textButton="Connect"
              // />
              <></>
            )}
            {accountData && accountData.isFbAccessible === 1 && (
              <a
                href={`${SERVER_URL}/session/oauth/fb?state=${userId}`}
                className="button connect-saved-btn w-button"
              >
                {t("settings_connect")}
              </a>
            )}
          </div>

          {loading && (
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, .7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "10",
              }}
            >
              <img
                style={{ width: "100px", height: "100px" }}
                src="/images/assets/loading-deploy.gif"
                alt=""
              />
            </div>
          )}

          {/* @if(getCurrentUser()->getAccessToken(\App\Library\Schema\ChannelSchema::FACEBOOK)) */}
          {accountData && accountData.fbActiveAccountId ? (
            <div></div>
          ) : (
            <div
              className="card-footer"
              style={{ borderTop: "1px solid #DADADA" }}
            >
              {t("settings_fbConnectText")}

              <a href="#" className="text-peasy-purple">
                {t("settings_clickHere")}
              </a>
            </div>
          )}

          {accountData.facebookFallbackAccount === 0 && (
            <React.Fragment>
              <div className="connect-acc-list title-label">
                <div>
                  <div className="acc-id">{t("settings_accID")}</div>
                </div>
                <div className="acc-name">{t("settings_accName")}</div>
                <div className="acc-currency">{t("settings_currency")}</div>
                <div className="acc-action">{t("settings_action")}</div>
              </div>
              {accountData.fbAdAccounts &&
                accountData.fbAdAccounts.map((fbAdAccount) => (
                  <div className="connect-acc-list">
                    <div className="acc-id">{fbAdAccount.id}</div>
                    <div className="acc-name">{fbAdAccount.name}</div>
                    <div className="acc-currency">{fbAdAccount.currency}</div>
                    {fbAdAccount.account_status === 1 ? (
                      <div className="acc-toggle w-embed">
                        <input
                          className="fbtoggle"
                          name="fbtoggleActive"
                          type="checkbox"
                          id={fbAdAccount.id}
                          checked={fbAdAccount.id === activeId}
                          data-currency={fbAdAccount.currency}
                          onChange={() => onToggle(fbAdAccount.id)}
                        />
                        <label className="toggle" for={fbAdAccount.id}>
                          {t("settings_toggle")}
                        </label>
                      </div>
                    ) : (
                      <div
                        className="acc-action not-eligible"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={t("settings_activeAdAcc")}
                      >
                        {t("settings_notEligible")}
                      </div>
                    )}
                  </div>
                ))}
            </React.Fragment>
          )}

          {(!accountData ||
            accountData.facebookFallbackAccount === null ||
            accountData.facebookFallbackAccount === undefined) && (
            <div className="connect-acc-list title-label">
              <div>{t("settings_connectAccLabel")}</div>
            </div>
          )}

          {accountData &&
          accountData.facebookFallbackAccount &&
          accountData.facebookFallbackAccount !== 0 ? (
            <div className="connect-acc-list title-label">
              <div>{t("settings_fbConnectLabel")}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FbAdsTable;
