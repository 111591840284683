import React from "react";
import { Route, Redirect } from "react-router-dom";
import Navbar from "../../common/navbar/Navbar";
import Stickybar from "../../common/Stickybar";

const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !localStorage.getItem("token_auth") ? (
        <Component {...props} />
      ) : (
        <React.Fragment>
          <Navbar />
          <Stickybar />
          <Redirect to="/dashboard" />
        </React.Fragment>
      )
    }
  />
);

export default GuestRoute;
