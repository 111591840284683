import { CircularProgress, FormControl, Hidden, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import "./RMF.css";

import { RMFchart } from "./RMFchart";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }
}))

const RmfBox = ({ img, title, count, percentage }) => {
  return (
    <>
      <div>
        <img
          style={{ maxWidth: "50px" }}
          src={"/images/assets/rmf/" + img}
          alt=""
        />
      </div>
      <div>
        <p className="title">{title}</p>
        <div>
          <span className="value mr-1">{count}</span>
          <span className="percentage">{"(" + percentage + "%)"}</span>
        </div>
      </div>
    </>
  );
};

function RMF({ rmfMap, activeBox, setActiveBox, setRmfMap, isRMFLoading, rmfData, brandProfileList }) {
  const classes = useStyles();
  const { brandId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isRMFdataAvailable, setIsRMFdataAvailable] = useState(false);

  const [selectedRow, setSelectedRow] = useState(undefined);
  console.log(rmfMap, "-> rmfMap");
  useEffect(() => {
    if (selectedRow) {
      console.log(selectedRow);
      const item = rmfData.find((item) => {
        if (item.title === selectedRow[0]) {
          return item;
        }
      });
      if (item) {
        setActiveBox(item.id);
      }
    }
  }, [rmfData, selectedRow, setActiveBox]);

  useEffect(() => {
    if (rmfData && rmfData.length > 0) {
      setIsRMFdataAvailable(false);
      const d = rmfData.map((item) => {
        if (!isRMFdataAvailable && (item.percentage || item.count)) {
          setIsRMFdataAvailable(true);
        }
        return [item.title, "RMF", item.percentage, item.count];
      });

      console.log([
        [
          "Location",
          "Parent",
          "Market trade volume (size)",
          "Market increase/decrease (color)",
        ],
        ["Global", null, 0, 0],
        ...d,
      ]);

      setData([
        ["Title", "Parent", "Percentage", "Count"],
        ["RMF", null, 0, 0],
        ...d,
      ]);
    }
  }, [rmfData,brandId]);

  const brandProfileSwitcher = (e) =>{
    localStorage.setItem("profile", e.target.value);
    history.push(`/rmf/${e.target.value}`);
  }
  console.log(data,"rmf",isRMFdataAvailable);
  return (
    <div style={{ padding: "20px" }}>
      <h2 className="mt-2 mb-4">RMF Report</h2>
      <section>
        <div className="switch-brands">
          <Hidden smDown>
            <p className="mr-2">{t("dashboard_business_profile_text")}</p>
          </Hidden>
          {brandProfileList && brandId && (
            <FormControl
              variant="outlined"
              className={`${classes.formControl} profile-select`}
            >
              <InputLabel htmlFor="profile">
                {t("dashboard_profiles")}
              </InputLabel>
              <Select
                labelId="profile"
                value={brandId}
                onChange={brandProfileSwitcher}
                label={t("dashboard_profiles")}
              >
                {brandProfileList.map((item) => (
                  <MenuItem
                    className="pl-2 dropdown-option"
                    value={item.id}
                    key={item.id}
                  >
                    {item.brand_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {brandProfileList.length === 0 && <p>: No Profile Created</p>}
          
        </div>
      </section> 
      {
        isRMFLoading
        ? <div className="RMF_Loader_container d-flex align-items-center justify-content-center mt-2">
            <CircularProgress />
          </div>
        : isRMFdataAvailable 
        ? <RMFchart dataList={data} setSelectedRow={setSelectedRow} isRMFLoading={isRMFLoading} />
        : <div className="RMF_Loader_container d-flex align-items-center justify-content-center"><h3>No Data Available</h3></div>
      }
      

      <>
        {/* <Grid container textAlign="center" spacing={2} className="box-container">
        <Grid container item sm={12} md={10} spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={6} sm={6} md={2}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box1.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box1.id);
                }}
              >
                <div className="type1">
                  <RmfBox
                    img={rmfMap.box1.img}
                    title={rmfMap.box1.title}
                    count={rmfMap.box1.count}
                    percentage={rmfMap.box1.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box2.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box2.id);
                }}
              >
                <div className="type1">
                  <RmfBox
                    img={rmfMap.box2.img}
                    title={rmfMap.box2.title}
                    count={rmfMap.box2.count}
                    percentage={rmfMap.box2.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box3.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box3.id);
                }}
              >
                <div className="type1">
                  <RmfBox
                    img={rmfMap.box3.img}
                    title={rmfMap.box3.title}
                    count={rmfMap.box3.count}
                    percentage={rmfMap.box3.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box8.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box8.id);
                }}
              >
                <div className="type1">
                  <RmfBox
                    img={rmfMap.box8.img}
                    title={rmfMap.box8.title}
                    count={rmfMap.box8.count}
                    percentage={rmfMap.box8.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={9}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box11.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box11.id);
                }}
              >
                <div className="type1">
                  <RmfBox
                    img={rmfMap.box11.img}
                    title={rmfMap.box11.title}
                    count={rmfMap.box11.count}
                    percentage={rmfMap.box11.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box5.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box5.id);
                }}
              >
                <div className="type2">
                  <RmfBox
                    img={rmfMap.box5.img}
                    title={rmfMap.box5.title}
                    count={rmfMap.box5.count}
                    percentage={rmfMap.box5.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6} sm={6} md={2}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box4.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box4.id);
                }}
              >
                <div className="type2">
                  <RmfBox
                    img={rmfMap.box4.img}
                    title={rmfMap.box4.title}
                    count={rmfMap.box4.count}
                    percentage={rmfMap.box4.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box6.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box6.id);
                }}
              >
                {" "}
                <div className="type2">
                  <RmfBox
                    img={rmfMap.box6.img}
                    title={rmfMap.box6.title}
                    count={rmfMap.box6.count}
                    percentage={rmfMap.box6.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box7.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box7.id);
                }}
              >
                {" "}
                <div className="type2">
                  <RmfBox
                    img={rmfMap.box7.img}
                    title={rmfMap.box7.title}
                    count={rmfMap.box7.count}
                    percentage={rmfMap.box7.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <Box
                className={
                  `box-div ` +
                  (activeBox === rmfMap.box9.id ? "box-div-active" : "")
                }
                onClick={() => {
                  setActiveBox(rmfMap.box9.id);
                }}
              >
                {" "}
                <div className="type2">
                  <RmfBox
                    img={rmfMap.box9.img}
                    title={rmfMap.box9.title}
                    count={rmfMap.box9.count}
                    percentage={rmfMap.box9.percentage}
                  ></RmfBox>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs sm={12} md={2}>
          <Box
            className={
              `box-div ` +
              (activeBox === rmfMap.box10.id ? "box-div-active" : "")
            }
            onClick={() => {
              setActiveBox(rmfMap.box10.id);
            }}
          >
            <div className="type2">
              <RmfBox
                img={rmfMap.box10.img}
                title={rmfMap.box10.title}
                count={rmfMap.box10.count}
                percentage={rmfMap.box10.percentage}
              ></RmfBox>
            </div>
          </Box>
        </Grid>
      </Grid> */}
      </>
      <>
        {/* <div>
          <div>
            {data.length > 0 && (
              <> 
                <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="TreeMap"
                  loader={<div>Loading Chart</div>}
                  data={data}
                  options={{
                    minColor: "#E19EE9",
                    midColor: "#C771D6",
                    maxColor: "#7C4586",
                    fontColor: "white",
                    showScale: false,
                    enableHighlight: true,
                    fontFamily: "Montserrat",
                    fontSize: 16,
                    headerHeight: 0,
                    generateTooltip: (row, size, value) => {
                      console.log(size,value);
                      const d = data[row + 1];
                      return (
                        '<div style="background:#5c0d70; padding:10px; border-radius:5px; color:white;"> ' +
                        d[0] +
                        " - " +
                        d[3] +
                        " (" +
                        d[2] +
                        "%)" +
                        "</div>"
                      );
                    },
                  }}
                  chartEvents={[
                    {
                      eventName: "ready",
                      callback: ({ chartWrapper, google }) => {
                        const chart = chartWrapper.getChart();
                        google.visualization.events.addListener(
                          chart,
                          "select",
                          () => {
                            const selection = chart.getSelection();
                            if (
                              selection[0] &&
                              selection[0].row &&
                              selection[0].row > 0
                            ) {
                              const d = data[selection[0].row + 1];
                              setSelectedRow(d);
                            }
                          }
                        );
                      },
                    },
                  ]}
                  rootProps={{ "data-testid": "1" }}
                />
              </>
            )}
          </div>
        </div> */}
      </>
    </div>
  );
}

export default RMF;
