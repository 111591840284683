import React, { useState, useEffect } from "react";
import ChartContainer from "./ChartContainer";
import DropdownContainer from "./DropdownConatiner";
import StatisticsContainer from "./StatisticReport";
import HeaderContainer from "./Header";
import "./InsightReport.css";

const options = [{ value: "graph", label: "By Graph" }];

const ChartReport = (props) => {
  const [audiences, setAudiences] = useState([]);
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    if (props.audiences && props.audiences.length > 0) {
      if (
        props.campaignMetricData &&
        props.campaignMetricData.data &&
        props.campaignMetricData.data.audience_table_report
      ) {
        const { audience_table_report } = props.campaignMetricData.data;

        setAudiences([
          { value: "", label: "All Audiences" },
          ...props.audiences
            .filter((audience) => audience_table_report[audience.id])
            .map((audience) => ({
              ...audience,
              value: audience.id,
              label: audience.name,
            })),
        ]);
      }
    }

    if (props.channels && props.channels.length > 0) {
      setChannels([
        { value: "", label: "All Channels" },
        ...props.channels.map((channel) => {
          let channelName = channel.split("_")[1];
          return {
            value: channel,
            label:
              channelName.substr(0, 1).toUpperCase() +
              channelName.substr(1, channelName.length - 1).toLowerCase(),
          };
        }),
      ]);
    }
  }, [props]);

  return (
    <div className="cstm_container">
      <div className="insight_sec width100">
        <HeaderContainer {...props} />
        <DropdownContainer
          {...props}
          options={options}
          audiences={audiences}
          channels={channels}
          handleFocus={props.handleFocus}
          handleDataChange={props.handleDataChange}
        />
        <StatisticsContainer {...props} />
        <ChartContainer {...props} />
      </div>
    </div>
  );
};

export default ChartReport;
