import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import SmallErrorText from "../../../common/smallErrorText/SmallErrorText";
import "./EditAudienceForm.css";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import {
  getAudienceLanguage,
  getAudienceLocation,
} from "../../../utils/network/lib/audience";

const EditAudienceForm = ({
  audienceData,
  onChange,
  onCheckChange,
  onSelectChange,
  getSelectValue,
  errors,
  isEditMode,
  validateOnBlur,
}) => {
  // States
  const [langOptions, setLangOptions] = useState([]);
  const { t } = useTranslation();

  const [locationOptions, setLocationOptions] = useState([]);

  // Functions
  const isChecked = (name, value) => {
    if (audienceData[name]) {
      const values = audienceData[name].split(/(?:<|>)+/);
      if (values.indexOf(value) !== -1) return true;
      else return false;
    } else {
      return false;
    }
  };

  let timer = 500;

  const getLangOptions = (query) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const res = await getAudienceLanguage({
        params: {
          q: query,
        },
      });
      if (res.status === 200) {
        let array = [];
        res.data.data.map((lang) => {
          const dummy = lang.value.split("|");
          const object = {};
          object.value = dummy[0];
          object.label = dummy[1];
          array = [...array, object];
        });
        setLangOptions(array);
      } else toast.error(t("campaign_couldntFetchLang"));
    }, 500);
  };

  let locationTimer = 500;
  const getLocationOptions = (query) => {
    clearTimeout(locationTimer);
    locationTimer = setTimeout(async () => {
      const res = await getAudienceLocation({
        params: {
          q: query,
        },
      });

      if (res.status === 200) {
        let array = [];
        let temp = res.data.data.slice(0, 20);
        temp.map((location) => {
          const dummy = location.value.split("|");
          const object = {};
          object.value = dummy[0];
          object.label = dummy[1];
          array = [...array, object];
        });
        setLocationOptions(array);
      }
    }, 500);
  };

  // Declarative functions

  const getCurrency = () => {
    try {
      const currency = JSON.parse(
        localStorage.getItem("user_data")
      ).currency_type;
      return currency;
    } catch (error) {
      return "MYR";
    }
  };

  const formSelectStyles = {
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: "#F4F2FF",
      border: "2px solid #6F1DDF",
      borderRadius: "5px",
      fontSize: "16px",
      fontWeight: 500,
      color: "#ffffff",
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    control: (base, state) => ({
      ...base,
      border: "1px solid base.borderColor",
      boxShadow: state.isFocused ? "0px 0px 9px 0.5px #A1C8E8" : "none",
      borderColor: state.isFocused ? "#7EB7E4" : base.borderColor,
    }),
  };

  return (
    <form className="editAudience_form">
      <div style={{ marginTop: "5px" }} className="editaudienceform__field">
        <label className="editaudienceform__label">
          {t("campaign_nameAudSegment")}
        </label>
        <input
          className="form-control"
          value={audienceData.name || ""}
          onChange={(e) => onChange("name", e.target.value)}
          type="text"
          placeholder={t("campaign_enterSegmentName")}
          style={{ width: "90%", marginLeft: "1%" }}
          onBlur={() => validateOnBlur("name")}
        />
        {errors.name && (
          <SmallErrorText
            style={{ backgroundColor: "#FEF6F6", marginLeft: "1%" }}
            text={errors.name}
          />
        )}
      </div>

      <div className="editaudienceform__field">
        <label className="editaudienceform__label">
          {t("campaign_gender")}
        </label>

        <div className="editaudienceform_checkbox_field_box">
          <div
            className="form-check-inline custom-checkbox"
            style={{
              borderRadius: "4px",
              border: "solid 2px #6F1DDF",
              backgroundColor: "#F4F2FF",
              boxSizing: "border-box",
              minWidth: "115px",
              width: "auto",
              lineHeight: "50px",
              height: "50px",
              textAlign: "center",
            }}
          >
            <label
              className="form-check-label"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              <input
                type="checkbox"
                className="form-check-input editaudienceform__input--check"
                value="MALE"
                checked={isChecked("gender", "MALE")}
                onChange={(e) => onCheckChange("gender", "MALE")}
                onBlur={() => validateOnBlur("gender")}
              />
              {t("campaign_male")}
            </label>
          </div>

          <div
            className="form-check-inline custom-checkbox"
            style={{
              borderRadius: "4px",
              border: "solid 2px #6F1DDF",
              backgroundColor: "#F4F2FF",
              boxSizing: "border-box",
              minWidth: "115px",
              width: "auto",
              lineHeight: "50px",
              height: "50px",
              textAlign: "center",
            }}
          >
            <label
              className="form-check-label"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              <input
                type="checkbox"
                className="form-check-input editaudienceform__input--check"
                value="Female"
                checked={isChecked("gender", "FEMALE")}
                onChange={(e) => onCheckChange("gender", "FEMALE")}
                onBlur={() => validateOnBlur("gender")}
              />
              {t("campaign_female")}
            </label>
          </div>

          <div
            className="form-check-inline custom-checkbox"
            style={{
              borderRadius: "4px",
              border: "solid 2px #6F1DDF",
              backgroundColor: "#F4F2FF",
              boxSizing: "border-box",
              minWidth: "135px",
              width: "auto",
              lineHeight: "50px",
              height: "50px",
              textAlign: "center",
            }}
          >
            <label
              className="form-check-label"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              <input
                type="checkbox"
                className="form-check-input editaudienceform__input--check"
                name="unknown"
                value="Unknown"
                checked={isChecked("gender", "ALL")}
                onChange={(e) => onCheckChange("gender", "ALL")}
                onBlur={() => validateOnBlur("gender")}
              />
              {t("campaign_unknown")}
            </label>
          </div>
        </div>
        {errors.gender && (
          <SmallErrorText
            style={{ backgroundColor: "#FEF6F6", marginLeft: "1%" }}
            text={errors.gender}
          />
        )}
      </div>

      <div className="editaudienceform__field">
        <label className="editaudienceform__label">
          {t("campaign_ageGroup")}
        </label>

        <div style={{ marginLeft: "1%" }}>
          <div>
            {t("campaign_age")}
            <input
              style={{
                borderRadius: "4px",
                border: "1px solid #CCD0D2",
                boxSizing: "border-box",
                width: "60px",
                lineHeight: "50px",
                height: "50px",
                textAlign: "center",
                marginLeft: "10px",
              }}
              value={audienceData.age_min}
              onChange={(e) =>
                onChange("age_min", Math.round(Number(e.target.value)))
              }
              type="number"
              onBlur={() => validateOnBlur("age_min")}
              className="form-control form-check-inline"
              name="min_age"
              id="min_age"
              step="1"
            />{" "}
            {t("campaign_to")}
            <input
              style={{
                borderRadius: "4px",
                border: "1px solid #CCD0D2",
                boxSizing: "border-box",
                width: "60px",
                lineHeight: "50px",
                height: "50px",
                textAlign: "center",
                marginLeft: "10px",
              }}
              type="number"
              value={audienceData.age_max}
              onChange={(e) =>
                onChange("age_max", Math.round(Number(e.target.value)))
              }
              onBlur={() => validateOnBlur("age_max")}
              className="form-control form-check-inline"
              name="max_age"
              id="max_age"
              step="1"
            />
          </div>
        </div>
        {errors.age_min && (
          <SmallErrorText
            style={{ backgroundColor: "#FEF6F6", marginLeft: "1%" }}
            text={errors.age_min}
          />
        )}

        {errors.age_max && (
          <SmallErrorText
            style={{
              backgroundColor: "#FEF6F6",
              marginLeft: "1%",
              display: "block",
              width: "fit-content",
              marginTop: "10px",
            }}
            text={errors.age_max}
          />
        )}
      </div>

      <div className="editaudienceform__field">
        <label className="editaudienceform__label">
          {t("campaign_location")}
        </label>
        <Select
          value={getSelectValue("location")}
          onChange={(e) => onSelectChange("location", e)}
          onInputChange={(e) => getLocationOptions(e)}
          options={locationOptions}
          isMulti
          className="editaudienceform__input--select"
          styles={formSelectStyles}
          onMenuClose={() => validateOnBlur("location")}
        />

        {errors.location && (
          <SmallErrorText
            style={{
              backgroundColor: "#FEF6F6",
              marginLeft: "1%",
              marginTop: "10px",
              display: "block",
              width: "fit-content",
            }}
            text={errors.location}
          />
        )}

        <p className="editaudienceform__para">{t("campaign_canTypeTyxt")}</p>
      </div>

      <div className="editaudienceform__field">
        <label className="editaudienceform__label">
          {t("campaign_language")}
        </label>
        <Select
          value={getSelectValue("lang")}
          onChange={(e) => onSelectChange("lang", e)}
          onInputChange={(e) => getLangOptions(e)}
          options={langOptions}
          isMulti
          className="editaudienceform__input--select"
          placeholder={t("campaign_typeAudLang")}
          styles={formSelectStyles}
          onMenuClose={() => validateOnBlur("lang")}
        />

        {errors.lang && (
          <SmallErrorText
            style={{
              backgroundColor: "#FEF6F6",
              marginLeft: "1%",
              marginTop: "10px",
              display: "block",
              width: "fit-content",
            }}
            text={errors.lang}
          />
        )}

        <p className="editaudienceform__para">{t("campaign_ProfileLang")}</p>
      </div>

      <div className="editaudienceform__field field__devices ">
        <label className="editaudienceform__label">
          {t("campaign_adAppearInTheseDevices")}
        </label>

        <div className="editaudienceform_checkbox_field_box">
          <div
            className="form-check-inline custom-checkbox"
            style={{
              borderRadius: "4px",
              border: "solid 2px #6F1DDF",
              backgroundColor: "#F4F2FF",
              boxSizing: "border-box",
              minWidth: "115px",
              width: "auto",
              lineHeight: "50px",
              height: "50px",
              textAlign: "center",
            }}
          >
            <label
              className="form-check-label"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              <input
                type="checkbox"
                className="form-check-input editaudienceform__input--check"
                id="gender"
                name="gender[]"
                value="MALE"
                required
                checked={isChecked("device", "MOBILE")}
                onChange={(e) => onCheckChange("device", "MOBILE")}
                onBlur={() => validateOnBlur("device")}
              />
              {t("campaign_mobile")}
            </label>
          </div>

          <div
            className="form-check-inline custom-checkbox"
            style={{
              borderRadius: "4px",
              border: "solid 2px #6F1DDF",
              backgroundColor: "#F4F2FF",
              boxSizing: "border-box",
              minWidth: "115px",
              width: "auto",
              lineHeight: "50px",
              height: "50px",
              textAlign: "center",
            }}
          >
            <label
              className="form-check-label"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              <input
                type="checkbox"
                className="form-check-input editaudienceform__input--check"
                checked={isChecked("device", "DESKTOP")}
                onChange={(e) => onCheckChange("device", "DESKTOP")}
                onBlur={() => validateOnBlur("device")}
              />
              {t("campaign_desktop")}
            </label>
          </div>

          <div
            className="form-check-inline custom-checkbox"
            style={{
              borderRadius: "4px",
              border: "solid 2px #6F1DDF",
              backgroundColor: "#F4F2FF",
              boxSizing: "border-box",
              minWidth: "115px",
              width: "auto",
              lineHeight: "50px",
              height: "50px",
              textAlign: "center",
            }}
          >
            <label
              className="form-check-label"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              <input
                type="checkbox"
                className="form-check-input editaudienceform__input--check"
                id="gender"
                name="gender[]"
                value="MALE"
                required
                checked={isChecked("device", "ALL")}
                onChange={(e) => onCheckChange("device", "ALL")}
                onBlur={() => validateOnBlur("device")}
              />
              {t("campaign_tablet")}
            </label>
          </div>
          {errors.device && (
            <SmallErrorText
              style={{
                backgroundColor: "#FEF6F6",
                marginLeft: "1%",
                display: "block",
                width: "fit-content",
              }}
              text={errors.device}
            />
          )}
        </div>

        <label
          style={{ marginTop: "40px" }}
          className="editaudienceform__label"
        >
          {t("campaign_targetCostForConversion")}
        </label>

        <div style={{ marginLeft: "1%" }}>
          <div style={{ width: "90%" }} className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">{getCurrency()}</span>
            </div>
            <input
              type="text"
              className="form-control"
              name="avg_conversion_cost"
              id="avg_conversion_cost"
              aria-label="Amount (to the nearest dollar)"
              value={audienceData.avg_conversion_cost}
              onChange={(e) => onChange("avg_conversion_cost", e.target.value)}
              onBlur={() => validateOnBlur("avg_conversion_cost")}
            />
          </div>

          {errors.avg_conversion_cost && (
            <SmallErrorText
              text={errors.avg_conversion_cost}
              style={{
                backgroundColor: "#FEF6F6",
                marginLeft: "1%",
                display: "block",
                width: "fit-content",
                marginBottom: "10px",
              }}
            />
          )}

          <p style={{ width: "90%" }}>
            {t("campaign_bidForThisTargetSegmentText")}
          </p>
        </div>
      </div>
    </form>
  );
};

export default EditAudienceForm;
