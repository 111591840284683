export const EVENT_ID_1 = "1"
export const EVENT_ID_2 = "2"
export const EVENT_ID_3 = "3"
export const EVENT_ID_4 = "4"
export const EVENT_ID_5 = "5"
export const EVENT_ID_6 = "6"
export const EVENT_ID_7 = "7"
export const EVENT_ID_8 = "8"
export const EVENT_ID_9 = "9"
export const EVENT_ID_10 = "10"
export const EVENT_ID_11 = "11"
export const EVENT_ID_12 = "12"
export const EVENT_ID_13 = "13"
export const EVENT_ID_14 = "14"
export const EVENT_ID_15 = "15"
export const EVENT_ID_16 = "16"
export const EVENT_ID_17 = "17"
export const EVENT_ID_18 = "18"
export const EVENT_TYPE_EXTERNAL = "external"
export const EVENT_TYPE_CONTACT = "contact"
export const EVENT_TYPE_CREATIVE = "creative"