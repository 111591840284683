import axiosClient from "../apiClient";
import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";


const base = BASE_URL + VERSION_PREFIX


export function onUserLogin(loginReqPayload) {
    return axiosClient.post(VERSION_PREFIX + '/login', loginReqPayload);
}


export function onUserLogout() {
    return http.post(base + '/logout');
}
