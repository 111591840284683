import { BASE_URL } from "../../../StaticArray/StaticArray";
import http from "../../../shared/http-request";

import { notify } from "../Notification/Notifications";

const url = BASE_URL + "api/v1/";
var token = localStorage.getItem("token_auth");
const config = {
  headers: { Authorization: "Bearer " + localStorage.getItem("user_token") },
};

//   /**
//    * Request to login user
//    */
// export const login = async () => {
//     return new Promise (async (resolve, reject) => {
//             Axios.post(url+'login' ,{
//                 'email' : 'trustung@gmail.com',
//                 'password' : 'test'
//               }
//           ).then(
//           response => {
//             // localStorage.setItem('user_token',  response.data.data.access_token);
//             token=response.data.data.access_token;
//             resolve(response.data.data.access_token)
//           }).catch(err=> {
//             console.log('login error', err.response);
//             notify(err.response, 'Login Failed');
//             return reject(err.response);
//         })
//     })
// }

/**
 * Get User Details
 */
export const userDetails = async () => {
  return new Promise(async (resolve, reject) => {
    http
      .get(url + "user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token_auth"),
        },
      })
      .then((response) => {
        console.log("user details api", response.data.data);
        resolve(response.data.data);
      })
      .catch((err) => {
        console.log("User Detail Error", err.response);
        notify(err.response, "User detail not found!");
        return reject(err.response);
      });
  });
};

/**
 * Request to get packages
 */
export const getPackages = async () => {
  return new Promise(async (resolve, reject) => {
    http
      .get(url + "package", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        console.log("Packages List Error", err);
        notify(err.response, "Packages not found!");

        return reject(err);
      });
  });
};

/**
 * Request to get stripe packages
 */
export const getStripePackages = async () => {
  return new Promise(async (resolve, reject) => {
    http
      .get(url + "stripe/peasy/billings/", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        console.log("Packages List Error", err);
        notify(err.response, "Packages not found!");

        return reject(err);
      });
  });
};

/**
 * Get Transaction History for prepaid Users
 */
export const getTransactionHistory = async () => {
  return new Promise(async (resolve, reject) => {
    http
      .get(url + "chargebee/availablePackage", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response.data.data.transactions);
      })
      .catch((err) => {
        console.log("Transaction History Error", err.response);
        notify(err.response, "Transaction history not found!");
        return reject(err.response);
      });
  });
};

/**
 * Update chargebee subscription
 */
export const updateChargebeeSubscription = async (data) => {
  return new Promise(async (resolve, reject) => {
    http
      .post(url + "update/updateSubscription", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("Update subscription error", err.response);
        notify(err.response, "Please Try Again!");
        return reject(err.response);
      });
  });
};

/**
 * Top Up Request(Prepaid User)
 */
export const topUp = async (data) => {
  return new Promise(async (resolve, reject) => {
    http
      .post(url + "chargebee/topUp", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("Top Up error", err.response);
        notify(err.response, "Please Try Again!");
        return reject(err.response);
      });
  });
};

/**
 * Change Plan request
 */
export const changePlan = async (data) => {
  return new Promise(async (resolve, reject) => {
    http
      .post(url + "chargebee/switchPlan", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("change plan error", err.response);
        return reject(err.response);
      });
  });
};

/**
 * Request to Unsubscibe plan
 */
export const unsubscribe = async () => {
  return new Promise(async (resolve, reject) => {
    http
      .post(
        url + "chargebee/unsubscribe",
        {},
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("UnSubscribe error", err.response);
        return reject(err.response);
      });
  });
};

/**
 * Get Sub Users
 */
export const getSubUsers = async () => {
  return new Promise(async (resolve, reject) => {
    http
      .get(url + "user/subuser", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        console.log("Sub Users Error", err.response);
        notify(err.response, "Sub User not found!");
        return reject(err.response);
      });
  });
};

/**
 * Invite New Sub User
 */
export const inviteSubUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    http
      .post(url + "user/inviteSubuser", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        notify(err.response, "Error While Inviting");
        return reject(err.response);
      });
  });
};

/**
 * Remove Sub User
 */
export const revokeSubUser = async (id) => {
  return new Promise(async (resolve, reject) => {
    http
      .post(url + "user/revokeSubuser", id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("Revoke Error error", err.response);
        notify(err.response, "Error While Revoking");
        return reject(err.response);
      });
  });
};

/**
 * Assign Qoute To Sub User
 */
export const assignQoute = async (id) => {
  return new Promise(async (resolve, reject) => {
    http
      .post(url + "campaign/balance/assignPostpaid", id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("Qoute error", err.response);
        return reject(err.response);
      });
  });
};
