import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";
import RegistrationForm from "../Register/RegistrationForm";
import DividerWithText from "../../common/divider-with-text/DividerWithText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Hidden } from "@material-ui/core";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";

const LoginPage = (props) => {
  const { t } = useTranslation();
  console.log(props,"ck log prop-");
  const { grant } = useParams();
  const matches = useMediaQuery("(min-width:1010px)");
  const matchesAtSm = useMediaQuery("(max-width:400px)");
  const [activateFacebookLogin, setActivateFacebookLogin] = useState(
    props.externalUrl
  );
  const [queries, setQueries] = useState({ global_id: "" });

  // Configuring notifications library
  toast.configure({
    position: toast.POSITION.BOTTOM_LEFT,
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    console.log("login canva params", params);
    try {
      // window.$peasy.reset();
      // console.log("login page reset");
    } catch (e) {}

    // if(window.location.search)
    // setQueries((prevState) => ({ ...prevState, ...params }));
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setQueries((prevState) => ({ ...prevState, ...params }));
  }, []);
  useEffect(() => {
    if (props.auth) {
      if (props.auth.status == "no-ad-account") {
        setActivateFacebookLogin(true);
        setTimeout(() => setActivateFacebookLogin(false), 2000);
      }
    }
  }, [props.auth]);

  // History config
  const History = useHistory();
  const [loginData, setLoginData] = useState({ email: "", password: "" });

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("submit", window.location);
    await props.loginUser(loginData.email, loginData.password, window.location);
  };

  if (props.auth.fetched && props.auth.isAuthenticated) {
    console.log("window.location.pathname", window.location.pathname);
    if (window.location.pathname === "/external") {
      History.push({
        pathname: "/external",
        search: window.location.search,
      });
    } else {
      if (window.location.pathname != "/canva" && window.location.pathname != "/ps-connect"  ) {
        History.push("/dashboard");
      }
    }
  }

  return (
    <React.Fragment>
      <div id="app"></div>
      <nav className="navbar navbar-expand-lg navbar-absolute">
        <div className="container-fluid container-fluid-negative-margin">
          <a className="logo" href="#"></a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="nav nav-item">
              <a href="{{ url('/') }}" className="navbar-brand">
                <img src="/images/assets/logo.png" alt="logo" />
              </a>
            </div>
          </div>
          {matches && (
            <>
              <div style={{ float: "right", marginRight: "20px" }}>
                {!grant ? (
                  <>
                    <div style={{ fontWeight: "bold", color: "#8d02fa" }}>
                      {t("login_dontHaveAccount")}?{" "}
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {t("login_getPeasyFree")}
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ fontWeight: "bold", color: "#8d02fa" }}>
                      {t("login_applyForGrant")}
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {t("login_willConnectShortly")}
                    </div>
                  </>
                )}
              </div>

              <div
                style={{ float: "right", marginRight: "20px", width: "400px" }}
              >
                <RegistrationForm globalId={queries.global_id} isExternalRoute={props.isExternalRoute} />
              </div>
            </>
          )}
        </div>
      </nav>

      <div className="container-fluid basic-layout">
        <div className="row">
          <Hidden smDown>
            {!grant ? (
              <div className="col col-md-6 col-lg-4 jumbotron">&nbsp;</div>
            ) : (
              <div className="col col-md-6 col-lg-4 jumbotron grant">
                &nbsp;
              </div>
            )}
          </Hidden>

          <div className="col col-sm-12 col-md-6 col-lg-8">
            <div className="row justify-content-md-center">
              <div className="col col-lg-5 ">
                {/* <h1 className="text-center"><a className="logo" href="{{ url('/') }}">{{ config('mrt.app_name') }}</a></h1> */}

                {!matches && !matchesAtSm && (
                  <div style={{ padding: "0px 20px", marginTop: "40px" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "#8d02fa",
                        textAlign: "center",
                      }}
                    >
                      {t("login_dontHaveAccount")}?
                    </div>
                    <div style={{ fontWeight: "bold", textAlign: "center" }}>
                      {t("login_getPeasyFree")}
                    </div>
                    <br />
                    <RegistrationForm globalId={queries.global_id} isExternalRoute={props.isExternalRoute} />
                  </div>
                )}

                <div className="card border-0 card-login">
                  <h2 className="card-header bg-transparent border-0 text-center">
                    {t("login_signIn")}
                  </h2>
                  <div className="card-body">
                    <form className="form-horizontal" onSubmit={onSubmit}>
                      <div
                        className={`form-group ${
                          props.auth.error && props.auth.emailError
                            ? "has-error"
                            : ""
                        }`}
                      >
                        <label for="email" className="control-label">
                          <strong>{t("login_emailAddress")}</strong>
                        </label>
                        <div>
                          <input
                            // id="email"
                            type="email"
                            className="form-control"
                            name="email"
                            value={loginData.email}
                            onChange={(e) => {
                              const object = {
                                ...loginData,
                                email: e.target.value,
                              };
                              setLoginData(object);
                            }}
                          />
                          {props.auth.error && props.auth.emailError && (
                            <span className="help-block">
                              <p className="text-danger">
                                {props.auth.emailError}
                              </p>
                            </span>
                          )}{" "}
                        </div>
                      </div>
                      <div
                        className={`form-group ${
                          props.auth.error && props.auth.passwordErr
                            ? "has-error"
                            : ""
                        }`}
                      >
                        <label for="password" className="control-label">
                          <strong>{t("login_password")}</strong>
                        </label>
                        <div>
                          <input
                            id="password"
                            type="password"
                            className="form-control"
                            name="password"
                            value={loginData.password}
                            onChange={(e) => {
                              const object = {
                                ...loginData,
                                password: e.target.value,
                              };
                              setLoginData(object);
                            }}
                          />
                          <span className="help-block">
                            {props.auth.error && props.auth.passwordErr && (
                              <p className="text-danger">
                                {props.auth.passwordErr}
                              </p>
                            )}{" "}
                          </span>
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <div className="col-md-offset-4">
                          <div className="checkbox">
                            <label>
                              <input type="checkbox" name="remember" /> Remember
                              Me
                            </label>
                          </div>
                        </div>
                      </div> */}
                      <div className="form-group">
                        <div className="col-md-offset-4">
                          <button
                            type="submit"
                            style={{
                              width: "100%",
                              height: "50px",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                            className="btn btn-primary"
                          >
                            <i className="fa fa-btn fa-sign-in"></i>
                            {t("loginHeading")}
                          </button>

                          {/* <DividerWithText>OR</DividerWithText> */}
                          <p>
                            {/* Get your free Peasy account. */}
                            <br />

                            <RegistrationForm
                              isLogin={true}
                              activateFacebookLogin={activateFacebookLogin}
                              globalId={queries.global_id}
                              isExternalRoute={props.isExternalRoute}
                            />

                            <br />
                            <a href="https://app.peasy.ai/service/password/reset">
                              <span className="btn btn-link">
                                {t("login_forgotPassword")}
                              </span>
                            </a>
                            {/* <div
                              className="btn btn-outline-primary"
                              onClick={() => {
                                History.push("/register");
                              }}
                            >
                              Register
                            </div> */}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (email, password, path = {}) => {
      dispatch(loginUser(email, password, path));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
