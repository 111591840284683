import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import {
  onGetPrepaidSettingsActiveAccounts,
  onGetPrepaidUnusedCurrency,
  onPostPrepaidSetting,
  onUpdatePrepaidSetting,
} from "../../../utils/network/lib/prepaidSetting";
import PrepaidScreenFbTable from "../prepaidScreenFbTable/PrepaidScreenFbTable";
import "./PrepaidUserForm.css";

const PrepaidUserForm = ({
  setShowAddScreen,
  isEditingMode,
  editSetting,
  setEditSetting,
  setIsEditingMode,
  getPrepaidSettings,
}) => {
  // useEffect(() => {
  //   getCurrencies();
  // })

  const History = useHistory();

  const [formData, setFormData] = useState({
    currency: "",
    tier1_ceiling: null,
    tier1_rate: null,
    tier2_ceiling: null,
    tier2_rate: null,
    tier3_ceiling: null,
    tier3_rate: null,
    tax: null,
  });

  const [savedData, setSavedData] = useState(null);

  const [showFbTable, setShowFbTable] = useState(false);

  const [activeAccounts, setActiveAccounts] = useState([]);

  const [currencyList, setCurrencyList] = useState([]);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    if (isEditingMode) {
      setShowFbTable(true);
      setSavedData(editSetting);
      setCurrencyList([editSetting.currency]);
    }
    if (!isEditingMode) {
      getCurrencies();
    }
  }, [isEditingMode]);

  useEffect(() => {
    if (savedData && isEditingMode) {
      getActiveAccounts();
      // setIsEditingMode(false);
      // setEditSetting(null);
    }
  }, [savedData]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (isEditingMode) {
      onUpdatePrepaidSetting(savedData.id, formData)
        .then((res) => {
          setSavedData(res.data.data);
          setShowFbTable(true);
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
      onPostPrepaidSetting(formData)
        .then((res) => {
          setIsSubmitDisabled(true);
          setSavedData(res.data.data);
          setShowFbTable(true);
        })
        .catch((err) => toast.error(err.response.data.message));
    }
  };

  const getActiveAccounts = () => {
    onGetPrepaidSettingsActiveAccounts(savedData.id)
      .then((res) => {
        setFormData(res.data.data.settings);
        setActiveAccounts(res.data.data.activeAccounts);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const getCurrencies = () => {
    onGetPrepaidUnusedCurrency()
      .then((res) => {
        let array = [];
        Object.values(res.data).map((currency) => {
          array = [...array, currency];
        });
        setCurrencyList(array);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  return (
    <React.Fragment>
      <div className="prepaiduserform__container">
        <div className="FormPannel-Main">
          <form onSubmit={onSubmit}>
            <label for="country">Currency:</label>
            <select
              onChange={(e) =>
                setFormData({ ...formData, currency: e.target.value })
              }
              value={formData.currency}
            >
              <option disabled value="">
                Select currency
              </option>
              {currencyList.map((currency) => (
                <option value={currency}>{currency}</option>
              ))}
            </select>
            <br></br>
            <label for="username">Tier 1 </label>
            <label for="username"> Ceiling</label>
            <input
              required
              onChange={(e) =>
                setFormData({ ...formData, tier1_ceiling: e.target.value })
              }
              value={formData.tier1_ceiling}
              className="prepaiduserform__input"
              type="number"
            />
            <label>Fee</label>
            <input
              required
              onChange={(e) =>
                setFormData({ ...formData, tier1_rate: e.target.value })
              }
              value={formData.tier1_rate}
              className="prepaiduserform__input"
              type="number"
            />
            <br></br>
            <label for="username">Tier 2</label>
            <label for="username">Ceiling</label>
            <input
              required
              onChange={(e) =>
                setFormData({ ...formData, tier2_ceiling: e.target.value })
              }
              value={formData.tier2_ceiling}
              className="prepaiduserform__input"
              type="number"
            />
            <label>Fee</label>
            <input
              required
              onChange={(e) =>
                setFormData({ ...formData, tier2_rate: e.target.value })
              }
              value={formData.tier2_rate}
              className="prepaiduserform__input"
              type="number"
            />{" "}
            <br></br>
            <label for="username">Tier 3</label>
            <label for="username">Ceiling</label>
            <input
              required
              onChange={(e) =>
                setFormData({ ...formData, tier3_ceiling: e.target.value })
              }
              value={formData.tier3_ceiling}
              className="prepaiduserform__input"
              type="number"
            />
            <label>Fee</label>
            <input
              required
              onChange={(e) =>
                setFormData({ ...formData, tier3_rate: e.target.value })
              }
              value={formData.tier3_rate}
              className="prepaiduserform__input"
              type="number"
            />{" "}
            <br></br>
            <br></br>
            <label style={{ marginTop: "50px" }} for="username">
              Tax Rate
            </label>
            <input
              required
              onChange={(e) =>
                setFormData({ ...formData, tax: e.target.value })
              }
              value={formData.tax}
              className="prepaiduserform__input"
              type="number"
            />
            <br></br>
            <button
              style={{ float: "right", marginRight: "10%" }}
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitDisabled}
            >
              Submit
            </button>
            <button
              style={{ float: "right", marginRight: "3%" }}
              type="button"
              className="btn btn-outline-primary"
              // onClick={() => setShowAddScreen(false)}
              onClick={() => History.go(0)}
            >
              Back
            </button>
          </form>
        </div>
      </div>

      {showFbTable && (
        <PrepaidScreenFbTable
          savedData={savedData}
          activeAccounts={activeAccounts}
          getActiveAccounts={getActiveAccounts}
          currency={savedData.currency}
        />
      )}
    </React.Fragment>
  );
};

export default PrepaidUserForm;
