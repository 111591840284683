import React, { PureComponent } from 'react'
import "./topUpModal.css";
import {topUp} from '../../Axios/ApiRequests';

import Modal from "react-responsive-modal";



export class TopUpModal extends PureComponent {

    constructor(props) {
        super(props);
        this.state={
            top_up_value: 50,
            is_loading: false
        }
    }

    async handleTopUp() {
      const {closeModal, showNot} = this.props
      this.setState({is_loading: true});
      try {
        const resp = await topUp({
          'amount':this.state.top_up_value
      })
      closeModal();
      showNot(resp)
      } catch (error) {
        console.log(error.response)
        closeModal();
        showNot(error.response);
      }
      
      this.setState({is_loading: false});
      this.setState({top_up_value: 50});
        
    }



  /**
   * Decrement TopUp Value
   */
  decrementTopUp() {
    if(this.state.top_up_value !== 50) {
      this.setState({top_up_value: this.state.top_up_value - 10});
      return true;
    } else {
      return false;
    }
  }
  
  /**
   * Increment TopUp Value
   */
  incrementTopUp() {
      this.setState({top_up_value: this.state.top_up_value + 10});
      return;
  }

    render() {
       const {open, closeModal, userData} =this.props;
        return (
          <div className="outer-model">
            <Modal  open={open} onClose={() => closeModal()} showCloseIcon={false}>
            <div className='model-content'>
            <h2>TOPUP AD SPEND</h2>
              <div align="center">
                <div>
                  <div>
                    <button className="button w-button" onClick={() => this.decrementTopUp()}>-</button>
                    {this.state.top_up_value}	
                    <button className="button w-button" onClick={() => this.incrementTopUp()}>+</button>
                  </div>
                </div>
                <div>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">{userData.currency_type}</span>
                  </div>
                </div>
                      <div className="mt-16">
                        <div className="lead-container"><button onClick={()=>this.handleTopUp()} disabled={this.state.is_loading} className="button w-button">{!this.state.is_loading ? 'Upgrade Plan' : 'Please Wait!'}</button>
                        </div>
                      </div>
                    </div>
                    </div>
            </Modal>
        
          </div>
        )
    }
}

export default TopUpModal
