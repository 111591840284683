import React from "react";
import { BASE_URL } from "../../../../StaticArray/StaticArray";
import "./Integration.css";

const Integration = () => {
  return (
    <div className="billing-row">
      <div style={{justifyContent: 'space-evenly'}} className="row">
        <div className="col-md-4">
          <div className="billing-single">
            <h1 style={{fontSize: '40px'}} className="large-price">CRM</h1>
            <ul className="price-list">
              <li key="desc">
                <span className="integration__span--desc" >
                  Integrate Peasy to your CRM. Give Peasy the permission to:
                </span>
              </li>
              <li key="acc">
                <img
                  className="integration__img--tick"
                  src={BASE_URL + "images/check.png"}
                  alt=""
                />
                <span className="integation__span--tick">
                  Automatically import leads from Facebook Lead Form
                </span>
              </li>
              <li key="data">
                <img
                  className="integration__img--tick"
                  src={BASE_URL + "images/check.png"}
                  alt=""
                />
                <span className="integation__span--tick">
                  Export leads to automatically create customaudience for
                  Facebook and Google campaign
                </span>
              </li>
              <li key="date">
                <img
                  className="integration__img--tick"
                  src={BASE_URL + "images/check.png"}
                  alt=""
                />
                <span className="integation__span--tick">
                  Update lead status
                </span>
              </li>
            </ul>
            <button className="button">Zapier</button>
            <button className="button" style={{ marginTop: "20px" }}>
              Generate Pixel
            </button>
          </div>
        </div>

        <div className="col-md-4">
          <div className="billing-single">
            <h1 style={{ fontSize: "40px" }} className="large-price">
              Inventory
            </h1>
            <ul className="price-list">
              <li key="desc">
                <span className="integration__span--desc" >
                  Integrate Peasy to your webstore. Grant Peasy the permission
                  to:
                </span>
              </li>
              <li key="acc">
                <img
                  className="integration__img--tick"
                  src={BASE_URL + "images/check.png"}
                  alt=""
                />
                <span className="integation__span--tick">
                  Automatically import inventory from your webstore
                </span>
              </li>
              <li key="data">
                <img
                  className="integration__img--tick"
                  src={BASE_URL + "images/check.png"}
                  alt=""
                />
                <span className="integation__span--tick">
                  Generate audience segments based on product set
                </span>
              </li>
              <li key="date">
                <img
                  className="integration__img--tick"
                  src={BASE_URL + "images/check.png"}
                  alt=""
                />
                <span className="integation__span--tick">
                  Dynamically adjust your ads targeting and spent based on
                  innventory leveland ROAS
                </span>
              </li>
            </ul>
            <button className="button">WooCommerce</button>
            <button className="button" style={{ marginTop: "20px" }}>
              Zapier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
