import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  FormText,
} from "reactstrap";
import { getAllPackagePermissions } from "../../../redux/actions/permissionActions";
import { connect } from "react-redux";
import { MenuItem } from "@material-ui/core";

const PackageAddModal = (props) => {
  const {
    toggle,
    className,
    isOpen,
    permissions: { allPermissions },
    handleAddPackage,
    perErr,
  } = props;
  const [inputs, setInputs] = useState({
    name: "",
    description: "",
    price: "",
    ad_spend_limit: "",
    account_limit: "",
    reference: "",
    permission_ids: "",
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  useEffect(() => {
    props.getAllPackagePermissions();
  }, []);
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Add Package</ModalHeader>
        {!!allPermissions && allPermissions.length > 0 ? (
          <Form onSubmit={(e) => handleAddPackage(e, inputs)}>
            <ModalBody>
              <FormGroup>
                <Label for="name">Package name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={handleInputChange}
                  value={inputs.name}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  onChange={handleInputChange}
                  value={inputs.description}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="price">Price</Label>
                <Input
                  type="text"
                  name="price"
                  value={inputs.price}
                  id="price"
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="ad_spend_limit">Add spend limit</Label>
                <Input
                  type="text"
                  name="ad_spend_limit"
                  id="ad_spend_limit"
                  onChange={handleInputChange}
                  value={inputs.ad_spend_limit}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="account_limit">Account limit</Label>
                <Input
                  type="text"
                  name="account_limit"
                  id="account_limit"
                  onChange={handleInputChange}
                  value={inputs.account_limit}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="reference">Reference</Label>
                <Input
                  type="text"
                  name="reference"
                  id="reference"
                  onChange={handleInputChange}
                  value={inputs.reference}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="permission_ids">Permission</Label>
                <Input
                  type="select"
                  name="permission_ids"
                  id="permission_ids"
                  onChange={handleInputChange}
                  value={inputs.permission_ids}
                  required={true}
                  ariaRequired="true"
                >
                  <MenuItem>Select permission</MenuItem>
                  {allPermissions.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Input>
                <FormText>
                  <span className="text-danger">
                    {!!perErr && "Please select permission ID."}
                  </span>
                </FormText>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Add
              </Button>{" "}
            </ModalFooter>
          </Form>
        ) : !allPermissions ? (
          <Spinner />
        ) : (
          <span>No permissions found</span>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ permissions }) => ({
  permissions,
});

export default connect(mapStateToProps, { getAllPackagePermissions })(
  PackageAddModal
);
