import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";
import axios from "axios";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/validate"
const shortBase = VERSION_PREFIX + "/validate"

export function onCheckFbTos(payload) {
    return PeasyApi.post(shortBase + '/fb/tos', payload)
}

export function onCheckFbAccess() {
    return PeasyApi.get(shortBase + '/fb/access')
}

export function onFbValidateAssets(payload) {
    return PeasyApi.post(shortBase + '/fb/assets', payload)
}


export function onValidateRegisterFields(payload) {
    return axios.post(base + '/validateRegisterFields', payload)
}

