import React from "react";
import "./LowerInfoSection.css";

const LowerInfoSection = ({ textType, text, errors }) => {
  console.log('errors', errors);
  const getText = () => {
    switch (textType) {
      case "warning":
        return (
          <span className="lowerinfosection__span--warning">
            <span className="lowerinfosection__circle--exclamation">!</span>
            {text}
          </span>
        );
      case "error":
          return (
            <span className="lowerinfosection__span--error">
            <span style={{backgroundColor: '#F52727'}} className="lowerinfosection__circle--exclamation">	&#9888;</span>
            {errors[0]}
          </span>
        );
      default:
        return null;
    }
  };

  return <div className="lowerinfosection__container">{getText()}</div>;
};

export default LowerInfoSection;
