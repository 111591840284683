import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  Button,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import "./dashboard.css";
// import StackedBarChart from "./dashboardComponents/StackedBarChart";
import { TrendingDown, TrendingFlat, TrendingUp } from "@material-ui/icons";
import BrandOverview from "../BrandOverview/BrandOverview";
import Graph from "../BrandCreate/steps/Graph";
import PeasyApi from "../../utils/peasy.api";
import { useHistory, useLocation } from "react-router";
import { Spinner } from "reactstrap";
import FirstTimePopup from "./FirstTimePopup";
import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { toast } from "react-toastify";
import "../../translations/i18n";
import Articles from "./dashboardComponents/Articles";
import { useTranslation } from "react-i18next";
import RSS from "./dashboardComponents/RSS";
import HorizontalScroll from "react-horizontal-scrolling";
import CardComponent from "../../components/card-component/CardComponent";
import CheckDialog from "../CheckDialog";
import CampaignChips from "./dashboardComponents/CampaignChips";
import CohortChart from "./dashboardComponents/CohortChart";
import i18next from "i18next";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import {
  onCheckFbAccess,
  onFbValidateAssets,
} from "../../utils/network/lib/validate";
import {
  onGetBrandData,
  onGetBrandLimit,
  onGetBrandProfileList,
  onGetDummyBrandProfile,
} from "../../utils/network/lib/brand";
import {
  onGetDashboardCampaignMetric,
  onGetDashboardLeadMetric,
} from "../../utils/network/lib/dashboard";
import { onGetFunnelData } from "../../utils/network/lib/funnel";

const tagManagerArgs = {
  gtmId: "GTM-TX74NMK",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow:
      "rgba(`6`0, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
  },
  btn: {
    margin: "0px",
  },
  formControl: {
    minWidth: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dashboardBtn: {
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  noProfileBtn: {
    minWidth: "200px",
    borderRadius: "10px",
    marginTop: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  businessProfileBtn: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "6px",
    },
  },
  dummybrandSH: {
    color: "rgba(24, 24, 25, 0.42)",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [brandProfileList, setBrandProfileList] = useState([]);
  const [currentBrandId, setCurrentBrandId] = useState(null);
  const [details, setDetails] = useState({});
  const [brand, setBrand] = useState({});
  const [campaignMetricData, setCampaignMetricData] = useState({});
  const [ecommMetricData, setEcommMetricData] = useState(null);
  const [leadMetricData, setLeadMetricData] = useState({});
  const [lineChartData, setLineChartData] = useState([]);
  const [isLineChartDataEmpty, setIsLineChartDataEmpty] = useState(true);
  const [campaignDaysFilter, setCampaignDaysFilter] = useState(7);
  const [leadDaysFilter, setLeadDaysFilter] = useState(7);
  const [ecommDaysFilter, setEcommDaysFilter] = useState(7);
  const [availableBalance, setAvailableBalance] = useState("");
  const [checkCampaignCreateLoading, setCheckCampaignCreateLoading] =
    useState(false);

  const [isBrandProfileListEmpty, setIsBrandProfileListEmpty] = useState(false);
  const [isBrandProfileListLoading, setIsbrandProfileListLoading] =
    useState(true);

  const [dummyProfileLoading, setDummyProfileLoading] = useState(false);
  const [dummyProfileDisabled, setDummyProfileDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [target, setTarget] = useState("");
  const [checkLoading, setCheckLoading] = useState(false);
  const [leadMetricLoading, setLeadMetricLoading] = useState(true);
  const [campaignMetricLoading, setCampaignMetricLoading] = useState(false);
  const [isEcommLineChartDataEmpty, setIsEcommLineChartDataEmpty] =
    useState(true);
  const [ecommerceMetricData, setEcommerceMetricData] = useState(null);

  let LineChartOptions = {
    colors: ["#6F1DDF", "#BA68C8", "#B4FF00", "#ff00ff", "#38BEAB", "#BBA1D4"],
    chart: {
      renderTo: "container",
      type: "spline",
    },
    title: {
      text: "",
      x: -20, //center
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: t("dashboard_range"),
      },
      min: 0,
    },
    tooltip: {
      formatter: function () {
        return (
          "Value for " +
          this.series.name +
          " on" +
          "</b><br/>" +
          this.x +
          ": " +
          this.y
        );
      },
    },
    series: [],
  };
  // const getBalance = async () => {
  //   const res = await PeasyApi.get("api/v1/balance");
  //   setAvailableBalance(res);
  // };
  // useEffect(() => {
  //   getBalance();
  // }, []);

  useEffect(() => {
    try {
      console.log("login event call...");
      window.$peasy.triggerEvent({ event_name: "login" });
      console.log("login event called ");
    } catch (error) {
      console.log("trouble sending event", error);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(campaignMetricData).length > 0) {
      let lineChartOptions = LineChartOptions;
      let { chip_data, ...others } = campaignMetricData;
      lineChartOptions = {
        ...lineChartOptions,
        xAxis: {
          categories: getCategories(others),
        },
        series: getSeries(others),
      };
      var lineChartKeyArray = Object.keys(others);
      const isEmpty = (field) => others[field].length === 0;
      if (lineChartKeyArray.every(isEmpty)) {
        setIsLineChartDataEmpty(true);
      } else {
        setIsLineChartDataEmpty(false);
      }
      setLineChartData(lineChartOptions);
    }
  }, [campaignMetricData]);

  useEffect(() => {
    if (
      ecommMetricData &&
      ecommMetricData.status == true &&
      Object.keys(ecommMetricData.data).length > 0
    ) {
      let lineChartOptions = LineChartOptions;
      let others = ecommMetricData.data;
      lineChartOptions = {
        ...lineChartOptions,
        xAxis: {
          categories: getCategories(others),
        },
        series: getSeries(others),
      };
      var lineChartKeyArray = Object.keys(others);
      const isEmpty = (field) => others[field].length === 0;
      if (ecommMetricData.data.status == false) {
        setIsEcommLineChartDataEmpty(true);
      } else {
        setIsEcommLineChartDataEmpty(false);
      }
      setEcommerceMetricData(lineChartOptions);
    }
  }, [ecommMetricData]);

  useEffect(() => {
    fetchBrandProfileList();
    updateLanguage();
    addGtagData();
    addGistIdentify();
  }, []);

  useEffect(() => {
    if (brand.id) {
      fetchCampaignMetric(campaignDaysFilter);
    }
  }, [brand, campaignDaysFilter]);
  useEffect(() => {
    if (brand.id) {
      fetchLeadMetric(leadDaysFilter);
    }
  }, [brand, leadDaysFilter]);
  useEffect(() => {
    if (brand.id) {
      getFunnelData();
    }
  }, [brand]);

  useEffect(() => {
    if (currentBrandId) {
      fetchBrandProfileData(currentBrandId);
    }
  }, [currentBrandId]);

  useEffect(() => {
    let fbConnectFlag = localStorage.getItem("fbConnectFlag");
    if (fbConnectFlag === "true") {
      checkFbAccess();
    }
  }, []);

  useEffect(() => {
    if (
      details &&
      details.objective &&
      details.objective !== "LEAD_GENERATION"
    ) {
      fetchEcommMetric(ecommDaysFilter);
    }
  }, [details, ecommDaysFilter]);

  const checkFbAccess = async () => {
    let data;
    try {
      localStorage.setItem("fbConnectFlag", false);
      const resp = await onCheckFbAccess();
      if (resp && resp.access_token_status.status === false) {
        data = resp.access_token_status;
        setUrl(data.callback_url);
        setMessage(data.message);
        setButtonText(data.callback_button_text);
        setTarget(data.target);
        setOpen(true);
      } else if (resp && resp.ad_account_status.status === false) {
        data = resp.ad_account_status;
        setUrl(data.callback_url);
        setMessage(data.message);
        setButtonText(data.callback_button_text);
        setTarget(data.target);
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = (chartData) => {
    const chartDataKeys = chartData ? Object.keys(chartData) : [];
    return (
      chartData &&
      chartDataKeys.length &&
      Object.keys(chartData[chartDataKeys[0]])
    );
  };

  const getSeries = (chartData) => {
    const chartDataKeys = chartData ? Object.keys(chartData) : [];
    let categories =
      chartData &&
      chartDataKeys.length &&
      Object.keys(chartData[chartDataKeys[0]]);
    let series = [];
    series =
      Array.isArray(chartDataKeys) &&
      chartDataKeys.map((chartKey) => {
        let item = {
          marker: {
            enabled: false,
          },
          name: chartKey,
          data:
            Array.isArray(categories) &&
            categories.map((key) => chartData[chartKey][key]),
        };
        return item;
      });
    return series;
  };

  const fetchBrandProfileData = async (id) => {
    try {
      const data = await onGetBrandData({
        id: id,
      });
      if (data) {
        setBrand(data);
      }
    } catch (e) {
      console.log(e);
      toast.error(t("dashboard_brandDataError"));
    }
  };

  const updateLanguage = async () => {
    let userData = JSON.parse(localStorage.getItem("user_data")) || {};
    i18next.changeLanguage(userData.language || "en");
  };

  const addGtagData = () => {
    let userData = JSON.parse(localStorage.getItem("user_data")) || {};
    TagManager.initialize(tagManagerArgs);
    // console.log(userData.user_total_spent);
    const args = {
      
      dataLayer: {
        user_email: userData.email || "",
        user_name: userData.name || "",
        user_language: userData.language || "en",
        user_role: userData.role || "user",  
        user_total_spent: userData.user_total_spent || 0,
        pm_user_id: userData.id || 0,
        user_prepaid_balance: userData.prepaid_balance || 0,
        user_plan: userData.active_package?.name || "",
        user_paid: userData.active_package?.price || 0,
        user_session_count: userData.session_count || 0,
        /* can pass more variables here if needed */
      },
    };
    TagManager.dataLayer(args);
    // console.log("User set dataLayer updated");
    
  };

  const addGistIdentify = () => {
    window.gist.identify(
      localStorage.getItem("user_data") &&
        JSON.parse(localStorage.getItem("user_data")).id,
      {
        email: JSON.parse(localStorage.getItem("user_data")).email,
      }
    );
  };
  
  const fetchBrandProfileList = async () => {
    try {
      const data = await onGetBrandProfileList();
      if (data) {
        setBrandProfileList(data);
        setIsbrandProfileListLoading(false);
        try {
          var savedCPID = await localStorage.getItem("profile");
        } catch (e) {}
        if (location.state && location.state.id) {
          setCurrentBrandId(savedCPID ? savedCPID : location.state.id);
          storeCurrentBrandId(savedCPID ? savedCPID : location.state.id)
        } else if (data.length > 0) {
          setCurrentBrandId(savedCPID ? savedCPID : data[0].id);
          storeCurrentBrandId(savedCPID ? savedCPID : data[0].id)
        } else if (data.length === 0) {
          storeCurrentBrandId(null)
          setIsBrandProfileListEmpty(true);
          if (getData("account_type") == "prepaid") {
            history.push("/brand/create");
          }
        }
      } else {
      }
    } catch (e) {
      toast.error(t("dashboard_errorWhilefetchingProfiles"));
      console.log(e);
    }
  };
  const fetchCampaignMetric = async (days) => {
    setCampaignMetricLoading(true);
    const end_date = moment().format("YYYY MM DD").replaceAll(" ", "-");
    const start_date = moment()
      .subtract(days, "days")
      .format("YYYY MM DD")
      .replaceAll(" ", "-");
    try {
      const data = await onGetDashboardCampaignMetric(
        brand.id,
        start_date,
        end_date
      );

      if (data) {
        setCampaignMetricData(data);
        setCampaignMetricLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(t("dashboard_campaignMetricError"));
    }
  };
  const fetchLeadMetric = async (days) => {
    setLeadMetricLoading(true);
    const end_date = moment().format("YYYY MM DD").replaceAll(" ", "-");
    const start_date = moment()
      .subtract(days, "days")
      .format("YYYY MM DD")
      .replaceAll(" ", "-");
    try {
      const data = await onGetDashboardLeadMetric(
        brand.id,
        start_date,
        end_date
      );
      if (data) {
        setLeadMetricData(data.data);
        setLeadMetricLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(t("dashboard_leadMetricError"));
    }
  };

  const fetchEcommMetric = async (days) => {
    setLeadMetricLoading(true);
    const end_date = moment().format("YYYY MM DD").replaceAll(" ", "-");
    const start_date = moment()
      .subtract(days, "days")
      .format("YYYY MM DD")
      .replaceAll(" ", "-");
    try {
      const data = await PeasyApi.get(`api/v1/dashboard/ecomm/metric?brand_profile_id=${brand.id}&start_date=${start_date}&end_date=${end_date}`);
      if (data) {
        setEcommMetricData(data);
        setLeadMetricLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(t("dashboard_leadMetricError"));
    }
  };

  const getFunnelData = async () => {
    var category = brand.product_category;
    var distributionMethod = brand.distribution_method;
    const payload = {
      category: category,
      distribution_method: distributionMethod,
      brand_profile_id: currentBrandId,
    };
    try {
      const resp = await onGetFunnelData(payload);
      if (resp) {
        setDetails(resp);
      }
    } catch (e) {
      console.log(e);
      toast.error(t("dashboard_funnelDataError"));
    }
  };

  const getData = (name) => {
    return JSON.parse(localStorage.getItem("user_data"))[name] || "";
  };

  const verifyLimit = async () => {
    if (localStorage.getItem("user_data")) {
      const user = JSON.parse(localStorage.getItem("user_data"));
      const user_id = user.id;
      try {
        const resp = await onGetBrandLimit(user_id);

        if (resp && resp.status) {
          // this.setState({ redirect: true });
          history.push("/brand/create");
        } else {
          toast.error(t(resp.message));
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error(t("dashboard_Error"));
    }
  };

  const handleCampaignClick = async () => {
    try {
      const resp = await onFbValidateAssets({
        brand_profile_id: currentBrandId,
      });
      if (resp) {
        let result = resp.result;
        var array = Object.keys(result);
        for (let i = 0; i < array.length; i++) {
          var temp = array[i];
          if (
            (array[i] === "page_access" || array[i] === "ad_account") &&
            result[temp].status === false
          ) {
            setUrl(result[temp].callback_url);
            setMessage(result[temp].message);
            setButtonText(result[temp].callback_button_text);
            setTarget(result[temp].target);
            setOpen(true);
            setCheckLoading(false);
            return;
          }
        }
        history.push(`/campaign/create-new/${brand.encoded_id}`, result);
      }
    } catch (error) {
      toast.error(t("dashboard_somethingWrongWhilePerformingAction"));
      setCheckCampaignCreateLoading(false);
      setCheckLoading(false);
    }
  };

  const createBrandSection = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <div className="switch-brands mt-2">
              <Button
                className={classes.dashboardBtn}
                variant="outlined"
                color="primary"
                size="small"
                type="button"
                onClick={() => verifyLimit()}
              >
                +{t("dashboard_businessProfile")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const process = async () => {
    try {
      const data = await onGetDummyBrandProfile();
      if (data) {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
      toast.error(t("dashboard_somethingWrongWhilePerformingAction"));
    }
    setDummyProfileLoading(false);
    setDummyProfileDisabled(false);
  };

  const storeCurrentBrandId = async (currentBrandId) => {
    if (currentBrandId === null) {
      localStorage.removeItem('profile');
      return;
    }
    localStorage.setItem("profile", currentBrandId);
  };
  // console.log(ecommerceMetricData,"ecommerceMetricData");
  return (
    <div className="dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className="d-flex">
          <div className="dashboard-title">
            <Hidden smDown>
              <h2>Dashboard</h2>
            </Hidden>
          </div>
        </Grid>

        {brandProfileList.length > 0 && (
          <>
            <Grid item xs={12} sm={6}>
              <Button
                className={`${classes.dashboardBtn} ${classes.businessProfileBtn}`}
                variant="outlined"
                color="primary"
                // size="small"
                type="button"
                onClick={() => verifyLimit()}
              >
                + {t("dashboard_businessProfile")}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div className="switch-brands">
                <Hidden smDown>
                  <p className="mr-2">{t("dashboard_business_profile_text")}</p>
                </Hidden>
                {brandProfileList && currentBrandId && (
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} profile-select`}
                  >
                    <InputLabel htmlFor="profile">
                      {t("dashboard_profiles")}
                    </InputLabel>
                    <Select
                      labelId="profile"
                      value={currentBrandId}
                      onChange={(e) => {
                        setCurrentBrandId(e.target.value);
                        storeCurrentBrandId(e.target.value);
                      }}
                      label={t("dashboard_profiles")}
                    >
                      {brandProfileList.map((item) => (
                        <MenuItem
                          className="pl-2 dropdown-option"
                          value={item.id}
                          key={item.id}
                        >
                          {item.brand_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            </Grid>
          </>
        )}
      </Grid>
      {brandProfileList.length > 0 && (
        <div>
          <div className="mt-2 promotions">
            <div className="promotion-box">
              <Box
                className="box"
                style={{ minHeight: "80px", minWidth: "320px" }}
              >
                <div className="ad-spent-box">
                  <div className="icon">
                    <img src="/images/assets/dollar_img.svg" alt="" />
                  </div>

                  <div>
                    <Typography color="primary">
                      {t("dashboard_ad_spend")}
                    </Typography>
                  </div>

                  <div>
                    <Typography className="ad-spent" color="primary">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        {"  "}
                        {
                          JSON.parse(localStorage.getItem("user_data"))
                            .currency_type
                        }{" "}
                        {JSON.parse(localStorage.getItem("user_data"))
                          .account_type === "prepaid"
                          ? JSON.parse(
                              localStorage.getItem("user_data")
                            ).prepaid_balance.toFixed(2)
                          : ((localStorage.getItem("user_data") &&
                              JSON.parse(localStorage.getItem("user_data"))
                                .user_current_spend &&
                              JSON.parse(localStorage.getItem("user_data"))
                                .user_current_spend.total_spend_amount +
                                JSON.parse(localStorage.getItem("user_data"))
                                  .user_current_spend.total_planned_amount) ||
                              0) +
                            "/" +
                            Math.round(getData("user_ad_spend_limit"))}
                      </div>
                    </Typography>
                  </div>
                </div>
              </Box>
            </div>
            <div className="promotion-box">
              <Box
                className="box d-flex justify-content-center flex-column"
                style={{
                  backgroundColor: "#6F1DDF",
                  minHeight: "80px",
                  minWidth: "320px",
                }}
              >
                <div className="ad-spent-box">
                  <div className="icon">
                    <img src="/images/waving-hand.png" alt="" />
                  </div>
                  <div>
                    <div>
                      <Typography className="text-white">
                        {t("dashboard_AIKnowsYouWell")}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        className="dashboard-data-byte-text text-white"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        <span style={{ color: "#B4FF00" }}>
                          {brand.data_size ? brand.data_size : "00.0"}{" "}
                          {brand.data_size_unit}
                        </span>
                        {t("dashboard_dataCollectedAndTrained")}
                      </Typography>
                    </div>
                  </div>
                </div>
                {/* <p> className="text-white"</p>
                <h4 className="text-white">
                  <span style={{ color: "#B4FF00" }}>
                    {brand.data_size} {brand.data_size_unit}
                  </span>{" "}
                  of data collected and trained
                </h4> */}
              </Box>
            </div>
            {/* <div className="promotion-box">
            <Box
              className="box"
              style={{ minHeight: "80px", minWidth: "320px" }}
            ></Box>
          </div> */}
          </div>
        </div>
      )}

      {brandProfileList.length === 0 && isBrandProfileListEmpty && (
        <>
          <Box
            className="box mt-2"
            style={{ minWidth: "700px", margin: "auto" }}
          >
            <div className="text-center mt-4 mb-4">
              <img
                className="mb-2 dashboard-img"
                style={{ height: "50px", width: "50px" }}
                src="/images/dashboard-no-brand-profiles-icon.svg"
                alt=""
              />
              {/* <h4>{t("dashboard_noProfilesText")}</h4> */}
              <h4>{t("dashboard_NoProfilesCreatedYet")}</h4>
              <Typography className={`${classes.dummybrandSH} mt-2`}>
                {getData("account_type") == "postpaid" ??
                  t("dashboard_useDummyData")}
              </Typography>
              <div>
                {dummyProfileLoading ? (
                  <div className="mt-4">
                    <Spinner size="small" />
                  </div>
                ) : (
                  getData("account_type") == "postpaid" ?? (
                    <Button
                      className={classes.noProfileBtn}
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={() => {
                        setDummyProfileLoading(true);
                        setDummyProfileDisabled(true);
                        process();
                      }}
                      disabled={dummyProfileDisabled}
                    >
                      {t("dashboard_experienceNow")}
                    </Button>
                  )
                )}
              </div>
              <div>
                <Button
                  className={classes.noProfileBtn}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => verifyLimit()}
                >
                  +{t("dashboard_businessProfile")}
                </Button>
              </div>
            </div>
          </Box>
        </>
      )}

      {brandProfileList.length !== 0 &&
        Object.keys(brand).length > 0 &&
        currentBrandId && (
          <>
            <Grid container className="mt-2" spacing={2}>
              <Grid item xs={12} sm={12} md={5}>
                <Box
                  className="box"
                  style={{
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="">
                    <h4 className="dashboard-section-heading">
                      {t("dashboard_conversionFunnel")}
                    </h4>
                  </div>
                  <div
                    className="graph-div"
                    style={{
                      width: "100%",
                      overflow: "auto",
                      border: "none",
                    }}
                  >
                    {details.objective === "LEAD_GENERATION" ? (
                      <img
                        className="funnel-graph-img"
                        src="/images/assets/Funnelb.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className="funnel-graph-img"
                        src="/images/assets/Funnela.svg"
                        alt=""
                      />
                    )}
                    {details.flag === 1 ? (
                      <Graph
                        values={details.target}
                        type={details.objective}
                        object={"default"}
                      />
                    ) : (
                      <Graph
                        values={details.default}
                        type={details.objective}
                        object={"default"}
                      />
                    )}
                  </div>
                  <Grid container spacing={1} className="mt-1">
                    <Grid item md={6} xs={6}>
                      {brand.fb_promoted_id ? (
                        checkLoading ? (
                          <Button
                            className={classes.dashboardBtn}
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                          >
                            {t("dashboard_validating")}
                            {"  "}
                            <Spinner
                              style={{
                                width: "14px",
                                height: "14px",
                                marginLeft: "5px",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button
                            className={classes.dashboardBtn}
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setCheckLoading(true);
                              handleCampaignClick();
                            }}
                          >
                            <img
                              className="dashboard-img mr-1"
                              src="/images/dashboard-plus-icon.svg"
                              alt=""
                            />{" "}
                            {t("dashboard_campaign")}
                          </Button>
                        )
                      ) : (
                        <Button
                          className={classes.dashboardBtn}
                          fullWidth
                          size="small"
                          variant="outlined"
                          color="primary"
                        >
                          <img
                            className="dashboard-img mr-1"
                            src="/images/dashboard-plus-icon.svg"
                            alt=""
                          />{" "}
                          {t("dashboard_campaign")}
                        </Button>
                      )}
                    </Grid>
                    {/* <Grid item md={4} xs={6}>
                    <Button
                      className={classes.dashboardBtn}
                      fullWidth
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      {t("dashboard_campaignReport")}
                    </Button>
                  </Grid> */}
                    <Grid item md={6} xs={6}>
                      <Button
                        className={classes.dashboardBtn}
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          history.push(`/brand/update/${brand.id}`, brand);
                        }}
                      >
                        {/* {t("dashboard_funnelReport")} */}
                        <img
                          className="dashboard-img mr-1"
                          src="/images/dashboard-edit-icon.svg"
                          alt=""
                        />{" "}
                        {t("dashboard_editFunnel")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={7}>
                <Box
                  className="box"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {leadMetricLoading ? (
                    <>
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <Spinner />
                      </div>
                    </>
                  ) : details && details.objective === "LEAD_GENERATION" ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h4 className="dashboard-section-heading">
                            {t("dashboard_leads")}
                          </h4>
                          {/* <p style={{ color: "grey", fontSize: ".6rem" }}>
                      Last Updated: Today {new Date().toLocaleDateString()}
                    </p> */}
                        </div>
                        <FormControl
                          variant="outlined"
                          className={`$ profile-select`}
                        >
                          <InputLabel htmlFor="ecommerceMetric">
                            {t("dashboard_metric")}
                          </InputLabel>
                          <Select
                            labelId="ecommerceMetric"
                            value={leadDaysFilter}
                            onChange={(e) => setLeadDaysFilter(e.target.value)}
                            label={t("dashboard_metric")}
                          >
                            <option className="pl-2 dropdown-option" value={7}>
                              {t("dashboard_metric7days")}
                            </option>
                            <option className="pl-2 dropdown-option" value={15}>
                              {t("dashboard_metric15days")}
                            </option>
                            <option className="pl-2 dropdown-option" value={30}>
                              {t("dashboard_metric30days")}
                            </option>
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        className="stacked-bar-chart-parent mt-2 mb-2"
                        style={{ height: 270, overflow: "auto" }}
                      >
                        {leadMetricLoading ? (
                          <>
                            <div className="d-flex align-items-center justify-content-center h-100">
                              <Spinner />
                            </div>
                          </>
                        ) : (
                          <CohortChart data={leadMetricData} />
                        )}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Grid container spacing={1}>
                          <Hidden smDown>
                            <Grid item lg={3}></Grid>
                          </Hidden>
                          {/* <Grid item xs={6} lg={3} md={4}>
                            <Button
                              fullWidth
                              className={`${classes.dashboardBtn}`}
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                history.push(`/rmf/${currentBrandId}`);
                              }}
                            >
                              <img
                                className="dashboard-img mr-1"
                                src="/images/dashboard-rmf-report-icon.svg"
                                alt=""
                              />{" "}
                              {t("dashboard_rmfReport")}
                            </Button>
                          </Grid> */}
                          <Grid item xs={6} md={4}>
                            <Button
                              fullWidth
                              className={`${classes.dashboardBtn}`}
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                history.push(
                                  `/brand/leads/${currentBrandId}`,
                                  currentBrandId
                                );
                              }}
                            >
                              <img
                                className="dashboard-img mr-1"
                                src="/images/dashboard-contact-icon-2.svg"
                                alt=""
                              />{" "}
                              {t("dashboard_contacts")}
                            </Button>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Button
                              fullWidth
                              className={`${classes.dashboardBtn}`}
                              // className="ml-2"
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                history.push(
                                  `/brand/custom-audience/${currentBrandId}`
                                );
                              }}
                            >
                              <img
                                className="dashboard-img mr-1"
                                src="/images/dashboard-audience-icon.svg"
                                alt=""
                              />{" "}
                              {t("dashboard_customAudience")}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : leadMetricLoading ? (
                    <>
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <Spinner />
                      </div>
                    </>
                  ) : ecommerceMetricData && !isEcommLineChartDataEmpty ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h4 className="dashboard-section-heading">
                            {t("dashboard_ecommerceChartMetric")}
                          </h4>
                        </div>
                        <FormControl
                          variant="outlined"
                          className={`$ profile-select`}
                        >
                          <InputLabel htmlFor="ecommerceMetric">
                            {t("dashboard_metric")}
                          </InputLabel>
                          <Select
                            labelId="ecommerceMetric"
                            value={ecommDaysFilter}
                            onChange={(e) => setEcommDaysFilter(e.target.value)}
                            label={t("dashboard_metric")}
                          >
                            <option className="pl-2 dropdown-option" value={7}>
                              {t("dashboard_metric7days")}
                            </option>
                            <option className="pl-2 dropdown-option" value={15}>
                              {t("dashboard_metric15days")}
                            </option>
                            <option className="pl-2 dropdown-option" value={30}>
                              {t("dashboard_metric30days")}
                            </option>
                          </Select>
                        </FormControl>
                      </div>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ecommerceMetricData}
                      />
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={6}>
                          {
                            user.active_package?.options?.catalog === "true" && <Button
                              fullWidth
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                history.push(`/rmf/${currentBrandId}`);
                              }}
                            >
                              <img
                                className="dashboard-img mr-1"
                                src="/images/dashboard-rmf-report-icon.svg"
                                alt=""
                              />
                              {t("dashboard_rmfReport")}
                            </Button>
                          }
                          
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              history.push(
                                `/brand/custom-audience/${currentBrandId}`
                              );
                            }}
                          >
                            <img
                              className="dashboard-img mr-1"
                              src="/images/dashboard-audience-icon.svg"
                              alt=""
                            />{" "}
                            {t("dashboard_customAudience")}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h4 className="dashboard-section-heading">
                          {t("dashboard_woocommerce")} 
                        </h4>
                      </div>

                      <div>
                        <h3 style={{ textAlign: "center" }}>
                          {t("dashboard_woocommerce_connected")} 
                        </h3>
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                          >
                            Learn More
                          </Button>
                        </div>
                      </div>
                      <div>{""}</div>
                    </>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="box">
                  <div className="mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 className="dashboard-section-heading">
                          {t("dashboard_campaign")}
                        </h4>
                      </div>
                      <div>
                        <FormControl
                          variant="outlined"
                          className={`$ profile-select`}
                        >
                          <InputLabel htmlFor="campaignMetric">
                            {t("dashboard_metric")}
                          </InputLabel>
                          <Select
                            labelId="campaignMetric"
                            value={campaignDaysFilter}
                            onChange={(e) =>
                              setCampaignDaysFilter(e.target.value)
                            }
                            label={t("dashboard_metric")}
                          >
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={7}
                            >
                              {t("dashboard_metric7days")}
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={15}
                            >
                              {t("dashboard_metric15days")}
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={30}
                            >
                              {t("dashboard_metric30days")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <Grid container spacing={1}>
                    {Object.keys(campaignMetricData).length > 0 && (
                      <Grid item xs={12} className="">
                        <Hidden smDown>
                          <HorizontalScroll>
                            {campaignMetricData.chip_data &&
                              campaignMetricData.chip_data.map(
                                (chipData, i) => {
                                  return (
                                    <Box className="box chips" key={i}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="mb-1">
                                          <p
                                            style={{
                                              color: "#595959",
                                              spacing: 2,
                                              fontSize: "12px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {chipData.name.toUpperCase()}
                                          </p>
                                        </div>
                                        {chipData.rate_type === 0 ? (
                                          <>
                                            <div
                                              style={{
                                                color: "#4285F4",
                                                fontSize: "12px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              <>
                                                <TrendingFlat />
                                                <span>
                                                  {" "}
                                                  {Math.round(
                                                    chipData.rate * 100
                                                  ) / 100}
                                                  %
                                                </span>
                                              </>
                                            </div>
                                          </>
                                        ) : chipData.rate_type === -1 ? (
                                          <div
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            <>
                                              <TrendingDown />
                                              <span>
                                                {" "}
                                                -
                                                {Math.round(
                                                  chipData.rate * 100
                                                ) / 100}
                                                %
                                              </span>
                                            </>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              color: "#00c700",
                                              fontSize: "12px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            <>
                                              <TrendingUp />
                                              <span>
                                                {" "}
                                                {Math.round(
                                                  chipData.rate * 100
                                                ) / 100}
                                                %
                                              </span>
                                            </>
                                          </div>
                                        )}
                                      </div>
                                      <div className="d-flex justify-content-between align-items-between">
                                        <div>
                                          <p
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {chipData.count}
                                          </p>
                                        </div>
                                        {/* <div>
                                      <img
                                        style={{ width: "80px" }}
                                        src="/images/dummy-graph.svg"
                                        alt=""
                                      />
                                    </div> */}
                                      </div>
                                    </Box>
                                  );
                                }
                              )}
                          </HorizontalScroll>
                        </Hidden>
                        <Hidden smUp>
                          <div
                            className="d-flex"
                            style={{ overflow: "auto", flexWrap: "nowrap" }}
                          >
                            <CampaignChips
                              campaignMetricData={campaignMetricData}
                            />
                          </div>
                        </Hidden>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box className="box">
                        <div>
                          <h4 className="mb-2 mt-3">
                            {t("dashboard_campaignMetric")}
                          </h4>
                        </div>
                        {campaignMetricLoading ? (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              height: "70%",
                              minHeight: "150px",
                            }}
                          >
                            <Spinner />
                          </div>
                        ) : isLineChartDataEmpty ? (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              backgroundImage: `url("/images/dummy_campaign_image.png")`,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              minHeight: "160px",
                              height: "70%",
                            }}
                          >
                            <h4>{t("dashboard_noCampaignMetricText")}</h4>
                          </div>
                        ) : (
                          <>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={lineChartData}
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box
                        className="box"
                        style={{
                          height: "100%",
                          overflow: "hidden",
                          padding: "10px",
                        }}
                      >
                        {brand.id && (
                          <BrandOverview encodedId={brand.encoded_id} />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="box">
                  <div className="mb-4">
                    <div className="">
                      <div>
                        <h4 className="dashboard-section-heading">
                          {t("dashboard_articles")}
                        </h4>
                      </div>
                    </div>
                  </div>

                  <RSS />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      <CheckDialog
        //prashant
        // this is a check dialog for checking account validity and all other checks
        open={open}
        setOpen={setOpen}
        url={url}
        message={message}
        srcImg="/images/assets/Failure.svg"
        target={target}
        buttonText={buttonText}
      />
    </div>
  );
}
