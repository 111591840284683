import React from "react";
import "./HeaderOption.css";

const HeaderOption = ({ iconClass, text, onClick, isActive }) => (
  <div
    style={isActive ? { borderBottom: "3px solid #8D02FA" } : {}}
    className="headeroption__container"
    onClick={() => onClick()}
  >
    <i className={iconClass} />
    <span className="headeroption__text">{text}</span>
  </div>
);

export default HeaderOption;
