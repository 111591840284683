import React from "react";
import { Redirect } from "react-router";
import BrandProfileList from "../brandProfileList/BrandProfileList";
import EmptyBrandProfilesBox from "../emptyBrandProfilesBox/EmptyBrandProfilesBox";
import PeasyApi from "../../../utils/peasy.api";
import "./BrandOverviewLeftSection.css";
import { toast } from "react-toastify";
import { Button, Grid } from "@material-ui/core";
import { onGetBrandLimit } from "../../../utils/network/lib/brand";

class BrandOverviewLeftSection extends React.Component {
  constructor(props) {
    super(props);

    this.setIsListEmptyTrue = this.setIsListEmptyTrue.bind(this);
    this.verifyLimit = this.verifyLimit.bind(this);
    this.state = {
      isListEmpty: false,
      redirect: false,
    };
  }
  // history = useHistory();
  setIsListEmptyTrue() {
    !this.state.isListEmpty && this.setState({ isListEmpty: true });
  }

  verifyLimit = async () => {
    if (localStorage.getItem("user_data")) {
      const user = JSON.parse(localStorage.getItem("user_data"));
      const user_id = user.id;
      try {
        const resp = await onGetBrandLimit(user_id);
        if (resp.status) {
          this.setState({ redirect: true });
        } else {
          toast.error(resp.message);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error("Opps! Something went wrong");
    }
  };

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="brand/create" />;
    }
    const { isListEmpty } = this.state;
    return (
      <div className="brandoverviewleftsection__container">
        <div style={{ marginTop: "7vh" }}>
          <Grid container>
            <Grid item xs={6}>
              <span className="brandoverviewleftsection__heading">
                Brand Overview
              </span>
            </Grid>
            <Grid item xs={6}>
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  type="button"
                  onClick={() => this.verifyLimit()}
                >
                  + BUSINESS PROFILE
                </Button>
              </span>
            </Grid>
          </Grid>
        </div>
        {isListEmpty ? (
          <EmptyBrandProfilesBox />
        ) : (
          <BrandProfileList setIsListEmptyTrue={this.setIsListEmptyTrue} />
        )}
      </div>
    );
  }
}
export default BrandOverviewLeftSection;
