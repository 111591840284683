import { CANVA_BASE } from "../../canva";

export function getPendingPosts(campaign_id) {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return fetch(
    CANVA_BASE + "/get-pending?&camp=" + campaign_id,
    requestOptions
  );
}

export function deletePendingPost(campaign_id, name) {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };
  return fetch(
    CANVA_BASE + `/delete-pending?camp=${campaign_id}&fileName=${name}`,
    requestOptions
  );
}
