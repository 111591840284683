import React, { PureComponent } from "react";
import "./SideBar.css";

import { userDetails } from "./Axios/ApiRequests";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { Link } from "react-router-dom";

/**
 * Screens
 */

class SideBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeScreen: "user_profile",
      packages: [],
      user_details: [],
    };
    this.userDetails();
  }

  async userDetails() {
    const user_details = await userDetails();
    this.setState({ user_details: user_details });
  }

  
  render() {
    
    return (
      <div className="screen-mainbox">
        <div id="switch_screen">
          <div className="Sidebar-MainWrapper">
            <NotificationContainer />
            <div className="sidebar">
              <ul>
                <li
                  className={`list-style-none ${
                    this.props.active === "userProfile" ? "active" : ""
                  }`}
                >
                  <Link to="/session/auth/user-profile">User Profile </Link>{" "}
                </li>
                <li
                  className={`list-style-none ${
                    this.props.active === "connection" ? "active" : ""
                  }`}
                >
                  <Link to="/session/auth/connection">Connection Setup</Link>{" "}
                </li>
                <li
                  className={`list-style-none ${
                    this.props.active === "usersTeams" ? "active" : ""
                  }`}
                >
                  <Link to="/session/auth/users-teams">Users & Team</Link>{" "}
                </li>

                <li
                  className={`list-style-none ${
                    this.props.active === "billing" ? "active" : ""
                  }`}
                >
                  <Link to="/session/auth/billing">Billing</Link>{" "}
                </li>

                {JSON.parse(localStorage.getItem("user_data")).id === 513 && (
                  <li
                    className={`list-style-none ${
                      this.props.active === "integration" ? "active" : ""
                    }`}
                  >
                    <Link to="integration">Integration</Link>
                  </li>
                )}

                <li
                  className={`list-style-none ${
                    this.props.active === "apiKey" ? "active" : ""
                  }`}
                >
                  <Link to="/session/auth/api-key">API Key</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="Right-Wrapper">
              {this.props.children}
              {/* {activeScreen == "user_profile" && <UserProfile />}
              {activeScreen == "connetion_setup" && <ConnectionSetup />}
              {activeScreen == "billing" && (
                <Billing
                  user_details={user_details}
                  refreshUser={() => this.userDetails()}
                />
              )}
              {activeScreen == "user_team" && (
                <UserTeam
                  user_details={user_details}
                  refreshUser={() => this.userDetails()}
                />
              )}
              {activeScreen == "integration" && <Integration />} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SideBar;
