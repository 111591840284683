import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Graph from "./Graph";
import { makeStyles } from "@material-ui/core/styles";
import { Spinner } from "reactstrap";
import Player from "./Player";
import TagManager from "react-gtm-module";
import PeasyApi from "../../../utils/peasy.api";
import { toast } from "react-toastify";
import { CheckCircleOutlineRounded, QuestionAnswer } from "@material-ui/icons";
import CheckDialog from "../../CheckDialog";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import { onUpdateFunnelData } from "../../../utils/network/lib/funnel";
import { EVENT_TYPE_EXTERNAL } from "../../../utils/eventConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const args = {
  dataLayer: {
    event_custom: "brand_profile_create_final_step",
    /* can pass more variables here if needed */
  },
};

const CreateBrandLastStep = ({
  createBrand,
  isUpdate,
  goToStep,
  brandCreateLoading,
  getFunnelData,
  category,
  distributionMethod,
  fb_promoted_id,
}) => {
  TagManager.dataLayer(args);

  const { t } = useTranslation();

  const classes = useStyles();

  const [details, setDetails] = useState({});
  const [tempDetails, setTempDetails] = useState({});
  const [view, setView] = useState(true);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(true);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [redirectBrandProfile, setRedirectBrandProfile] = useState(null);

  useEffect(() => {
    loadFunnelData();
  }, []);

  const loadFunnelData = async () => {
    var resp = await getFunnelData();
    if (resp) {
      setDetails(resp);
      setTempDetails(resp);
      setType(resp.objective);
      setLoading(false);
      setFlag(resp.flag);
      if (resp.flag) {
        setView(false);
      }
    }
  };

  const handleSubmit = async () => {
    if (tempDetails.default.reach > 100) {
      toast.error(t("brand_reachNotGreater"));
    } else if (tempDetails.default.qualified > 100) {
      toast.error(t("brand_qualifiedNotGreater"));
    } else if (tempDetails.default.sales > 100) {
      toast.error(t("brand_salesNotGreater"));
    } else if (
      tempDetails.default.reach < parseInt(tempDetails.default.qualified)
    ) {
      toast.error(t("brand_QualNotGrtrThanRch"));
    } else if (
      tempDetails.default.qualified < parseInt(tempDetails.default.sales)
    ) {
      toast.error(t("brand_salesNotGrtrThanQual"));
    } else {
      try {
        const payload = {
          detail: tempDetails.default,
          category: category,
          distribution_method: distributionMethod,
          user_funnel_id: tempDetails.user_funnel_id,
        };
        const submit = await onUpdateFunnelData(payload);
        setView(false);
        loadFunnelData();
      } catch (e) {
        toast.error(t("brand_error"));
      }
    }
  };

  const handleCreateBrand = async () => {
    var resp = await createBrand();
    if (resp && resp.status) {
      // console.log(resp);
      setRedirectBrandProfile(resp.res.data.id);
      setOpen(true);
    } else {
      // console.log("error", resp.status);
      window.$peasy.triggerEvent({
        event_name: "error",
        event_params: {
          type: EVENT_TYPE_EXTERNAL,
          id: "2",
          error_msg: JSON.stringify(resp),
        },
      });
    }
    setDisabled(false);
  };

  const SuccessPopup = ({ open, setOpen }) => {
    const history = useHistory();
    return (
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            <img
              style={{ width: "100%" }}
              src="/images/assets/success.svg"
              alt=""
            />
            <Typography
              className="mt-2 "
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              {" "}
              {t("brand_aiTypo")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" align="justify">
              {" "}
              {t("brand_alertDialog")}
            </DialogContentText>
            <button
              className="brandoverviewleftsection__btn--purple"
              color="primary"
              style={{
                display: "block",
                margin: "auto",
                width: "100%",
                marginBottom: "10px",
              }}
              onClick={() => {
                setOpen(false);
                history.push("/dashboard", { id: redirectBrandProfile });
              }}
            >
              {" "}
              {t("brand_continue")}
            </button>
          </DialogContent>
          <DialogActions justifyContent="center"></DialogActions>
        </Dialog>
      </div>
    );
  };

  if (loading)
    return (
      <div style={{ textAlign: "center", paddingTop: "50px", width: "100%" }}>
        <Spinner style={{ width: "100px", height: "100px", color: "grey" }} />
      </div>
    );
  return (
    <div className="col-12 active create-brand-last-step-box" id="Step7">
      <div className="row">
        <div className="col-md-12">
          <SuccessPopup open={open} setOpen={setOpen} />

          <div className="mb-3">
            <Box>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                    overflow: "hidden",
                  }}
                >
                  <video controls style={{ borderRadius: "5px" }}>
                    <source
                      src="/videos/FunnelAnalysis.mp4"
                      type="video/mp4"
                    ></source>
                    <source src="movie.ogg" type="video/ogg"></source>
                  </video>
                </div>
              </div>
            </Box>
          </div>
          <p className="text-center mb-3">
            {t("brand_articleHeading")}
            <a
              target="_blank"
              href="https://peasy.ai/understanding-your-funnel/"
              style={{
                cursor: "pointer",
                color: "#6f1ddf",
              }}
            >
              {t("brand_here")}
            </a>
          </p>
          <div className="last-step-heading text-center mb-4 mt-4 ">
            {" "}
            {t("brand_heading")}
          </div>
          <Box className={(classes.root, "Graph")}>
            <Grid container justify="center" spacing={4}>
              {!flag ? (
                <>
                  <Grid
                    item
                    md={12}
                    lg={6}
                    style={{
                      width: "100%",
                      marginBottom: "15px",
                    }}
                  >
                    <Typography variant="h6" align="center">
                      {" "}
                      {t("brand_dataHeading")}
                    </Typography>
                    <div
                      className="graph-div"
                      style={{ width: "100%", overflow: "auto" }}
                    >
                      {type === "LEAD_GENERATION" ? (
                        <img
                          // style={{ width: "500px" }}
                          className="funnel-graph-img"
                          src="/images/assets/Funnelb.svg"
                          alt=""
                        />
                      ) : (
                        <img
                          // style={{ width: "500px" }}
                          className="funnel-graph-img"
                          src="/images/assets/Funnela.svg"
                          alt=""
                        />
                      )}
                      <Graph
                        values={details.default}
                        type={details.objective}
                        object={"default"}
                      />
                    </div>
                  </Grid>
                </>
              ) : (
                <></>
              )}

              <Grid
                item
                md={12}
                lg={6}
                style={{
                  width: "100%",
                  marginBottom: "15px",
                  // overflow: "auto",
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  className="graph-heading"
                >
                  {t("brand_benchmark")} {category}
                </Typography>
                <div
                  className="graph-div"
                  style={{ width: "100%", overflow: "auto" }}
                >
                  {type === "LEAD_GENERATION" ? (
                    <img
                      // style={{ width: "500px" }}
                      className="funnel-graph-img"
                      src="/images/assets/Funnelb.svg"
                      alt=""
                    />
                  ) : (
                    <img
                      // style={{ width: "500px" }}
                      className="funnel-graph-img"
                      src="/images/assets/Funnela.svg"
                      alt=""
                    />
                  )}

                  <Graph
                    values={details.target}
                    type={details.objective}
                    object={"target"}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
          {/* Dialog */}
          {type === "CONVERSIONS" && !flag ? (
            <></>
          ) : (
            <Dialog
              open={view}
              aria-labelledby="form-dialog-title"
              maxWidth="xs"
            >
              <div className="popup-icon">
                <QuestionAnswer />
              </div>
              <DialogTitle id="form-dialog-title" align="center">
                {" "}
                {t("brand_formTitle")}
              </DialogTitle>
              <div style={{ textAlign: "center", margin: "0px 20px" }}>
                {" "}
                {t("brand_averageTitle")}
              </div>
              <DialogContent>
                <TextField
                  required
                  className="mb-2"
                  margin="dense"
                  id="outlined-basic"
                  label={t("brand_reach")}
                  type="number"
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                  helperText={t("brand_popupHelperTextReach")}
                  value={tempDetails.default.reach}
                  onChange={(e) => {
                    setTempDetails({
                      ...tempDetails,
                      default: {
                        ...tempDetails.default,
                        reach: e.target.value,
                      },
                    });
                  }}
                />
                <TextField
                  required
                  className="mb-2"
                  margin="dense"
                  id="outlined-basic"
                  label={t("brand_qualified")}
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  helperText={t("brand_popupHelperTextQualified")}
                  value={tempDetails.default.qualified}
                  onChange={(e) =>
                    setTempDetails({
                      ...tempDetails,
                      default: {
                        ...tempDetails.default,
                        qualified: e.target.value,
                      },
                    })
                  }
                />

                <TextField
                  required
                  className="mb-2"
                  margin="dense"
                  id="outlined-basic"
                  label={t("brand_sales")}
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  value={tempDetails.default.sales}
                  helperText={t("brand_popupHelperTextSales")}
                  onChange={(e) =>
                    setTempDetails({
                      ...tempDetails,
                      default: {
                        ...tempDetails.default,
                        sales: e.target.value,
                      },
                    })
                  }
                  fullWidth
                />
                <button
                  className="brandoverviewleftsection__btn--purple"
                  onClick={() => {
                    handleSubmit();
                  }}
                  color="primary"
                  style={{
                    display: "block",
                    margin: "auto",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  {t("brand_update")}
                </button>
                <button
                  className="brandprofilecard__btn--white"
                  onClick={() => setView(false)}
                  color="primary"
                  style={{
                    display: "block",
                    margin: "auto",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  {" "}
                  {t("brand_cancel")}
                </button>
              </DialogContent>

              <DialogActions style={{ display: "block" }}></DialogActions>
            </Dialog>
          )}
          <ul className="text-center list-inline list-unstyled mt-5 mb-4 create-brand-last-step-navigation">
            <li className=" list-inline-item">
              {!flag && details.objective === "LEAD_GENERATION" ? (
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setView(true)}
                >
                  {" "}
                  {t("brand_salesDataHeadng")}
                </button>
              ) : (
                <></>
              )}
            </li>
            <li className=" list-inline-item">
              <button
                onClick={() => {
                  goToStep(1);
                  document.getElementById("progressbar").style.width = "10%";
                }}
                className="btn btn-outline-primary"
              >
                {" "}
                {t("brand_backToBrand")}
              </button>
            </li>
            <li
              className="list-inline-item create-brand-button"
              // onClick={this.createBrand}
            >
              <button
                disabled={brandCreateLoading || disabled}
                onClick={() => {
                  setDisabled(true);
                  handleCreateBrand();
                }}
                className="btn btn-primary"
              >
                {brandCreateLoading || disabled ? (
                  <>
                    <Spinner
                      className="ml-5 mr-5"
                      style={{ width: "14px", height: "14px" }}
                    />
                  </>
                ) : isUpdate ? (
                  t("brand_updateText")
                ) : (
                  t("brand_createText")
                )}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CreateBrandLastStep;
