import React, { Component } from "react";
import moment from "moment";
import { Redirect, withRouter } from "react-router-dom";
import Objectives from "./steps/objectives.jsx";
import PixelEvent from "./steps/pixel_event.jsx";
import CampaignName from "./steps/CampaignName.jsx";
import TargetCampaign from "./steps/TargetCampaign.jsx";
import SpendCampaign from "./steps/SpendCampaign.jsx";
import CampaignEndDate from "./steps/CampaignEndDate.jsx";
import CampaignLocation from "./steps/CampaignLocation.jsx";
import LeadForm from "./steps/LeadForm.jsx";
import ExisingCampaign from "./steps/ExisingCampaign.jsx";
import PreparePredictionCampaign from "./steps/PreparePredictionCampaign.jsx";
import ShowPredictionCampaign from "./steps/ShowPredictionCampaign.jsx";
import FbMessenger from "./steps/FbMessenger.jsx";
import http from "../../shared/http-request";
import SelectFunnelType from "./SelectFunnelType";
import Method from "./steps/Method";
import MethodKey from "./steps/MethodKey";
import TargetCategory from "./steps/TargetCategory";

import { toast } from "react-toastify";
import Axios from "axios";
import {
  Objectives_Array,
  Objectives_Display_Array,
  BASE_URL,
  Objective_Step_Array,
} from "../../StaticArray/StaticArray";
import FormFieldMapping from "./steps/FormFieldMapping.jsx";
import { Spinner } from "reactstrap";

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step_next: 1,
      objective: "",
      pixel_event: "",
      pixel_event_list: null,
      pixel_event_text: "",
      target_campaign: "",
      campaign_amount: "",
      campaign_sync: "0",
      selected_campaigns: [],
      addClass: "",
      api_token: localStorage.getItem("token_auth"),
      brand_id: null,
      brandId_decode: null,
      start_date: moment(),
      end_date: moment(),
      campaign_id: "",
      BASE_URL: BASE_URL,
      event_type: "",
      campaign_name: "",
      locationSelectd: "",
      existing_campaigns: [],
      platform: "WEBSITE",
      currency: "",
      currencyOffset: "",
      minAmount: 0,
      secondMinAmount: 0,
      form_id: "",
      lead_form_list: null,
      lead_form_text: "",
      page_id: "",
      account_id: "",
      funnel_type: 0,
      method: 0,
      method_key: null,
      target_category: 0,
      adwordFallbackAccount: 1,
      facebookFallbackAccount: 1,
      loading: true,
      productCategory: "",
      distributionMethod: "",
      skipLeadForm: false,
      obj: "",
      tosData: {},
      brandFunnelType: "",
    };
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goForward = this.goForward.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setExistingCampaigns = this.setExistingCampaigns.bind(this);
    this.RefreshToken = this.RefreshToken.bind(this);
    this.CheckObjective = this.CheckObjective.bind(this);
    this.getDisplayObjective = this.getDisplayObjective.bind(this);
    // this.getLeadFormsList = this.getLeadFormsList.bind(this);
  }

  componentWillMount() {
    // here data from the brandprofile page is coming regarding the tos
    // we will set the state data for upcoming tos data
    const tosData = this.props.location.state;
    this.setState({ tosData: tosData });

    const decodeId = window.location.href.split("/").pop();
    http
      .get(`${BASE_URL}api/campaign-create-meta-data`, {
        params: {
          decodeId,
        },
      })
      .then((res) => {
        if (res.data.status) {
          this.setState({
            pixel_event_list: res.data.data.fbevent,
            brand_id: res.data.data.brand_id,
            brandId_decode: decodeId,
            currencyOffset: res.data.data.offset,
            currency: res.data.data.currency,
            minAmount: res.data.data.minAmount,
            secondMinAmount: res.data.data.secondMinAmount,
            page_id: res.data.data.page_id,
            account_id: res.data.data.account_id,
            adwordFallbackAccount: res.data.data.adwordFallbackAccount,
            facebookFallbackAccount: res.data.data.facebookFallbackAccount,
            productCategory: res.data.data.exist.product_category,
            distributionMethod: res.data.data.exist.distribution_method,
            loading: false,
          });
          //this.getLeadFormsList();
        } else if (!res.status) {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(() => {
            this.props.history.push("/dashboard");
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          this.props.history.push("/dashboard");
        }, 1000);
      });
  }

  CheckObjective() {
    const { objective } = this.state;
    return Objectives_Array[objective];
  }

  getDisplayObjective() {
    const { objective } = this.state;
    let objectiveName = "";

    switch (objective) {
      case 1:
        objectiveName = "IMPRESSION";
        break;
      case 2:
        objectiveName = "CLICK";
        break;
      case 3:
        objectiveName = "TRAFFIC";
        break;
      case 4:
        objectiveName = "CONVERSION";
        break;
      case 5:
        objectiveName = "ENGAGEMENT";
        break;
      case 6:
        objectiveName = "LEAD";
        break;
      default:
        objectiveName = "";
    }

    return objectiveName;
  }

  nextStep(skipNext = 0) {
    this.props.handleQuestionNumber(this.props.questionNumber + 1);
    var currStep = this.state.step_next;
    const { objective } = this.state;
    const nextStepIndex = Objective_Step_Array[objective].indexOf(currStep) + 1;
    const nextStep = Objective_Step_Array[objective][nextStepIndex];
    this.setState(
      {
        step_next: nextStep,
      },
      () => this.props.UpdateProgressBar(nextStep, "next")
    );
  }

  prevStep(skipPrev = 0) {
    this.props.handleQuestionNumber(this.props.questionNumber - 1);
    var currStep = this.state.step_next;
    const { objective } = this.state;

    const prevStepIndex = Objective_Step_Array[objective].indexOf(currStep) - 1;
    const prevStep = Objective_Step_Array[objective][prevStepIndex];
    this.setState(
      {
        step_next: prevStep,
      },
      () => this.props.UpdateProgressBar(prevStep, "next")
    );
  }

  //Prashant Bansal
  //Here I have made seperate functions for going forward and backward
  //in case of the full funnel

  goForward() {
    this.props.handleQuestionNumber(this.props.questionNumber + 1);
    this.setState({ step_next: this.state.step_next + 1 });
    this.props.fullFunnelProgress("next");
  }
  goBack() {
    if (this.state.skipLeadForm && this.state.step_next === 5) {
      this.setState({ step_next: this.state.step_next - 3 });
    } else {
      this.setState({ step_next: this.state.step_next - 1 });
    }

    this.props.fullFunnelProgress("");
    this.props.handleQuestionNumber(this.props.questionNumber - 1);
  }

  //To save Existing campaign option6

  setExistingCampaigns(campaign_id, account_id) {
    var { selected_campaigns } = this.state;

    var new_array = [];

    //check if ID is available in array then remove from array and it in
    if (campaign_id in selected_campaigns) {
      for (var key in selected_campaigns) {
        if (key != campaign_id) {
          new_array[key] = selected_campaigns[key];
        }
      }
    } else {
      for (var key in selected_campaigns) {
        new_array[key] = selected_campaigns[key];
      }
      new_array[campaign_id] = account_id;
    }

    this.setState({
      selected_campaigns: new_array,
    });
  }

  handleChange(input, value) {
    this.setState({ [input]: value });
    setTimeout(() => {
      console.log(this.state.method);
    }, 2000);
  }

  RefreshToken() {
    var token = this.state.api_token;
    //HIt APi to refresh-token

    let config = {
      headers: { Authorization: "Bearer " + token },
    };

    let bodyParameters = {
      token: token,
    };

    Axios.post(
      this.state.BASE_URL + "api/refresh-token",
      bodyParameters,
      config
    )
      .then((response) => {
        if (response.data.flag == 1) {
          localStorage.setItem("token_auth", response.data.token);
          this.setState({
            api_token: response.data.token,
          });
        } else {
          // toast.error("Token Error !",{
          //     position: toast.POSITION.BOTTOM_LEFT
          // });
          this.RefreshToken();
        }
      })

      .catch((err) => console.log(err));
  }

  render() {
    // this will redirect to brand profile page in case of direct approach to campaign create route
    // because tos data would be absent in that case
    if (!this.state.tosData) {
      return (
        <>
          <Redirect to="/dashboard" />
        </>
      );
    }

    const { step_next } = this.state;

    const {
      pixel_event_list,
      brandId_decode,
      locationSelectd,
      campaign_name,
      event_type,
      campaign_id,
      start_date,
      end_date,
      brand_id,
      objective,
      pixel_event,
      pixel_event_text,
      target_campaign,
      campaign_amount,
      api_token,
      campaign_sync,
      selected_campaigns,
      existing_campaigns,
      platform,
      currency,
      currencyOffset,
      minAmount,
      secondMinAmount,
      form_id,
      lead_form_list,
      lead_form_text,
      account_id,
      page_id,
      funnel_type,
      method,
      method_key,
      target_category,
      adwordFallbackAccount,
      facebookFallbackAccount,
      loading,
      productCategory,
      distributionMethod,
      skipLeadForm,
    } = this.state;
    const values = {
      pixel_event_list,
      brandId_decode,
      locationSelectd,
      campaign_name,
      event_type,
      campaign_id,
      start_date,
      end_date,
      brand_id,
      step_next,
      objective,
      pixel_event,
      pixel_event_text,
      target_campaign,
      campaign_amount,
      api_token,
      campaign_sync,
      selected_campaigns,
      existing_campaigns,
      platform,
      currency,
      currencyOffset,
      minAmount,
      secondMinAmount,
      form_id,
      lead_form_list,
      lead_form_text,
      account_id,
      page_id,
      funnel_type,
      method,
      method_key,
      target_category,
      adwordFallbackAccount,
      facebookFallbackAccount,
      loading,
      productCategory,
      distributionMethod,
      skipLeadForm,
    };
    if (this.state.funnel_type === 0) {
      if (loading) {
        return (
          <div className="loader" style={{ height: "50vh" }}>
            <Spinner style={{ width: "4rem", height: "4rem" }} />
          </div>
        );
      }
      return (
        // <Method
        //   amount={this.state.campaign_amount}
        //   target={this.state.target_campaign}
        //   goForward={this.goForward}
        //   goBack={this.goBack}
        //   value={this.state.method}
        //   handleChange={this.handleChange}
        //   funnel_type={this.state.funnel_type}
        //   getDisplayObjective={this.getDisplayObjective}
        //   state={values}
        // />
        <SelectFunnelType
          value={this.state.funnel_type}
          handleChange={this.handleChange}
          productCategory={productCategory}
          distributionMethod={distributionMethod}
          handleQuestionNumber={this.props.handleQuestionNumber}
          tosData={this.state.tosData}
        />
      );
    } else if (this.state.funnel_type === 1) {
      // switch (step_next) {
      //   case 1:
      //     return (
      //       <Objectives
      //         nextStep={this.nextStep}
      //         RefreshToken={this.RefreshToken}
      //         handleChange={this.handleChange}
      //         setExistingCampaigns={this.setExistingCampaigns}
      //         state={values}
      //         BASE_URL={BASE_URL}
      //         step_next={this.state.step_next}
      //       />

      switch (step_next) {
        case 1:
          return (
            <Objectives
              nextStep={this.nextStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              state={values}
              BASE_URL={BASE_URL}
              step_next={this.state.step_next}
              facebookFallbackAccount={this.state.facebookFallbackAccount}
              adwordFallbackAccount={this.state.adwordFallbackAccount}
              handleQuestionNumber={this.props.handleQuestionNumber}
              brandFunnelType={this.state.brandFunnelType}
              tosData={this.state.tosData}
            />
          );

        case 2:
          return (
            <CampaignName
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
            />
          );

        case 3:
          return (
            <FbMessenger
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              BASE_URL={BASE_URL}
              handleChange={this.handleChange}
              values={values}
              objective={this.state.objective}
            />
          );
        case 4:
          return (
            <PixelEvent
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              state={values}
              BASE_URL={BASE_URL}
            />
          );

        case 5:
          return (
            <CampaignEndDate
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
            />
          );

        case 6:
          return (
            <SpendCampaign
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              state={values}
              BASE_URL={BASE_URL}
            />
          );
        case 7:
          return (
            <TargetCampaign
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              objective={this.objective}
              CheckObjective={this.CheckObjective}
              getDisplayObjective={this.getDisplayObjective}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              state={values}
              BASE_URL={BASE_URL}
            />
          );

        case 8:
          return (
            <CampaignLocation
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
              objective={objective}
              CheckObjective={this.CheckObjective}
            />
          );

        case 9:
          return (
            <LeadForm
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              setExistingCampaigns={this.setExistingCampaigns}
              handleChange={this.handleChange}
              CheckObjective={this.CheckObjective}
              state={values}
              BASE_URL={BASE_URL}
            />
          );
        case 10:
          return (
            <ExisingCampaign
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              CheckObjective={this.CheckObjective}
              values={values}
              BASE_URL={BASE_URL}
            />
          );
        case 11:
          return (
            <PreparePredictionCampaign
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              CheckObjective={this.CheckObjective}
              getDisplayObjective={this.getDisplayObjective}
              BASE_URL={BASE_URL}
              locationSelectd={locationSelectd}
            />
          );
        case 12:
          return (
            <ShowPredictionCampaign
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
            />
          );

        case 13:
          return (
            <FormFieldMapping
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
            />
          );
        case 14:
          return (
            <TargetCategory
              goForward={this.nextStep}
              goBack={this.prevStep}
              value={this.state.target_category}
              handleChange={this.handleChange}
              funnel_type={this.state.funnel_type}
            />
          );

        default:
          return (
            <Objectives
              nextStep={this.nextStep}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              state={values}
              BASE_URL={BASE_URL}
              step_next={this.state.step_next}
              brandFunnelType={this.state.brandFunnelType}
              tosData={this.state.tosData}
            />
          );
      }
    } else if (this.state.funnel_type === 2) {
      switch (step_next) {
        case 1:
          return (
            <CampaignName
              goForward={this.goForward}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
              funnel_type={this.state.funnel_type}
              handleQuestionNumber={this.props.handleQuestionNumber}
            />
          );
        case 2:
          return (
            <CampaignEndDate
              goForward={this.goForward}
              goBack={this.goBack}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
              funnel_type={this.state.funnel_type}
            />
          );

        case 3:
          if (skipLeadForm) {
            this.handleChange("step_next", step_next + 2);
          } else
            return (
              <LeadForm
                nextStep={this.goForward}
                prevStep={this.goBack}
                RefreshToken={this.RefreshToken}
                setExistingCampaigns={this.setExistingCampaigns}
                handleChange={this.handleChange}
                CheckObjective={this.CheckObjective}
                state={values}
                BASE_URL={BASE_URL}
              />
            );
        case 4:
          return (
            <FormFieldMapping
              nextStep={this.goForward}
              prevStep={this.goBack}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
              step_next={this.state.step_next}
              skipLeadForm={skipLeadForm}
            />
          );
        case 5:
          return (
            <Method
              amount={this.state.campaign_amount}
              target={this.state.target_campaign}
              goForward={this.goForward}
              goBack={this.goBack}
              value={this.state.method}
              handleChange={this.handleChange}
              funnel_type={this.state.funnel_type}
              getDisplayObjective={this.getDisplayObjective}
              state={values}
              obj={this.state.obj}
            />
          );

        case 6:
          return (
            <TargetCategory
              goForward={this.goForward}
              goBack={this.goBack}
              value={this.state.target_category}
              handleChange={this.handleChange}
              funnel_type={this.state.funnel_type}
            />
          );

        case 7:
          return (
            <CampaignLocation
              goForward={this.goForward}
              goBack={this.goBack}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
              objective={objective}
              CheckObjective={this.CheckObjective}
              funnel_type={this.state.funnel_type}
            />
          );
        default:
          return (
            <CampaignName
              goForward={this.goForward}
              RefreshToken={this.RefreshToken}
              handleChange={this.handleChange}
              setExistingCampaigns={this.setExistingCampaigns}
              values={values}
              BASE_URL={BASE_URL}
              funnel_type={this.state.funnel_type}
            />
          );
      }
    }
  }
}

export default withRouter(Create);
