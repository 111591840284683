import React, { useState } from "react";
import { BASE_URL } from "../../StaticArray/StaticArray";
import PrepaidSettings from "./prepaidSettings/PrepaidSettings";
import Features from "./Features/Features";
import HocTest from "./HocTest/HocTest";

const AdminSidebar = (props) => {
  console.log("Admin sidebar", props);

  const [activeScreen, setActiveScreen] = useState("prepaid");
  return (
    <div className="screen-mainbox">
      <div id="switch_screen">
        <div className="Sidebar-MainWrapper">
          <div className="sidebar">
            <ul>
              <li
                className={`list-style-none  ${
                  activeScreen === "prepaid" ? "active" : ""
                }`}
                onClick={() => setActiveScreen("prepaid")}
              >
                <a href="javascript:void()">Prepaid Settings</a>
              </li>
              <li
                className={`list-style-none  ${
                  activeScreen == "users" ? "active" : ""
                }`}
                onClick={() => setActiveScreen("users")}
              >
                <a href={`${BASE_URL}admin`}>Users</a>
              </li>
              <li
                className={`list-style-none  ${
                  activeScreen === "features" ? "active" : ""
                }`}
                onClick={() => setActiveScreen("features")}
              >
                <a href="javascript:void()">Packages</a>
              </li>
              {/* <li
              className={`list-style-none  ${
                activeScreen === "test" ? "active" : ""
              }`}
              onClick={() => setActiveScreen("test")}
            >
              <a href="javascript:void()">TEST HOC</a>
            </li> */}
            </ul>
          </div>
          <div className="Right-Wrapper">
            {activeScreen === "prepaid" && <PrepaidSettings />}
            {activeScreen === "features" && <Features />}
            {activeScreen === "test" && <HocTest />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
