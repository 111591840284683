import React, { useState, useEffect } from "react";
import { Button } from 'reactstrap'
import "./Features.css";
import { addFilterPackage, updateFilterPackage } from "../../../redux/actions/packageAction";
import { connect } from "react-redux";

const PackageFeatureRow = (props) => {
  const {features,permissions,row}=props
  const [feature, setFeature] = useState({});
  const [permission, setpermission] = useState();
  const [parameters, setParameters] = useState('');
  const [ edit, setEdit ] = useState(false)

    const handleFeatureIdChange = (event) => {
      const featuresList = features.filter(feature => feature.id===parseInt(event.target.value))
      if(featuresList.length>0){
        setFeature(featuresList[0])
      }
    }

    useEffect(() => {
      if(!!props.row.feature_id){
        const feature = features.find(item => item.id === props.row.feature_id)
        setFeature(feature)
        setEdit(true)
      }
      if(!!props.row.permission){
        setpermission(props.row.permission)
      }
      if(!!props.row.parameter){
        setParameters(props.row.parameter)
      }
    }, [props.row])

    const handlePermissionChange = (event) => {
      setpermission(event.target.value)
    }

    const handleParametersChange = (event) => {
      setParameters(event.target.value)
    }

  const handleAddFeature = (e, id) => {
    e.preventDefault();
    // permission.id
    if(edit){
      props.updateFilterPackage(permission, props.id, feature.id, parameters, id)
    }else{
      props.addFilterPackage(permission, props.id, feature.id, parameters)
    }
    // props.addFilter()
  }

  return (
    <tr>
    <td>
        <select name="features" class="form-control" onChange={handleFeatureIdChange} value={!!feature.id ? feature.id : ''}>
      <option selected>Choose</option>
      {features.map(feature=><option value={feature.id}>{feature.id}</option>)}
    </select>
    </td>
    <td>{feature.name}</td>
    <td>
      <select name="permissions" class="form-control" onChange={handlePermissionChange} value={permission}>
      <option selected>Choose</option>
      <option value={'Clickable'}>clickable</option>
      <option value={'Visible'}>Visible</option>
      <option value={'Hidden'}>Hidden</option>
    </select>
    </td>
    <td><input type="text" name="parameter" value={parameters} onChange={handleParametersChange} /></td>
    <td><Button onClick={(e) => handleAddFeature(e, row.id)}>{!!edit ? "update" : 'save'}</Button></td>
    {
      !!edit && (
        <td><button style={{ float: "right"}} className="btn btn-primary" onClick={()=>props.removeRows(props.key, row.id)}>-</button></td>
      )
    }
  </tr>
  );
};

const mapStateToProps = state => {
  return {
    featureAdd: state.packages.addFilter
  }
}
export default connect(mapStateToProps, { addFilterPackage, updateFilterPackage })(PackageFeatureRow)
