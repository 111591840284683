import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";
import { onCheckCampaignBalance } from "../../../utils/network/lib/campaign";

class SpendCampaign extends Component {
  constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
    this.onKeyPressValidation = this.onKeyPressValidation.bind(this);
    this.GetMiniMumAmount = this.GetMiniMumAmount.bind(this);
    this.ProcessNext = this.ProcessNext.bind(this);
    this.checkCampaignBalance = this.checkCampaignBalance.bind(this);
  }

  saveAndContinue() {
    //const { campaign_amount, currency } = this.props.state;
    const balance = this.checkCampaignBalance();
    // if (!balance) return null;
    // if (campaign_amount > balance)
    //   toast.error(
    //     `Amount can't be bigger than your account balance. Your balance is ${balance} ${currency}`
    //   );
    //const budget = this.GetMiniMumAmount();
    /*if (campaign_amount == "" || campaign_amount == 0) {
      toast.error("Please Enter Campaign Amount !", {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (campaign_amount < budget) {
      toast.error("Budget Should be Minimum " + budget + " !", {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      this.props.nextStep();
      toast.dismiss();
    }*/
  }

  ProcessNext(event) {
    if (event.key === "Enter") {
      this.saveAndContinue();
    }
  }

  onKeyPressValidation(event) {
    let val = event.target.value.replace(/[^0-9]/gi, "");
    this.props.handleChange("campaign_amount", val);
  }

  gobackprevious() {
    this.props.prevStep();
  }

  GetMiniMumAmount() {
    var start_date = this.props.state.start_date;
    var end_date = this.props.state.end_date;
    var diff = end_date.diff(start_date, "days") + 1;
    let dur = diff;
    let min = dur * this.props.state.minAmount;

    const minimum_budget = dur < 8 ? this.props.state.secondMinAmount : min;
    return minimum_budget;
  }

  FormatedBudget(minimum_budget) {
    var offset = this.props.state.currencyOffset == 100 ? 2 : 0;
    const returnbudget = minimum_budget.toFixed(offset);
    return returnbudget;
  }

  checkCampaignBalance() {
    const { campaign_amount, currency } = this.props.state;
    const budget = this.GetMiniMumAmount();
    if (campaign_amount == "" || campaign_amount == 0) {
      return toast.error(this.props.t("campaign_enterAmount"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (campaign_amount < budget) {
      return toast.error(this.props.t("campaign_minBudget") + budget + " !", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    if (JSON.parse(localStorage.getItem("user_data")).role === "superadmin")
      this.props.nextStep();
    else if (
      JSON.parse(localStorage.getItem("user_data")).account_type === "prepaid"
    ) {
      onCheckCampaignBalance("prepaid")
        .then((res) => {
          const balance =
            res.data.data && res.data.data.available_balance
              ? res.data.data.available_balance
              : -1;
          if (balance != 1 && balance < campaign_amount) {
            toast.error(this.props.t("campaign_budgetMoreThanBalance"), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else {
            this.props.nextStep();
            toast.dismiss();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    } else {
      onCheckCampaignBalance("postpaid")
        .then((res) => {
          const balance =
            res.data.data && res.data.data.balance ? res.data.data.balance : -1;
          if (balance != 1 && balance < campaign_amount) {
            toast.error(this.props.t("campaign_budgetMoreThanBalance"), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else {
            this.props.nextStep();
            toast.dismiss();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
  }

  render() {
    const minimum_budget = this.GetMiniMumAmount();
    const Budget_formated = this.FormatedBudget(minimum_budget);

    return (
      <div className="w-100 animated">
        <div
          className="active col-12 px-0 tab-pane border-rounded p-3 mb-2"
          id="step11"
        >
          <div className="form-group brand-custom-form mt-2">
            <label style={{ margin: 0 }} className="mb-2">
              {this.props.t("campaign_amountforcampaign")}
            </label>
            <p className="question-description mb-3">
              {this.props.t("campaign_budgetCalculationText")}
            </p>

            <span className="cus-label-span d-block w-100 mb-4">
              {this.props.t("campaign_minspent")} {this.props.state.currency}{" "}
              {Budget_formated}{" "}
            </span>
            <input
              type="text"
              className="form-control"
              placeholder={this.props.t("campaign_typeAmount")}
              onChange={this.onKeyPressValidation}
              onKeyPress={this.ProcessNext}
              value={this.props.state.campaign_amount}
            />
          </div>
        </div>
        <div className="navination_bar">
          <div className="bar_search d-flex">
            <div className="above_navigation_box ml-auto">
              <button
                className="brandprofilecard__btn--white"
                onClick={this.gobackprevious}
              >
                {this.props.t("campaign_back")}
              </button>
            </div>
            <div
              className="below_navigation_box"
              style={{ marginRight: "0px" }}
            >
              <button
                className="brandoverviewleftsection__btn--purple"
                onClick={this.saveAndContinue}
              >
                {this.props.t("campaign_next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SpendCampaign);
