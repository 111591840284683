import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import "./BrandProfileCard.css";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import CheckDialog from "../../CheckDialog";
import PeasyApi from "../../../utils/peasy.api";
import { Spinner } from "reactstrap";
import { onFbValidateAssets } from "../../../utils/network/lib/validate";
import { onGetBrandsById } from "../../../utils/network/lib/brand";

const BrandProfileCard = ({ brandProfile }) => {
  const [open, setOpen] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [target, setTarget] = useState("");
  const [checkLoading, setCheckLoading] = useState(false);

  const History = useHistory();

  const handleCampaignClick = async () => {
    //prashant
    // this function is calling the TOS api and we are getting a number of field in which we have
    // the condition for TOS. If the condition matches the pop up will be displayed to accept the TOS
    // the data will be sent to the campaign creation for further checks.
    try {
      const resp = await onFbValidateAssets({
        brand_profile_id: brandProfile.id,
      });
      if (resp) {
        let result = resp.result;
        var array = Object.keys(result);
        for (let i = 0; i < array.length; i++) {
          var temp = array[i];
          if (
            (array[i] === "page_access" || array[i] === "ad_account") &&
            result[temp].status === false
          ) {
            setUrl(result[temp].callback_url);
            setMessage(result[temp].message);
            setButtonText(result[temp].callback_button_text);
            setTarget(result[temp].target);
            setOpen(true);
            setCheckLoading(false);
            return;
          }
        }
        History.push(`/campaign/create-new/${brandProfile.encoded_id}`, result);
      }
    } catch (error) {
      toast.error("Opps! Something went wrong while performing the action");
      setCheckLoading(false);
    }
  };

  const onEditClick = () => {
    onGetBrandsById(brandProfile.id)
      .then((res) => {
        History.push(`/brand/update/${brandProfile.id}`, res.data.data);
      })
      .catch((err) => {
        return toast.error(err.response.data.message);
      });
  };

  return (
    <div className="brandprofilecard__container">
      <i
        onClick={() => onEditClick()}
        style={{
          position: "absolute",
          right: "7%",
          top: "7%",
          fontSize: "12px",
          cursor: "pointer",
        }}
        className="fas fa-edit"
      />

      <span className="brandprofilecard__heading">
        {brandProfile.brand_name}
      </span>
      {/* {brandProfile.data && brandProfile.data.length > 0 && (
      <div style={{ width: "90%", marginLeft: "5%" }}>
        <BrandProfileCardChart chartData={brandProfile.data} />
      </div>
    )} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0px",
        }}
      >
        <div
          style={{
            fontSize: "40px",
            marginLeft: "7%",
            letterSpacing: "1px",
            position: "relative",
            paddingRight: "25px",
          }}
          className="brandprofilecard__heading"
        >
          {/* <sup style={{fontSize: '16px', verticalAlign: 'text-bottom', float: 'right', fontWeight: 'bold'}} >{brandProfile.data_size_unit}</sup> */}
          {brandProfile.data_size}
          <span className="brandprofilecard__bytes">
            {brandProfile.data_size_unit}
          </span>
        </div>

        <span
          className="brandprofilecard__heading"
          style={{ fontSize: "40px", marginRight: "20%", position: "relative" }}
        >
          {brandProfile.total_campaigns}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "-2vh",
        }}
      >
        <span
          className="brandprofilecard__smalltext"
          style={{ marginLeft: "5%" }}
        >
          Trained
        </span>
        <span
          className="brandprofilecard__smalltext"
          style={{ width: "79px", textAlign: "center", marginLeft: "15%" }}
        >
          No. of active campaigns
        </span>
      </div>

      <div className="brandprofilecard__row--buttons">
        {/* This css can be found in brandoverviewleftsection component */}
        <Link to={`/brand/overview/${brandProfile.encoded_id}`}>
          <button className="brandprofilecard__btn--white">REPORT</button>
        </Link>
        {brandProfile.fb_promoted_id ? (
          checkLoading ? (
            <button className="brandoverviewleftsection__btn--purple">
              Validating <Spinner style={{ width: "14px", height: "14px" }} />
            </button>
          ) : (
            <div
              onClick={() => {
                setCheckLoading(true);
                handleCampaignClick();
              }}
            >
              <button className="brandoverviewleftsection__btn--purple">
                +CAMPAIGN
              </button>
            </div>
          )
        ) : (
          <button
            style={{
              backgroundColor: "#6c757d",
              cursor: "default",
              border: "1px solid #6c757d",
            }}
            className="brandoverviewleftsection__btn--purple"
          >
            +CAMPAIGN
          </button>
        )}
        {brandProfile.fb_promoted_id ? (
          <Link to={`/brand/leads/${brandProfile.id}`}>
            <button className="brandoverviewleftsection__btn--purple">
              Leads
            </button>
          </Link>
        ) : (
          <button
            style={{
              backgroundColor: "#6c757d",
              cursor: "default",
              border: "1px solid #6c757d",
            }}
            className="brandoverviewleftsection__btn--purple"
          >
            Leads
          </button>
        )}
      </div>
      <CheckDialog
        //prashant
        // this is a check dialog for checking account validity
        open={open}
        setOpen={setOpen}
        url={url}
        message={message}
        srcImg="/images/assets/Failure.svg"
        target={target}
        buttonText={buttonText}
      />
    </div>
  );
};

export default BrandProfileCard;
