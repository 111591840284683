export const Impression = 1;
export const Click = 2;
export const Traffic = 3;
export const Conversion = 4;
export const Engagement = 5;
export const Lead = 6;
// export const Lead = 4;

export const Objectives_Array = [];
export const Objective_Step_Array = [];
export var BASE_URL = "/peasy/public/";
export var SERVER_URL = "https://app.peasy.ai/peasy/public/";
Objectives_Array[Impression] = "IMPRESSION";
Objectives_Array[Click] = "CLICK";
Objectives_Array[Traffic] = "TRAFFIC";
// Objectives_Array[Lead] = "LEAD";
Objectives_Array[Conversion] = "SALES";
Objectives_Array[Engagement] = "POST_ENGAGEMENT";
Objectives_Array[Lead] = "LEAD";

export const Objectives_Display_Array = [];

Objectives_Display_Array[Impression] = "IMPRESSION";
Objectives_Display_Array[Click] = "CLICK";
Objectives_Display_Array[Traffic] = "TRAFFIC";
// Objectives_Display_Array[Lead] = "LEAD";
Objectives_Display_Array[Conversion] = "CONVERSION";
Objectives_Display_Array[Engagement] = "ENGAGEMENT";
Objectives_Display_Array[Lead] = "LEAD";

const OBJECTIVE_STEP = 1;
const NAME_STEP = 2;
const PLATFORM_STEP = 3;
const PIXEL_STEP = 4;
const DURATION_STEP = 5;
const BUDGET_STEP = 6;
const TARGET_STEP = 7;
const LOCATION_STEP = 8;
const LEAD_FORM_STEP = 9;
const FORM_FIELD_MAPPING = 13;
const TARGET_CATEGORY = 14;

Objective_Step_Array[Impression] = [
  OBJECTIVE_STEP,
  NAME_STEP,
  DURATION_STEP,
  BUDGET_STEP,
  TARGET_STEP,
  // TARGET_CATEGORY,
  LOCATION_STEP,
];
Objective_Step_Array[Click] = [
  OBJECTIVE_STEP,
  NAME_STEP,
  DURATION_STEP,
  BUDGET_STEP,
  TARGET_STEP,
  // TARGET_CATEGORY,
  LOCATION_STEP,
];
Objective_Step_Array[Traffic] = [
  OBJECTIVE_STEP,
  NAME_STEP,
  PLATFORM_STEP,
  DURATION_STEP,
  BUDGET_STEP,
  TARGET_STEP,
  // TARGET_CATEGORY,
  LOCATION_STEP,
];
Objective_Step_Array[Engagement] = [
  OBJECTIVE_STEP,
  NAME_STEP,
  DURATION_STEP,
  BUDGET_STEP,
  TARGET_STEP,
  // TARGET_CATEGORY,
  LOCATION_STEP,
];
Objective_Step_Array[Conversion] = [
  OBJECTIVE_STEP,
  NAME_STEP,
  PLATFORM_STEP,
  PIXEL_STEP,
  DURATION_STEP,
  BUDGET_STEP,
  TARGET_STEP,
  // TARGET_CATEGORY,
  LOCATION_STEP,
];
Objective_Step_Array[Lead] = [
  OBJECTIVE_STEP,
  NAME_STEP,
  LEAD_FORM_STEP,
  FORM_FIELD_MAPPING,
  DURATION_STEP,
  BUDGET_STEP,
  TARGET_STEP,
  // TARGET_CATEGORY,
  LOCATION_STEP,
];

// BASE_URL = 'http://dev5.peasy.ai/peasy_gerry/public/';

const ISLIVESITE = 1;
if (ISLIVESITE == 0) {
  BASE_URL = "/service/";                                           // "/peasy/public/"
  //BASE_URL = "/peasy-sh/public/";
  SERVER_URL = "https://app.peasy.ai/service";                     // https://dev5.peasy.ai/peasy/public/
  // SERVER_URL = "https://dev5.peasy.ai/peasy-sh/public/";
} else if (ISLIVESITE == 1) {
  BASE_URL = "/service/";
  SERVER_URL = "https://app.peasy.ai/service";
} else {
  BASE_URL = "";
  SERVER_URL = "http://localhost/";
}

export default {
  Objectives_Array,
  BASE_URL,
  Objectives_Display_Array,
  SERVER_URL,
  Objective_Step_Array,
};
