import { Hidden } from "@material-ui/core";
import React, { PureComponent } from "react";
import ReactDOM from "react-dom";

const Stickybar = () => {
  const getData = (name) => {
    return JSON.parse(localStorage.getItem("user_data"))[name] || "";
  };

  if (window.location.href.includes("campaign/edit")) return <></>;

  return (
    <Hidden smDown>
      <div style={{ position: "relative", zIndex: 1 }} className="stickynote">
        <div className="row">
          <div className="col col-md-3">
            {/* {Number(isTrialVersion) ? ( */}
            {/* {userDetails.trialVersion ? (
              <span>
                <b>You are on Peasy Free Trial BETA</b>
                <br />
                Spend up to {userDetails.trialAmount}{" "}
                {userDetails.activeCurrency || 'MYR'} on Facebook and Google ads!
              </span>
            ) : (
              ""
            )} */}
          </div>
          <div className="col col-md-6 text-center">
            <div className="row">
              <div className="col col-md-6">
                {localStorage.getItem("user_data") &&
                JSON.parse(localStorage.getItem("user_data")).account_type ===
                  "superadmin" ? (
                  <></>
                ) : (
                  <div>
                    <b>Available Ad Spend</b>
                    <br />
                    {(localStorage.getItem("user_data") &&
                      JSON.parse(localStorage.getItem("user_data"))
                        .user_current_spend.total_spend_amount &&
                      JSON.parse(localStorage.getItem("user_data"))
                        .user_current_spend.total_spend_amount) ||
                      0}{" "}
                    / {Math.round(getData("user_ad_spend_limit"))}{" "}
                    {getData("currency_type")}
                  </div>
                )}
              </div>
              {/* <div className="col col-md-6">
                <b>Non-Expirable Ad Spend</b>
                <br />
                {userDetails.nonExpirableCredit} {userDetails.activeCurrency || 'MYR'}
              </div> */}
            </div>
          </div>
          <div className="col col-md-3">
            {/* {userDetails.trialVersion ? (
              <a
                data-toggle="modal"
                id="upgrade-now"
                data-target="#peasy-upgrade"
                className="btn btn-secondary pull-right"
              >
                Upgrade Now
              </a>
            ) : (
              <a
                data-toggle="modal"
                id="topup"
                data-target="#peasy-topup"
                className="btn btn-secondary pull-right"
              >
                Topup
              </a>
            )} */}
          </div>
        </div>
      </div>
    </Hidden>
  );
};

export default Stickybar;
