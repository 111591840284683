import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import validator from "validator";
import CampaignInfoBox from "./campaignInfoBox/CampiagnInfoBox";
import SelectAudiences from "./selectAudiences/SelectAudiences";
import FbCard from "./FbCard/FbCard";
import AddPostCard from "./addPostCard/AddPostCard";
import SelectPostPopup from "./selectPostPopup/SelectPostPopup";
import { BASE_URL } from "../../StaticArray/StaticArray";
import http from "../../shared/http-request";
import PopupAlert from "../../common/popupAlert/PopupAlert";
import CreateCardSteps from "./createCardSteps/CreateCardSteps";
import CarouselCard from "./carouselCard/CarouselCard";
import ImageCard from "./imageCard/ImageCard";
import VideoCard from "./videoCard/VideoCard";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./PostBoost.css";
import { PostBoostChartComponent } from "./postBoostChartComponent/PostBoostChartComponent";
import { GET_CAMPAIGNMETRIC_URL } from "../../redux/routes";
import CheckoutComponent from "../Payment/Stripe/CheckoutComponent.jsx";
import ToupInvoicePopUp from "../Payment/TopupInvoicePopUp";
import {
  AppBar,
  CircularProgress,
  debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import PeasyApi from "../../utils/peasy.api";
import MergeAudienceDialog from "./audienceOverlap/MergeAudienceDialog";
import Error from "../../utils/hrefError/Error";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import {
  onDeployCampaign,
  onGetAudienceList,
  onGetNotifications,
  onSavePosts,
  onPauseCampaign,
} from "../../utils/network/lib/campaign";
import {
  onAudienceOverlap,
  onAudienceRecommendation,
} from "../../utils/network/lib/audience";
import {
  onGetPageDetails,
  onImageUpload,
  onVideoUpload,
} from "../../utils/network/lib/misc";
import { onGetUserBalance } from "../../utils/network/lib/user";
import { getPendingPosts } from "../../utils/network/lib/canva";
import { CANVA_BASE } from "../../utils/canva";
// import { attributeHelpers, userMethods } from "../../utils/simplifierHelper.js";
import { useSelector } from "react-redux";

toast.configure({
  position: toast.POSITION.BOTTOM_LEFT,
});
function getCampaignId() {
  const url = window.location.href.split("/").pop();
  return url.split("?")[0];
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div {...other}>
      {(props.windowWidth > 600 || value === index) && <>{children}</>}
    </div>
  );
}
const stripePromise = loadStripe(
  "pk_live_51GcRudIB8fU21ThgW0EcLP5MZ69oCYn9whXnlTT7GRFFjGLEZeI7G6sCwDRJ965h9QTmVCgN4r3bQwenMHlIFTAZ00a9oHmEha"
);
const PostBoost = () => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "pi_3KyAUMIB8fU21Thg0rvWV1Td_secret_F7kPRGdbs7GrB2lqhRSUoGMPs",
  };
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state?.auth?.user);

  const { id } = useParams();
  const [currentCampaignId, setCurrentCampaignId] = useState(id);
  const [firstCampaignId, setFirstCampaignId] = useState(id);
  const [secondCampaignId, setSecondCampaignId] = useState(null);
  const [campaignSwitchButtonText, setCampaignSwitchButtonText] =
    useState("Next Campaign");
  const [mergeAudienceDialogOpen, setMergeAudienceDialogOpen] = useState(false);

  const [audienceMergeLoading, setAudienceMergeLoading] = useState(false);
  const [audienceMergeDialogOpened, setAudienceMergeDialogOpened] =
    useState(false);
  const [getMediaDetailsCallingFlag, setGetMediaDetailsCallingFlag] =
    useState(false);
  const [mergeAudienceImage, setMergeAudienceImage] = useState(
    "/images/assets/merge-audience.svg"
  );
  const [timeoutCount, setTimeoutCount] = useState(20000);
  const rendersForSavePost = useRef(0);
  const creativeSection = useRef(0);

  const history = useHistory();

  const [postsToShow, setPostsToShow] = useState(null);

  const [cardsToShow, setCardsToShow] = useState([]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [pageDetails, setPageDetails] = useState({});

  const [searchPostQuery, setSearchPostQuery] = useState("");

  const [audiences, setAudiences] = useState(null);

  const [dataToSubmit, setDataToSubmit] = useState({});

  const [ctaList, setCtaList] = useState([]);

  const [hasExistingPosts, setHasExistingPosts] = useState(false);

  const [existingPosts, setExistingPosts] = useState(null);

  const [editCardPosition, setEditCardPosition] = useState(null);

  const [removeCardAtEdit, setRemoveCardAtEdit] = useState("");

  const [validationErrors, setValidationErrors] = useState({});

  const [cardToEditData, setCardToEditData] = useState(null);

  const [showDeploymentPopup, setShowDeploymentPopup] = useState(false);
  const [showTopupPopup, setShowTopupPopup] = useState(false);
  const [toppAmount, setTopupAmount] = useState(0);
  const [userCurrency, setUserCurrency] = useState(
    JSON.parse(localStorage.getItem("user_data")).currency || "MYR"
  );
  const [otherAmount, setOtherAmount] = useState(0);

  const [cardsStepPost, setCardsStepPost] = useState(null);

  const [budgetPercentages, setBudgetPercentages] = useState({});

  // const [budgets, setBudgets] = useState({});

  // const [totalBudget, setTotalBudget] = useState(0);

  const [saveLoading, setSaveLoading] = useState(false);

  const [publishLoading, setPublishLoading] = useState(false);

  const [campaignData, setCampaignData] = useState(null);
  const [nextCampaignData, setNextCampaignData] = useState(null);

  const [imageCards, setImageCards] = useState([]);

  const [imageCardIds, setImageCardIds] = useState([]);

  const [videoCards, setVideoCards] = useState([]);

  const [videoCardIds, setVideoCardIds] = useState([]);

  const [gotImageAdsPaths, setGotImageAdsPaths] = useState(false);

  const [gotVideoAdsPaths, setGotVideoAdsPaths] = useState(false);

  const [gotCarouselAdsPaths, setGotCarouselAdsPaths] = useState(false);

  const [gotPostAds, setGotPostAds] = useState(false);

  const [totalVolume, setTotalVolume] = useState([]);

  const [predictionVolume, setPredictionVolume] = useState([]);

  const [shouldDeploy, setShouldDeploy] = useState(false);

  const [minBudgetPercentage, setMinBudgetPercentage] = useState(0);

  const [myr3, setMyr3] = useState(0);

  const [campaignStep4, setCampaignStep4] = useState(0);
  const [campaignStep3, setCampaignStep3] = useState(0);

  // state goes to edit audience fields as prop
  const [editAudienceFields, setEditAudienceFields] = useState({});

  const [isBudgetError, setBudgetError] = useState(false);

  const [carouselCards, setCarouselCards] = useState([]);

  const [carouselCardIds, setCarouselCardIds] = useState([]);

  const [isCarouselPopUp, carouselPopVisible] = useState(false);

  const [carouselMediaId, setCarouselId] = useState("");

  const [showRecommendAudiencePopup, setShowRecommendAudiencePopup] =
    useState(false);

  const [recommendAudienceLoading, setRecommendAudienceLoading] =
    useState(false);

  //state controls loading of ok button in deployment popup
  const [deploymentLoading, setDeploymentLoading] = useState(false);

  const [deploymentLoadingMsg, setDeploymentLoadingMsg] = useState(null);

  const [redirect, setRedirect] = useState(false);

  const [redirectAction, setRedirectAction] = useState(false);

  const [campaignMetricData, setCampaignMetricData] = useState({});

  const [isCampaignMetricDataLoading, setIsCampaignMetricDataLoading] =
    useState(true);

  const [campaignException, setCampaignException] = useState(false);
  const [open, setOpen] = useState(false);
  const [unAssignedAudiences, setUnassignedAudiences] = useState([]);

  const [nextCampaignId, setNextCampaignId] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [prepaidTopup, setPrepaidTopup] = useState({});
  const [budgetPredictionError, setPredictionBudgetError] = useState({
    errorMessage: "",
    section: "",
  });
  const [tabValue, setTabValue] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [startStripePayment, setStartStripePayment] = useState(false);

  //CANVA START
  let fetchedPending = false;

  //component did mount
  const [notification, setNotification] = useState([]);

  const getNotification = async () => {
    const campaign_id = getCampaignId();
    try {
      const data = await onGetNotifications(campaign_id);

      setNotification(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    updateHeight();
    const query = new URLSearchParams(window.location.search);
    const token = query.get("callback");
    if (token && token === "payment") {
      checkBalanceAvailable();
    }
  }, []);

  useEffect(() => {
    setMergeAudienceDialogOpen(false);
    setAudienceMergeDialogOpened(false);
    setMergeAudienceImage("/images/assets/merge-audience.svg");
    setAudienceMergeDialogOpened(false);
    setCampaignMetricData({});
    getPageDetails();
    setPredictionVolume([]);
  }, [currentCampaignId]);

  // when audiences is set total budget, budgets array and budget percentages are set
  // useEffect(() => {
  //   if (audiences && audiences.length > 0) {
  //     let object = {};
  //     let budgetObject = {};         //removable code
  //     let total_budget = 0;
  //     audiences.map(audience => {
  //       total_budget += audience.budget;
  //       budgetObject[audience.id] = audience.budget;
  //       object[audience.id] = audience.budget_percentage;
  //     });
  //     setTotalBudget(total_budget);
  //     setBudgets(budgetObject);
  //     setBudgetPercentages(object);
  //   }
  // }, [audiences]);

  //when card to show changes,
  // 1. if has existing posts is true then all the posts are put in data to submit state
  // 2. if cards to show length is 0, data to submit is {}
  // 3. otherwise map cards to show and
  useEffect(() => {
    if (hasExistingPosts && cardsToShow.length > 0) {
      const object = {};
      existingPosts.map((post) => {
        console.log("existing post ad", post);
        object[post.id] = {
          post_id: post.path,
          platform: post.platform,
          cta: post.cta,
          // url: post.url,
          ids: post.ids,
          url: post.final_url,
          audiences: post.audiences,
          ad_ids: post.ad_ids,
        };
      });
      setDataToSubmit(object);
      setHasExistingPosts(false);
      setExistingPosts(null);
    } else {
      if (cardsToShow.length === 0) return setDataToSubmit({});
      cardsToShow.map((card) => {
        if (dataToSubmit[card.id]) return null;
        else {
          const data = {};
          data.post_id = card.id;
          data.platform = card.platform;
          data.audiences = card.audiences || [];
          data.url = campaignData.promoted_url;
          data.cta = card.cta || "";
          data.ids = [];
          // if (card.audiences) data.audiences = card.audiences;
          // if (card.url) data.url = card.url;
          if (cardToEditData) {
            data.url = cardToEditData.url;
            data.cta = cardToEditData.cta;
            data.audiences = cardToEditData.audiences;
            setCardToEditData(null);
          }
          dataToSubmit[card.id] = data;
        }
      });
    }
  }, [cardsToShow]);

  //when page details has come and has existing posts is true, get existing posts is called
  // useEffect(() => {
  //   if (hasExistingPosts && pageDetails.id) {
  //     getExistingPosts();
  //   }
  // }, [hasExistingPosts, pageDetails]);

  useEffect(() => {
    // console.log("inside page details effect", pageDetails, campaignStep4);
    if (campaignStep3 === 1) {
      getMedia();
      getAudienceList();
      getPostMetricData();
      getNotification();
    }
  }, [pageDetails, campaignStep3]);

  // Setting Pediction volume and cpa for leads chart
  useEffect(() => {
    if (
      campaignMetricData &&
      campaignMetricData.data != null &&
      !isNaN(predictionVolume[0])
    ) {
      // console.log(
      //   "predictionVolume UseEffect => ",
      //   predictionVolume,
      //   Date.now()
      // );
      let campaignDataTemp = campaignMetricData;
      let result = 0;
      let cpa = 0;
      if (predictionVolume && predictionVolume.length > 0) {
        for (let i = 0; i < audiences.length; i++) {
          result += predictionVolume[i];
        }
      }
      if (
        campaignDataTemp &&
        campaignDataTemp.data &&
        campaignDataTemp.data.sales_vol_data
      ) {
        campaignDataTemp.data.sales_vol_data[2].value = result;
        if (campaignData) {
          cpa = campaignData.budget / result;
          if (campaignData.objective === "IMPRESSION") cpa = cpa * 1000;
        }
        campaignDataTemp.data.cpa_comparison_data[2].value = parseFloat(
          cpa.toFixed(2)
        );
        setCampaignMetricData(campaignDataTemp);
      }
    }
    // console.log("predictionVolume campaignMetricData=>", campaignMetricData);
  }, [predictionVolume]);

  // useEffect(() => {
  //   console.log("Audience useEffect", Date.now());
  // }, [audiences]);

  // useEffect(() => {
  //   console.log("predictionVolume useEffect", Date.now());
  // }, [predictionVolume]);

  // if image cards change change the array of ids for the correct order
  useEffect(() => {
    let array = [];
    imageCards.map((card) => (array = [...array, card.unique_id || card.id]));
    setImageCardIds(array);
  }, [imageCards]);

  // if video cards change, change the array of ids for the correct order
  useEffect(() => {
    let array = [];
    videoCards.map((card) => (array = [...array, card.unique_id || card.id]));
    setVideoCardIds(array);
    console.log("video cards useEffect", videoCards);
  }, [videoCards]);

  // if carousalCards cards change, change the array of ids for the correct order
  useEffect(() => {
    let array = [];
    carouselCards.map(
      (card) => (array = [...array, card.unique_id || card.id])
    );
    setCarouselCardIds(array);
  }, [carouselCards]);

  //when you get paths for all image, video and carousel ads send call save api
  useEffect(() => {
    if (rendersForSavePost.current === 0) {
      rendersForSavePost.current = 1;
      return;
    }

    if (isBudgetError) {
      toast.error(t("campaign_divideRatioInPerfectManager"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setSaveLoading(false);
    } else {
      let goodToGo1 = false;
      let goodToGo2 = false;
      let goodToGo3 = false;
      let goodToGo4 = false;

      // Handle image get path Ads
      if (imageCards.length > 0 && gotImageAdsPaths) goodToGo1 = true;

      if (imageCards.length === 0) goodToGo1 = true;

      // Handle video get path Ads
      if (videoCards.length > 0 && gotVideoAdsPaths) goodToGo2 = true;

      if (videoCards.length === 0) goodToGo2 = true;

      // Handle carousel path manipulation
      if (carouselCards.length > 0 && gotCarouselAdsPaths) goodToGo3 = true;

      if (carouselCards.length === 0) goodToGo3 = true;

      if (gotPostAds) goodToGo4 = true;

      if (goodToGo1 && goodToGo2 && goodToGo3 && goodToGo4) {
        savePosts();
      }
    }
  }, [gotImageAdsPaths, gotVideoAdsPaths, gotPostAds, gotCarouselAdsPaths]);

  //api to get page details i.e.
  // access token, name, id
  const getPageDetails = () => {
    const campaign_id = getCampaignId();
    onGetPageDetails({
      params: {
        campaign_id,
      },
    }).then((res) => {
      if (res.data.status) setPageDetails(res.data.data);
      else
        toast.error(t("campaign_somethingWentWrong"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
    }); // Configuring notifications library
    toast.configure({
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  //new get media details

  useEffect(() => {
    console.log("called");
    callGetMediaDetails();
  }, [currentCampaignId, getMediaDetailsCallingFlag]);

  useEffect(() => {
    if (!campaignData?.promoted_url || fetchedPending) return;
    console.log("canva: finding pending uploads");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const campaign_id = getCampaignId();

    getPendingPosts(campaign_id)
      .then((response) => response.text())
      .then(async (results) => {
        let userData = JSON.parse(localStorage.getItem("user_data"));

        console.log("canva: res", results, campaign_id, "");
        results = JSON.parse(results);
        let SubmitData = [];
        await Promise.all(
          results.map(async (result) => {
            let tempUrl = CANVA_BASE + "/" + campaign_id + "/" + result;
            console.log("canva: temp url", tempUrl);

            const response = await fetch(tempUrl);
            console.log("canva: img res", response);
            const blob = await response.blob();
            console.log("canva: blob", blob);
            SubmitData.push({
              image: blob,
              name: result,
            });
          })
        );
        console.log("canva: res", SubmitData);

        let testArray = [];
        SubmitData.map((image, i) => {
          console.log("image arr", image);
          if (image["image"]) {
            const newArray = [
              ...imageCards,
              {
                file_object: image.image,
                unique_id: Math.floor(Math.random() * 1000),
                path: "",
                headline: "",
                description: "",
                message: "",
                cta: null,
                final_url: campaignData.promoted_url,
                ids: null,
                audiences: null,
                width: image.width ? image.width : "",
                height: image.height ? image.height : "",
                widthRatio: image.widthRatio ? image.widthRatio : "",
                heightRatio: image.heightRatio ? image.heightRatio : "",
                isCanva: { campaign_id: campaign_id, name: image["name"] },
              },
            ];
            testArray = testArray.concat(newArray);
            console.log("testArray", testArray);
            setImageCards(testArray);
          }
        });

        // recogRef.current.scrollIntoView({
        //   behavior: "smooth",
        // });
        // showImageCard(SubmitData);
      })

      .catch((error) => console.log("canva: error", error))
      .finally(() => {
        fetchedPending = true;

        let scroll = new URLSearchParams(window.location.search).get("scroll");
        if (scroll && scroll == "creatives") {
          const myTimeout = setTimeout(myStopFunction, 1000);

          function myStopFunction() {
            console.log("creative section", creativeSection);
            if (creativeSection) {
              creativeSection.current.scrollIntoView({
                behavior: "smooth",
              });
            } else {
              console.log("creative section else");
            }
          }
          window.history.pushState(
            {},
            document.title,
            window.location.pathname
          );
        }
      });
  }, [campaignData?.promoted_url]);

  // const fetchPendingCanva = () => {
  //   getMediaDetails();
  // };
  const callGetMediaDetails = () => {
    getMediaDetails();
  };
  const getMediaDetails = async () => {
    const campaign_id = getCampaignId();
    try {
      const res = await http.get(`${BASE_URL}api/campaign/details`, {
        params: {
          campaign_id,
        },
      });

      if (res && res.data.status) {
        setNextCampaignId(res.data.data.campaign.next_campaign);
        if (res.data.data.next_campaign) {
          setNextCampaignData(res.data.data.next_campaign);
        }
        if (!secondCampaignId) {
          setSecondCampaignId(res.data.data.campaign.next_campaign);
        }
        setCampaignData(res.data.data.campaign);
        setEventId(res.data.data.campaign_event_goal);
        let cta_list = res.data.data.cta_list;
        setCtaList(res.data.data.cta_list);
        setMinBudgetPercentage(res.data.data.min_audience_perentage);
        setMyr3(res.data.data.myr3_in_usr);

        // setting this state sets the effect which calls
        //for audiences and posts
        setCampaignStep3(res.data.data.campaign_steps.step_3);
        setCampaignStep4(res.data.data.campaign_steps.step_4);

        const object = {
          default_audience_bid: res.data.data.default_audience_bid,
          facebook_connect: res.data.data.facebook_connect,
          google_connect: res.data.data.google_connect,
          max_bid: res.data.data.max_bid,
          min_bid: res.data.data.min_bid,
        };
        setEditAudienceFields(object);
        // if (res.data.data.campaign_steps.error === 1) {
        //   setCampaignException(true);
        //   toast.error(
        //     "Oops! Something went wrong while creating campaign, please contact Admin",
        //     {
        //       position: toast.POSITION.BOTTOM_LEFT,
        //     }
        //   );
        // } else
        if (res.data.data.campaign_steps.step_4 === 1) {
          getAudienceList();
          // setMergeAudienceDialogOpen(false);
          setAudienceMergeLoading(false);
          setMergeAudienceImage("/images/assets/success.svg");
        }

        if (
          res.data.data.campaign_steps.step_4 === 0 &&
          audiences &&
          (audiences.length === 1 || audiences.length === 0)
        ) {
          runAudienceMerge(false);
        }

        if (
          res.data.data.campaign_steps.step_3 === 1 &&
          res.data.data.campaign_steps.step_4 === 0 &&
          !audienceMergeDialogOpened &&
          audiences &&
          audiences.length > 1
        ) {
          setAudienceMergeDialogOpened(true);
          setMergeAudienceImage("/images/assets/merge-audience.svg");
          setTimeout(() => {
            handleaudienceMerge();
          }, timeoutCount);
        }
        if (res.data.data.campaign_steps.step_4 === 0) {
          setTimeout(() => {
            setGetMediaDetailsCallingFlag(!getMediaDetailsCallingFlag);
          }, 10000);
        }
      } else
        toast.error(t("campaign_somethingWentWrong"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
    } catch (err) {
      toast.error(t("campaign_somethingWentWrong"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const handleaudienceMerge = () => {
    !mergeAudienceDialogOpen && setMergeAudienceDialogOpen(true);
  };
  // useEffect(() => {
  //   handleaudienceMerge();
  // }, [getMediaDetailsCallingFlag]);
  const getMedia = () => {
    const campaign_id = getCampaignId();
    if (pageDetails && pageDetails.id && pageDetails.access_token) {
      http
        .get(`${BASE_URL}api/campaign/creatives`, {
          params: {
            campaign_id,
            page_id: pageDetails.id,
            access_token: pageDetails.access_token,
          },
        })
        .then((res) => {
          if (res.data.data.image_ads.length > 0) {
            let temp = imageCards.concat(res.data.data.image_ads);
            setImageCards(temp);
          } else {
            // setImageCards([]);
          }
          if (res.data.data.post_ads.length > 0) {
            setHasExistingPosts(true);
            setExistingPosts(res.data.data.post_ads);
            let fbPosts = [];
            let instaPosts = [];
            res.data.data.post_ads.map((post) => {
              if (post.platform === 1) fbPosts = [...fbPosts, post];
              if (post.platform === 2) instaPosts = [...instaPosts, post];
            });
            // const fbDataForCards = setFbData(fbPosts);
            // const instaDataForCards = setInstaData(instaPosts);
            setCardsToShow([...fbPosts, ...instaPosts]);
            // setCardsToShow([...fbDataForCards, ...instaDataForCards]);
          } else {
            setCardsToShow([]);
          }
          if (res.data.data.video_ads.length > 0) {
            setVideoCards(res.data.data.video_ads);
          } else {
            setVideoCards([]);
          }
          if (res.data.data.carousel_ads.length > 0) {
            let updateData = [];
            res.data.data.carousel_ads.map((card) => {
              let carouselInternalData = [];
              card.carousel_sources.map((data) => {
                carouselInternalData = [
                  ...carouselInternalData,
                  {
                    ...data,
                    unique_id: data.unique_id
                      ? data.unique_id
                      : Math.floor(Math.random() * 1000),
                    description: data.description ? data.description : "",
                    thumbnail: data.thumbnail ? data.thumbnail : "",
                  },
                ];
              });

              updateData = [
                ...updateData,
                {
                  ...card,
                  carousel_group_id: card.carousel_group_id
                    ? card.carousel_group_id
                    : "",
                  unique_id: Math.floor(Math.random() * 1000),
                  carousel_sources: carouselInternalData,
                  description: card.description ? card.description : "",
                },
              ];
            });

            setCarouselCards(updateData);
          } else {
            setCarouselCards([]);
          }
        });
    }
  };

  const setExistingImageCards = (imageAds) => {
    let array = [];
    imageAds.map((image) => {
      const audiences = [];
      if (image.audiences.length > 0) {
        image.audiences.map((audience) => {
          if (audiences.indexOf(audience) === -1) audiences.push(audience);
        });
      }
      const object = {
        ...image,
        unique_id: Math.floor(Math.random() * 1000),
        audiences,
      };
      array = [...array, object];
    });
    setImageCards(array);
  };

  //api to get audience list
  const getAudienceList = () => {
    const campaign_id = getCampaignId();

    onGetAudienceList({
      params: {
        campaign_id,
      },
    })
      .then((res) => {
        setAudiences(res.data.data);
        console.log("aud loaded");
        try {
          window.$peasy.triggerEvent({ event_name: "audienceLoaded" });
        } catch (e) {}
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  //api to get existing posts
  // const getExistingPosts = () => {
  //   const objectToSend = {
  //     page_id: pageDetails.id,
  //     access_token: pageDetails.access_token,
  //     instagram_id: pageDetails.instagram_business_account
  //       ? pageDetails.instagram_business_account.id
  //       : null,
  //     campaign_id: window.location.href.split("/").pop()
  //   };
  //   http
  //     .get(`${BASE_URL}api/get-fb-saved-posts`, {
  //       params: objectToSend
  //     })
  //     .then(res => {
  //       if (res.data.status) {
  //         let fbPosts = [];
  //         let instaPosts = [];
  //         setExistingPosts(res.data.data.existing_posts);
  //         res.data.data.existing_posts.map(post => {
  //           if (post.platform === 1) fbPosts = [...fbPosts, post.post];
  //           if (post.platform === 2) instaPosts = [...instaPosts, post.post];
  //         });
  //         const fbDataForCards = setFbData(fbPosts);
  //         const instaDataForCards = setInstaData(instaPosts);
  //         setCardsToShow([...fbDataForCards, ...instaDataForCards]);
  //       }
  //     })
  //     .catch(err => console.log("error in getting existing posts", err));
  // };

  //api to get posts
  const getPosts = (platform) => {
    if (Object.keys(pageDetails).length === 0)
      return toast.error(t("campaign_errorGettingPosts"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });

    const campaign_id = getCampaignId();

    http
      .get(`${BASE_URL}api/posts`, {
        params: {
          platform,
          page_id: pageDetails.id || "",
          page_access_token: pageDetails.access_token || "",
          q: searchPostQuery,
          campaign_id,
          instagram_id: pageDetails.instagram_business_account
            ? pageDetails.instagram_business_account.id
            : null,
        },
      })
      .then((res) => {
        if (res.data.status === true) setResponseData(platform, res.data.data);
        else if (res.data.status === false)
          toast.error(t("campaign_somethingWentWrong"), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("campaign_somethingWentWrong"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  //data is segregated according to platform
  const setResponseData = (platform, res) => {
    switch (platform) {
      case 1:
        const data = setFbData(res);
        setPostsToShow(data);
        break;
      case 2:
        const instaData = setInstaData(res);
        setPostsToShow(instaData);
        break;
      default:
        const fbData = setFbData(res);
        setPostsToShow(fbData);
    }
  };

  //instagram data is fed into an object
  const setInstaData = (res) => {
    let postsList = [];
    res.map((post) => {
      const postObject = {};
      // if (!post.is_instagram_eligible || !post.is_eligible_for_promotion) null;
      // else {
      if (post.caption) {
        postObject.text = post.caption.substr(0, 40);
      }
      // else postObject.text = "This post has no caption"
      postObject.comments = post.comments_count;
      postObject.id = post.id;
      postObject.img = post.media_url;
      postObject.likes = post.like_count;
      postObject.platform = 2;
      postObject.type = post.media_type;
      postObject.createdTime = moment(post.timestamp).format("ll");
      postsList = [...postsList, postObject];
      // }
    });
    return postsList;
  };

  //fb data is fed into an object
  const setFbData = (res) => {
    let postsList = [];
    res.map((post) => {
      const postObject = {};
      // if (!post.is_instagram_eligible || !post.is_eligible_for_promotion) {
      //   return null;
      // } else {
      if (post.attachments && post.attachments.data[0].media_type !== "link") {
        postObject.img = post.attachments.data[0].media.image.src;
        postObject.type = post.attachments.data[0].media_type || "status";
        postObject.description = post.attachments.data[0].description || "";
      }
      if (post.message) postObject.text = post.message.substr(0, 40);
      postObject.platform = 1;
      postObject.id = post.id;
      postObject.createdTime = moment(post.created_time).format("ll");
      if (postObject.likes) {
        postObject.likes = post.likes.summary.total_count;
      } else {
        postObject.likes = 0;
      }
      if (post.comments) {
        postObject.comments = post.comments.summary.total_count;
      } else {
        postObject.comments = 0;
      }

      if (post.url) postObject.url = post.url;
      if (post.call_to_action) postObject.call_to_action = post.call_to_action;
      // console.log('post object', postObject);
      return (postsList = [...postsList, postObject]);
      // }
    });
    return postsList;
  };

  const showCreateCardSteps = (selectedPost) => {
    setCardsStepPost((data) => ({ ...data, ...selectedPost }));
  };

  const deleteFbCard = (id) => {
    const allCards = cardsToShow;
    const filteredCards = allCards.filter((card) => card.id !== id);
    setCardsToShow(filteredCards);
  };

  const editFbCard = (id) => {
    setRemoveCardAtEdit(id);
    storeCardToEditData(id);
    let count = 0;
    const allCards = cardsToShow;
    for (let i = 0; i < allCards.length; i++) {
      count += 1;
      if (allCards[i].id === id) break;
    }
    setEditCardPosition(count - 1);
    setIsPopupVisible(true);
  };

  const storeCardToEditData = (id) => {
    const data = { ...dataToSubmit };
    const object = {};
    // object.id = id;
    object.url = data[id].url;
    object.cta = data[id].cta;
    object.audiences = data[id].audiences;
    // setCardsStepPost(data => ({ ...data, ...object }));
    setCardToEditData(object);
  };

  const editCardData = (id, name, data) => {
    setDataToSubmit((dataToSubmit) => {
      return { ...dataToSubmit, [id]: { ...dataToSubmit[id], [name]: data } };
    });
  };

  const setFinalBudgetPercentages = (object) => {
    setBudgetPercentages(object);
  };

  const validatePosts = () => {
    let errorObj = {};
    const data = { ...dataToSubmit };
    Object.values(data).map((post) => {
      const id = post.post_id;
      const idObject = {};
      // if (post.url && !validator.isURL(post.url))
      //   idObject.url = "Please enter a valid url";
      // if (!post.url) idObject.url = "Please enter a url";
      if (!post.audiences)
        idObject.audiences = t("campaing_selectSomeAudience");
      else if (!post.audiences.length)
        idObject.audiences = t("campaing_selectSomeAudience");
      // if (!post.cta) idObject.cta = "Please select a CTA";
      errorObj = { ...errorObj, [id]: idObject };
    });
    setValidationErrors((errors) => ({ ...errors, ...errorObj }));
    let dummyArray = [];
    Object.values(errorObj).map((val) => {
      dummyArray = [...dummyArray, ...Object.values(val)];
    });
    if (dummyArray.length === 0) return true;
    else return false;
  };

  // when volume changes of any of the audience set total volume state

  // show image card from the uploaded file
  const showImageCard = (array) => {
    array.map((image, i) => {
      if (image["image"]) {
        const newArray = [
          ...imageCards,
          {
            file_object: image.image,
            unique_id: Math.floor(Math.random() * 1000),
            path: "",
            headline: "",
            description: "",
            message: "",
            cta: null,
            final_url: campaignData.promoted_url,
            ids: null,
            audiences: null,
            width: image.width ? image.width : "",
            height: image.height ? image.height : "",
            widthRatio: image.widthRatio ? image.widthRatio : "",
            heightRatio: image.heightRatio ? image.heightRatio : "",
            isCanva: image.isCanva ? image.isCanva : false,
          },
        ];
        setImageCards(newArray);
      }
    });
  };

  // add or edit an image card
  const changeImageCardArray = (index, object) => {
    // TODO
    const array = [...imageCards];
    array.splice(index, 1, object);
    setImageCards(array);
  };

  // Handle Update Carousel Individual data

  const updateCarouselIndividualData = (id, object) => {
    let array = [];
    array = [...carouselCards];
    console.log(" ====== previous array =====", array);

    let newObj = array.find((r) => r.unique_id === id);
    console.log(" ======new Obj =====", newObj);

    let newIndex = array.findIndex((r) => r.unique_id === id);
    let internalFileIndex = newObj.carousel_sources.findIndex(
      (r) => r.unique_id === object.unique_id
    );
    newObj.carousel_sources.splice(internalFileIndex, 1, object);
    array.splice(newIndex, 1, newObj);
    // console.log("hhhhhhhhhhhhh", array);

    setCarouselCards(array);
  };

  const updateCarouselCtaData = (object) => {
    let array = [];
    array = [...carouselCards];
    array.map((arr) => {
      arr.carousel_sources.map((carousel) => {
        carousel.cta = object;
      });
    });

    setCarouselCards(array);
  };

  //delete an image card
  const deleteImageCard = (index) => {
    const array = [...imageCards];
    array.splice(index, 1);
    setImageCards(array);
  };

  //delete an image card
  const deleteCarousalCard = (index) => {
    const array = [...carouselCards];
    array.splice(index, 1);
    setCarouselCards(array);
  };

  // Get the paths for carousel ads when user clicks on submit
  const getPathForCarouselAds = () => {
    let newParentArray = [];
    // let newFinalArray = []
    let isAllCarouselPath = [];
    carouselCards.map((carousel, i) => {
      isAllCarouselPath[i] = false;
      let parentId = carousel.unique_id;
      let newArray = [];
      let internalCarouselPath = [];

      newArray = [...carousel.carousel_sources];

      carousel.carousel_sources.map((data, j) => {
        if (data.type === 1) {
          internalCarouselPath = [
            ...internalCarouselPath,
            data.path ? true : false,
          ];
          if (data.path.length === 0) {
            const imageData = new FormData();
            imageData.append("file_object", data.file_object);
            onImageUpload(imageData)
              .then((res) => {
                internalCarouselPath[j] = true;
                if (res.data.status) {
                  let newObj = {
                    ...data,
                    file_object: null,
                    path: res.data.path,
                  };
                  newArray.splice(j, 1, newObj);
                  if (
                    internalCarouselPath.length ===
                      carousel.carousel_sources.length &&
                    !internalCarouselPath.includes(false)
                  ) {
                    isAllCarouselPath[i] = true;
                    let newParentObj = {
                      ...carousel,
                      carousel_sources: newArray,
                    };
                    newParentArray.splice(parentId, 0, newParentObj);
                    if (
                      isAllCarouselPath.length === carouselCards.length &&
                      !isAllCarouselPath.includes(false)
                    ) {
                      // let newObjArray = [...carouselCards];
                      // newParentArray.splice(parentId, 0, newParentObj);
                      setCarouselCards(newParentArray);
                      setGotCarouselAdsPaths(true);
                    }
                  }
                  const campaign_id = getCampaignId();
                  window.$peasy.triggerEvent({
                    event_name: "creativeUploaded",
                    event_params: {
                      campaign_id: campaign_id,
                    },
                  });
                }
              })
              .catch((err) => {
                setSaveLoading(false);
                setPublishLoading(false);
                if (err.response.data.message)
                  return toast.error(err.response.data.message);
                else toast.error(t("campaign_errorUploadingCarouselAd"));
              });
          } else {
            let newObj = { ...data };
            newArray.splice(j, 1, newObj);
            if (
              internalCarouselPath.length ===
                carousel.carousel_sources.length &&
              !internalCarouselPath.includes(false)
            ) {
              isAllCarouselPath[i] = true;
              let newParentObj = { ...carousel, carousel_sources: newArray };
              newParentArray.splice(parentId, 0, newParentObj);
              if (
                isAllCarouselPath.length === carouselCards.length &&
                !isAllCarouselPath.includes(false)
              ) {
                // let newObjArray = [...carouselCards];
                // newParentArray.splice(parentId, 0, newParentObj);
                setCarouselCards(newParentArray);
                setGotCarouselAdsPaths(true);
              }
            }
          }
        } else {
          internalCarouselPath = [
            ...internalCarouselPath,
            data.path ? true : false,
          ];
          let videoObj = { ...data };

          if (data.path.length === 0) {
            let thumbnailUrl = "";
            const videoData = new FormData();
            videoData.append("file_object", data.thumbnail_object);
            onImageUpload(videoData)
              .then((res) => {
                if (res.data.status) {
                  videoObj = {
                    ...data,
                    thumbnail_object: null,
                    thumbnail: res.data.path,
                  };
                  thumbnailUrl = res.data.path;

                  // newArray.splice(j, 1, videoObj);

                  // if (
                  //   internalCarouselPath.length === carousel.files.length &&
                  //   !internalCarouselPath.includes(false)
                  // ) {
                  //   isAllCarouselPath[i] = true;
                  //   let newParentObj = { ...carousel, files: newArray };
                  //   if (
                  //     isAllCarouselPath.length === carouselCards.length &&
                  //     !isAllCarouselPath.includes(false)
                  //   ) {
                  //     let newObjArray = [...carouselCards];
                  //     newObjArray.splice(parentId, 1, newParentObj);
                  //     setCarouselCards(newObjArray);
                  //   }
                  // }
                  // const isVedioCardValidated = vedioCardValidation(videoObj);
                  // if (isVedioCardValidated[0] === false) {
                  //   areAllValidated = false;
                  //   videoObj = {
                  //     ...videoObj,
                  //     errors: isVedioCardValidated[1]
                  //   };
                  // }
                  // if (isVedioCardValidated[0] === true) {
                  //   videoObj = { ...videoObj, errors: {} };
                  // }

                  const campaign_id = getCampaignId();
                  window.$peasy.triggerEvent({
                    event_name: "creativeUploaded",
                    event_params: {
                      campaign_id: campaign_id,
                    },
                  });
                }
                return true;
              })
              .then((bool) => {
                if (data.path.length === 0) {
                  const videoData = new FormData();
                  videoData.append("file_object", data.file_object);
                  onVideoUpload(videoData).then((res) => {
                    internalCarouselPath[j] = true;
                    if (res.data.status) {
                      videoObj = {
                        ...data,
                        file_object: null,
                        path: res.data.path,
                        thumbnail: thumbnailUrl,
                      };
                      newArray.splice(j, 1, videoObj);

                      if (
                        internalCarouselPath.length ===
                          carousel.carousel_sources.length &&
                        !internalCarouselPath.includes(false)
                      ) {
                        isAllCarouselPath[i] = true;
                        let newParentObj = {
                          ...carousel,
                          carousel_sources: newArray,
                        };
                        newParentArray.splice(parentId, 0, newParentObj);
                        if (
                          isAllCarouselPath.length === carouselCards.length &&
                          !isAllCarouselPath.includes(false)
                        ) {
                          // let newObjArray = [...carouselCards];
                          // newParentArray.splice(parentId, 0, newParentObj);
                          setCarouselCards(newParentArray);
                          setGotCarouselAdsPaths(true);
                        }
                      }

                      // setVideoCards(newArray);
                      // if (areAllValidated) {
                      //   !isAllVideosPath.includes(false) &&
                      //     setGotVideoAdsPaths(true);
                      // } else {
                      //   toast.warn(
                      //     "Please complete your Vedio ad(s) before saving"
                      //   );
                      //   setSaveLoading(false);
                      //   setPublishLoading(false);
                      // }
                      // }

                      const campaign_id = getCampaignId();
                      window.$peasy.triggerEvent({
                        event_name: "creativeUploaded",
                        event_params: {
                          campaign_id: campaign_id,
                        },
                      });
                    }
                  });
                }
              })
              .catch((err) => {
                setSaveLoading(false);
                toast.error(err.response.data.message);
              });
          } else {
            let newObj = { ...data };
            // let isVedioCardValidated = vedioCardValidation(videoObj);

            // if (isVedioCardValidated[0] === false) {
            //   areAllValidated = false;
            //   newObj = { ...newObj, errors: isVedioCardValidated[1] };
            // }

            // if (isVedioCardValidated[0] === true) {
            //   newObj = { ...newObj, errors: {} };
            // }

            newArray.splice(j, 1, newObj);

            if (
              internalCarouselPath.length ===
                carousel.carousel_sources.length &&
              !internalCarouselPath.includes(false)
            ) {
              isAllCarouselPath[i] = true;
              let newParentObj = { ...carousel, carousel_sources: newArray };
              newParentArray.splice(parentId, 0, newParentObj);
              if (
                isAllCarouselPath.length === carouselCards.length &&
                !isAllCarouselPath.includes(false)
              ) {
                // let newObjArray = [...carouselCards];

                setCarouselCards(newParentArray);
                setGotCarouselAdsPaths(true);
              }
            }
            // if (index === videoCards.length - 1) {
            // console.log("bbbbbbbbbbbbbbbbb", newArray);

            // setVideoCards(newArray);
            // if (areAllValidated) {
            //   !isAllVideosPath.includes(false) && setGotVideoAdsPaths(true);
            // } else {
            //   toast.warn("Please complete your video ad(s) before saving");
            //   setSaveLoading(false);
            //   setPublishLoading(false);
            // }
            // }
          }
        }
      });
    });
  };

  //get the paths for image ads when a user clicks on submit
  const getPathsForImageAds = () => {
    let newArray = [];

    let isAllImagesPath = [];
    imageCards.map((image) => {
      isAllImagesPath = [...isAllImagesPath, image.path ? true : false];
    });

    let areAllValidated = true;
    imageCards.map((image, index) => {
      if (image.path.length === 0) {
        const imageData = new FormData();
        imageData.append("file_object", image.file_object);
        onImageUpload(imageData)
          .then((res) => {
            isAllImagesPath[index] = true;
            if (res.data.status) {
              let newObj = { ...image, file_object: null, path: res.data.path };
              const isImageCardValidated = imageCardValidation(newObj);
              if (isImageCardValidated[0] === false) {
                areAllValidated = false;
                newObj = { ...newObj, errors: isImageCardValidated[1] };
              }

              if (isImageCardValidated[0] === true) {
                newObj = { ...newObj, errors: {} };
              }

              newArray.splice(index, 0, newObj);
              // if (index === imageCards.length - 1) {
              setImageCards(newArray);
              if (areAllValidated) {
                console.log("inside are all validated", areAllValidated);
                console.log("is all images path", isAllImagesPath);
                !isAllImagesPath.includes(false) && setGotImageAdsPaths(true);
              } else {
                toast.warn(t("campaign_completeImageAd"));
                setSaveLoading(false);
                setPublishLoading(false);
              }
              // }
              const campaign_id = getCampaignId();
              window.$peasy.triggerEvent({
                event_name: "creativeUploaded",
                event_params: {
                  campaign_id: campaign_id,
                },
              });
            }
          })
          .catch((err) => {
            setSaveLoading(false);
            setPublishLoading(false);
            if (err.response.data.message) {
              return toast.error(err.response.data.message);
            } else return toast.error(t("campaign_errorUploadingImagePost"));
          });
      } else {
        let newObj = { ...image };
        const isImageCardValidated = imageCardValidation(newObj);

        if (isImageCardValidated[0] === false) {
          areAllValidated = false;
          newObj = { ...newObj, errors: isImageCardValidated[1] };
        }

        if (isImageCardValidated[0] === true) {
          newObj = { ...newObj, errors: {} };
        }

        newArray.splice(index, 0, newObj);
        // if (index === imageCards.length - 1) {
        setImageCards(newArray);
        console.log("are image cards validated", areAllValidated);
        if (areAllValidated) {
          console.log("is all images path", isAllImagesPath);
          !isAllImagesPath.includes(false) && setGotImageAdsPaths(true);
        } else {
          toast.warn(t("campaign_completeImageAd"));
          setSaveLoading(false);
          setPublishLoading(false);
        }
        // }
      }
    });
  };

  const imageCardValidation = (imageObj) => {
    let isImageCardValidated = true;
    const object = {};
    if (imageObj.headline.length === 0) {
      isImageCardValidated = false;
      object.headline = t("campaign_headlineCantBeEmpty");
    }
    if (imageObj.description.length === 0) {
      isImageCardValidated = false;
      object.description = t("campaign_descriptionCantBeBlank");
    }
    if (!validator.isURL(imageObj.final_url)) {
      isImageCardValidated = false;
      object.url = t("campaign_enterValidURL");
    }
    if (imageObj.final_url.length === 0) {
      isImageCardValidated = false;
      object.url = t("campaign_enterURL");
    }
    if (!imageObj.audiences || !imageObj.audiences.length) {
      isImageCardValidated = false;
      object.audiences = t("campaign_selectSomeAudiences");
    }
    if (!imageObj.cta) {
      isImageCardValidated = false;
      object.cta = t("campaign_selectCTA");
    }
    // if (imageObj.message.length === 0) {
    //   object.message = "Please enter a caption for the image card!";
    // }
    return [isImageCardValidated, object];
  };

  //show video card from the uploaded file
  const showVideoCard = (array) => {
    if (array[0].video) {
      const newArray = [
        ...videoCards,
        {
          unique_id: Math.floor(Math.random() * 1000),
          file_object: array[0].video,
          thumbnail_object: null,
          thumbnail_path: "",
          path: "",
          headline: "",
          description: "",
          message: "",
          cta: null,
          final_url: campaignData.promoted_url,
          ids: null,
          audiences: null,
          duration: array[0].duration ? array[0].duration : "",
        },
      ];
      setVideoCards(newArray);
    }
  };

  //edit video card array
  const changeVideoCardArray = (index, object) => {
    //console.log("obj objp obj", object)
    const array = [...videoCards];
    array.splice(index, 1, object);
    setVideoCards(array);
  };

  const dataFromVideoCard = (index, object) => {
    //console.log("obj objp obj", object);
    //console.log("before", videoCards)
    const array = [...videoCards];
    array.splice(index, 1, object);
    // const array = [{ ...videoCards, thumbnail_object: object.thumbnail_object }];
    //console.log("after array", array);

    setVideoCards(array);
  };

  // Change carousal array when click on edit
  const changeCarousalArray = (selectedCarouselId, newCarouselData) => {
    let newArray = [];
    newArray = [...carouselCards];
    let carouselEditObj = newArray.find(
      (r) => r.unique_id === selectedCarouselId
    );
    carouselEditObj = { ...carouselEditObj, carousel_sources: newCarouselData };

    let carouselEditIndex = newArray.findIndex(
      (r) => r.unique_id === selectedCarouselId
    );

    console.log(" indexxx@@@@@@@@@@@@@@@@@@@", carouselEditObj);

    newArray.splice(carouselEditIndex, 1, carouselEditObj);
    setCarouselCards(newArray);
  };

  const dataFromCarouselCard = (index, object) => {
    //console.log("obj objp obj", object);
    //console.log("before", videoCards)
    const array = [...carouselCards];

    let arrayObj = array[index];

    let internalMediaIndex = arrayObj.carousel_sources.findIndex(
      (r) => r.unique_id === object.unique_id
    );

    arrayObj.carousel_sources.splice(internalMediaIndex, 1, object);

    array.splice(index, 1, arrayObj);

    // const array = [{ ...videoCards, thumbnail_object: object.thumbnail_object }];
    //console.log("after array", array);

    setCarouselCards(array);
  };

  //delete a card of video
  const deleteVideoCard = (index) => {
    const array = [...videoCards];
    array.splice(index, 1);
    setVideoCards(array);
  };

  //get the paths for video ads when a user clicks on submit
  const getPathsForVideoAds = () => {
    let newArray = [];

    let isAllVideosPath = [];
    videoCards.map((video) => {
      isAllVideosPath = [...isAllVideosPath, video.path ? true : false];
    });

    videoCards.map((video, index) => {
      let videoObj = { ...video, audiences: [...new Set(video.audiences)] };

      let areAllValidated = true;
      if (videoObj.thumbnail_path.length === 0) {
        const videoData = new FormData();
        videoData.append("file_object", videoObj.thumbnail_object);
        onImageUpload(videoData)
          .then((res) => {
            if (res.data.status) {
              videoObj = {
                ...videoObj,
                thumbnail_object: null,
                thumbnail_path: res.data.path,
              };
              const isVedioCardValidated = vedioCardValidation(videoObj);
              if (isVedioCardValidated[0] === false) {
                areAllValidated = false;
                videoObj = { ...videoObj, errors: isVedioCardValidated[1] };
              }
              if (isVedioCardValidated[0] === true) {
                videoObj = { ...videoObj, errors: {} };
              }
              const campaign_id = getCampaignId();
              window.$peasy.triggerEvent({
                event_name: "creativeUploaded",
                event_params: {
                  campaign_id: campaign_id,
                },
              });
            }
            return true;
          })
          .then((bool) => {
            if (videoObj.path.length === 0) {
              const videoData = new FormData();
              videoData.append("file_object", videoObj.file_object);
              onVideoUpload(videoData)
                .then((res) => {
                  isAllVideosPath[index] = true;
                  if (res.data.status) {
                    videoObj = {
                      ...videoObj,
                      file_object: null,
                      path: res.data.path,
                    };
                    newArray.splice(index, 0, videoObj);

                    setVideoCards(newArray);
                    if (areAllValidated) {
                      !isAllVideosPath.includes(false) &&
                        setGotVideoAdsPaths(true);
                    } else {
                      toast.warn(t("campaign_completeYourVideoAd"));
                      setSaveLoading(false);
                      setPublishLoading(false);
                    }
                    // }
                    const campaign_id = getCampaignId();
                    window.$peasy.triggerEvent({
                      event_name: "creativeUploaded",
                      event_params: {
                        campaign_id: campaign_id,
                      },
                    });
                  }
                })
                .catch((err) => {
                  setSaveLoading(false);
                  setPublishLoading(false);
                  if (err.response.data.message) {
                    return toast.error(err.response.data.message);
                  } else return toast.error(t("campaign_errorUploadingVideo"));
                });
            }
          })
          .catch((err) => {
            setSaveLoading(false);
            setPublishLoading(false);
            if (err.response.data.message) {
              return toast.error(err.response.data.message);
            } else return toast.error(t("campaign_errorUploadingVideo"));
          });
      } else {
        let newObj = { ...video };
        let isVedioCardValidated = vedioCardValidation(videoObj);

        if (isVedioCardValidated[0] === false) {
          areAllValidated = false;
          newObj = { ...newObj, errors: isVedioCardValidated[1] };
        }

        if (isVedioCardValidated[0] === true) {
          newObj = { ...newObj, errors: {} };
        }

        newArray.splice(index, 0, newObj);
        // if (index === videoCards.length - 1) {
        // console.log("bbbbbbbbbbbbbbbbb", newArray);

        setVideoCards(newArray);
        if (areAllValidated) {
          !isAllVideosPath.includes(false) && setGotVideoAdsPaths(true);
        } else {
          toast.warn(t("campaign_completeYourVideoAd"));
          setSaveLoading(false);
          setPublishLoading(false);
        }
        // }
      }
    });
  };

  const validationChecker = () => {
    let videoChecker = false;
    let imageChecker = false;
    let postChecker = false;
    let carouselChecker = false;

    // Carousel card validation checker
    if (carouselCards.length) {
      carouselCards.map((carousel) => {
        console.log("a carousel card inside validation check", carousel);
        if (
          !carousel.headline ||
          carousel.headline.length === 0
          // ||
          // carousel.audiences.length === 0
        ) {
          carouselChecker = true;
        }

        if (carousel.carousel_sources.length) {
          carousel.carousel_sources.map((data) => {
            if (
              data.description.length === 0 ||
              data.cta.length === 0 ||
              data.message.length === 0
            ) {
              carouselChecker = true;
            }
          });
        }
      });
    }

    // Handle VideoCard validation checker
    if (videoCards.length) {
      videoCards.map((video) => {
        if (
          video.headline.length === 0 ||
          video.description.length === 0 ||
          // !video.audiences ||
          // !video.audiences.length ||
          !video.cta ||
          video.message.length === 0
        ) {
          videoChecker = true;
        }
      });
    }

    // Handle Image validation checker
    if (imageCards.length) {
      imageCards.map((image) => {
        if (
          image.headline.length === 0 ||
          image.description.length === 0 ||
          image.message.length === 0 ||
          // !image.audiences ||
          // !image.audiences.length ||
          !image.cta
          //  || image.message.length === 0
        ) {
          imageChecker = true;
        }
      });
    }

    // Post add card validation checker
    if (cardsToShow.length) {
      let postDataSubmit = Object.keys(dataToSubmit);
      if (
        postDataSubmit.length &&
        postDataSubmit.length === cardsToShow.length
      ) {
        postDataSubmit.map((data, i) => {
          if (
            dataToSubmit[data].audiences.length === 0 ||
            dataToSubmit[data].cta.length === 0 ||
            dataToSubmit[data].url.length === 0
          ) {
            postChecker = true;
          }
        });
      } else {
        postChecker = true;
      }
    }

    // Handle permutations and combination for all cards
    if (
      imageCards.length === 0 &&
      videoCards.length === 0 &&
      cardsToShow.length === 0 &&
      carouselCards.length === 0
    ) {
      return true;
    } else {
      if (postChecker || videoChecker || imageChecker || carouselChecker) {
        return true;
      } else {
        return false;
      }
    }
  };

  const vedioCardValidation = (vedioObj) => {
    let isVedioCardValidated = true;
    const object = {};
    if (vedioObj.headline.length === 0) {
      isVedioCardValidated = false;
      object.headline = t("campaign_headlineCantBeEmpty");
    }
    if (vedioObj.description.length === 0) {
      isVedioCardValidated = false;
      object.description = t("campaign_descriptionCantBeBlank");
    }
    if (!validator.isURL(vedioObj.final_url)) {
      isVedioCardValidated = false;
      object.url = t("campaign_enterValidURL");
    }
    if (vedioObj.final_url.length === 0) {
      isVedioCardValidated = false;
      object.url = t("campaign_enterURL");
    }
    if (!vedioObj.audiences || !vedioObj.audiences.length) {
      isVedioCardValidated = false;
      object.audiences = t("campaign_selectSomeAudiences");
    }
    if (!vedioObj.cta) {
      isVedioCardValidated = false;
      object.cta = t("campaign_selectCTA");
    }
    if (vedioObj.message.length === 0) {
      object.message = t("campaign_enterCaptionForImageCard");
    }
    return [isVedioCardValidated, object];
  };

  //showCarouselCard from uploaded file
  const showCarouselCard = (array) => {
    let fileData = [];
    array[0].map((carosel, i) => {
      if (carosel.image) {
        fileData = [
          ...fileData,
          {
            index: i,
            file_object: carosel.image,
            unique_id: Math.floor(Math.random() * 1000),
            path: "",
            message: "",
            cta: "",
            description: "",
            final_url: campaignData.promoted_url,
            type: carosel.type,
            order: carosel.order,
            ids: null,
            thumbnail: "",
            width: carosel.width ? carosel.width : "",
            height: carosel.height ? carosel.height : "",
            widthRatio: carosel.widthRatio ? carosel.widthRatio : "",
            heightRatio: carosel.heightRatio ? carosel.heightRatio : "",
          },
        ];
      } else {
        fileData = [
          ...fileData,
          {
            index: i,
            unique_id: Math.floor(Math.random() * 1000),
            file_object: carosel.video,
            thumbnail_object: null,
            thumbnail_path: "",
            path: "",
            message: "",
            cta: "",
            description: "",
            final_url: campaignData.promoted_url,
            type: carosel.type,
            order: carosel.order,
            ids: null,
            thumbnail: "",
            duration: carosel.duration ? carosel.duration : "",
          },
        ];
      }
    });
    const newArray = [
      ...carouselCards,
      {
        carousel_group_id: "",
        unique_id: Math.floor(Math.random() * 1000),
        carousel_sources: fileData,
        headline: "",
        description: "",
        message: "",
        ids: null,
        audiences: [],
      },
    ];

    setCarouselCards(newArray);
  };

  // Handle Update carousel card

  const updateCarouselInternalData = (array, carouselId) => {
    // Find object of updated carousel media object
    let newObj = carouselCards.find((car) => car.unique_id === carouselId);
    if (newObj.carousel_sources.length + array[0].length > 10) {
      toast.error(t("campaign_cantUploadMoreThanTenMediaFiles"));
    } else {
      let newArray = [];
      newArray = [...carouselCards];
      let fileData = [];
      array[0].map((carosel, i) => {
        if (carosel.image) {
          fileData = [
            ...fileData,
            {
              index: i,
              file_object: carosel.image,
              unique_id: Math.floor(Math.random() * 1000),
              path: "",
              message: "",
              cta: "",
              description: "",
              final_url: campaignData.promoted_url,
              type: carosel.type,
              order: carosel.order,
              ids: null,
              thumbnail: "",
            },
          ];
        } else {
          fileData = [
            ...fileData,
            {
              index: i,
              unique_id: Math.floor(Math.random() * 1000),
              file_object: carosel.video,
              thumbnail_object: null,
              thumbnail_path: "",
              path: "",
              message: "",
              cta: "",
              description: "",
              final_url: campaignData.promoted_url,
              type: carosel.type,
              order: carosel.order,
              ids: null,
              thumbnail: "",
            },
          ];
        }
      });

      // Find index of update carousel media object
      let mediaIndexValue = carouselCards.findIndex(
        (car) => car.unique_id === carouselId
      );

      newObj.carousel_sources = [...newObj.carousel_sources, ...fileData];

      // Replace old object with updated one
      newArray.splice(mediaIndexValue, 1, newObj);
      // Updated original array
      setCarouselCards(newArray);
    }
  };

  // Handle Update main data
  const updateMainCarouselData = (index, object) => {
    // TODO
    const array = [...carouselCards];
    array.splice(index, 1, object);
    setCarouselCards(array);
  };

  const checkBalanceAvailable = () => {
    const campaign_id = getCampaignId();
    onGetUserBalance({
      campaign_id: campaign_id,
    })
      .then((res) => {
        if (res.data.status == true) {
          setPublishLoading(false);
          if (
            JSON.parse(localStorage.getItem("user_data")).account_type ===
            "prepaid"
          ) {
            setDeploymentLoadingMsg(t("campaign_deploymentLoadingMsg_prepaid"));
          } else {
            setDeploymentLoadingMsg(t("campaign_deploymentLoadingMsg"));
          }
          setShowDeploymentPopup(true);
        } else {
          setShowTopupPopup(true);
          setTopupAmount(Math.ceil(res.data.data.total_amount));
          setPrepaidTopup(res.data.data);
          setOtherAmount(Math.ceil(res.data.data.order_amount));
        }
      })
      .catch((err) => {
        setSaveLoading(false);
        setPublishLoading(false);
      });
  };

  //save posts only after we have got paths for all the posts
  const savePosts = () => {
    //console.log("this s from save", imageCards);
    //console.log("this video cards", videoCards);
    setGotVideoAdsPaths(false);
    setGotImageAdsPaths(false);
    setGotCarouselAdsPaths(false);
    setGotPostAds(false);
    let CarouselDataArray = [];

    let newCarouselArray = [...carouselCards];
    newCarouselArray.map((data) => {
      data.carousel_sources.map((value) => {
        if (value.path !== "") {
          CarouselDataArray = [...CarouselDataArray, data];
        }
      });
    });

    const campaign_id = getCampaignId();
    onSavePosts(campaign_id, {
      image_ads: imageCards,
      video_ads: videoCards,
      carousel_ads: carouselCards,
      post_ads: Object.values(dataToSubmit),
    })
      .then((res) => {
        if (res.data.data) {
          getMedia();

          setSaveLoading(false);
          if (!publishLoading) return toast.success(t("campaign_postsSaved"));
          else {
            checkBalanceAvailable();
          }
        } else if (!res.data.data) {
          setSaveLoading(false);
          setPublishLoading(false);
          return toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setSaveLoading(false);
        setPublishLoading(false);
      });
  };

  const onSaveClick = (doesDeploy) => {
    if (!doesDeploy) setSaveLoading(true);
    if (doesDeploy) {
      setPublishLoading(true);
      setShouldDeploy(true);
    }

    setGotPostAds(true);
    getPathsForImageAds();
    getPathsForVideoAds();
    getPathForCarouselAds();
  };

  // Handle update carousel data
  const updateCarouselData = (updatedData, index) => {
    let newArray = [];
    newArray = [...carouselCards];
    let replaceIndex = newArray.findIndex((r) => r.unique_id === index);
    newArray.splice(replaceIndex, 1, updatedData);
    setCarouselCards(newArray);
  };

  const getUpatemediaCreatives = () => {
    console.log(" ====== Call this funcrion");
  };

  const isBudgetDivideEqually = (isBudget) => {
    setBudgetError(isBudget);
  };

  // const redirect = () => {
  //   setTimeout(() => {
  //     <Redirect to='/session/auth/connection' />
  //   }, 10000);
  // }

  const deployCampaign = (campaign_id) => {
    setDeploymentLoading(true);
    //const campaign_id = getCampaignId();
    onDeployCampaign({
      params: {
        campaign_id,
      },
    })
      .then((res) => {
        console.log("response from yes", res);
        if (res.status == 200) {
          setDeploymentLoading(false);
          setShowDeploymentPopup(false);
          return toast.success(t("campaign_deployedSuccessfully"));
        }
      })
      .catch((err) => {
        setDeploymentLoading(false);
        setShowDeploymentPopup(true);
        if (err.response.status == 422) {
          setDeploymentLoadingMsg(t("campaign_connectSocialMedia"));
          setRedirectAction(true);
        }
        return toast.error(err.response.data.message);
      });
  };
  // Extracting selected audience
  let audienceAssign = [];
  let tilesAudience = [];

  // Extract audience from video
  videoCards.length &&
    videoCards.map((video) => {
      if (video.audiences) {
        tilesAudience = [...tilesAudience, ...video.audiences];
      }
    });

  // Extract audience from image
  imageCards.length &&
    imageCards.map((image) => {
      if (image.audiences) {
        tilesAudience = [...tilesAudience, ...image.audiences];
      }
    });

  // Extract audience from post card
  cardsToShow.length &&
    Object.keys(dataToSubmit).length &&
    Object.keys(dataToSubmit).map((data) => {
      tilesAudience = [...tilesAudience, ...dataToSubmit[data].audiences];
    });

  // Extract audience from carousel card
  carouselCards.length &&
    carouselCards.map((carousel) => {
      if (carousel.audiences) {
        tilesAudience = [...tilesAudience, ...carousel.audiences];
      }
    });

  // extract unique selected audience
  audienceAssign = [...new Set(tilesAudience)];
  let apiAudience = [];
  audiences &&
    audiences.length &&
    audiences.map((aud) => {
      apiAudience = [...apiAudience, aud.id];
    });
  useEffect(() => {
    // console.log("apiAudience chnaged");
  }, [apiAudience]);
  // Checking if current available audience is less than assigned audience
  let assignedAudienceArray = [];

  if (apiAudience.length < audienceAssign.length) {
    assignedAudienceArray = apiAudience;
  } else {
    assignedAudienceArray = apiAudience;
  }

  // Comparing the selected audience in all tiles is equal to total available audience
  // let isAllAudienceAssigned =
  //   apiAudience.length === audienceAssign.length &&
  //   apiAudience.sort().every(function (value, index) {
  //     return value === audienceAssign.sort()[index];
  //   });

  let isAllAudienceAssigned = apiAudience.every((aud) => {
    return audienceAssign.includes(aud);
  });

  const isPredectionError = () => {
    Error(budgetPredictionError.error, budgetPredictionError.section);
  };

  const topupSuccessCallback = () => {
    //toast success
    toast.success(t("topup_success"));
    setStartStripePayment(false);
    setTimeout(() => {
      checkBalanceAvailable();
      setPublishLoading(false);
    }, 3000);
  };
  const topupFailureCallback = () => {
    //failure toast
    toast.error(t("topup_failure"));
    setStartStripePayment(false);
    setPublishLoading(false);
  };

  // api to get recommended audience
  const recommendAudience = async () => {
    setRecommendAudienceLoading(true);
    try {
      const campaign_id = getCampaignId();

      const res = await onAudienceRecommendation(campaign_id);
      if (res.status === 200) {
        console.log("success toast should be called without message");
        toast.success(t("campaign_recommendAudienceAddedSuccessfully"));
        setRecommendAudienceLoading(false);
        setShowRecommendAudiencePopup(false);
        getAudienceList();
        getMedia();
      } else throw Error;
    } catch (err) {
      setRecommendAudienceLoading(false);
      setShowRecommendAudiencePopup(false);
      if (err.response.data.message) {
        console.log("error toast should be called with message");
        return toast.error(err.response.data.message);
      } else {
        console.log("error toast should be called without message");
        toast.error(t("campaign_errorRecommendingAudience"));
      }
    }
  };

  const getPostMetricData = () => {
    // TODO
    let paramJson = {
      startDate: moment().subtract(30, "days"),
      endDate: moment(),
      campaign_id: getCampaignId(),
    };
    let paramString =
      "?campaign_id=" +
      paramJson.campaign_id +
      "&start_date=" +
      moment(paramJson.startDate).format("YYYY-MM-DD") +
      "&end_date=" +
      moment(paramJson.endDate).format("YYYY-MM-DD") +
      "&all_data=false";

    http
      .get(`${BASE_URL}${GET_CAMPAIGNMETRIC_URL}${paramString}`)
      .then((res) => {
        const { status, data } = res;
        console.log("setCampaignMetricData =>", data);
        setCampaignMetricData(res);
      })
      .catch((err) => {
        let res = {
          data: {
            chart_data: [],
            cpa_comparison_data: [],
          },
        };
        setCampaignMetricData(res);
      });
  };

  const getObjective = (data) => {
    if (data) {
      if (data.objective === "POST_ENGAGEMENT") return "ENGAGEMENT";
      else return data.objective;
    } else return "";
  };
  const showAudienceNotSelectedError = () => {
    let array;
    array = audiences
      .filter((aud) => {
        if (!audienceAssign.includes(aud.id)) {
          return true;
        }
      })
      .map((aud) => {
        return aud.name;
      });
    setUnassignedAudiences(array);
    setOpen(true);
  };

  const runAudienceMerge = async (value) => {
    try {
      const payload = {
        campaign_id: currentCampaignId,
        run: value,
      };
      const res = await onAudienceOverlap(payload);
      if (value === false && res) {
        setMergeAudienceDialogOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const DialogDiv = () => {
    return (
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {t("campaign_opps")}
          </DialogTitle>
          <DialogContent>
            <div className="mb-2">
              <img
                style={{ width: "100%" }}
                src="/images/assets/creative-audience.png"
                alt=""
              />
              <hr />
            </div>
            <Typography className="text-center mb-2">
              {t("campaign_cantBePublishedText")}
            </Typography>
            <div id="alert-dialog-description" align="justify" className="p-2">
              <ol className="mb-4">
                {unAssignedAudiences.map((aud, index) => {
                  return (
                    <li
                      key={index}
                      className="mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      {aud}
                    </li>
                  );
                })}
              </ol>

              {campaignData &&
                (campaignData.objective === "REACH" ||
                  (campaignData.objective === "CLICK" && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <img
                          style={{ width: "100%" }}
                          src="/images/assets/channel.png"
                          alt=""
                        />
                      </Grid>
                      <Grid item xs={6} style={{ fontSize: "11px" }}>
                        *{t("campaign_sudienceSegmentText")}
                      </Grid>
                    </Grid>
                  )))}
              {}
            </div>

            <button
              className="brandoverviewleftsection__btn--purple full-width-button"
              color="primary"
              onClick={() => {
                setOpen(false);
              }}
            >
              {t("campaign_okay")}
            </button>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  const getBudgetPercent = (data) => {
    if (nextCampaignId && campaignData && nextCampaignData) {
      return Math.round(
        (data.budget * 100) / (campaignData.budget + nextCampaignData.budget)
      );
    } else return "100";
  };

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const delayedHeightChange = useCallback(
    debounce(() => updateHeight(), 500),
    []
  );

  useLayoutEffect(() => {
    window.addEventListener("resize", delayedHeightChange);
    return () => window.removeEventListener("resize", delayedHeightChange);
  }, []);

  const updateHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  const pauseCampaign = async (id) => {
    try {
      const res = await onPauseCampaign({
        params: {
          campaign_id: id,
        },
      });

      if (res.status === 200) {
        setCampaignData({
          ...campaignData,
          status:"PAUSED"
        })
        toast.success(t("dashboard_campaignPaused"));
        // getBrandCampaigns();
      } else {
        throw new Error("ERROR")
      };
    } catch (err) {
      if (err.response.data.message)
        return toast.error(err.response.data.message);
      else toast.error(t("dashboard_couldntPausedCampaign"));
    }
  };

  const CAMPAIGN_STATUSES = {
    PAUSED: "PAUSED",
    ACTIVE: "ACTIVE",
    DELETED: "DELETED",
    IN_REVIEW: "IN_REVIEW",
  }
  const showSavePublishBtn = () =>{
    if(currentUser?.account_type === "prepaid"){
      if(campaignData?.status === CAMPAIGN_STATUSES.PAUSED || campaignData?.status === CAMPAIGN_STATUSES.DELETED){
        return 'saveAndPublishBtn'
      }else if(campaignData?.status === CAMPAIGN_STATUSES.ACTIVE || campaignData?.status === CAMPAIGN_STATUSES.IN_REVIEW){
        return 'pauseBtn'
      }
    }
    return 'saveAndPublishBtn'
  }

  return (
    <div className="post-boost-screen">
      <Hidden smUp>
        <AppBar>
          <div className="">
            <Tabs value={tabValue} onChange={handleTabValue} className="pt-1">
              <Tab
                label={
                  <>
                    {tabValue === 0 ? (
                      <img src="/images/pb-graph-icon-active.svg" alt="" />
                    ) : (
                      <img src="/images/pb-graph-icon.svg" alt="" />
                    )}
                    <p className="tab-text">{t("postboost_tab_graph")}</p>
                  </>
                }
              />
              <Tab
                label={
                  <>
                    {tabValue === 1 ? (
                      <img
                        src="/images/pb-campaign-details-icon-active.svg"
                        alt=""
                      />
                    ) : (
                      <img src="/images/pb-campaign-details-icon.svg" alt="" />
                    )}
                    <p className="tab-text">
                      {t("postboost_tab_campaign_details")}
                    </p>
                  </>
                }
              />
              <Tab
                label={
                  <>
                    {tabValue === 2 ? (
                      <img src="/images/pb-audience-icon-active.svg" alt="" />
                    ) : (
                      <img src="/images/pb-audience-icon.svg" alt="" />
                    )}
                    <p className="tab-text">{t("postboost_tab_audiences")}</p>
                  </>
                }
              />
              <Tab
                label={
                  <>
                    {tabValue === 3 ? (
                      <img src="/images/pb-creative-icon-active.svg" alt="" />
                    ) : (
                      <img src="/images/pb-creative-icon.svg" alt="" />
                    )}
                    <p className="tab-text">{t("postboost_tab_creative")}</p>
                  </>
                }
              />
            </Tabs>
          </div>
        </AppBar>
        <div style={{ height: "50px" }}></div>
      </Hidden>

      <div className="campaign-top-bar mt-1">
        <p className="text-center campaign-bold-white d-flex justify-content-center">
          {campaignData && (
            <span>
              <span>
                {t("campaign_current")}
                {nextCampaignId && (
                  <>
                    <sup>1</sup>/<sub>2</sub>:{" "}
                  </>
                )}
              </span>
              {/* <span>
            <img src="/images/assets/campaign-icons/clicks.svg" alt="img" />
          </span> */}
              &nbsp;
              <span>{campaignData.sales_goal} </span>
              <span style={{ color: "#B4FF00" }}>
                ({getObjective(campaignData).toLowerCase()})&nbsp;
              </span>
              <span>
                {getBudgetPercent(campaignData)}% {t("campaign_budget")}{" "}
              </span>
            </span>
          )}
          {nextCampaignId && nextCampaignData && (
            <span>
              &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAudiences(null);
                  if (currentCampaignId === firstCampaignId) {
                    history.push(`/campaign/edit/${secondCampaignId}`);
                    setCampaignSwitchButtonText(t("campaign_previousCampaign"));
                    setCurrentCampaignId(secondCampaignId);
                  } else if (currentCampaignId === secondCampaignId) {
                    history.push(`/campaign/edit/${firstCampaignId}`);
                    setCampaignSwitchButtonText(t("campaign_nextCampaign"));
                    setCurrentCampaignId(firstCampaignId);
                  }
                }}
              >
                {campaignSwitchButtonText} (<sup>1</sup>/<sub>2</sub>):{" "}
              </span>
              {/* <div className="campaign-icon-img">
            <img src="/images/assets/campaign-icons/conversion.svg" alt="img" />
          </div> */}
              &nbsp;
              <span>{nextCampaignData.sales_goal} </span>
              <span style={{ color: "#B4FF00" }}>
                ({getObjective(nextCampaignData).toLowerCase()}){" "}
              </span>
              <span>
                {getBudgetPercent(nextCampaignData)}% {t("campaign_budget")}
              </span>
            </span>
          )}
        </p>
      </div>
      <hr />
      <TabPanel value={tabValue} index={0} windowWidth={windowWidth}>
        <PostBoostChartComponent
          campaignMetricData={campaignMetricData}
          predictionVolume={predictionVolume}
          isLoading={isCampaignMetricDataLoading}
          campaignException={campaignException}
        />
      </TabPanel>
      {cardsStepPost && (
        <CreateCardSteps
          post={cardsStepPost}
          pageDetails={pageDetails}
          audiences={audiences}
          setCardsToShow={setCardsToShow}
          setCardsStepPost={setCardsStepPost}
          ctaList={ctaList}
          editCardPosition={editCardPosition}
          setEditCardPosition={setEditCardPosition}
          cardsToShow={cardsToShow}
          setDataToSubmit={setDataToSubmit}
          removeCardAtEdit={removeCardAtEdit}
          // cardToEditData={cardToEditData}
        />
      )}
      {startStripePayment && (
        <PopupAlert
          heading="Payment"
          body={
            <CheckoutComponent
              toppAmount={toppAmount}
              amount={toppAmount}
              currency={userCurrency}
              successCallback={topupSuccessCallback}
              errorCallback={topupFailureCallback}
            />
          }
          showButton={false}
          onNoClick={() => {
            setStartStripePayment(false);
            setPublishLoading(false);
          }}
        />
      )}
      {showTopupPopup && (
        <PopupAlert
          heading="Top Up"
          body={
            <ToupInvoicePopUp
              topupData={prepaidTopup}
              setOtherAmount={setOtherAmount}
              otherAmount={otherAmount}
            />
          }
          onNoClick={() => {
            setShowTopupPopup(false);
            setPublishLoading(false);
          }}
          onYesClick={() => {
            if (otherAmount && otherAmount < prepaidTopup.order_amount) {
              toast.error(t("otherAmount_failure"));
              return;
            }
            if (otherAmount) {
              setTopupAmount(
                Math.ceil(
                  parseFloat(otherAmount) +
                    (otherAmount * prepaidTopup.transaction_fee) / 100
                )
              );
            }

            setStartStripePayment(true);
            setShowTopupPopup(false);
          }}
          yesText={
            deploymentLoading ? (
              <span>
                {" "}
                {t("campaign_deploying")}{" "}
                <img
                  style={{ width: "20px", height: "20px" }}
                  src="/images/assets/loading-button.gif"
                  alt=""
                />
              </span>
            ) : (
              t("continue")
            )
          }
          noText={t("campaign_cancel")}
          footer="*Tax is not included"
        />
      )}
      {showDeploymentPopup && (
        <PopupAlert
          heading={t("campaign_areYouSure")}
          body={deploymentLoadingMsg}
          onNoClick={() => setShowDeploymentPopup(false)}
          onYesClick={() => {
            if (redirectAction) {
              setTimeout(() => {
                setRedirect(true);
              }, 5000);
              return;
            }
            !deploymentLoading && deployCampaign(currentCampaignId);
          }}
          yesText={
            deploymentLoading ? (
              <span>
                {" "}
                {t("campaign_deploying")}{" "}
                <img
                  style={{ width: "20px", height: "20px" }}
                  src="/images/assets/loading-button.gif"
                  alt=""
                />
              </span>
            ) : (
              t("campaign_yes")
            )
          }
          noText={t("campaign_cancel")}
        />
      )}
      {redirect && <Redirect to="/session/auth/connection" />}
      {showRecommendAudiencePopup && (
        <PopupAlert
          heading={t("campaign_areYouSure")}
          body={t("campaign_thisWillRewriteChanges")}
          onNoClick={() => setShowRecommendAudiencePopup(false)}
          onYesClick={() => recommendAudience()}
          yesText={
            recommendAudienceLoading ? (
              <div>
                <span> {t("campaign_recommending")} </span>{" "}
                <img
                  style={{ width: "20px", height: "20px" }}
                  alt=""
                  src="/images/assets/loading-button.gif"
                />
              </div>
            ) : (
              t("campaign_yes")
            )
          }
          noText={t("campaign_cancel")}
        />
      )}
      <TabPanel value={tabValue} index={1} windowWidth={windowWidth}>
        <CampaignInfoBox
          audiences={audiences}
          campaignData={campaignData}
          getAudienceList={getAudienceList}
          getMediaDetails={getMediaDetails}
          totalVolume={totalVolume}
          predictionVolume={predictionVolume}
          eventId={eventId}
          salesGoals={campaignData && campaignData.sales_goal}
          setPredictionBudgetError={setPredictionBudgetError}
          setNotification={setNotification}
          notification={notification}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2} windowWidth={windowWidth}>
        <SelectAudiences
          getMediaDetails={getMediaDetails}
          setCampaignStep4={setCampaignStep4}
          setAudienceMergeDialogOpened={setAudienceMergeDialogOpened}
          getAudienceList={getAudienceList}
          campaignData={campaignData}
          audiences={audiences}
          setFinalBudgetPercentages={setFinalBudgetPercentages}
          setTotalVolume={setTotalVolume}
          setPredictionVolume={setPredictionVolume}
          minBudgetPercentage={minBudgetPercentage}
          myr3={myr3}
          editAudienceFields={editAudienceFields}
          isBudgetDivideEqually={isBudgetDivideEqually}
          getUpatemediaCreatives={getMedia}
          setShowRecommendAudiencePopup={setShowRecommendAudiencePopup}
          nextCampaignId={nextCampaignId}
          setCurrentCampaignId={setCurrentCampaignId}
          setNotification={setNotification}
          notification={notification}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3} windowWidth={windowWidth}>
        <AddPostCard
          showSmall={
            !!cardsToShow.length ||
            !!imageCards.length ||
            !!videoCards.length ||
            !!carouselCards.length
          }
          setIsPopupVisible={setIsPopupVisible}
          audiences={audiences}
          setNotification={setNotification}
          notification={notification}
        />
        <div className="post-boost-creative-container" ref={creativeSection}>
          {cardsToShow.length > 0 &&
            cardsToShow.map((card) => (
              <FbCard
                platform={campaignData.platform}
                editFbCard={editFbCard}
                deleteFbCard={deleteFbCard}
                pageDetails={pageDetails}
                card={card}
                key={card.id}
                audiences={audiences}
                editCardData={editCardData}
                ctaList={ctaList}
                dataToSubmit={dataToSubmit}
                setDataToSubmit={setDataToSubmit}
                validationErrors={validationErrors}
                campaignData={campaignData}
                setNotification={setNotification}
                notification={notification}
              />
            ))}

          {imageCards.length > 0 &&
            imageCards.map((image, index) => (
              <ImageCard
                platform={campaignData.platform}
                key={index + "_imageCard"}
                pageDetails={pageDetails}
                ctaList={ctaList}
                card={image}
                audiences={audiences}
                imageCardIds={imageCardIds}
                changeImageCardArray={changeImageCardArray}
                deleteImageCard={deleteImageCard}
                setNotification={setNotification}
                notification={notification}
              />
            ))}

          {videoCards.length > 0 &&
            videoCards.map((video, i) => (
              <VideoCard
                key={video.unique_id}
                i={i}
                platform={campaignData.platform}
                pageDetails={pageDetails}
                ctaList={ctaList}
                card={video}
                audiences={audiences}
                videoCardIds={videoCardIds}
                changeVideoCardArray={changeVideoCardArray}
                deleteVideoCard={deleteVideoCard}
                dataFromVideoCard={dataFromVideoCard}
                setNotification={setNotification}
                notification={notification}
              />
            ))}

          {/* ---- Carousel container ---- */}
          {carouselCards.length > 0 &&
            carouselCards.map((carousel, index) => (
              <CarouselCard
                platform={campaignData.platform}
                key={index + "_carousel"}
                pageDetails={pageDetails}
                audiences={audiences}
                card={carousel}
                ctaList={ctaList}
                carouselCardIds={carouselCardIds}
                deleteCarousalCard={deleteCarousalCard}
                updateCarouselData={(data, updateIndex) =>
                  updateCarouselData(data, updateIndex)
                }
                carouselPopVisible={carouselPopVisible}
                handleUpdateCarousalMedia={(id) => {
                  setCarouselId(id);
                }}
                dataFromCarouselCard={dataFromCarouselCard}
                changeCarousalArray={changeCarousalArray}
                updateMainCarouselData={updateMainCarouselData}
                updateCarouselIndividualData={updateCarouselIndividualData}
                updateCarouselCtaData={updateCarouselCtaData}
                setNotification={setNotification}
                notification={notification}
              />
            ))}

          {/* {carouselCards.length > 0 && carouselCards.map()}<CarouselCard pageDetails={pageDetails} audiences={audiences} card={{}} /> */}
        </div>
      </TabPanel>
      {/* <button
        style={{
          float: "right",
          marginRight: "5%",
          marginTop: "35px"
        }}
        className="btn btn-primary"
        onClick={() => setShowRecommendAudiencePopup(true)}
      >
        Recommend Audience
      </button> */}

      {/* <AudiencesDisplay
          audiences={audiences}
          budgetPercentages={budgetPercentages}
          setBudgetPercentages={setBudgetPercentages}
          setValidationErrors={setValidationErrors}
          validationErrors={validationErrors}
          budgets={budgets}
          setBudgets={setBudgets}
          totalBudget={totalBudget}
        /> */}

      {(isPopupVisible || isCarouselPopUp) && (
        <React.Fragment>
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: 0,
              left: 0,
              zIndex: 999,
              backgroundColor: "rgba(0, 0, 0, .5)",
            }}
          ></div>
          <SelectPostPopup
            setIsPopupVisible={setIsPopupVisible}
            carouselPopVisible={carouselPopVisible}
            getPosts={getPosts}
            postsToShow={postsToShow}
            cardsToShow={cardsToShow}
            setCardsToShow={setCardsToShow}
            setSearchPostQuery={setSearchPostQuery}
            searchPostQuery={searchPostQuery}
            editCardPosition={editCardPosition}
            setEditCardPosition={setEditCardPosition}
            setDataToSubmit={setDataToSubmit}
            removeCardAtEdit={removeCardAtEdit}
            showCreateCardSteps={showCreateCardSteps}
            setCardsStepPost={setCardsStepPost}
            dataToSubmit={dataToSubmit}
            showImageCard={showImageCard}
            showVideoCard={showVideoCard}
            showCarouselCard={showCarouselCard}
            isCarouselPopUp={isCarouselPopUp}
            carouselMediaId={carouselMediaId}
            carouselCards={carouselCards}
            updateCarouselInternalData={updateCarouselInternalData}
            campaignObjective={campaignData.objective}
            campaignPlatform={campaignData.platform}
          />
        </React.Fragment>
      )}
      <div className="d-flex justify-content-end postboost-save-actions">
        {/* <button
          onClick={() => window.history.back()}
          // className="postboost__btn--back"
          className="btn btn-outline-primary"
          style={{
            marginLeft: "10px",
            marginTop: "20px",
          }}
        >
          {t("campaign_back")}
        </button> */}
        {
          showSavePublishBtn() === "saveAndPublishBtn" && (
            <button
              style={{
                // float: "right",
                marginLeft: "10px",
                marginTop: "20px",
              }}
              disabled={publishLoading}
              onClick={() => {
                if (budgetPredictionError.error) {
                  isPredectionError();
                } else {
                  isAllAudienceAssigned
                    ? onSaveClick(true)
                    : showAudienceNotSelectedError();
                }
              }}
              className={`btn btn-primary ${
                validationChecker() || isBudgetError ? "btn-disabled" : ""
              }`}
            >
              {publishLoading ? (
                <img
                  style={{ width: "20px" }}
                  src={`${BASE_URL}images/assets/loading-button.gif`}
                  alt={t("campaign_loading")}
                />
              ) : (
                t("campaign_saveAndPublish")
              )}
              {/* Publish btn change here condition */}
            </button>
          )
        }
        {
          showSavePublishBtn() === "pauseBtn" && (
            <button
              style={{
                // float: "right",
                marginLeft: "10px",
                marginTop: "20px",
              }}
              // disabled={publishLoading}
              onClick={() => pauseCampaign(campaignData?.id)}
              className={`btn btn-primary`}
            >Pause</button>
          )
        }


        <button
          style={{
            marginTop: "20px",
          }}
          onClick={() => {
            if (budgetPredictionError.error) {
              isPredectionError();
            } else {
              isAllAudienceAssigned
                ? onSaveClick(false)
                : showAudienceNotSelectedError();
            }
          }}
          disabled={saveLoading}
          className={`btn btn-primary right-btn ${
            validationChecker() || isBudgetError
              ? "btn-disabled"
              : "btn-enabled"
          }`}
        >
          {saveLoading ? (
            <img
              style={{ width: "20px" }}
              src={`${BASE_URL}images/assets/loading-button.gif`}
              alt="loading..."
            />
          ) : (
            t("campaign_save")
          )}
        </button>
      </div>
      <DialogDiv />
      <MergeAudienceDialog
        mergeAudienceDialogOpen={mergeAudienceDialogOpen}
        mergeAudienceImage={mergeAudienceImage}
        audienceMergeLoading={audienceMergeLoading}
        campaignStep4={campaignStep4}
        runAudienceMerge={runAudienceMerge}
        setAudienceMergeDialogOpened={setAudienceMergeDialogOpened}
        setAudienceMergeLoading={setAudienceMergeLoading}
        setMergeAudienceDialogOpen={setMergeAudienceDialogOpen}
        setMergeAudienceImage={setMergeAudienceImage}
        setTimeoutCount={setTimeoutCount}
      />
    </div>
  );
};

export default PostBoost;
