import React, { useEffect, useState } from "react";
import { List, ListItemText, Typography } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { useRef } from "react";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
const ToupInvoicePopUp = ({ topupData, setOtherAmount, otherAmount }) => {
  // let amount = parseFloat(topupData.total_amount).toFixed(2);
  const { t } = useTranslation();
  let transactionCharges = parseFloat(
    otherAmount * (topupData.transaction_fee / 100)
  ).toFixed(2);
  let total = parseFloat(otherAmount) + parseFloat(transactionCharges);
  console.log(topupData);
  return (
    <>
      <List
        style={{
          width: "100%",
          flexDirection: "-moz-initial",
        }}
      >
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>{t("invoice_order_amount")} </h2>
          <TextField
            id="standard-basic"
            label=""
            variant="outlined"
            size="small"
            value={otherAmount}
            onChange={(e) => {
              if (e.target.value == "") {
                setOtherAmount(0);
              } else {
                setOtherAmount(parseFloat(e.target.value));
              }
            }}
          />
        </ListItem>
        <Divider />
        <ListItem
          style={{ display: "flex", justifyContent: "space-between" }}
          button
        >
          <h2>
            {t("invoice_transaction_charges")} ({topupData.transaction_fee}%){" "}
          </h2>
          <Typography variant="subtitle1">{transactionCharges}</Typography>
        </ListItem>
        <Divider />

        <ListItem
          style={{ display: "flex", justifyContent: "space-between" }}
          button
        >
          <h2>{t("invoice_total")}</h2>
          <Typography variant="subtitle1">
            {JSON.parse(localStorage.getItem("user_data")).currency || "MYR"}{" "}
            {parseFloat(total).toFixed(2)}
            {}
          </Typography>
        </ListItem>

        {/* <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          Other Amount
          <TextField
            id="standard-basic"
            label=""
            variant="outlined"
            size="small"
            onChange={(e) => {
              setOtherAmount(e.target.value);
            }}
          />
        </ListItem> */}
      </List>
    </>
  );
};

export default ToupInvoicePopUp;
