import React, { PureComponent } from "react";

import "./Billing.css";
import { userDetails } from "../Axios/ApiRequests";
import Sidebar from "../SideBar";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import { TopUpModal } from "./child/TopUpModal";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";

import {
  getPackages,
  getTransactionHistory,
  changePlan,
  unsubscribe,
  updateChargebeeSubscription,
} from "../Axios/ApiRequests";
import { notify } from "../Notification/Notifications";
import { NotificationManager } from "react-notifications";
import {
  createStripeSession,
  getCurrentPriceId,
  getStripePackages,
  onStripeUnsubscribe,
  switchStripePlan,
} from "../../../utils/network/lib/misc";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { STATIC_DATA } from "../../../utils/network/Constants";
import { toast } from "react-toastify";

class Billing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      transaction_history: [],
      open: false,
      top_up_value: 50,
      packages: [],
      loader: false,
      available_credit: 0,
      user_details: [], //chargebee_customer is blank  show chargebee when switch plan
      buy_now_plan_id: null,
      openPaymentStatusModal: false,
      paymentStatus: null,
      currentPriceId: null,
    };
  }

  async componentWillMount() {
    let userActivePackageId = null;
    let packages = [];

    this.fetchCurrentPack();

    this.userDetails().then(async (user_details) => {
      if (user_details.account_type === "prepaid") {
        this.getTransaction();
      }
      packages = await this.getAllPackages();

      console.log("get packages", packages);

      if (user_details?.active_package?.id) {
        userActivePackageId = user_details.active_package.id;
      }

      //GET EXTERNAL URL PACKAGE ID
      let buy_now_plan_id = new URLSearchParams(this.props.location.search).get(
        "buy_now_plan_id"
      );

      const paymentStatusParam = new URLSearchParams(
        this.props.location.search
      ).get("success");

      if (paymentStatusParam) {
        this.setState({
          paymentStatus: paymentStatusParam,
          openPaymentStatusModal: true,
        });
        const pathname = window.location.pathname;
        window.history.pushState({}, document.title, pathname);
      }

      console.log("userActivePackageId", userActivePackageId);
      console.log("buy_now_plan_id", buy_now_plan_id);
      console.log("will mount packages", packages);

      const parsedPackages = packages?.data[0];
      if (buy_now_plan_id && parsedPackages) {
        // if (userActivePackageId && userActivePackageId == buy_now_plan_id)
        //   return;

        console.log("will mount parsedPackages", parsedPackages);

        if (Array.isArray(parsedPackages)) {
          parsedPackages.some((plan) => {
            let prices = plan.parsedPrices;
            console.log("will mount prices", prices);
            prices.forEach((price) => {
              if (price.priceId === buy_now_plan_id) {
                this.handleChangePlan(plan.id, plan.reference, buy_now_plan_id);
                console.log("External Checking out", plan);
                return true;
              }
            });
            return false;
          });
        }
        this.state.buy_now_plan_id = buy_now_plan_id;
      }
    });
  }

  async userDetails() {
    const user_details = await userDetails();
    console.log("user details after api", user_details);
    localStorage.setItem("user_data", JSON.stringify(user_details));
    this.setState({ user_details });
    return user_details;
  }

  async fetchCurrentPack() {
    let currentPriceIdResponse = await getCurrentPriceId();
    if (currentPriceIdResponse && currentPriceIdResponse.data) {
      if (currentPriceIdResponse.data.price_id) {
        const currentPriceId = currentPriceIdResponse.data.price_id;
        this.setState({ currentPriceId: currentPriceId });
      }
    }

    console.log("currentPriceIdResponse", currentPriceIdResponse);
  }


  

  /**
   * Retrieve Packages from APi
   */
  async getAllPackages() {
    this.setState({ loader: true });
    const packages = await getStripePackages();
    let response = packages.data[0];

    response.forEach((pack) => {
      const prices = pack.pricing.original[0]["data"];

      let pricing = [];
      prices.forEach((price) => {
        let temp = {};
        let parsedPrice = price.unit_amount / 100;
        temp["interval"] = price.recurring?.interval;
        temp["price"] = parsedPrice;
        temp["priceId"] = price.id;
        temp["currency"] = price.currency;
        pricing.push(temp);
      });
      pricing.reverse();
      pack.parsedPrices = pricing;
    });

    console.log("packages response", response);

    this.setState({ packages: response });
    this.setState({ loader: false });

    return packages;
  }

  /**
   * Get Transaction history for prepaid user
   */
  async getTransaction() {
    const data = await getTransactionHistory();
    // this.setState({ transaction_history: data.list });
    // this.setState({ available_credit: data.available_credit });
  }

  async savePaymentDetails(userId, paymentId, packageReference) {
    try {
      let payload = {
        user_id: userId,
        paymentId: paymentId,
        packageReference: packageReference,
      };
      const resp = await updateChargebeeSubscription(payload);
      notify(resp, "Subscription updated Successfully.");
      this.userDetails();
    } catch (err) {
    } finally {
    }
  }

  async initiateChargebee(userDetails, id, reference) {
    let chargeBee = window.Chargebee.init({
      site: "peasy",
    });
    let product = chargeBee.initializeProduct(reference);
    let cart = chargeBee.getCart();
    let names = userDetails.name.split(" ");
    let customer = {
      email: userDetails.email,
      first_name: names[0],
      last_name: names[1],
      billing_address: {
        first_name: names[0],
        last_name: names[1],
        company: "",
        phone: userDetails.phone,
        line1: "",
        line2: "",
      },
    };
    cart.setCustomer(customer);
    cart.replaceProduct(product);
    chargeBee.setCheckoutCallbacks((cart) => {
      // you can define a custom callbacks based on cart object
      return {
        loaded: () => {
          console.log("checkout opened");
        },
        close: () => {
          // setVisibility(true);
        },
        success: (hostedPageId) => {
          this.savePaymentDetails(userDetails.id, hostedPageId, reference);
        },
        step: (value) => {
          // value -> which step in checkout
          console.log("Here", value);
        },
      };
    });
    cart.proceedToCheckout();
  }

  /**
   * Change Plan
   */
  async handleChangePlan(id, reference, priceId) {
    console.log("priceId", priceId);
    // return;
    this.setState({ loader: true });

    let { user_details } = this.state;

    if (user_details && user_details.id && priceId) {
      let createSessionResponse = await switchStripePlan({
        user_id: user_details.id,
        price_id: priceId,
      });
      // update the user information after switching plan  _sh_
      // this.userDetails();  

      if (createSessionResponse && createSessionResponse.data) {
        console.log("createSessionResponse", createSessionResponse);

        if (createSessionResponse.data.checkout_url) {
          // console.log("redirect to ", createSessionResponse.data.checkout_url);
          window.location = createSessionResponse.data.checkout_url;
        } else {
          toast.success("Switched Successfully.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          this.fetchCurrentPack();
          this.userDetails();       // already updating _sh_
        }
      } else {
        toast.error("Could not switch plan currently, Try again later", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.error("Could not switch plan currently, Try again later", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    this.setState({ loader: false });

    /*
    ChargeBee
    */
    // let { user_details } = this.state;

    // // let { user_details } = this.state;
    // if (user_details.chargebee_user_id === "") {
    //   this.initiateChargebee(user_details, id, reference);
    //   return;
    // }
    // this.setState({ loader: true });
    // try {
    //   const resp = await changePlan({
    //     switch_package_id: id,
    //   });
    //   notify(resp, "Plan Changed Successfully.");
    //   this.userDetails();
    // } catch (error) {
    //   notify(error, "Error While Changing Plan.");
    //   console.log(error);
    // }
    // this.setState({ loader: false });
  }

  async handleUnsubscribe() {
    // NotificationManager.success("Top Up Successfully!", "Successful!", 3000);

    let { user_details } = this.state;
    this.setState({ loader: true });
    try {
      const resp = await onStripeUnsubscribe({
        user_id: user_details.id,
      });
      console.log("unsub resp", resp, notify);
      this.userDetails();
      // this.getAllPackages();
      notify(resp, "Unsubscribe Successfully.");
      toast.info("Unsubscribe Successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // this.props.refreshUser();
    } catch (error) {
      notify(error, "Error While Unsubscibe Plan.");
      console.log("error", error);
    }
    this.setState({ loader: false });

    // this.setState({ loader: true });
    // try {
    //   const resp = await unsubscribe();
    //   // this.getAllPackages();
    //   notify(resp, "Unsubscribe Successfully.");
    //   this.props.refreshUser();
    // } catch (error) {
    //   notify(error, "Error While Unsubscibe Plan.");
    //   console.log("error", error);
    // }
    // this.setState({ loader: false });
  }

  /**
   * Get button according to user
   */
  getButton(id, price, reference, priceId) {
    // console.log("getting button", id, price, reference, priceId);
    // console.log("user details for button", this.state.user_details);
    const { user_details } = this.state;
    // console.log("user_details", user_details);

    if (user_details.unsubscribe === "yes") {
      return (
        <button
          className="button"
          onClick={() => this.handleChangePlan(id, reference, priceId)}
        >
          {this.props.t("settings_subscribe")}
        </button>
      );
    }

    if (
      this.state.currentPriceId &&
      priceId &&
      priceId === this.state.currentPriceId
    ) {
      return (
        <button
          className="button active"
          onClick={() => this.handleUnsubscribe(id)}
        >
          {this.props.t("settings_unsubscribe")}
        </button>
      );
    }

    // if (
    //   user_details.downgrade_package &&
    //   user_details.downgrade_package.id === id
    // ) {
    //   return (
    //     <button
    //       className="button active"
    //       onClick={() => this.handleUnsubscribe(id)}
    //     >
    //       {this.props.t("settings_unsubscribe")}
    //     </button>
    //   );
    // }

    // if (
    //   user_details.active_package &&
    //   user_details.active_package.id == id &&
    //   user_details.subscription_status == "active" &&
    //   user_details.downgrade_package === null
    // ) {
    //   return (
    //     <button
    //       className="button active"
    //       onClick={() => this.handleUnsubscribe()}
    //     >
    //       {this.props.t("settings_unsubscribe")}
    //     </button>
    //   );
    // }
    else if (
      user_details.active_package &&
      user_details.active_package.id == id &&
      user_details.subscription_status == "inactive"
    ) {
      return (
        <button className="button inactive">
          {this.props.t("settings_makePayment")}
        </button>
      );
    } else {
      if (user_details.downgrade_package) {
        return (
          <button
            className="button"
            onClick={() => this.handleChangePlan(id, reference, priceId)}
          >
            {this.props.t("settings_switch")}
          </button>
        );
      }
      if (!user_details.active_package) {
        return (
          <button
            className="button"
            onClick={() => this.handleChangePlan(id, reference, priceId)}
          >
            {this.props.t("settings_subscribe")}
          </button>
        );
      }

      return (
        <button
          className="button"
          onClick={() => this.handleChangePlan(id, reference, priceId)}
        >
          {this.props.t("settings_switch")}
        </button>
      );
    }
  }

  /**
   * Display price
   */
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /**
   * Open Modal
   */
  onOpenModal() {
    this.setState({ open: true });
  }

  /**
   * Close Modal
   */
  onCloseModal() {
    this.setState({ open: false });
  }

  showNotification(resp) {
    if (resp.status === 200) {
      NotificationManager.success("Top Up Successfully!", "Successful!", 3000);
      this.getTransaction();
    } else {
      NotificationManager.error("Error Occured", "Error!", 2000);
    }
  }

  render() {
    const { packages, loader, open, transaction_history, available_credit } =
      this.state;
    const { user_details } = this.state;
    return (
      <div className="billing-box">
        <div className="BillingWrapper">
          <h1 className="main-heading">
            {this.props.t("settings_billingScreen")}
          </h1>
          {loader ? (
            <div className="load" style={{ textAlign: "center" }} id="loader-4">
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : (
            <div className="billing-row">
              <div className="row">
                {packages &&
                  packages.length > 0 &&
                  packages.map((pkg) => (
                    <div
                      className="col-md-4"
                      style={{ marginBottom: 30 }}
                      key={pkg.id}
                    >
                      <div className="billing-single">
                        <div>
                          <h1 className="large-price">
                            <span className="small-text">{pkg.name}</span>
                          </h1>

                          <div style={{ marginTop: 30 }}></div>
                          <h2 className="small-price">
                            ${pkg.ad_spend_limit}
                            <span className="small-text">
                              {this.props.t("settings_adSpentLimit")} <br />
                              {this.props.t("settings_renewsEveryMonth")}
                            </span>
                          </h2>
                          <ul className="price-list">
                            <li key="desc">
                              <img src={BASE_URL + "images/check.png"} alt="" />
                              {pkg.description}
                            </li>
                            <li key="acc">
                              <img src={BASE_URL + "images/check.png"} alt="" />
                              {this.props.t("settings_noOfUserAcc")}{" "}
                              {pkg.account_limit}
                            </li>
                            <li key="data">
                              <img src={BASE_URL + "images/check.png"} alt="" />
                              {this.props.t("settings_maxDataStorage")}{" "}
                              {parseInt(pkg.max_data) / 1024}
                              {this.props.t("settings_GB")}
                            </li>
                            <li key="date">
                              <img src={BASE_URL + "images/check.png"} alt="" />
                              {this.props.t("settings_maxRetentionPeriod")}{" "}
                              {parseInt(pkg.max_retention) / 30}
                              {this.props.t("settings_month")}
                            </li>
                          </ul>
                        </div>

                        {pkg.parsedPrices.map((price, index) =>
                          index === 0 ? (
                            <div>
                              <h1 className="large-price">
                                {price.price + " " + price.currency}
                                <span className="small-text">
                                  {price.interval === "year"
                                    ? this.props.t("settings_billedYearly")
                                    : this.props.t("settings_billedMonthly")}
                                </span>
                              </h1>

                              {this.getButton(
                                pkg.id,
                                price.price,
                                pkg.reference,
                                price.priceId
                              )}
                            </div>
                          ) : (
                            <div style={{ marginTop: 10 }}>
                              <hr></hr>
                              <h1 className="large-price">
                                {price.price + " " + price.currency}
                                <span className="small-text">
                                  {price.interval === "year"
                                    ? this.props.t("settings_billedYearly")
                                    : this.props.t("settings_billedMonthly")}
                                </span>
                              </h1>
                              {this.getButton(
                                pkg.id,
                                price.price,
                                pkg.reference,
                                price.priceId
                              )}
                            </div>
                          )
                        )}

                        {/* <div>
                          {pkg.parsedPrices.map((price, index) =>
                            index === 0 ? (
                              <div>
                                {this.getButton(
                                  pkg.id,
                                  price.price,
                                  pkg.reference,
                                  price.priceId
                                )}

                                <span className="smaller-text">
                                  {price.interval == "year"
                                    ? price.price +
                                      " (" +
                                      price.currency +
                                      ")" +
                                      " per year"
                                    : price.price +
                                      " (" +
                                      price.currency +
                                      ")" +
                                      " per month"}
                                </span>
                              </div>
                            ) : (
                              <div style={{ marginTop: 10 }}>
                                <hr />

                                {this.getButton(
                                  pkg.id,
                                  price.price,
                                  pkg.reference,
                                  price.priceId
                                )}

                                <span className="smaller-text">
                                  {price.interval == "year"
                                    ? price.price +
                                      " (" +
                                      price.currency +
                                      ")" +
                                      " per year"
                                    : price.price +
                                      " (" +
                                      price.currency +
                                      ")" +
                                      " per month"}
                                </span>
                              </div>
                            )
                          )}
                        </div> */}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        <TopUpModal
          userData={user_details}
          open={open}
          closeModal={() => this.onCloseModal()}
          showNot={(e) => this.showNotification(e)}
        />

        <Modal
          isOpen={this.state.openPaymentStatusModal}
          toggle={() => {
            this.setState({
              openPaymentStatusModal: !this.state.openPaymentStatusModal,
            });
          }}
        >
          <ModalBody>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={
                  this.state.paymentStatus === "true"
                    ? "/images/payment_success.png"
                    : "/images/payment_failed.png"
                }
                width={120}
              />
              <h2 style={{ fontSize: 18, marginTop: 15 }}>
                {this.state.paymentStatus === "true"
                  ? "Your Payment was Successful!"
                  : "Unable to process payment. Please Try Again."}
              </h2>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(Billing);
