import React, { useState } from "react";
import { Card, CardContent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    minHeight: 135,
  },
});

const CardComponent = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

export default CardComponent;
