import React, { useState, useEffect, useRef } from "react";
import PredictionStats from "../predictionStats/PredictionStats";
import PredictionForm from "../predictionForm/PredictionForm";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import "./CampaignInfoBox.css";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { onEditCampaignData } from "../../../utils/network/lib/campaign";

toast.configure({
  position: toast.POSITION.BOTTOM_LEFT,
});

const CampaignInfoBox = ({
  campaignData,
  audiences,
  getAudienceList,
  getMediaDetails,
  totalVolume,
  predictionVolume,
  eventId,
  salesGoals,
  setPredictionBudgetError,
  setNotification,
  notification,
}) => {
  const renders = useRef(0);

  // useEffect(() => {
  //   if (audiences && audiences.length > 0) return getCampaignPrediction();
  // }, [audiences]);

  useEffect(() => {
    if (campaignData) {
      setInitialFormData();
    }
  }, [campaignData]);

  // on end date or start date change call api automatically
  //because on blur event is not updating in time

  const [predictionFormData, setPredictionFormData] = useState({
    start_date: "",
    end_date: "",
    budget: "",
    sales_goal: "",
  });

  const [predictionStats, setPredictionStats] = useState({});

  useEffect(() => {
    if (renders.current < 2) renders.current += 1;
    else editCampaignData();
  }, [predictionFormData.end_date, predictionFormData.start_date]);

  const setInitialFormData = () => {
    setPredictionFormData({
      start_date: campaignData.start_date,
      end_date: campaignData.end_date,
      budget: campaignData.budget,
      sales_goal: campaignData.sales_goal,
    });
  };

  const editCampaignData = () => {
    const campaign_id = window.location.href.split("/").pop();
    const p = predictionFormData;
    const c = campaignData;
    if (
      p.start_date === c.start_date &&
      p.end_date === c.end_date &&
      p.budget === c.budget &&
      p.sales_goal === c.sales_goal
    )
      return null;

    if (
      Math.abs(
        moment(predictionFormData.end_date).diff(
          predictionFormData.start_date,
          "days"
        )
      ) < 4
    ) {
      {
        setInitialFormData();
        return toast.error("Campaign Dates must have a gap of 4 days");
      }
    }
    const todayDate = moment().format("YYYY-MM-DD HH:mm:ss");

    if (moment(predictionFormData.end_date).diff(todayDate, "days") < 0) {
      {
        setInitialFormData();
        return toast.error("End date can't be in past");
      }
    }

    onEditCampaignData(campaign_id, predictionFormData)
      .then((res) => {
        if (res.status === 200) {
          console.log("toast for success should be called");
          toast.success("Campaign edited successfully!");
          getAudienceList();
          getMediaDetails();
          setPredictionBudgetError({
            error: "",
            section: "",
          });
        } else throw Error;
      })
      .catch((err) => {
        if (
          err.response.data.message.includes(
            "Campaign budget should be greater than minimum budget"
          )
        ) {
          document.getElementById("budgetInput").focus();
          setPredictionBudgetError({
            error: "Campaign budget is less than required",
            section: "budgetInput",
          });
          return toast.error(err.response.data.message);
        } else if (err.response.data.message) {
          console.log("toast for error should be called with error message");
          toast.error(err.response.data.message);
        } else {
          console.log("toast for error should be called without message");
          toast.error("Couldn't update campaign! Please try again");
        }
        setInitialFormData();
      });
  };

  return (
    <div className="campaigninfobox__container">
      <PredictionStats
        predictionStats={predictionStats}
        campaignData={campaignData}
        audiences={audiences}
        totalVolume={totalVolume}
        predictionVolume={predictionVolume}
        eventId={eventId}
        salesGoals={salesGoals}
      />
      <PredictionForm
        setPredictionFormData={setPredictionFormData}
        predictionFormData={predictionFormData}
        editCampaignData={editCampaignData}
        audiences={audiences}
        setInitialFormData={setInitialFormData}
        setNotification={setNotification}
        notification={notification}
      />
    </div>
  );
};

export default CampaignInfoBox;
