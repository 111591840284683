import React from "react";
import BrandProfileCard from "../brandProfileCard/BrandProfileCard";
import BottomScrollListener from "react-bottom-scroll-listener";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import { InfiniteScrollLoader } from "../../../shared/InfiniteScroll/InfiniteScroll";
import Dashboard from "../Dashboard";
// import RMF from "../rmf-screen/RMF";
// import ConsumerList from "../rmf-screen/ConsumerList";

class BrandProfileList extends React.Component {
  constructor(props) {
    super(props);
    this.loadCards = this.loadCards.bind(this);
    this.loadMoreCards = this.loadMoreCards.bind(this);
    this.state = {
      total_count: null,
      chunk_size: null,
      flag: null,
      index: 0,
      data: [],
      showLastCard: false,
      loading: false,
    };
  }

  handleContainerOnBottom() {
    alert("Bottom hit");
  }

  componentDidMount() {
    // debugger;
    http
      .get(BASE_URL + "api/get-brand-profile-meta")
      //.get('http://dev5.peasy.ai/peasy_gerry/public/api/get-brand-profile-meta')
      .then((res) => {
        this.setState({ ...res.data.data });
        console.log(res.data);
      })
      .then(() => this.loadCards())
      .catch((err) => {
        console.log("inside error of meta api", err);
      });
  }

  loadCards() {
    this.setState({ loading: true });
    if (this.state.total_count === (null || 0)) this.props.setIsListEmptyTrue();
    else
      http
        .get(BASE_URL + "api/get-brand-profile-data", {
          params: {
            index: this.state.index,
            chunk_size: this.state.chunk_size,
          },
        })
        .then((res) => {
          this.setState((state) => ({
            data: [...state.data, ...res.data.data],
            loading: false,
          }));
          console.log(res.data);
        })
        .then(() => {
          this.setState((state) => ({ index: state.index + state.chunk_size }));
          if (this.state.index === this.state.chunk_size)
            return this.loadMoreCards();
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
  }

  loadMoreCards() {
    if (this.state.index < this.state.total_count && !this.state.loading)
      this.loadCards();
  }

  render() {
    const { data, loading } = this.state;

    return (
      <>
        {data.length > 0 ? (
          <Dashboard brandProfiles={this.state.data} />
        ) : (
          "Loading..."
        )}
        <BottomScrollListener offset={1000} onBottom={this.loadMoreCards}>
          <div
            id="brand_profile_list"
            style={{
              marginTop: "8vh",
              width: "100%",
              display: "flex",
              flexFlow: "row wrap",
            }}
          >
            {data.length > 0 &&
              data.map((brandProfile) => {
                return (
                  <React.Fragment key={brandProfile.id}>
                    <BrandProfileCard
                      key={brandProfile.id}
                      brandProfile={brandProfile}
                    />
                    {this.state.showLastCard && (
                      <span>last card will be shown here</span>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
          {loading && <InfiniteScrollLoader style={{ marginTop: "20px" }} />}
        </BottomScrollListener>
      </>
    );
  }
}
export default BrandProfileList;
