import React from "react";
import "./PrepaidTableHeader.css";

const PrepaidTableHeader = () => {
  return (
    <div className="prepaidtableheader__container">
      <span className="prepaidtableheader__span--country">Currency</span>

      <span className="prepaidtableheader__span--tier1">Tier 1</span>

      <span className="prepaidtableheader__span--tier2">Tier 2</span>

      <span className="prepaidtableheader__span--tier3">Tier 3</span>

      <span className="prepaidtableheader__span--users">Active Users</span>

      <span className="prepaidtableheader__span--action">Action</span>
    </div>
  );
};

export default PrepaidTableHeader;
