import React, { Component, Fragment } from "react";
import "./Retrieve.css";
import HeaderContainer from "./Header";
import RetriveLeftContainer from "./RetriveLeftStatistics";
import RetriveRightContainer from "./RetriveRightContainer";

const options = [
  { value: "best", label: "Best" },
  { value: "promising", label: "Promising" },
  { value: "worst", label: "Worst" }
];
class ChartReport extends Component {
  state = {
    selectedOption: { value: "promising", label: "Promising" },
    swoSelectOption: []
  };

  // Handle props change and state change
  shouldComponentUpdate(nextProps, nextState) {
    const { campaignMetricData } = nextProps;

    // Handle if previous and nextstate is not same
    if (nextState !== this.state) {
      return true;
    }

    // Handle campaignMetric data change
    if (campaignMetricData !== this.props.campaignMetricData) {
      if (campaignMetricData.status === 200) {
        const { swo } = campaignMetricData.data;
        let swoData = [];
        Object.keys(swo).map(val => {
          if (swo[val]) {
            swoData = [
              ...swoData,
              {
                value: val,
                label: val.charAt(0).toUpperCase() + val.slice(1)
              }
            ];
          }
        });
        this.setState({ swoSelectOption: swoData });
      }
    }

    return false;
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };
  render() {
    const { selectedOption, swoSelectOption } = this.state;
    const data =
      this.props.campaignMetricData && this.props.campaignMetricData.data;
    const swo = data && data.swo;
    return (
      <Fragment>
        <HeaderContainer
          options={swoSelectOption}
          handleChange={this.handleChange}
          selectedOption={selectedOption}
        />

        <div class="analys_frame">
          {/* <div class="frame_heading mob_dply_none">
            <h4>Frame 1</h4>
          </div> */}
          <div class="cstm_container">
            <div class="frame_row">
              {swo && swo[selectedOption.value] ? (
                <>
                  <RetriveLeftContainer swo={swo[selectedOption.value]} />
                  <RetriveRightContainer swo={swo[selectedOption.value]} />
                </>
              ) : (
                <p>Data not available</p>
              )}
            </div>
          </div>
          {/* <div class="frame_heading mob_dply_none">
            <h4>Frame 2</h4>
          </div> */}
        </div>
      </Fragment>
    );
  }
}

export default ChartReport;
