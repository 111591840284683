import React, { useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import http from "../../../shared/http-request";
import { Grid } from "@material-ui/core";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import { onCheckCampaignBalance } from "../../../utils/network/lib/campaign";

function SelectFunnelType({
  value,
  handleChange,
  goForward,
  goBack,
  getDisplayObjective,
  state,
  obj,
}) {
  const { t } = useTranslation();

  const [collapse_open, setCollapseOpen] = useState();
  const [disable, setDisable] = useState(false);

  const collapse = () => {
    setCollapseOpen(!collapse_open);
  };
  const checkEnter = (e) => {
    if (e.keyCode === 13) {
      validate();
    }
  };

  const validate = () => {
    if (state.target_campaign === "" && value === 1) {
      return toast.error(t("campaign_enterTargetValue"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    if (value === 2) {
      return checkCampaignBalance();
    }
    if (value) {
      goForward();
    } else {
      toast.error(t("campaign_selectRecommendatnMethod"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const submitPlatform = (val) => {
    handleChange("method", val);
    collapse();
  };
  const listStyles = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "3%",
    fontWeight: "bold",
    cursor: "pointer",
  };
  const displayPlatformName = (name) => {
    if (name === 1) return t("campaign_target");
    if (name === 2) return t("campaign_budget");
  };

  const onKeyPressValidation = (event) => {
    let val = event.target.value.replace(/[^0-9]/gi, "");
    // debugger;
    if (value === 1) {
      handleChange("target_campaign", val);
      handleChange("campaign_amount", "");
    } else if (value === 2) {
      handleChange("target_campaign", "");
      handleChange("campaign_amount", val);
    }
  };
  const checkCampaignBalance = () => {
    const { campaign_amount, currency } = state;
    const budget = GetMiniMumAmount();
    if (campaign_amount == "" || campaign_amount == 0) {
      setDisable(false);
      return toast.error(t("campaign_enterAmount"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (campaign_amount < budget) {
      setDisable(false);
      return toast.error(t("campaign_budgetMinimumError") + budget + " !", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    if (JSON.parse(localStorage.getItem("user_data")).role === "superadmin")
      goForward();
    else {
      onCheckCampaignBalance("postpaid")
        .then((res) => {
          const balance =
            res.data.data && res.data.data.balance ? res.data.data.balance : -1;
          if (balance != 1 && balance < campaign_amount) {
            setDisable(false);
            toast.error(t("campaign_budgetMoreThanBalance"), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else {
            goForward();
            toast.dismiss();
          }
        })
        .catch((err) => {
          setDisable(false);
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
  };
  const GetMiniMumAmount = () => {
    var start_date = state.start_date;
    var end_date = state.end_date;
    var diff = end_date.diff(start_date, "days") + 1;
    let dur = diff;
    let min = dur * state.minAmount;

    const minimum_budget = dur < 8 ? state.secondMinAmount : min;
    return minimum_budget;
  };

  const FormatedBudget = (minimum_budget) => {
    var offset = state.currencyOffset == 100 ? 2 : 0;
    const returnbudget = minimum_budget.toFixed(offset);
    return returnbudget;
  };

  const minimum_budget = GetMiniMumAmount();
  const Budget_formated = FormatedBudget(minimum_budget);
  return (
    <div className="outer_div w-100 animated" onKeyDown={(e) => checkEnter(e)}>
      <div className="col-12 px-0 tab-pane border-rounded p-3 mb-2" id="step6">
        <div className="form-group brand-custom-form brand-custom-dropdown mt-2">
          <p className="question-description mb-3">
            {t("campaign_recommendCampaignStrategyText")}
          </p>

          <div style={{ display: "flex" }} className="mb-4 custom-flex">
            <div style={{ flex: "60", marginTop: "10px" }}>
              <label>{t("campaign_recommendText")}</label>
            </div>

            <div style={{ flex: "55" }}>
              <button
                type="button"
                className="form-control brand-dropdown"
                onClick={() => collapse()}
              >
                {value === 0
                  ? t("campaign_selectMethod")
                  : displayPlatformName(value)}
                <i className="fa fa-caret-down float-right"></i>
              </button>
              <div
                id="catefory"
                className={collapse_open == true ? "collapse show" : "collapse"}
              >
                <ul className="list-unstyled">
                  <li style={listStyles} onClick={() => submitPlatform(1)}>
                    {t("campaign_target")}
                  </li>
                  <li style={listStyles} onClick={() => submitPlatform(2)}>
                    {t("campaign_budget")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {value === 1 ? (
            <>
              <div
                className="mt-4 target-budget"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label style={{ marginBottom: "0px" }}>
                  {t("campaign_of")}
                </label>

                <input
                  className="custom-target-budget-input"
                  type="text"
                  placeholder={t("campaign_fillIn")}
                  onKeyPress={checkEnter}
                  onChange={onKeyPressValidation}
                  value={state.target_campaign}
                />
                <label style={{ marginBottom: "0px" }}>{obj}</label>
              </div>
              <span className="cus-label-span d-block w-100 mb-4 mt-2 min-spent">
                {t("campaign_howManyXText")}
              </span>
            </>
          ) : (
            <>
              <div
                className="mt-4 target-budget"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label style={{ marginBottom: "0px" }}>
                  {t("campaign_of")}
                </label>
                <span>
                  <input
                    className="custom-target-budget-input"
                    type="text"
                    placeholder={t("campaign_fillIn")}
                    onChange={onKeyPressValidation}
                    onKeyPress={checkEnter}
                    value={state.campaign_amount}
                  />
                </span>
                <label style={{ marginBottom: "0px" }}>{state.currency}</label>
              </div>
              <span className="cus-label-span d-block w-100 mb-4 mt-2 min-spent">
                {t("campaign_minSpent")} {state.currency} {Budget_formated}{" "}
              </span>
            </>
          )}
        </div>
      </div>

      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              className="brandprofilecard__btn--white"
              onClick={() => {
                goBack();
              }}
            >
              {t("campaign_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              disabled={disable}
              className="brandoverviewleftsection__btn--purple"
              onClick={() => {
                if (value === 2) {
                  setDisable(true);
                }
                validate();
              }}
            >
              {t("campaign_next")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectFunnelType;
