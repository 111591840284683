import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button as ProcessButton,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  customBtn: {
    minWidth: "200px",
    borderRadius: "10px",
    marginTop: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
}));
function CheckDialog({
  open,
  setOpen,
  url,
  message,
  srcImg,
  target,
  buttonText,
}) {
  const classes = useStyles();
  const handleLocalStorageSetItem = () => {
    if (buttonText === "Connect Facebook") {
      localStorage.setItem("access_token_status_flag", false);
    }
  };
  const Button = ({ text }) => {
    return (
      <ProcessButton
        className={classes.customBtn}
        color="primary"
        fullWidth
        variant="contained"
      >
        {text}
      </ProcessButton>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img style={{ width: "100%" }} src={srcImg} alt="" />
          <Typography
            className="mt-2 "
            style={{ fontSize: "18px", fontWeight: "bold" }}
          >
            {message}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText
            id="alert-dialog-description"
            align="justify"
          ></DialogContentText> */}
          {target === "_self" ? (
            <Link
              to={url}
              onClick={() => {
                setOpen(false);
                handleLocalStorageSetItem();
              }}
            >
              <Button text={buttonText} />
            </Link>
          ) : (
            <a
              target={target}
              href={url}
              onClick={() => {
                setOpen(false);
                handleLocalStorageSetItem();
              }}
            >
              <Button text={buttonText} />
            </a>
          )}
          <ProcessButton
            variant="outlined"
            color="primary"
            className={`${classes.customBtn} mb-2`}
            fullWidth
            onClick={() => {
              setOpen(false);
              handleLocalStorageSetItem();
            }}
          >
            Cancel
          </ProcessButton>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CheckDialog;
