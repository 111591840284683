import React, { useState, useEffect } from "react";
import moment from "moment";
import ChannelChart from "../../charts/ChannelChart";
import LowerInfoSection from "../lowerInfoSection/LowerInfoSection";
import "../../../../translations/i18n";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";

import "./SingleAudience.css";
import {
  Hidden,
  IconButton,
  Select,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  customSelect: {
    padding: "3.5px 14px",
  },
});

const SingleAudience = ({
  audience,
  totalBudget,
  setBudgetPercentages,
  budgetPercentages,
  selectedAudienceIds,
  setSelectedAudienceIds,
  updateAudiences,
  campaignData,
  getSetVolume,
  index,
  indexesForBudgetError,
  minBudgetPercentage,
  myr3,
  toastErrors,
  setToastErrors,
  editAudience,
  getCpaText,
  setNotification,
  notification,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  // States

  const [isExpanded, setIsExpanded] = useState(false);

  const [lowerInfo, setLowerInfo] = useState("");

  const [infoType, setInfoType] = useState("");

  const [isSelected, setIsSelected] = useState(false);

  // console.log("budget percentages", budgetPercentages);

  // console.log("audienece id", audience.id);

  const [budgetPercentage, setBudgetPercentage] = useState(
    audience.budget_percentage || 0
  );

  const [budget, setBudget] = useState(
    (audience.budget_percentage / 100) * campaignData.budget || 0
  );

  const decideChannelPercentages = () => {
    const object = {};
    if (audience.media_channel[4]) {
      object.fb = {
        channel_id: 4,
        budget_split: Number(audience.media_channel[4].budget_split),
      };
    }
    if (audience.media_channel[5]) {
      object.ig = {
        channel_id: 5,
        budget_split: Number(audience.media_channel[5].budget_split),
      };
    }
    if (audience.media_channel[7]) {
      object.g = {
        channel_id: 7,
        budget_split: Number(audience.media_channel[7].budget_split),
      };
    }
    return object;
  };

  const [channelPercentages, setChannelPercentages] = useState(
    decideChannelPercentages()
  );

  const [selectedChannel, setSelectedChannel] = useState("fb");

  const [volume, setVolume] = useState(audience.reach);

  const [cpa, setCpa] = useState(audience.kpi);

  const [errors, setErrors] = useState([]);

  // if index is present in the array show budget split error
  //otherwise remove it from errors
  useEffect(() => {
    let errorArray = [...errors];
    if (indexesForBudgetError.indexOf(index) !== -1) {
      // console.log("inside correct if 1", indexesForBudgetError, index);
      if (errorArray.indexOf("Total budget split must be 100%") === -1) {
        // console.log("inside correct if 2");
        errorArray.push("Total budget split must be 100%");
      }
    } else {
      if (errorArray.indexOf("Total budget split must be 100%") !== -1) {
        errorArray.splice(
          errorArray.indexOf("Total budget split must be 100%"),
          1
        );
      }
    }
    setInfoType("error");
    setErrors(errorArray);
  }, [indexesForBudgetError]);

  useEffect(() => {
    if (campaignData) {
      const value = (audience.budget_percentage / 100) * campaignData.budget;
      setBudget(value);
    }
  }, [campaignData]);

  //re-calculate budget percentage when percentages in parent component change

  useEffect(() => {
    setBudgetPercentage(budgetPercentages[audience.id]);
    const newBudget = (
      (budgetPercentages[audience.id] / 100) *
      campaignData.budget
    ).toFixed(2);
    setBudget(newBudget);
  }, [budgetPercentages]);

  // re calculate budget when budget percentage changes

  // useEffect(() => {
  //   const newBudget = (budgetPercentage / 100) * campaignData.budget;
  //   setBudget(newBudget);
  // }, [budgetPercentage]);

  // useEffect(() => {
  //   console.log(
  //     "use effect of budget_percentage is called",
  //     audience.budget_percentage
  //   );
  //   setBudgetPercentage(audience.budget_percentage);
  // }, [audience.budget_percentage]);

  // change volume if budget, budget percentage or media channel percentage changes
  useEffect(() => {
    if (campaignData) {
      decideVolumeForOthers(campaignData.objective);
    }
  });

  // when volume changes send this volume to select audiences for totalling
  useEffect(() => {
    getSetVolume(index, Math.floor(volume));
    decideCpa(campaignData.objective);
  }, [volume]);

  useEffect(() => {
    if (errors.length > 0) {
      const object = { ...toastErrors, [audience.id]: errors[0] };
      setToastErrors(object);
    } else {
      if (toastErrors[audience.id]) {
        setToastErrors((errors) => {
          const object = { ...errors };
          delete object[audience.id];
          return object;
        });
      }
    }
  }, [errors]);

  //Functions

  const decideVolumeForClick = () => {
    const newVolume =
      (channelPercentages.fb
        ? (channelPercentages.fb.budget_split * budget) /
          100 /
          (audience.media_channel[4].cpc / 1000000)
        : 0) +
      (channelPercentages.ig
        ? (channelPercentages.ig.budget_split * budget) /
          100 /
          (audience.media_channel[5].cpc / 1000000)
        : 0) +
      (channelPercentages.g
        ? (channelPercentages.g.budget_split * budget) /
          100 /
          (audience.media_channel[7].cpc / 1000000)
        : 0);
    setVolume(newVolume);
  }; // let [timerId] = useState(null);
  // let [timerId] = useState(null);

  const decideVolumeForImpression = () => {
    const newVolume =
      (channelPercentages.fb
        ? ((channelPercentages.fb.budget_split * budget) /
            100 /
            (audience.media_channel[4].cpm / 1000000)) *
          1000
        : 0) +
      (channelPercentages.ig
        ? ((channelPercentages.ig.budget_split * budget) /
            100 /
            (audience.media_channel[5].cpm / 1000000)) *
          1000
        : 0) +
      (channelPercentages.g
        ? ((channelPercentages.g.budget_split * budget) /
            100 /
            (audience.media_channel[7].cpm / 1000000)) *
          1000
        : 0);
    setVolume(newVolume);
  };

  const decideVolumeForOthers = (objective) => {
    let newVolume = budget / audience.avg_conversion_cost;
    if (objective === "IMPRESSION") {
      newVolume = newVolume * 1000;
    }
    setVolume(newVolume);
  };

  const decideCpa = (objective) => {
    let newCpa = budget / volume;
    if (objective === "IMPRESSION") {
      newCpa = newCpa * 1000;
    }
    setCpa(newCpa);
  };

  const pushDataToSelectedArray = () => {
    updateAudiences(index, {
      id: audience.id,
      budget_percentage: Number(budgetPercentage),
      channel_budget: Object.values(channelPercentages),
    });
  };

  const changeBudget = () => {
    const newBudget = ((budgetPercentage / 100) * totalBudget).toFixed(2);
    if (newBudget !== budget) setBudget(newBudget);
    pushDataToSelectedArray();

    checkMinBudgetError();
  };

  const changeBudgetPercentage = () => {
    const newPercentage = (budget / totalBudget) * 100;
    setBudgetPercentages((obj) => ({ ...obj, [audience.id]: newPercentage }));
    if (newPercentage !== budgetPercentage) setBudgetPercentage(newPercentage);
    // checkTotalPercent({ [audience.id]: newPercentage });
    updateAudiences(index, {
      id: audience.id,
      budget_percentage: newPercentage,
      channel_budget: Object.values(channelPercentages),
    });

    // check for minimum budget error
    checkMinBudgetError(newPercentage);
  };

  // new percentage is recieved from change budget percentage
  // from change budget no new percentage is recieved
  const checkMinBudgetError = (newPercentage) => {
    let errorArray = [...errors];
    if ((newPercentage || budgetPercentage) < minBudgetPercentage) {
      // console.log("inside min budget percentage chech", minBudgetPercentage);
      if (
        errorArray.indexOf(
          `Budget percentage can't be less than ${minBudgetPercentage} %`
        ) === -1
      ) {
        errorArray.unshift(
          `Budget percentage can't be less than ${minBudgetPercentage} %`
        );
      }
    } else {
      if (
        errorArray.indexOf(
          `Budget percentage can't be less than ${minBudgetPercentage} %`
        ) !== -1
      ) {
        errorArray.splice(
          errorArray.indexOf(
            `Budget percentage can't be less than ${minBudgetPercentage} %`
          ),
          1
        );
      }
    }
    setErrors(errorArray);
  };

  const onCheckChange = () => {
    if (!isSelected) {
      setIsSelected(true);
      const array = [...selectedAudienceIds, audience.id];
      setSelectedAudienceIds(array);
    } else {
      setIsSelected(false);
      const array = selectedAudienceIds.filter((id) => audience.id !== id);
      setSelectedAudienceIds(array);
    }
  };

  const channelInputChange = (value) => {
    if (Number(value) <= 100) {
      let object = {};
      object = {
        ...channelPercentages,
        [selectedChannel]: {
          ...channelPercentages[selectedChannel],
          budget_split: Number(value),
        },
      };
      setChannelPercentages(object);
    } else {
      console.log("elseeeeeeeeee");
    }
  };

  const onChannelInputBlur = () => {
    let object = {};
    switch (selectedChannel) {
      case "fb":
        object.fb = channelPercentages.fb;
        if (channelPercentages.ig) {
          let igSpilitPercentage = Number(channelPercentages.ig.budget_split);
          let additionSpilitBudget =
            Number(channelPercentages.ig.budget_split) +
            Number(
              channelPercentages.g ? channelPercentages.g.budget_split : 0
            );
          let finalDividedValue =
            igSpilitPercentage === 0 && additionSpilitBudget === 0
              ? 1
              : igSpilitPercentage / additionSpilitBudget;
          object.ig = {
            channel_id: 5,
            budget_split:
              finalDividedValue *
              Number(100 - Number(channelPercentages.fb.budget_split)),
          };
        }
        if (channelPercentages.g) {
          object.g = {
            channel_id: 7,
            budget_split:
              (channelPercentages.g.budget_split /
                (channelPercentages.g.budget_split +
                  (channelPercentages.ig
                    ? channelPercentages.ig.budget_split
                    : 0))) *
              (100 - channelPercentages.fb.budget_split),
          };
        }
        break;
      case "ig":
        if (channelPercentages.fb) {
          object.fb = {
            channel_id: 4,
            budget_split:
              (channelPercentages.fb.budget_split /
                (channelPercentages.fb.budget_split +
                  (channelPercentages.g
                    ? channelPercentages.g.budget_split
                    : 0))) *
              (100 - channelPercentages.ig.budget_split),
          };
        }
        object.ig = channelPercentages.ig;
        if (channelPercentages.g) {
          object.g = {
            channel_id: 7,
            budget_split:
              (channelPercentages.g.budget_split /
                (channelPercentages.g.budget_split +
                  (channelPercentages.fb
                    ? channelPercentages.fb.budget_split
                    : 0))) *
              (100 - channelPercentages.ig.budget_split),
          };
        }
        break;
      case "g":
        if (channelPercentages.fb) {
          object.fb = {
            channel_id: 4,
            budget_split:
              (channelPercentages.fb.budget_split /
                (channelPercentages.fb.budget_split +
                  (channelPercentages.ig
                    ? channelPercentages.ig.budget_split
                    : 0))) *
              (100 - channelPercentages.g.budget_split),
          };
        }
        if (channelPercentages.ig) {
          object.ig = {
            channel_id: 5,
            budget_split:
              (channelPercentages.ig.budget_split /
                (channelPercentages.ig.budget_split +
                  (channelPercentages.fb
                    ? channelPercentages.fb.budget_split
                    : 0))) *
              (100 - channelPercentages.g.budget_split),
          };
        }
        object.g = channelPercentages.g;
        break;
      default:
        return null;
    }
    setChannelPercentages(object);
    checkMinChannelPercentage(object);
    updateAudiences(index, {
      channel_budget: Object.values(object),
      id: audience.id,
      budget_percentage: budgetPercentage,
    });
  };

  const checkMinChannelPercentage = (object) => {
    const duration = moment(campaignData.end_date).diff(
      campaignData.start_date,
      "days"
    );

    const minPercent = Math.ceil(parseFloat((myr3 * duration * 100) / budget));

    const errorsArray = [...errors];
    const values = Object.values(object);
    for (let i = 0; i < values.length; i++) {
      if (
        values[i].budget_split !== 0 &&
        values[i].budget_split !== 100 &&
        values[i].budget_split < minPercent
      ) {
        if (
          errorsArray.indexOf(
            `Minimum channel percentage can't be less than ${minPercent} %`
          ) === -1
        ) {
          errorsArray.unshift(
            `Minimum channel percentage can't be less than ${minPercent} %`
          );
          return setErrors(errorsArray);
        } else return;
      } else {
        if (
          errorsArray.indexOf(
            `Minimum channel percentage can't be less than ${minPercent} %`
          ) !== -1
        ) {
          errorsArray.splice(
            errorsArray.indexOf(
              `Minimum channel percentage can't be less than ${minPercent} %`
            ),
            1
          );
        }
      }
      setErrors(errorsArray);
    }
  };
  // console.log("errors inside audience no", index, errors);

  return (
    <>
      <TableRow className={`audience__container__${audience.id}`}>
        <TableCell align="left">
          <input
            checked={isSelected}
            onChange={() => {
              onCheckChange();
            }}
            className="singleaudience__checkbox"
            type="checkbox"
          />
        </TableCell>
        <TableCell
          align="left"
          style={{ minWidth: "150px", maxWidth: "200px" }}
        >
          <p
            onClick={() => editAudience(audience)}
            style={{ cursor: "pointer" }}
            className="singleaudiencebox__span--name"
            style={{ width: "100%", wordBreak: "break-word" }}
          >
            {audience.name}
          </p>
        </TableCell>
        <TableCell align="left">
          <div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <input
                className="budget-percent"
                type="number"
                value={budget}
                style={{
                  color: "#8C25F6",
                  fontWeight: "bold",

                  fontSize: "13px",
                  border: "none",
                  borderBottom: "2px solid #6f1ddf",
                  marginRight: "10px",
                  width: "75px",
                }}
                onFocus={() => {
                  setInfoType("warning");
                  setLowerInfo(t("campaign_changingAudienceBudgetText"));
                }}
                onChange={(e) => setBudget(e.target.value)}
                onBlur={() => {
                  changeBudgetPercentage();
                  setInfoType("error");
                }}
              />
              {JSON.parse(localStorage.getItem("user_data")).currency
                ? JSON.parse(localStorage.getItem("user_data")).currency
                : "MYR"}
            </div>

            <div style={{ display: "flex" }}>
              <input
                className="budget-percent"
                type="number"
                value={budgetPercentage}
                style={{
                  color: "#8C25F6",
                  fontWeight: "bold",

                  fontSize: "13px",
                  border: "none",
                  borderBottom: "2px solid #6f1ddf",
                  marginRight: "10px",
                  width: "75px",
                }}
                onFocus={() => {
                  setInfoType("warning");
                  setLowerInfo(t("campaign_changingAudienceBudgetText"));
                }}
                onChange={(e) => setBudgetPercentage(e.target.value)}
                onBlur={() => {
                  changeBudget();
                  setInfoType("error");
                }}
              />
              %
            </div>
          </div>
        </TableCell>
        <TableCell align="left">
          {/* <div className="singleaudiencebox__div--channel"> */}
          <div
            className="d-flex align-items-center"
            style={{ flexWrap: "nowrap" }}
          >
            <Hidden smDown>
              {channelPercentages && (
                <ChannelChart channelPercentages={channelPercentages} />
              )}
            </Hidden>
            <div classes="d-flex align-items-center">
              <Select
                variant="outlined"
                onChange={(e) => setSelectedChannel(e.target.value)}
                value={selectedChannel}
                className="channel-select"
                onFocus={() => {
                  setInfoType("warning");
                  setLowerInfo(t("campaign_changingChannelText"));
                }}
                onBlur={() => setInfoType("error")}
              >
                {Object.keys(channelPercentages).map((channel, i) => (
                  <option value={channel} key={i} className="dropdown-option">
                    {channel.toUpperCase()}
                  </option>
                ))}
              </Select>
            </div>
            <input
              value={channelPercentages[selectedChannel].budget_split}
              onChange={(e) => channelInputChange(e.target.value)}
              style={{ marginTop: "0px", marginRight: "20px" }}
              className="singleaudiencebox__input--budget"
              type="number"
              disabled={Object.keys(channelPercentages).length === 1}
              onFocus={() => {
                setInfoType("warning");
                setLowerInfo(
                  t("campaign_changingChannelWillAffectVolumeAndCPA") +
                    getCpaText()
                );
              }}
              onBlur={() => {
                onChannelInputBlur();
                setInfoType("error");
              }}
            />
          </div>
          {/* </div> */}
        </TableCell>
        <TableCell align="left">
          <div className="">
            {Math.floor(audience.population).toLocaleString()}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="">
            {JSON.parse(localStorage.getItem("user_data")).currency
              ? JSON.parse(localStorage.getItem("user_data")).currency
              : "MYR"}
            <span style={{ marginLeft: "10px" }}>{cpa.toFixed(2)}</span>
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="">{Math.floor(volume).toLocaleString()}</div>
        </TableCell>
        <TableCell align="left">
          {!isExpanded ? (
            <span
              onClick={() => setIsExpanded((bool) => !bool)}
              style={{ color: "#6f1ddf", cursor: "pointer" }}
            >
              {t("campaign_showDetails")}
            </span>
          ) : (
            <span
              onClick={() => setIsExpanded((bool) => !bool)}
              style={{ color: "#6f1ddf", cursor: "pointer" }}
            >
              {t("campaign_hideDetails")}
            </span>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        {((infoType === "warning" && lowerInfo.length > 0) ||
          (infoType === "error" && errors.length > 0)) && (
          <TableCell colSpan="8">
            <LowerInfoSection
              textType={infoType}
              text={lowerInfo}
              errors={errors}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {isExpanded && (
          <TableCell colSpan="8">
            <div style={{ wordBreak: "break-all" }}>
              {`${audience.device}, ${audience.gender}, ${audience.interest}, ${audience.age_min} to ${audience.age_max} `}
              {t("campaign_yearsOld")}
            </div>
          </TableCell>
        )}
      </TableRow>
      {notification  && audience.status == 'ERROR' && ( notification.map((notify) => {
        if(notify.data.details.object_type == "Audience" && audience && audience.id == notify.data.details.object_id) {
          
          return (
          <Joyride
            steps={[
              {
                target: `.audience__container__${audience.id}`,
                content: `${notify.data.details.message.transalation_key}`,
              },
            ]}
            hideCloseButton={true}
            styles={{
              options: {
                arrowColor: '#8D02FA',
                primaryColor: '#8D02FA',
                beaconSize: 100,
              }
            }}
          />)
        }

      }))}
    </>
  );
};

export default SingleAudience;
