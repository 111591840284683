import React from "react";
import { components } from "react-select";

const CustomSelectLabel = ({ children, ...props }) => {
  const label = children.split("|")[0];

  return (
    <components.MultiValueLabel {...props}>{label}</components.MultiValueLabel>
  );
};

export default CustomSelectLabel;
