import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import http from "../../../../../shared/http-request";
import { BASE_URL, SERVER_URL } from "../../../../../StaticArray/StaticArray";
import "../../../../../translations/i18n";
import { useTranslation } from "react-i18next";
import {
  onUserActivateAccountAdw,
  onUserRemoveActivateAccountAdw,
} from "../../../../../utils/network/lib/user";

const GoogleAdsTable = ({ accountData, getAccountDetails, loading }) => {
  // Configuring notifications library
  const { t } = useTranslation();

  toast.configure({
    position: toast.POSITION.BOTTOM_LEFT,
  });

  useEffect(() => {
    if (Object.keys(accountData).length > 0 && accountData.adwActiveAccountId) {
      setActiveId(accountData.adwActiveAccountId);
    }
  }, [accountData]);

  const [activeId, setActiveId] = useState("");

  const onToggle = (id) => {
    if (activeId === id) {
      setActiveId("");
      onUserRemoveActivateAccountAdw()
        .then((res) => {
          toast.success(t("settings_accDeactivatnSuccessful"));
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          getAccountDetails();
        });
    } else {
      setActiveId(id);
      onUserActivateAccountAdw({
        account_id: id,
      })
        .then((res) => {
          toast.success(t("settings_accActivatnsuccessful"));
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          getAccountDetails();
        });
    }
  };

  const onLogoutAdw = () => {
    onUserRemoveActivateAccountAdw()
      .then(() => null)
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        getAccountDetails();
      });
  };

  return (
    <React.Fragment>
      <div style={{ position: "relative" }} className="connect-acc-box">
        <div className="div-block-73">
          <div className="group-horizontal">
            <img
              src={`${BASE_URL}images/GDN_1GDN.png`}
              width="20"
              height="20"
              alt=""
              className="icon-small"
            />
            <h4 className="connect-acc-label">{t("settings_googleAds")}</h4>
          </div>

          {accountData && accountData.isAdwAccessible ? (
            <button
              id="btnDisconnectAdwords"
              className="button connect-saved-btn w-button"
              onClick={() => onLogoutAdw()}
            >
              {t("settings_logout")}
            </button>
          ) : (
            <a
              href={`${SERVER_URL}session/oauth/adw`}
              className="button connect-saved-btn w-button"
            >
              {t("settings_connect")}
            </a>
          )}
        </div>
        {accountData.adwActiveAccountId ? (
          <div></div>
        ) : (
          <div
            className="card-footer"
            style={{ borderTop: "1px solid #DADADA" }}
          >
            {t("settings_googleConnectText")}

            <a href="#" className="text-peasy-purple">
              {t("settings_clickHere")}
            </a>
          </div>
        )}

        {loading && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, .7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "10",
              top: "10px",
            }}
          >
            <img
              style={{ width: "100px", height: "100px" }}
              src="/images/assets/loading-deploy.gif"
              alt=""
            />
          </div>
        )}

        {accountData &&
          accountData.adwAccounts &&
          !accountData.adwordFallbackAccount && (
            <React.Fragment>
              <div className="connect-acc-list title-label">
                <div>
                  <div className="acc-id">{t("settings_accID")}</div>
                </div>
                <div className="acc-name">{t("settings_accName")}</div>
                <div className="acc-currency">{t("settings_currency")}</div>
                <div className="acc-action">{t("settings_action")}</div>
              </div>
              {accountData.adwAccounts.map((adwAccount) => (
                <div className="connect-acc-list">
                  <div className="acc-id">{adwAccount.id}</div>
                  <div className="acc-name">{adwAccount.name}</div>
                  <div className="acc-currency">{adwAccount.currency}</div>
                  <div className="acc-toggle w-embed">
                    <input
                      className="gtoggle"
                      name="gtoggleActive"
                      type="checkbox"
                      id={adwAccount.id}
                      checked={adwAccount.id === activeId}
                      data-currency={adwAccount.currency}
                      onChange={() => onToggle(adwAccount.id)}
                    />
                    <label className="toggle" for={adwAccount.id}>
                      {t("settings_toggle")}
                    </label>
                  </div>
                </div>
              ))}
            </React.Fragment>
          )}

        {accountData &&
        accountData.adwAccounts &&
        accountData.adwordFallbackAccount ? (
          <div className="connect-acc-list title-label">
            <div>{t("settings_googleConnectLabel")}</div>
          </div>
        ) : (
          ""
        )}
        {(!accountData ||
          accountData.adwAccounts === null ||
          accountData.adwAccounts === undefined) && (
          <div className="connect-acc-list title-label">
            <div>{t("settings_connectGoogleAccLabel")}</div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default GoogleAdsTable;
