import React, { Fragment } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const HeaderContainer = props => {
  const { campaignDetailsData } = props;
  const campaign = campaignDetailsData && campaignDetailsData.campaign;
  return (
    <Fragment>
      <span className="dash_link">
        {/* <Link to="#" className="back_link">
          Back to dashboard
        </Link> */}
      </span>
      <div className="camp_sec width100 ">
        <div className="camp_lft">
          <h3>{campaign && campaign.campaign_name}</h3>
          {campaign && (
            <p>
              {moment(campaign && campaign.start_date).format("MMM YYYY")} -{" "}
              {moment(campaign && campaign.end_date).format("MMM YYYY")}
            </p>
          )}
        </div>
        <div className="camp_rgt">
          <span className="Status">
            Status:{" "}
            {campaign && campaign.status === "ACTIVE" ? "Active" : "Inactive"}
          </span>
          <label className="switch">
            <input
              type="checkbox"
              checked={campaign && campaign.status === "ACTIVE"}
              disabled
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderContainer;
