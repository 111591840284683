import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import VideoThumbnail from "react-video-thumbnail";
import Logo from "../logo/Logo";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import Description from "../description/Description";
import SmallErrorText from "../../../common/smallErrorText/SmallErrorText";
import PopupAlert from "../../../common/popupAlert/PopupAlert";
import SliderComponent from "../../../common/Slider";
import PhotoComponent from "../photoComponent/PhotoComponent";
import CarouselPopUp from "./carouselPopUp";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import "./CarouselCard.css";
import Joyride from "react-joyride";

const CarouselCard = ({
  pageDetails,
  audiences,
  ctaList,
  card,
  carouselCardIds,
  deleteCarousalCard,
  updateCarouselData,
  carouselPopVisible,
  handleUpdateCarousalMedia,
  dataFromCarouselCard,
  changeCarousalArray,
  updateCarouselIndividualData,
  updateCarouselCtaData,
  updateMainCarouselData,
  platform,
  setNotification,
  notification,
}) => {
  // States
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);

  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);

  const [cardData, setCardData] = useState(card);

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [isThumbnail, setIsThumbnail] = useState(true);

  const [isErrorPopUp, setErrorPopUp] = useState(false);

  const [previousAudience, setPreviousAudience] = useState([]);

  const [errorArrayIndex, setErrorIndex] = useState([]);

  const [itemSelected, setItemSelected] = useState(null);

  //Effects

  // set options for audience list
  useEffect(() => {
    getAudienceOptions();
  }, [audiences]);

  useEffect(() => {
    decideCardData();
    console.log("carousle card", cardData);
  }, [card]);

  //Functions

  const decideCardData = () => {
    const object = {
      unique_id: card.unique_id || Math.floor(Math.random() * 1000),
      carousel_sources: card.carousel_sources || [],
      headline: card.headline || "",
      description: card.description || "",
      ids: card.ids || null,
      audiences: card.audiences || null,
      errors: card.errors || {},
      status: card.status || "",
      carousel_group_id: card.carousel_group_id ? card.carousel_group_id : "",
    };
    setCardData(object);
  };

  const getAudienceOptions = () => {
    if (audiences && audiences.length > 0) {
      let array = [];
      audiences.map((audience) => {
        if (!audience.media_channel[7]) {
          const object = {};
          object.value = audience.id;
          object.label = audience.name;
          array = [...array, object];
        }
      });
      setOptions(array);
    }
  };

  const onChange = (name, value, e) => {
    if (e) {
      let errorIndex = [...errorArrayIndex];
      if (previousAudience.length <= e.length) {
        let filterData = e.filter(
          (o) => !previousAudience.find((o2) => o.value === o2.value)
        );
        let selectedValue = filterData[0].value;
        let selectedAudience = audiences.find((r) => r.id === selectedValue);

        let isValid = [];
        cardData.carousel_sources.map((file, index) => {
          isValid[index] = false;
          if (file.type === 1) {
            if (
              selectedAudience &&
              selectedAudience.media_channel[5] &&
              selectedAudience.media_channel[5].budget_split > 0 &&
              file.widthRatio > 1
            ) {
              isValid[index] = false;
              errorIndex = [...errorIndex, { index, type: file.type }];
              setErrorIndex(errorIndex);
              setErrorPopUp(true);
              setIsPopupVisible(true);
            } else {
              isValid[index] = true;
              if (!isValid.includes(false)) {
                setPreviousAudience(e);
                const obj = { ...cardData, [name]: value };
                setCardData(obj);
              }
            }
          }

          if (file.type === 2) {
            if (
              selectedAudience &&
              selectedAudience.media_channel[5] &&
              selectedAudience.media_channel[5].budget_split > 0 &&
              file.duration > 120
            ) {
              isValid[index] = false;
              errorIndex = [...errorIndex, { index, type: file.type }];
              setErrorIndex(errorIndex);
              setErrorPopUp(true);
              setIsPopupVisible(true);
            } else {
              isValid[index] = true;
              if (!isValid.includes(false)) {
                setPreviousAudience(e);
                const obj = { ...cardData, [name]: value };
                setCardData(obj);
              }
            }
          }
        });
      } else {
        setPreviousAudience(e);
        const obj = { ...cardData, [name]: value };
        setCardData(obj);
      }
    } else {
      const obj = { ...cardData, [name]: value };
      setCardData(obj);
    }
  };

  // Handle delete
  const onDeleteClick = () => {
    const index = carouselCardIds.indexOf(cardData.unique_id);
    deleteCarousalCard(index);
  };

  const changeMainDataOnBlur = (object) => {
    const index = carouselCardIds.indexOf(cardData.unique_id);
    if (object) updateMainCarouselData(index, object);
    else updateMainCarouselData(index, cardData);
  };

  const getAudiencesFromIds = () => {
    if (cardData.audiences && cardData.audiences.length > 0) {
      let selectedAudiences = [];
      cardData.audiences.map((id) => {
        for (let i = 0; i < options.length; i++) {
          if (options[i].value === id)
            return (selectedAudiences = [...selectedAudiences, options[i]]);
        }
      });
      return selectedAudiences;
    }
    return [];
  };

  const updateCariuselData = (result) => {
    const object = {
      unique_id: cardData.unique_id,
      carousel_sources: result,
      headline: cardData.headline || "",
      description: cardData.description || "",
      ids: cardData.ids || null,
      audiences: cardData.audiences || null,
      errors: cardData.errors || {},
    };
    updateCarouselData(object, object.unique_id);
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const deleteMediaCard = (index) => {
    let newObject = cardData;
    newObject.carousel_sources.splice(index, 1);
    updateCarouselData(newObject, newObject.unique_id);
  };

  const changeCtaValue = (cta) => {
    let updatedValue = cta.replace("_", " ");
    return updatedValue;
  };

  const dummyContent = (data) => (
    <div
      style={{
        backgroundColor: "#E9EBED",
        paddingBottom: "1px",
        position: "relative",
        height: "100%",
      }}
    >
      <span className="imagecard__url imagecard__url--image-card">
        <input
          disabled={platform === "MESSENGER"}
          value={data.final_url}
          type="text"
          className="imagecard__input--headline"
          placeholder={t("campaign_enterURL")}
          style={{
            backgroundColor: "#E9EBED",
          }}
        />
      </span>

      <div
        className="imagecard__btn--cta--container "
        style={{
          position: "absolute",
          top: "-0.5rem",
          right: 0,
          border: !data.cta ? "2px solid red" : "1px solid black",
        }}
      >
        <button className="imagecard__btn--cta">
          {data.cta ? changeCtaValue(data.cta) : t("campaign_selectCTA")}
        </button>
      </div>

      <span className="imagecard__subheading imagecard__subheading--image-card">
        <input
          className="imagecard__input--headline"
          value={data.message}
          type="text"
          style={{
            backgroundColor: "#E9EBED",
            width: "11rem",
            borderBottom: data.message === "" ? "2px solid red" : "none",
          }}
          placeholder={t("campaign_enterCaption")}
        />
      </span>
      <span
        className="imagecard__span--message"
        style={{ position: "absolute", top: "60px", left: 0 }}
      >
        <input
          className="imagecard__input--headline"
          value={data.description}
          type="text"
          style={{
            backgroundColor: "#E9EBED",
            width: "11rem",
            borderBottom: data.description === "" ? "2px solid red" : "none",
          }}
          placeholder={t("campaign_enterDescription")}
        />
      </span>
    </div>
  );

  if (card.carousel_sources.length !== cardData.carousel_sources.length) {
    setCardData(card);
  }

  const config = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "20%",
  };

  return (
    <React.Fragment>
      {showDeletePopup && (
        <PopupAlert
          heading={t("campaign_areYouSure")}
          body={t("campaign_sureYouWantToDeletePost")}
          onNoClick={() => setShowDeletePopup(false)}
          onYesClick={() => {
            onDeleteClick();
            setShowDeletePopup(false);
          }}
        />
      )}
      
      <div className="carouselcard__container">
      <div className={`carouselcard__container__${cardData.carousel_group_id}`}>
        <div style={{ display: "flex" }}>
          <Logo
            url={
              pageDetails.page_image ||
              "https://randomuser.me/api/portraits/women/50.jpg"
            }
            style={{ marginTop: "2%", marginLeft: "2%" }}
          />

          <div className="carouselcard__heading--container">
            <span className="carouselcard__heading--bold">
              {pageDetails.name}
            </span>
            <span className="carouselcard__issponsored">
              {t("campaign_sponsored")}
            </span>
          </div>
        </div>
        {/* <span className="carouselcard__subheading">
          <input
            className="carouselcard__input--headline"
            value={cardData.headline}
            type="text"
            placeholder="Headline"
            onChange={(e) => onChange("headline", e.target.value)}
            onBlur={() => {
              changeMainDataOnBlur();
            }}
          />

          {cardData.errors && cardData.errors.headline && (
            <SmallErrorText
              style={{
                display: "block",
                marginTop: "10px",
                backgroundColor: "#faebd7",
                width: "fit-content",
              }}
              text={cardData.errors.headline}
            />
          )}
        </span> */}
        <Description
          text={cardData.headline}
          isEditable={isDescriptionEditable}
          onEditClick={() => setIsDescriptionEditable(true)}
          onChange={(name, value) => onChange(name, value)}
          onBlur={() => {
            setIsDescriptionEditable(false);
            changeMainDataOnBlur();
          }}
          commonStyle={{
            marginLeft: "5%",
            marginBottom: "10px",
            fontSize: "1.4rem",
            borderBottom: cardData.headline === "" ? "2px solid red" : "none",
          }}
        />
        {cardData.errors && cardData.errors.headline && (
          <SmallErrorText
            style={{
              display: "inline-block",
              backgroundColor: "rgb(250, 235, 215)",
              fontWeight: 500,
              fontSize: "12px",
              color: "rgb(145, 45, 43)",
              marginBottom: "10px",
              marginLeft: "5%",
            }}
            text={cardData.errors.headline}
          />
        )}
        <div className="carouselcard__photo--container">
          <SliderComponent config={config}>
            {cardData.carousel_sources.length
              ? cardData.carousel_sources.map((obj, i) => {
                  if (obj.type === 1) {
                    if (obj.file_object) {
                      return (
                        <div className="imageBox">
                          <PhotoComponent
                            url={URL.createObjectURL(obj.file_object)}
                          />
                          {dummyContent(obj)}
                        </div>
                      );
                    }
                    if (obj.path) {
                      return (
                        <div className="imageBox">
                          <PhotoComponent
                            url={`${BASE_URL}thumbnail/adimage?path=${obj.path}`}
                          />
                          {dummyContent(obj)}
                        </div>
                      );
                    }
                  }

                  if (obj.type === 2 && obj.file_object && isThumbnail) {
                    return (
                      <div className="imageBox">
                        <VideoThumbnail
                          key={obj.unique_id + "thumb"}
                          videoUrl={window.URL.createObjectURL(obj.file_object)}
                          thumbnailHandler={(preview) => {
                            const thumbnail = dataURLtoFile(
                              preview,
                              "thumbnail"
                            );
                            const newObj = {
                              ...obj,
                              thumbnail_object: thumbnail,
                            };
                            const index = carouselCardIds.indexOf(
                              cardData.unique_id
                            );
                            console.log("index index index", index);

                            dataFromCarouselCard(index, newObj);
                            // setCardData(newObj);
                          }}
                        />
                        {dummyContent(obj)}
                      </div>
                    );
                  }
                  if (obj.type === 2 && obj.path) {
                    return (
                      <div className="imageBox">
                        <PhotoComponent
                          url={`${BASE_URL}thumbnail/adimage?path=${obj.thumbnail}`}
                        />
                        {dummyContent(obj)}
                      </div>
                    );
                  }
                })
              : ""}
          </SliderComponent>
          <div id="carouselcard-layover" className="carouselcard__layover">
            <button
              onClick={() => setIsPopupVisible(true)}
              className="carouselcard__btn--edit"
            >
              {t("campaign_editCreative")}
            </button>
            <button
              onClick={() => setShowDeletePopup(true)}
              style={{ top: "61%" }}
              className="carouselcard__btn--edit"
            >
              {t("campaign_delPost")}
            </button>{" "}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "94%",
            marginLeft: "3%",
            marginTop: "10px",
            fontSize: "13px",
            color: "rgb(101, 107, 115)",
            marginTop: "12px",
            fontWeight: "bold",
            fontSize: "10px",
            marginBottom: "5px",
          }}
        >
          <span>
            <img
              style={{ marginTop: "-5px" }}
              src={`${BASE_URL}images/thumb-up.png`}
              alt=""
            />
            {"   "}
            {cardData.likes || 0}
          </span>
          <span
            style={{
              marginLeft: "50%",
            }}
          >
            {cardData.comments || 0}
            {"   "} {t("campaign_comments")}
          </span>
          <span
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "100%",
              backgroundColor: "#000",
              display: "inline-block",
              marginTop: "5px",
            }}
          />

          <span>
            {cardData.shares || 0}
            {"   "} {t("campaign_shares")}
          </span>
        </div>

        <div className="imagecard__likes--container">
          <span>
            <img
              src={`${BASE_URL}images/like.png`}
              className="imagecard__img--like"
              alt=""
            />
            {t("campaign_like")}
          </span>
          <span>
            <img
              src={`${BASE_URL}images/comment.png`}
              className="imagecard__img--like"
              alt=""
            />
            {t("campaign_comment")}
          </span>
          <span>
            <img
              src={`${BASE_URL}images/share.png`}
              className="imagecard__img--like"
              alt=""
            />
            {t("campaign_share")}
          </span>
        </div>
        <Select
          options={options}
          noOptionsMessage={() => <span>{t("campaign_noAudience")}</span>}
          isMulti
          value={getAudiencesFromIds()}
          className="carouselcard__audience--selector"
          placeholder={t("campaign_audience")}
          onChange={(e) => {
            setTimeout(() => {
              let ids = [];
              if (e) e.map((audience) => (ids = [...ids, audience.value]));
              onChange("audiences", ids, e);
            }, 10);
          }}
          onBlur={() => changeMainDataOnBlur()}
        />
        {cardData.errors && cardData.errors.audiences && (
          <SmallErrorText
            style={{
              fontSize: "12px",
              color: "rgb(145, 45, 43)",
              marginLeft: "5%",
              marginTop: "7px",
              display: "block",
              width: "fit-content",
              fontWeight: 500,
              backgroundColor: "#faebd7",
            }}
            text={cardData.errors.audiences}
          />
        )}
      </div>
      </div>
      
     
        {notification  && cardData.status == 'ERROR' && ( notification.map((notify) => {
        if(notify.data.details.object_type == "AdCarousel" && cardData && cardData.carousel_group_id == notify.data.details.object_id) {
          
          return (
          <Joyride
            steps={[
              {
                target: `.carouselcard__container__${cardData.carousel_group_id}`,
                content: `${notify.data.details.message.transalation_key}`,
              },
            ]}
            hideCloseButton={true}
            styles={{
              options: {
                arrowColor: '#8D02FA',
                primaryColor: '#8D02FA',
                beaconSize: 100,
              }
            }}
          />)
        }

      }))}
      
      {isPopupVisible && (
        <Fragment>
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: 0,
              left: 0,
              zIndex: 999,
              backgroundColor: "rgba(0, 0, 0, .5)",
            }}
          ></div>
          <CarouselPopUp
            setIsPopupVisible={setIsPopupVisible}
            deleteMediaCard={(index) => deleteMediaCard(index)}
            carouselData={cardData ? cardData.carousel_sources : []}
            updateCariuselData={(e) => updateCariuselData(e)}
            carouselPopVisible={carouselPopVisible}
            carousalId={cardData.unique_id}
            handleUpdateCarousalMedia={handleUpdateCarousalMedia}
            dataFromCarouselCard={dataFromCarouselCard}
            carouselCardIds={carouselCardIds}
            changeCarousalArray={changeCarousalArray}
            updateCarouselIndividualData={updateCarouselIndividualData}
            updateCarouselCtaData={updateCarouselCtaData}
            audiences
            ctaList={ctaList}
            isErrorPopUp={isErrorPopUp}
            errorArrayIndex={errorArrayIndex}
            setErrorPopUp={setErrorPopUp}
            setErrorIndex={setErrorIndex}
            setIsThumbnail={setIsThumbnail}
          />
        </Fragment>
      )}
      
      
    </React.Fragment>
  );
};

export default CarouselCard;
