import React from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";

const DateRangePickerContainer = ({
  startDate,
  endDate,
  calendarFocused,
  handleFocus,
  handleDataChange,
  orientation
}) => {
  return (
    <DateRangePicker
      onFocusChange={focusedInput => {
        handleFocus(focusedInput);
      }}
      isOutsideRange={() => false}
      startDate={startDate} // momentPropTypes.momentObj or null,
      startDateId="startDate" // PropTypes.string.isRequired,
      endDate={endDate} // momentPropTypes.momentObj or null,
      endDateId="endDate" // PropTypes.string.isRequired,
      onDatesChange={({ startDate, endDate }) =>
        handleDataChange(startDate, endDate)
      } // PropTypes.func.isRequired,
      focusedInput={calendarFocused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      // PropTypes.func.isRequired,
      displayFormat="MMM D, YYYY"
      orientation={orientation ? orientation : "horizontal"}
    />
  );
};

export default DateRangePickerContainer;
