import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import DateRangePickerContainer from "../../../../common/DateRangePicker";

const DropdownContainer = ({
  selectedOption,
  audiences,
  channels,
  startDate,
  endDate,
  options,
  handleChange,
  handleFocus,
  calendarFocused,
  handleDataChange,
  channel,
  audience
}) => {
  let windowWidth = window.innerWidth;
  return (
    <div className="drop_row width100">
      {/* <div className="drop_col">
        <Select
          value={selectedOption}
          onChange={e => handleChange(e, "selectedOption")}
          options={options}
        />
      </div> */}
      <div className="drop_col">
        <Select
          value={audience}
          onChange={e => handleChange(e, "audience")}
          options={audiences}
        />
      </div>
      <div className="drop_col">
        <Select
          value={channel}
          onChange={e => handleChange(e, "channel")}
          options={channels}
        />
      </div>
      <div className="drop_col col_ext">
        <DateRangePickerContainer
          handleDataChange={handleDataChange}
          handleFocus={handleFocus}
          startDate={startDate}
          endDate={endDate}
          calendarFocused={calendarFocused}
          orientation={windowWidth > 768 ? "horizontal" : "vertical"}
        />
      </div>
    </div>
  );
};
DropdownContainer.propTypes = {
  options: PropTypes.array,
  selectedOption: PropTypes.object,
  handleChange: PropTypes.func.isRequired
};

export default DropdownContainer;
