import * as types from "../actions/actionTypes";

const initialState = {
  allFeatures: [],
};

export function featureReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_ALL_FEATURES_SUCCESS:
      return {
        ...state,
        allFeatures: payload,
      };
    case types.GET_CURRENT_FEATURE_SUCCESS:
      return {
        ...state,
        currentFeatures: payload,
      };
    case types.ADD_NEW_FEATURE_SUCCESS:
      return {
        ...state,
        allFeatures: [...state.allFeatures, payload],
      };
    case types.UPDATE_FEATURE_SUCCESS:
      const featuresList = state.allFeatures.map((feature) => {
        if (feature.id === payload.id)
          return { id: payload.id, name: payload, path: payload.path };
        return feature;
      });
      return {
        ...state,
        allFeatures: featuresList,
      };
    case types.DELETE_FEATURE_SUCCESS:
      return {
        ...state,
        allFeatures: state.allFeatures.filter(
          (feature) => feature.id !== parseInt(payload)
        ),
      };
    default:
      return state;
  }
}
