import React, { useState, useEffect } from "react";
import Select from "react-select";
import http from "../../../shared/http-request";
import CustomSelectOption from "../customSelectOption/CustomSelectOption";
import CustomSelectLabel from "../customSelectLabel/CustomSelectLabel";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import SmallErrorText from "../../../common/smallErrorText/SmallErrorText";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";

import "./FbIncludeAudience.css";
import {
  onAudienceInterestSuggestion,
  onAudienceSearchInterest,
} from "../../../utils/network/lib/audience";

const FbIncludeAudience = ({
  getSelectValue,
  onSelectChange,
  errors,
  selectStyles,
  audienceListOptions,
  interestsList,
  audienceData,
}) => {
  const { t } = useTranslation();

  const [focus, setFocus] = useState(false);

  const [options, setOptions] = useState([]);

  const [fbIncludeAudienceSuggestions, setFbIncludeAudienceSuggestions] =
    useState([]);

  useEffect(() => {
    let selectedData = getSelectValue("interest");
    if (selectedData && selectedData.length > 0) {
      onSelectValue(selectedData);
    }
  }, [audienceListOptions]);

  useEffect(() => {
    console.log("fbIncludeAudienceSuggestions => ", options);
    if (
      audienceListOptions.length > 0 &&
      fbIncludeAudienceSuggestions.length === 0
    ) {
      setOptions(interestsList);
      const element = document.getElementById("fb_select1");
      element.firstChild.addEventListener("click", () => setFocus(true));
    }
    const fb_select1 = document.getElementById("fb_select1");
    const element = document.getElementById("selectpostpopup__container");
    element.addEventListener("click", (e) => {
      if (fb_select1.contains(e.target)) {
        setFocus(true);
      } else {
        setFocus(false);
        if (fbIncludeAudienceSuggestions.length === 0) {
          setOptions(interestsList);
        } else {
          setOptions(fbIncludeAudienceSuggestions);
        }
      }
    });
  }, [interestsList, audienceListOptions, fbIncludeAudienceSuggestions]);

  let timer = 500;

  const onInputChange = (query) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      if (query.length === 0) return;
      const res = await onAudienceSearchInterest({
        params: {
          q: query,
        },
      });
      if (res.status === 200) {
        let array = [];
        res.data.data.map((interest) => {
          const object = {};
          const values = interest.value.split("|");
          object.value = values[0];
          // object.label = values[1];
          values.length > 2
            ? (object.label = values[1] + "|" + values[2])
            : (object.label = values[1]);
          object.type = interest.type;
          array = [...array, object];
        });
        setFocus(true);
        setOptions(array);
      }
    }, 500);
  };
  const onSelectValue = async (selectedValuesOption) => {
    if (selectedValuesOption == null) {
      setOptions(interestsList);
      setFbIncludeAudienceSuggestions([]);
      return;
    }
    let query = [];

    selectedValuesOption.map((param) => {
      query.push({ type: "interests", id: parseInt(param.value) });
    });

    if (query.length === 0) return;
    await onAudienceInterestSuggestion({
      params: {
        q: query,
      },
    }).then((res) => {
      if (res.status === 200) {
        let array = [];
        res.data.data.map((interest) => {
          const object = {};
          const values = interest.value.split("|");
          object.value = values[0];
          object.label = values[1] + "|" + values[2];
          // object.type = interest.type;
          object.type = values[2];
          array = [...array, object];
        });
        setFbIncludeAudienceSuggestions(array);
        setOptions(array);
      }
    });
  };

  return (
    <div
      style={{ marginTop: "40px" }}
      className="fbincludeaudience__container"
      id="fbincludeaudience__container"
    >
      <div className="fbincludeaudience__heading--container">
        <div className="fbincludeaudience__heading">
          <img
            src="/images/facebook_1facebook.png"
            className="fbincludeaudience-fb-icon"
            alt=""
          />
          {t("includeAudience")}
        </div>
        <div>
          <span
            className="fbincludeaudience_span_hover_pointer"
            onClick={() => {
              setOptions(audienceListOptions);
              console.log(audienceListOptions);
              setFocus(true);
            }}
          >
            {t("savedAudience")}
            <img
              className="fbincludeaudience__img--arrow"
              src="/images/media-projection.png"
              alt=""
            />
          </span>
        </div>
      </div>
      <Select
        isMulti
        id="fb_select1"
        menuIsOpen={focus}
        components={{
          Option: (optionProps) => (
            <CustomSelectOption
              {...optionProps}
              audienceData={audienceData}
              parentComponent="fbInclude"
            />
          ),
          MultiValueLabel: (labelProps) => (
            <CustomSelectLabel {...labelProps} />
          ),
        }}
        options={options}
        className="fbincludeaudience__input--select"
        placeholder={t("includeAudiencePlaceHolder")}
        value={getSelectValue("interest")}
        onChange={(e) => {
          setFocus(false);
          setOptions(interestsList);
          onSelectChange("interest", e);
          onSelectValue(e);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onInputChange={(e) => onInputChange(e)}
        styles={selectStyles}
      />

      {errors.interest && (
        <SmallErrorText
          style={{
            backgroundColor: "#FEF6F6",
            marginLeft: "5%",
            marginTop: "-10px",
            display: "block",
            marginBottom: "16px",
            width: "fit-content",
          }}
          text={errors.interest}
        />
      )}

      <p className="fbincludeaudience__para">{t("includeAudiencePara")}</p>
    </div>
  );
};

export default FbIncludeAudience;
