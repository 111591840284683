import React from "react";
import HeaderOption from "./headerOption/HeaderOption";
import "./PopupHeader.css";
import "../../../../translations/i18n";
import { useTranslation } from "react-i18next";

const PopupHeader = ({
  postsType,
  setPostsType,
  isCarouselPopUp,
  campaignObjective,
  campaignPlatform,
}) => {
  const { t } = useTranslation();
  return (
    <div className="popupheader__container">
      {!isCarouselPopUp &&
      campaignObjective !== "LEAD" &&
      !(campaignObjective === "TRAFFIC" && campaignPlatform === "MESSENGER") ? (
        <HeaderOption
          onClick={() => setPostsType("facebook")}
          iconClass="fab fa-facebook-square"
          text={t("campaign_fbPage")}
          isActive={postsType === "facebook"}
        />
      ) : (
        ""
      )}
      <HeaderOption
        onClick={() => setPostsType("uploadImage")}
        iconClass="fa fa-upload"
        text={t("campaign_uploadCreative")}
        isActive={postsType === "uploadImage"}
      />
      {/* <HeaderOption
      onClick={() => setPostsType("instagram")}
      iconClass="fab fa-instagram"
      text="Instagram"
      isActive={postsType === "instagram"}
    />
    <HeaderOption
      onClick={() => setPostsType("branded")}
      iconClass="far fa-handshake"
      text="Branded Content"
      isActive={postsType === "branded"}
    /> */}
    </div>
  );
};

export default PopupHeader;
