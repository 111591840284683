import React from 'react';
import { connect } from "react-redux";

const permissionsHoc = Component => {
  const permissionsHocMain = props => {
    let permissions={}
    if(props.featureId){
      const feturePermission=props.permissions.currentPermissions.filter((p)=>p.id===props.featureId)
      if(feturePermission.length>0){
        permissions=feturePermission[0]
      }
    }
    return (
        <Component {...props} user={props.auth.user} permissions={permissions} />
    );
  } 
  const mapStateToProps = state => {
    return {
        auth: state.auth,
        permissions: state.permissions
    };
  };
  return  connect(
    mapStateToProps,
    null
  )(permissionsHocMain);
};

  export default permissionsHoc;
