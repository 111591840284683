import moment from "moment";
import { GET_CAMPAIGN_METRIC_CONSTANTS } from "../constants";
import { GET_CAMPAIGNMETRIC_URL } from "../routes";
import http from "../../shared/http-request";
import { BASE_URL } from "../../StaticArray/StaticArray";
import { onGetCampaignMetrics } from "../../utils/network/lib/campaign";

export const getCampaignMetrics = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_CAMPAIGN_METRIC_CONSTANTS.GET_CAMPAIGN_METRIC_STARTED,
    });

    return onGetCampaignMetrics(payload)
      .then((res) => {
        const { status, data } = res;
        return dispatch({
          type: GET_CAMPAIGN_METRIC_CONSTANTS.GET_CAMPAIGN_METRIC_SUCCESS,
          payload: { status, data },
        });
      })
      .catch((error) => {
        if (error.toString() === "TypeError: Network request failed") {
          return dispatch({
            type: GET_CAMPAIGN_METRIC_CONSTANTS.GET_CAMPAIGN_METRIC_NET_FAILED,
            payload: { status: 500, data: { message: error.toString() } },
          });
        }
        return dispatch({
          type: GET_CAMPAIGN_METRIC_CONSTANTS.GET_CAMPAIGN_METRIC_FAILED,
          payload: { ...error },
        });
      });
  };
};

export const flterCampaignMetrics = (payload) => {
  return (dispatch) => {
    const { newStartDate, newEndDate, campaignMetricData } = payload;

    let metric = Object.assign({}, campaignMetricData);
    const chart_data =
      metric && metric.data && Object.assign(metric.data.chart_data);
    for (let data in chart_data) {
      for (const datum in chart_data[data]) {
        if (!moment(datum).isBetween(newStartDate, newEndDate, "days", "[]"))
          delete chart_data[data][datum];
      }
    }
    dispatch({
      type: GET_CAMPAIGN_METRIC_CONSTANTS.FILTER_CAMPAIGN_METRIC_SUCCESS,
      payload: metric,
    });
  };
};
