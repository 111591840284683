export const TRANSLATIONS_MY = {
  // sidebar
  dashboard: "Dashboard",
  settings: "Tetapan",
  ecommerce: "E-Dagang",
  campaigns: "Kempen",
  contacts: "Contacts",
  help: "Bantuan",
  translate: "Bahasa",

  // toast.errors
  brand_selectToContinue: "Sila pilih audience untuk melanjutkan",
  brand_selectCategoryToContinue: "Sila pilih categori untuk melanjutkan",
  brand_reachNotGreater: "Jumlah Reach tidak boleh melebihi 100",
  brand_qualifiedNotGreater: "Jumlah Qualified tidak boleh melebihi 100",
  brand_salesNotGreater: "Jumlah Jualan tidak boleh melebihi 100",
  brand_QualNotGrtrThanRch:
    "Jumlah Qualified tidak boleh melebihi jumlah Reach",
  brand_salesNotGrtrThanQual:
    "Jumlah Jualan tidak boleh melebihi jumlah Qualified",
  brand_brandName: "Sila nyatakan nama bisnis anda",
  brand_selectPixel: "Sila pilih pixel untuk menlanjutkan",
  brand_selectPage: "Sila pilih Facebook Page untuk menlanjutkan",
  brand_notValid: "Ini bukan URL yang sah",
  brand_entrUrl: "Sila nyatakan URL website atau produk anda",
  brand_entrValidUrl: "Sila nyatakan URL yang sah",
  brand_enterDistributn: "Sila pilih kaedah pengedaran bisnis anda",

  // Common keywords
  brand_back: "Kembali",
  brand_continue: "Lanjutkan",
  brand_next: "Seterusnya",
  brand_connect: "Sambung",
  brand_enterContinue: "Tekan Enter untuk melanjutkan",
  brand_update: "Kemaskini",
  brand_cancel: "Batal",
  brand_error: "Opps! Ralat Semasa Melakukan Aksi Ini",

  campaign_back: "Kembali",
  campaign_next: "Seterusnya",
  campaign_none: "Tiada",
  campaign_of: "daripada",
  campaign_enterAmount: "Sila masukkan jualan kempen",

  settings_back: "Kembali",
  settings_save: "Simpan",
  settings_submit: "Hantar",
  settings_cancel: "Batal",

  // Add Audience
  brand_selectAud: "Pilih Audience untuk Brand ini",

  // CreateBrandCategory
  brand_descNature: "Bagaimana anda gambarkan perniagaan anda?",
  brand_questionDesc:
    "Pilih industri yang paling menggambarkan produk dan perkhidmatan anda. Kami menetapkan strategi, penanda aras dan cadangan yang berbeza yang disesuaikan untuk setiap industri. Cadangan kami diperibadikan berdasarkan kempen, halaman Facebook dan data laman web anda yang lalu. Sekiranya anda tidak mempunyai data yang mencukupi untuk AI kami, kami akan menambah cadangan generik dari rakan industri. Kemudahan mempunyai kumpulan data pembelajaran kolektif yang berkembang untuk 26 industri yang berbeza.",
  brand_brandCategory:
    "Pilih kategori yang paling tepat menggambarkan jenama ini",

  // CreateFbconnect
  brand_adHeading: " Mari Sambungkan Akaun Ads anda",
  brand_adtext:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla massa dolor, condimentum a bibendu at, varius vel orci. Aenean nulla felis, convallis eget magna at",
  brand_vidIntro: "Video Pengenalan",
  brand_connectBtn: "Klik butang di bawah untuk menghubungkan akaun anda",
  brand_fbAd: "Facebook Ads",
  brand_googleAd: "Google Ads",

  // CreateBrandLastStep
  brand_aiTypo: "Kami sedang menciptat dan melatih AI anda sendiri",
  brand_alertDialog:
    "Sekiranya anda pernah menjalankan kempen sebelumnya, tunggu sementara AI kami mengumpulkan dan menganalisis data kempen anda yang lalu. Peasy akan menggunakan pembelajaran ini untuk memperibadikan cadangan untuk kempen anda. Pembelajaran selesai apabila meter AI dalam profil perniagaan anda meningkat dari 0mb dan seterusnya. Meter tersebut menunjukkan jumlah data yang diserap untuk membuat dan melatih AI peribadi anda",
  brand_articleHeading:
    "Untuk maklumat lebih lanjut mengenai funnel dan pengoptimuman yang disyorkan, sila baca artikel kami di sini. ",
  brand_heading: "Berdasarkan maklumat anda",
  brand_dataHeading: "Data anda",
  brand_formTitle: "Terangkan sedikit sebanyak mengenai penjualan offline anda",
  brand_averageTitle: "Apakah peratusan leads yang akan mencapai status ini:",
  brand_salesDataHeadng: "Data penjualan luar talian",
  brand_backToBrand: "Kembali ke Persediaan Profil Bisnis",
  brand_benchmark: "Penanda aras untuk",
  brand_popupHelperTextReach:
    "Leads yang boleh dihubungi (nombor telefon atau e-mel berfungsi yang sah)",
  brand_popupHelperTextQualified:
    "Leads yang sesuai dengan profil sasaran anda dan mempunyai minat yang tulen",
  brand_popupHelperTextSales:
    "Leads yang berubah menjadi pelanggan yang membayar.",
  brand_updateText: "Kemas kini Profil Bisnis",
  brand_createText: "Cipta Profil Bisnis",

  //  createBrandName
  brand_BPName: "Nama Profil Perniagaan",
  brand_questionDescription:
    "Cipta nama yang berbeza untuk mengenal pasti profil perniagaan ini. Anda boleh menggunakan nama berdaftar syarikat anda (Acme Sdn Bhd) atau bahagian produk (Perniagaan Pengedar Acme).",
  brand_typeBrand: "Nyatakan nama bisnis anda...",

  // CreatebrandPixels
  brand_pixelEvent: "Pilih Pixel Event",
  brand_pixelBrand: "Pilih Pixel Event untuk bisnis ini",

  // createBrandSelectFbPage
  brand_selectFbPage: "Pilih halaman Facebook",
  brand_FbPageList: "Senaraikan halaman Facebook anda",

  // createBrandWebsite
  brand_webUrl: "Sila masukkan URL Laman Web anda.",
  brand_questionDescriptionWebsite:
    "Tidak mempunyai laman web? Jangan risau, anda boleh menggunakan laman web pesaing atau ulasan produk, AI kami akan mencari kata kunci yang relevan dan menggunakannya untuk memperibadikan cadangan untuk kempen anda.",

  // createDistributionMethod
  brand_selectcustomer:
    "Pilih satu pelanggan sasaran untuk profil perniagaan ini",
  brand_businessToBusiness: "Perniagaan ke perniagaan",
  brand_otherBusiness: "Saya ingin menjual kepada perniagaan lain.",
  brand_businessToDistributor: "Perniagaan kepada Pengedar",
  brand_newDistributors: "Saya mahu merekrut pengedar dan ejen baru",
  brand_businessToConsumer: "Perniagaan kepada Pengguna",
  brand_newCustomer: "Saya mahu mendapatkan pelanggan baru.",

  // CAMPAIGN

  // toast.errors
  campaign_endDateAfterStartDate: "Tarikh Akhir Mesti Selepas Tarikh Mula!",
  campaign_MoreThanFourdays: "Tempoh Mesti Lebih Dari 4 Hari!",
  campaign_StartDateLessThanEndDate:
    "Tarikh Mula Harus Kurang Dari Tarikh Akhir!",
  campaign_selectLocation: "Sila Pilih sekurang-kurangnya satu lokasi!",
  campaign_name: "Sila Masukkan Nama Kempen!",
  campaign_nameMorethanTenChar: "Nama Kempen Harus Lebih Dari 10 Huruf!",
  campaign_selectChoice: "Sila pilih satu pilihan",
  campaign_phoneFieldNoneerror: "Medan telefon tidak boleh menjadi tidak ada.",
  campaign_formFieldMappingfailed:
    "Pemetaan medan borang tidak boleh dilanjutkan.",
  campaign_formFieldSaveError: "Gagal menyimpan nilai medan borang.",
  campaign_noListData: "Opps tiada data senarai leads",
  campaign_gettingAccIdFailed: "Gagal mendapatkan id akaun,sila hubungi admin",
  campaign_selectOnePixelevent:
    "Sila Pilih Sekurang-Kurangya Satu Pixel Event !",
  campaign_enterTargetValue: "Sila masukkan nilai sasaran",
  campaign_selectRecommendatnMethod: "Sila pilih kaedah cadangan",
  campaign_budgetMinimumError: "Bajet Harus Minimum",
  campaign_budgetMoreThanBalance: "Belanjawan kempen melebihi baki akaun anda",
  campaign_selectChoice: "Sila pilih satu pilihan",
  campaign_defaultNotificatn: "Notifikasi !",
  campaign_successNotificatn: "Sukses !",
  campaign_errorNotificatn: "Ralat !",
  campaign_warningNotificatn: "Amaran!",
  campaign_infoNotificatn: "Maklumat Lanjut !",
  campaign_customNotificatn: "Custom Style Notification with css class!",
  campaign_selectObjective: "Sila pilih satu objektif !",
  campaign_minBudget: "Bajet Harus Minimum",
  campaign_budgetMoreThanBalance: "Belanjawan kempen melebihi baki akaun anda",
  campaign_fillAccurateTarget: "Sila Isi Sasaran Yang Tepat !!",
  campaign_selectPlatform: "Sila Pilih Platform !",
  campaign_selectPixelEvent: "Sila Pilih Sekurang-Kurangnya Satu Pixel Event !",

  // CampaignEndDate
  campaign_campaignDate: "Apakah tarikh tamat kempen anda?",
  campaign_startDate: "Tarikh Mula",
  campaign_endDate: "Tarikh Tamat",

  // campaignLocation
  campaign_targetLocation: "Lokasi Sasaran Kempen?",
  campaign_creatingCampaignText: "Mencipta Kempen...",
  campaign_createCampaignText: "Cipta Kempen",
  campaign_enterTargetLocation: "Masukkan Lokasi Sasaran Kempen...",

  //   CampaignName
  campaign_headingCampaignName: "Name Kempen",
  campaign_enterCampaign: "Masukkan Nama Kempen...",

  // selectFunnelType
  campaign_currentFunnelLabel: "Sales Funnel Anda:",
  campaign_explanatnLabel: "Penjelasan:",
  campaign_fullFunnelExplanation:
    "Full Funnel mengambil kira customer journey (kebocoran, keluasan) berbanding sasaran penjualan anda. Dengan menggunakan pilihan Full Funnel, Peasy secara automatik akan mencipta beberapa kempen dengan objektif yang berbeza, masing-masing menyasar fasa funnel yang berlainan. Peasy secara automatik akan mengagihkan bajet pemasaran ke seluruh kempen untuk memastikan penjualan maksimum dan berkesan. Dengan penggunaan full funnel, anda jimat masa daripada perancangan kempen manual dan memastikan kebarangkalian yang lebih bagus untuk mencapai sasaran penjualan",
  campaign_strategyLabel: "Bagaimana anda mahu Peasy membuat strategi anda?",
  campaign_fullFunnelHeading: "Full Funnel",
  campaign_fullFunnelcardDescription:
    "AI mungkin mencipta beberapa kempen COLD, WARM dan HOT. Setiap satu menyasarkan fasa yang berbeza dalam sales funnel anda. AI kami akan merancang dan mencipta strategi digital. Dicadangkan bagi pengguna yang tidak mempunyai strategi digital.",
  campaign_singleFunnelHeading: "Single Funnel",
  campaign_singleFunnelCardDescription:
    "Cipta kempen tunggal dengan objektif pilihan sendiri. AI tidak mempertimbangkan sales funnel semasa membuat ramalan. Dicadangkan untuk pengguna yang berpengalaman.",

  // formFieldMapping
  campaign_mapFormHeading: "Padankan Medan Borang Anda",
  campaign_phone: "telefon",
  campaign_name: "Nama",
  campaign_email: "Emel",

  // LeadForm
  campaign_selectExistingForm: "Pilih Borang Lead",
  campaign_createNewform: "Cipta Borang Baru",
  campaign_leadFormsList: "Senaraikan borang leads anda",

  // Method
  campaign_recommendText: "Saya ingin Peasy cadangkan berdasarkan",
  campaign_selectMethod: "Pilih Kaedah",
  campaign_target: "Sasaran",
  campaign_budget: "Bajet",
  campaign_fillIn: "Isikan",
  campaign_minSpent: "Perbelanjaan minima adalah",

  // TargetCategory
  campaign_wantToTarget: "Saya ingin menyasarkan:",
  campaign_newCustomers: "Pelanggan Baru",
  campaign_setToFindCustomers:
    "Tetapkan kempen saya untuk mencari pelanggan baru",
  campaign_driveRepeatSalesOrUpsell: "Penjualan Berulang ataupun upsell",
  campaign_setToExistingCustomers:
    "Tetapkan kempen saya untuk menyasarkan pelanggan seadanya",
  campaign_both: "Kedua-dua",
  campaign_setToNewExistingCustomers:
    "Tetapkan kempen saya untuk menyasarkan pelanggan baru dan pelanggan seadanya",

  // objectives
  campaign_reachImpression: "Reach & Impression",
  campaign_awarenessBrand:
    "Saya mahukan lebih banyak kesedaran dan penarikan jenama",
  campaign_clicks: "Clicks",
  campaign_moreClicks: "Saya mahu mendapatkan lebih banyak klik untuk ads saya",
  campaign_trafficOrReply: "Traffic/Reply",
  campaign_moreVisitOrreplies:
    "Saya mahukan orang untuk lawati laman web saya ataupun balas messenger saya",
  campaign_conversion: "Conversion",
  campaign_valuableActions:
    "Saya mahu orang mengambil tindakan berharga di laman web saya",
  campaign_engagement: "Engagement",
  campaign_lead: "Leads",
  campaign_valuableLeads: "Saya mahukan leads berharga untuk produk saya",
  campaign_chooseObjectives: "Pilih objektif anda",

  // spendCampaign
  campaign_amountforcampaign:
    "Berapa banyak yang anda mahu belanjakan untuk kempen ini",
  campaign_minspent: "Perbelanjaan minima adalah",
  campaign_typeAmount: "Taip [jumlah] ...",

  // targetCampaign
  // campaign_target: "Berapakah sasaran anda",
  campaign_enterTarget: "Masukkan nilai sasaran anda",
  campaign_typeNumberOf: "Taipkan bilangan ",
  campaign_here: " sini",

  // FbMessenger
  campaign_whereToTakeBusiness: "Di mana anda mahu mengambil perniagaan anda?",
  campaign_fbMessenger: "Fb Messenger",
  campaign_website: "Laman web",

  // pixel_event
  campaign_selectPixelEvent: "Pilih Pixel event",
  campaign_pixelEventList: "Senaraikan pixel event",

  // SETTINGS

  // toast.errors
  settings_userProfileEdited: "Profil pengguna berjaya diedit",
  settings_accDeactivatnSuccessful: "Akaun berjaya dinyahaktifkan",
  settings_accActivatnsuccessful: "Akaun berjaya diaktifkan",
  settings_userUnsupported: "Fungsi ini tidak disokong untuk pengguna ini",
  settings_copied: "Disalin!",
  settings_smthngWentWrong: "Oops! Kesalahan telah berlaku.",
  settings_APIKeyGenerated: "Berjaya menghasilkan kunci API",
  settings_addedAPIInList: "Berjaya menambahkan API ini dalam senarai",
  settings_noBusinessProfile: "Tiada Profil Bisnis",
  settings_selectProfileFirst: "Sila pilih profil terdahulu",

  // SideBar
  settings_userProfile: "Profil Pengguna",
  settings_connectnSetup: "Persediaan Sambungan",
  settings_usersAndTeam: "Pengguna & Tim",
  settings_billing: "Maklumat Bil",
  settings_apiKey: "API Key",

  // UserProfile
  settings_phoneNoLength: "Nombor telefon harus 10 digit",
  settings_enterValidPhoneNo: "Sila masukkan nombor telefon yang sah",
  settings_enterPhoneNo: "Sila masukkan nombor telefon",
  settings_mismatchedPasswords: "Kata laluan tidak sepadan",
  settings_confirmPassword: "Sila pastikan kata laluan anda",
  settings_passwordFieldNotBlank: "Kata laluan mesti diisi",
  settings_nameNotBlank: "Nama mesti diisi",
  settings_enterValidEmail: "Sila masukkan emel yang sah ",
  settings_emailCantBeBlank: "Emel mesti diisi",
  settings_userProfileHeading: "Profil Pengguna",
  settings_phoneNumber: "Nombor HP",
  settings_emailAddr: "Emel",
  settings_currency: "Mata wang",
  settings_indonesiaRupiah: "IDR Indonesia Rupiah",
  settings_malaysiaRinggit: "MYR Malaysia Ringgit",
  settings_singaporeDollar: "SGD Singapore Dollar",
  settings_USDollar: "USD United State Dollar",
  settings_vietnamDong: "VND Vietnam Dong",
  settings_japaneseYen: "JPY Japanese Yen",
  settings_confirmPassword: "Pastikan kata laluan",
  settings_password: "Kata laluan",

  // ConnectionSetup
  settings_connectionSetup: "Persediaan Sambungan",

  // FbAdsTable and  googleAdsTable
  settings_fbAds: "Facebook Ads",
  settings_logout: "Log keluar",
  settings_connect: "Sambung",
  settings_fbConnectText:
    "Sambungkan akaun Facebook Ads anda dengan Peasy. Jika anda tidak mempunyai sebarang akaun Facebook Ads, atau ingin tahu lebih mengenai akaun Facebook Ads.",
  settings_clickHere: "klik di sini",
  settings_accID: "ID Akaun",
  settings_accName: "Nama Akaun",
  settings_currency: "Mata wang",
  settings_action: "Pilih",
  settings_toggle: "Togol",
  settings_activeAdAcc: "Akaun Iklan anda mesti aktif",
  settings_notEligible: "Tidak layak",
  settings_connectAccLabel:
    "Anda sedang menggunakan Akaun Facebook Ads sementara. Sila buat akaun Facebook Ads yang baru atau minta akses Akaun Facebook Ads dari syarikat atau pelanggan anda.",
  settings_fbConnectLabel:
    "Anda sedang menggunakan Akaun Facebook Ads sementara. Sila sambungkan Peasy dengan akaun Facebook Ads anda.",

  settings_googleAds: "Google Ads",
  settings_googleConnectText:
    "Sambungkan akaun Google Ads anda dengan Peasy. Jika anda tidak mempunyai sebarang akaun Google Ads, atau ingin tahu lebih mengenai akaun Google Ads.",
  settings_googleConnectLabel:
    "Anda sedang menggunakan Akaun Google Ads sementara. Sila buat akaun Google Ads yang baru atau minta akses Akaun Google Ads dari syarikat atau pelanggan anda.",
  settings_connectGoogleAccLabel:
    "Anda sedang menggunakan Akaun Google Ads sementara. Sila sambungkan Peasy dengan akaun Google Ads anda.",

  // UserTeam
  settings_inviteUsers: "Ajak pengguna baru",
  settings_usersAndTeam: "Pengguna & Tim",
  settings_totalBalance: "Jumlah baki perbelanjaan iklan",
  settings_user: "Pengguna",
  settings_quota: "Kuota",
  settings_balanceMonth: "Baki perbelanjaan iklan bulan ini",
  settings_status: "Status",
  settings_loginAs: "LOGIN SEBAGAI",
  settings_revoke: "Batalkan Akses",

  // billing
  settings_unsubscribe: "Hentikan Langganan",
  settings_subscribe: "Langgan",
  settings_makePayment: "Bayar",
  settings_upgrade: "Naik Taraf",
  settings_downgrade: "Turun Taraf",
  settings_billingScreen: "Maklumat Bil",
  settings_billedMonthly: "Dibil sebulan",
  settings_adSpentLimit: "Had perbelanjaan iklan sebulan.",
  settings_renewsEveryMonth: "Diperbaharui setiap bulan.",
  settings_noOfUserAcc: "Bilangan akaun",
  settings_maxDataStorage: "Penyimpanan data maksimum",
  settings_GB: "gb",
  settings_maxRetentionPeriod: "Tempoh penyimpanan data maksimum",
  settings_month: "bulan",
  settings_availCredit: "Kredit sedia ada",
  settings_topUp: "Tambah Nilai",
  settings_date: "Tarikh",
  settings_transaction: "Transaksi",
  settings_amount: "Jumlah",
  settings_credit: "Kredit",
  settings_balance: "Baki Kredit",
  settings_loadMoreTransactns: "Muat lebih banyak transaksi",

  // APIKey
  settings_businessAPIKey: "API Key Profil Bisnis",
  settings_addNew: "Tambah Baru",
  settings_ApiKey: "ApiKey",
  settings_businessProfileName: "Nama Profil Bisnis",
  settings_integratns: "Integrasi",
  settings_integratn: "Integrasi",
  settings_type: "Jenis",
  settings_apiKey: "API Key",
  settings_webhook: "Webhook",
  settings_noIntegratnAvail: "Tiada integrasi dijumpai",
  settings_addIntegratn: "Tambahkan Integrasi",
  settings_integratnName: "Nama Integrasi",
  settings_webhookUrl: "Webhook URL",
  settings_type: "Jenis",
  settings_peasysales: "Peasy Sales",
  settings_others: "Selebihnya",
  settings_profiles: "Profil",
  settings_status: "Status",
  settings_selectBusinessProfile: "Sila pilih Profil Bisnis",
  settings_generate: "Janakan",

  // DASHBOARD
  dashboard_business_profile_text: "Profil Bisnis",
  dashboard_profiles: "Profil",
  dashboard_conversionFunnel: "Sales Funnel",
  dashboard_ad_spend: "Had perbelanjaan iklan:",
  dashboard_noProfilesText:
    "Tiada Profil Bisnis dijumpai. Sila buat Profil Bisnis baru.",
  dashboard_createCampaign: "+ Kempen Baru",
  dashboard_campaignReport: "Laporan Kempen",
  dashboard_funnelReport: "Laporan Funnel",
  dashboard_ecommerce: "E-Dagang",
  dashboard_metric: "Metrik",
  dashboard_metric7days: "7 Hari Lepas",
  dashboard_metric15days: "15 Hari Lepas",
  dashboard_metric30days: "30 Hari Lepas",
  dashboard_campaign: "Kempen",
  dashboard_noMetricText: "Mohon maaf, metriks belum tersedia",
  dashboard_noCampaigns: "Tiada Kempen",
  dashboard_campaignList: "Senarai Kempen",
  dashboard_Status: "Status",
  dashboard_activeCampaign: "Kempen aktif",
  dashboard_pausedCampaign: "Kempen dijeda",
  dashboard_archivedCampaign: "Kempen diarkibkan",
  dashboard_noCampaignsFound: "Tiada kempen dijumpai!!",
  dashboard_deleteCamapaign: "Anda yakin ingin menghapus kempen ini? ",

  // toast.errors
  dashboard_errorWhilefetchingProfiles:
    "Opps! Ralat berlaku. Sila muat semula halaman ini",
  dashboard_somethingWentWrong: "Opps! Ralat berlaku",
  dashboard_somethingWrongWhilePerformingAction: "Opps! Ralat berlaku.",

  clients_somethngWrongWhileUpdating:
    "Ralat berlaku semasa sistem menyemas kini data",
  clients_notifyWhenLeadsImported:
    "Anda akan diberitahu melalui email apabila contacts telah siap di-import",
  clients_somethngWrongWhileImportingLeads:
    "Ralat berlaku semasa mengimport contacts",
  clients_errorWhileDownloadingFile: "Ralat berlaku ketika file dimuat turun",
  cients_errorWhileFetchingFromServer: "Ralat berlaku ketika data dimuat turun",
  clients_notifiedViaEmail:
    "Anda akan diberitahu melalui e-mel setelah leads diimport sepenuhnya", //NEW
  clients_somethingWrongWhileImportingLeads:
    "Ralat berlaku semasa mengimport leads", //NEW
  clients_errorWhileServer: "Ralat semasa mengambil fail dari pelayan", //NEW

  cAudience_deletedSuccessfully: "Sukses! Custom audience berjaya dihapuskan",

  cAudience_cannotContain: "tidak mengandungi",
  cAudience_valuesCantBeEmpty: "Maaf, medan mesti diisi dengan lengkap",
  cAudience_fieldsCantBeBlank: "Medan mesti diisi dengan lengkap.",
  cAudience_factIDCantHaveSpace: "Fact ID tidak boleh mengandungi space",
  cAudience_idAsQualificatnCantBeCreated:
    "Sistem tidak menerima fact dengan kualifikasi sebagai ID.",
  cAudience_factWithIdAs: "Fact dengan ID ",
  cAudience_cantBeCreated: " tidak boleh dicipta",
  cAudience_alreadyExists: " sudah wujud.",
  cAudience_failedToCreateFact: "Gagal mencipta fact.",

  // Dashboard
  dashboard_campaignMetric: "Kempen Metrik",
  dashboard_range: "Jangka Waktu",
  dashboard_businessProfile: "PROFIL BISNIS",
  dashboard_AIKnowsYouWell: "AI kami memahami bisnis anda :",
  dashboard_dataCollectedAndTrained: " data telah dikumpul",
  dashboard_NoProfilesCreatedYet: "Tiada profil bisnis",
  dashboard_useDummyData: "Cuba dengan sampel data dulu",
  dashboard_experienceNow: "Cuba Sekarang",
  dashboard_editFunnel: "Edit Funnel",
  dashboard_contacts: "Contacts",
  dashboard_customAudience: "Custom Audience",
  dashboard_articles: "Artikel",
  dashboard_validating: "Pengesahan",

  // Articles
  dashboard_readMore: "Baca selanjutnya",

  // StackedBarChart
  dashboard_noLeadMetricText: "Tiada Metrik Leads dikesan.",

  //  LoginPage
  login_dontHaveAccount: "Tiada akaun Peasy?",
  login_getPeasyFree: "Dapatkan Peasy Percuma",
  login_applyForGrant: "Saya nak Register Geran Sekarang.",
  login_willConnectShortly: "Kami akan menghubungi anda sebentar lagi",
  login_signIn: "Log Masuk",
  login_emailAddress: "Emel",
  login_password: "Kata Laluan",
  loginHeading: "Login",
  login_forgotPassword: "Lupa kata laluan?",

  // Clients
  clients_from: "Dari",
  clients_to: "Kepada",
  clients_apply: "Register",
  clients_listing: "Contact’s Listing",
  clients_cancel: "Batal",
  clients_reset: "Reset",
  clients_customAudience: "Custom Audience",
  clients_import: "Import",
  clients_noOfLeads: "Jumlah Contacts :",
  clients_export: "Export",

  // ClientListingTable
  clients_noDataToDisplay: "Tiada data dikesan",
  clients_email: "EMEL",
  clients_phone: "NOMBOR TELEFON",
  clients_predictedScore: "Skor Prediksi",
  clients_qualification: "KUALIFIKASI",
  clients_campaign: "KEMPEN",
  clients_date: "TARIKH",
  clients_details: "PERIHAL",

  // CustomAudienceTable
  cAudience_pleaseRemove: "Sila delete",
  cAudience_lookalikeBeforeDeletingAudience:
    "lookalike audience dari targeting kempen-kempen ini sebelum delete custom audience ini.",
  cAudience_sureYouWantToDelete: "Anda pasti anda mahu delete?",
  cAudience_customAudience: "Custom Audience",
  cAudience_deletingCustomAudience: "Memadamkan khalayak tersuai!",
  cAudience_cancel: "Batal",
  cAudience_delete: "Padam",
  cAudience_createCustomAudience: "Cipta Custom Audience",
  cAudience_sNo: "S. No.",
  cAudience_name: "Nama",
  cAudience_creationDate: "Dicipta pada",
  cAudience_validLeads: "Jumlah contacts yang boleh dipadan",
  cAudience_inValidLeads: "Jumlah contacts yang tidak boleh dipadan",
  cAudience_associatedCampaigns: "Jumlah kempen yang menggunakan audience ini",
  cAudience_action: "Aksi",
  cAudience_noCustomAudienceAvailable: "Tiada custom audience dikesan",

  // CustomAudienceBuilder
  cAudience_createNewCustomAudience: "Cipta Custom Audience baru",
  cAudience_customAudienceName: "Nama Custom Audience",
  cAudience_addFact: "Tambah Fact",
  cAudience_factType: "Jenis Fact",
  cAudience_string: "String",
  cAudience_integer: "Integer",
  cAudience_double: "Double",
  cAudience_boolean: "Boolean",
  cAudience_datetime: "Datetime",
  cAudience_date: "Date",
  cAudience_time: "Time",
  cAudience_factID: "Fact ID",
  cAudience_factLabel: "Fact Label",
  cAudience_cancel: "Batal",
  cAudience_save: "Simpan",
  cAudience_getEstimatedLeads: "Anggaran jumlah contacts",
  cAudience_leads: "Contacts",
  cAudience_Create: "Cipta",

  // RightViewMoreModalRender
  clients_noDataToDisplay: "Tiada data dikesan",
  clients_adName: "NAMA AD",
  clients_date: "TARIKH",
  clients_clientDetails: "Contact's Details",
  clients_predictedScore: "SKOR PREDIKSI:",
  clients_qualification: "KUALIFIKASI",
  clients_close: "Tutup",

  // RightViewMoreModal
  clients_unableToFetchData: "Tiada data dikesan",
  clients_updatedSuccessfully: "Data telah dikemas kini",
  clients_somethingWrongWhileUpdating: "Ralat berlaku ketika data dikemas kini",
  clients_viewMore: "Lihat selanjutnya",

  // SelectFilter
  clients_campaignName: "Nama Kempen",
  clients_adSetName: "Nama Ad Set",
  clients_campaignId: "Kempen ID",
  clients_addSetId: "Add Set ID",
  clients_registeredBetween: "Daftar di antara tarikh",
  clients_predictionScore: "Skor Prediksi",
  clients_qualificationStatus: "Status Kualifikasi",

  // StepWrapper
  campaign_question: "Soalan",
  campaign_start: "Mula",
  campaign_predictResult: "Anggaran pulangan kempen",
  campaign_targetLabel: "Apakah target anda ",

  // brandOverview
  dashboard_newestFirst: "Terkini",
  dashboard_oldestFirst: "Tertua",
  dashboard_objective: "Objektif",

  // Dashboard
  dashboard_edit: "Edit",
  dashboard_delete: "Delete",
  dashboard_deploy: "Deploy Semula",
  dashboard_noCampaignMetricText:
    "Tiada sebarang kempen aktif dalam jangka masa ini. Mulakan satu sekarang.",
  dashboard_leads: "Leads",

  // Cohort
  dashboard_noMetricText: "	Tidak ada leads baru dalam jangka masa ini.",

  // UserTeam
  campaign_packageDoesNotAllowSubuser:
    "Maaf,  pelan anda hanya membenarkan satu .akaun sahaja. Hubungi kami untuk menaik taraf pelan anda",

  // Clients
  clients_clientListing: "Contacts",
  clients_noOfLeads: "Jumlah Contacts",

  // BrandCreate
  brand_updatedSuccessfully: "Maklumat Profil Bisnis anda telah dikemaskini",

  // toast.errors
  brand_somethingWrongWhileAction: "Maaf, ralat berlaku. Sila cuba sekali lagi",
  dashboard_somethingWentWrong: "Maaf, ralat berlaku. Sila cuba sekali lagi",
  dashboard_somethingWentWrongWhileFetchingData:
    "Maaf, ralat berlaku. Sila cuba.sekali lagi",
  dashboard_campaignPaused: "Kempen dihentikan setika",
  dashboard_couldntPausedCampaign: "Tidak dapat menjeda kempen. Sila cuba lagi",
  dashboard_campaignStarted: "Kempen telah diaktifkan semula",
  dashboard_couldntStartCampaign:
    "Tidak dapat aktifkan kempen. Sila cuba sebentar lagi.",
  dashboard_campaignRedeployed: "Kempen telah diaktifkan semula",

  campaign_dontHaveValidPixel:
    "Maaf, anda memerlukan Facebook Pixel untuk memilih objektif ini",
  campaign_inactiveAccount: "Maaf, akaun anda tidak aktif",
  campaign_somethingWrongWhileAction:
    "Maaf, ralat berlaku. Sila cuba sekali lagi",

  dashboard_somethingWentWrong: "Maaf, ralat berlaku.",
  dashboard_logout: "Log Keluar",
  dashboard_RMF: "RMF",

  settings_somethingWentWrong: "Maaf, ralat berlaku.",

  // Common keywords
  brand_here: "di sini",

  // CreateBrandSelectFbPage
  brand_whereWillAudienceLand:
    "Apakah destinasi audience anda setelah mengklik iklan anda?",

  // BrandCreate
  brand_question: "Soalan",

  // CreateBrandLastStep
  brand_reach: "Reach",
  brand_qualified: "Qualified",
  brand_sales: "Sales",

  // CreateBrandPixels
  brand_pixelDomainEventText:
    "Pastikan piksel, domain dan event anda adalah sah dan diperiksa dengan betul. Piksel, domain, atau event yang tidak disahkan dan diperiksa dengan betul akan menyebabkan targeting dan pelaporan yang tidak tepat. Untuk maklumat lebih lanjut",

  // BrandOverview
  dashboard_campaignHaltText:
    "Kempen anda telah dihentikan kerana perubahan manual yang dibuat di Facebook dan / atau Pengurus Google Ad ",
  dashboard_campaignNotDeployed:
    "Kempen anda tidak boleh diaktifkan. Sila periksa notifikasi dan emel anda untuk penerangan lanjut",
  dashboard_budget: "Budget",
  dashboard_target: "Target",
  dashboard_all: "Semua",

  // CampaignEndDate
  campaign_sevenDaysToDeliverResults:
    "Minimum 4 hari. Kami mengesyorkan sekurang-kurangnya 7 hari agar AI kami memberikan hasil yang optimum.",

  // CampaignLocation
  campaign_specifyingMultipleLocations:
    "Anda boleh menentukan lebih dari 1 lokasi, tetapi pastikan lokasi ini tidak bertindih. Contohnya, anda tidak boleh memilih Kuala Lumpur dan Malaysia kerana Kuala Lumpur adalah sebahagian daripada Malaysia.",

  // CampaignName
  campaign_nameThatHelpIdentifyCampaign:
    "Pilih sebarang nama yang boleh membantu anda mengenal pasti kempen ini",
  campaign_minTenCharacters: "Minimum 10 huruf",

  // FormFieldMapping
  campaign_mapFormFieldsText:
    "Padankan kolom borang. Anda hanya perlu melakukan tindakan ini sekali untuk setiap borang baru. Peasy akan menggunakan padanan ini untuk membuat dan memperbarui custom audience",

  // LeadForm
  campaign_chooseExistingFbLeadFormText:
    "Pilih Borang Facebook yang sedia ada dan aktif. Jika opsi adalah kosong, silakan klik Buat Borang Baru untuk membuat Borang Facebook baru. Anda memerlukan akses Admin Halaman Facebook untuk membuat dan menerbitkan borang baru. Untuk informasi lebih lanjut,  sila baca",
  // MISSING LABEL FOR CREATE FORM:	"Buat Borang Baru",
  campaign_Here: "di sini",

  // Method
  campaign_recommendCampaignStrategyText:
    "Peasy akan mencadangkan strategi kempen berdasarkan target atau budget. Jika anda menetapkan budget, Peasy akan memperkirakan target terbaik berdasarkan budget anda. Jika anda menetapkan target, Peasy akan merekomendasikan budget yang diperlukan untuk mencapai target anda",
  campaign_howManyXText:
    "Apakah jumlah X yang anda mahu mengecapi melalui kempen ini? Peasy akan memberi anggaran dengan memperkirakan jangka masa, budget dan data audience.",

  // SpendCampaign
  campaign_budgetCalculationText:
    "Budget minimum dihitung berdasarkan perbelanjaan minimum Facebook dan Google mengikut tempoh kempen. Budget kempen bukanlah komitmen yang tegas, anda masih boleh menjeda atau mengurangkan budget kempen kemudian. Kami mengesyorkan agar anda menetapkan budget yang lebih tinggi terlebih dahulu, dan mengurangkan jumlahnya di bahagian perancangan dan ramalan kemudian",

  // Sidebar
  dashboard_logout: "Logout",
  dashboard_questionaire: "Soalan",
  dashboard_editCampaign: "Edit Kempen",
  dashboard_settings: "Tetapan",
  dashboard_campaignReport: "Laporan Kempen",
  dashboard_RMF: "RMF",

  // campaign_divideRatioInPerfectManager
  campaign_somethingWentWrong: "Maaf, ralat berlaku.",
  campaign_errorGettingPosts:
    "Ralat berlaku semasa muat turun post dari Facebook",
  campaing_selectSomeAudience: "Sila pilih audience",
  campaign_errorUploadingCarouselAd:
    "Ralat berlaku semasa upload iklan Carousel, sila cuba lagi.",
  campaign_completeImageAd:
    "Pastikan kesemua tetapan ad adalah lengkap sebelum menyimpan kempen",
  campaign_errorUploadingImagePost:
    "Ralat berlaku semasa upload iklan image, sila cuba lagi.",
  campaign_completeYourVideoAd:
    "Pastikan kesemua tetapan ad adalah lengkap sebelum menyimpan kempen",
  campaign_errorUploadingVideo:
    "Ralat berlaku semasa upload iklan video, sila cuba lagi.",
  campaign_cantUploadMoreThanTenMediaFiles:
    "Anda hanya boleh membuat maksimum 10 slaid dalam iklan karusel",
  campaign_postsSaved: "Ad Post berjaya disimpan",
  campaign_deployedSuccessfully: "Kempen telah diaktifkan:",
  campaign_recommendAudienceAddedSuccessfully:
    "AI Peasy berjaya mencari audience yang padan untuk anda",
  campaign_errorRecommendingAudience:
    "AI Peasy tidak dapat mencari audience yang sesuai untuk kempen ini. Sila periksa tetapan Facebook anda atau hubungi khidmat pelanggan Peasy.",

  campaign_selectCTA: "Sila pilih CTA",
  campaign_areYouSure: "Anda pasti?",
  campaign_sureYouWantToDeletePost: "Anda pasti mahu delete iklan ini?",
  campaign_sponsored: "Ditaja",
  campaign_editCreative: "Edit Creative",
  campaign_delPost: "Delete Iklan",
  campaign_enterURL: "Masukkan URL",
  campaign_comment: "Komen",
  campaign_comments: "Komen",
  campaign_shares: "Shares",
  campaign_like: "Like",
  campaign_share: "Share",
  campaign_targeting: "Targeting",
  campaign_noAudience: "Iklan ini masih belum audience",
  campaign_audience: "Audience",
  campaign_loading: "loading...",
  campaign_enterCaption: "Masukkan caption",
  campaign_enterDescription: "Masukkan description",

  // postBoostChartComponent
  campaign_comparison: "Perbandingan",
  campaign_range: "Jangka masa",
  campaign_ROIOverMonth: "ROI Kempen selama sebulan",
  campaign_failedToLoad: "Ralat berlaku semasa memuat data kempen lama",
  campaign_CPAComparison: "Perbandingan CPA",
  campaign_failedToLoadCPAComparison:
    "Ralat berlaku semasa memuat data kempen lama",
  campaign_saleVolumeComparison: "Perbandingan jumlah sales",
  campaign_failedToLoadSaleVolumeComparison:
    "Ralat berlaku semasa memuat data sales",
  campaign_historicalCampaignROI: "ROI Kempen selama sebulan",
  // campaign_valueFor	:,
  // campaign_on:

  // PredictionStats
  campaign_estimatedToProduce: "Kempen ini dianggarkan menghasilkan",
  campaign_addToCart: "Add to Cart pada",
  // campaign_perAddToCartWithin
  // campaign_toAchieve
  // campaign_sales
  // campaign_landingPageViewAt
  // campaign_LandingPageViewWithin
  // campaign_initiateCheckoutAt
  // campaign_perInitiateCheckoutWithin
  campaign_per: "untuk satu",
  campaign_within: "dalam masa",
  campaign_budgetTargetCampaignAreGood:
    "Nampaknya segala tetapan kempen anda sudah ready. Ayuh, mari deploy kempen anda sekarang!",
  campaign_uploadAdCreativeAndClick:
    'Muat naik kreatif iklan anda dan klik "Simpan & Aktif" untuk aktifkan kempen anda.',

  // AddPostCard
  campaign_letsUploadCreative: "Ayuh, mari muat naik kreatif anda",
  campaign_addNewPost: "Tambah Post Baru",

  //  PostBoost
  campaign_headlineCantBeEmpty: "Headline mesti dilengkapi",
  campaign_descriptionCantBeBlank: "Description mesti dilengkapi",
  campaign_enterValidURL: "Masukkan URL yang sah",
  campaign_enterURL: "Masukkan URL yang sah",
  campaign_selectSomeAudiences:
    "Anda mesti pilih sekurang-kurangnya satu audience",
  campaign_selectCTAError: "Pilih salah satu CTA",
  campaign_enterCaptionForImageCard: "Masukkan caption untuk setiap kad image",
  campaign_deploymentLoadingMsg:
    "Kempen ini akan dihantar untuk semakan Facebook atau Google. Pastikan kempen anda mematuhi polisi platform masing-masing. Kegagalan boleh menyebabkan pengangtungan akaun halaman dan iklan anda.",
  campaign_connectSocialMedia:
    "Anda tidak boleh aktifkan sebarang kempen sehingga Peasy disambung dengan ad akaun anda",
  campaign_opps: "Opps!",
  campaign_cantBePublishedText:
    "Kempen tidak boleh diaktifkan selagi ada audiences yang tidak mempunyai kreatif. Sila pilih sekurang-kurangnya satu kreatif untuk audience ini:",
  campaign_sudienceSegmentText:
    "Segmen khalayak ini menggunakan iklan Rangkaian Paparan Google (GDN). GDN hanya dapat menyokong gambar pegun dan bukan video dan karusel. Sila buat sekurang-kurangnya satu kreatif gambar pegun untuk segmen ini ATAU tetapkan pembahagian anggaran GDN segmen tersebut menjadi 0",
  campaign_okay: "Okay",
  campaign_saveAndPublish: "Simpan & Aktif",
  campaign_save: "Simpan",

  campaign_current: "Kempen Semasa", //New
  campaign_previousCampaign: "Kempen Sebelumnya", //New
  campaign_nextCampaign: "Kempen Seterusnya", //New

  // ImageCard
  campaign_instaImageValidation: "Pengesahan imej Instagram",
  campaign_imageSizeError:
    "Gambar ini perlu berukuran 1080x1080 untuk disebarkan di Instagram. Tukar gambar ini atau tetapkan pembahagian budget 0% untuk Instagram",

  // VideoCard
  campaign_instaVdoValidatn: "Pengesahan video Instagram",
  campaign_vdoDuratnError:
    "Durasi maksimum video ini mestilah 2 minit atau kurang untuk disebarkan di Instagram. Tukar video ini atau tetapkan pembahagian budget 0% untuk Instagram",
  camaign_editThumbnail: "Edit Thumbnail",

  // PredictionForm
  campaign_startDateInPastCantChange:
    "Tarikh mula kempen mestilah sama atau lebih lewat daripada tarikh semasa",
  campaign_startCantBeAfterEndDate:
    "Tarikh mula kempen tidak boleh melebihi tarikh tamat kempen",
  campaign_endDateCantBeforeStartDate:
    "Tarikh akhir kempen tidak boleh lebih awal daripada tarikh mula kempen",

  // NEW

  // toast.errors
  campaign_budgetSplitMustBeHundred: "Jumlah budget keseluruhan mesti 100%",
  campaign_audienceUpdatedSuccessfully: "Audience telah dikemaskini",
  campaing_selAudienceBeforeDel:
    "Anda mesti pilih sekurang-kurangnya satu audience",
  campaign_audienceDeletedSuccessfully: "Audience telah dibuang dengan berjaya",
  campaign_couldntFetchAud: "Ralat berlaku, sila cuba lagi.",
  campaign_couldntFetchAudList: "Ralat berlaku, sila cuba lagi.",
  campaign_couldntUpdateAud:
    "Pastikan kesemua data audience dilengkapi sebelum cuba menyimpan data audience ini",
  campaign_errorUpdatingAud: "Ralat berlaku, sila cuba lagi.",
  campaign_couldntAddNewAud:
    "Pastikan kesemua data audience dilengkapi sebelum cuba menyimpan data audience ini",
  campaign_audienceAddedSuccessfully: "Audience telah dikemaskini",
  campaign_errorAddingAud: "Ralat berlaku, sila cuba lagi.",
  campaign_couldntFetchLang:
    "Facebook/Google tidak ada targeting untuk bahasa ,ini",

  // PostBoost
  campaign_yes: "Ya",
  campaign_cancel: "Batal",
  campaign_recommending: "Sedang mencari audience yang sesuai...",
  campaign_budget: "Budget",
  campaign_deploying: "Muatnaik kempen....",
  campaign_thisWillRewriteChanges:
    "Saranan dari AI akan menimpa segala perubahan yang anda pernah buat",

  // SelectAudiences
  campaign_select: "Pilih",
  campaign_audienceName: "Nama Audience",
  campaign_budgetHeading: "Budget",
  campaign_channel: "Channel",
  campaign_estPopulatn: "Anggaran Populasi",
  campaign_estCPA: "Anggaran CPA",
  campaign_estVolume: "Anggaran Jumlah",
  campaign_description: "Ciri Targeting",
  campaign_noAudToDisplay: "Tiada audience buat masa kini",
  campaign_recommendAud: "Sarankan Audience",
  campaign_del: "Delete",
  campaign_update: "Kemaskini",
  campaign_add: "Tambah",

  // SingleAudience
  campaign_budgetPercCantLessThan: "Persen budget tidak boleh kurang dari",
  campaign_minChannelPercCantLessThan:
    "Persen minima untuk channel ini tidak boleh kurang dari",
  campaign_changingAudienceBudgetText:
    "Perhatian! Perhitungan dan pulangan yang dianggar dan pelan oleh AI mungkin terjejas apabila anda mengubah distribusi budget",
  campaign_changingChannelText:
    "Perhatian! Distribusi budget untuk channel lain akan terjejas apabila anda mengubah salah satu channel",
  campaign_changingChannelWillAffectVolumeAndCPA:
    "Perhatian, apabila anda mengubah budget untuk channel; akan berlaku impak pada pulangan kempen dan",
  campaign_showDetails: "Tunjuk perincian",
  campaign_hideDetails: "Sembunyi perincian",
  campaign_yearsOld: "umur",

  // EditAudiencePopup
  campaign_enterName: "Sila beri nama untuk segmen audience ini",
  campaign_selGender: "Sila pilih sekurang-kurangnya satu jantina",
  campaign_minAgeCantLessThan18:
    "Umur minima mesti sekurang-kurangnya 18 tahun",
  campaign_minAgeCantMoreThan64:
    "Umur minima tidak boleh melebih dari 64 tahun",
  campaign_maxAgeCantMoreThan65: "Umur maxima tidak boleh melebihi 65 tahun",
  campaign_maxAgeCantLessThan14:	"Umur maxima mesti sekurang-kurangnya 14 tahun",
  campaign_fillIncludeAudField:	"Bidang tex include tidak boleh kosong",
  campaign_selLocatn:	"Sila pilih sekurang-kurangnya satu lokasi",
  campaign_selLang:	"Sila pilih sekurang-kurangnya satu bahasa",
  campaign_selDevice:	"Sila pilih sekurang-kurangnya satu device",
  campaign_AvgConversionMustGreaterThan:	"Tawaran anda untuk conversion kos mesti melebihi",
  campaign_AvgConversionCostCantMoreThan:	"Tawaran anda untuk conversion kos tidak boleh melebihi",
  campaign_fillAudAgeGroup:	"Sila lengkapkan umur segmen audience ini",
  campaign_updateAud:	"Kemaskini Audience",
  campaign_addAud:	"Tambah Audience",
    
  // EditAudienceForm	
  campaign_nameAudSegment:	"Namakan segmen audience ini",
  campaign_enterSegmentName:	"Masukkan nama segmen audience ini",
  campaign_gender:	"Jantina",
  campaign_male:	"Lelaki",
  campaign_female:	"Perempuan",
  campaign_unknown:	"Tidak ketahui",
  campaign_ageGroup:	"Golongan umur",
  campaign_age:	"Umur",
  campaign_to:	"sampai",
  campaign_location:	"Lokasi",
  campaign_canTypeTyxt:	"Anda boleh masukan kawasan, bandar, daerah, negeri negara dan poskod. Maksima 25 lokasi.",
  campaign_language:	"Bahasa",
  campaign_typeAudLang:	"Taip bahasa",
  campaign_ProfileLang:	"Target audiene yang menggunakan bahasa ini",
  campaign_adAppearInTheseDevices:	"Iklan anda akan muncul dalam device sebegini",
  campaign_mobile:	"Telefon mudah alih",
  campaign_desktop:	"Desktop & Laptops",
  campaign_tablet:	"Tablet",
  campaign_targetCostForConversion:	"Target kos per conversion",
  campaign_bidForThisTargetSegmentText:	"Tawaran untuk bersaing untuk segmen sasaran ini. Facebook & Google mungkin memperbelanja 30% lebih dari tawaran yang anda set.Kami mengesyorkan agar anda menetapkan tawaran yang lebih tinggi dan membiarkan Peasy secara beransur-ansur mengurangkan tawaran semasa pengoptimuman",
    
    
  // MergeAudienceDialog	
  campaign_imgHere:	"Imej di sini",
  campaign_overlapDetected:	"Overlap dikesan",
  campaign_overlapFixed:	"Overlap telah dibaik pulih",
  campaign_processing:	"Processing...",
  campaign_audienceOverlapText:	"Audience overlap jikalau dibiarkan boleh menyebabkan prestasi kempen anda menjadi tidak menentu, pulangan yang lemah dan kos yang lebih tinggi. Adakah anda ingin Peasy memperbaiki overlap ini?",
  campaign_congrats:	"Tahniah, audience overlap telah dibaik pulih",
  campaign_fixIt:	"Baik pulih",
  campaign_noThanks:	"Jangan baik pulih",
  campaign_okay:	"Okay",
    
  // GoogleAudienceAffinity	
  campaign_googleAffinity:	"Google Affinity",
  campaign_narrowAud:	"Sempitkan targeting kepada",
  campaign_typeInInterest:	"Masukan interest, demografi atau ciri-ciri audience yang",
  campaign_must:	"WAJIB",
  campaign_metToNarrowAud:	"kriteria untuk sempitkan targeting",
    
  // edit Audience popup	
  excludeAudience:	"Exclude Audience",
  savedAudience:	"Saved Audience",
  excludeAudiencePlaceHolder:	"Audience yang anda ingin kecualikan",
  excludeAudiencePara:	"Eg interest seperti travel atau demografi seperti parents, atau behaviour seperti console gamers yang anda ingin dikecualikan di dalam targeting",
  includeAudience:	"Include Audience",
  includeAudiencePlaceHolder:	"Audience yang anda ingin target",
  includeAudiencePara	:"Eg interest seperti travel atau demografi seperti parents, atau behaviour seperti console gamers yang anda ingin sertakan di dalam targeting",
  narrowAudience:	"Narrow Audience",
  narrowAudiencePlaceHolder:	"Sempitkan targeting kepada audience yang mempunyai ciri-ciri ini DAN ciri-ciri Include Audience",
  narrowAudiencePara:	"Eg interest seperti travel atau demografi seperti parents, atau behaviour seperti console gamers yang anda ingin sertakan di dalam targeting",
    
  // uploadFile	
  campaign_uploadMin3:	"Anda boleh muatnaik 3-10 imej/video untuk iklan jenis Carousel",
  campaign_formatNotAccepted:	"Format .MOV tidak diterima",
  campaign_cantAddMoreThan1:	"Anda tidak boleh ada lebih dari satu post",
  campaign_uploadMin3Or10:	"Anda boleh muatnaik 3-10 imej/video untuk iklan jenis Carousel",
  campaign_dropFilesHere:	"Letak fail anda di sini",
  campaign_dragAndDrop:	"Drag dan drop untuk muatnaik",
  campaign_or:	"atau",
  campaign_browse:	"Cari",
  campaign_toChooseFile:	"dan pilih fail",
  campaign_imgAds:	"Iklan Imej",
  campaign_ratioAndMin:	"nisbah dan dimensi minima",
  campaign_vdoAds:	"Iklan Video",
  campaign_recommendMaxDuratn:	"16:9,9:16 nisbah. Kami syorkan durasi 15 saat untuk IG,  durasi maxima 120 saat",
  campaign_maxDuration:	"Durasi maxima 60 minit",
  campaign_carousalAds:	"Iklan Carousel",
  campaign_IgRequiresRatio:	"IG menghendaki semua gambar dan video mempunyai nisbah 1:1",
  campaign_lessThan60Min:	"durasi tidak boleh melebihi 60 minit",
  campaign_ratioBetween:	"Nisbah mesti samaada 1.91:1 atau 1:1",
    
  // FbCardDescription	 
  campaign_seeMore:	"maklumat lanjut",
  campaign_seeLess:	"sembunyikan",
    
  // Description	
  campaign_enterPrimaryText:	"Masukkan teks ",
    
  // SelectPostTable 	
  campaign_FbPost:	"Facebook Pos",
  campaign_PostHasNoMsg:	"Pos ini tidak mempunyai mesej",
  campaign_postID:	"Pos ID",
  campaign_dataCreated:	"Tarikh dicipta",
  campaign_createdTime:	"Masa dicipta",
  campaign_likes:	"Likes",
  campaign_comments:	"Comments",
  campaign_mediaType:	"Jenis iklan",
  campaign_uploadImage:	"Muat naik imej",
  campaign_instaPost:	"Instagram Pos",
  campaign_postHasNoCaptn:	"Pos ini tidak mempunyai kapsyen",
  campaign_selAnotherPst:	"Sila pilih pos yang lain",
  campaign_continue:	"Lanjutkan,",
    
  // PopupHeader 	
  campaign_fbPage:	"Facebook Page",
  campaign_uploadCreative:	"Muat naik Iklan",
  
  // FbCardDescription
  postboost_tab_graph:	"Perbandingan",
  postboost_tab_campaign_details:	"Maklumat Kempen",
  postboost_tab_audiences:	"Audiences",
  postboost_tab_creative:	"Iklan",
  cohort_days:	"Hari",
  cohort_total:	"Jumlah",
    
  Total:	"Jumlah",
  Date:	"Tarikh",
    
 
  dashboard_brandDataError:"Opps! Ralat berlaku sambil mengambil funnel data  ",
  
  

    
      
  };
