import React, {PureComponent} from "react";

export class InfiniteScroll extends PureComponent {

    componentDidMount() {
        const parent = document.getElementById('infinite-scroll');
        const child = parent.children[0];
        let timerId = 0;
        if (this.props.scrolled) {
            parent.onscroll = () => {
                if ((child.getBoundingClientRect().bottom - (this.props.offset || 10)) - parent.getBoundingClientRect().bottom <= 0) {
                    clearTimeout(timerId);
                    timerId = setTimeout(() => this.props.scrolled(), this.props.debounce || 200);
                }
            };
        }
    }

    render() {
        return (<div id={'infinite-scroll'} className={this.props.class || ''}>
            {this.props.children}
        </div>);
    }
}

export class InfiniteScrollLoader extends PureComponent {
    render() {
        return (<div style={this.props.style} className={'infiniteLoaderWrapper'}>
            <div className="small-loader">
                <div className="lds-ring">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        </div>);
    }
}
