import React, { useState } from "react";
import { useTable } from "react-table";
import Shimmer from '../../../../common/shimmer/Shimmer';

const Table = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()} className="selectposttable__table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} className="selectposttable__th">
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td className="selectposttable__td" {...cell.getCellProps()}>
                    <Shimmer />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const ShimmerTableRows = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Facebook Post",
        accessor: "",
        width: Math.round(window.innerWidth * 0.1)
      },
      {
        Header: "Post ID",
        accessor: "",
        width: Math.round(window.innerWidth * 0.15)
      },
      {
        Header: "Data Created",
        accessor: "",
        width: Math.round(window.innerWidth * 0.1),
      },
      {
        Header: "Likes",
        accessor: "",
        width: Math.round(window.innerWidth * 0.05)
      },
      {
        Header: "Comments",
        accessor: "",
        width: Math.round(window.innerWidth * 0.075)
      },
      {
        Header: "Media Type",
        accessor: "",
        width: Math.round(window.innerWidth * 0.1)
      }    ],
    []
  );

    const data=[1, 2, 3, 4];

  return (
    <div className="selectposttable__container">
      <Table columns={columns} data={data} />
    </div>
  );
};

// Code ends here

export default ShimmerTableRows;
