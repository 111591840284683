import React, { useState } from "react";
import "../../../../translations/i18n";
import { useTranslation } from "react-i18next";

const Description = ({
  text,
  lessLimit,
}) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();

  if (!showFullDescription)
    return (
      <span className="description__span">
        {text && text.substr(0, lessLimit || 20)}
        {text && text.length > 20 && (
          <span
            onClick={() => setShowFullDescription(true)}
            className="description__span--more"
          >
            . . . {t("campaign_seeMore")}
          </span>
        )}{" "}
      </span>
    );


  if (showFullDescription)
    return (
      <span className="description__span">
          <span >
            {text}
            <span
              onClick={() => setShowFullDescription(false)}
              className="description__span--more"
            >
              . . . {t("campaign_seeLess")}
            </span>{" "}
          </span>
      </span>
    );
};

export default Description;
