import React, { Component, Fragment } from 'react'
import "./EditAndDeleteComponent.css"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class EditAndDeleteComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showOptions: false,
            showDeleteModal: false,
            showSaveButton: false,
        }
    }

    showActionsLinkModal = (e) => {
        console.log("kajshd")
        this.setState({ showOptions: !this.state.showOptions })
    }

    onClickHandler = (data) => {
        console.log(data)
    }

    showRowEditOptions = () => {
        this.setState({ showSaveButton: true, showOptions: false })
        // this.props.showRowEditOptions()
        this.props.isEditOn()
    }

    showDeleteModal = (e) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal })
    }

    deleteValue = (id) => {
        this.props.deleteValue(id);
    }

    savedClicked = (id) => {
        this.setState({ showSaveButton: false })
        this.props.savedClicked()
    }

    render() {
        return (
            <Fragment>
                <div className="editanddelete_tooltip">
                    {!this.state.showSaveButton && <a style={{ cursor: 'pointer', color: '#8D02FA' }} href="#" onClick={this.showActionsLinkModal}><h3>. . .</h3></a>}
                    {this.state.showOptions && <div className="editanddelete_tooltiptext" >
                        <span><a href="#" onClick={this.showRowEditOptions}> <i className="fa fa-pencil" aria-hidden="true"></i> </a></span> | &nbsp;
                        <span><a href="#" onClick={this.showDeleteModal}> <i className="fa fa-trash"></i> </a></span>
                    </div>}
                    {this.state.showSaveButton && <a href="#" onClick={(e) => { this.savedClicked(this.props.data.id) }}> Save </a>}
                </div>
                {this.state.showDeleteModal && <Modal isOpen={this.state.showDeleteModal} toggle={this.showDeleteModal}>
                    <ModalBody>
                        <div className="container modal_container">
                            <div className="row">
                                <div className="col-md-11"></div>
                                <div className="col-md-1" style={{ cursor: 'pointer' }} onClick={this.showDeleteModal}>
                                    <i className="fa fa-close"></i>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Are you Sure to Delete <span style={{ color: 'purple' }}>{this.props.data.email}</span> from the list?</h2>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <button type="text" className="btn btn-cancel" onClick={(e) => { this.deleteValue(this.props.data.id) }}>Yes, I am Sure </button>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <a href="#" onClick={this.showDeleteModal} style={{ color: 'purple' }}>Cancel</a>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="secondary" >Cancel</Button>
                        <Button color="danger">Delete</Button>
                    </ModalFooter> */}
                </Modal>}
            </Fragment>
        )
    }
}
