import React, { Component } from "react";
import "./RightViewMoreModal.css";
import QualificationDropdown from "../QualificationDropdown/QualificationDropdown";
import http from "../../../../shared/http-request";
import { BASE_URL } from "../../../../StaticArray/StaticArray";
import { Button, Hidden } from "@material-ui/core";
import "../../../../translations/i18n";
import { withTranslation } from "react-i18next";

class RightViewMoreModalRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  renderAdDateTable() {
    let dataObj = [];
    if (
      this.props.data.other_leads !== null &&
      this.props.data.other_leads !== undefined
    ) {
      dataObj = this.props.data.other_leads.map((leads) => {
        let href_url = "/campaign/edit/" + leads.campaign_id;
        // if (leads.lead_creation_time !== "") {
        //   if (leads.campaign_id !== "") {
        //     return (
        //       <tr>
        //         <td className="clientlistingtable_table_td">
        //           <a href={href_url}>{leads.adset_name}</a>{" "}
        //         </td>
        //         <td className="clientlistingtable_table_td">
        //           {leads.lead_creation_time}
        //         </td>
        //       </tr>
        //     );
        //   } else {
        //     return (
        //       <tr>
        //         <td className="clientlistingtable_table_td">
        //           {leads.adset_name}
        //         </td>
        //         <td className="clientlistingtable_table_td">
        //           {leads.lead_creation_time}
        //         </td>
        //       </tr>
        //     );
        //   }
        // } else {
          if (leads.campaign_id !== "") {
            return (
              <tr>
                <td className="clientlistingtable_table_td">
                  <a href={href_url}>{leads.adset_name}</a>{" "}
                </td>
                <td className="clientlistingtable_table_td">
                  {leads.created_at}
                </td>
              </tr>
            );
          } else {
            return (
              <tr>
                <td className="clientlistingtable_table_td">
                  {leads.adset_name}
                </td>
                <td className="clientlistingtable_table_td">
                  {leads.created_at}
                </td>
              </tr>
            );
          }
        //}
      });

      dataObj = dataObj.filter((obj) => {
        return obj !== undefined;
      });

      if (dataObj.length == 0) {
        dataObj = (
          <tr>
            <td colSpan="2">{this.props.t("clients_noDataToDisplay")} </td>
          </tr>
        );
      }
    } else {
      dataObj = (
        <tr>
          <td colSpan="2"> {this.props.t("clients_noDataToDisplay")} </td>
        </tr>
      );
    }

    return (
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th className="clientlistingtable_th_backgroud">
                  {this.props.t("clients_adName")}
                </th>
                <th className="clientlistingtable_th_backgroud">
                  {this.props.t("clients_date")}
                </th>
              </tr>
            </thead>
            <tbody>{dataObj}</tbody>
          </table>
        </div>
      </div>
    );
  }

  renderDetailsKeys() {
    let details = JSON.parse(this.props.data.details);
    let obj = [];
    console.log(details);
    if (!Array.isArray(details)) {
      obj = Object.entries(details).map((detail) => {
        if (typeof detail[1] === "object") {
          return <></>;
        }
        return (
          <div className="row" key={detail[0]}>
            <div
              className="col-md-6 col-sm-4 text-left text-key-details"
              title={detail[0].toUpperCase()}
            >
              {detail[0]}:
            </div>
            <div className="col-md-6 col-sm-8 text-right">{detail[1]}</div>
          </div>
        );
      });
    } else {
      obj = details.map((detail) => {
        let name = detail["name"].split("_").join(" ");
        return (
          <div className="row" key={detail["name"]}>
            <div
              className="col-md-6 col-sm-4 text-left text-key-details"
              title={name.toUpperCase()}
            >
              {name}:
            </div>
            <div className="col-md-6 col-sm-8 text-right">
              {detail["values"][0]}
            </div>
          </div>
        );
      });
    }

    return obj;
  }

  componentDidMount() {
    // http
    //   .get(BASE_URL + "api/v1/lead/" + this.props.data.id)
    //   .then((res) => {
    //     console.log(res);
    //     this.setState({ data: res.data.data });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  closeView = () => {
    this.props.closeView();
  };

  renderQualification = (data) => {
    return this.props.renderQualification(data);
  };

  render() {
    if (this.props.data === null || this.props.data === undefined) {
      return (
        <div className="clients_right_side_bar_view">
          {" "}
          <div className="renderviewmoremodal_loader"></div>{" "}
        </div>
      );
    }
    return (
      <div className="clients_right_side_bar_view">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 clients_right_side_modal_header">
              <div className="row">
                <div className="col-md-1 col-sm-1 clients_right_side_view_close">
                  <div className="" onClick={this.closeView}>
                    <i className="fa fa-minus"></i>
                  </div>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-md-6 col-sm-12">
                  <h2>{this.props.t("clients_clientDetails")}</h2>
                </div>
                {/* <div className="col-md-6" onClick={this.showEditView}>
                                        <i className="fa fa-pencil"></i>
                                    </div> */}
              </div>
            </div>
          </div>
          <br />
          <div className="container clients_side_view_details">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                {this.renderDetailsKeys()}
              </div>
            </div>
            <br />
            <hr />
            <div className="row" id="predicted_and_qualification">
              <div
                className="col-md-6 col-sm-6"
                style={{ borderRight: "1px solid black" }}
              >
                <p
                  style={{
                    color: "#212121",
                    textTransform: "capitalize",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  {this.props.t("clients_predictedScore")}
                </p>
                <h1 style={{ color: "#8D02FA" }}>
                  {this.props.data.predicted_score}%
                </h1>
              </div>
              <div className="col-md-6 col-sm-6">
                <p
                  style={{
                    color: "#212121",
                    textTransform: "capitalize",
                    fontWeight: 600,
                  }}
                >
                  {this.props.t("clients_qualification")}:
                </p>
                <h1>
                  {/* {this.renderQualification(this.props.data.qualification)}{" "} */}
                  <QualificationDropdown
                    id={this.props.data.id}
                    qualification={this.props.data.qualification}
                    onChangeHandler={this.props.onQualificationChangeHandler}
                    isLarge={true}
                  />
                </h1>
              </div>
            </div>
            <br />
            <hr />
            {this.renderAdDateTable()}
            <Hidden smDown>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={this.closeView}
              >
                {this.props.t("clients_close")}
              </Button>
            </Hidden>
            {/* {this.state.isEditOn && (
                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                      <button
                        className="btn btn-cancel"
                        onClick={this.onEditFalse}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-3">
                      <button
                        className="btn btn-apply"
                        onClick={this.onApplyEdit}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                )} */}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RightViewMoreModalRender);
