import { Box } from "@material-ui/core";
import { TrendingDown, TrendingFlat, TrendingUp } from "@material-ui/icons";
import React from "react";
import "../dashboard.css";

const CampaignChips = ({ campaignMetricData }) => {
  return (
    <>
      {campaignMetricData.chip_data &&
        campaignMetricData.chip_data.map((chipData, i) => {
          return (
            <Box className="box chips" key={i}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-1">
                  <p
                    style={{
                      color: "#595959",
                      spacing: 2,
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {chipData.name.toUpperCase()}
                  </p>
                </div>
                {chipData.rate_type === 0 ? (
                  <>
                    <div
                      style={{
                        color: "#4285F4",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      <>
                        <TrendingFlat />
                        <span> {Math.round(chipData.rate * 100) / 100}%</span>
                      </>
                    </div>
                  </>
                ) : chipData.rate_type === -1 ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    <>
                      <TrendingDown />
                      <span> -{Math.round(chipData.rate * 100) / 100}%</span>
                    </>
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#00c700",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    <>
                      <TrendingUp />
                      <span> {Math.round(chipData.rate * 100) / 100}%</span>
                    </>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-between">
                <div>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {chipData.count}
                  </p>
                </div>
                {/* <div>
                                      <img
                                        style={{ width: "80px" }}
                                        src="/images/dummy-graph.svg"
                                        alt=""
                                      />
                                    </div> */}
              </div>
            </Box>
          );
        })}
    </>
  );
};

export default CampaignChips;
