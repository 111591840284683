import React from "react";
import PropTypes from "prop-types";
import PermissionsHoc from "../../../utils/PermissionsHoc";
import { toast } from "react-toastify";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const Objective = ({
  className,
  onClick,
  href,
  hrefTitle,
  imagePath,
  permissions,
  title,
  content,
  style,
  disabled,
  campaign,
  featureId,
  adwordFallbackAccount,
  facebookFallbackAccount,
  brandFunnelType,
  tosData,
}) => {
  const featureContent = content ? content : this.props.children;
  const { t } = useTranslation();

  const featureStyle = {
    ...style,
    // display:permissions?permissions.permission==='hidden'? 'none' : 'block' :'block'
  };
  // const featureDisabled=disabled?disabled:permissions.permission!=='clickable' ? true : false
  const featureDisabled = false;
  // if(featureDisabled){
  //   featureStyle.opacity="0.5"
  // }
  return (
    <div
      className={className}
      onClick={() => {
        if (
          campaign.id === 4 &&
          tosData.pixel_status.status === false
        ) {
          toast.error(t("campaign_dontHaveValidPixel")
            ,
            {
              position: toast.POSITION.BOTTOM_LEFT,
            }
          );
          return;
        }
        if (facebookFallbackAccount === 0) {
          // return !featureDisabled ? onClick("objective", campaign.id) : {};
          console.log(campaign.id);
          return !featureDisabled ? onClick("objective", campaign.id) : {};
        } else {
          toast.error(t("campaign_inactiveAccount"), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      }}
      style={featureStyle}
    >
      <div className="brand-white-bg h-100 p-3 text-center">
        <div className="w-100 mb-3">
          <img src={imagePath} alt={title} />
        </div>
        <h5 className="mb-3">{title}</h5>
        <p className="mb-1">{featureContent}</p>
        {href && (
          <a href={href} className="full-sec">
            {hrefTitle}{" "}
          </a>
        )}
      </div>
    </div>
  );
};

Objective.propTypes = {
  featureId: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  permissions: PropTypes.object,
  imagePath: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  hrefTitle: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

Objective.defaultProps = {
  onClick: () => {},
};

export default PermissionsHoc(Objective);
