import React, { useState, useEffect } from "react";
import "./multi-step-registration.css";
import Modal from "react-responsive-modal";
import { useForm, useStep } from "react-hooks-helper";
import AdsAccount from "./steps/AdsAccount";
import RegistrationDetails from "./steps/RegistrationDetails";
import ChargeBeeDetails from "./steps/ChargeBeeDetails";
import AdditionalInformation from "./steps/AdditionalInformation";
import Axios from "axios";
import { BASE_URL } from "../../StaticArray/StaticArray";
import { useLocation } from "react-router-dom";
import { fbLogin } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import { onUserCheckFb } from "../../utils/network/lib/user";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const steps = [
  { id: "registration_details" },
  { id: "ads_accounts" },
  { id: "chargebee" },
  { id: "additional_information" },
];

const defaultData = {
  id: "",
  fbId: "",
  email: "",
  fullName: "",
  mobileNo: "",
  password: "",
  confirmPassword: "",
  industry: "",
  jobRole: "",
  salesTeamSize: "",
  paymentId: "",
  accesstoken: "",
  adAccountId: "",
  addAccountCurrency: "",
  isSuccess: false,
  currency: "MYR",
};

function MultiStepRegistration({
  isVisible,
  fbResponse,
  hideRegistration,
  loginUser,
  globalId,
  isExternalRoute
}) {
  let query = useQuery();
  const queryObj = Object.fromEntries(query);
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const props = { formData, setForm, navigation };
  const [visibility, setVisibility] = useState(false);
  const [fbData, setFbData] = useState(fbResponse);
  const [isFreeUser, setIsFreeUser] = useState(true);
  
  // computed
  const checkParams = () =>{
    const isSourceGrant = queryObj.source === 'grant';
    const isEmailInParams = queryObj.email ? true : false;
    const isExternalGrantWIthEmail = isSourceGrant && isEmailInParams && isExternalRoute;
    return {
      isSourceGrant,
      isEmailInParams,
      isExternalGrantWIthEmail,
    }
  }

  const checkFBUser = async (fbID, accessToken) => {
    try {
      const { data, status } = await onUserCheckFb(fbID);
      if (status == 200) {
        if (data.data.registration_step === "done") {
          await loginUser(fbID, accessToken);
          // setForm({ target: { name: "isSuccess", value: true } });
          // navigation.go("additional_information");
          return;
        } else {
          const email = checkParams().isExternalGrantWIthEmail ? queryObj.email : data.data.email; 
          setForm({ target: { name: "id", value: data.data.id } });
          setForm({ target: { name: "fullName", value: data.data.name } });
          setForm({ target: { name: "email", value: email } });
          setForm({ target: { name: "mobileNo", value: data.data.phone } });
          if (data.data.registration_step === "payment")
            navigation.go("chargebee");
          else navigation.go("ads_accounts");
        }
      }
    } catch (err) {
    } finally {
    }
    setVisibility(true);
  };

  useEffect(() => {
    const email = checkParams().isExternalGrantWIthEmail ? queryObj.email : fbResponse.email; 
    let paidUser = query.get("tracking_id");
    if (paidUser && paidUser === "fc407180cd6b849d0d93d6c9fab0d029") {
      setIsFreeUser(false);
    }
    setFbData(fbResponse);
    setForm({ target: { name: "accesstoken", value: fbResponse.accessToken } });
    setForm({ target: { name: "email", value: email } });
    setForm({ target: { name: "fullName", value: fbResponse.name } });
    setForm({ target: { name: "fbId", value: fbResponse.id } });

    checkFBUser(fbResponse.id, fbResponse.accessToken);
  }, [fbResponse]);

  const renderStep = (id) => {
    switch (id) {
      case "registration_details":
        return (
          <RegistrationDetails
            {...props}
            isFreeUser={isFreeUser}
            isExternalRoute={isExternalRoute}
            globalId={globalId}
          />
        );
      case "ads_accounts":
        return (
          <AdsAccount
            {...props}
            fbData={fbData}
            isFreeUser={isFreeUser}
            globalId={globalId}
          />
        );
      case "chargebee":
        return (
          <ChargeBeeDetails
            {...props}
            setVisibility={setVisibility}
            isFreeUser={isFreeUser}
          />
        );
      case "additional_information":
        return (
          <AdditionalInformation
            {...props}
            hideRegistration={hideRegistration}
            loginUser={loginUser}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={visibility}
      onClose={() => hideRegistration()}
      center
      closeOnEsc={false}
      closeOnOverlayClick={false}
      classNames={{
        modal: "multi-step-registration_modal",
      }}
    >
      {renderStep(id)}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (fbId, accessToken) => {
      dispatch(fbLogin(fbId, accessToken));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiStepRegistration);
