import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Typography } from "@material-ui/core";
import TagManager from "react-gtm-module";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
const args = {
  dataLayer: {
    event_custom: "brand_profile_create_name",
    /* can pass more variables here if needed */
  },
};

const CreateBrandName = ({ onChange, nextPageOnEnter, value }) => {
  const history = useHistory();
  const { t } = useTranslation();

  TagManager.dataLayer(args);
  const checkEnter = (e) => {
    if (e.keyCode === 13) {
      validate();
    }
  };

  const validate = () => {
    if (value.length > 0) return nextPageOnEnter();
    else {
      toast.error(t("brand_brandName"));
    }
  };

  return (
    <>
      <Box className="createBrand-box">
        <div className="tab-pane col-12 px-0 active" id="Step1">
          <div className="form-group brand-custom-form">
            <label>{t("brand_BPName")}</label>
            <p className="question-description">
              {t("brand_questionDescription")}
            </p>
            <input
              type="text"
              name="brand_name"
              className="form-control"
              placeholder={t("brand_typeBrand")}
              onChange={(e) => onChange("brand_name", e.target.value)}
              onKeyDown={(e) => checkEnter(e)}
              value={value}
              autoComplete="off"
              autoFocus
            />
          </div>
          <div className="d-block">
            <div className="span-text mt-3 d-block">
              {t("brand_enterContinue")}
            </div>
          </div>
        </div>
      </Box>
      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              type="button"
              className="brandprofilecard__btn--white"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {t("brand_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              type="button"
              className="brandoverviewleftsection__btn--purple"
              onClick={() => validate()}
            >
              {t("brand_next")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBrandName;
