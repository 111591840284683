import React from "react";
import { Chart } from "react-google-charts";

const options = {
  legend: { position: "none" },
  vAxis: {
    textPosition: "none",
    gridlines: { count: 0 },
    baseline: { color: "#fff" },
  },
  backgroundColor: "transparent",
  width: "100%",
};

// const data = [
//   ["Channel", "Percentage", { role: "style" }, { role: 'annotation' }],
//   ["FB", 50, "color: #1671F9", "50"],
//   ["IG", 40, "color: #F52727", "40"],
//   ["G", 10, "color: #FFE03A", "10"],
// ];

const ChannelChart = ({ channelPercentages }) => {
  const data = () => {
    const array = [];
    array.push([
      "Channel",
      "Percentage",
      { role: "style" },
      { role: "annotation" },
    ]);
    if (channelPercentages["fb"])
      array.push([
        "FB",
        channelPercentages["fb"].budget_split,
        "color: #1671F9",
        `${channelPercentages["fb"].budget_split}`,
      ]);
    if (channelPercentages["ig"])
      array.push([
        "IG",
        channelPercentages["ig"].budget_split,
        "color: #F52727",
        `${channelPercentages["ig"].budget_split}`,
      ]);
    if (channelPercentages["g"])
      array.push([
        "G",
        channelPercentages["g"].budget_split,
        "color: #FFE03A",
        `${channelPercentages["g"].budget_split}`,
      ]);
    return array;
  };

  return (
    <Chart
      options={options}
      style={{
        position: "relative",
        left: "0px",
        top: "0px",
        minWidth: "150px",
      }}
      chartType="ColumnChart"
      width="80%"
      height="100px"
      data={data()}
    />
  );
};

export default ChannelChart;
