import { combineReducers } from "redux";
import { loginReducer } from "./authReducer";
import { persmissionReducer } from "./persmissionReducer";
import { packageReducer } from "./packageReducer";
import { featureReducer } from "./featureReducer";
import campaignMetricsReducer from "./campaignMetricsReducer";
import campaignDetailsReducer from "./campaignDetailsReducer";

export default combineReducers({
  auth: loginReducer,
  permissions: persmissionReducer,
  packages: packageReducer,
  features: featureReducer,
  campaignDetails: campaignDetailsReducer,
  campaignMetrics: campaignMetricsReducer
});


