
import * as types from "../actions/actionTypes";
const staticPermissions=[
  {
    id:1,
    name:"create-reach",
    permission:"clickable"
  },
  {
    id:2,
    name:"create-click",
    permission:"clickable"
  },
  {
    id:3,
    name:"create-traffic",
    permission:"clickable"
  },  
  {
    id:4,
    name:"create-conversion",
    permission:"visible"
  },
  {
    id:5,
    name:"create-engagement",
    permission:"hidden"
  }
]
const initialState = {
  allPermissions: staticPermissions,
  currentPermissions:staticPermissions
};

export function persmissionReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_ALL_PACKAGE_PERMISSIONS_SUCCSESS:
      return {
        ...state,
        allPermissions: payload
      };
      case types.CURRENT_PACKAGE_PERMISSIONS_SUCCESS:
        return {
          ...state,
          currentPermissions: payload
        };
        case types.ADD_NEW_PERMISSION_SUCCESS:
            return {
              ...state,
              currentPermissions: [...state.currentPermissions,payload]
        };
        case types.UPDATE_NEW_PERMISSION_SUCCESS:
            return {
                ...state,
                currentPermissions: [...state.currentPermissions,payload]
        };
        case types.DELETE_PERMISSION_SUCCESS:
            return {
                ...state,
                currentPermissions: [...state.currentPermissions,payload]
        };
    default:
      return state;
  }
}