import { createStore, applyMiddleware } from 'redux';
// import createSagaMiddleware from 'redux-saga';
import "regenerator-runtime/runtime";
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/rootReducer';
// import rootSaga from './sagas/rootSaga';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(thunk,createLogger());
export default function configureStore(initialState={}) {
  return createStore(
    rootReducer,
    middleware,
  );
}

// sagaMiddleware.run(rootSaga);