export const VERSION_PREFIX = "api/v1"
export const STATIC_DATA = [
    [
        {
            "id": 1,
            "created_at": "2019-01-03T22:44:32.000000Z",
            "updated_at": "2020-04-28T03:27:15.000000Z",
            "name": "O2O Starter",
            "description": "Chat & Online",
            "price": "1500.00",
            "ad_spend_limit": 3000,
            "account_limit": 1,
            "active": 1,
            "reference": "o2o-starter",
            "store_data": 1,
            "max_data": 0,
            "max_retention": 0,
            "billing_interval": 1,
            "is_default": 0,
            "brand_profile_limit": 100,
            "stripe_price_id": "price_1LmsDBIB8fU21ThglCgjnV70",
            "stripe_product_id": "prod_MO9lsmsgqcJwAR",
            "pricing": {
                "headers": {},
                "original": [
                    {
                        "object": "list",
                        "data": [
                            {
                                "id": "price_1LfNSLIB8fU21Thgrqq8Yw3G",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1662554053,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MO9lsmsgqcJwAR",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "year",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 140000,
                                "unit_amount_decimal": "140000"
                            }
                        ],
                        "has_more": false,
                        "url": "/v1/prices"
                    }
                ],
                "exception": null
            }
        },
        {
            "id": 2,
            "created_at": "2019-01-25T00:00:00.000000Z",
            "updated_at": "2019-01-25T00:00:00.000000Z",
            "name": "E-Commmerce Starter",
            "description": "Chat & Online",
            "price": "2400.00",
            "ad_spend_limit": 4500,
            "account_limit": 1,
            "active": 1,
            "reference": "e-commerce-starter",
            "store_data": 1,
            "max_data": 0,
            "max_retention": 0,
            "billing_interval": 1,
            "is_default": 0,
            "brand_profile_limit": 100,
            "stripe_price_id": "price_1LmsEeIB8fU21ThgmFdPhN68",
            "stripe_product_id": "prod_MOnLrxjz7x6o3V",
            "pricing": {
                "headers": {},
                "original": [
                    {
                        "object": "list",
                        "data": [
                            {
                                "id": "price_1LvGXtIB8fU21Thg1JLWDIHG",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1666340737,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MOnLrxjz7x6o3V",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "month",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 30000,
                                "unit_amount_decimal": "30000"
                            },
                            {
                                "id": "price_1LfzkpIB8fU21ThgijDM5dwH",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1662701271,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MOnLrxjz7x6o3V",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "year",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 180000,
                                "unit_amount_decimal": "180000"
                            }
                        ],
                        "has_more": false,
                        "url": "/v1/prices"
                    }
                ],
                "exception": null
            }
        },
        {
            "id": 3,
            "created_at": "2019-04-17T00:00:00.000000Z",
            "updated_at": "2019-04-17T00:00:00.000000Z",
            "name": "O2O Premium",
            "description": "Chat, Online & Webinar",
            "price": "2400.00",
            "ad_spend_limit": 4500,
            "account_limit": 1,
            "active": 1,
            "reference": "o2o-premium",
            "store_data": 1,
            "max_data": 0,
            "max_retention": 0,
            "billing_interval": 1,
            "is_default": 0,
            "brand_profile_limit": 100,
            "stripe_price_id": "price_1LmsPZIB8fU21ThgS2LV7Hed",
            "stripe_product_id": "prod_MOnN1pU5JlDeD2",
            "pricing": {
                "headers": {},
                "original": [
                    {
                        "object": "list",
                        "data": [
                            {
                                "id": "price_1LvGYGIB8fU21Thgu7dHpk01",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1666340760,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MOnN1pU5JlDeD2",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "month",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 25000,
                                "unit_amount_decimal": "25000"
                            },
                            {
                                "id": "price_1LfzmhIB8fU21Thgyj4MGjYE",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1662701387,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MOnN1pU5JlDeD2",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "year",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 240000,
                                "unit_amount_decimal": "240000"
                            }
                        ],
                        "has_more": false,
                        "url": "/v1/prices"
                    }
                ],
                "exception": null
            }
        },
        {
            "id": 6,
            "created_at": "2019-04-17T00:00:00.000000Z",
            "updated_at": "2019-04-17T00:00:00.000000Z",
            "name": "E-Commerce Premium",
            "description": "Chat, Online & Webinar",
            "price": "3300.00",
            "ad_spend_limit": 6000,
            "account_limit": 5,
            "active": 1,
            "reference": "e-commerce-premium",
            "store_data": 1,
            "max_data": 0,
            "max_retention": 0,
            "billing_interval": 1,
            "is_default": 0,
            "brand_profile_limit": 100,
            "stripe_price_id": "price_1LmsLZIB8fU21ThgXnZDy28Q",
            "stripe_product_id": "prod_MOnQqxqhlNsrQO",
            "pricing": {
                "headers": {},
                "original": [
                    {
                        "object": "list",
                        "data": [
                            {
                                "id": "price_1LfzpMIB8fU21ThgXitCnFJv",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1662701552,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MOnQqxqhlNsrQO",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "year",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 360000,
                                "unit_amount_decimal": "360000"
                            }
                        ],
                        "has_more": false,
                        "url": "/v1/prices"
                    }
                ],
                "exception": null
            }
        },
        {
            "id": 8,
            "created_at": "2019-01-04T04:14:32.000000Z",
            "updated_at": "2019-01-04T04:14:32.000000Z",
            "name": "Free Forever",
            "description": " Can only create but not deploy campaign",
            "price": "0.00",
            "ad_spend_limit": 0,
            "account_limit": 1,
            "active": 1,
            "reference": "freeforever",
            "store_data": 1,
            "max_data": 3072,
            "max_retention": 60,
            "billing_interval": 1,
            "is_default": 0,
            "brand_profile_limit": 100,
            "stripe_price_id": "price_1LmsHoIB8fU21ThgFHpHNmen",
            "stripe_product_id": "prod_Lendfm1BsXlxAM",
            "pricing": {
                "headers": {},
                "original": [
                    {
                        "object": "list",
                        "data": [
                            {
                                "id": "price_1KxU1nIB8fU21ThgYhcjB3PS",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "tiered",
                                "created": 1652092883,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_Lendfm1BsXlxAM",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "month",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": "volume",
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": null,
                                "unit_amount_decimal": null
                            }
                        ],
                        "has_more": false,
                        "url": "/v1/prices"
                    }
                ],
                "exception": null
            }
        },
        {
            "id": 9,
            "created_at": null,
            "updated_at": null,
            "name": "Platinum",
            "description": "Chat, Priority, Phone",
            "price": "7200.00",
            "ad_spend_limit": 50000,
            "account_limit": 30,
            "active": 1,
            "reference": "platinum",
            "store_data": 1,
            "max_data": 6047,
            "max_retention": 60,
            "billing_interval": 1,
            "is_default": 0,
            "brand_profile_limit": 100,
            "stripe_price_id": "price_1LuAzjIB8fU21ThgKeLgBTQ4",
            "stripe_product_id": "prod_MR6Njd7bmPsAC8",
            "pricing": {
                "headers": {},
                "original": [
                    {
                        "object": "list",
                        "data": [
                            {
                                "id": "price_1LvGXJIB8fU21ThgUtjvPgBw",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1666340701,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MR6Njd7bmPsAC8",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "month",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 50000,
                                "unit_amount_decimal": "50000"
                            },
                            {
                                "id": "price_1LiEAYIB8fU21Thgq41Z8Hvu",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1663233338,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MR6Njd7bmPsAC8",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "year",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 550000,
                                "unit_amount_decimal": "550000"
                            }
                        ],
                        "has_more": false,
                        "url": "/v1/prices"
                    }
                ],
                "exception": null
            }
        },
        {
            "id": 10,
            "created_at": null,
            "updated_at": null,
            "name": "Prepaid",
            "description": "Self Service - 0 monthly subscription (13% of Media Spent)",
            "price": "0.00",
            "ad_spend_limit": 600,
            "account_limit": 1,
            "active": 1,
            "reference": "prepaid",
            "store_data": 1,
            "max_data": 0,
            "max_retention": 0,
            "billing_interval": 0,
            "is_default": 1,
            "brand_profile_limit": 1,
            "stripe_price_id": "price_1LmsHoIB8fU21ThgFHpHNmen",
            "stripe_product_id": "prod_MOnR4xIQkzcDx7",
            "pricing": {
                "headers": {},
                "original": [
                    {
                        "object": "list",
                        "data": [
                            {
                                "id": "price_1LfzqrIB8fU21ThgSlZeIrmW",
                                "object": "price",
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1662701645,
                                "currency": "myr",
                                "custom_unit_amount": null,
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": [],
                                "nickname": null,
                                "product": "prod_MOnR4xIQkzcDx7",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "year",
                                    "interval_count": 1,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 5000,
                                "unit_amount_decimal": "5000"
                            }
                        ],
                        "has_more": false,
                        "url": "/v1/prices"
                    }
                ],
                "exception": null
            }
        }
    ]
]