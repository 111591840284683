import React, { useState, useEffect, useRef } from "react";
import "./custom-audience-builder.scss";
import {
  Button,
  Typography,
  TextField,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import PeasyApi from "../../../utils/peasy.api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";

import $ from "jquery";
import "jQuery-QueryBuilder/dist/js/query-builder.js";
import "jQuery-QueryBuilder/dist/scss/default.scss";
import {
  onCustomAudienceEstimatedLeads,
  onPostCustomAudience,
} from "../../../utils/network/lib/audience";
import { onFactCreate, onReadFacts } from "../../../utils/network/lib/misc";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const DEFAULT_FACTS = [
  {
    id: "name",
    label: "Name",
    type: "string",
  },
  {
    id: "email",
    label: "Email",
    type: "string",
  },
  {
    id: "phone",
    label: "Phone Number",
    type: "string",
  },
  {
    id: "qualification",
    label: "Qualification Status",
    type: "string",
  },
];

const to = {
  equal: "equal",
  notEqual: "not_equal",
  greaterThan: "greater",
  greaterThanInclusive: "greater_or_equal",
  lessThan: "less",
  lessThanInclusive: "less_or_equal",
  in: "in",
  notIn: "not_in",
  contains: "contains",
  doesNotContaine: "not_contains",
  after: "ends_with",
  before: "begins_with",
};

const from = {
  equal: "equal",
  not_equal: "notEqual",
  greater: "greaterThan",
  greater_or_equal: "greaterThanInclusive",
  less: "lessThan",
  less_or_equal: "lessThanInclusive",
  in: "in",
  not_in: "notIn",
  contains: "contains",
  not_contains: "doesNotContaine",
  ends_with: "after",
  begins_with: "before",
};

const operators = [
  "equal",
  "not_equal",
  "greater",
  "greater_or_equal",
  "less",
  "less_or_equal",
  "in",
  "not_in",
  "contains",
  "not_contains",
  "ends_with",
  "begins_with",
];

const CustomAudienceBuilder = ({
  getRule,
  brandId,
  fetchData,
  action,
  onAction,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // const { type, id } = useParams();
  const type = action.type;
  const id = action.audienceId;

  const [formValue, setFormValue] = useState({
    rule_name: "",
  });

  const [addFact, setAddFact] = useState(false);

  const [fact, setFact] = useState({
    id: "",
    label: "",
    type: "",
  });
  const [facts, setFacts] = useState(DEFAULT_FACTS);

  const [estimateLeads, setEstimateLeads] = useState(0);
  const [estimateLoading, setEstimateLoading] = useState(false);
  const [disableUI, setDisableUI] = useState(false);

  const el = useRef();
  const history = useHistory();

  const fetchFacts = async () => {
    var f = [];
    try {
      f = await onReadFacts(brandId);
      f = [...DEFAULT_FACTS, ...f].map((fact) => {
        return { ...fact, input: "text", value_separator: "," };
      });
      setFacts(f);
    } catch (e) {}
    return f;
  };

  const initRuleEngine = async () => {
    try {
      let allFacts = await fetchFacts();
      if (type === "view") {
        var rule = getRule(id);
        setFormValue({ formValue, rule_name: rule.name });
        $(el.current).queryBuilder({
          rules: rule.raw_rule,
          filters: allFacts,
          operators: operators,
        });
        $("button", el.current).prop("disabled", true);
        $("select", el.current).prop("disabled", true);
        $("select", el.current).trigger("chosen:updated");
        $("input", el.current).prop("disabled", true);
        setDisableUI(true);
        console.log("This", rule.raw_rule);
      } else {
        $(el.current).queryBuilder({
          filters: allFacts,
          operators: operators,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initRuleEngine();

    return () => {
      $(el.current).queryBuilder("destroy");
    };
  }, []);

  const processRules = (rule) => {
    let isAND = rule["all"];
    let child = {
      condition: isAND ? "AND" : "OR",
      rules: [],
    };

    if (isAND) {
      child.rules = (isAND ? rule["all"] : rule["any"]).map((r) => {
        if (r["all"] || r["any"]) {
          return processRules(r);
        } else {
          return {
            id: r.fact,
            field: r.fact,
            operator: to[r.operator],
            value: r.value,
          };
        }
      });
    }
    return child;
  };

  const convertRules = (condition, rules) => {
    let childs = rules.map((rule) => {
      if (rule.condition) {
        return convertRules(rule.condition, rule.rules);
      } else {
        return {
          fact: rule.id.includes("details.")
            ? rule.id.replace("details.", "")
            : rule.id,
          operator: from[rule.operator],
          value: rule.value,
          type: rule.type,
          input: rule.input,
        };
      }
    });

    let resp = {};
    resp[condition == "OR" ? "any" : "all"] = childs;
    return resp;
  };

  const handleInputChange = (e) => {
    e.preventDefault();

    const newFormValue = { ...formValue, [e.target.name]: e.target.value };
    setFormValue(newFormValue);
  };

  var validatorFlag = true;
  var errorFound = false;
  const showError = (field, symbol) => {
    var field = field.replace("details.", "");
    toast.error(field + " " + t("cAudience_cannotContain") + " " + symbol);
    validatorFlag = false;
    errorFound = true;
  };

  const validateRules = (rule) => {
    if (
      rule.type === "integer" ||
      rule.type === "double" ||
      rule.type === "boolean"
    ) {
      return;
    }

    let value = rule.value;
    if (Array.isArray(value)) value = value.join();

    if (value.includes('"')) {
      showError(rule.field, '"');
    } else if (value.includes("'")) {
      showError(rule.field, "'");
    } else if (value.includes("\\0")) {
      showError(rule.field, "\\0");
    } else if (value.includes("\\b")) {
      showError(rule.field, "\\b");
    } else if (value.includes("\\r")) {
      showError(rule.field, "\\r");
    } else if (value.includes("\\n")) {
      showError(rule.field, "\\n");
    } else if (value.includes("\\t")) {
      showError(rule.field, "\\t");
    } else if (value.includes("\\Z")) {
      showError(rule.field, "\\Z");
    } else if (value.includes("\\")) {
      showError(rule.field, "\\");
    }
  };

  const validator = (rules) => {
    if (rules.condition) {
      const RULES = rules.rules;
      errorFound = false;
      for (var i = 0; i < RULES.length; i++) {
        validator(RULES[i]);
        if (errorFound) {
          break;
        }
      }
    } else {
      validateRules(rules);
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    var rawJson = $(el.current).queryBuilder("getRules");
    if (rawJson === null) {
      toast.error(t("cAudience_valuesCantBeEmpty"));
      return;
    } else {
      validatorFlag = true;
      validator(rawJson);
      if (validatorFlag === false) return;
    }
    var sql = $(el.current).queryBuilder("getSQL", "question_mark");
    // var r2 = $(el.current).queryBuilder("getSQL", "numbered");
    // var r3 = $(el.current).queryBuilder("getSQL", "named");
    // var r4 = $(el.current).queryBuilder("getSQL", "numbered([char])");
    // var r5 = $(el.current).queryBuilder("getSQL", "named([char])");
    // var mongo = $(el.current).queryBuilder("getMongo");

    var convertedRule = [];
    if (!$.isEmptyObject(rawJson)) {
      convertedRule = convertRules(rawJson.condition, rawJson.rules);
    }

    const payload = {
      brand_profile_id: brandId,
      name: formValue.rule_name,
      conditions: convertedRule,
      raw_rule: rawJson,
      // mongodb_query: mongo,
      sql_query: sql,
    };

    // e.stopPropagation();
    try {
      const resp = await onPostCustomAudience(payload);
      fetchData();
      toast.success(resp);
      onAction("");
      // history.goBack();
    } catch (e) {
      console.log(e);
      toast.error(e.response.data);
    }
  };

  const getEstimatedLeads = async () => {
    setEstimateLoading(true);
    var rawJson = $(el.current).queryBuilder("getRules");
    if (rawJson === null) {
      toast.error(t("cAudience_valuesCantBeEmpty"));
      setEstimateLoading(false);
      return;
    } else {
      validatorFlag = true;
      validator(rawJson);
      if (validatorFlag === false) {
        setEstimateLoading(false);
        return;
      }
    }
    var rawJson = $(el.current).queryBuilder("getRules");
    var sql = $(el.current).queryBuilder("getSQL", "question_mark");
    var convertedRule = [];
    if (!$.isEmptyObject(rawJson)) {
      convertedRule = convertRules(rawJson.condition, rawJson.rules);
    }

    const payload = {
      brand_profile_id: brandId,
      name: formValue.rule_name,
      conditions: convertedRule,
      raw_rule: rawJson,
      sql_query: sql,
    };

    try {
      const resp = await onCustomAudienceEstimatedLeads(payload);
      // fetchData();
      setEstimateLoading(false);
      setEstimateLeads(resp);
    } catch (e) {
      setEstimateLeads(0);
      setEstimateLoading(false);
      console.log(e);
      toast.error(e.response.data);
    }
  };

  return (
    <Container fixed className="rule-builder">
      <Typography component="div" variant="h5">
        {type === "view"
          ? "Custom Audience"
          : t("cAudience_createNewCustomAudience")}
      </Typography>
      <div className={classes.paper}>
        <form style={{ width: "100%" }} onSubmit={handleOnSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={t("cAudience_customAudienceName")}
                name="rule_name"
                variant="outlined"
                onChange={handleInputChange}
                value={formValue.rule_name}
                disabled={disableUI}
              />
            </Grid>
            <Grid item xs={12}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAddFact(true)}
                  disabled={disableUI}
                >
                  {t("cAudience_addFact")}
                </Button>
                <Dialog open={addFact} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">
                    {t("cAudience_addFact")}
                  </DialogTitle>
                  <DialogContent>
                    <FormControl margin="dense" fullWidth>
                      <InputLabel>{t("cAudience_factType")}</InputLabel>
                      <Select
                        value={fact.type}
                        onChange={(e) =>
                          setFact({ ...fact, type: e.target.value })
                        }
                        required
                      >
                        <MenuItem value={"string"}>
                          {t("cAudience_string")}
                        </MenuItem>
                        <MenuItem value={"integer"}>
                          {t("cAudience_integer")}
                        </MenuItem>
                        <MenuItem value={"double"}>
                          {t("cAudience_double")}
                        </MenuItem>
                        <MenuItem value={"boolean"}>
                          {t("cAudience_boolean")}
                        </MenuItem>
                        <MenuItem value={"datetime"}>
                          {t("cAudience_datetime")}
                        </MenuItem>
                        <MenuItem value={"date"}>
                          {t("cAudience_date")}
                        </MenuItem>
                        <MenuItem value={"time"}>
                          {t("cAudience_time")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      margin="dense"
                      id="id"
                      label={t("cAudience_factID")}
                      type="text"
                      value={fact.id}
                      onChange={(e) => setFact({ ...fact, id: e.target.value })}
                      fullWidth
                      required
                    />
                    <TextField
                      margin="dense"
                      id="label"
                      label={t("cAudience_factLabel")}
                      type="text"
                      fullWidth
                      value={fact.label}
                      onChange={(e) =>
                        setFact({ ...fact, label: e.target.value })
                      }
                      required
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setAddFact(false)} color="primary">
                      {t("cAudience_cancel")}
                    </Button>
                    <Button
                      onClick={async () => {
                        if (
                          fact.id.trim() === "" ||
                          fact.type.trim() === "" ||
                          fact.label.trim() === ""
                        ) {
                          toast.error(t("cAudience_fieldsCantBeBlank"));
                          return;
                        }
                        if (fact.id.trim().includes(" ")) {
                          toast.error(t("cAudience_factIDCantHaveSpace"));
                          return;
                        }
                        if (fact.id === "qualification") {
                          toast.error(
                            t("cAudience_idAsQualificatnCantBeCreated")
                          );
                          return;
                        }
                        if (DEFAULT_FACTS.find((fc) => fc.id === fact.id)) {
                          toast.error(
                            t("cAudience_factWithIdAs") +
                              fact.id +
                              t("cAudience_cantBeCreated")
                          );
                          return;
                        }
                        if (
                          facts.find((fc) => fc.id === "details." + fact.id)
                        ) {
                          toast.error(
                            t("cAudience_factWithIdAs") +
                              fact.id +
                              t("cAudience_alreadyExists")
                          );
                          return;
                        }
                        try {
                          const f = await onFactCreate({
                            id: "details." + fact.id,
                            label: fact.label,
                            type: fact.type,
                            brand_profile_id: brandId,
                          });
                          setAddFact(false);
                          setFact({
                            id: "",
                            label: "",
                            type: "",
                          });
                          let newFacts = await fetchFacts();
                          if (newFacts.length > 0)
                            $(el.current).queryBuilder("setFilters", newFacts);
                        } catch (e) {
                          console.log(e);
                          toast.error(t("cAudience_failedToCreateFact"));
                        }
                      }}
                      color="primary"
                    >
                      {t("cAudience_save")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div ref={el}></div>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              {type === "add" && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "25px" }}
                    onClick={() => {
                      getEstimatedLeads();
                    }}
                  >
                    {t("cAudience_getEstimatedLeads")}
                  </Button>
                  {estimateLoading ? (
                    <CircularProgress
                      style={{ height: "25px", width: "25px" }}
                    />
                  ) : (
                    <Typography>
                      {estimateLeads} {t("cAudience_leads")}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
            <Hidden mdDown>
              <Grid item xs={4} />
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} style={{ height: "25px" }} />
            </Hidden>
            <Grid
              item
              xs={12}
              md={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                fullWidth
                onClick={() => {
                  // history.goBack();
                  onAction("");
                }}
                variant="contained"
                color="primary"
                style={{ marginRight: "25px" }}
              >
                {t("cAudience_cancel")}
              </Button>

              {type === "add" && (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t("cAudience_Create")}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default CustomAudienceBuilder;
