import React, { Component } from "react";
import { toast } from "react-toastify";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";

class TargetCampaign extends Component {
  constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
    this.onKeyPressValidation = this.onKeyPressValidation.bind(this);
    this.ProcessNext = this.ProcessNext.bind(this);
  }

  saveAndContinue() {
    const { target_campaign } = this.props.state;
    if (target_campaign == "" || target_campaign == 0) {
      toast.error(this.props.t("campaign_fillAccurateTarget"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      this.props.nextStep();
      toast.dismiss();
    }
  }

  onKeyPressValidation(event) {
    let val = event.target.value.replace(/[^0-9]/gi, "");
    this.props.handleChange("target_campaign", val);
  }

  gobackprevious() {
    this.props.prevStep();
  }

  ProcessNext(event) {
    if (event.key === "Enter") {
      this.saveAndContinue();
    }
  }

  render() {
    return (
      <div className="outer_div w-100 animated">
        <div className="col-12 px-0 border-rounded p-3 mb-2" id="step9">
          <div className="form-group brand-custom-form mt-2">
            <label>
              {" "}{this.props.t("campaign_targetLabel")}
               {this.props.getDisplayObjective()}?{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={
                this.props.funnel_type === 2
                  ? this.props.t("campaign_enterTarget")
                  : this.props.t("campaign_typeNumberOf") +
                    this.props.getDisplayObjective() +
                    this.props.t("campaign_here") 
              }
              onKeyPress={this.ProcessNext}
              onChange={this.onKeyPressValidation}
              value={this.props.state.target_campaign}
            />
          </div>
        </div>

        <div className="navination_bar">
          <div className="bar_search d-flex">
            <div className="above_navigation_box ml-auto">
              <button
                className="brandprofilecard__btn--white"
                onClick={this.gobackprevious}
              >{this.props.t("campaign_back")}
                
              </button>
            </div>
            <div
              className="below_navigation_box"
              style={{ marginRight: "0px" }}
            >
              <button
                className="brandoverviewleftsection__btn--purple"
                onClick={this.saveAndContinue}
              >{this.props.t("campaign_next")}
                
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TargetCampaign)