import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import { onGetPrepaidSettings } from "../../../utils/network/lib/prepaidSetting";
import PrepaidTableHeader from "../prepaidTableHeader/PrepaidTableHeader";
import PrepaidTableRow from "../prepaidTableRow/PrepaidTableRow";
import PrepaidUserForm from "../prepaidUserForm/PrepaidUserForm";

const PrepaidSettings = () => {
  const [showAddScreen, setShowAddScreen] = useState(false);

  const [settingsList, setSettingsList] = useState([]);

  const [isEditingMode, setIsEditingMode] = useState(false);

  const [editSetting, setEditSetting] = useState(null);

  // const [currencies, setCurrencies] = useState(null);

  useEffect(() => {
    getPrepaidSettings();
  }, []);

  const getPrepaidSettings = () => {
    onGetPrepaidSettings()
      .then((res) => setSettingsList(res.data.data))
      .catch((err) => toast.error(err.response.data.message));
  };

  const onEditClick = (setting) => {
    setShowAddScreen(true);
    setIsEditingMode(true);
    setEditSetting(setting);
  };

  return (
    <React.Fragment>
      {!showAddScreen && (
        <div>
          <button
            style={{ float: "right", marginBottom: "20px" }}
            className="btn btn-primary"
            onClick={() => setShowAddScreen(true)}
          >
            Add
          </button>
          <PrepaidTableHeader />
          {!settingsList.length && (
            <div
              style={{
                height: "143px",
                textAlign: "center",
                paddingTop: "83px",
                color: "#708090",
                fontWeight: "bold",
              }}
            >
              <span>There are no prepaid accounts right now</span>
            </div>
          )}

          {settingsList.length !== 0 &&
            settingsList.map((setting) => (
              <PrepaidTableRow
                getPrepaidSettings={getPrepaidSettings}
                setting={setting}
                onEditClick={onEditClick}
              />
            ))}
        </div>
      )}

      {showAddScreen && (
        <PrepaidUserForm
          setShowAddScreen={setShowAddScreen}
          isEditingMode={isEditingMode}
          editSetting={editSetting}
          setEditSetting={setEditSetting}
          setIsEditingMode={setIsEditingMode}
          getPrepaidSettings={getPrepaidSettings}
        />
      )}
    </React.Fragment>
  );
};

export default PrepaidSettings;
