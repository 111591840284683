import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { onGetBrandProfileList } from "../../../utils/network/lib/brand";
import {
  onFetchRmfLeadList,
  onGetRmfBrandProfile,
} from "../../../utils/network/lib/misc";
import PeasyApi from "../../../utils/peasy.api";
import ConsumerList from "./ConsumerList";
import ProductList from "./ProductList";
import ProductListActiveBox from "./ProductListActiveBox";
import RMF from "./RMF";
import "./rmf-screen.css";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div {...other}>
      {(props.windowWidth > 600 || value === index) && <>{children}</>}
    </div>
  );
}

const RmfScreen = () => {
  const user = useSelector((state) => state.auth.user);
  const { brandId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [brandProfileList, setBrandProfileList] = useState([]);
  const [isBrandProfileLoading, setIsBrandProfileLoading] = useState(true);
  const [activeBox, setActiveBox] = useState(0); // 0
  const [leadRows, setLeadRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);  // 0
  const [isRMFLoading, setIsRMFLoading] = useState(true);
  const [rmfData, setRMFData] = useState([]);
  const [rmfMap, setRmfMap] = useState({
    box1: {
      title: "Can't Loose Them",
      count: 0,
      percentage: 0,
      img: "cantlose.svg",
    },
    box2: {
      title: "At Risk",
      count: 0,
      percentage: 0,
      img: "atrisk.svg",
    },
    box3: {
      title: "Champions",
      count: 0,
      percentage: 0,
      img: "champions.svg",
    },
    box4: {
      title: "New Customers",
      count: 0,
      percentage: 0,
      img: "newcustomer.svg",
    },
    box5: {
      title: "Loyal Customers",
      count: 0,
      percentage: 0,
      img: "loyal.svg",
    },
    box6: {
      title: "Promising",
      count: 0,
      percentage: 0,
      img: "promising.svg",
    },
    box7: {
      title: "About To Sleep",
      count: 0,
      percentage: 0,
      img: "sleep.svg",
    },
    box8: {
      title: "Need Attention",
      count: 0,
      percentage: 0,
      img: "needattention.svg",
    },
    box9: {
      title: "Potential Loyalist",
      count: 0,
      percentage: 0,
      img: "potential.svg",
    },
    box10: {
      title: "Hibernating",
      count: 0,
      percentage: 0,
      img: "hibernating.svg",
    },
    box11: {
      title: "Lost",
      count: 0,
      percentage: 0,
      img: "lost.svg",
    },
  });
  

  const getBrandProfilelIST = async() =>{
    setIsBrandProfileLoading(true);
    try {
      // const brandProfileListRes = await PeasyApi.get("api/v1/get-brand-profile-list");
      const brandProfileListRes = await onGetBrandProfileList();
      if (Array.isArray(brandProfileListRes)) {
        setBrandProfileList(brandProfileListRes);
        if (brandId && user.active_package?.options?.catalog !== "true") {
          history.push("/dashboard");
        }
      }
    } catch (e) {
      toast.error(t("dashboard_errorWhilefetchingProfiles"));
      console.log(e);
    } finally{
      setIsBrandProfileLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    getBrandProfilelIST();
  }, [brandId]);

  

  useEffect(() => {
    if (activeBox !== 0) {
      fetchRmfLeadList(brandId,activeBox);
    }
  }, [activeBox,brandId]);

  const fetchData = async () => {
    setIsRMFLoading(true);
    try {
      const data = await onGetRmfBrandProfile(brandId);
      // console.log(data);
      setRMFData(data.data);
      let list = data.data;
      // sort based on object count
      list = list.sort((a, b) => {
        return a.count - b.count;
      });
      // console.log(list);
      setRmfMap({
        box1: list[0],
        box2: list[1],
        box3: list[2],
        box4: list[3],
        box5: list[4],
        box6: list[5],
        box7: list[6],
        box8: list[7],
        box9: list[8],
        box10: list[9],
        box11: list[10],
      });
    } catch (error) {
      console.log(error);
    }finally{
      setIsRMFLoading(false);
    }
  };
  
  const fetchRmfLeadList = async (brandId,id) => {
    try {
      setLoading(true);
      if (!brandId || !id) return;
      // const data = await PeasyApi.get(
      //   `api/v1/rmf/leads?brand_profile_id=${brandId}&rmf=${id}`
      // );
      const data = await onFetchRmfLeadList(brandId, id);
      setLeadRows(data.data);
      // console.log(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div className="rmf-screen">
      <RMF
        rmfMap={rmfMap}
        activeBox={activeBox}
        setRmfMap={setRmfMap}
        setActiveBox={setActiveBox}
        rmfData={rmfData}
        isRMFLoading={isRMFLoading}
        brandProfileList={brandProfileList}
      />
      <div className="">
        <Tabs value={tabValue} onChange={handleTabValue} className="pt-1">
          <Tab label={<>Consumer List</>} />
          <Tab label={<>Product List</>} />
        </Tabs>
      </div>
      <TabPanel value={tabValue} index={0}>
        <ConsumerList activeBox={activeBox} rows={leadRows} loading={loading} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ProductListActiveBox activeBox={activeBox} rows={leadRows} loading={loading} />
        {/* <ProductList activeBox={activeBox}  loading={loading} /> */}
      </TabPanel>
    </div>
  );
};

export default RmfScreen;
