import React from "react";
import { useHistory } from "react-router-dom";
const NotFound = () => {
  const History = useHistory();

  return (
    <React.Fragment>
      <nav class="navbar navbar-expand-lg navbar-absolute">
        <div class="container-fluid container-fluid-negative-margin">
          <a class="logo" href="#"></a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="nav nav-item">
              <button
                onClick={() => History.push("/")}
                class="navbar-brand"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <img src="/images/assets/logo.png" alt="logo" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="container">
        <div className="text-center">
          <h1 style={{ fontWeight: 100 }} className="mt-5">Page Not Found</h1>
          <p>The page you are looking for is not available</p>
          <p>
            <button onClick={() => History.push('/')} className="btn btn-primary">Go Back</button>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
