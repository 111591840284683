import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import Navbar from "../../common/navbar/Navbar";
import Stickybar from "../../common/Stickybar";
import Sidebar from "../../components/Sidebar";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const History = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem("token_auth")) {
          let userData = JSON.parse(localStorage.getItem("user_data"));
          if (userData) {
            console.log(userData,"userData in protected");
            
            if (userData.role && userData.role !== "superadmin") {
              // redirect the user to /connection if the upgraded_prepaid === 1 _sh_
              if (userData.upgraded_prepaid === true && window.location.pathname !== "/session/auth/connection") {
                return <Redirect to={"/session/auth/connection"} />;
              }
              if ( userData.subscription_status && userData.subscription_status !== "active") {
                if (
                  History.location &&
                  History.location.pathname &&
                  History.location.pathname !== "/session/auth/billing"
                ) {
                  return <Redirect to={"/session/auth/billing"} />;
                }
              }
            }

          }

          return (
            <React.Fragment>
              <Navbar />
              <Sidebar />
              <Component {...props} />
            </React.Fragment>
          );
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
