// import React, { PureComponent } from "react";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import "./BrandOverview.css";
// import { BASE_URL } from "../../StaticArray/StaticArray";
// import http from "../../shared/http-request";
// import PageLoader from "../../shared/PageLoader/PageLoader";
// import Shimmer from "../../common/shimmer/Shimmer";
// import PopupAlert from "../../common/popupAlert/PopupAlert";
// import {
//   FormControl,
//   IconButton,
//   InputLabel,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@material-ui/core";
// import Select from "@material-ui/core/Select";
// import { withStyles } from "@material-ui/styles";
// import Paper from "@material-ui/core/Paper";
// import { MoreHorizRounded } from "@material-ui/icons";

// const styles = (theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     fontSize: 16,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// });
// const ChannelDeploymentStatusSchema = {
//   OK: "OK",
//   ERROR: "ERROR",
//   IN_PROGRESS: "IN_PROGRESS",
// };

// const CampaignStatusSchema = {
//   ACTIVE: "ACTIVE",
//   PAUSED: "PAUSED",
//   DELETED: "DELETED",
// };

// export class BrandOverview extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       branddetails: {},
//       list: [],
//       budgetUsed: {},
//       kpiAchieved: {},
//       activeCurrency: "",
//       deleteCampaignId: 0,
//       componentLoaded: false,
//       chunkLength: 5,
//       lastRowIndex: 0,
//       fetching: true,
//       countRead: 0,
//       campaign_data: {},
//       campaignIds: [],
//       totalCount: 0,
//       id: window.location.href.split("/").pop(),
//       showDeletePopup: false,
//       status: "ACTIVE",
//     };
//     this.confirmDeleteCampaign = this.confirmDeleteCampaign.bind(this);
//     this.handleChange = this.handleChange.bind(this);
//     // todo delete, active class
//   }

//   handleChange = (event) => {
//     this.setState({ status: event.target.value });
//   };
//   componentWillMount() {
//     // console.log(this.state.status);
//     // const decodeId = window.location.href.split("/").pop();
//     const decodeId = "NDcy";
//     http
//       .get(`${BASE_URL}api/campaign-create-meta-data`, {
//         params: {
//           decodeId,
//         },
//       })
//       .then((res) => {
//         if (res.data.status) {
//           this.setState({
//             activeCurrency: res.data.data.currency,
//           });
//         } else if (!res.status) {
//           toast.error("Something went wrong. Please try again", {
//             position: toast.POSITION.BOTTOM_LEFT,
//           });
//         }
//       })
//       .catch((err) => {
//         toast.error("Something went wrong. Please try again", {
//           position: toast.POSITION.BOTTOM_LEFT,
//         });
//       });
//   }

//   componentDidMount() {
//     this.getBrandCampaigns();
//     // console.log(this.state.status);
//   }

//   getBrandCampaigns() {
//     // const decodeId = window.location.href.split("/").pop();
//     const decodeId = "NDcy";
//     http
//       .get(`${BASE_URL}/api/get-brand-campaigns/${decodeId}`)
//       .then((res) => {
//         this.setState({
//           list: res.data.campaigns,
//           componentLoaded: true,
//           fetching: false,
//         });
//         let campaignIds = [];
//         res.data.campaigns.map((campaign) => {
//           campaignIds = [...campaignIds, campaign.id];
//         });
//         this.setState({ campaignIds });
//         this.setState({ branddetails: res.data.branddetails });
//         this.setState({ totalCount: campaignIds.length });
//         this.fetchCampaignData();
//         // console.log(this.state.list);
//       })
//       .catch((err) => console.log("error", err));
//   }

//   setDeleteCampaignId(deleteCampaignId) {
//     this.setState({ deleteCampaignId, showDeletePopup: true });
//   }

//   confirmDeleteCampaign() {
//     const { deleteCampaignId } = this.state;
//     http
//       .delete(`${BASE_URL}api/v1/campaign-delete/${deleteCampaignId}`)
//       .then((res) => null)
//       .catch((err) => toast.error(err.response.data.message))
//       .finally(() => {
//         this.getBrandCampaigns();
//       });
//   }

//   getHrefForEdit(val) {
//     //if (val.objective === 'POST_ENGAGEMENT') return `${BASE_URL}/post-boost/${val.id}`;
//     if (val.is_completed)
//       return `${BASE_URL}/campaign/edit/detail?id=${val.id}`;
//     else return `${BASE_URL}/campaign/create?id=${val.id}`;
//   }

//   fetchCampaignData() {
//     debugger;
//     const campaignIds = Array.from(this.state.campaignIds);
//     if (campaignIds.length <= 0) {
//       return;
//     }
//     //call
//     const requestIds = campaignIds.splice(0, 5);
//     http
//       .post(`${BASE_URL}/api/get-campaign-details`, {
//         campaign_ids: requestIds,
//       })
//       .then((res) => {
//         this.setState(
//           {
//             campaign_data: {
//               ...this.state.campaign_data,
//               ...res.data.campaign_data,
//             },
//             campaignIds,
//           },
//           () => {
//             this.fetchCampaignData();
//             console.log(campaignIds);
//           }
//         );
//       })
//       .catch((err) => console.log("error", err));
//   }

//   pauseCampaign = async (id) => {
//     try {
//       const res = await http.get(`${BASE_URL}api/v1/campaign/pause`, {
//         params: {
//           campaign_id: id,
//         },
//       });
//       if (res.status === 200) {
//         toast.success("Campaign paused successfully!");
//         this.getBrandCampaigns();
//       } else throw "ERROR";
//     } catch (err) {
//       if (err.response.data.message)
//         return toast.error(err.response.data.message);
//       else toast.error("Couldn't pause campaign. Please try again");
//     }
//   };

//   startCampaign = async (id) => {
//     try {
//       const res = await http.get(`${BASE_URL}api/v1/campaign/start`, {
//         params: {
//           campaign_id: id,
//         },
//       });
//       if (res.status === 200) {
//         toast.success("Campaign started successfully!");
//         this.getBrandCampaigns();
//       } else throw "ERROR";
//     } catch (err) {
//       if (err.response.data.message)
//         return toast.error(err.response.data.message);
//       else toast.error("Couldn't start campaign. Please try again");
//     }
//   };

//   redeployCampaign = async (id) => {
//     try {
//       const res = await http.get(`${BASE_URL}api/v1/campaign/deploy`, {
//         params: {
//           campaign_id: id,
//         },
//       });
//       if (res.status === 200) {
//         toast.success("Campaign redeployed successfully!");
//         this.getBrandCampaigns();
//       } else throw "ERROR";
//     } catch (err) {
//       if (err.response.data.message)
//         return toast.error(err.response.data.message);
//       else toast.error("Couldn't start campaign. Please try again");
//     }
//   };

//   renderCampaigns = (list) => {
//     const { activeCurrency } = this.state;
//     if (list && list[0]) {
//       let campaingUI = list
//         .filter((val) => {
//           if (val.status === this.state.status && val.deleted_at === null) {
//             return true;
//           } else if (val.deleted_at !== null) {
//             return true;
//           }
//           return false;
//         })
//         .map((val) => {
//           return (
//             <>
//               <TableRow>
//                 {/* <li className="outer_campaign" key={val.id}> */}
//                 {/* <div className="row"> */}
//                 {/* <div className="col-md-1 operation_section col-sm-2 col-6"> */}
//                 <TableCell className="cell" align="left">
//                   <img
//                     style={{ width: "50px" }}
//                     src={`/images/${val.objective.toLowerCase()}.png`}
//                     // className="w-100"
//                     alt={val.objective}
//                   />
//                 </TableCell>
//                 {/* </div> */}
//                 <>
//                   <div className="status" style={{ display: "none" }}>
//                     {val.is_completed ? (
//                       val.isModifiedManually ? (
//                         <i
//                           data-toggle="tooltip"
//                           data-placement="top"
//                           title="Your campaign is halted due to change made manually on Facebook or Google Platform"
//                           className="fa fa fa-exclamation-triangle"
//                           style={{
//                             fontSize: "30px",
//                             color: "red",
//                           }}
//                         />
//                       ) : val.deployment_status ===
//                         ChannelDeploymentStatusSchema.IN_PROGRESS ? (
//                         <img
//                           width="30"
//                           src={`/images/assets/loading-campaign.gif`}
//                           alt={"loading"}
//                         />
//                       ) : val.deployment_status ===
//                         ChannelDeploymentStatusSchema.ERROR ? (
//                         <i
//                           className="fa fa fa-exclamation-triangle"
//                           data-toggle="tooltip"
//                           data-placement="top"
//                           title="We are not able to deploy your campaign, please check the notification to see the issue"
//                           style={{
//                             fontSize: "30px",
//                             color: "red",
//                           }}
//                         />
//                       ) : (
//                         <React.Fragment>
//                           {val.status === CampaignStatusSchema.ACTIVE ? (
//                             <React.Fragment>
//                               {/* <a
//                           href={
//                             BASE_URL +
//                             "/campaign/pause?id=" +
//                             val.id
//                           }
//                         > */}
//                               <i
//                                 onClick={() => this.pauseCampaign(val.id)}
//                                 className="fa fa-toggle-on active"
//                                 style={{
//                                   fontSize: "30px",
//                                   cursor: "pointer",
//                                 }}
//                               />
//                               {/* </a> */}
//                               <input
//                                 type="hidden"
//                                 name="cenddate"
//                                 value={val.end_date}
//                                 readOnly={true}
//                               />
//                             </React.Fragment>
//                           ) : (
//                             // <a
//                             //   href={
//                             //     BASE_URL +
//                             //     "/campaign/start?id=" +
//                             //     val.id
//                             //   }
//                             // >
//                             <i
//                               onClick={() => this.startCampaign(val.id)}
//                               className="fa fa-toggle-on"
//                               style={{
//                                 fontSize: "30px",
//                                 cursor: "pointer",
//                               }}
//                             />
//                             // </a>
//                           )}
//                         </React.Fragment>
//                       )
//                     ) : (
//                       <i
//                         className="fa fa-toggle-off"
//                         style={{
//                           fontSize: "30px",
//                           cursor: "pointer",
//                         }}
//                       />
//                     )}
//                   </div>
//                 </>

//                 {/* <div className="col-md-3 col-sm-8"> */}
//                 <TableCell className="cell" align="left">
//                   {val.is_completed ? (
//                     <Link to={`/campaign-report/${val.id}`}>
//                       <p className="cmpname" style={{ fontSize: "12px" }}>
//                         {val.campaign_name.slice(0, 15)}
//                       </p>
//                     </Link>
//                   ) : (
//                     <p
//                       className="cmpname"
//                       style={{ fontSize: "12px" }}
//                       onClick={() => this.props.history.push("" + val.id)}
//                     >
//                       {val.campaign_name.slice(0, 15)}
//                     </p>
//                   )}
//                   <p className="info_campaign font-grey">{val.platform}</p>
//                 </TableCell>

//                 {/* </div> */}
//                 {/* <div className="col-md-2 col-sm-4"> */}
//                 <TableCell className="cell">
//                   <h4 className="budget_info" style={{ fontSize: "12px" }}>
//                     Budget
//                   </h4>
//                   <p className="expire font-grey">
//                     {this.state.campaign_data[val.id] ? (
//                       `${parseFloat(
//                         this.state.campaign_data[val.id].budget_used
//                       ).toFixed(2)} / ${val.budget} ${activeCurrency}`
//                     ) : (
//                       <Shimmer />
//                     )}
//                   </p>
//                 </TableCell>

//                 {/* </div> */}
//                 {/* <div className="col-md-2 col-sm-4"> */}
//                 <TableCell className="cell">
//                   <h4 className="budget_info" style={{ fontSize: "12px" }}>
//                     Target
//                   </h4>
//                   <p className="expire font-grey">
//                     {this.state.campaign_data[val.id] ? (
//                       `${parseFloat(
//                         this.state.campaign_data[val.id].kpi_achieved
//                       ).toFixed(2)} / ${val.sales_goal}`
//                     ) : (
//                       <Shimmer />
//                     )}
//                   </p>
//                 </TableCell>

//                 {/* </div> */}
//                 {/* <div className="col-md-3 col-sm-4" id="edit_section"> */}
//                 <TableCell className="cell">
//                   <ul className="w-100 text-center list-inline text-uppercase list-unstyled mb-0">
//                     {/* <li className="list-inline-item">
//                       <Link
//                         to={`/campaign/edit/${val.id}`}
//                         className="btn btn-sm btn-outline-primary"
//                       >
//                         Edit
//                       </Link>
//                     </li> */}
//                     {/* <li className="list-inline-item">
//                       <a
//                         href="#"
//                         onClick={() => this.setDeleteCampaignId(val.id)}
//                         className="delete_camp"
//                       >
//                         Delete
//                       </a>
//                     </li> */}
//                     {val.deployment_status ===
//                       ChannelDeploymentStatusSchema.ERROR && (
//                       <li
//                         onClick={() => this.redeployCampaign(val.id)}
//                         className="list-inline-item"
//                       >
//                         {/* <a
//                       className="redeploy btn btn-primary"
//                       href={
//                         BASE_URL +
//                         "/campaign/redeploy?id=" +
//                         val.id
//                       }
//                     > */}
//                         Redeploy
//                         {/* </a> */}
//                       </li>
//                     )}
//                   </ul>
//                   <IconButton style={{ outline: "none" }}>
//                     <MoreHorizRounded fontSize="large" color="primary" />
//                   </IconButton>
//                 </TableCell>

//                 {/* </div> */}
//                 {/* </div> */}
//                 {/* </li> */}
//               </TableRow>
//             </>
//           );
//         });
//       if (campaingUI.length === 0) {
//         return <h2>No Campaigns</h2>;
//       }
//       return campaingUI;
//     }
//   };

//   render() {
//     // Start timing now
//     const { classes } = this.props;
//     const {
//       branddetails,
//       list,
//       id,
//       activeCurrency,
//       componentLoaded,
//       totalCount,
//     } = this.state;
//     const ret = (
//       <React.Fragment>
//         {componentLoaded ? (
//           <React.Fragment>
//             {this.state.showDeletePopup && (
//               <PopupAlert
//                 heading="Are you sure you want to delete this campaign ?"
//                 onNoClick={() => this.setState({ showDeletePopup: false })}
//                 onYesClick={() => {
//                   this.setState({ showDeletePopup: false });
//                   this.confirmDeleteCampaign();
//                 }}
//               />
//             )}

//             <div className="campaign-list-header container-fluid brand-sec-prt">
//               <div className="row">
//                 <div className="col-lg-12 col-md-12">
//                   {/* <div className="mt-5 mb-5 row mx-0">
//                     <h2 className="float-left mr-3">Brand Overview</h2>
//                     <Link
//                       to="/brand/create"
//                       className="btn btn-primary float-left new_brand_add"
//                     >
//                       + NEW BRAND PROFILE
//                     </Link>
//                   </div>

//                   <div className="back_home">
//                     <Link to="/brand-profiles">BACK TO BRAND PROFILE</Link>
//                   </div> */}

//                   <div className="card" id="overview-section">
//                     <div className="card-body">
//                       <div className="row">
//                         <div className="col-lg-6 col-sm-6 brand-name">
//                           {/* <p>{branddetails && branddetails.brand_name}</p> */}
//                           <h4>Campaign List</h4>
//                         </div>
//                         <div className="col-lg-6 col-sm-6 actions">
//                           <div className=" d-flex justify-content-end align-items-center">
//                             <div className="filter">
//                               <FormControl
//                                 variant="outlined"
//                                 className={classes.formControl}
//                               >
//                                 <InputLabel
//                                   htmlFor="status"
//                                   style={{ fontSize: "12px" }}
//                                 >
//                                   Status
//                                 </InputLabel>
//                                 <Select
//                                   labelId="status"
//                                   value={this.state.status}
//                                   onChange={this.handleChange}
//                                   label="Status"
//                                   style={{ fontSize: "12px" }}
//                                 >
//                                   <MenuItem
//                                     className="pl-2 dropdown-option"
//                                     value={"ACTIVE"}
//                                     style={{ fontSize: "12px" }}
//                                   >
//                                     Active Campaigns
//                                   </MenuItem>
//                                   <MenuItem
//                                     className="pl-2 dropdown-option"
//                                     value={"PAUSED"}
//                                     style={{ fontSize: "12px" }}
//                                   >
//                                     Paused Campaigns
//                                   </MenuItem>
//                                   <MenuItem
//                                     className="pl-2 dropdown-option"
//                                     value={"ARCHIVED"}
//                                     style={{ fontSize: "12px" }}
//                                   >
//                                     Archived Campaigns
//                                   </MenuItem>
//                                 </Select>
//                               </FormControl>
//                             </div>
//                             {/* <div className=" clearfix">
//                               <ul className="w-100 text-center list-inline text-uppercase list-unstyled mb-0">
//                                 <li className="list-inline-item">
//                                   <Link
//                                     to={`/campaign/create-new/${id}`}
//                                     className="btn btn-sm btn-primary"
//                                   >
//                                     + create campaign
//                                   </Link>
//                                 </li>
//                               </ul>
//                             </div> */}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   {/* {console.log(JSON.stringify(list))} */}

//                   <div className="w-100" id="campaign_list">
//                     <ul
//                       className="clearfix"
//                       style={{ overflow: "auto", height: "200px" }}
//                     >
//                       <TableContainer component={Paper}>
//                         <Table className="" aria-label="simple table">
//                           <TableBody>
//                             {/* {rows.map((row) => (

//                     ))} */}

//                             {this.renderCampaigns(list)}
//                           </TableBody>
//                         </Table>
//                       </TableContainer>
//                       {totalCount === 0 && (
//                         <li className="outer_campaign">
//                           <h4>No Campaign Found !!</h4>
//                         </li>
//                       )}
//                     </ul>
//                   </div>
//                 </div>
//                 <div className="col-lg-3 col-md-12 brand-sec-right">
//                   <h3 style={{ textAlign: "center", marginTop: "30vh" }}>
//                     Coming Soon
//                   </h3>
//                 </div>
//               </div>
//             </div>

//             {/* <div id="deleteconfirmation" className="modal fade" role="dialog">
//               <div className="modal-dialog">
//                 <div className="modal-content">
//                   <div className="modal-header">
//                     <h4 className="modal-title">Delete Campaign.</h4>
//                     <button type="button" className="close">
//                       &times;
//                     </button>
//                   </div>
//                   <div className="modal-body">
//                     Do You Want To Delete Campaign ?
//                   </div>
//                   <div className="modal-footer">
//                     <button
//                       type="button"
//                       className="btn btn-primary yes_confirm"
//                       style={{ borderRadius: "4px !important" }}
//                       onClick={this.confirmDeleteCampaign}
//                     >
//                       Yes
//                     </button>
//                     <button type="button" className="btn btn-default">
//                       No
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div> */}
//             <form
//               action={BASE_URL + "/campaign/pause"}
//               method="get"
//               id="pauseCampaign"
//               style={{ display: "none" }}
//             >
//               <input type="text" value="" name="id" readOnly={true} />
//               <input
//                 type="hidden"
//                 name="isModifiedManually"
//                 value="1"
//                 readOnly={true}
//               />
//             </form>
//             <form
//               action={BASE_URL + "/campaign/start"}
//               method="get"
//               id="startCampaign"
//               style={{ display: "none" }}
//             >
//               <input type="text" value="" name="id" readOnly={true} />
//               <input
//                 type="hidden"
//                 name="isModifiedManually"
//                 value="1"
//                 readOnly={true}
//               />
//             </form>
//           </React.Fragment>
//         ) : (
//           <PageLoader />
//         )}
//       </React.Fragment>
//     );
//     return ret;
//   }
// }

// export default withStyles(styles)(BrandOverview);

import React, { PureComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import "./BrandOverview.css";
import { BASE_URL } from "../../StaticArray/StaticArray";
import http from "../../shared/http-request";
import PageLoader from "../../shared/PageLoader/PageLoader";
import Shimmer from "../../common/shimmer/Shimmer";
import PopupAlert from "../../common/popupAlert/PopupAlert";
import {
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import { MoreHorizRounded, MoreVert } from "@material-ui/icons";
import SortIcon from "@material-ui/icons/Sort";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import BrandOverviewAction from "./BrandOverviewAction";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import {
  onConfirmDeleteCampaign,
  onPauseCampaign,
  onRedeployCampaign,
  onStartCampaign,
} from "../../utils/network/lib/campaign";

const styles = (theme) => ({});
const ChannelDeploymentStatusSchema = {
  OK: "OK",
  ERROR: "ERROR",
  IN_PROGRESS: "IN_PROGRESS",
};

const CampaignStatusSchema = {
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  DELETED: "DELETED",
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 550,
  },
  body: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100px",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const BrandOverview = ({ encodedId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [branddetails, setBranddetails] = useState({});
  const [list, setList] = useState([]);
  const [budgetUsed, setbudgetUsed] = useState({});
  const [kpiAchieved, setKpiAchieved] = useState({});
  const [activeCurrency, setActiveCurrency] = useState("");
  const [deleteCampaignId, setDeleteCampaignId] = useState(0);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [chunkLength, setChunkLength] = useState(5);
  const [lastRowIndex, setLastRowIndex] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [countRead, setCountRead] = useState(0);
  const [campaign_data, setCampaign_data] = useState({});
  const [campaignIds, setCampaignIds] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [id, setId] = useState(window.location.href.split("/").pop());
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [status, setStatus] = useState("ACTIVE");
  const [selectedObjective, setSelectedObjective] = useState("all");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handleChange = (event) => {
    setStatus(event.target.value);
    storeStatus(event.target.value);
  };

  useEffect(() => {
    // console.log(status);
    const decodeId = encodedId;
    http
      .get(`${BASE_URL}api/campaign-create-meta-data`, {
        params: {
          decodeId,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setActiveCurrency(res.data.data.currency);
        } else if (!res.status) {
          toast.error(t("dashboard_somethingWentWrong"), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      })
      .catch((err) => {
        toast.error(t("dashboard_somethingWentWrongWhileFetchingData"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  }, [encodedId]);

  useEffect(() => {
    getBrandCampaigns();
    // console.log(status);
  }, [encodedId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBrandCampaigns = () => {
    const decodeId = encodedId;
    http
      .get(`${BASE_URL}api/get-brand-campaigns/${decodeId}`)
      .then((res) => {
        setList(res.data.campaigns);
        setComponentLoaded(true);
        setFetching(false);
        let campaign_Ids = [];
        res.data.campaigns.map((campaign) => {
          campaign_Ids = [...campaign_Ids, campaign.id];
        });

        setCampaignIds(campaign_Ids);
        setBranddetails(res.data.branddetails);
        setTotalCount(campaign_Ids.length);
        // console.log(list);
      })
      .catch((err) => console.log("error", err));
  };

  const handleDeleteCampaignId = (value) => {
    setDeleteCampaignId(value);
    setShowDeletePopup(true);
  };

  const confirmDeleteCampaign = () => {
    onConfirmDeleteCampaign(deleteCampaignId)
      .then((res) => null)
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => {
        getBrandCampaigns();
      });
  };

  const getHrefForEdit = (val) => {
    if (val.is_completed) return `${BASE_URL}campaign/edit/detail?id=${val.id}`;
    else return `${BASE_URL}campaign/create?id=${val.id}`;
  };

  const pauseCampaign = async (id) => {
    try {
      const res = await onPauseCampaign({
        params: {
          campaign_id: id,
        },
      });

      if (res.status === 200) {
        toast.success(t("dashboard_campaignPaused"));
        getBrandCampaigns();
      } else throw "ERROR";
    } catch (err) {
      if (err.response.data.message)
        return toast.error(err.response.data.message);
      else toast.error(t("dashboard_couldntPausedCampaign"));
    }
  };

  const startCampaign = async (id) => {
    try {
      const res = await onStartCampaign({
        params: {
          campaign_id: id,
        },
      });

      if (res.status === 200) {
        toast.success(t("dashboard_campaignStarted"));
        getBrandCampaigns();
      } else throw "ERROR";
    } catch (err) {
      if (err.response.data.message)
        return toast.error(err.response.data.message);
      else toast.error(t("dashboard_couldntStartCampaign"));
    }
  };

  const redeployCampaign = async (id) => {
    try {
      const res = await onRedeployCampaign({
        params: {
          campaign_id: id,
        },
      });

      if (res.status === 200) {
        toast.success(t("dashboard_campaignRedeployed"));
        getBrandCampaigns();
      } else throw "ERROR";
    } catch (err) {
      if (err.response.data.message)
        return toast.error(err.response.data.message);
      else toast.error(t("dashboard_couldntStartCampaign"));
    }
  };

  const renderCampaigns = (list) => {
    if (list && list[0]) {
      let campaingUI = list
        .filter((o) => {
          if (o.objective === selectedObjective) {
            return true;
          } else if (selectedObjective === "all") {
            return true;
          } else {
            return false;
          }
        })
        .filter((val) => {
          if (val.status === status && val.deleted_at === null) {
            return true;
          } else if (val.deleted_at !== null) {
            return true;
          }
          return false;
        })
        .map((val, i) => {
          return (
            <>
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #eaeaea",
                  alignItems: "center",
                }}
              >
                <div className="cell" align="left">
                  <img
                    style={{ width: "35px" }}
                    src={`/images/${val.objective.toLowerCase()}.png`}
                    alt={val.objective}
                  />
                </div>
                <>
                  <div className="cell">
                    {val.is_completed ? (
                      val.isModifiedManually ? (
                        <i
                          data-toggle="tooltip"
                          data-placement="top"
                          title={t("dashboard_campaignHaltText")}
                          className="fa fa fa-exclamation-triangle"
                          style={{
                            fontSize: "30px",
                            color: "red",
                          }}
                        />
                      ) : val.deployment_status ===
                        ChannelDeploymentStatusSchema.IN_PROGRESS ? (
                        <img
                          width="30"
                          src={`/images/assets/loading-campaign.gif`}
                          alt={"loading"}
                        />
                      ) : val.deployment_status ===
                        ChannelDeploymentStatusSchema.ERROR ? (
                        <i
                          className="fa fa fa-exclamation-triangle"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={t("dashboard_campaignNotDeployed")}
                          style={{
                            fontSize: "30px",
                            color: "red",
                          }}
                        />
                      ) : (
                        <React.Fragment>
                          {val.status === CampaignStatusSchema.ACTIVE ? (
                            <React.Fragment>
                              {/* <a
                          href={
                            BASE_URL +
                            "campaign/pause?id=" +
                            val.id
                          }
                        > */}
                              <i
                                onClick={() => pauseCampaign(val.id)}
                                className="fa fa-toggle-on active"
                                style={{
                                  fontSize: "30px",
                                  cursor: "pointer",
                                }}
                              />
                              {/* </a> */}
                              <input
                                type="hidden"
                                name="cenddate"
                                value={val.end_date}
                                readOnly={true}
                              />
                            </React.Fragment>
                          ) : (
                            // <a
                            //   href={
                            //     BASE_URL +
                            //     "/campaign/start?id=" +
                            //     val.id
                            //   }
                            // >
                            <i
                              onClick={() => startCampaign(val.id)}
                              className="fa fa-toggle-on"
                              style={{
                                fontSize: "30px",
                                cursor: "pointer",
                              }}
                            />
                            // </a>
                          )}
                        </React.Fragment>
                      )
                    ) : (
                      <i
                        className="fa fa-toggle-off"
                        style={{
                          fontSize: "30px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </>

                <div className="cell" align="left">
                  {val.is_completed ? (
                    <Link to={`/campaign-report/${val.id}`}>
                      <p className="cmpname">
                        {val.campaign_name.slice(0, 15)}
                      </p>
                    </Link>
                  ) : (
                    <p
                      className="cmpname"
                      onClick={() => history.push("" + val.id)}
                    >
                      {val.campaign_name.slice(0, 15)}
                    </p>
                  )}
                  <p className="info_campaign font-grey">{val.platform}</p>
                </div>

                <div className="cell" align="left">
                  <h4 className="budget_info" style={{ fontSize: "12px" }}>
                    {t("dashboard_budget")}
                  </h4>
                  <p className="expire font-grey">
                    {parseFloat(val.budget_used).toFixed(2)} / {val.budget}
                    {activeCurrency}
                  </p>
                </div>

                <div className="cell" align="left">
                  <h4 className="budget_info" style={{ fontSize: "12px" }}>
                    {t("dashboard_target")}
                  </h4>
                  <p className="expire font-grey">
                    {parseFloat(val.kpi_achieved).toFixed(2)} /{val.sales_goal}
                  </p>
                </div>

                <div className="cell" align="left">
                  <ul className="w-100 text-center list-inline text-uppercase list-unstyled mb-0">
                    {/* <li className="list-inline-item">
                      <Link
                        to={`/campaign/edit/${val.id}`}
                        className="btn btn-sm btn-outline-primary"
                      >
                        Edit
                      </Link>
                    </li> */}
                    {/* <li className="list-inline-item">
                      <a
                        href="#"
                        onClick={() => handleDeleteCampaignId(val.id)}
                        className="delete_camp"
                      >
                        Delete
                      </a>
                    </li> */}
                    {/* {val.deployment_status ===
                      ChannelDeploymentStatusSchema.ERROR && (
                      <li
                        onClick={() => redeployCampaign(val.id)}
                        className="list-inline-item"
                      >
                        <a
                          className="redeploy btn btn-primary"
                          href={BASE_URL + "campaign/redeploy?id=" + val.id}
                        >
                          Redeploy
                        </a>
                      </li>
                    )} */}
                  </ul>
                  {/* <IconButton
                    style={{ outline: "none" }}
                    aria-controls={`simple-menu+${i.toString()}`}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreHorizRounded fontSize="large" color="primary" />
                  </IconButton> */}
                  <BrandOverviewAction
                    val={val}
                    handleDeleteCampaignId={handleDeleteCampaignId}
                    ChannelDeploymentStatusSchema={
                      ChannelDeploymentStatusSchema
                    }
                    redeployCampaign={redeployCampaign}
                  />
                </div>
              </div>
            </>
          );
        });
      if (campaingUI.length === 0) {
        return <h4 className="text-center">{t("dashboard_noCampaigns")}</h4>;
      }
      return campaingUI;
    }
  };

  const storeObejct = async (selectedObjective) => {
    localStorage.setItem("objective", selectedObjective);
  };

  const storeStatus = async (status) => {
    localStorage.setItem("status", status);
  };

  useEffect(() => {
    getObjective();
  }, [selectedObjective]);

  useEffect(() => {
    getStatus();
  }, [status]);

  const getObjective = async () => {
    try {
      const getSelectObjective = await localStorage.getItem("objective");
      if (getSelectObjective) setSelectedObjective(getSelectObjective);
    } catch (e) {}
  };

  const getStatus = async () => {
    try {
      const getStatus = await localStorage.getItem("status");
      if (getStatus) setStatus(getStatus);
    } catch (e) {}
  };

  const ret = (
    <React.Fragment>
      {componentLoaded ? (
        <React.Fragment>
          {showDeletePopup && (
            <PopupAlert
              heading={t("dashboard_deleteCamapaign")}
              onNoClick={() => setShowDeletePopup(false)}
              onYesClick={() => {
                setShowDeletePopup(false);
                confirmDeleteCampaign();
              }}
            />
          )}

          <div className="campaign-list-header  brand-sec-prt">
            {/* <div className="row">
              <div className="col col-sm-12"> */}
            <div className="card" id="overview-section">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 brand-name">
                    {/* <p>{branddetails && branddetails.brand_name}</p> */}
                    <h4>{t("dashboard_campaignList")}</h4>
                  </div>
                  <div
                    className="col-6"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <SortIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          var l = list.sort((a, b) =>
                            a.id < b.id ? 1 : b.id < a.id ? -1 : 0
                          );
                          setList(l);
                          handleClose();
                        }}
                      >
                        {t("dashboard_newestFirst")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          var l = list.sort((a, b) =>
                            a.id > b.id ? 1 : b.id > a.id ? -1 : 0
                          );
                          console.log(l);
                          setList(l);
                          handleClose();
                        }}
                      >
                        {t("dashboard_oldestFirst")}
                      </MenuItem>
                    </Menu>
                  </div>
                  <div className="col-lg-12 col-sm-12 actions">
                    <div className=" ">
                      <div className="filter mt-2 mb-1">
                        <FormControl
                          variant="outlined"
                          className={`${classes.formControl} status-select mr-2`}
                        >
                          <InputLabel
                            htmlFor="objective"
                            style={{ fontSize: "12px" }}
                          >
                            {t("dashboard_objective")}
                          </InputLabel>
                          <Select
                            labelId="objective"
                            value={selectedObjective}
                            onChange={(e) => {
                              setSelectedObjective(e.target.value);
                              storeObejct(e.target.value);
                            }}
                            label={t("dashboard_objective")}
                            style={{ fontSize: "12px" }}
                          >
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"all"}
                              style={{ fontSize: "12px" }}
                            >
                              {t("dashboard_all")}
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"LEAD"}
                              style={{ fontSize: "12px" }}
                            >
                              {/* {t("dashboard_activeCampaign")} */}
                              Lead
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"SALES"}
                              style={{ fontSize: "12px" }}
                            >
                              {/* {t("dashboard_pausedCampaign")} */}
                              Sales
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"CLICK"}
                              style={{ fontSize: "12px" }}
                            >
                              {/* {t("dashboard_archivedCampaign")} */}
                              Click
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"IMPRESSION"}
                              style={{ fontSize: "12px" }}
                            >
                              {/* {t("dashboard_archivedCampaign")} */}
                              Impression
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"TRAFFIC"}
                              style={{ fontSize: "12px" }}
                            >
                              {/* {t("dashboard_archivedCampaign")} */}
                              Traffic
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"POST_ENGAGEMENT"}
                              style={{ fontSize: "12px" }}
                            >
                              {/* {t("dashboard_archivedCampaign")} */}
                              Post Engagement
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          className={`${classes.formControl} status-select`}
                        >
                          <InputLabel
                            htmlFor="status"
                            style={{ fontSize: "12px" }}
                          >
                            {t("dashboard_Status")}
                          </InputLabel>
                          <Select
                            labelId="status"
                            value={status}
                            onChange={handleChange}
                            label={t("dashboard_Status")}
                            style={{ fontSize: "12px" }}
                          >
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"ACTIVE"}
                              style={{ fontSize: "12px" }}
                            >
                              {t("dashboard_activeCampaign")}
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"PAUSED"}
                              style={{ fontSize: "12px" }}
                            >
                              {t("dashboard_pausedCampaign")}
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"ARCHIVED"}
                              style={{ fontSize: "12px" }}
                            >
                              {t("dashboard_archivedCampaign")}
                            </MenuItem>
                            <MenuItem
                              className="pl-2 dropdown-option"
                              value={"IN_REVIEW"}
                              style={{ fontSize: "12px" }}
                            >
                              {t("dashboard_reviewCampaign")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100" id="campaign_list">
              <div
                className="clearfix"
                style={{ overflow: "auto", height: "320px" }}
                id="style-2"
              >
                <div component={Paper}>
                  <div className={classes.table} aria-label="a dense table">
                    <div className="m-auto">
                      <div className={classes.body}>
                        {renderCampaigns(list)}
                      </div>
                    </div>
                  </div>
                </div>
                {totalCount === 0 && (
                  <h4 className="text-center">
                    {t("dashboard_noCampaignsFound")}
                  </h4>
                )}
              </div>
            </div>
            {/* </div>
            </div> */}
          </div>

          <form
            action={BASE_URL + "campaign/pause"}
            method="get"
            id="pauseCampaign"
            style={{ display: "none" }}
          >
            <input type="text" value="" name="id" readOnly={true} />
            <input
              type="hidden"
              name="isModifiedManually"
              value="1"
              readOnly={true}
            />
          </form>
          <form
            action={BASE_URL + "campaign/start"}
            method="get"
            id="startCampaign"
            style={{ display: "none" }}
          >
            <input type="text" value="" name="id" readOnly={true} />
            <input
              type="hidden"
              name="isModifiedManually"
              value="1"
              readOnly={true}
            />
          </form>
        </React.Fragment>
      ) : (
        <div
          style={{ minHeight: "250px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      )}
    </React.Fragment>
  );
  return ret;
};

export default withStyles(styles)(BrandOverview);
