import React, { useEffect } from "react";
import "./PopupAlert.css";

const PopupAlert = ({
  heading,
  body,
  onNoClick,
  onYesClick,
  yesText,
  noText,
  isError,
  footer,
  showButton = true,
}) => {
  // Disable background scroll when popup opens and set to auto when closes
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  useEffect(
    () => () => {
      document.body.style.overflow = "auto";
    },
    []
  );

  // Code till here to disable background scroll

  return (
    <React.Fragment>
      <div className="popupalert__background" />
      <div className="popupalert__container">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="staticBackdropLabel">
              {heading}
            </h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                onNoClick(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div style={{ fontSize: "14px" }} className="modal-body">
            {body}
          </div>
          {showButton && (
            <div className="modal-footer">
              <h3 style={{ marginRight: "15rem", color: "red" }}>{footer}</h3>
              {!isError ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ fontSize: "20px" }}
                  data-dismiss="modal"
                  onClick={() => onNoClick(false)}
                >
                  {noText || "No"}
                </button>
              ) : (
                ""
              )}
              <button
                type="button"
                style={{ fontSize: "20px" }}
                onClick={() => onYesClick()}
                className="btn btn-primary"
              >
                {isError ? "Ok" : yesText || "Yes"}
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default PopupAlert;
