import React, { useState } from "react";
import { toast } from "react-toastify";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PopupAlert from "../../../common/popupAlert/PopupAlert";
import "./PrepaidTableRow.css";
import { onPrepaidSettingsDeleteRow } from "../../../utils/network/lib/prepaidSetting";

const PrepaidTableRow = ({ getPrepaidSettings, setting, onEditClick }) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const deleteRow = () => {
    onPrepaidSettingsDeleteRow(setting.id)
      .then((res) => {
        toast.success("Setting deleted succefully");
        setShowDeletePopup(false);
        getPrepaidSettings();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setShowDeletePopup(false);
      });
  };

  return (
    <React.Fragment>
      {showDeletePopup && (
        <PopupAlert
          heading="Are you sure you want to delete this setting?"
          body=""
          onNoClick={() => setShowDeletePopup(false)}
          onYesClick={() => deleteRow()}
        />
      )}

      <div className="prepaidtablerow__container">
        <span className="prepaidtablerow__span--country">
          {setting.currency}
        </span>

        <span className="prepaidtablerow__span--tier1">
          0 - {setting.tier1_ceiling} <br />{" "}
          <span>{`(${setting.tier1_rate})%`}</span>
        </span>

        <span className="prepaidtablerow__span--tier2">
          {setting.tier1_ceiling + 1} - {setting.tier2_ceiling} <br />{" "}
          <span className="prepaidtablerow__span--percent">{`(${setting.tier2_rate})%`}</span>
        </span>

        <span className="prepaidtablerow__span--tier3">
          {setting.tier2_ceiling + 1} - {setting.tier3_ceiling} <br />{" "}
          <span className="prepaidtablerow__span--percent">{`(${setting.tier3_rate})%`}</span>
        </span>

        <span className="prepaidtablerow__span--users">{setting.users}</span>

        <span className="prepaidtablerow__span--action">
          <i
            className="fas fa-edit"
            onClick={() => onEditClick(setting)}
            style={{ cursor: "pointer" }}
          />{" "}
          /{" "}
          <i
            onClick={() => setShowDeletePopup(true)}
            className="fas fa-trash-alt"
            style={{ cursor: "pointer" }}
          />
        </span>
      </div>
    </React.Fragment>
  );
};

export default PrepaidTableRow;
