import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select as MaterialSelect,
  Hidden,
  MenuItem,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React from "react";
import { withTranslation } from "react-i18next";
import "../../translations/i18n";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import http from "../../shared/http-request";
import { BASE_URL } from "../../StaticArray/StaticArray";
import PeasyApi from "../../utils/peasy.api";
import { QUALIFICATION_FILTERS } from "./clientConstants/Constant";
import ClientListingTable from "./ClientListingTable/ClientListingTable";
import "./Clients.css";
import Pagination from "./Pagination/Pagination";
import SelectFilter from "./SelectFilters/SelectFilter";
import UploadTextFile from "./uploadTextFiles/uploadFile";
import {
  onDeleteValue,
  onExportLeads,
  onUpdateQualification,
} from "../../utils/network/lib/lead";
import {
  onGetBandLeads,
  onGetBrandProfileList,
} from "../../utils/network/lib/brand";

const rowsPerPage = 25;

const predictionOptions = [
  {
    value: "0",
    label: "0 To 30",
  },
  {
    value: "1",
    label: "31 To 60",
  },
  {
    value: "2",
    label: "61 To 100",
  },
];

class Clients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilter: "name",
      tableData: [],
      showClientFilter: false,
      allTableData: [],
      orginalTableData: [],
      allSearch: "",
      prediction_value: "0",
      predictionOptions: predictionOptions,
      qualification_value: "",
      loadingData: false,
      date: new Date(),
      date_selected_from: null,
      date_selected_to: null,
      fromDateType: "text",
      toDateType: "text",
      showImportModal: false,
      isExportLoading: false,
      brandProfileList: [],
      currentBrandId: "",
      // searchValue: "",
    };
    this.brand_profile_id = null;
    this.filterDataBadge = [];
    this.showClientFilter = this.showClientFilter.bind(this);
    this.fetchBrandProfileList = this.fetchBrandProfileList.bind(this);
    this.filterOptionsMap = {
      campaign_name: "Campaign Name",
      adset_name: "Ad Set Name",
      campaign_id: "Campaign Id",
      adset_id: "Add Set Id",
      registered_before: "Registered Between",
      prediction_score: "Prediction Score",
      qualification: "Qualification Status",
    };
    this.filterOptionMapValues = {
      campaign_name: "",
      adset_name: "",
      campaign_id: "",
      adset_id: "",
      registered_before: "",
      prediction_score: "",
      qualification: "",
    };
  }

  loadTable = (brand_profile_id) => {
    this.setState({ loadingData: true });

    onGetBandLeads(brand_profile_id)
      .then((res) => {
        const first10Rows = [...res.data.data];
        let tableData = [];
        let i = 0;
        first10Rows.map((row) => {
          if (i < rowsPerPage) {
            tableData.push(row);
            i++;
          }
        });
        this.setState({
          allTableData: [...res.data.data],
          orginalTableData: [...res.data.data],
          tableData,
          loadingData: false,
        });
      })
      .catch((err) => {
        this.setState({ loadingData: false });
        toast.error(this.props.t("clients_somethingWentWrong"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  fetchBrandProfileList = async () => {
    try {
      const data = await onGetBrandProfileList();
      if (data) {
        // setBrandProfileList(data);
        this.setState({ brandProfileList: data });
        // setIsbrandProfileListLoading(false);
        if (data.length > 0) {
          // setIsBrandProfileListEmpty(false);
          // setCurrentBrandId(data[0].id);
          this.setState({
            currentBrandId: this.props.match.params.id
              ? this.props.match.params.id
              : data[0].id,
          });
          if (!this.props.match.params.id) {
            this.loadTable(data[0].id);
            this.props.history.push({
              pathname: `/brand/leads/${data[0].id}`,
              // state: param,
            });
          }
        } else if (data.length === 0) {
          // setIsBrandProfileListEmpty(true);
        }
      } else {
        // setIsBrandProfileListEmpty(true);
      }
    } catch (e) {
      // toast.error(t("dashboard_errorWhilefetchingProfiles"));
      console.log(e);
    }
  };
  componentDidMount() {
    // Getting id from path url
    debugger;
    const pathName = this.props.location.pathname;
    const brandId = this.props.match.params.id;
    console.log(this.props);
    // console.log(this.props.match.params.id);

    // Splitting the path to get the last part as the brand_profile_id
    this.brand_profile_id = pathName.split("/").pop();

    if (brandId) {
      this.loadTable(brandId);
    }

    this.fetchBrandProfileList();
  }

  getDate = (cell, row) => {
    return cell;
  };

  renderSelectedFilters() {
    if (this.filterDataBadge.length > 0) {
      return this.filterDataBadge;
    }
  }

  resetFilters = (e) => {
    this.filterDataBadge = [];
    let filterOptionMapValues = {
      name: "",
      set_name: "",
      campaign_id: "",
      adset_id: "",
      registered_before: "",
      prediction_score: "",
      qualification: "",
    };
    this.setState({
      allTableData: this.state.orginalTableData,
      fromDateType: "text",
      toDateType: "text",
      filterOptionMapValues: filterOptionMapValues,
    });
  };

  showClientFilter() {
    this.setState({
      showClientFilter: !this.state.showClientFilter,
    });
  }

  removeFilter(data) {
    this.filterOptionMapValues[data] = "";
    this.filterDataBadge = this.filterDataBadge.filter((filter) => {
      return filter.key !== data;
    });
    this.setState({ date: new Date(), selectedFilter: "" });
    this.searchBasedOnFilters();
  }

  searchBasedOnFilters = () => {
    let objKeys = Object.keys(this.filterOptionMapValues);

    const orginalData = [...this.state.orginalTableData];

    let filteredData = orginalData;

    objKeys.map((key) => {
      let orginalData = filteredData;

      if (
        this.filterOptionMapValues[key] !== null &&
        this.filterOptionMapValues[key] !== ""
      ) {
        switch (key) {
          case "registered_before":
            const { date_selected_from, date_selected_to } = this.state;
            filteredData = orginalData.filter((data) => {
              if (date_selected_to == null && date_selected_from == null) {
                return true;
              } else if (
                date_selected_to == null &&
                date_selected_from !== null
              ) {
                return (
                  new Date(data["created_at"]) >=
                  new Date(this.state.date_selected_from + " 00:00:00")
                );
              } else if (
                date_selected_to !== null &&
                date_selected_from == null
              ) {
                return (
                  new Date(data["created_at"]) <=
                  new Date(this.state.date_selected_to + " 23:59:59")
                );
              } else {
                return (
                  new Date(data["created_at"]) >=
                    new Date(this.state.date_selected_from + " 00:00:00") &&
                  new Date(data["created_at"]) <=
                    new Date(this.state.date_selected_to + " 23:59:59")
                );
              }
            });
            break;

          case "prediction_score":
            let Val = predictionOptions.filter((option) => {
              return this.filterOptionMapValues[key].includes(option.label);
            });

            let fromVal = parseInt(Val[0].label.split(" ")[0].trim());
            let ToVal = parseInt(Val[0].label.split(" ")[2].trim());
            filteredData = orginalData.filter((data) => {
              return (
                data["predicted_score"] >= fromVal &&
                data["predicted_score"] < ToVal
              );
            });
            break;
          case "qualification":
            let qualification_array = QUALIFICATION_FILTERS.filter((qual) => {
              return this.filterOptionMapValues[key].includes(qual.label)
                ? qual.value
                : 0;
            });
            qualification_array = qualification_array.map((q) => {
              return q.value;
            });
            filteredData = orginalData.filter((data) => {
              return qualification_array.indexOf(data["qualification"]) !== -1;
            });
            break;
          default:
            filteredData = orginalData.filter((data) => {
              return data[key].includes(this.filterOptionMapValues[key]);
            });
            break;
        }
      }
    });
    let tableData = [];
    let i = 0;
    filteredData.map((row) => {
      if (i < rowsPerPage) {
        tableData.push(row);
        i++;
      }
    });
    this.setState({
      allTableData: filteredData,
      tableData,
      showClientFilter: !this.state.showClientFilter,
      fromDateType: "text",
      toDateType: "text",
    });
  };

  onSelectChangeHandler = (e) => {
    // debugger;
    const { filter_value, selectedFilter } = this.state;
    this.removeFilter(selectedFilter);
    let filterValue = "";
    switch (selectedFilter) {
      case "registered_before":
        filterValue = filter_value;
        break;
      case "registered_after":
        filterValue = filter_value;
        break;
      case "prediction_score":
        filterValue = filter_value.label;
        break;
      case "qualification":
        filterValue = filter_value.map((value) => {
          return value.label;
        });
        filterValue = filterValue.join(",");
        break;
      default:
        filterValue = filter_value;
        break;
    }
    this.filterDataBadge.push(
      <li key={selectedFilter} className="clients_filter_badge">
        {this.filterOptionsMap[selectedFilter]} :
        <span style={{ color: "#8D02FA" }}>{filterValue}</span>{" "}
        &nbsp;&nbsp;&nbsp;
        <Cancel
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            this.removeFilter(selectedFilter);
          }}
        />
      </li>
    );
    this.filterOptionMapValues[selectedFilter] = filterValue;
    this.searchBasedOnFilters();
  };

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      filter_value: e.target.value,
    });
  };

  onAllSearchHandler = () => {
    const orginalData = [...this.state.orginalTableData];
    let tableData = [];
    let i = 0;

    // let filterValue = e.target.value;

    // let keys = Object.keys(orginalData[0]);
    let filteredData = [];

    for (let i = 0; i < orginalData.length; i++) {
      let currentRow = orginalData[i];
      let keys = Object.keys(currentRow);

      keys.map((key) => {
        let val = currentRow[key] + "";
        if (val.includes(this.state.allSearch)) {
          let pushOp = true;
          if (filteredData.length > 0) {
            filteredData.map((data) => {
              if (data["id"] === currentRow["id"]) {
                pushOp = false;
              }
            });
          }
          if (pushOp) {
            filteredData.push(currentRow);
          }
        }
      });
    }
    filteredData.map((row) => {
      if (i < rowsPerPage) {
        tableData.push(row);
        i++;
      }
    });
    this.setState({
      allTableData: filteredData,
      tableData: tableData,
      // allSearch: e.target.value,
    });
  };

  updateQualificationChangeHandler = (id, data) => {
    onUpdateQualification(id, { qualification: data })
      .then((res) => {
        this.loadTable(this.brand_profile_id);
      })
      .catch((err) => {
        this.setState({ date: new Date() });
        toast.error(this.props.t("clients_somethngWrongWhileUpdating"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  onSuccessImport = (message, status) => {
    this.setState({ showImportModal: false });
    if (status) {
      toast.success(this.props.t("clients_notifiedViaEmail"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error(this.props.t("clients_somethingWrongWhileImportingLeads"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  onApplyEdit = (data) => {
    this.loadTable(this.brand_profile_id);
  };

  onPageChanged = (data) => {
    const { allTableData } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const tableData = allTableData.slice(offset, offset + pageLimit);

    this.setState({ currentPage, tableData, totalPages });
  };

  deleteValue = (id) => {
    onDeleteValue(id).then((res) => {
      this.loadTable(this.brand_profile_id);
    });
  };

  setFilterOptionsByValue = (value) => {
    this.setState({ selectedFilter: value });
  };

  onSelectChangeHandlerCancel = (e) => {
    this.setState({
      showClientFilter: false,
      selectedFilter: "",
      fromDateType: "text",
      toDateType: "text",
    });
  };
  onDateChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      filter_value: e.target.value,
    });
  };

  onPredictionValueChangeHandler = (value) => {
    this.setState({ prediction_value: value, filter_value: value });
  };

  onQualificationValueChangeHandler = (value) => {
    this.setState({ qualification_value: value, filter_value: value });
  };

  returnFiltersBySelection = () => {
    const { selectedFilter } = this.state;
    switch (selectedFilter) {
      case "registered_before":
        return (
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <input
                className={
                  this.state.fromDateType == "text"
                    ? "client_filter_input"
                    : "form-control"
                }
                onChange={this.onDateChangeHandler}
                type={this.state.fromDateType}
                name="date_selected_from"
                placeholder={this.props.t("clients_from")}
                onFocus={(e) => {
                  this.setState({ fromDateType: "date" });
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <input
                type={this.state.toDateType}
                placeholder={this.props.t("clients_to")}
                name="date_selected_to"
                className={
                  this.state.toDateType == "text"
                    ? "client_filter_input"
                    : "form-control"
                }
                onChange={this.onDateChangeHandler}
                onFocus={(e) => {
                  this.setState({ toDateType: "date" });
                }}
              />
            </div>
            &nbsp;
          </div>
        );
      case "prediction_score":
        return (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Select
                options={this.state.predictionOptions}
                onChange={this.onPredictionValueChangeHandler}
                value={this.state.prediction_value}
              />
            </div>
          </div>
        );
      case "qualification":
        return (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Select
                options={QUALIFICATION_FILTERS}
                onChange={this.onQualificationValueChangeHandler}
                value={this.state.qualification_value}
                isMulti
              />
            </div>
          </div>
        );
      case "campaign_name":
        return (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <input
                type="text"
                name="filter_value"
                id="filter_value"
                onChange={this.onChangeHandler}
                className="client_filter_input"
              />
            </div>
          </div>
        );
      case "adset_name":
        return (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <input
                type="text"
                name="filter_value"
                id="filter_value"
                onChange={this.onChangeHandler}
                className="client_filter_input"
              />
            </div>
          </div>
        );
      case "campaign_id":
        return (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <input
                type="text"
                name="filter_value"
                id="filter_value"
                onChange={this.onChangeHandler}
                className="client_filter_input"
              />
            </div>
          </div>
        );
      case "adset_id":
        return (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <input
                type="text"
                name="filter_value"
                id="filter_value"
                onChange={this.onChangeHandler}
                className="client_filter_input"
              />
            </div>
          </div>
        );
    }
  };

  sortItems = (key, order) => {
    const { allTableData } = this.state;
    let dataToBeSorted = [...allTableData];
    switch (key) {
      case "PREDICTED_SCORE":
        if (order == "DESC") {
          dataToBeSorted = dataToBeSorted.sort((a, b) => {
            return b.predicted_score - a.predicted_score;
          });
        } else {
          dataToBeSorted = dataToBeSorted.sort(
            (a, b) => a.predicted_score - b.predicted_score
          );
        }
        break;
      case "QUALIFICATION":
        if (order == "DESC") {
          dataToBeSorted.sort((a, b) => b.qualification - a.qualification);
        } else {
          dataToBeSorted.sort((a, b) => a.qualification - b.qualification);
        }
        break;
      case "DATE":
        if (order == "DESC") {
          dataToBeSorted.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
        } else {
          dataToBeSorted.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );
        }
        break;
    }

    const first10Rows = [...dataToBeSorted];
    let tableData = [];
    let i = 0;
    first10Rows.map((row) => {
      if (i < rowsPerPage) {
        tableData.push(row);
        i++;
      }
    });

    this.setState({ allTableData: dataToBeSorted, tableData });
  };
  onImportbuttonClicked = () => {
    this.setState({ showImportModal: !this.state.showImportModal });
  };

  onExportClick = () => {
    let brandId = this.brand_profile_id;
    const { allSearch } = this.state;
    this.setState({ isExportLoading: true });
    let requestUri = `?brand_profile_id=${brandId}`;
    if (allSearch != "") {
      requestUri = `${requestUri}&search=${allSearch}`;
    }
    let filters = this.filterOptionMapValues;
    for (let key in filters) {
      if (key != "registered_before" && filters[key] != "") {
        let value = filters[key].split(" ").join("+");
        requestUri = `${requestUri}&${key}=${value}`;
      }
      if (key === "registered_before" && filters[key] != "") {
        if (this.state.date_selected_from != null) {
          requestUri = `${requestUri}&date_selected_from=${this.state.date_selected_from}`;
        }
        if (this.state.date_selected_to != null) {
          requestUri = `${requestUri}&date_selected_to=${this.state.date_selected_to}`;
        }
      }
    }
    onExportLeads(requestUri)
      .then((response) => {
        let path = response.data.path.split("/")[1];
        http
          .get(BASE_URL + `file/download?path=${response.data.path}`)
          .then((response) => {
            let data = new Blob([response.data], { type: "text/csv" });
            let csvUrl = window.URL.createObjectURL(data);
            let tempLink = document.createElement("a");
            tempLink.href = csvUrl;
            tempLink.setAttribute("download", path);
            tempLink.click();
            this.setState({ isExportLoading: false });
          })
          .catch((err) => {
            toast.error(this.props.t("clients_errorWhileDownloadingFile"), {
              position: "bottom-left",
            });
            console.log(`Error in Download path => ${err}`);
            this.setState({ isExportLoading: false });
          });
      })
      .catch((err) => {
        toast.error(this.props.t("clients_errorWhileServer"), {
          position: "bottom-left",
        });
        console.log(`Error in export leads => ${err}`);
        this.setState({ isExportLoading: false });
      });
  };

  render() {
    const { allTableData, isExportLoading } = this.state;
    const allTableDataRecords = allTableData.length;

    return (
      <div className="clients_container">
        <div style={{ marginTop: "6vh" }}>
          <div className="d-flex align-tems-center justify-content-between mb-2 client-head">
            <span className="clients_heading mb-2">
              <Hidden smDown>{this.props.t("clients_listing")}</Hidden>
            </span>
            {this.state.brandProfileList && this.state.currentBrandId && (
              <FormControl variant="outlined" className={`profile-select`}>
                <InputLabel htmlFor="profile">
                  {this.props.t("dashboard_profiles")}
                </InputLabel>
                <MaterialSelect
                  labelId="profile"
                  value={this.state.currentBrandId}
                  onChange={(e) => {
                    this.setState({
                      currentBrandId: e.target.value,
                      orginalTableData: [],
                      tableData: [],
                    });
                    this.brand_profile_id = e.target.value;
                    this.loadTable(e.target.value);
                    this.props.history.push({
                      pathname: `/brand/leads/${e.target.value}`,
                      // state: param,
                    });
                  }}
                  // label={t("dashboard_profiles")}
                  label="Profiles"
                >
                  {this.state.brandProfileList.map((item) => (
                    <MenuItem
                      className="pl-2 dropdown-option"
                      value={item.id}
                      key={item.id}
                    >
                      {item.brand_name}
                    </MenuItem>
                  ))}
                </MaterialSelect>
              </FormControl>
            )}
          </div>
          <div className="client_table_div">
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="m-2 client-search-div">
                  <input
                    className="form-control"
                    type="text"
                    name="all_search"
                    id="all_search"
                    value={this.state.allSearch}
                    onChange={(e) => {
                      this.setState({ allSearch: e.target.value });
                    }}
                  />
                  <Button
                    className="ml-2 "
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={this.onAllSearchHandler}
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    Search
                  </Button>
                  <Button
                    className="ml-2 "
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        tableData: this.state.orginalTableData.slice(0, 25),
                        allTableData: this.state.orginalTableData,
                        allSearch: "",
                      })
                    }
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    Reset
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="mr-2 ml-2 pt-2 pb-2"
                    onClick={this.showClientFilter}
                    aria-expanded="false"
                    // id="clients_fitler_menu"
                    aria-haspopup="true"
                  >
                    <i
                      style={{ margin: 0, fontSize: "20px" }}
                      className="fa fa-filter"
                      id="clients_fa_filter"
                      aria-hidden="true"
                    ></i>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="client-action-btn p-2 h-100">
                  {!this.state.showClientFilter && (
                    <>
                      {/* <Link
                        to={`/brand/custom-audience/${this.brand_profile_id}`}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          type="button"
                          className="btn"
                          color="primary"
                        >
                          Custom Audience
                        </Button>
                      </Link> */}

                      <Button
                        variant="outlined"
                        size="small"
                        type="button"
                        className="btn"
                        color="primary"
                        onClick={this.onImportbuttonClicked}
                      >
                        {this.props.t("clients_import")}
                      </Button>

                      <Button
                        variant="outlined"
                        size="small"
                        type="button"
                        className="btn"
                        color="primary"
                        onClick={this.onExportClick}
                        disabled={isExportLoading}
                      >
                        {isExportLoading
                          ? `${this.props.t("clients_export")}..`
                          : this.props.t("clients_export")}
                      </Button>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} lg={6} className="m-2">
                {this.state.showClientFilter && (
                  <div
                    id="clients_filter_menu_drop_down"
                    // style={{ width: "45rem" }}
                  >
                    <span className="dropdown-menu-arrow"></span>
                    <div className="row" style={{ padding: "1%" }}>
                      <div className="col-md-5 col-sm-12">
                        <div className="form-group">
                          <SelectFilter
                            setFilterOptionsByValue={
                              this.setFilterOptionsByValue
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-7 col-sm-12">
                        {this.returnFiltersBySelection()}
                      </div>
                    </div>
                    <Grid container>
                      <Grid item xs={12} className="d-flex justify-content-end">
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          type="button"
                          // className="btn"
                          // id="filter_apply_button"
                          onClick={this.onSelectChangeHandler}
                        >
                          {this.props.t("clients_apply")}
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          type="button"
                          className="btn ml-2 mr-2"
                          // id="filter_cancel_button"
                          onClick={this.onSelectChangeHandlerCancel}
                        >
                          {this.props.t("clients_cancel")}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
                <ul className="">{this.renderSelectedFilters()}</ul>
                <div className="">
                  {this.filterDataBadge.length > 0 ? (
                    <span id="">
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        href=""
                        onClick={this.resetFilters}
                      >
                        {this.props.t("clients_reset")}
                      </Button>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>

            <ClientListingTable
              tableData={this.state.tableData}
              updateQualificationChangeHandler={
                this.updateQualificationChangeHandler
              }
              onApplyEdit={this.onApplyEdit}
              deleteValue={this.deleteValue}
              loadingData={this.state.loadingData}
              sortItems={this.sortItems}
            />
            <div className="table-footer">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="container">
                    {this.state.tableData.length > 0 ? (
                      <label className="text-primary">
                        <span style={{ color: "#6E6893" }}>
                          {this.props.t("clients_noOfLeads")}
                        </span>{" "}
                        {this.state.allTableData.length}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {!this.state.loadingData && (
                  <div className="col-md-6 col-sm-12">
                    <Pagination
                      totalRecords={allTableDataRecords}
                      pageLimit={25}
                      pageNeighbours={1}
                      onPageChanged={this.onPageChanged}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.showImportModal && (
          <UploadTextFile
            brand_profile_id={this.brand_profile_id}
            onImportbuttonClicked={this.onImportbuttonClicked}
            onSuccessImport={this.onSuccessImport}
          />
        )}
      </div>
    );
  }
}
export default withTranslation()(withRouter(Clients));
