import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import http from "../../../shared/http-request";
import {
  onDeletePrepaidAdConnection,
  onGetPrepaidFbAccounts,
  onPostPrepaidAdConnection,
} from "../../../utils/network/lib/prepaidSetting";

const PrepaidScreenFbTable = ({
  activeAccounts,
  savedData,
  getActiveAccounts,
  currency,
}) => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    onGetPrepaidFbAccounts()
      .then((res) => {
        setAccounts(res.data.data);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const logoutFbAccount = () => {
    http
      .delete(
        `${BASE_URL}api/v1/      setEditSetting(null);
      setEditSetting(null);
      ser/removeActivateAccountFb`
      )
      .then(() => null)
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => getAccounts());
  };

  const onToggle = (activeAccountId) => {
    if (activeAccounts.length > 0) {
      console.log("active accounts length > 0");
      let isAlreadyActive = false;
      for (let i = 0; i < activeAccounts.length; i++) {
        if (activeAccounts[i].active_account_id === activeAccountId) {
          isAlreadyActive = true;
        }
      }
      if (isAlreadyActive) {
        console.log("going to deselct");
        return deselectAccount(activeAccountId);
      } else return selectAccount(activeAccountId);
    }
    return selectAccount(activeAccountId);
  };

  const selectAccount = (activeAccountId) => {
    onPostPrepaidAdConnection({
      prepaid_account_setting_id: savedData.id,
      channel: "facebook",
      active_account_id: activeAccountId,
    })
      .then((res) => res)
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => getActiveAccounts());
  };

  const deselectAccount = (activeAccountId) => {
    onDeletePrepaidAdConnection(savedData.id, activeAccountId)
      .then((res) => res)
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => getActiveAccounts());
  };

  const isChecked = (accountId) => {
    for (let i = 0; i < activeAccounts.length; i++) {
      if (activeAccounts[i].active_account_id === accountId) return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: "100px" }} className="connect-acc-wrapper">
        <div className="connect-acc-box">
          <div className="div-block-73">
            <div className="group-horizontal">
              <img
                src="/images/facebook_1facebook.png"
                width="20"
                height="20"
                alt=""
                className="icon-small"
              />
              <h4 className="connect-acc-label">Facebook ads</h4>
            </div>
            {accounts && accounts.isFbAccessible ? (
              <button
                id="btnDisconnectFb"
                className="button connect-saved-btn w-button"
                onClick={() => logoutFbAccount()}
              >
                Logout
              </button>
            ) : (
              <a
                href={`${BASE_URL}session/oauth/fb`}
                className="button connect-saved-btn w-button"
              >
                Connect
              </a>
            )}
          </div>

          {/* {accounts.facebookFallbackAccount === 0 && ( */}
          <React.Fragment>
            <div className="connect-acc-list title-label">
              <div>
                <div className="acc-id">Account ID</div>
              </div>
              <div className="acc-name">Account name</div>
              <div className="acc-currency">Currency</div>
              <div>Users</div>
              <div className="acc-action">Action</div>
            </div>
            {accounts.length > 0 &&
              accounts.map((account) => {
                if (account.currency === currency)
                  return (
                    <div className="connect-acc-list">
                      <div className="acc-id">{account.id}</div>
                      <div className="acc-name">{account.name}</div>
                      <div className="acc-currency">{account.currency}</div>
                      <div className="acc-currency">0</div>
                      {account.account_status === 1 ? (
                        <div className="acc-toggle w-embed">
                          <input
                            className="fbtoggle"
                            name="fbtoggleActive"
                            type="checkbox"
                            id={account.id}
                            checked={isChecked(account.id)}
                            data-currency={account.currency}
                            onChange={() => onToggle(account.id)}
                          />
                          <label className="toggle" for={account.id}>
                            Toggle
                          </label>
                        </div>
                      ) : (
                        <div
                          className="acc-action not-eligible"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Your Ad Account Must Be Active"
                        >
                          Not eligible
                        </div>
                      )}
                    </div>
                  );
              })}
          </React.Fragment>
          {/* )} */}

          {/* {(!accounts ||
            accounts.facebookFallbackAccount === null ||
            accounts.facebookFallbackAccount === undefined) && (
            <div className="connect-acc-list title-label">
              <div>
                There is no Facebook Business Ads Account attached to this user.
                Please create new business account or ask your company or your
                client to add you as an advertiser of their business account
              </div>
            </div>
          )} */}

          {/* {accounts &&
          accounts.facebookFallbackAccount &&
          accounts.facebookFallbackAccount !== 0 ? (
            <div className="connect-acc-list title-label">
              <div>
                You are connected with a Test account. Please disconnect this
                account and connect your facebook account
              </div>
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrepaidScreenFbTable;
