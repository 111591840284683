import axios from "axios";
import { toast } from "react-toastify";
import * as types from "./actionTypes";
import { BASE_URL } from "../../StaticArray/StaticArray";
import {
  onAddPermissionPackage,
  onDeletePermissionPackageById,
  onGetPermissionPackageById,
  onGetPermissionPackages,
  onUpdatePermissionPackageById,
} from "../../utils/network/lib/package";

export const getAllPackagePermissions = () => {
  return (dispatch) =>
    onGetPermissionPackages()
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.GET_ALL_PACKAGE_PERMISSIONS_SUCCSESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const getByPackageId = (packageId) => {
  return (dispatch) =>
    onGetPermissionPackageById(packageId)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.CURRENT_PACKAGE_PERMISSIONS_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const addPermission = ({ name, parameter }) => {
  let addPermissionReqPayload = {
    name,
    parameter,
  };
  return (dispatch) =>
    onAddPermissionPackage(addPermissionReqPayload)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.ADD_NEW_PERMISSION_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const updatePermission = ({ name, parameter }, packageId) => {
  let addPermissionReqPayload = {
    name,
    parameter,
  };
  return (dispatch) =>
    onUpdatePermissionPackageById(packageId, addPermissionReqPayload)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.UPDATE_NEW_PERMISSION_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const deletePermission = (permissionId) => {
  return (dispatch) =>
    onDeletePermissionPackageById(permissionId)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.DELETE_PERMISSION_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};
