import React, { useState } from "react";
import FeaturesSettings from "./FeaturesSettings";
import PackageSettings from "./PackageSettings";
import "./Features.css";

const AdminSidebar = () => {
  const [activeScreen, setActiveScreen] = useState("features");

  return (
    <div className="screen-mainbox">
      <div id="switch_screen">
        <div className="Sidebar-MainWrapper">
          <div className="sidebar features" style={{ left: "0px" }}>
            <ul>
              <li
                className={`list-style-none  ${
                  activeScreen === "features" ? "active" : ""
                }`}
                onClick={() => setActiveScreen("features")}
              >
                <span>Features</span>
              </li>
              <li
                className={`list-style-none  ${
                  activeScreen == "package" ? "active" : ""
                }`}
                onClick={() => setActiveScreen("package")}
              >
                <span>Packages</span>
              </li>
            </ul>
          </div>
          <div className="Right-Wrapper" style={{ padding: "20px" }}>
            {activeScreen === "features" ? (
              <FeaturesSettings />
            ) : (
              <PackageSettings />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
