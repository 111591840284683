
import * as types from "../actions/actionTypes";
const initialState = {
  allPackages: [],
  currentPackages:[],
  addFilter: false
};

export function packageReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_ALL_PACKAGES_SUCCESS:
      return {
        ...state,
        allPackages: payload,
        loading: false
      };
    case types.PACKAGE_LOADING:
      return {
        ...state,
        loading: true,
        addFilter: false
      };
      case types.GET_CURRENT_PACKAGE_SUCCESS:
        return {
          ...state,
          currentPackages: payload
        };
        case types.ADD_NEW_PACKAGE_SUCCESS:
            return {
              ...state,
              allPackages: [...state.allPackages,payload]
        };
        case types.UPDATE_PACKAGE_SUCCESS:
            return {
                ...state,
                currentPackages: [...state.currentPackages,payload]
        };
        case types.DELETE_PACKAGE_SUCCESS:
            return {
                ...state,
                allPackages: state.allPackages.filter(pack => !payload.includes(payload.id.toString()))
        };
      case types.ADD_FILTER_PACKAGE_SUCCESS:
        return {
          ...state,
          addFilter: true,
          data: payload,
          loading: false
        };
        case types.FILTER_PACKAGE:
          return {
              ...state,
              allPackages: state.allPackages.filter(pack => !payload.includes(pack.id.toString()))
      };
    default:
      return state;
  }
}