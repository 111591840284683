import React, { Fragment } from "react";
import SliderContainer from "../../../../common/Slider";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 2,
  initialSlide: 0,
  centerPadding: "20px",
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        dots: true,
        arrows: false,
      },
    },
  ],
};

const Campaign = ({ campaignMetricData, campaignDetailsData }) => {
  const handleStatsData = (data) => {
    const campaignObj =
      campaignDetailsData &&
      campaignDetailsData.campaign &&
      campaignDetailsData.campaign.objective.toLowerCase();

    const platformObj =
      campaignDetailsData &&
      campaignDetailsData.campaign &&
      campaignDetailsData.campaign.platform.toLowerCase();

    if (campaignObj === "impression") {
      return (
        <SliderContainer config={settings}>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Impressions</p>                
              <span className="pointer">
                {data ? data.impression.toFixed(2) : 0}
              </span>
                     
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Total Spent</p>                
              <span className="pointer">
                {data ? data.total_cost.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
                  
            <div className="dash_box_col">
              <p className="box_head">Average CPA</p>                
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.conversions === 0
                    ? 0
                    : (data.total_cost / data.conversions).toFixed(2)
                  : 0}
              </span>
                        
            </div>
          </div>
                    
        </SliderContainer>
      );
    } else if (campaignObj === "click") {
      return (
        <SliderContainer config={settings}>
                      
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Click</p>
              <span className="pointer">
                {data ? data.click.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Impressions</p>
              <span className="pointer">
                {data ? data.impression.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Total Spent</p>
              <span className="pointer">
                {data ? data.total_cost.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPM</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.impression === 0
                    ? 0
                    : (data.total_cost / (data.impression / 1000)).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPC</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.click === 0
                    ? 0
                    : (data.total_cost / data.click).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
        </SliderContainer>
      );
    } else if (campaignObj === "post_engagement") {
      return (
        <SliderContainer config={settings}>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Clicks</p>
              <span className="pointer">
                {data ? data.click.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Impressions</p>
              <span className="pointer">
                {data ? data.impression.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Engagement</p>
              <span className="pointer">
                {data ? data.click_all.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Total Spent</p>
              <span className="pointer">
                {data ? data.total_cost.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPM</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.impression === 0
                    ? 0
                    : (data.total_cost / (data.impression / 1000)).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPC</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 || data.click === 0
                    ? 0
                    : (data.total_cost / data.click).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPE</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 || data.impression === 0
                    ? 0
                    : (data.total_cost / data.click_all).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
        </SliderContainer>
      );
    } else if (campaignObj === "traffic") {
      return (
        <SliderContainer config={settings}>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">
                {platformObj === "messenger" ? "Conversation" : "Traffic"}
              </p>
              <span className="pointer">
                {platformObj === "messenger"
                  ? data
                    ? data.conversations.toFixed(2)
                    : 0
                  : data
                  ? data.traffic.toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Click</p>
              <span className="pointer">
                {data ? data.click.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Impressions</p>
              <span className="pointer">
                {data ? data.impression.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Total Spent</p>
              <span className="pointer">
                {data ? data.total_cost.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPM</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.impression === 0
                    ? 0
                    : (data.total_cost / (data.impression / 1000)).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">
                Average {platformObj === "messenger" ? "CPCN" : "CCPC"}
              </p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.click === 0
                    ? 0
                    : (data.total_cost / data.click).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPT</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.traffic === 0
                    ? 0
                    : (data.total_cost / data.traffic).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
        </SliderContainer>
      );
    } else if (campaignObj === "sales") {
      return (
        <SliderContainer config={settings}>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">
                {platformObj === "messenger" ? "Conversation" : "Conversions"}
              </p>
              <span className="pointer">
                {platformObj === "messenger"
                  ? data
                    ? data.conversations.toFixed(2)
                    : 0
                  : data
                  ? data.conversions.toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Traffic</p>
              <span className="pointer">
                {data ? data.traffic.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Click</p>
              <span className="pointer">
                {data ? data.click.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Impressions</p>
              <span className="pointer">
                {data ? data.impression.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Total Spent</p>
              <span className="pointer">
                {data ? data.total_cost.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPM</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.impression === 0
                    ? 0
                    : (data.total_cost / (data.impression / 1000)).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPC</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.click === 0
                    ? 0
                    : (data.total_cost / data.click).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPT</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.traffic === 0
                    ? 0
                    : (data.total_cost / data.traffic).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPA</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.conversions === 0
                    ? 0
                    : (data.total_cost / data.conversions).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
        </SliderContainer>
      );
    } else {
      return (
        <SliderContainer config={settings}>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Click</p>
              <span className="pointer">
                {data ? data.click.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Impressions</p>
              <span className="pointer">
                {data ? data.impression.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Leads</p>
              <span className="pointer">{data ? data.leads : 0}</span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Total Spent</p>
              <span className="pointer">
                {data ? data.total_cost.toFixed(2) : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPM</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.impression === 0
                    ? 0
                    : (data.total_cost / (data.impression / 1000)).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPC</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.click === 0
                    ? 0
                    : (data.total_cost / data.click).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="parentWrap">
            <div className="dash_box_col">
              <p className="box_head">Average CPL</p>
              <span className="pointer">
                {data
                  ? data.total_cost === 0 && data.leads === 0
                    ? 0
                    : (data.total_cost / data.leads).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
        </SliderContainer>
      );
    }
  };

  const data = campaignMetricData && campaignMetricData.data;
  return <Fragment>{handleStatsData(data)}</Fragment>;
};

export default Campaign;
