import React from "react";
import "./ErrorMessage.css";
export const ErrorMessage = ({ message }) => {
  return (
    <div className="errormessage">
      <span className="errormessage__warning--large">&#9888;</span>
      <p>
        <h3>{message}</h3>
      </p>
    </div>
  );
};
