import React, { Component, Fragment, useEffect, useState } from "react";
import ReactCohortGraph from "prashant-react-cohort-graph";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const CohortChart = ({ data }) => {
  const [chartData, setChartData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setChartData(data);
  }, [data]);

  return (
    <Fragment>
      {chartData && Object.keys(chartData).length > 0 ? (
        <ReactCohortGraph
          shadeColor="#7c4586"
          data={{ days: chartData }}
          showHeaderValues={true}
          headerFormatter={(value, type) => {
            const headerLabel = [
              `${t("cohort_days")}`,
              `${t("cohort_total")}`,
              "New",
              "Reach",
              "Qualified",
              "Disqualified",
              "Negotiating",
              "Won",
              "Lost",
            ];
            // console.log(value);
            // console.log(type);
            if (type == "LABLE") {
              return headerLabel[value.index];
            }
            if (type == "VALUE" && value.index == 0) {
              return "";
            }
            if (type == "VALUE" && value.index == 1) {
              return value.total;
            } else if (type == "VALUE") {
              return value.percent + "%";
            }
            // return [...value];
            return value.total;
          }}
        />
      ) : (
        <>
          <div
            className="d-flex align-items-center justify-content-center "
            style={{
              backgroundImage: `url("/images/dummy_lead_image.png")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              minHeight: "160px",
              height: "90%",
            }}
          >
            <h4>{t("dashboard_noMetricText")}</h4>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default CohortChart;
