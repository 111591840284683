import React from "react";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
const MergeAudienceDialog = ({
  mergeAudienceDialogOpen,
  mergeAudienceImage,
  audienceMergeLoading,
  campaignStep4,
  runAudienceMerge,
  setAudienceMergeDialogOpened,
  setAudienceMergeLoading,
  setMergeAudienceDialogOpen,
  setMergeAudienceImage,
  setTimeoutCount,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={mergeAudienceDialogOpen}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <img
          style={{ width: "100%" }}
          src={mergeAudienceImage}
          alt={t("campaign_imgHere")}
        />
        <Typography
          className="mt-2"
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >
          {campaignStep4 === 0 ? <>{t("campaign_overlapDetected")}</> : <>{t("campaign_overlapFixed")}</>}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          align="center"
          className="mb-4"
        >
          {audienceMergeLoading ? (
            <span className="flex-div" style={{ flexDirection: "column" }}>
              <CircularProgress className="mb-3" />
              {t("campaign_processing")}
            </span>
          ) : campaignStep4 === 0 ? (
            <>
              {t("campaign_audienceOverlapText")}
            </>
          ) : (
            <>{t("campaign_congrats")}</>
          )}
        </DialogContentText>

        {campaignStep4 === 0 ? (
          <>
            <button
              className="brandoverviewleftsection__btn--purple full-width-button"
              color="primary"
              disabled={audienceMergeLoading}
              onClick={() => {
                runAudienceMerge(true);
                setAudienceMergeLoading(true);
              }}
            >
             {t("campaign_fixIt")} 
            </button>

            <button
              type="button"
              className="brandprofilecard__btn--white full-width-button"
              color="primary"
              disabled={audienceMergeLoading}
              onClick={() => {
                runAudienceMerge(false);
              }}
            >
              {t("campaign_noThanks")}
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="brandprofilecard__btn--white full-width-button"
              color="primary"
              onClick={() => {
                setMergeAudienceDialogOpen(false);
                setAudienceMergeDialogOpened(false);
                setMergeAudienceImage("/images/assets/merge-audience.svg");
                setTimeoutCount(20000);
              }}
            >
              {t("campaign_okay")}
            </button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MergeAudienceDialog;
