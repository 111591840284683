import React, { useState, useEffect } from "react";
import SmallErrorText from '../../../common/smallErrorText/SmallErrorText';
import "./EnterAudienceStep.css";

const EnterAudienceStep = ({
  audiences,
  onAudienceChange,
  selectedAudiences,
  setStepNumber,
  onCreatePostClick,
  errors
}) => {
  const [showButtons, setShowButtons] = useState(false);

  const [searchAudience, setSearchAudience] = useState("");

  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    if (searchAudience.length > 0 && audiences.length > 0) {
      let array = [];
      audiences.map(audience => {
        if (audience.name.indexOf(searchAudience) !== -1)
          array = [...array, audience];
      });
      setSearchResults(array);
    }
    else setSearchResults(null);
  }, [searchAudience]);

  const isAudienceActive = id => {
    if (selectedAudiences) {
      const filter = selectedAudiences.filter(audienceId => audienceId === id);
      if (filter.length) return "active";
      else return "";
    }
  };

  return (
    <React.Fragment>
      {!showButtons && (
        <div className="enteraudiencestep__dialog--container">
          <p>
            And finally choose the audience that will be targeted by this post.
          </p>
          <button
            style={{ float: "right" }}
            className="btn btn-primary enterstep__btn"
            onClick={() => {
              // setStepNumber(2)
              setShowButtons(true);
            }}
          >
            Next
          </button>
        </div>
      )}

      <div className="enteraudiencestep__audience--container">
        <span className="enterurlstep__url--question">
          Select audience that will see this post
        </span>
        <p className="createcardstep__span--text">
          You can choose more than one audience
        </p>
          <div style={{ position: "relative" }}>
          <input
            className="form-control"
            type="text"
            onChange={e => setSearchAudience(e.target.value)}
          />
          <i
            className="fas fa-search"
            style={{
              position: "absolute",
              right: "2%",
              top: "12px",
              fontSize: "20px",
              color: "#C4C4C4"
            }}
          />
        </div>
        <p className="createcardstep__span--text">
          Recent audience that are created
        </p>
        {errors && errors.audiences && <SmallErrorText text={errors.audiences} />}
        <div className="enteraudiencestep__audience__list--container">
          {!searchResults &&
            audiences.map(audience => (
              <div
                className={`enteraudiencestep__audience--box ${isAudienceActive(
                  audience.id
                )}`}
              >
                <div
                  onClick={() => onAudienceChange(audience.id)}
                  className="audiencebox__radio--container"
                >
                  <input type="radio" />
                  <span className="checkmark"></span>
                </div>
                <div style={{ width: "70%" }}>
                  <span className="audiencebox__audience--name">
                    {audience.name.substr(0, 20)}
                  </span>
                  <span className="audiencebox__population--size">
                    {audience.population}
                  </span>
                </div>
                <p className="audiencebox__interest">
                  {audience.interest.substr(0, 30)}
                </p>
              </div>
            ))}

          {searchResults &&
            searchResults.map(audience => (
              <div
                className={`enteraudiencestep__audience--box ${isAudienceActive(
                  audience.id
                )}`}
              >
                <div
                  onClick={() => onAudienceChange(audience.id)}
                  className="audiencebox__radio--container"
                >
                  <input type="radio" />
                  <span className="checkmark"></span>
                </div>
                <div style={{ width: "70%" }}>
                  <span className="audiencebox__audience--name">
                    {audience.name.substr(0, 20)}
                  </span>
                  <span className="audiencebox__population--size">
                    {audience.population}
                  </span>
                </div>
                <p className="audiencebox__interest">
                  {audience.interest.substr(0, 30)}
                </p>
              </div>
            ))}
        </div>

        {showButtons && (
          <div className="enteraudiencestep__buttons--container">
            <button
              // onClick={() => setStepNumber(0)}
              onClick={() => setShowButtons(false)}
              className="enteraudiencestep__btn--back"
            >
              Back
            </button>
            <button
              onClick={() => onCreatePostClick()}
              className="btn btn-primary enteraudiencestep__btn--create"
            >
              Create Post
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default EnterAudienceStep;
