import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";
import { VERSION_PREFIX } from "../Constants";


const base =  VERSION_PREFIX
const prefix = base + "/rmf"
const shortBase = VERSION_PREFIX + "/dashboard"

// `api/v1/rmf/products?brand_profile_id=${brand_id}&rmf=${rmf_activeBox_id}`

export function onGetRMFproductListByActiveBox(brand_id,rmf_activeBox_id) {
    console.log(prefix," -> ",VERSION_PREFIX," -> ",prefix + `/products?brand_profile_id=${brand_id}&rmf=${rmf_activeBox_id}`,"llllllllll");
    return PeasyApi.get(prefix + `/products?brand_profile_id=${brand_id}&rmf=${rmf_activeBox_id}`);
}
