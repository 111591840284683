import React, { Component } from "react";
import { toast } from "react-toastify";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";

class CampaignName extends Component {
  constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
    this.submitname = this.submitname.bind(this);
    this.ProcessNext = this.ProcessNext.bind(this);
  }

  saveAndContinue() {
    const { campaign_name } = this.props.values;
    if (campaign_name == "") {
      toast.error(this.props.t("campaign_name"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (campaign_name != "" && campaign_name.length < 10) {
      toast.error(this.props.t("campaign_nameMorethanTenChar"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      if (this.props.funnel_type === 2) {
        return this.props.goForward();
      }
      console.log("objective", this.props.values.objective);
      switch (this.props.values.objective) {
        case 1:
        case 2:
          return this.props.nextStep(2);
          break;
        case 3:
        case 4:
          return this.props.nextStep();
          break;
        case 5:
        case 6:
          return this.props.nextStep(2);
          break;
      }
    }
  }

  submitname(event) {
    let val = event.target.value;
    this.props.handleChange("campaign_name", val);
  }

  ProcessNext(event) {
    if (event.key === "Enter") {
      this.saveAndContinue();
    }
  }

  gobackprevious() {
    if (this.props.funnel_type === 2) {
      this.props.handleChange("funnel_type", 0);
      this.props.handleQuestionNumber(1);
    } else {
      this.props.prevStep();
    }
  }

  render() {
    return (
      <div className="outer_div w-100">
        <div className="col-12 px-0 border-rounded p-3 mb-2" id="step12">
          <div className="form-group brand-custom-form mt-2">
            <label>{this.props.t("campaign_headingCampaignName")}</label>
            <p className="question-description">
            {this.props.t("campaign_nameThatHelpIdentifyCampaign")}
            </p>
            <input
              type="text"
              className="form-control"
              placeholder={this.props.t("campaign_enterCampaign")}
              onKeyPress={this.ProcessNext}
              onChange={this.submitname}
              value={this.props.values.campaign_name}
            />
            <div className="d-block">
              <div
                className="span-text mt-1 d-block"
                style={{ fontSize: "14px" }}
              >{this.props.t("campaign_minTenCharacters")}
                
              </div>
            </div>
          </div>
        </div>

        <div className="navination_bar">
          <div className="bar_search d-flex">
            <div className="above_navigation_box ml-auto">
              <button
                className="brandprofilecard__btn--white"
                onClick={this.gobackprevious}
              >
                {this.props.t("campaign_back")}
              </button>
            </div>
            <div
              className="below_navigation_box"
              style={{ marginRight: "0px" }}
            >
              <button
                className="brandoverviewleftsection__btn--purple"
                onClick={this.saveAndContinue}
              >
                {this.props.t("campaign_next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CampaignName);
