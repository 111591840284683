import React, { Component } from "react";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import "../../../translations/i18n";

class Pixel_event extends Component {
  constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
    this.collapse = this.collapse.bind(this);
    this.submitvalue = this.submitvalue.bind(this);
    this.getLiHtml = this.getLiHtml.bind(this);
    this.messengerEventList = [
      "PURCHASE",
      "ADD_PAYMENT_INFO",
      "ADD_TO_CART",
      "SEARCH",
      "LEAD",
      "INITIATED_CHECKOUT",
      "COMPLETE_REGISTRATION",
      "CONTENT_VIEW",
      "ADD_TO_WISHLIST",
      "CONTACT",
      "CUSTOMIZE_PRODUCT",
      "DONATE",
      "FIND_LOCATION",
      "SCHEDULE",
      "SUBSCRIBE",
      "START_TRIAL",
      "SUBMIT_APPLICATION",
    ];
    this.state = {
      pixel_event_list: this.props.state.pixel_event_list,
      pixel_selected: 0,
      collapse_open: false,
      load_list: false,
    };
  }

  collapse() {
    this.setState({
      collapse_open: !this.state.collapse_open,
    });
  }

  saveAndContinue() {
    const { pixel_event } = this.props.state;
    if (pixel_event == 0) {
      toast.error(this.props.t("campaign_selectPixelEvent"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      this.props.nextStep();
      toast.dismiss();
    }
  }

  gobackprevious() {
    this.props.prevStep();
  }

  submitvalue(event, value, dropdowntext) {
    this.props.handleChange(event, value);
    this.props.handleChange("pixel_event_text", dropdowntext);

    //Close Drop down
    this.setState({
      collapse_open: !this.state.collapse_open,
    });

    //Set value
    this.setState({
      pixel_selected: value,
    });
  }

  getLiHtml(pixel_event_list) {
    var j = 1;
    var that = this;
    return (
      <div className="list_event_list">
        {Object.keys(pixel_event_list).map((k) => {
          return (
            <ul
              key={pixel_event_list[k].pixel_id}
              data-key={pixel_event_list[k].pixel_id}
            >
              <li className="MainClassLi custom-control custom-radio dropdown-item">
                {pixel_event_list[k].name} ( {pixel_event_list[k].pixel_id} )
              </li>
              {Object.keys(pixel_event_list[k].events).map((iner) => {
                if (pixel_event_list[k].events[iner] != undefined && pixel_event_list[k].events[iner].conversion_count > 0) {
                  if (that.props.state.platform === "WEBSITE") {
                    return (
                      <li
                        className="custom-control custom-radio dropdown-item"
                        key={
                          pixel_event_list[k].pixel_id +
                          pixel_event_list[k].events[iner].event_type +
                          pixel_event_list[k].events[iner].conversion_id
                        }
                      >
                        <input
                          type="radio"
                          name="pixel_event"
                          className="custom-control-input"
                          id={
                            pixel_event_list[k].pixel_id +
                            "_" +
                            pixel_event_list[k].events[iner].conversion_id
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={
                            pixel_event_list[k].pixel_id +
                            "_" +
                            pixel_event_list[k].events[iner].conversion_id
                          }
                          onClick={() =>
                            that.submitvalue(
                              "pixel_event",
                              pixel_event_list[k].pixel_id +
                                "|" +
                                pixel_event_list[k].events[iner].event_type +
                                "|" +
                                pixel_event_list[k].events[iner].conversion_id,
                              pixel_event_list[k].events[iner].name
                                .toString()
                                .replace(new RegExp("_", "g"), " ")
                            )
                          }
                        >
                          {pixel_event_list[k].events[iner].name
                            .toString()
                            .replace(new RegExp("_", "g"), " ")}
                        </label>
                      </li>
                    );
                    j++;
                  } else if (that.props.state.platform === "MESSENGER") {
                    return that.messengerEventList.map((eventName) => {
                      if (
                        eventName ===
                        pixel_event_list[k].events[iner].name.toString()
                      ) {
                        return (
                          <li
                            className="custom-control custom-radio dropdown-item"
                            key={
                              pixel_event_list[k].pixel_id +
                              pixel_event_list[k].events[iner].event_type +
                              pixel_event_list[k].events[iner].conversion_id
                            }
                          >
                            <input
                              type="radio"
                              name="pixel_event"
                              className="custom-control-input"
                              id={
                                pixel_event_list[k].pixel_id +
                                "_" +
                                pixel_event_list[k].events[iner].conversion_id
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={
                                pixel_event_list[k].pixel_id +
                                "_" +
                                pixel_event_list[k].events[iner].conversion_id
                              }
                              onClick={() =>
                                that.submitvalue(
                                  "pixel_event",
                                  pixel_event_list[k].pixel_id +
                                    "|" +
                                    pixel_event_list[k].events[iner]
                                      .event_type +
                                    "|" +
                                    pixel_event_list[k].events[iner]
                                      .conversion_id,
                                  pixel_event_list[k].events[iner].name
                                    .toString()
                                    .replace(new RegExp("_", "g"), " ")
                                )
                              }
                            >
                              {pixel_event_list[k].events[iner].name
                                .toString()
                                .replace(new RegExp("_", "g"), " ")}
                            </label>
                          </li>
                        );
                      }
                    });
                  }
                }
              })}
            </ul>
          );
        })}
      </div>
    );
  }

  render() {
    const { pixel_event_list } = this.state;
    const pixelList = pixel_event_list;
    // var pixelList = JSON.parse(pixel_event_list.replace(/&quot;/g,'"'));
    const items = this.getLiHtml(pixelList);

    return (
      <div className="outer_div w-100 animated">
        <div className="col-12 px-0 tab-pane border-rounded p-3 m-2" id="step6">
          <div className="form-group brand-custom-form brand-custom-dropdown mt-2">
            <label>{this.props.t("campaign_selectPixelEvent")} </label>

            <button
              type="button"
              className="form-control brand-dropdown"
              onClick={this.collapse}
            >
              {this.props.state.pixel_event_text == ""
                ?this.props.t("campaign_pixelEventList") 
                : this.props.state.pixel_event_text}
              <i className="fa fa-caret-down float-right"></i>
            </button>
            <div
              id="catefory"
              className={
                this.state.collapse_open == true ? "collapse show" : "collapse"
              }
            >
              <ul className="list-unstyled">{items}</ul>
            </div>
          </div>
        </div>

        <div className="navination_bar">
          <div className="bar_search d-flex">
            <div className="above_navigation_box ml-auto">
              <button
                className="brandprofilecard__btn--white"
                onClick={this.gobackprevious}
              >{this.props.t("campaign_back")}
                
              </button>
            </div>
            <div
              className="below_navigation_box"
              style={{ marginRight: "0px" }}
            >
              <button
                className="brandoverviewleftsection__btn--purple"
                onClick={this.saveAndContinue}
              >{this.props.t("campaign_next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Pixel_event)