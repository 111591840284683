import React, { PureComponent } from "react";

import "./UserTeam.css";
import Sidebar from "../SideBar";
import { userDetails } from "../Axios/ApiRequests";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../../utils/peasy.api";
import { getSubUsers, revokeSubUser, assignQoute } from "../Axios/ApiRequests";
import { SubUserModal } from "./child/SubUserModal";
import { notify } from "../Notification/Notifications";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Button } from "@material-ui/core";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";
import { onUserAdminSwitch } from "../../../utils/network/lib/user";

const url = BASE_URL + "peasy_ravi/public/admin/loginas";

class UserTeam extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sub_users: [],
      loader: false,
      openModal: false,
      qoute: 0,
      setQuota: null,
      user_details: [],
      redirect: "",
    };
    this.userDetails();
  }

  componentDidMount() {
    this.fetchSubUser();
  }

  /**
   * Fethc Users
   */
  async fetchSubUser() {
    this.setState({ loader: true });
    this.userDetails();
    const subUsers = await getSubUsers();
    this.setState({ sub_users: subUsers });
    this.setState({ loader: false });
  }

  /**
   * Revoke Sub User
   */
  async handleRevoke(id) {
    this.setState({ loader: true });
    try {
      const res = await revokeSubUser({ subuser_id: id });
      notify(res, "User revoked successfully.");
      this.fetchSubUser();
    } catch (err) {
      notify(err, "Error Occured.");
      this.setState({ loader: false });
    }
  }

  /**
   * Display price
   */
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /**
   * Open Modal
   */
  onOpenModal() {
    this.setState({ openModal: true });
  }

  /**
   * Close Modal
   */
  onCloseModal() {
    this.setState({ openModal: false });
  }

  /**
   * Show Notification
   */
  showNotification(resp) {
    if (resp.status === 200) {
      notify(resp, "User Invited Successfully.");
      this.fetchSubUser();
    } else {
      notify(resp, "Error Occured!");
    }
  }

  /**
   * Pending Invite to show invite tabs
   */
  getPendingInvite() {
    debugger;
    const { user_details } = this.state;
    console.log(user_details);
    let invites = [];
    const numberPending =
      user_details.maximum_allowed_subuser - user_details.total_active_user;

    if (numberPending === 0)
      return (
        <li key={i} className="mt-2">
          <h4>{this.props.t("campaign_packageDoesNotAllowSubuser")}</h4>
        </li>
      );
    for (var i = 1; i <= numberPending; i++) {
      invites.push(
        <li key={i} onClick={() => this.onOpenModal()}>
          <a href="#">+ {this.props.t("settings_inviteUsers")} </a>
        </li>
      );
    }
    return invites;
  }

  /**
   * Update Qoute on Blur
   */
  async updateQoute(id, e) {
    const { user_details } = this.props;
    if (e.target.value === "") {
      return;
    }

    try {
      const res = await assignQoute({ subuser_id: id, amount: e.target.value });
      notify(res, "Qoute updated successfully.");
      this.setState({ setQuota: null });
      this.fetchSubUser();
    } catch (err) {
      this.setState({ setQuota: null });

      notify(err, err.data.message);
    }
  }

  /**
   * Show Input Qoute Field
   */
  editQuota(i) {
    this.setState({ setQuota: i });
  }

  /**
   * Create Title Case
   */
  toSentenceCase(string) {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence;
  }

  async userDetails() {
    const user_details = await userDetails();
    this.setState({ user_details: user_details });
  }

  async handleClick(id) {
    const payload = new FormData();
    payload.append("user_id", id);
    try {
      const res = await onUserAdminSwitch(payload);
      if (res) {
        console.log(res);
        localStorage.setItem("token_auth", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("user_data", JSON.stringify(res.data));
        localStorage.setItem("fbConnectFlag", true);
        this.setState({ redirect: "/dashboard" });
      }
    } catch (e) {
      toast.error(this.props.t("settings_somethingWentWrong"));
    }
  }

  render() {
    const { sub_users, loader, openModal } = this.state;
    const { user_details } = this.state;

    if (this.state.redirect === "/dashboard") {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      // <Sidebar active="usersTeams" >
      <div className="UserTeamWrapper">
        <h1 className="main-heading">
          {" "}
          {this.props.t("settings_usersAndTeam")}
        </h1>
        <span>
          {this.props.t("settings_totalBalance")}:{" "}
          <strong>
            {user_details.postpaid_quota_allocation}% (
            {user_details.currency_type})
          </strong>
        </span>
        {loader ? (
          <div className="load" style={{ textAlign: "center" }} id="loader-4">
            <span></span>
            <span></span>
            <span></span>
          </div>
        ) : (
          <div>
            <div className="table-responsive">
              <table className="table userTeamTable">
                <tbody>
                  {sub_users &&
                    sub_users.length > 0 &&
                    sub_users.map((usr, i) => (
                      <tr
                        key={i}
                        style={{ minWidth: "500px", overflow: "auto" }}
                      >
                        <td>
                          {" "}
                          {this.props.t("settings_user")}
                          <span className="dark-text d-block">{usr.name}</span>
                        </td>
                        <td>
                          <div className="">
                            {" "}
                            {this.props.t("settings_quota")}
                            (%)
                            <span className="dark-text d-block">
                              <span className="purple-text">
                                <span
                                  className={`purple-text ${
                                    i === this.state.setQuota ? "dNone" : ""
                                  }`}
                                  onClick={this.editQuota.bind(this, i)}
                                >
                                  {usr.postpaid_quota_allocation}
                                </span>
                                {i === this.state.setQuota && (
                                  <input
                                    type="number"
                                    name={`quote${usr.id}`}
                                    onBlur={this.updateQoute.bind(this, usr.id)}
                                    defaultValue={usr.postpaid_quota_allocation}
                                  />
                                )}
                                %
                              </span>
                            </span>
                          </div>
                        </td>
                        <td>
                          {" "}
                          {this.props.t("settings_balanceMonth")}(
                          {user_details.currency_type})
                          <span className="dark-text d-block">
                            {this.numberWithCommas(
                              parseInt(usr.user_balance) -
                                parseInt(usr.user_quota)
                            ) +
                              "/" +
                              this.numberWithCommas(usr.user_quota)}
                          </span>
                        </td>
                        <td>
                          {" "}
                          {this.props.t("settings_status")}
                          <span className="active-text d-block">
                            {this.toSentenceCase(usr.subscription_status)[0]}
                          </span>
                        </td>
                        <td>
                          <div
                            className="btnbox"
                            // style={{ flexWrap: "nowrap" }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "170px",
                            }}
                          >
                            <Button
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={() => this.handleClick(usr.id)}
                            >
                              {" "}
                              {this.props.t("settings_loginAs")}
                            </Button>
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                              className=""
                              onClick={() => this.handleRevoke(usr.id)}
                            >
                              {" "}
                              {this.props.t("settings_revoke")}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="invite-users-box">
              <ul className="btn-list">{this.getPendingInvite()}</ul>
            </div>
          </div>
        )}
        <SubUserModal
          openModal={openModal}
          closeModal={() => this.onCloseModal()}
          showNot={(e) => this.showNotification(e)}
        />
      </div>
      // </Sidebar>
    );
  }
}

export default withTranslation()(UserTeam);
