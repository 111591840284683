import React, { useState, useEffect } from "react";
import "./registration-form.scss";
import FacebookLogin from "react-facebook-login";
import { toast } from "react-toastify";
import MultiStepRegistration from "./MultiStepRegistration";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const RegistrationForm = ({ isLogin, activateFacebookLogin, globalId, isExternalRoute }) => {
  let query = useQuery();
  const queryObj = Object.fromEntries(query);
  const [autoLoad, setAutoLoad] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fbResponse, setFbResponse] = useState({});

  const onFacebookFailure = (resp) => {
    window.$peasy.triggerEvent({
      event_name: "error",
      event_params: { type: "external", id: "1" },
    });
    console.log(resp);
    const args = {
      dataLayer: {
        event_custom: "facebook_login_failed",
        /* can pass more variables here if needed */
      },
    };
    TagManager.dataLayer(args);
    // toast.error(resp.message);
  };

  const onFacebookCallback = async (resp) => {
    setFbResponse(resp);
    setShowRegistration(true);
    const args = {
      dataLayer: {
        event_custom: "facebook_register",
        /* can pass more variables here if needed */
      },
    };
    TagManager.dataLayer(args);
  };

  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    if (activateFacebookLogin && activateFacebookLogin === true) {
      setAutoLoad(true);
    } else {
      setAutoLoad(false);
    }
  }, [activateFacebookLogin]);

  useEffect(() => {
    if (loading === false) {
      setTimeout(() => {
        setKey(Math.random());
      }, 200);
    }
  }, [loading]);

  // Have no idea what is the reason,
  // but we need to re-render button once more to load sdk properly.
  useEffect(() => {
    let freeUser = query.get("tracking_id");
    if (freeUser && freeUser === "fc407180cd6b849d0d93d6c9fab0d029") {
      setAutoLoad(true);
    }
    setLoading(false);
  }, []);

  if (loading) return <></>;

  return (
    <div>
      <FacebookLogin
        cssClass={"facebook-login"}
        key={key}
        cookie={true}
        size="small"
        textButton={`${isLogin ? "Login" : "Signup"} with Facebook`}
        appId="544831322621153"
        autoLoad={autoLoad}
        fields="name,email,picture"
        returnScopes={true}
        scope="ads_management,ads_read,business_management,pages_read_engagement,pages_manage_ads,pages_show_list,pages_manage_metadata,pages_read_user_content,pages_show_list,leads_retrieval,email"
        callback={onFacebookCallback}
        onFailure={onFacebookFailure}
        disableMobileRedirect={true}
        icon="fa-facebook"
      />

      {showRegistration && (
        <MultiStepRegistration
          isVisible={showRegistration}
          hideRegistration={() => setShowRegistration(false)}
          fbResponse={fbResponse}
          globalId={globalId}
          isExternalRoute={isExternalRoute}
        />
      )}
    </div>
  );
};

export default RegistrationForm;
