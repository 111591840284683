import React from "react";
import { Spinner } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

function Graph({ values, type }) {
  const format = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const FunnelData = () => {
    if (type === "CONVERSIONS") {
      var list = [
        // "population",
        "impressions",
        "clicks",
        "landing_page_view",
        "add_to_cart",
        "checkout_initiated",
        "purchase",
      ];
    }
    if (type === "LEAD_GENERATION") {
      var list = [
        // "population",
        "impressions",
        "clicks",
        "open_form",
        "lead",
        "reach",
        "qualified",
        "sales",
      ];
    }
    if (values && list)
      return (
        <div>
          {list.map((d, i) => {
            if (d === "impressions") {
              return (
                <LightTooltip
                  key={i}
                  title={`Impressions : ${format(values[d])}`}
                  placement="right"
                >
                  <p className="graph-text">{format(values[d])}</p>
                </LightTooltip>
              );
            } else if (Number(values[list[i - 1]]) === 0) {
              return (
                <LightTooltip
                  key={i}
                  title={`${capitalize(d).replace("_", " ")} : 0%`}
                  placement="right"
                >
                  <p className="graph-text">0</p>
                </LightTooltip>
              );
            } else {
              return (
                <LightTooltip
                  key={i}
                  title={`${capitalize(d).replace("_", " ")} : ${Math.round(
                    (values[d] * 100) / values[list[i - 1]]
                  )}%`}
                  placement="right"
                >
                  <p className="graph-text">{`${format(values[d])}`}</p>
                </LightTooltip>
              );
            }
          })}
        </div>
      );
    else {
      return (
        <div style={{ textAlign: "center", paddingTop: "50px" }}>
          <Spinner color="dark" />
        </div>
      );
    }
  };
  return (
    <>
      {type === "LEAD_GENERATION" ? (
        <div className="lead-funnel-graph">
          <FunnelData />
        </div>
      ) : (
        <div className="conversion-funnel-graph">
          <FunnelData />
        </div>
      )}
    </>
  );
}

export default Graph;
