import React from "react";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import "./AddPostCard.css";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEffect } from "react";

const AddPostCard = ({
  setIsPopupVisible,
  showSmall,
  audiences,
  carousel,
  handleCarousalPopOver,
}) => {
  const [isOpen, setOpen] = React.useState(false);

  const { t } = useTranslation();

  return (
    <div className="addpostcard_section">
      <span className="addpostcard_heading">
        {t("campaign_letsUploadCreative")}
      </span>
      <div
        className="addpostcard__container"
        style={
          (showSmall
            ? {}
            : {
                height: "40vh",
                marginTop: "5vh",
              },
          { flexDirection: "column" })
        }
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (audiences) {
              carousel ? handleCarousalPopOver() : setIsPopupVisible(true);
            } else return null;
          }}
        >
          <img
            src={`${BASE_URL}images/upload-icon.png`}
            alt="picture"
            className="addpostcard__img"
          />
          <span className="addpostcard__text">{t("campaign_addNewPost")}</span>
        </div>
        <br></br>

        <button
          id="canva-create"
          class="btn btn-primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          {t("campaign_createCanva")}
        </button>
      </div>
      <Modal isOpen={isOpen}>
        <ModalHeader color="primary">Select Type</ModalHeader>
        <ModalBody>
          <Button
            color="primary"
            onClick={() => {
              window.open(
                "https://www.canva.com/design?create&type=TABQqs5Kbyc&category=tADer2V5Ac4&schema=web-2"
              );
            }}
          >
            Instagram - 1:1
          </Button>
          <span style={{ marginLeft: "5px" }}></span>
          <Button
            color="primary"
            onClick={() => {
              window.open(
                "https://www.canva.com/design?create&type=TABQquIHMEM&category=tACZCoDePIo&schema=web-2"
              );
            }}
          >
            Facebook/Google - 1.91:1
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddPostCard;
