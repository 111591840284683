import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllPackageFeatures,
  addFeature,
  deleteFeature,
  updateFeature,
} from "../../../redux/actions/featureAction";
import * as types from "../../../redux/actions/actionTypes";
import FeaturesTableHeader from "./FeaturesTableHeader";
import FeatureAddModal from "./FeatureAddModal";
import DeleteConf from "../../../common/DeleteConfModal";

const FeaturesSettings = () => {
  const dispatch = useDispatch();
  const [features, setFeatures] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [deleteConf, setDeleteConf] = useState(false);

  useEffect(() => {
    dispatch(getAllPackageFeatures()).then((data) => {
      setFeatures(data.payload);
    });
  }, []);

  const toggleAdd = (_) => {
    setIsAdd((isAdd) => !isAdd);
  };
  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleOnchange = (event, id) => {
    const value = event.target.value;
    const name = event.target.name;
    const featuresList = features.map((feature) => {
      if (feature.id === id) return { ...feature, [name]: value };
      return feature;
    });
    setFeatures(featuresList);
  };

  const handleUpdate = (event, id) => {
    const feature = features.filter((feature) => feature.id === id);
    if (feature.length === 0) {
      return;
    }
    dispatch(updateFeature(feature[0]));
  };

  const handleDelete = (toggle) => {
    const selectedFeatures = Object.keys(checkedItems).filter(
      (data) => checkedItems[data] === true
    );
    if (selectedFeatures.length === 0) {
      return;
    } else {
      if (toggle) {
        setDeleteConf(!deleteConf);
      } else {
        selectedFeatures.map((item) => {
          dispatch(deleteFeature(item)).then((res) => {
            if (res.type === types.DELETE_FEATURE_SUCCESS) {
              const featuresList = features.filter(
                (feature) => !selectedFeatures.includes(feature.id.toString())
              );
              setFeatures(featuresList);
            }
          });
        });
        setDeleteConf(!deleteConf);
      }
    }
  };

  const handleAddFeaure = async (e, inputs) => {
    e.preventDefault();
    dispatch(addFeature(inputs)).then((res) => {
      if (res.type === types.ADD_NEW_FEATURE_SUCCESS) {
        setFeatures([...features, res.payload]);
        setIsAdd(false);
      }
    });
  };

  return (
    <div className="wrapper" style={{ padding: "0px" }}>
      <button
        style={{ float: "right", marginBottom: "20px" }}
        className="btn btn-primary"
        onClick={toggleAdd}
      >
        Add
      </button>
      <button
        style={{ float: "right", marginBottom: "20px" }}
        className="btn btn-primary mr-10"
        onClick={() => handleDelete(true)}
      >
        Delete
      </button>

      <table className="tableWrapper">
        <thead>
          <tr>
            <FeaturesTableHeader />
          </tr>
        </thead>
        <tbody>
          {features.map((feature) => {
            return (
              <tr key={feature.id}>
                <td>{feature.id}</td>
                <td>
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={feature.name}
                      onChange={(e) => handleOnchange(e, feature.id)}
                      onBlur={(e) => handleUpdate(e, feature.id)}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    name="path"
                    value={feature.path}
                    onChange={(e) => handleOnchange(e, feature.id)}
                    onBlur={(e) => handleUpdate(e, feature.id)}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={feature.id}
                    checked={checkedItems[feature.id]}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <FeatureAddModal
        isOpen={isAdd}
        toggle={toggleAdd}
        handleAddFeaure={handleAddFeaure}
        className="featureAdd"
      />
      <DeleteConf
        isOpen={deleteConf}
        toggle={() => setDeleteConf(!deleteConf)}
        onConfirm={() => handleDelete(false)}
      />
    </div>
  );
};

export default FeaturesSettings;
