import React, { useEffect, useState } from "react";
import Select from "react-select";
import Logo from "../logo/Logo";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import FbCardDescription from "./fbcardDescription/FbCardDescription";
import PhotoComponent from "../photoComponent/PhotoComponent";
import SmallErrorText from "../../../common/smallErrorText/SmallErrorText";
import PopupAlert from "../../../common/popupAlert/PopupAlert";
import "./FbCard.css";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const FbCard = ({
  card,
  pageDetails,
  deleteFbCard,
  audiences,
  editCardData,
  ctaList,
  dataToSubmit,
  setDataToSubmit,
  editFbCard,
  validationErrors,
  isDisabled,
  editingDisabled,
  setCardData,
  campaignData,
  platform,
  setNotification,
  notification,
}) => {
  const [options, setOptions] = useState([]);

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [isUrlEditable, setIsUrlEditable] = useState(false);

  const [showCtaOptions, setShowCtaOptions] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (audiences && audiences.length > 0) {
      let array = [];
      audiences.map((audience) => {
        const object = {};
        object.value = audience.id;
        object.label = audience.name;
        array = [...array, object];
      });
      setOptions(array);
    }
  }, [audiences]);

  const onChange = (name, data) => {
    editCardData(card.id, name, data);
  };

  const confirmedDelete = () => {
    const finalObject = { ...dataToSubmit };
    delete finalObject[`${card.id}`];
    setDataToSubmit(finalObject);
    deleteFbCard(card.id);
  };

  const showSelectedAudiences = (audienceList) => {
    let array = [];
    if (audiences && audiences.length > 0) {
      if (audienceList && audienceList.length > 0) {
        for (let i = 0; i < audienceList.length; i++) {
          for (let j = 0; j < audiences.length; j++) {
            const object = {};
            if (audienceList[i] === audiences[j].id) {
              object.label = audiences[j].name;
              object.value = audiences[j].id;
              array = [...array, object];
              break;
            }
          }
        }
      }
    }
    return array;
  };

  const changeCtaValue = (cta) => {
    if (cta) {
      let updatedValue = cta.replace("_", " ");
      return updatedValue;
    } else return t("campaign_selectCTA");
  };

  return (
    <React.Fragment>
      {showDeletePopup && (
        <PopupAlert
          heading={t("campaign_areYouSure")}
          body={t("campaign_sureYouWantToDeletePost")}
          onNoClick={setShowDeletePopup}
          onYesClick={confirmedDelete}
        />
      )}

      <div className="fbcard__container">
        <div style={{ display: "flex" }}>
          <Logo
            url={
              pageDetails.page_image ||
              "https://randomuser.me/api/portraits/women/50.jpg"
            }
            style={{ marginTop: "6%", marginLeft: "5%" }}
          />

          <div className="fbcard__heading--container">
            <span className="fbcard__heading--bold">{pageDetails.name}</span>
            <span className="fbcard__issponsored">
              {t("campaign_sponsored")}
            </span>
          </div>
        </div>
        <span className="fbcard__subheading">
          {card.text
            ? card.text.substr(0, 100)
            : card.post.message && card.post.message.substr(0, 100)}
        </span>
        <FbCardDescription text={card.description} />
        <div className="fbcard__photo--container">
          <PhotoComponent
            url={
              card.post
                ? card.post.attachments.data[0].media.image.src
                : card.img
            }
          />
          <div id="fbcard-layover" className="fbcard__layover" />
          <button
            onClick={() => editFbCard(card.id)}
            className="fbcard__btn--edit"
          >
            {t("campaign_editCreative")}
          </button>
          <button
            onClick={() => setShowDeletePopup(true)}
            style={{ top: "56%" }}
            className="fbcard__btn--edit"
          >
            {t("campaign_delPost")}
          </button>{" "}
        </div>

        <div
          style={{
            backgroundColor: "#E9EBED",
            paddingBottom: "53px",
            position: "relative",
          }}
        >
          <span className="fbcard__url">
            <input
              disbled={platform === "MESSENGER"}
              type="text"
              className="imagecard__input--headline"
              placeholder={t("campaign_enterURL")}
              value={
                dataToSubmit[card.id]
                  ? dataToSubmit[card.id].url || campaignData.promoted_url
                  : campaignData.promoted_url
              }
              onChange={(e) => onChange("url", e.target.value)}
              style={{ backgroundColor: "#E9EBED" }}
            />
          </span>

          <div className="imagecard__btn--cta--container">
            <button
              className="imagecard__btn--cta"
              onClick={() => {
                if (showCtaOptions) {
                  setShowCtaOptions(false);
                  // changeMainDataOnBlur();
                } else setShowCtaOptions(true);
              }}
            >
              {dataToSubmit[card.id]
                ? changeCtaValue(dataToSubmit[card.id].cta)
                : t("campaign_selectCTA")}
            </button>

            {showCtaOptions && (
              <div>
                <div className="fbcard__ctaoptions--container">
                  {ctaList &&
                    ctaList.map((cta) => (
                      <span
                        onClick={(e) => {
                          onChange("cta", cta.value);
                          setShowCtaOptions(false);
                        }}
                      >
                        {cta.label}
                      </span>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* {card.call_to_action && (
          <span className="fbcard__url">
            {card.call_to_action.value.link.toUpperCase()}
          </span>
        )}
        {card.call_to_action && (
          <button
            data-toggle="collapse"
            data-target="#ctaOptions"
            aria-expanded="false"
            aria-controls="collapseExample"
            className="fbcard__btn--cta"
          >
            {card.call_to_action.type}
          </button>
        )} */}
        {/* <div className="collapse" id="ctaOptions">
          <div className="card card-body fbcard__ctaoptions--container">
            {ctaList &&
              Object.keys(ctaList).map(cta => (
                <span
                  onClick={e =>
                    setCardData(data => ({ ...data, cta: e.target.value }))
                  }
                  value={ctaList[cta]}
                >
                  {cta}
                </span>
              ))}
          </div>
        </div> */}
        {/* {card.attachments &&
            card.attachments.data[0].media_type === "video" && (
              <VideoComponent url={card.attachments.data[0].media.source} />
            )} */}
        {/* <div
          style={{
            backgroundColor: "#E8EAEE",
            width: "80%",
            overflow: "auto",
            marginLeft: "10%"
          }}
        >
          <select
            className="fbcard__btn--send-message"
            onChange={e => onChange("cta", e.target.value)}
            value={
              dataToSubmit[card.id] ? dataToSubmit[card.id].cta || null : null
            }
          >
            <option selected disabled>
              Select CTA
            </option>
            {ctaList &&
              Object.keys(ctaList).map(cta => (
                <option value={ctaList[cta]}>{cta}</option>
              ))}
          </select>
        </div>
        {validationErrors[card.id] && validationErrors[card.id].cta && (
          <SmallErrorText
            style={{ marginLeft: "47%" }}
            text={validationErrors[card.id].cta}
          />
        )} */}
        {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
              marginLeft: "10%",
              marginTop: "10px",
              fontSize: "13px",
              color: "#656B73"
            }}
          >
            <span>
              <i
                className="far fa-thumbs-up"
                style={{ fontSize: "20px", color: "cornflowerblue" }}
              />
              {"   "}
              {card.likes}
            </span>
            <span
              style={{
                marginLeft: "32%"
              }}
            >
              {card.comments}
              {"   "} Comments
            </span>
            <span>
              {card.shares || 0}
              {"   "} Shares
            </span>
          </div> */}
        {/* <div
          style={{
            marginTop: "10px",
            marginLeft: "10%",
            width: "80%"
          }}
        >
          <span>Url: </span>
          <input
            type="text"
            className="fbcard__url--input"
            value={
              dataToSubmit[card.id] ? dataToSubmit[card.id].url || null : null
            }
            onChange={e => onChange("url", e.target.value)}
          />
        </div>
        {validationErrors[card.id] && validationErrors[card.id].url && (
          <SmallErrorText
            style={{ marginLeft: "10%" }}
            text={validationErrors[card.id].url}
          />
        )} */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "94%",
            marginLeft: "3%",
            marginTop: "10px",
            fontSize: "13px",
            color: "rgb(101, 107, 115)",
            marginTop: "20px",
            fontWeight: "bold",
            fontSize: "10px",
            marginBottom: "5px",
          }}
        >
          <span>
            <img
              style={{ marginTop: "-5px" }}
              src={`${BASE_URL}images/thumb-up.png`}
              alt=""
            />
            {"   "}
            {card.likes}
          </span>
          <span
            style={{
              marginLeft: "50%",
            }}
          >
            {card.comments}
            {"   "} {t("campaign_comments")}
          </span>
          <span
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "100%",
              backgroundColor: "#000",
              display: "inline-block",
              marginTop: "5px",
            }}
          />

          <span>
            {card.shares || 0}
            {"   "}
            {t("campaign_shares")} Shares
          </span>
        </div>

        <div className="fbcard__likes--container">
          <span>
            <img
              src={`${BASE_URL}images/like.png`}
              className="fbcard__img--like"
              alt=""
            />
            {t("campaign_like")}
          </span>
          <span>
            <img
              src={`${BASE_URL}images/comment.png`}
              className="fbcard__img--like"
              alt=""
            />
            {t("campaign_comment")}
          </span>
          <span>
            <img
              src={`${BASE_URL}images/share.png`}
              className="fbcard__img--like"
              alt=""
            />
            {t("campaign_share")}
          </span>
        </div>
        <div
          style={{
            paddingTop: "13px",
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <span
            style={{ marginTop: "18px", marginLeft: "5%", fontWeight: "bold" }}
          >
            {t("campaign_targeting")}:{" "}
          </span>

          <Select
            options={options}
            value={
              dataToSubmit[card.id]
                ? showSelectedAudiences(dataToSubmit[card.id].audiences) || null
                : null
            }
            noOptionsMessage={() => <span>{t("campaign_noAudience")}</span>}
            isMulti
            className="fbcard__audience--selector"
            placeholder={t("campaign_audience")}
            onChange={(e) => {
              setTimeout(() => {
                let ids = [];
                if (e) e.map((audience) => (ids = [...ids, audience.value]));
                onChange("audiences", ids);
              }, 10);
            }}
          />

          {validationErrors[card.id] && validationErrors[card.id].audiences && (
            <SmallErrorText
              style={{ marginLeft: "5%" }}
              text={validationErrors[card.id].audiences}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FbCard;
