import React from "react";
import Select from "react-select";

const RetriveHeader = ({ handleChange, selectedOption, options }) => (
  <div className="analys_top">
    <div className="cstm_container">
      <div className="analys_row">
        <h2>SWO Analysis</h2>
        <div className="drop_row width100">
          <div className="drop_col">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RetriveHeader;
