import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SmallErrorText from "../../../common/smallErrorText/SmallErrorText";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
// import { Dialog } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import PeasyApi from "../../../utils/peasy.api";
import "./APIKey.css";

import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  onGetApiKey,
  onGetIntergration,
  onPostApiKey,
  onPostIntegration,
} from "../../../utils/network/lib/misc";
import { onGetBrandProfileList } from "../../../utils/network/lib/brand";

const APIKey = () => {
  const { t } = useTranslation();

  const [brand, setBrand] = useState("");
  const [brandProfiles, setBrandProfiles] = useState([]);
  const [apiKeyList, setApiKeyList] = useState([]);
  const [businessProfiles, setBusinessProfiles] = useState([]);
  const [activeAPIKey, setActiveAPIKey] = useState();
  const [isIntegrationLoading, setIsIntergationLoading] = useState(true);
  const [allIntegration, setAllIntegration] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    api_key: "",
    webhook_url: "",
    type: 1,
  });

  const user = useSelector((state) => state.auth.user);
  

  const useStyles = makeStyles((theme) => ({
    formControl: {
      marginTop: theme.spacing(2),
      //   marginBottom: theme.spacing(1),
      // minWidth: 360,
      textAlign: "left",
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      "& > *": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        // width: "25ch",
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchAPIKey();
    fetchIntegrationAPIKeyList();
    fetchBussinessProfiles();
  }, []);

  const fetchBussinessProfiles = async () => {
    try {
      const resp = await onGetBrandProfileList();
      setBrandProfiles(resp);
    } catch (e) {
      console.log(e);
    }
  };
  const lookup = {
    type: { 1: "Peasy Sales", 2: "Others" },
    enabled: { 0: "Disabled", 1: "Enabled" },
  };

  const handleCopy2Clipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success(t("settings_copied"));
    } catch (e) {
      console.error(e);
      toast.error(t("settings_smthngWentWrong"));
    }
  };

  const renderAPIKey = (key) => {
    return (
      <strong
        className="api-key-container"
        onClick={(e) => {
          e.preventDefault();
          handleCopy2Clipboard(key);
        }}
      >
        <code>{key}</code>
      </strong>
    );
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchAPIKey = async () => {
    try {
      const response = await onGetApiKey();

      if (!(response.data && response.data[0] && response.data[0].key)) {
        throw Error("keyIsNotPresent");
      }

      //   setActiveAPIKey(response.data[0].key);
      setApiKeyList(response.data);
      // console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchIntegrationAPIKeyList = async () => {
    setIsIntergationLoading(true);
    try {
      const response = await onGetIntergration();

      setAllIntegration(response.data);
    } catch (e) {
      console.error(e);
      setAllIntegration([]);
    }
    setIsIntergationLoading(false);
  };

  const handleGenerateAPIKeyClick = async () => {
    // e.preventDefault();

    try {
      const response = await onPostApiKey({
        brand_profile_id: brand,
      });

      if (response) {
        // console.log(response);
      }
      if (!(response.data && response.data[0] && response.data[0].key)) {
        throw Error("keyIsNotPresent");
      }
      fetchAPIKey();
      setOpenModal2(false);

      //   setActiveAPIKey(response.data[0].key);
      toast.success(t("settings_APIKeyGenerated"));
    } catch (e) {
      console.error(e);
      toast.error(t("settings_smthngWentWrong"));
    }
  };

  const handleOnSubmit = async () => {
    try {
      const response = onPostIntegration({
        ...formData,
        brand_profile_id: brand,
      });
      toast.success(t("settings_addedAPIInList"));
      setOpenModal(false);
      fetchIntegrationAPIKeyList();
    } catch (e) {
      console.error(e);
      toast.error(t("settings_smthngWentWrong"));
    }
  };

  return (
    <div className="ApiKeyWrapper">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="">{t("settings_businessAPIKey")}</h2>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          // className="brandprofilecard__btn--white mb-1"
          onClick={(e) => {
            e.preventDefault();
            if (brandProfiles.length > 0) {
              setOpenModal2(true);
            } else {
              toast.error(t("settings_noBusinessProfile"));
            }
          }}
        >
          {t("settings_addNew")}
        </Button>
      </div>
      <hr />
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "50%" }}>{t("settings_ApiKey")}</th>
              <th style={{ width: "25%" }}>
                {t("settings_businessProfileName")}
              </th>
            </tr>
          </thead>
          <tbody>
            {apiKeyList.length > 0 &&
              apiKeyList.map((api) => (
                <tr>
                  <td>
                    <strong className="api-container">
                      <code>{api.key}</code>
                    </strong>
                  </td>
                  <td>{api && api.brand && api.brand.brand_name}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <hr style={{ marginBottom: "50px" }} />
      <div
        className="mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2 className="">{t("settings_integratns")}</h2>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            if (brandProfiles.length > 0) {
              setOpenModal(true);
            } else {
              toast.error(t("settings_noBusinessProfile"));
            }
          }}
        >
          {t("settings_addNew")}
        </Button>
      </div>

      <hr />
      {isIntegrationLoading ? (
        <div className="load" style={{ textAlign: "center" }} id="loader-4">
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "15" }}>{t("settings_integratn")}</th>
                <th style={{ width: "15" }}>{t("settings_type")}</th>
                <th style={{ width: "20" }}>{t("settings_apiKey")}</th>
                <th style={{ width: "15" }}>{t("settings_webhook")}</th>
                <th style={{ width: "15" }}>
                  {t("settings_businessProfileName")}
                </th>
              </tr>
            </thead>
            <tbody>
              {!allIntegration.length ? (
                <tr>
                  <td colSpan="5">{t("settings_noIntegratnAvail")}</td>
                </tr>
              ) : (
                allIntegration.map((integration, i) => (
                  <tr>
                    <td>{integration.name}</td>
                    <td>{lookup.type[integration.type]}</td>
                    <td>{renderAPIKey(integration.api_key)}</td>
                    <td>{integration.webhook_url}</td>
                    <td>
                      {integration &&
                        integration.brand &&
                        integration.brand.brand_name}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {t("settings_addIntegratn")}
        </DialogTitle>
        <DialogContent>
          <form className={classes.root}>
            <TextField
              label={t("settings_integratnName")}
              fullWidth={true}
              variant="outlined"
              type="text"
              name="name"
              onChange={handleInputChange}
              value={formData.name}
            />

            <TextField
              label={t("settings_apiKey")}
              fullWidth={true}
              variant="outlined"
              type="text"
              name="api_key"
              onChange={handleInputChange}
              value={formData.api_key}
            />

            <TextField
              label={t("settings_webhookUrl")}
              fullWidth={true}
              variant="outlined"
              type="text"
              name="webhook_url"
              onChange={handleInputChange}
              value={formData.webhook_url}
            />
            {"" && <SmallErrorText text={""} />}

            <FormControl
              variant="outlined"
              className={classes.formControl}
              width="100%"
            >
              <InputLabel htmlFor="Type">{t("settings_type")}</InputLabel>
              <Select
                autoWidth={true}
                labelId="Type"
                value={formData.type}
                onChange={handleInputChange}
                label={t("settings_type")}
                name="type"
              >
                <MenuItem className="pl-2 dropdown-option" value="1">
                  {t("settings_peasysales")}
                </MenuItem>
                {user.active_package?.options?.integration === "true" && (
                  <MenuItem className="pl-2 dropdown-option" value="2">
                    {t("settings_others")}
                  </MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              className={classes.formControl}
              width="100%"
            >
              <InputLabel htmlFor="profiles">
                {t("settings_profiles")}
              </InputLabel>
              <Select
                autoWidth={true}
                labelId="profiles"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                label={t("settings_status")}
              >
                {brandProfiles &&
                  brandProfiles.map((brandProfile) => (
                    <MenuItem
                      className="pl-2 dropdown-option"
                      value={brandProfile.id}
                    >
                      {brandProfile.brand_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </form>
          <button
            className="brandoverviewleftsection__btn--purple"
            onClick={() => {
              handleOnSubmit();
              setOpenModal(false);
            }}
            color="primary"
            style={{
              display: "block",
              margin: "auto",
              width: "100%",
              marginBottom: "10px",
              marginTop: "15px",
            }}
          >
            {t("settings_submit")}
          </button>
          <button
            className="brandprofilecard__btn--white"
            onClick={() => setOpenModal(false)}
            color="primary"
            style={{
              display: "block",
              margin: "auto",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            {t("settings_cancel")}
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      {/* ******************************************************************** */}
      {/* ******************************************************************** */}
      <Dialog
        open={openModal2}
        onClose={() => setOpenModal2(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {t("settings_selectBusinessProfile")}
        </DialogTitle>
        <DialogContent>
          <form className="add-integration-form">
            <div className="form_body">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
              >
                <InputLabel htmlFor="profiles">
                  {t("settings_profiles")}
                </InputLabel>
                <Select
                  autoFocus={false}
                  autoWidth={true}
                  labelId="profiles"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  label={t("settings_profiles")}
                >
                  {brandProfiles &&
                    brandProfiles.map((brandProfile) => (
                      <MenuItem
                        className="pl-2 dropdown-option"
                        value={brandProfile.id}
                      >
                        {brandProfile.brand_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </form>
          <button
            className="brandoverviewleftsection__btn--purple"
            onClick={() => {
              if (brand) {
                handleGenerateAPIKeyClick();
                setOpenModal2(false);
              } else {
                toast.error(t("settings_selectProfileFirst"));
              }
            }}
            color="primary"
            style={{
              display: "block",
              margin: "auto",
              width: "100%",
              marginBottom: "10px",
              marginTop: "15px",
            }}
          >
            {t("settings_generate")}
          </button>
          <button
            className="brandprofilecard__btn--white"
            onClick={() => setOpenModal2(false)}
            color="primary"
            style={{
              display: "block",
              margin: "auto",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            {t("settings_cancel")}
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default APIKey;
