import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Error = (message, elementId) => {
  var element = document.querySelector(`#${elementId}`);
  element.focus();
  return toast.error(message);
};

export default Error;
