import React, { Component } from "react";
import QualificationDropdown from "../QualificationDropdown/QualificationDropdown";
import RightViewMoreModal from "../RightViewMoreModal/RightViewMoreModal";
import EditAndDeleteComponent from "../EditAndDeleteComponent/EditAndDeleteComponent";
import http from "../../../../shared/http-request";
import { BASE_URL } from "../../../../StaticArray/StaticArray";
import { QUALIFICATION_FILTERS } from "../../clientConstants/Constant";
import Shimmer from "../../../../common/shimmer/Shimmer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  onLeadSavedClicked,
  onQualificationChange,
} from "../../../../utils/network/lib/lead";
export default class LeadsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditOn: false,
      email: this.props.data.email,
      loadingState: false,
      data: this.props.data,
    };
  }

  onInputChangeHandler = (e) => {
    console.log(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  renderInputFields = (input_name) => {
    if (this.state.isEditOn) {
      return (
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name={input_name}
            value={this.state[input_name]}
            onChange={this.onInputChangeHandler}
          />
        </div>
      );
    } else {
      return this.props.data[input_name] ? this.props.data[input_name] : "NA";
    }
  };

  onRenderViewUpdate = (data) => {
    this.setState({ data });
  };

  onQualificationChangeHandler = (id, data) => {
    this.setState({ loadingState: true });

    // this.props.updateQualificationChangeHandler(id, data);
    onQualificationChange(id, { qualification: data })
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          this.setState({ data: res.data.data, loadingState: false });
          toast.success("Updated Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          this.setState({ loadingState: false });
          toast.error("Something Went wrong while updating", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        // this.loadTable(this.brand_profile_id);
      })
      .catch((err) => {
        this.setState({ date: new Date(), loadingState: false });
        toast.error("Something Went wrong while updating", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        console.log("Error occured for ", err);
      });
  };

  renderViewMoreLink = (data) => {
    return (
      <RightViewMoreModal
        data={data}
        renderQualification={this.renderQualification}
        onQualificationChangeHandler={this.onRenderViewUpdate}
        onApplyEdit={this.onApplyEdit}
      />
    );
  };

  onApplyEdit = (data) => {
    this.props.onApplyEdit(data);
  };

  deleteValue = (id) => {
    this.props.deleteValue(id);
  };

  isEditOn = () => {
    this.setState({ isEditOn: true });
  };

  isEditOff = () => {
    this.setState({ isEditOn: false });
  };

  savedClicked = () => {
    this.isEditOff();
    console.log(this.state);
    const { email } = this.state;

    let data = {
      email: email,
    };
    onLeadSavedClicked(this.props.data.id, data)
      .then((res) => {
        this.props.onApplyEdit(res.data.data);
      })
      .catch((err) => {
        console.log("Error occured for ", err);
      });
  };

  renderActionsLink(data) {
    return (
      <EditAndDeleteComponent
        data={data}
        deleteValue={this.deleteValue}
        savedClicked={this.savedClicked}
        isEditOn={this.isEditOn}
        isEditOff={this.isEditOff}
      />
    );
  }
  renderQualification(data) {
    let renderObj = QUALIFICATION_FILTERS.filter((filter) => {
      return filter.value == data;
    });

    return renderObj.length > 0 ? <span>{renderObj[0].label}</span> : "";
  }

  render() {
    const { data } = this.state;
    let style = {
      width: "100%",
    };
    return (
      <tr key={data.id}>
        {/* <td>{data.name}</td>
                <td className="clientlistingtable_table_td">{data.phone}</td> */}
        <td className="clientlistingtable_table_td clientlistingtable_table_td_fixed">
          {this.renderInputFields("email")}
        </td>
        <td className="clientlistingtable_table_td clientlistingtable_table_td_fixed">
          {data.phone && data.phone !== "" ? data.phone : "NA"}
        </td>
        <td className="clientlistingtable_table_td">{data.predicted_score}</td>
        <td className="clientlistingtable_table_td">
          {this.state.loadingState && <Shimmer style={style} />}
          {/* {!this.state.loadingState &&
            this.renderQualification(data.qualification)} */}
          {!this.state.loadingState && (
            <QualificationDropdown
              id={data.id}
              qualification={data.qualification}
              onChangeHandler={this.onQualificationChangeHandler}
            />
          )}
        </td>
        <td className="clientlistingtable_table_td">{data.campaign_name}</td>
        <td className="clientlistingtable_table_td">{data.created_at}</td>
        <td className="clientlistingtable_table_td">
          {this.renderViewMoreLink(data)}
        </td>
        {/* <td className="clientlistingtable_table_td">{this.renderActionsLink(data)}</td> */}
      </tr>
    );
  }
}
