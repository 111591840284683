import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from '../../StaticArray/StaticArray';
import http from '../../shared/http-request';


export const SaveCampaign = (values, RefreshToken, CheckObjective, handleChange, GetPredictionResponse, handleErrorLoading, history) => {


  let token = "";
  try {
    token = values.api_token;
  } catch (err) {
    RefreshToken();
    SaveCampaign();
  }



  let config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };

  var allValues = JSON.stringify(values);
  let bodyParameters = {
    value: allValues,
    objective: CheckObjective(),
    location: Object.keys(values.locationSelectd)
  };

  http
    .post(BASE_URL + "api/save-campaign", bodyParameters, config)
    .then(response => {
      if (response.data.flag == 1) {
          createDefaultCampaign(values, response.data.campaign_id, handleErrorLoading, history);
        }
       else {
        toast.error(response.data.msg, {
          position: toast.POSITION.BOTTOM_LEFT
        })
        handleErrorLoading()
      }
    })

    .catch(err => {
      toast.error(err.response.data.msg, {
        position: toast.POSITION.BOTTOM_LEFT
      })
      handleErrorLoading()
    });
}


export const createDefaultCampaign = (values, campaign_id, handleErrorLoading, history) => {

  http
    .post(`${BASE_URL}api/create-default-campaign`, {
      campaign_id: campaign_id,
    })
    .then(res => {
      if (res.data.status) {
        return history.push(`/campaign/edit/${campaign_id}`);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT
        })
        handleErrorLoading()
      }
    })
    .catch(err => {
      console.log('inside catch', err.response)
      toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      })
      handleErrorLoading()
    });
}
