import React from "react";
import validator from "validator";
import { toast } from "react-toastify";
import { Box, Hidden, Typography } from "@material-ui/core";
import TagManager from "react-gtm-module";
import { Rotate90DegreesCcwTwoTone } from "@material-ui/icons";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const args = {
  dataLayer: {
    event_custom: "brand_profile_create_website_iput",
    /* can pass more variables here if needed */
  },
};

const CreateBrandWebsite = ({
  value,
  onChange,
  nextPageOnEnter,
  prevStep,
  step,
}) => {
  TagManager.dataLayer(args);
  const { t } = useTranslation();

  const checkEnter = (e) => {
    if (e.keyCode === 13) {
      validate();
    }
  };

  const validate = () => {
    if (value.includes("facebook.com")) {
      return toast.error(t("brand_notValid"));
    }
    if (value.length < 9) {
      return toast.error(t("brand_entrUrl"));
    }
    if (!validator.isURL(value)) {
      return toast.error(t("brand_entrValidUrl"));
    }
    return nextPageOnEnter();
  };

  return (
    <>
      <Box className="createBrand-box">
        <div
          // style={{ minHeight: "60vh" }}
          className="tab-pane col-12 px-0 active"
        >
          <div className="form-group brand-custom-form mt-4">
            <label>
              {" "}
              {t("brand_webUrl")}
              <Hidden smDown>
                <a
                  href="javascript:void(0);"
                  data-toggle="tooltip"
                  title="Specify URL!"
                >
                  <i className="fa fa-info-circle" />
                </a>
              </Hidden>
            </label>
            <p className="question-description">
              {t("brand_questionDescriptionWebsite")}
            </p>
            <input
              autoComplete="off"
              type="text"
              name="brand_website"
              value={value || "http://"}
              className="form-control"
              placeholder="http://"
              onChange={(e) => onChange("brand_website", e.target.value)}
              onKeyDown={(e) => checkEnter(e)}
            />
          </div>
          <div className="d-block">
            <div className="span-text mt-3 d-block">
              {t("brand_enterContinue")}
            </div>
          </div>
        </div>
      </Box>

      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              type="button"
              onClick={() => prevStep()}
              className="brandprofilecard__btn--white"
            >
              {t("brand_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              type="button"
              className="brandoverviewleftsection__btn--purple"
              onClick={() => validate()}
            >
              {t("brand_next")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBrandWebsite;
