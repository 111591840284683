import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";
import axios from "axios";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/feature"
const shortBase = VERSION_PREFIX + "/feature"

export function onGetAllPackageFeatures() {
    return axios.get(prefix + '/')
}

export function onGetFeatureById(featureId) {
    return axios.get(prefix + '/' + featureId)
}


export function onAddFeature(payload) {
    return axios.post(prefix, payload)
}

export function onUpdateFeature(id, payload) {
    return axios.put(prefix + '/' + id, payload)
}


export function onDeleteFeature(id) {
    return axios.delete(prefix + '/' + id)
}
