import React from "react";
import PermissionsHoc from "../../../utils/PermissionsHoc";
import {Button} from "../../../utils/Button";

const Feature = ({featureId,featureName,permissions}) => {
    console.log('feature permissions',permissions)
    const onSubmit = async ()=> {
        console.log('Handle featureClick')
      };
  return (
    <React.Fragment>
        <Button
        type="button"
        color="secondary"
        onClick={onSubmit}
        permission={permissions.permission}
        >
          {featureName}
        </Button> 
    </React.Fragment>
  );
};

export default PermissionsHoc(Feature)

