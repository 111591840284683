import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  withRouter,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import PeasyApi from "../../utils/peasy.api";
import { Spinner } from "reactstrap";
import CustomAudienceTable from "./CustomAudienceTable/CustomAudienceTable";
import CustomAudienceBuilder from "./CustomAudienceBuilder/CustomAudienceBuilder";
import { onGetCustomAudience } from "../../utils/network/lib/audience";

const CustomAudience = ({}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let { brandId } = useParams();
  let { path } = useRouteMatch();

  const [action, setAction] = useState({
    type: "",
    audienceId: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const onAction = (type, audienceId = "") => {
    setAction({
      type,
      audienceId,
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await onGetCustomAudience(brandId);
      setData(resp);
    } catch (e) {
      toast.error("Failed to get custom audience.");
    }
    setLoading(false);
  };

  const getRuleByAudienceId = (audienceId) => {
    let customAudience = null;
    if (audienceId) {
      customAudience = data.custom_audiences.find(
        (custom_audience) => custom_audience.audience_id == audienceId
      );
    }
    return customAudience.rule;
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <Spinner color="dark" />
      </div>
    );
  }

  if (action.type === "") {
    return (
      <Container>
        <CustomAudienceTable
          data={data}
          fetchData={fetchData}
          brandId={brandId}
          onAction={onAction}
        />
      </Container>
    );
  } else {
    return (
      <Container>
        <CustomAudienceBuilder
          getRule={getRuleByAudienceId}
          fetchData={fetchData}
          brandId={brandId}
          action={action}
          onAction={onAction}
        />
      </Container>
    );
  }

  // return (
  //   <Router>
  //     <Container>
  //       <Switch>
  //         <Route path={`${path}`}>
  //           <CustomAudienceTable
  //             data={data}
  //             fetchData={fetchData}
  //             brandId={brandId}
  //             onAction={onAction}
  //           />
  //         </Route>
  //         <Route path={`${path}/:type/:id?`}>
  //           <CustomAudienceBuilder
  //             getRule={getRuleByAudienceId}
  //             fetchData={fetchData}
  //             brandId={brandId}
  //           />
  //         </Route>
  //         <Route path={`${path}`}>
  //           <Redirect to={`${path}`} />
  //         </Route>
  //       </Switch>
  //     </Container>
  //   </Router>
  // );
};

export default withRouter(CustomAudience);
