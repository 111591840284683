export const QUALIFICATION_FILTERS = [
  {
    label: "New",
    value: 10,
  },
  {
    label: "Reach",
    value: 20,
  },
  {
    label: "Negotiating",
    value: 35,
  },
  {
    label: "Qualified",
    value: 30,
  },
  {
    label: "Disqualified",
    value: 25,
  },
  {
    label: "Won",
    value: 60,
  },
  {
    label: "Lost",
    value: 70,
  },
];
