import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import axios from "axios";
import PeasyApi from "../../peasy.api";

const base = BASE_URL + VERSION_PREFIX;
const shortBase = VERSION_PREFIX;

export function onFileUpload(payload) {
  return http.post(base + "/file/upload", payload);
}

export function onImageUpload(payload) {
  return http.post(base + "/image/upload", payload);
}

export function onVideoUpload(payload) {
  return http.post(base + "/video/upload", payload);
}

export function onGetIntergration() {
  return http.get(base + "/integration");
}
export function onPostIntegration(payload) {
  return http.post(base + "/integration", payload);
}

export function onGetApiKey() {
  return http.get(base + "/apiKey");
}

export function onPostApiKey(payload) {
  return http.post(base + "/apiKey", payload);
}

//facts
export function onFactCreate(payload) {
  return PeasyApi.post(shortBase + "/fact/create", payload);
}

export function onReadFacts(brandId) {
  return PeasyApi.get(shortBase + "/facts/read/" + brandId);
}

//rule
export function onPostRule(id, payload) {
  return PeasyApi.post(shortBase + "/updaterule/" + id, payload);
}
export function onAddRule(payload) {
  return PeasyApi.post(shortBase + "/addrule", payload);
}

export function onGetPageDetails(payload) {
  return http.get(base + "/page-details", payload);
}

export function onStripePayment(payload) {
  return http.post(base + "/stripe/payment", payload);
}

export function getStripePackages() {
  console.log("base", base);
  return http.get(base + "/stripe/peasy/billings/");
}

export function createStripeSession(payload) {
  return http.post(base + "/stripe/subscribe", payload);
}

export function onStripeUnsubscribe(payload) {
  return http.post(base + "/stripe/user-subscription/unsubscribe", payload);
}

export function getCurrentPriceId() {
  return http.get(base + "/stripe/current_price_id");
}

export function switchStripePlan(payload) {
  return http.post(base + "/stripe/user-subscription/switch", payload);
}

export function onCheckLeadTOS(payload) {
  return PeasyApi.post(shortBase + "/checkLeadTermsOfService", payload);
}

export function onGetFormQuestions(id) {
  return http.get(base + "/getformquestions?lead_form_id=" + id);
}

export function onGetDefaultPackage() {
  return axios.get(base + "/defaultPackage");
}

export function onPostFbAdAccounts(payload) {
  return axios.post(base + "/getFbAdAccounts", payload);
}

export function onUpsertLeadMap(payload) {
  return http.post(base + "/upsertleadmap", payload);
}

export function onAddUserFbAccount(payload) {
  return axios.post(base + "/add/userFbAccount", payload);
}

export function onGetFormFieldMap(id) {
  return http.get(base + "/getFormFieldMap/" + id);
}

export function onGetRmfBrandProfile(id) {
  return PeasyApi.get(shortBase + "/brand/rmf?brand_profile_id=" + id);
}

export function onFetchRmfLeadList(brandId, id) {
  return PeasyApi.get(
    shortBase + "/rmf/leads?brand_profile_id=" + brandId + "&rmf=" + id
  );
}
