import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Box, Grid } from "@material-ui/core";
import PeasyApi from "../../utils/peasy.api";
import Graph from "../BrandCreate/steps/Graph";
import Player from "../BrandCreate/steps/Player";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { onGetFunnelData } from "../../utils/network/lib/funnel";

function SelectFunnelType({
  value,
  handleChange,
  productCategory,
  distributionMethod,
  handleQuestionNumber,
  tosData,
}) {
  useEffect(() => {
    getFunnelData();
  }, []);
  const { t } = useTranslation();

  const [type, setType] = useState(0);
  const [details, setDetails] = useState({});
  const [disabled, setDisabled] = useState(false);
  const checkEnter = (e) => {
    if (e.keyCode === 13) {
      validate();
    }
  };

  const validate = () => {
    if (type != "0") {
      console.log("called");
      handleChange("funnel_type", Number(type));
      handleQuestionNumber(2);
    } else {
      toast.error(t("campaign_selectChoice"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const saveChange = (val) => {
    setType(val);
  };
  const getFunnelData = async () => {
    const payload = {
      category: productCategory,
      distribution_method: distributionMethod,
    };
    console.log(payload);
    try {
      const resp = await onGetFunnelData(payload);
      if (resp) {
        console.log(resp);
        setDetails(resp);
        if (resp.objective === "CONVERSIONS") {
          handleChange("brandFunnelType", "CONVERSIONS");
          handleChange("skipLeadForm", true);
          handleChange("obj", "conversions");
        } else {
          handleChange("obj", "leads");
          handleChange("brandFunnelType", "LEAD_GENERATION");
          // handleChange("objective", "leads");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="outer_div w-100 " onKeyDown={(e) => checkEnter(e)}>
      <div className="col-12 px-0 border-rounded p-3 mb-2" id="step12">
        <div className="form-group brand-custom-form">
          <label htmlFor="" className="mb-4">
            {t("campaign_currentFunnelLabel")}
          </label>
          <Grid container spacing={4} style={{ gridAutoRows: "1fr" }}>
            <Grid item xs={12} md={12} lg={7}>
              <Box>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <video controls style={{ borderRadius: "5px" }}>
                      <source
                        src="/videos/CreateCampaign.mp4"
                        type="video/mp4"
                      ></source>
                      <source src="movie.ogg" type="video/ogg"></source>
                    </video>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <Box className="bg-F4F2FF w-100 h-100 p-3" borderRadius={5}>
                <label className="mb-2">{t("campaign_explanatnLabel")}</label>
                <p style={{ textAlign: "justify" }}>
                  {t("campaign_fullFunnelExplanation")}
                </p>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid container justify="center" className="mb-2 mt-4">
            <Grid item xs={12} md={12}>
              <Box
                style={{
                  overflow: "auto",
                  height: "100%",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 8px rgb(155 155 155 / 25%)",
                }}
              >
                <div
                  style={{
                    width: "500px",
                    position: "relative",
                    margin: "auto",
                    borderRadius: "5px",
                    paddingBottom: "30px",
                  }}
                >
                  {details.objective === "LEAD_GENERATION" ? (
                    <img
                      // style={{ width: "500px" }}
                      className="funnel-graph-img"
                      src="/images/assets/Funnelb.svg"
                      alt=""
                    />
                  ) : (
                    <img
                      // style={{ width: "500px" }}
                      className="funnel-graph-img"
                      src="/images/assets/Funnela.svg"
                      alt=""
                    />
                  )}

                  <Graph
                    values={details.default}
                    type={details.objective}
                    object={"target"}
                  />
                </div>
              </Box>
            </Grid>
          </Grid> */}
          <hr />

          <div className="form-group brand-custom-form mt-4 radio">
            <label>{t("campaign_strategyLabel")} </label>
            <Grid container spacing={8} justify="center">
              <Grid item xs={12} md={4} lg={4}>
                <Box className="button-card-container">
                  <Tooltip
                    title={
                      details.objective === "CONVERSIONS" &&
                        tosData.pixel_status.status === false
                        ? "This choice is currently disabled"
                        : "Full Funnel"
                    }
                  >
                    <button
                      disabled={
                        details.objective === "CONVERSIONS" &&
                        tosData.pixel_status.status === false
                      }
                      className={
                        type === "2" ? "button-card-focused" : "button-card"
                      }
                      onClick={(e) => {
                        saveChange("2");
                      }}
                    >
                      <img
                        className="mb-3"
                        src="/images/assets/funnel-type/single-funnel.svg"
                        alt=""
                      />
                      <p className="button-card-heading ">
                        {t("campaign_fullFunnelHeading")}
                      </p>
                      <p className="button-card-description text-justify">
                        {t("campaign_fullFunnelcardDescription")}
                      </p>
                    </button>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Box className="button-card-container">

                  <Tooltip title={t("campaign_singleFunnelHeading")}>
                    <button
                      className={
                        type === "1" ? "button-card-focused" : "button-card"
                      }
                      onClick={(e) => {
                        saveChange("1");
                      }}
                    >
                      <img
                        className="mb-3"
                        src="/images/assets/funnel-type/full-funnel.svg"
                        alt=""
                      />
                      <p className="button-card-heading ">
                        {t("campaign_singleFunnelHeading")}
                      </p>
                      <p className="button-card-description text-justify">
                        {t("campaign_singleFunnelCardDescription")}
                      </p>
                    </button>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button className="disabled-btn">{t("campaign_back")}</button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              className="brandoverviewleftsection__btn--purple "
              onClick={() => {
                validate();
              }}
            >
              {t("campaign_next")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectFunnelType;
