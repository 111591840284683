import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import validator from "validator";
import { toast } from "react-toastify";
import Sidebar from "../SideBar";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import SmallErrorText from "../../../common/smallErrorText/SmallErrorText";
import "./UserProfile.css";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { onEditUser } from "../../../utils/network/lib/user";

// import SubuserUserelement from '../AdminPannelContent/AdminPannelContent';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    backgroundColor: "#fafafa",
  },
  select: {
    fontSize: "18px",
    fontWeight: "700",
    color: "rgb(123 123 123)",
  },
}));

const UserProfile = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const History = useHistory();

  const [formData, setFormData] = useState({});

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    console.log("userData");
    const object = {};
    object.name = userData.name || "";
    object.email = userData.email || "";
    object.phone = userData.phone || "";
    object.password = userData.password || "";
    object.currency =
      JSON.parse(localStorage.getItem("user_data")).currency_type || "";
    object.password_confirmation = "";
    setFormData(object);
  }, []);

  const onSaveClick = (e) => {
    e.preventDefault();
    const isValidated = validateForm();
    if (isValidated) {
      const id = JSON.parse(localStorage.getItem("user_data")).id;
      onEditUser(id, formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t("settings_userProfileEdited"));
            localStorage.setItem("user_data", JSON.stringify(res.data.data));
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          if (err.response.data.errors) {
            setErrors(err.response.data.errors);
          }
        });
    }
  };

  const validateForm = () => {
    const errorObj = {};
    if (!validator.isLength(formData.phone, { min: 10, max: 10 }))
      errorObj.phone = t("settings_phoneNoLength");
    if (!validator.isNumeric(formData.phone))
      errorObj.phone = t("settings_enterValidPhoneNo");
    if (!formData.phone.length) errorObj.phone = t("settings_enterPhoneNo");

    if (formData.password_confirmation !== formData.password)
      errorObj.password_confirmation = t("settings_mismatchedPasswords");
    if (!formData.password_confirmation.length)
      errorObj.password_confirmation = t("settings_confirmPassword");

    if (!formData.password.length)
      errorObj.password = t("settings_passwordFieldNotBlank");

    if (!formData.name.length) errorObj.name = t("settings_nameNotBlank");

    if (!validator.isEmail(formData.email))
      errorObj.email = t("settings_enterValidEmail");
    if (!formData.email.length) errorObj.email = t("settings_emailCantBeBlank");

    setErrors(errorObj);
    if (!Object.keys(errorObj).length) return true;
    else return false;
  };

  return (
    <div className="UserProfileForm">
      <div className="UserProfileForm-Main">
        <form onSubmit={onSaveClick}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <div className="">
                <h2>{t("settings_userProfileHeading")}</h2>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="UserProfile-action-buttons">
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => History.push("/")}
                >
                  {t("settings_back")}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ marginLeft: "15px" }}
                >
                  {t("settings_save")}
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                type="text"
                label={t("settings_userProfile")}
                InputProps={{
                  readOnly: true,
                }}
                value={formData.name}
                variant="outlined"
                fullWidth
              />
              {errors.name && <SmallErrorText text={errors.name} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                type="text"
                label={t("settings_phoneNumber")}
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                variant="outlined"
                fullWidth
              />
              {errors.phone && <SmallErrorText text={errors.phone} />}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                type="email"
                InputProps={{
                  readOnly: true,
                }}
                label={t("settings_emailAddr")}
                value={formData.email}
                variant="outlined"
                fullWidth
              />
              {errors.email && <SmallErrorText text={errors.email} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  className={classes.inputLabel}
                  id="demo-simple-select-outlined-label"
                >
                  {t("settings_currency")}
                </InputLabel>
                <Select
                  className={classes.select}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={formData.currency}
                  onChange={(e) =>
                    setFormData({ ...formData, currency: e.target.value })
                  }
                  label="Age"
                >
                  <MenuItem value="IDR">
                    {t("settings_indonesiaRupiah")}
                  </MenuItem>
                  <MenuItem value="MYR">
                    {t("settings_malaysiaRinggit")}
                  </MenuItem>
                  <MenuItem value="SGD">
                    {t("settings_singaporeDollar")}
                  </MenuItem>
                  <MenuItem value="USD">{t("settings_USDollar")} </MenuItem>
                  <MenuItem value="VND">{t("settings_vietnamDong")}</MenuItem>
                  <MenuItem value="JPY">{t("settings_japaneseYen")}</MenuItem>
                </Select>
              </FormControl>

              {errors.currency && <SmallErrorText text={errors.currency} />}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                type="password"
                label={t("settings_password")}
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                variant="outlined"
                fullWidth
              />
              {errors.password && <SmallErrorText text={errors.password} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                type="password"
                label={t("settings_confirmPassword")}
                value={formData.password_confirmation}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password_confirmation: e.target.value,
                  })
                }
                variant="outlined"
                fullWidth
              />
              {errors.password_confirmation && (
                <SmallErrorText text={errors.password_confirmation} />
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
