import axios from "axios";
import { toast } from "react-toastify";
import * as types from "./actionTypes";
import { BASE_URL } from "../../StaticArray/StaticArray";
import {
  onAddPackageById,
  onAddPermissionPackage,
  onDeletePackageById,
  onDeletePermissionPackageById,
  onGetAllPackageById,
  onGetAllPackages,
  onUpdatePackageById,
  onUpdatePermissionPackageById,
} from "../../utils/network/lib/package";

export const getAllPackages = () => {
  return (dispatch) => {
    dispatch({ type: types.PACKAGE_LOADING });
    return onGetAllPackages()
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.GET_ALL_PACKAGES_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
};

export const getPackageById = (packageId) => {
  return (dispatch) =>
    onGetAllPackageById(packageId)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.GET_CURRENT_PACKAGE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const addPackage = ({
  name,
  description,
  price,
  ad_spend_limit,
  account_limit,
  reference,
  permission_ids,
}) => {
  let addPackageReqPayload = {
    name,
    description,
    price,
    ad_spend_limit,
    account_limit,
    reference,
    permission_ids: [parseInt(permission_ids)],
  };
  return (dispatch) =>
    onAddPackageById(addPackageReqPayload)
      .then((res) => {
        if (res.status === 200) {
          toast.success("New Package Added Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return dispatch({
            type: types.ADD_NEW_PACKAGE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const updatePackage = (
  {
    name,
    description,
    price,
    ad_spend_limit,
    account_limit,
    reference,
    permission_ids,
  },
  packageId
) => {
  let addPackageReqPayload = {
    name,
    description,
    price,
    ad_spend_limit,
    account_limit,
    reference,
    permission_ids,
  };
  return (dispatch) =>
    onUpdatePackageById(packageId, addPackageReqPayload)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.UPDATE_PACKAGE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const deletePackage = (packageId) => {
  return (dispatch) =>
    onDeletePackageById(packageId)
      .then((res) => {
        toast.success("Package Deleted Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        if (res.status === 200) {
          return dispatch({
            type: types.DELETE_PACKAGE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const filterPackage = (ids) => {
  return (dispatch) =>
    dispatch({
      type: types.FILTER_PACKAGE,
      payload: ids,
    });
};

export const addFilterPackage = (perId, id, feature_id, path) => {
  let payload = {
    permission: perId,
    package_id: id,
    feature_id: feature_id,
    parameter: path,
  };
  return (dispatch) => {
    dispatch({ type: types.PACKAGE_LOADING });

    onAddPermissionPackage(payload)
      .then((res) => {
        toast.success("Feature Added Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        if (res.status === 200) {
          return dispatch({
            type: types.ADD_FILTER_PACKAGE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
};

export const updateFilterPackage = (perId, id, feature_id, path, packId) => {
  let payload = {
    permission: perId,
    package_id: id,
    feature_id: feature_id,
    parameter: path,
  };
  return (dispatch) => {
    dispatch({ type: types.PACKAGE_LOADING });

    onUpdatePermissionPackageById(packId, payload)
      .then((res) => {
        toast.success("Feature Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        if (res.status === 200) {
          return dispatch({
            type: types.ADD_FILTER_PACKAGE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
};

export const deleteFilterPackage = (packId) => {
  return (dispatch) => {
    // dispatch({type: types.PACKAGE_LOADING})
    return onDeletePermissionPackageById(packId)
      .then((res) => {
        if (res.status === 204) {
          toast.success("Feature Deleted Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // return dispatch({
          //   type: types.ADD_FILTER_PACKAGE_SUCCESS,
          //   payload: res.data.data
          // });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
};
