import React, { useEffect, useState } from "react";
import FbCard from "../FbCard/FbCard";
import EnterUrlStep from "../enterUrlStep/EnterUrlStep";
import EnterAudienceStep from "../enterAudienceStep/EnterAudienceStep";
import SelectCtaStep from "../selectCtaStep/SelectCtaStep";
import "./CreateCardSteps.css";

const CreateCardSteps = ({
  post,
  pageDetails,
  audiences,
  setCardsToShow,
  setCardsStepPost,
  ctaList,
  editCardPosition,
  setEditCardPosition,
  cardsToShow,
  setDataToSubmit,
  removeCardAtEdit
  // cardToEditData
}) => {
  // Disable background scroll when popup opens and set to auto when closes
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document
    .body
    .addEventListener('keydown', closeWindow, true);
  }, []);

  useEffect(
    () => () => {
      document.body.style.overflow = "auto";
      document
      .body
      .removeEventListener('keydown', closeWindow, true);
    },
    []
  );

  // Code till here to disable background scroll

  useEffect(() => {
    if (post) setCardData(post);
  }, [post]);

  const [stepNumber, setStepNumber] = useState(0);

  const [cardData, setCardData] = useState(null);

  const [errors, setErrors] = useState(null);

  const onUrlChange = value => {
    setCardData(data => ({ ...data, url: value }));
  };


  const closeWindow = e => {
    if (e.key === 'Escape') {
      setCardsStepPost(null);
    }
  };


  const onAudienceChange = audienceId => {
    if (!cardData.audiences)
      return setCardData(data => ({ ...data, audiences: [audienceId] }));
    let audiences = [...cardData.audiences];
    let count = 0;
    let foundAudience = false;
    audiences.map(audience => {
      count += 1;
      if (audience === audienceId) {
        foundAudience = true;
        audiences.splice(count - 1, 1);
      }
    });
    if (!foundAudience) audiences = [...audiences, audienceId];
    return setCardData(data => ({ ...data, audiences: audiences }));
  };

  const onCreatePostClick = () => {
    if (!cardData.audiences || cardData.audiences.length === 0)
      setErrors({ audiences: "Please select some audiences to continue" });
    else {
      setCardsStepPost(null);
      console.log("editcard position", editCardPosition);
      if (editCardPosition || editCardPosition === 0) {
        console.log("inside if of edit card position");
        const cards = [...cardsToShow];
        cards.splice(editCardPosition, 1, cardData);
        setCardsToShow(cards);
        setDataToSubmit(data => {
          const object = { ...data };
          delete object[removeCardAtEdit];
          return object;
        });
        setEditCardPosition(null);
      } else setCardsToShow(cards => [...cards, cardData]);
      setCardData(null);
    }
  };

  return (
    <React.Fragment>
      {console.log("card data", cardData)}
      <div className="createcardsteps__card--container">
        <FbCard
          isDisabled
          card={post}
          audiences={[]}
          pageDetails={pageDetails}
          editingDisabled
          ctaList={ctaList}
          setCardData={setCardData}
        />

        {/* {stepNumber === 0 && <EnterUrlStep setStepNumber={setStepNumber} />} */}

        {/* <div className="enterurlstep__url--container">
          <span className="enterurlstep__url--question">
            What is the destination URL?
          </span>
          <input
            type="text"
            className="form-control urlstep__url--input"
            placeholder="http://"
            value={cardData && cardData.url ? cardData.url : ""}
            onChange={e => onUrlChange(e.target.value)}
          />
        </div> */}

        {/* {(stepNumber === 1 || stepNumber === 2) && ( */}
        {cardData && (
          <EnterAudienceStep
            audiences={audiences}
            onAudienceChange={onAudienceChange}
            selectedAudiences={cardData.audiences}
            setStepNumber={setStepNumber}
            onCreatePostClick={onCreatePostClick}
            errors={errors}
          />
        )}
        {/* )} */}

        {/* {stepNumber === 2 && <SelectCtaStep />} */}
      </div>
      <div className="createcardsteps__curtain"></div>
    </React.Fragment>
  );
};

export default CreateCardSteps;
