import React from "react";
import RetriveStatistics from "./RetriveStatistics";
import RetriveSuggestions from "./RetriveSuggestions";

const RetriveRightContainer = props => (
  <div className="frame_rgt_col">
    <RetriveStatistics {...props} />
    <RetriveSuggestions {...props} />
  </div>
);

export default RetriveRightContainer;
