import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DeleteConf = props => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader color="danger" toggle={props.toggle}>Delete confirmation</ModalHeader>
            <ModalBody>
                <span>Are you sure you want to delete selected item(s).</span>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                <Button color="danger" onClick={props.onConfirm}>Delete</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteConf