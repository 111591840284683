import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import "./BrandCreate.css";
import { BASE_URL } from "../../StaticArray/StaticArray";
import http from "../../shared/http-request";
import "../../translations/i18n";
import { withTranslation } from "react-i18next";

import CreateBrandName from "./steps/CreateBrandName";
import CreateBrandCategory from "./steps/CreateBrandCategory";
import CreateBrandWebsite from "./steps/CreateBrandWebsite";
// import CreateBrandFbConnect from "./steps/CreateBrandFbConnect";
import CreateBrandSelectFbPage from "./steps/CreateBrandSelectFbPage";
// import CreateBrandSelectFbAccount from "./steps/CreateBrandSelectFbAccount";
import CreateBrandPixels from "./steps/CreateBrandPixels";
import CreateBrandLastStep from "./steps/CreateBrandLastStep";
// import AddAudiences from "./steps/AddAudiences";
import { toast } from "react-toastify";
import CreateDistributionMethod from "./steps/CreateDistributionMethod";
import PeasyApi from "../../utils/peasy.api";
import TagManager from "react-gtm-module";
import { Spinner } from "reactstrap";
import { AppBar, Box, Hidden, Typography } from "@material-ui/core";
import {
  getCatalogs,
  onCreateBrand,
  onGetBrandProfileList,
  onGetBrandsById,
  onPostBrand,
} from "../../utils/network/lib/brand";
import { onGetFunnelData } from "../../utils/network/lib/funnel";
import { EVENT_TYPE_EXTERNAL } from "../../utils/eventConstants";
import CreateBrandCatalogs from "./steps/CreateBrandCatalogs";

class BrandCreate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      fbAdAccounts: [],
      fbActiveAccountId: "",
      accountCurrency: "",
      fbAllPages: [],
      isFacebookAccessible: false,
      fbevent: {},
      formData: {
        brand_name: "",
        product_category: "",
        distribution_method: "",
        brand_website: "",
        fb_promoted_id: "",
        prepaid_fb_account_id: "",
        prepaid_pixel_events: [],
        prepaid_audience_ids: [],
        user_funnel_id: null,
        pixel_id: "",
        catalog_id: "",
      },
      currentStep: 1,
      update: false,
      accountType: "",
      fb_account: null,
      brandCreateLoading: false,
      loading: true,
      catalogs: [],
    };
  }

  async componentWillMount() {
    let s;
    const editData = {};
    const args = {
      dataLayer: {
        event_custom: "brand_profile_create",
        /* can pass more variables here if needed */
      },
    };
    TagManager.dataLayer(args);
    const urlArray = window.location.href.split("/");
    if (urlArray[urlArray.length - 2] === "update") {
      if (!this.props.location.state) {
        const brandId = window.location.href.split("/").pop();
        onGetBrandsById(brandId)
          .then((res) => {
            s = res.data.data;
            let audienceIds = [];
            if (s && s.audiences) {
              s.audiences.map((audience) => {
                let object = {};
                object.id = audience.audience_id;
                object.name = audience.audience_name;
                audienceIds = [...audienceIds, object];
              });
            }
            if (s) {
              editData.brand_name = s.brand_name || "";
              editData.product_category = s.product_category || "";
              editData.brand_website = s.brand_website || "";
              editData.fb_promoted_id = s.fb_promoted_id || "";
              editData.prepaid_fb_account_id = "";
              editData.prepaid_audience_ids = audienceIds;
              editData.prepaid_pixel_events = s.pixels;
              editData.distribution_method = s.distribution_method || "";
              editData.pixel_id = s.pixel_id || "";
              editData.profile_id = window.location.href.split("/").pop();
              editData.pixel_id = s.pixel_id || "";
              editData.catalog_id = s.catalog?.integrations?.facebook?.id
            }
          })
          .catch((err) => {
            return toast.error(err.response.data.message);
          });
      } else {
        s = this.props.location.state;
        let audienceIds = [];
        if (s && s.audiences) {
          s.audiences.map((audience) => {
            let object = {};
            object.id = audience.audience_id;
            object.name = audience.audience_name;
            audienceIds = [...audienceIds, object];
          });
        }
        if (s) {
          editData.brand_name = s.brand_name || "";
          editData.product_category = s.product_category || "";
          editData.brand_website = s.brand_website || "";
          editData.fb_promoted_id = s.fb_promoted_id || "";
          editData.prepaid_fb_account_id = "";
          editData.prepaid_audience_ids = audienceIds;
          editData.prepaid_pixel_events = s.pixels;
          editData.distribution_method = s.distribution_method || "";
          editData.pixel_id = s.pixel_id || "";
          editData.profile_id = window.location.href.split("/").pop();
          editData.pixel_id = s.pixel_id || "";
          editData.catalog_id = s.catalog?.integrations?.facebook?.id
        }
      }

      this.setState({
        update: true,
        formData: editData,
      });
    }

    getCatalogs({
      brand_profile_id:
        urlArray[urlArray.length - 2] === "update" ? urlArray.pop() : "",
    })
      .then((res) => {
        if (
          res.status === 200 &&
          res.data &&
          Array.isArray(res.data) &&
          res.data.length > 0
        ) {
          console.log("get catalogs res", res.data);
          this.setState({ catalogs: res.data });
        }
      })
      .catch((err) => {
        console.log("get catalogs err", err);
      });

    try {
      // return;
      onCreateBrand()
        .then((res) => {
          if (!res.data.data.fbAllPages.data) {
            res.data.data.fbAllPages.data = [];
          }
          console.log("onCreateBrand", res.data.data.fbAllPages.data);
          this.setState({ ...res.data.data });
          this.setState({ loading: false });
        })
        .catch((err) => {
          toast.error(err.response.data?.message ?? "Something went wrong");
          // toast.error(err.response.data.message);
          // window.$peasy.triggerEvent({
          //   event_name: "error",
          //   event_params: {
          //     type: "external",
          //     id: "2",
          //     error_msg: err.response.data.message,
          //   },
          // });
        });
    } catch (err) {
      toast.error(this.props.t("brand_somethingWentWrong"));
    }

    //Info event
    const data = await onGetBrandProfileList();

    const getActivePackage =
      JSON.parse(localStorage.getItem("user_data"))["active_package"] || "";
    const account_type =
      JSON.parse(localStorage.getItem("user_data"))["account_type"] || "";
    const prepaid_balance =
      JSON.parse(localStorage.getItem("user_data"))["prepaid_balance"] || "";

    if (
      data.length == 0 &&
      getActivePackage &&
      getActivePackage.price &&
      account_type == "prepaid" &&
      prepaid_balance == 0
    ) {
      window.$peasy.triggerEvent({
        event_name: "info",
        event_params: { id: "1" },
      });
    }
  }

  onChange = (name, value) => {

    if(name === "catalog"){

      const {formData} = this.state
      formData["catalog_id"] = value[1]
      formData["catalog_name"] = value[0]
      this.setState({ formData: formData });

    }else{
      this.setState({ formData: { ...this.state.formData, [name]: value } });
      setTimeout(() => {
      }, 2000);

    }

   
  };
  nextPageOnEnter = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  prevStep = () => {
    this.setState({ currentStep: this.state.currentStep - 1 });
  };

  backSomeSteps = (i) => {
    this.setState({ currentStep: this.state.currentStep - i });
  };

  goToStep = (i) => {
    this.setState({ currentStep: i });
  };

  getFunnelData = async () => {
    var category = this.state.formData["product_category"];
    // var category = "finance";
    var distributionMethod = this.state.formData["distribution_method"];
    const urlArray = window.location.href.split("/");
    const payload = {
      category: category,
      distribution_method: distributionMethod,
      brand_profile_id:
        urlArray[urlArray.length - 2] === "update" ? urlArray.pop() : "",
    };
    try {
      const resp = await onGetFunnelData(payload);
      if (resp) {
        // this.setState("user_funnel_id", resp.user_funnel_id);
        const newState = {
          ...this.state.formData,
          user_funnel_id: resp.user_funnel_id,
        };
        this.setState({ formData: newState });
        return resp;
      }
    } catch (e) {
      console.log(e);
    }
  };

  checkToSkipPixel = () => {
    const product = [
      "Finance",
      "Business & Industrial",
      "Law & Government",
      "Real Estate",
      "Autos & Vehicles",
      "Health",
      "Science",
      "Jobs & Education",
      "Insurance",
    ];
    if (
      this.state.formData.distribution_method === 1 ||
      this.state.formData.distribution_method === 2 ||
      product.includes(this.state.formData.product_category)
    ) {
      return "LEAD_GENERATION";
    } else {
      return "CONVERSION";
    }
  };

  getCurrentStep = () => {
    switch (this.state.currentStep) {
      case 1:
        return (
          <CreateBrandName
            onChange={this.onChange}
            nextPageOnEnter={this.nextPageOnEnter}
            value={this.state.formData.brand_name}
          />
        );
      case 2:
        document.getElementById("progressbar").style.width = "20%";
        return (
          <CreateDistributionMethod
            value={this.state.formData.distribution_method}
            onChange={this.onChange}
            nextPageOnEnter={this.nextPageOnEnter}
            prevStep={this.prevStep}
          />
        );
      case 3:
        document.getElementById("progressbar").style.width = "40%";
        return (
          <CreateBrandCategory
            checkToSkipPixel={this.checkToSkipPixel}
            value={this.state.formData.product_category}
            onChange={this.onChange}
            nextPageOnEnter={this.nextPageOnEnter}
            category={this.state.category}
            prevStep={this.prevStep}
          />
        );
      case 4:
        document.getElementById("progressbar").style.width = "60%";

        return (
          <CreateBrandWebsite
            value={this.state.formData.brand_website}
            onChange={this.onChange}
            nextPageOnEnter={this.nextPageOnEnter}
            prevStep={this.prevStep}
          />
        );

      case 5:
        document.getElementById("progressbar").style.width = "80%";

        return (
          <CreateBrandSelectFbPage
            fbAllPages={this.state.fbAllPages}
            onChange={this.onChange}
            nextPageOnEnter={this.nextPageOnEnter}
            value={this.state.formData.fb_promoted_id}
            prevStep={this.prevStep}
            backSomeSteps={this.backSomeSteps}
            isFacebookAccessible={this.state.isFacebookAccessible}
            checkFunnelType={this.checkToSkipPixel}
          />
        );
      case 6:
        if (Object.keys(this.state.fbevent).length === 0) {
          this.nextPageOnEnter();
        }
        if (this.checkToSkipPixel() === "LEAD_GENERATION") {
          this.nextPageOnEnter();
        }
        document.getElementById("progressbar").style.width = "85%";
        return (
          <CreateBrandPixels
            fbevent={this.state.fbevent}
            onChange={this.onChange}
            nextPageOnEnter={this.nextPageOnEnter}
            prepaid_fb_account_id={this.state.formData.prepaid_fb_account_id}
            prevStep={this.prevStep}
            pixel_id={this.state.formData.pixel_id}
          />
        );

      case 7:
        if (Object.keys(this.state.catalogs).length === 0) {
          this.nextPageOnEnter();
        }
        // if (this.checkToSkipPixel() === "LEAD_GENERATION") {
        //   this.nextPageOnEnter();
        // }
        // document.getElementById("progressbar").style.width = "90%";
        return (
          <CreateBrandCatalogs
            catalogs={this.state.catalogs}
            onChange={this.onChange}
            nextPageOnEnter={this.nextPageOnEnter}
            prepaid_fb_account_id={this.state.formData.prepaid_fb_account_id}
            prevStep={this.prevStep}
            pixel_id={this.state.formData.pixel_id}
            catalog_id={this.state.formData.catalog_id}
          />
        );

      case 8:
        document.getElementById("progressbar").style.width = "95%";

        return (
          <CreateBrandLastStep
            createBrand={this.createBrand}
            isUpdate={this.state.update}
            goToStep={this.goToStep}
            brandCreateLoading={this.state.brandCreateLoading}
            getFunnelData={this.getFunnelData}
            category={this.state.formData.product_category}
            distributionMethod={this.state.formData.distribution_method}
            fb_promoted_id={this.state.formData.fb_promoted_id}
          />
        );
      default:
        return <CreateBrandName />;
    }
  };

  createBrand = () => {
    this.setState({ brandCreateLoading: true });
    return onPostBrand(this.state.formData)
      .then((res) => {
        console.log("post brand", res);
        if (res.data.flag === 0) {
          return toast.error(res.data.error);
        }
        document.getElementById("progressbar").style.width = `100%`;
        if (this.state.update) {
          toast.success(this.props.t("brand_updatedSuccessfully"));
          return this.props.history.push("/dashboard", {
            id: window.location.href.split("/").pop(),
          });
        } else {
          window.gist.identify(
            localStorage.getItem("user_data") &&
              JSON.parse(localStorage.getItem("user_data")).id,
            {
              brand_name: res.data.brand_name,
              brand_business:
                res.data.distribution_method === 1
                  ? "Business to Business"
                  : res.data.distribution_method === 2
                  ? "Business to Distributer"
                  : "Business to Consumer",
              brand_industry: res.data.product_category,
            }
          );

          return { status: true, res: res };
          // toast.success("Brand created successfully!");
        }

        // return this.props.history.push("/brand-profiles");
        // return true;
      })
      .catch((err) => {
        toast.error(err.response.data.message);

        window.$peasy.triggerEvent({
          event_name: "error",
          event_params: { id: "3", type: EVENT_TYPE_EXTERNAL },
        });

        // if (
        //   err.response.data.message == "Unable to connect to your Facebook Page"
        // ) {
        //   // window.$peasy.triggerEvent({
        //   //   event_name: "error",
        //   //   event_params: { id: "3", type: "external" },
        //   // });
        // }
      })
      .finally(() => {
        this.setState({ brandCreateLoading: false });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <Box className="loader">
          <Spinner />
        </Box>
      );
    }
    const { formData } = this.state;

    return (
      <>
        <div className="container brand-sec-prt brand-mobile-view-container">
          <div className="row">
            <div className="col-12 mt-3">
              <form id="regForm" onSubmit={(e) => e.preventDefault()}>
                <div className="d-block mb-3 mt-3 row mx-0">
                  {this.state.currentStep <= 6 ? (
                    <h2 style={{ margin: "10px 0px", padding: "6px" }}>
                      {this.props.t("brand_question")} {this.state.currentStep}
                    </h2>
                  ) : (
                    <></>
                  )}
                  <div className="progress ml-2 mr-2">
                    <div
                      className="progress-bar"
                      id="progressbar"
                      style={{ width: "14.29%" }}
                      aria-valuenow="14.29"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </div>
                <div className="tab-content brand-custom-form-prt row mx-0">
                  {this.getCurrentStep()}
                </div>
                <input
                  type="hidden"
                  name="profile_id"
                  value={formData.id || ""}
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(BrandCreate));
