import React from "react";

const ImageContainer = ({
  alt,
  width,
  height,
  className,
  src,
  onClick,
  align,
  server
}) => (
  <img
    alt={alt}
    width={width}
    height={height}
    onClick={onClick}
    className={className}
    align={align ? align : ""}
    src={server ? `${src}` : `${process.env.PUBLIC_URL}/images/${src}`}
  />
);

export default ImageContainer;
