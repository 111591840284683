import React, { useEffect, useRef, useState } from 'react';
import "./RMF.css";

// mesure width of a text string 
function getTextWidth(text, fontSize, fontFace) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${fontSize}px ${fontFace}`;
  const metrics = context.measureText(text);
  return metrics.width;
}



export function RMFchart({dataList=[],setSelectedRow}) {
  const chartRef = useRef(null);
  const [chartLoaded, setChartLoaded] = useState(false);
  // console.log(dataList,"gg dataList");
  /*
    dataList = [
        ['Title', 'Parent', 'Percentage', 'Count'],
        ['RMF', null, 15, 10],
        ['New Customers', 'RMF', 18, 7],
        ['Champions', 'RMF', 0, 6],
        ['About To Sleep', 'RMF', 4, 5],
        ['Need Attention', 'RMF', 15, 10],
        ['Lost', 'RMF', 0, 3],
        ['Loyal Customers', 'RMF', 0, 7],
        ['Promising', 'RMF', 14, 21],
        ['Potential Loyalist', 'RMF', 6, 0],
        ['At Risk', 'RMF', 18.18, 2],
        ["Can't Loose Them", 'RMF', 27.27, 3],
        ['Hibernating', 'RMF', 4.55, 6]
    ]
  */
  function drawTree(productList) {
    console.log("in dTr",productList);
    if (!productList?.length) {
        return
    }
    // console.log(chartLoaded,"chartLoaded");
    // if (!chartLoaded) {
    //   console.log(chartLoaded,"chartLoaded In");
    //     return
    // }
    
    // add demo data and delete later end
    const data = window.google.visualization.arrayToDataTable([...productList]);
    const chart = new window.google.visualization.TreeMap(chartRef.current);

    window.google.visualization.events.addListener(chart, 'ready', addChildLabels);
    window.google.visualization.events.addListener(chart, 'select', addChildLabels);

    const observer = new MutationObserver(moveOriginalLabels);
    observer.observe(chartRef.current, {
      childList: true,
      subtree: true,
    });

    function moveOriginalLabels() {
      Array.prototype.forEach.call(chartRef.current.getElementsByTagName('text'), function (text) {
        const bounds = text.getBBox();
        const rect = text.parentNode.getElementsByTagName('rect')[0];
        if (rect.getAttribute('fill') !== '#cccccc' && text.getAttribute('text-anchor') === 'middle') {
          text.setAttribute('fill', '#424242');
        }
      });
    }

    function addChildLabels() {
      const childCount = [];
      const childLabels = [];
      // console.log("Call enter addChild");
      const svgNS = chartRef.current.getElementsByTagName('svg')[0].namespaceURI;
      Array.prototype.forEach.call(chartRef.current.getElementsByTagName('text'), function (text) {
        
        if (text.getAttribute('text-anchor') === 'middle') {
          const rect = text.parentNode.getElementsByTagName('rect')[0];
          rect.style.boxShadow = '2px 2px 4px #666';
          rect.style.backgroundColor = '#666';
          if (rect.getAttribute('fill') !== '#cccccc') {
            moveOriginalLabels();
            // console.log(text.getAttribute('text-anchor'),"movvvvvv");
            // add text position center
            text.setAttribute('fill', '#000000');
            text.setAttribute('font-family', 'Roboto');
            text.setAttribute('font-weight', '700');
            text.setAttribute('font-size', '20px');
            text.setAttribute('line-height', '23.44px');
            const rectX = parseFloat(rect.getAttribute('x'));
            const rectY = parseFloat(rect.getAttribute('y'));
            const rectWidth = parseFloat(rect.getAttribute('width'));
            const rectHeight = parseFloat(rect.getAttribute('height'));
            const textX = rectX + rectWidth / 2; // x coordinate for center of rectangle
            const textY = rectY + rectHeight / 2; // y coordinate for center of rectangle
            text.setAttribute('x', textX);
            text.setAttribute('y', textY);




            let nodeValue;
            for (let i = 0; i < data.getNumberOfRows(); i++) {
              if (data.getValue(i, 0) === text.textContent || data.getFormattedValue(i, 0) === text.textContent) {
                nodeValue = data.getValue(i, 0);
              }
            }
            const children = data.getFilteredRows([
              {
                column: 1,
                value: nodeValue,
              },
            ]);

            
            // get productCount and Percentage
            const currentData = productList.find(productInfo=>productInfo[0] === nodeValue);
            const productPercentText = `${currentData[3]}(${currentData[2]}%)`;
            const productPercent = document.createElementNS(svgNS, 'text');
            productPercent.setAttribute('fill', '#000000');
            productPercent.setAttribute('font-size', '16');
            // productPercent.setAttribute('x', textX); // set x position same as text
            productPercent.setAttribute('x', textX - parseFloat(getTextWidth(productPercentText,16,'Arial')/2)); 
            productPercent.setAttribute('y', textY + parseFloat(productPercent.getAttribute('font-size')) + 4); 
            productPercent.textContent = productPercentText;
            childCount.push([text, productPercent]);
            
            // console.log(parseFloat(getTextWidth(`${currentData[3]}(${currentData[2]}%)`,16,'Arial')/2));
            
            // add icon to UI
            var imgIconLabel = document.createElementNS("http://www.w3.org/2000/svg", "image");
            imgIconLabel.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", `/images/RMF_icons/${nodeValue}.png`);
            imgIconLabel.setAttribute("width", "64");
            imgIconLabel.setAttribute("height", "64");
            imgIconLabel.setAttribute('x', rectX + 25); // set x position at left of rectangle
            imgIconLabel.setAttribute('y', rectY + rectHeight - 85); // set y position at bottom of rectangle
            imgIconLabel.textContent = 'Children image icon';
            childLabels.push([text, imgIconLabel]);
            // console.log("Drawn Img: ",`/images/RMF_icons/${nodeValue}.png`);
          }
        }
      });

      childCount.forEach(function (text) {
        text[0].parentNode.appendChild(text[1]);
      });
      childLabels.forEach(function (text) {
        text[0].parentNode.appendChild(text[1]);
      });
    }
    

   const options = {
      highlightOnMouseOver: true,
      maxDepth: 1,
      maxPostDepth: 2,
      minHighlightColor: '#f0edff',
      midHighlightColor: '#f0edff',
      maxHighlightColor: '#f0edff',
      minColor: '#f3f2f7',
      midColor: '#f7f7f7',
      maxColor: '#F4F2FF',
      headerHeight: 0,
      showScale: false,
      useWeightedAverageForAggregation: true,
   }
    chart.draw(data, options);
    
    // prevenet the select to hide the other block
      window.google.visualization.events.addListener(chart, 'select', (e) => {
        const selection = chart.getSelection();
        if ( selection[0] && selection[0].row && selection[0].row > 0) {
          const d = productList[selection[0].row + 1];
          setSelectedRow(d);
        }
        chart.setSelection([]);
        addChildLabels();
      });
      
     /*
      window.google.visualization.events.addListener(chart, 'select', () => {
        // Get the data for the selected node
        const selection = chart.getSelection();
        const selectedNodeData = productList[selection[0].row];

        // Do whatever you need to do with the selected node data
        console.log(selectedNodeData);
        // setData(data);
        chart.setSelection([]);
        addChildLabels();
      });
      */

  }



  useEffect(() => {
    console.log("khall",dataList);
    let chartLoad = null;
    if (typeof window.google !== 'undefined' && typeof window.google.charts !== 'undefined') {
      window.google.charts.load('current', {
        packages: ['treemap'],
        callback: () => {
          chartLoad = new window.google.visualization.TreeMap(chartRef.current);
          setChartLoaded(true);
          drawTree(dataList);
        },
      });
    } else {
      const script = document.createElement('script');
      script.src = 'https://www.gstatic.com/charts/loader.js';
      script.async = true;
      script.onload = () => {
        window.google.charts.load('current', {
          packages: ['treemap'],
          callback: () => {
            console.log("calling bck");
            chartLoad = new window.google.visualization.TreeMap(chartRef.current);
            setChartLoaded(true);
            drawTree(dataList);
          },
        });
      };
      document.head.appendChild(script);
    }

    // call to draw graph again after resize window
    const handleResize = () => {
      console.log("Resizing chacking");
      if (chartLoad) {
        console.log("Resizing....");
        drawTree(dataList);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [dataList,JSON.stringify(dataList)]);

  return <div style={{ height: '100%', width: '100%', marginTop:"10px" }}>
      <div  ref={chartRef}  id="chart_div" />
  </div>
}

// export default React.memo(RMFchart);
export default RMFchart;

