import React, { useState, useEffect } from "react";
import Select from "react-select";
import Logo from "../logo/Logo";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import Description from "../description/Description";
import PhotoComponent from "../photoComponent/PhotoComponent";
import SmallErrorText from "../../../common/smallErrorText/SmallErrorText";
import PopupAlert from "../../../common/popupAlert/PopupAlert";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import "./ImageCard.css";
import { EVENT_TYPE_CREATIVE } from "../../../utils/eventConstants";
import { deletePendingPost } from "../../../utils/network/lib/canva";

const ImageCard = ({
  pageDetails,
  audiences,
  ctaList,
  card,
  imageCardIds,
  changeImageCardArray,
  deleteImageCard,
  platform,
  setNotification,
  notification,
}) => {
  //CANVA START
  const onEditCanvaDesign = async (opts) => {
    console.log("onedit opts", opts, card.isCanva);

    const response = await fetch(opts.exportUrl);
    const blob = await response.blob();
    const fileElement = new File([blob], "canva_export.jpg", {
      type: blob.type,
    });

    console.log("cardData", cardData);
    const obj = { ...cardData, path: "", file_object: fileElement };
    const index = imageCardIds.indexOf(cardData.unique_id);
    changeImageCardArray(index, obj);
  };
  //CANVA END

  // States
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);

  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);

  const [showCtaOptions, setShowCtaOptions] = useState(false);

  const [cardData, setCardData] = useState({});

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [isErrorPopUp, setErrorPopUp] = useState(false);

  const [previousAudience, setPreviousAudience] = useState([]);

  const [errorHeading, setErrorHeading] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  //Effects

  // set options for audience list
  useEffect(() => {
    getAudienceOptions();
  }, [audiences]);

  useEffect(() => {
    decideCardData();
  }, [card]);

  //if we assign a new unique id here send the data for calibration
  // useEffect(() => {
  //   changeMainDataOnBlur();
  // }, [cardData.unique_id]);

  //Functions

  const decideCardData = () => {
    const object = {
      unique_id: card.unique_id || card.id,
      file_object: card.file_object || null,
      path: card.path || "",
      headline: card.headline || "",
      description: card.description || "",
      message: card.message || "",
      cta: card.cta || null,
      final_url: card.final_url || "",
      ids: card.ids || null,
      audiences: card.audiences || null,
      errors: card.errors || {},
      status: card.status || "DRAFT",
      width: card.width ? card.width : "",
      height: card.height ? card.height : "",
      widthRatio: card.widthRatio ? card.widthRatio : "",
      heightRatio: card.heightRatio ? card.heightRatio : "",
      isCanva: card.isCanva,
    };
    setCardData(object);
  };

  const getAudienceOptions = () => {
    if (audiences && audiences.length > 0) {
      let array = [];
      audiences.map((audience) => {
        const object = {};
        object.value = audience.id;
        object.label = audience.name;
        array = [...array, object];
      });
      setOptions(array);
    }
  };

  const onChange = (name, value, e) => {
    if (e) {
      if (previousAudience.length <= e.length) {
        // let filterData = e.filter(
        //   (o) => !previousAudience.find((o2) => o.value === o2.value)
        // );
        // setPreviousAudience(e);
        let selectedValue = e[0].value;
        let selectedAudience = audiences.find((r) => r.id === selectedValue);

        if (
          selectedAudience &&
          selectedAudience.media_channel[5] &&
          selectedAudience.media_channel[5].budget_split > 0 &&
          cardData.widthRatio > 1
        ) {
          setErrorHeading(t("campaign_instaImageValidation"));
          setErrorMessage(t("campaign_imageSizeError"));

          window.$peasy.triggerEvent({
            event_name: "error",
            event_params: {
              id: "10",
              type: EVENT_TYPE_CREATIVE,
              error_msg: "image/video",
            },
          });

          setErrorPopUp(true);
        } else {
          setPreviousAudience(e);
          const obj = { ...cardData, [name]: value };
          setCardData(obj);
        }
      } else {
        setPreviousAudience(e);
        const obj = { ...cardData, [name]: value };
        setCardData(obj);
      }
    } else {
      const obj = { ...cardData, [name]: value };
      setCardData(obj);
    }
  };

  const onEditClick = () => {
    console.log("image edit", card.isCanva);

    const index = imageCardIds.indexOf(cardData.unique_id);
    document.getElementsByClassName("input__file--image")[index].click();
  };

  const onFileChange = (file) => {
    const obj = { ...cardData, path: "", file_object: file };
    const index = imageCardIds.indexOf(cardData.unique_id);
    changeImageCardArray(index, obj);
  };

  const onDeleteClick = () => {
    const index = imageCardIds.indexOf(cardData.unique_id);

    if (cardData.isCanva) {
      let canvaDetails = cardData.isCanva;

      deletePendingPost(canvaDetails.campaign_id, canvaDetails.name).then(
        (response) => {
          console.log("delete res", response.status);
          if (response.status == 200) {
            deleteImageCard(index);
          } else {
          }
        }
      );
    } else {
      deleteImageCard(index);
    }
  };

  const changeMainDataOnBlur = (object) => {
    const index = imageCardIds.indexOf(cardData.unique_id);
    if (object) changeImageCardArray(index, object);
    else changeImageCardArray(index, cardData);
  };

  const changeCtaValue = (cta) => {
    let updatedValue = cta.replace("_", " ");
    return updatedValue;
  };

  // Getting audience function
  const getAudiencesFromIds = () => {
    let audienceData = [...new Set(cardData.audiences)];
    if (audienceData && audienceData.length > 0) {
      let selectedAudiences = [];
      audienceData.map((id) => {
        for (let i = 0; i < options.length; i++) {
          if (options[i].value === id)
            return (selectedAudiences = [...selectedAudiences, options[i]]);
        }
      });
      return selectedAudiences;
    }
    return [];
  };

  return (
    <React.Fragment>
      {showDeletePopup && (
        <PopupAlert
          heading={t("campaign_areYouSure")}
          body={t("campaign_sureYouWantToDeletePost")}
          onNoClick={() => setShowDeletePopup(false)}
          onYesClick={() => {
            onDeleteClick();
            setShowDeletePopup(false);
          }}
        />
      )}
      {isErrorPopUp && (
        <PopupAlert
          heading={errorHeading}
          body={errorMessage}
          onYesClick={() => {
            setErrorHeading("");
            setErrorMessage("");
            setErrorPopUp(false);
          }}
          isError={true}
        />
      )}

      {/* input file for editing the image. visibility hidden */}
      <input
        type="file"
        className="input__file--image"
        accept="image/png, image/jpeg"
        onChange={(e) => onFileChange(e.target.files[0])}
        style={{ display: "none" }}
      />
      <div className="imagecard__container">
        <div className={`imagecard__container__${cardData.unique_id}`}></div>
        <div style={{ display: "flex" }}>
          <Logo
            url={
              pageDetails.page_image ||
              "https://randomuser.me/api/portraits/women/50.jpg"
            }
            style={{ marginTop: "2%", marginLeft: "2%" }}
          />

          <div className="imagecard__heading--container">
            <span className="imagecard__heading--bold">{pageDetails.name}</span>
            <span className="imagecard__issponsored">
              {t("campaign_sponsored")}
            </span>
          </div>
        </div>

        {/*<span className="imagecard__subheading">
          <input
            className="imagecard__input--headline"
            value={cardData.headline}
            type="text"
            placeholder="Headline"
            onChange={(e) => onChange("headline", e.target.value)}
            onBlur={() => {
              changeMainDataOnBlur();
            }}
          />

          {cardData.errors && cardData.errors.headline && (
            <SmallErrorText
              style={{
                display: "block",
                marginTop: "10px",
                backgroundColor: "#faebd7",
                width: "fit-content",
              }}
              text={cardData.errors.headline}
            />
          )}
            </span> */}
        <Description
          text={cardData.headline}
          isEditable={isDescriptionEditable}
          onEditClick={() => setIsDescriptionEditable(true)}
          onChange={(name, value) => onChange(name, value)}
          onBlur={() => {
            setIsDescriptionEditable(false);
            changeMainDataOnBlur();
          }}
          commonStyle={{
            marginLeft: "5%",
            marginBottom: "10px",
            fontSize: "1.4rem",
            borderBottom: cardData.headline === "" ? "2px solid red" : "none",
          }}
        />

        {cardData.errors && cardData.errors.headline && (
          <SmallErrorText
            style={{
              display: "inline-block",
              backgroundColor: "rgb(250, 235, 215)",
              fontWeight: 500,
              fontSize: "12px",
              color: "rgb(145, 45, 43)",
              marginBottom: "10px",
              marginLeft: "5%",
            }}
            text={cardData.errors.headline}
          />
        )}

        <div className="imagecard__photo--container">
          {cardData.file_object && (
            <PhotoComponent url={URL.createObjectURL(cardData.file_object)} />
          )}
          {cardData.path && (
            <PhotoComponent
              url={`${BASE_URL}thumbnail/adimage?path=${cardData.path}`}
            />
          )}{" "}
          <div id="imagecard-layover" className="imagecard__layover" />
          <button
            onClick={() => onEditClick()}
            className="imagecard__btn--edit"
          >
            {t("campaign_editCreative")}
          </button>
          <button
            onClick={() => setShowDeletePopup(true)}
            style={{ top: "61%" }}
            className="imagecard__btn--edit"
          >
            {t("campaign_delPost")}
          </button>{" "}
        </div>

        <div
          style={{
            backgroundColor: "#E9EBED",
            paddingBottom: "1px",
            position: "relative",
            width: "100%",
            paddingTop: "20px",
            // height: "7rem",
          }}
        >
          <div className="imagecard__url imagecard__url--image-card">
            <input
              disabled={platform === "MESSENGER"}
              value={cardData.final_url}
              type="text"
              className="imagecard__input--headline"
              placeholder={t("campaign_enterURL")}
              onChange={(e) => onChange("final_url", e.target.value)}
              onBlur={() => {
                changeMainDataOnBlur();
              }}
              style={{
                backgroundColor: "#E9EBED",
                borderBottom:
                  cardData.final_url === ""
                    ? "2px solid red"
                    : "2px solid #8d02fa",
              }}
            />
          </div>

          {cardData.errors && cardData.errors.url && (
            <SmallErrorText
              style={{
                display: "block",
                marginTop: "10px",
                backgroundColor: "#faebd7",
                width: "fit-content",
                marginLeft: "5%",
                fontWeight: 500,
              }}
              text={cardData.errors.url}
            />
          )}

          {cardData.errors && cardData.errors.cta && (
            <SmallErrorText
              style={{
                display: "block",
                marginTop: "10px",
                backgroundColor: "rgb(250, 235, 215)",
                fontSize: "12px",
                color: "rgb(145, 45, 43)",
                width: "fit-content",
                position: "absolute",
                right: "0%",
                top: "40px",
                fontWeight: 500,
              }}
              text={cardData.errors.cta}
            />
          )}
          <div className="imagecard__subheading imagecard__subheading--image-card">
            <input
              className="imagecard__input--headline"
              value={cardData.message}
              type="text"
              placeholder={t("campaign_enterCaption")}
              onChange={(e) => onChange("message", e.target.value)}
              onBlur={() => {
                changeMainDataOnBlur();
              }}
              style={{
                backgroundColor: "#E9EBED",
                // width: "17rem",
                borderBottom:
                  cardData.message === ""
                    ? "2px solid red"
                    : "2px solid #8d02fa",
              }}
            />

            {cardData.errors && cardData.errors.message && (
              <SmallErrorText
                style={{
                  display: "block",
                  marginTop: "10px",
                  backgroundColor: "#faebd7",
                  width: "fit-content",
                  fontWeight: 500,
                }}
                text={cardData.errors.message}
              />
            )}
          </div>
          <div
            className="imagecard__span--message"
            style={{ top: "60px", left: 0 }}
          >
            <input
              className="imagecard__input--headline"
              value={cardData.description}
              type="text"
              onChange={(e) => onChange("description", e.target.value)}
              onBlur={() => {
                changeMainDataOnBlur();
              }}
              style={{
                backgroundColor: "#E9EBED",
                borderBottom:
                  cardData.description === ""
                    ? "2px solid red"
                    : "2px solid #8d02fa",
              }}
              placeholder={t("campaign_enterDescription")}
            />

            {cardData.errors && cardData.errors.description && (
              <SmallErrorText
                style={{
                  display: "block",
                  marginTop: "10px",
                  backgroundColor: "#faebd7",
                  width: "fit-content",
                  fontWeight: 500,
                }}
                text={cardData.errors.description}
              />
            )}
          </div>
          <div
            className="imagecard__btn--cta--container"
            style={{ top: "-0.5rem", right: 0 }}
          >
            <button
              style={{
                border: !cardData.cta ? "2px solid red" : "2px solid black",
              }}
              className="imagecard__btn--cta"
              onClick={() => {
                if (showCtaOptions) {
                  setShowCtaOptions(false);
                } else setShowCtaOptions(true);
              }}
            >
              {cardData.cta
                ? changeCtaValue(cardData.cta)
                : t("campaign_selectCTA")}
            </button>

            {showCtaOptions && (
              <div>
                <div className="imagecard__ctaoptions--container">
                  {ctaList &&
                    ctaList.map((ctaa) => (
                      <span
                        onClick={() => {
                          let cta = ctaa.value;
                          const object = { ...cardData, cta };
                          console.log(object);
                          setCardData(object);
                          setShowCtaOptions(false);
                          changeMainDataOnBlur(object);
                        }}
                      >
                        {changeCtaValue(ctaa.label)}
                      </span>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "94%",
            marginLeft: "3%",
            marginTop: "10px",
            fontSize: "13px",
            color: "rgb(101, 107, 115)",
            marginTop: "12px",
            fontWeight: "bold",
            fontSize: "10px",
            marginBottom: "5px",
          }}
        >
          <span>
            <img
              style={{ marginTop: "-5px" }}
              src={`${BASE_URL}images/thumb-up.png`}
              alt=""
            />
            {"   "}
            {cardData.likes || 0}
          </span>
          <span
            style={{
              marginLeft: "50%",
            }}
          >
            {cardData.comments || 0}
            {"   "}
            {t("campaign_comments")}
          </span>
          <span
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "100%",
              backgroundColor: "#000",
              display: "inline-block",
              marginTop: "5px",
            }}
          />

          <span>
            {cardData.shares || 0}
            {"   "} {t("campaign_shares")}
          </span>
        </div>

        <div className="imagecard__likes--container">
          <span>
            <img
              src={`${BASE_URL}images/like.png`}
              className="imagecard__img--like"
              alt=""
            />
            {t("campaign_like")}
          </span>
          <span>
            <img
              src={`${BASE_URL}images/comment.png`}
              className="imagecard__img--like"
              alt=""
            />
            {t("campaign_comment")}
          </span>
          <span>
            <img
              src={`${BASE_URL}images/share.png`}
              className="imagecard__img--like"
              alt=""
            />
            {t("campaign_share")}
          </span>
        </div>
        <div
          style={{
            paddingTop: "13px",
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <span
            style={{ marginTop: "18px", marginLeft: "5%", fontWeight: "bold" }}
          >
            {t("campaign_targeting")}:{" "}
          </span>

          <Select
            options={options}
            noOptionsMessage={() => <span>{t("campaign_noAudience")}</span>}
            isMulti
            value={getAudiencesFromIds()}
            className="imagecard__audience--selector"
            placeholder={t("campaign_audience")}
            onChange={(e) => {
              setTimeout(() => {
                let ids = [];
                if (e) e.map((audience) => (ids = [...ids, audience.value]));
                onChange("audiences", ids, e);
              }, 10);
            }}
            onBlur={() => changeMainDataOnBlur()}
          />

          {cardData.errors && cardData.errors.audiences && (
            <SmallErrorText
              style={{
                fontSize: "12px",
                color: "rgb(145, 45, 43)",
                marginLeft: "5%",
                marginTop: "7px",
                display: "block",
                width: "fit-content",
                fontWeight: 500,
                backgroundColor: "#faebd7",
              }}
              text={cardData.errors.audiences}
            />
          )}
        </div>
      </div>

      {notification &&
        cardData.status == "ERROR" &&
        notification.map((notify) => {
          if (
            notify.data.details.object_type == "AdImage" &&
            cardData &&
            cardData.ids.includes(notify.data.details.object_id)
          ) {
            return (
              <Joyride
                steps={[
                  {
                    target: `.imagecard__container__${cardData.unique_id}`,
                    content: `${notify.data.details.message.transalation_key}`,
                  },
                ]}
                hideCloseButton={true}
                styles={{
                  options: {
                    arrowColor: "#8D02FA",
                    primaryColor: "#8D02FA",
                    beaconSize: 100,
                  },
                }}
              />
            );
          }
        })}
    </React.Fragment>
  );
};

export default ImageCard;
