import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";
import axios from "axios";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/dashboard"
const shortBase = VERSION_PREFIX + "/dashboard"

export function onGetDashboardCampaignMetric(brandID, startDate, endDate) {
    return PeasyApi.get(
        shortBase + `/campaign/metric?brand_profile_id=${brandID}&start_date=${startDate}&end_date=${endDate}`
    );
}

export function onGetDashboardLeadMetric(brandID, startDate, endDate) {
    return PeasyApi.get(
        shortBase + `/lead/metric?brand_profile_id=${brandID}&start_date=${startDate}&end_date=${endDate}`
    );
}
