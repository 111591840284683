import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableRow,
  Paper,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Hidden,
} from "@material-ui/core";
import { Delete, Visibility, OpenInNew } from "@material-ui/icons";
import PeasyApi from "../../../utils/peasy.api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { onDeleteCustomAudience } from "../../../utils/network/lib/audience";

const CustomAudienceTable = ({ data, fetchData, brandId, onAction }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(undefined);
  const [customAudience, setCustomAudience] = useState([]);

  useEffect(() => {
    if (data && data.custom_audiences) {
      setCustomAudience(
        data.custom_audiences
          .filter((audience) => audience.type === 1)
          .map((cAudience) => cAudience)
      );
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
    setSelectedAudience(undefined);
  };

  const handleAddRuleButton = async (e) => {
    e.preventDefault();
    onAction("add");
    // history.push(`/brand/custom-audience/${brandId}/add`);
  };

  const handleViewButton = (customAudience) => {
    onAction("view", customAudience.audience_id);
    // history.push(
    //   `/brand/custom-audience/${brandId}/view/${customAudience.audience_id}`
    // );
  };

  const deleteCustomAduience = async (audienceId) => {
    try {
      await onDeleteCustomAudience(audienceId);

      await fetchData();
      toast.success(t("cAudience_deletedSuccessfully"));
      handleClose();
    } catch (e) {
      console.log(e);
      toast.error(e.response.data);
    }
  };

  const handleDeleteButton = async (custom_audience) => {
    if (custom_audience) {
      setSelectedAudience(custom_audience);
      setOpen(true);
    }
  };

  const renderLookalikeCampaigs = (lookalikeCampaigns) => {
    if (lookalikeCampaigns && lookalikeCampaigns.campaigns.length > 0) {
      return (
        <>
          <h3>
            {t("cAudience_pleaseRemove")}{" "}
            <b>{lookalikeCampaigns.lookalike.name}</b>{" "}
            {t("cAudience_lookalikeBeforeDeletingAudience")}
          </h3>
          {lookalikeCampaigns.campaigns.map((campaign) => {
            return (
              <Link target="_blank" to={`/campaign/edit/${campaign.id}`}>
                <OpenInNew />
                {campaign.name}
              </Link>
            );
          })}
        </>
      );
    } else {
      return <>{t("cAudience_sureYouWantToDelete")}</>;
    }
  };
  const srcImg = "/images/assets/Failure.svg";
  return (
    <div className="custom-audience-container">
      <Typography
        component="div"
        variant="h5"
        style={{ paddingTop: "20px", marginBottom: "10px" }}
      >
        {t("cAudience_customAudience")}
      </Typography>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("cAudience_deletingCustomAudience")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {data &&
                selectedAudience &&
                renderLookalikeCampaigs(
                  data.lookalike_campaigns[selectedAudience.audience_id]
                )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("cAudience_cancel")}
            </Button>
            <Button
              onClick={() => deleteCustomAduience(selectedAudience.audience_id)}
              color="primary"
            >
              {t("cAudience_delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Grid container spacing={3}>
        <Hidden mdDown>
          <Grid item md={8} />
        </Hidden>
        <Grid
          item
          xs={12}
          md={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddRuleButton}
          >
            {t("cAudience_createCustomAudience")}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("cAudience_sNo")}</TableCell>
                  <TableCell>{t("cAudience_name")}</TableCell>
                  <TableCell>{t("cAudience_creationDate")}</TableCell>
                  {/* <TableCell>Leads Count</TableCell> */}
                  <TableCell>{t("cAudience_validLeads")}</TableCell>
                  <TableCell>{t("cAudience_inValidLeads")}</TableCell>
                  <TableCell>{t("cAudience_associatedCampaigns")}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {t("cAudience_action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customAudience.length == 0 ? (
                  <TableRow>
                    <TableCell>
                      {t("cAudience_noCustomAudienceAvailable")}
                    </TableCell>
                  </TableRow>
                ) : (
                  data.custom_audiences
                    .filter((audience) => audience.type === 1)
                    .map((custom_audience, i) => (
                      <TableRow key={i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{custom_audience.name}</TableCell>
                        <TableCell>{custom_audience.created_at}</TableCell>
                        <TableCell>{custom_audience.valid_count}</TableCell>
                        <TableCell>{custom_audience.invalid_count}</TableCell>
                        {/* <TableCell>{custom_audience.lead_count}</TableCell> */}
                        <TableCell>
                          {data.lookalike_campaigns[custom_audience.audience_id]
                            ? data.lookalike_campaigns[
                                custom_audience.audience_id
                              ].campaigns.length
                            : 0}
                        </TableCell>

                        <TableCell>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Visibility
                                style={{ cursor: "pointer", float: "right" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewButton(custom_audience);
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Delete
                                style={{ cursor: "pointer", float: "left" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDeleteButton(custom_audience);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomAudienceTable;
