import React, { Fragment } from "react";
import SliderContainer from "../../../../common/Slider";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  centerPadding: "20px",
  arrows: false,
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0
      }
    }
  ]
};

const RetriveStatistics = ({
  swo: { pred_volume, pred_cpa, bud_perc, avg_vol, avg_cpa, budget }
}) => {
  return (
    <Fragment>
      <div class="frame_box_wrap display_desktop_none">
        <SliderContainer config={settings}>
          <div className="parentWrap">
            <div class="frame_box">
              <h1>{pred_volume}</h1>
              <h4>
                Predicted
                <br /> Weekly <br />
                Volume
              </h4>
            </div>
          </div>
          <div className="parentWrap">
            <div class="frame_box green_head">
              <h1>{avg_vol.split(" ")[0]}</h1>
              <h4>
                {avg_vol.split(" ")[1]} than
                <br /> average
                <br /> volume
              </h4>
            </div>
          </div>
          <div className="parentWrap">
            <div class="frame_box">
              <h1>{pred_cpa}</h1>
              <h4>
                Predicted <br />
                Average <br />
                CPA
              </h4>
            </div>
          </div>
          <div className="parentWrap">
            <div class="frame_box green_head">
              <h1>{avg_cpa.split(" ")[0]}</h1>
              <h4>
              {avg_cpa.split(" ")[1]} than
                <br /> average
                <br /> CPA
              </h4>
            </div>
          </div>
          <div className="parentWrap">
            <div class="frame_box">
              <h1>{bud_perc}</h1>
              <h4>
                of total <br />
                campaign budget <br />
                allocated
              </h4>
            </div>
          </div>
          <div className="parentWrap">
            <div class="frame_box green_head">
              <h1>{bud_perc}</h1>
              <h4>
                Recommended <br /> weekly spend
              </h4>
            </div>
          </div>
        </SliderContainer>
      </div>
      <div class="frame_box_wrap mob_dply_none">
        <div class="frame_box">
          <h1>{pred_volume}</h1>
          <h4>
            Predicted
            <br /> Weekly <br />
            Volume
          </h4>
        </div>
        <div class="frame_box">
          <h1>{pred_cpa}</h1>
          <h4>
            Predicted <br />
            Average <br />
            CPA
          </h4>
        </div>
        <div class="frame_box">
          <h1>{bud_perc}%</h1>
          <h4>
            of total <br />
            campaign budget <br />
            allocated
          </h4>
        </div>
        <div class="frame_box green_head">
          <h1>{avg_vol.split(" ")[0]}</h1>
          <h4>
            {avg_vol.split(" ")[1]} than
            <br /> average
            <br /> volume
          </h4>
        </div>
        <div class="frame_box green_head">
          <h1>{avg_cpa.split(" ")[0]}</h1>
          <h4>
          {avg_cpa.split(" ")[1]} than
            <br /> average
            <br /> CPA
          </h4>
        </div>
        <div class="frame_box green_head">
          <h1>{budget}</h1>
          <h4>
            Recommended <br /> weekly spend
          </h4>
        </div>
      </div>
    </Fragment>
  );
};

export default RetriveStatistics;
