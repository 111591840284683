import React from "react";

function AdditionalInformation({ formData, hideRegistration, loginUser }) {
  const { isSuccess, fbId, accesstoken } = formData;
  return (
    <>
      {isSuccess && (
        <>
          <h2 style={{ textAlign: "center" }}>Congrats</h2>
          <br />
          <h3 style={{ textAlign: "center" }}>Your Peasy Account is ready.</h3>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <img
              src="/images/congrats.png"
              alt=""
              width="250px"
              height="250px"
            />
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "40px",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={async () => {
                await loginUser(fbId, accesstoken);
                hideRegistration();
              }}
            >
              Login
            </button>
          </div>
        </>
      )}
      {!isSuccess && (
        <>
          <h3 style={{ textAlign: "center" }}>
            Failed to create your account.
          </h3>
          <div
            style={{
              border: "2px solid grey",
              backgroundColor: "#eeeeeeff",
              margin: "20px",
              padding: "20px",
              borderRadius: "10px",
              fontSize: "20px",
              lineHeight: "30px",
            }}
          >
            Our team has been notified and we will connect with you at the
            earliest.
          </div>
        </>
      )}
    </>
  );
}

export default AdditionalInformation;
