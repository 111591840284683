import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import "./sidebar.css";
import "../translations/i18n";
import { useTranslation } from "react-i18next";
import NotificationsIcon from "@material-ui/icons/Notifications";
import http from "../shared/http-request";

import i18next from "i18next";
import { Button, MenuItem, Menu } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { BASE_URL } from "../StaticArray/StaticArray";
import { toast } from "react-toastify";
import TagManager from "react-gtm-module";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Badge from "@material-ui/core/Badge";
import { DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useSelector } from "react-redux";
import { onEditUser } from "../utils/network/lib/user";
import {
  onGetNotificationByMessageId,
  onGetNotifications,
} from "../utils/network/lib/notifications";
import { onUserLogout } from "../utils/network/lib/auth";

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {},
  drawerPaper: {
    [theme.breakpoints.down("sm")]: {
      width: 260,
      flexShrink: 0,
      backgroundColor: "#6f1ddf",
      color: "white",
    },
  },
  drawerPaper: {
    [theme.breakpoints.up("sm")]: {
      width: 120,
      flexShrink: 0,
      backgroundColor: "#6f1ddf",
      color: "white",
    },
  },
  content: {
    // flexGrow: 1,
    padding: theme.spacing(3),
  },
  menu: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
}));

function ResponsiveDrawer(props) {
  const history = useHistory();
  const location = useLocation().pathname;
  const { Window } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorTranslateEl, setAnchorTranslateEl] = React.useState(null);
  const [anchorSettingsEl, setAnchorSettingsEl] = React.useState(null);
  const [appBarText, setAppBarText] = useState("Dashboard");
  const [dialogState, setDialogState] = useState(false);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [brandProfileId, setBrandProfileId] = useState(null);


  const user = useSelector((state) => state.auth.user);
  

  useEffect(() => {
    if (
      location.includes("/brand/create") ||
      location.includes("/campaign/create-new")
    ) {
      setAppBarText(t("dashboard_questionaire"));
    } else if (location.includes("dashboard")) {
      setAppBarText(t("dashboard"));
    } else if (location.includes("campaign/edit")) {
      setAppBarText(t("dashboard_editCampaign"));
    } else if (location.includes("session/auth")) {
      setAppBarText(t("dashboard_settings"));
    } else if (location.includes("campaign-report")) {
      setAppBarText(t("dashboard_campaignReport"));
    } else if (location.includes("brand/leads")) {
      setAppBarText("Contacts");
    } else {
      setAppBarText("");
    }
  }, [setAnchorSettingsEl]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleTranslateClick = (event) => {
    setAnchorTranslateEl(event.currentTarget);
  };

  const handleTranslateClose = () => {
    setAnchorTranslateEl(null);
  };
  const handleSettingsClick = (event) => {
    setAnchorSettingsEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorSettingsEl(null);
  };
  const getData = (name) => {
    return JSON.parse(localStorage.getItem("user_data"))[name] || "";
  };

  const setUserLanguage = (language) => {
    debugger;
    const args = {
      dataLayer: {
        user_language: language,
        /* can pass more variables here if needed */
      },
    };
    TagManager.dataLayer(args);
    const id = JSON.parse(localStorage.getItem("user_data")).id;
    const formData = { language: language };
    onEditUser(id, formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t("settings_userProfileEdited"));
          localStorage.setItem("user_data", JSON.stringify(res.data.data));
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        if (err.response.data.errors) {
          // setErrors(err.response.data.errors);
        }
      });
  };

  const logout = () => {
    onUserLogout()
      .then((res) => {
        if (res.status === 204) {
          localStorage.removeItem("token_auth");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user_data");
          localStorage.removeItem("prevent_set_user");
          localStorage.removeItem("opened");
          window.location.href = "https://app.peasy.ai/login";
          // window.$peasy.reset();
        }
      })
      .catch((err) => {
        toast.error(t("dashboard_somethingWentWrong"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const getNotifications = () => {
    onGetNotifications()
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getNotification = (msg) => {
    onGetNotificationByMessageId(msg.id)
      .then((res) => {
        setNotifications(res.data);
        history.push(`/${msg.redirect_url}`);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNotifications();
    console.log(notifications);
    // set brand profile id for RMF button
    const brandIdGetter = async() =>{
      const savedCPID = await localStorage.getItem("profile");
      setBrandProfileId(savedCPID ?? null);
    }
    brandIdGetter();
  }, []);

  const rmfNavigator = async() =>{
    const savedCPID = await localStorage.getItem("profile");
    if (savedCPID) {
      history.push(`/rmf/${savedCPID}`);
    }
  }
  
  

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Link to="/" className="nav nav-item">
          <div
            className="navbar-brand"
            style={{
              padding: "0px",
              margin: "0px",
              position: "fixed",
              zIndex: "10",
            }}
          >
            <img
              className="sidebar-logo"
              src="/images/assets/logo.png"
              alt="logo"
            />
          </div>
        </Link>
      </div>
      <Divider />
      <List style={{ fontSize: "12px" }}>
        <Link
          to="/dashboard"
          onClick={() => {
            if (mobileOpen) {
              handleDrawerToggle();
            }
          }}
        >
          <ListItem
            button
            style={{
              textDecoration: "none",
            }}
          >
            <div className="sidebar-nav-item">
              <img src="/images/dashboard-graph-icon.svg" alt="" />
              <p>{t("dashboard")}</p>
            </div>
          </ListItem>
        </Link>
        <ListItem
          button
          aria-controls="settings-menu"
          aria-haspopup="true"
          onClick={handleSettingsClick}
        >
          <div className="sidebar-nav-item">
            <img src="/images/dashboard-settings-icon.svg" alt="" />
            <p>{t("settings")}</p>
          </div>
        </ListItem>
        {/* <ListItem button>
          <div className="sidebar-nav-item">
            <img src="/images/dashboard-e-commerce-icon.svg" alt="" />
            <p>{t("ecommerce")}</p>
          </div>
        </ListItem> */}
        {/* <ListItem button>
          <div className="sidebar-nav-item">
            <img src="/images/dashboard-campaigns-icon.svg" alt="" />
            <p>{t("campaigns")}</p>
          </div>
        </ListItem> */}
        {/* <ListItem button onClick={() => logout()}>
          <div className="sidebar-nav-item">
            <Person />
            <p>{t("dashboard_logout")}</p>
          </div>
        </ListItem>
        <Link
          to={localStorage.profile ? "/brand/leads/"+localStorage.profile : "/brand/leads"}
          onClick={() => {
            if (mobileOpen) {
              handleDrawerToggle();
            }
          }}
        > */}
        <ListItem
          button
          onClick={() =>
            history.push(
              `/brand/leads/${localStorage.profile ? localStorage.profile : ""}`
            )
          }
        >
          <div className="sidebar-nav-item">
            <img src="/images/dashboard-contact-icon.svg" alt="" />
            <p>{t("contacts")}</p>
          </div>
        </ListItem>

        {
          user.active_package?.options?.catalog === "true" && <ListItem button onClick={rmfNavigator}>
            <div className="sidebar-nav-item">
              <img
                style={{ width: "20px", height: "auto",filter: 'invert(80%)' }}
                src="/images/rmf_icon.png"
                alt=""
              />
              <p>{t("dashboard_RMF")}</p>
            </div>
          </ListItem>
        }
        

        {/* <a href=" https://peasy.ai/manual" target="_blank"> */}
        <a href="http://peasy.ai/docs/peasymarketing" target="_blank">
          <ListItem button>
            <div className="sidebar-nav-item">
              <img src="/images/dashboard-help-icon.svg" alt="" />
              <p>{t("help")}</p>
            </div>
          </ListItem>
        </a>

        <Divider style={{ backgroundColor: "B4FF00" }} />
        <ListItem
          button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleTranslateClick}
        >
          <div className="sidebar-nav-item">
            <img
              style={{ width: "20px", height: "auto" }}
              src="/images/dashboard-translate-icon.svg"
              alt=""
            />
            <p style={{ textAlign: "center" }}>{t("translate")}</p>
          </div>
        </ListItem>
        <ListItem button onClick={() => logout()}>
          <div className="sidebar-nav-item">
            <Person />
            <p>{t("dashboard_logout")}</p>
          </div>
        </ListItem>
        
      </List>

      {/* This is the menu for the language support popover */}
      <div>
        <Menu
          // style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          id="simple-menu"
          anchorEl={anchorTranslateEl}
          keepMounted
          open={Boolean(anchorTranslateEl)}
          onClose={handleTranslateClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              handleTranslateClose();
              i18next.changeLanguage("en");
              setUserLanguage("en");
              if (mobileOpen) {
                handleDrawerToggle();
              }
            }}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateClose();
              i18next.changeLanguage("my");
              setUserLanguage("my");
              if (mobileOpen) {
                handleDrawerToggle();
              }
            }}
          >
            Malay
          </MenuItem>
        </Menu>
      </div>
      {/* This is the menu for the settings section */}
      <div>
        <Menu
          id="settings-menu"
          anchorEl={anchorSettingsEl}
          keepMounted
          open={Boolean(anchorSettingsEl)}
          onClose={handleSettingsClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Link
            to="/session/auth/user-profile"
            onClick={() => {
              handleSettingsClose();
              if (mobileOpen) {
                handleDrawerToggle();
              }
            }}
          >
            <MenuItem>{t("settings_userProfile")}</MenuItem>
          </Link>
          {getData("account_type") === "postpaid" && (
            <Link
              to="/session/auth/connection"
              onClick={() => {
                handleSettingsClose();
                if (mobileOpen) {
                  handleDrawerToggle();
                }
              }}
            >
              <MenuItem>{t("settings_connectnSetup")}</MenuItem>
            </Link>
          )}
          <Link
            to="/session/auth/users-teams"
            onClick={() => {
              handleSettingsClose();
              if (mobileOpen) {
                handleDrawerToggle();
              }
            }}
          >
            <MenuItem>{t("settings_usersAndTeam")}</MenuItem>
          </Link>
          <Link
            to="/session/auth/billing"
            onClick={() => {
              handleSettingsClose();
              if (mobileOpen) {
                handleDrawerToggle();
              }
            }}
          >
            <MenuItem>{t("settings_billing")}</MenuItem>
          </Link>
          {
            user.active_package?.options?.apikey === "true" && <Link
              to="/session/auth/api-key"
              onClick={() => {
                handleSettingsClose();
                if (mobileOpen) {
                  handleDrawerToggle();
                }
              }}
            >
              <MenuItem>{t("settings_apiKey")}</MenuItem> 
            </Link>
          }
        </Menu>
      </div>
    </div>
  );

  const container =
    Window !== undefined ? () => Window().document.body : undefined;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden smUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {props.topbar}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {appBarText}
            </Typography>

            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Badge badgeContent={notifications.length} color="primary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Dialog onClose={handleClose} open={open} fullScreen>
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ cursor: "pointer" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <div style={{ marginTop: "50px" }}>
                  <List>
                    {notifications.map((msg) => {
                      var date1 = new Date(msg.created_at);
                      var date2 = new Date();
                      let delta = Math.abs(date1 - date2) / 1000;
                      const days = Math.floor(delta / 86400);
                      delta -= days * 86400;
                      const hours = Math.floor(delta / 3600) % 24;
                      delta -= hours * 3600;
                      const minutes = Math.floor(delta / 60) % 60;

                      return (
                        <>
                          <div
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                            onClick={() => {
                              history.push(`/${msg.redirect_url}`);
                            }}
                          >
                            <h3>
                              <ReactReadMoreReadLess charLimit={60}>
                                {msg.message}
                              </ReactReadMoreReadLess>
                            </h3>
                            <h5 style={{ marginTop: "5px" }}>
                              {`${days}d ${hours}h ${minutes}m `}
                            </h5>
                          </div>
                          <Divider />
                        </>
                      );
                    })}
                  </List>
                </div>
              </DialogContent>
              {/* <DialogActions style={{ justifyContent: "center" }}>
                <Button
                  style={{ borderTop: "1px solid #F0F0F0" }}
                  variant="text"
                  color="primary"
                  size="small"
                  required
                  fullWidth
                  onClick={() => setNotifications([])}
                >
                  Mark All Read
                </Button>
              </DialogActions> */}
              <Divider />
            </Dialog>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main>
        <div />
        {props.main}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  Window: PropTypes.func,
};

export default ResponsiveDrawer;
