import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Feature from "./Feature";


const HocTest = () => {
  return (
    <React.Fragment>
      <Feature featureId={1} featureName="Reach & Impression"/>
      <Feature featureId={2} featureName="Clicks"/>
      <Feature featureId={3} featureName="Traffic"/>
      <Feature featureId={4} featureName="Conversion"/>
      <Feature featureId={5} featureName="Engagement"/>
    </React.Fragment>
  );
};
export default HocTest

