import React from "react";
import { toast } from "react-toastify";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
} from "@material-ui/core";
const CreateDistributionMethod = ({
  value,
  onChange,
  nextPageOnEnter,
  //   category,
  prevStep,
  step,
}) => {
  const { t } = useTranslation();

  const checkEnter = (e) => {
    if (e.keyCode === 13) {
      validate();
    } else {
      toast.error(t("brand_enterDistributn"));
    }
  };

  const validate = () => {
    if (value > 0) return nextPageOnEnter();
    else return toast.error(t("brand_enterDistributn"));
  };

  return (
    <>
      <Box
        className="createBrand-box"
        onKeyDown={(e) => {
          checkEnter(e);
        }}
      >
        <div className="tab-pane col-12 px-0 active" id="Step1">
          <div className="form-group brand-custom-form mt-4 radio">
            <label>{t("brand_selectcustomer")}</label>
            <RadioGroup
              aria-label="distribution-method"
              name="distribution-method"
              value={value.toString()}
              onChange={(e) => {
                onChange("distribution_method", Number(e.target.value));
              }}
            >
              <FormControlLabel
                style={{ marginBottom: "5px" }}
                value="1"
                control={<Radio style={{ fontWeight: "500" }} />}
                label={t("brand_businessToBusiness")}
              />
              <Typography
                style={{
                  marginLeft: "31px",
                  fontSize: "12px",
                  color: "grey",
                  marginBottom: "15px",
                }}
              >
                {t("brand_otherBusiness")}
              </Typography>
              <FormControlLabel
                style={{ marginBottom: "5px" }}
                value="2"
                control={<Radio />}
                label={t("brand_businessToDistributor")}
              />
              <Typography
                style={{
                  marginLeft: "31px",
                  fontSize: "12px",
                  color: "grey",
                  marginBottom: "15px",
                }}
              >
                {t("brand_newDistributors")}
              </Typography>
              <FormControlLabel
                style={{ marginBottom: "5px" }}
                value="3"
                control={<Radio />}
                label={t("brand_businessToConsumer")}
              />
              <Typography
                style={{
                  marginLeft: "31px",
                  fontSize: "12px",
                  color: "grey",
                  marginBottom: "15px",
                }}
              >
                {t("brand_newCustomer")}
              </Typography>
            </RadioGroup>
          </div>
          <div className="d-block">
            <div className="span-text mt-3 d-block">
              {t("brand_enterContinue")}
            </div>
          </div>
        </div>
      </Box>
      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              type="button"
              onClick={() => prevStep()}
              style={{ marginRight: "0px" }}
              className="brandprofilecard__btn--white"
            >
              {t("brand_back")}
            </button>
          </div>
          <div>
            <button
              className="below_navigation_box"
              type="submit"
              className="brandoverviewleftsection__btn--purple"
              onClick={() => validate()}
            >
              {t("brand_next")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDistributionMethod;
