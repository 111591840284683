import React, { Component } from "react";
import "./RightViewMoreModal.css";
import QualificationDropdown from "../QualificationDropdown/QualificationDropdown";
import http from "../../../../shared/http-request";
import { BASE_URL } from "../../../../StaticArray/StaticArray";
import RightViewMoreModalRender from "./RightViewMoreModalRender";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../translations/i18n";
import { withTranslation } from "react-i18next";
import { onGetLead, onUpdateLead } from "../../../../utils/network/lib/lead";

class RightViewMoreModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showView: false,
      isEditOn: false,
      // email: this.state.data.email,
      // campaign_id: this.state.data.campaign_id,
      // created_at: this.state.data.created_at,
      data: null,
    };
  }

  renderRightViewModal = (data) => {
    return "ada";
  };

  showView = (e) => {
    this.setState({ showView: true });
    onGetLead(this.props.data.id)
      .then((res) => {
        console.log(res);
        this.setState({ data: res.data.data, showView: true });
      })
      .catch((err) => {
        console.log(err);
        toast.error(this.props.t("clients_unableToFetchData"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        this.setState({ showView: false });
      });
    // this.setState({  });
  };

  closeView = (e) => {
    this.setState({ showView: false });
  };

  renderQualification = (data) => {
    return this.props.renderQualification(data);
  };

  renderInputFields = (input_name) => {
    if (this.state.isEditOn) {
      return (
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name={input_name}
            value={this.state[input_name]}
            onChange={this.onInputChangeHandler}
          />
        </div>
      );
    } else {
      return this.state.data[input_name];
    }
  };

  showEditView = () => {
    this.setState({ isEditOn: true });
  };

  onInputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onEditFalse = (e) => {
    this.setState({ isEditOn: false });
  };

  onApplyEdit = (e) => {
    this.setState({ isEditOn: false });
    const { email, campaign_id, created_at } = this.state;

    let data = {
      email: email,
      campaign_id: campaign_id,
      created_at: created_at,
    };
    onUpdateLead(this.state.data.id, data)
      .then((res) => {
        this.props.onApplyEdit(res.data.data);
      })
      .catch((err) => {
        console.log("Error occured for ", err);
      });
  };

  onQualificationChangeHandler = (id, data1) => {
    const { data } = this.state;
    let oldData = data;

    this.setState({ data: null });

    onUpdateLead(id, { qualification: data1 })
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          this.showView();
          this.setState({ loadingState: false });
          this.props.onQualificationChangeHandler(res.data.data);
          toast.success(this.props.t("clients_updatedSuccessfully"), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          this.setState({ data: oldData, loadingState: false });
          toast.error(this.props.t("clients_somethingWrongWhileUpdating"), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        // this.loadTable(this.brand_profile_id);
      })
      .catch((err) => {
        this.setState({ data: oldData, date: new Date(), loadingState: false });
        toast.error(this.props.t("clients_somethingWrongWhileUpdating"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        console.log("Error occured for ", err);
      });
    // this.props.onQualificationChangeHandler(id, data)
  };

  componentDidMount() {}

  showView() {}

  render() {
    return (
      <React.Fragment>
        <span
          style={{ cursor: "pointer", color: "#8D02FA" }}
          onClick={this.showView}
        >
          {this.props.t("clients_viewMore")}
        </span>
        {this.state.showView && (
          <RightViewMoreModalRender
            onQualificationChangeHandler={this.onQualificationChangeHandler}
            data={this.state.data}
            closeView={this.closeView}
            renderQualification={this.renderQualification}
          />
        )}
      </React.Fragment>
    );
  }
}
export default withTranslation()(RightViewMoreModal);
