import React, { Component } from "react";
import moment from "moment";
import ChartReportContainer from "./InsightReport";
import RetrieveContainer from "./RetrieveReading";
import CampainTableReport from "./CampaignTable";
import { connect } from "react-redux";
import { getCampaignDetails } from "../../redux/actions/campaignDetailsAction";
import {
  getCampaignMetrics,
  flterCampaignMetrics
} from "../../redux/actions/campaignMetricsAction";

import "./index.css";

class CampaignReportContainer extends Component {
  state = {
    selectedOption: { value: "graph", label: "By Graph" },
    audience: { value: "", label: "All Audiences" },
    channel: { value: "", label: "All Channels" },
    startDate: moment().subtract(10, "days"),
    endDate: moment(),
    calendarFocused: null,
    audiences: [],
    channels: [],
    campaignId: "",
    cpaCamparisonData: [],
    salesVolumeData: []
  };

  componentDidMount() {
    const { location } = this.props;
    let campaignIdLength =
      location.pathname && location.pathname.split("/").length;
    const id =
      location.pathname && location.pathname.split("/")[campaignIdLength - 1];
    this.setState({ campaignId: id }, () => {
      const { campaignId } = this.state;
      this.props.getCampaignDetails("?campaign_id=" + campaignId);
      this.getAudiences();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { campaignMetricData } = nextProps;

    // Handle state change
    if (nextState !== this.state) return true;

    // Handle campaign details data
    if (
      nextProps.campaignDetailsData &&
      nextProps.campaignDetailsData.status === 200
    ) {
      this.setState({
        audiences: nextProps.campaignDetailsData.audiences,
        channels: nextProps.campaignDetailsData.channels
      });
      return true;
    }

    // Handle campaign metric data
    if (campaignMetricData && campaignMetricData.status === 200) {
      if (campaignMetricData !== this.props.campaignMetricData)
        this.setState({
          chartData: campaignMetricData.data.chart_data,
          cpaCamparisonData: campaignMetricData.data.cpa_comparison_data,
          salesVolumeData: campaignMetricData.data.sales_vol_data
        });
      return true;
    }
    return false;
  }

  getAudiences = () => {
    const { startDate, endDate, campaignId, audience, channel } = this.state;
    if (startDate && endDate) {
      let params = "?campaign_id=";
      params += campaignId;
      if (startDate)
        params += "&start_date=" + moment(startDate).format("YYYY-MM-DD");
      if (endDate)
        params += "&end_date=" + moment(endDate).format("YYYY-MM-DD");
     params += `&audience_id=${audience.value}&channel=${channel.value}&all_data=true`;
      this.props.getCampaignMetrics(
        params
        // "?campaign_id=1095&start_date=2020-02-22&end_date=2020-02-26"
      );
    }
  };

  // Handle change data focused
  handleFocus = calendarFocused => {
    this.setState(() => ({ calendarFocused }));
  };

  // Handle dropdown change
  handleChange = (value, name) => {
    this.setState({ [name]: value }, () => {
      this.getAudiences();
    });
  };

  // Handle data change
  handleDataChange = (newStartDate, newEndDate) => {
    // let { startDate, endDate } = this.state;
    // let { campaignMetricData } = this.props;
    this.setState(
      {
        startDate: newStartDate,
        endDate: newEndDate
      },
      () => this.getAudiences()
    );
    // if (
    //   moment(newStartDate).isBetween(
    //     moment(startDate).subtract(1, "days"),
    //     moment(endDate).add(1, "days")
    //   ) &&
    //   moment(newEndDate).isBetween(
    //     moment(startDate).subtract(1, "days"),
    //     moment(endDate).add(1, "days")
    //   )
    // ) {
    //   this.setState(
    //     {
    //       startDate: newStartDate,
    //       endDate: newEndDate
    //     },
    //     () =>
    //       this.props.flterCampaignMetrics({
    //         newStartDate,
    //         newEndDate,
    //         campaignMetricData
    //       })
    //   );
    // } else {

    // }
  };

  render() {
    return (
      <div className="wrapper wdth100">
        <ChartReportContainer
          {...this.state}
          {...this.props}
          handleChange={this.handleChange}
          handleFocus={this.handleFocus}
          handleDataChange={this.handleDataChange}
        />
        <CampainTableReport {...this.state} {...this.props} />
        <RetrieveContainer {...this.state} {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    campaignMetricData: state.campaignMetrics.campaignMetrics,
    campaignDetailsData: state.campaignDetails.campaignDetails
  };
};

export default connect(mapStateToProps, {
  getCampaignDetails,
  getCampaignMetrics,
  flterCampaignMetrics
})(CampaignReportContainer);
