import React, { Component } from 'react'

export default class ShowPredictionCampaign extends Component {

	constructor(props)
	{
		super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
	}

	saveAndContinue(){		
		this.props.nextStep()
	}

	gobackprevious()
	{
		this.props.prevStep()
  	}


  	render () {
    	return (
			<div className="w-100">
				<div className="asdasdasdasd">
					Final Prediection Screen
				</div>
				<div className="navination_bar">
					<div className="bar_search d-flex">
					  <div className="above_navigation ml-auto">
						<button className="btn go_previous" onClick={this.gobackprevious}>
						<img src="/images/down-arrow.png" alt="down" className="previous_process rotate "/>
						</button>
					  </div>
					  <div className="below_navigation">
						<button className="btn go_next disabled_traverse">
						  <img src="/images/down-arrow.png" alt="down" className="next_process"/>
						</button>
					  </div>
					</div>
				 </div>
			</div>
		)
	}
}
