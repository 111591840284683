import React from "react";
import moment from "moment";
import "./PredictionStats.css";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const PredictionStats = ({
  predictionStats,
  campaignData,
  audiences,
  totalVolume,
  predictionVolume,
  eventId,
  salesGoals,
}) => {
  const { t } = useTranslation();

  const getObjective = () => {
    if (campaignData) {
      if (campaignData.objective === "POST_ENGAGEMENT") return "ENGAGEMENT";
      else if (
        campaignData.objective === "TRAFFIC" &&
        campaignData.platform === "MESSENGER"
      )
        return "REPLY";
      else return campaignData.objective;
    } else return "";
  };

  const getDuration = () => {
    if (campaignData) {
      let duration = moment(campaignData.end_date).diff(
        campaignData.start_date,
        "days"
      );
      if (duration > 14) return `${Math.floor(duration / 7)} weeks`;
      else return `${duration} days`;
    } else return "";
  };

  const getConversionRate = () => {
    let result = 0;
    let cpa = 0;
    if (audiences && predictionVolume && predictionVolume.length > 0) {
      for (let i = 0; i < audiences.length; i++) {
        result += predictionVolume[i];
      }
    }

    if (campaignData) {
      cpa = campaignData.budget / result;
      if (campaignData.objective === "IMPRESSION") cpa = cpa * 1000;
    }
    return cpa.toFixed(2);
  };

  const getConversions = () => {
    let result = 0;
    if (audiences && predictionVolume && predictionVolume.length > 0) {
      for (let i = 0; i < audiences.length; i++) {
        result += predictionVolume[i];
      }
    }
    return result.toLocaleString();
  };

  const showPredictionStatMessage = () => {
    if (getObjective() === "SALES") {
      if (eventId === "2") {
        return (
          <>
            {t("campaign_estimatedToProduce")} {getConversions()}{t("campaign_addToCart")} 
             {JSON.parse(localStorage.getItem("user_data")).currency || "MYR"}{" "}
            {getConversionRate()}{t("campaign_perAddToCartWithin")}  {getDuration()}{t("campaign_toAchieve")}  {salesGoals}
            {t("campaign_sales")} 
          </>
        );
      } else if (eventId === "5") {
        return (
          <>
            {t("campaign_estimatedToProduce")} {getConversions()} 
            {t("campaign_landingPageViewAt")}
            {" "}
            {JSON.parse(localStorage.getItem("user_data")).currency || "MYR"}{" "}
            {getConversionRate()} {t("campaign_LandingPageViewWithin")} {getDuration()}{" "}
            {t("campaign_toAchieve")} {salesGoals} {t("campaign_sales")}
          </>
        );
      } else if (eventId === "6") {
        return (
          <>
            {t("campaign_estimatedToProduce")} {getConversions()} {t("campaign_initiateCheckoutAt")}
            {" "}
            {JSON.parse(localStorage.getItem("user_data")).currency || "MYR"}{" "}
            {getConversionRate()} {t("campaign_perInitiateCheckoutWithin")} {getDuration()}{" "}
            {t("campaign_toAchieve")} {salesGoals} {t("campaign_sales")}
          </>
        );
      }
    }
    return (
      <>
        {t("campaign_estimatedToProduce")} {getConversions()} {getObjective()} at{" "}
        {JSON.parse(localStorage.getItem("user_data")).currency || "MYR"}{" "}
        {getConversionRate()} {t("campaign_per")} {getObjective()} {t("campaign_within")} {getDuration()}.
      </>
    );
  };

  return (
    <div className="predictionstats__container">
      <span className="predictionstats__heading">
        {t("campaign_predictn")}</span>
      <span className="predictionstats__span--colored">
      {t("campaign_budgetTargetCampaignAreGood")}
         {getObjective()}?
        {
          // TODO
          <img
            style={{ width: "20px", height: "20px", marginLeft: "5px" }}
            src="/images/assets/rocket.png"
            alt=""
          />
        }
        <span
          className="predictionstats__span--colored"
          style={{
            display: "block",
            marginLeft: 0,
            marginTop: "8px",
            marginBottom: "21px",
          }}
        >
         {t("campaign_uploadAdCreativeAndClick")} 
        </span>
      </span>
      <span className="predictionstats__span">
        {showPredictionStatMessage()}
        {/* This campaign will generate {getConversions()} {getObjective()} at{" "}
        {JSON.parse(localStorage.getItem("user_data")).currency || "MYR"}{" "}
        {getConversionRate()} per {getObjective()} within {getDuration()}. */}
      </span>
    </div>
  );
};

export default PredictionStats;
