import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Objective from "./Objective";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";
import { Box, Grid } from "@material-ui/core";

class objectives extends Component {
  notify() {
    toast(this.props.t("campaign_defaultNotificatn"));

    toast.success(this.props.t("campaign_successNotificatn"), {
      position: toast.POSITION.TOP_CENTER,
    });

    toast.error(this.props.t("campaign_errorNotificatn"), {
      position: toast.POSITION.TOP_LEFT,
    });

    toast.warn(this.props.t("campaign_warningNotificatn"), {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    toast.info(this.props.t("campaign_infoNotificatn"), {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    toast(this.props.t("campaign_customNotificatn"), {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
    });
  }

  constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.submitvalue = this.submitvalue.bind(this);
  }

  saveAndContinue() {
    const { objective } = this.props.state;
    if (objective === "") {
      toast.error(this.props.t("campaign_selectObjective"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      this.props.nextStep();
      toast.dismiss();
    }
  }

  submitvalue(event, value, active_index) {
    this.props.handleChange(event, value);
  }

  render() {
    const { state } = this.props;

    const campaigns = [
      {
        id: 1,
        title: this.props.t("campaign_reachImpression"),
        content: this.props.t("campaign_awarenessBrand"),
        image: "/images/impression.png",
        featureId: 1,
      },
      {
        id: 2,
        title: this.props.t("campaign_clicks"),
        content: this.props.t("campaign_moreClicks"),
        image: "/images/click.png",
        featureId: 2,
      },
      {
        id: 3,
        title: this.props.t("campaign_trafficOrReply"),
        content: this.props.t("campaign_moreVisitOrreplies"),
        image: "/images/traffic.png",
        featureId: 3,
      },
      {
        id: 4,
        title: this.props.t("campaign_conversion"),
        content: this.props.t("campaign_valuableActions"),
        image: "/images/conversion.png",
        featureId: 4,
      },
      {
        id: 5,
        title: this.props.t("campaign_engagement"),
        content: this.props.t("campaign_valuableActions"),
        image: "/images/post_engagement.png",
        featureId: 5,
      },
      {
        id: 6,
        title: this.props.t("campaign_lead"),
        content: this.props.t("campaign_valuableLeads"),
        image: "/images/lead.jpeg",
        featureId: 6,
      },
    ];
    return (
      <div className="outer_div w-100 animated">
        <div
          className="tab-pane col-12 px-0 active border-rounded p-3 mb-2"
          id="step1"
        >
          <div className="row mt-2 object-sec">
            <div className="col-12 mb-3">
              <h2>{this.props.t("campaign_chooseObjectives")}</h2>
            </div>

            <div className="col-12 mb-3">
              <Box>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      overflow: "hidden",
                    }}
                  >
                    <video controls style={{ borderRadius: "5px" }}>
                      <source
                        src="/videos/Objective.mp4"
                        type="video/mp4"
                      ></source>
                      <source src="movie.ogg" type="video/ogg"></source>
                    </video>
                  </div>
                </div>
              </Box>
              <p className="text-center mt-2">
                For more information read{" "}
                <a
                  target="_blank"
                  href="https://peasy.ai/types-of-campaign-objective/"
                  style={{
                    cursor: "pointer",
                    color: "#6f1ddf",
                  }}
                >
                  here.
                </a>
              </p>
            </div>

            {campaigns.map((campaign) => {
              return (
                <Objective
                  facebookFallbackAccount={this.props.facebookFallbackAccount}
                  adwordFallbackAccount={this.props.adwordFallbackAccount}
                  key={campaign.id}
                  className={
                    state.objective === campaign.id
                      ? "col mb-3 active_option"
                      : "col mb-3"
                  }
                  onClick={this.submitvalue}
                  title={campaign.title}
                  content={campaign.content}
                  imagePath={campaign.image}
                  campaign={campaign}
                  tosData={this.props.tosData}
                  brandFunnelType={this.props.brandFunnelType}
                  // featureId={campaign.featureId}
                />
              );
            })}
          </div>
        </div>

        <div className="navination_bar">
          <div className="bar_search d-flex">
            <div className="above_navigation_box ml-auto">
              <button
                className="brandprofilecard__btn--white"
                onClick={() => {
                  this.props.handleChange("funnel_type", 0);
                  this.props.handleQuestionNumber(1);
                }}
              >
                {this.props.t("campaign_back")}
              </button>
            </div>
            <div
              className="below_navigation_box"
              style={{ marginRight: "0px" }}
            >
              <button
                className="brandoverviewleftsection__btn--purple"
                onClick={this.saveAndContinue}
              >
                {this.props.t("campaign_next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(objectives);
