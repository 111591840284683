import React from "react";

const RetriveContainer = ({ swo: { name, channel, interest, lang } }) => (
  <div className="frame_left_col">
    <h2>{name}</h2>
    <div className="frame_sub_head">
      <h4>
        Channel<span>{channel}</span>
      </h4>
    </div>
    <div className="frame_sub_head">
      <h4>Interests</h4>
      <p>
        {interest}
        <a className="show_more">Show more</a>
      </p>
    </div>
    <div className="frame_sub_head">
      <h4>Language</h4>
      <p>{lang}</p>
    </div>
  </div>
);

export default RetriveContainer;
