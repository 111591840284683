import { GET_CAMPAIGN_DETAILS_CONSTANTS } from "../constants";

export default function reducer(
  state = { campaignDetails: null },
  action
) {
  switch (action.type) {
    case GET_CAMPAIGN_DETAILS_CONSTANTS.GET_CAMPAIGN_DETAILS_STARTED: {
      return { ...state, loading: true };
    }
    case GET_CAMPAIGN_DETAILS_CONSTANTS.GET_CAMPAIGN_DETAILS_SUCCESS: {
      const { status, data } = action.payload
      return {
        ...state,
        loading: false,
        campaignDetails: { ...data.data, status }
      };
    }
    case GET_CAMPAIGN_DETAILS_CONSTANTS.GET_CAMPAIGN_DETAILS_FAILED: {
      return {
        ...state,
        loading: false,
        campaignDetails: action.payload
      };
    }
    case GET_CAMPAIGN_DETAILS_CONSTANTS.GET_CAMPAIGN_DETAILS_NET_FAILED: {
      return {
        ...state,
        loading: false,
        campaignDetails: action.payload
      };
    }
    default: {
      return state;
    }
  }
}