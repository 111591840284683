import React from "react";
import "./SearchPost.css";

const SearchPost = ({ setSearchPostQuery, onSearchSubmit, searchPostQuery }) => (
  <div className="searchpost__container">
    <input
      type="text"
      className="searchpost__input"
      onChange={e => setSearchPostQuery(e.target.value)}
      value={searchPostQuery}
    />
    <i className="fas fa-search searchpost__icon" onClick={() => onSearchSubmit()} />
  </div>
);

export default SearchPost;
