import React, { useState, useEffect } from "react";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

import "./PredictionForm.css";
import { toast } from "react-toastify";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

import Joyride from "react-joyride";

const PredictionForm = ({
  predictionFormData,
  setPredictionFormData,
  editCampaignData,
  audiences,
  setInitialFormData,
  setNotification,
  notification,
}) => {
  const { t } = useTranslation();

  const [isStartFocused, setIsStartFocused] = useState(false);

  const [isEndFocused, setIsEndFocused] = useState(false);

  useEffect(() => {
    setDatePickerStyles();
  }, []);

  // set date pickers to disabled until no audiences are there
  useEffect(() => {
    if (!audiences) {
      document.querySelectorAll("input.DateInput_input")[0].disabled = true;
      document.querySelectorAll("input.DateInput_input")[1].disabled = true;
    } else {
      document.querySelectorAll("input.DateInput_input")[0].disabled = false;
      document.querySelectorAll("input.DateInput_input")[1].disabled = false;
    }
  }, [audiences]);

  const setDatePickerStyles = () => {
    document.querySelectorAll(
      "div.SingleDatePicker.SingleDatePicker_1"
    )[0].style.width = "100%";
    document.querySelectorAll(
      "div.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2"
    )[0].style.width = "100%";
    document.querySelectorAll("input.DateInput_input")[0].style.cssText =
      "padding-top: 0px !important; padding-bottom: 0px !important; border: 1px solid #CECBCB; border-radius: 8px; height: 40px; width: 100%; color: #708090; font-weight: bold; font-size: 14px; padding-left: 5%;";

    document.querySelectorAll(
      "div.SingleDatePicker.SingleDatePicker_1"
    )[1].style.width = "100%";
    document.querySelectorAll(
      "div.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2"
    )[1].style.width = "100%";
    document.querySelectorAll("input.DateInput_input")[1].style.cssText =
      "padding-top: 0px !important; padding-bottom: 0px !important; border: 1px solid #CECBCB; border-radius: 8px; height: 40px; width: 100%; color: #708090; font-weight: bold; font-size: 14px; padding-left: 5%;";
  };

  const onChange = (name, value) => {
    const obj = { ...predictionFormData, [name]: value };
    setPredictionFormData(obj);
    window.$peasy.triggerEvent({
      event_name: "info",
      event_params: { id: "10" },
    });
  };

  const onStartDateChange = (date) => {
    console.log("onStartDateChange onStartDateChange");

    if (
      moment(moment().format("YYYY-MM-DD HH:mm:ss")).diff(
        predictionFormData.start_date,
        "days"
      )
    ) {
      setInitialFormData();

      return toast.error(t("campaign_startDateInPastCantChange"));
    }
    if (moment(date).diff(predictionFormData.end_date, "days") > 0) {
      return toast.error(t("campaign_startCantBeAfterEndDate"));
    } else {
      const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
      const object = { ...predictionFormData, start_date: formattedDate };

      setPredictionFormData(object);
    }
  };

  const onEndDateChange = (date) => {
    if (moment(date).diff(predictionFormData.start_date, "days") < 0) {
      return toast.error(t("campaign_endDateCantBeforeStartDate"));
    } else {
      const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
      const object = { ...predictionFormData, end_date: formattedDate };
      setPredictionFormData(object);
    }
  };

  const isBudgetDisabled = () =>{
    // disable budget field when planId = 10 OR prepaid 
    const  userData = JSON.parse(localStorage.getItem("user_data"));
    if ((userData.account_type === 'prepaid') || (userData.active_package === 10)) {
      return true;
    }
    return false;
  }

  return (
    <>
      <form className="predictionform__container">
        <div className="predictionform__input--container">
          <label className="predictionform__label">
            {t("campaign_startDate")}
          </label>
          <div className="campaign_startDate">
            <SingleDatePicker
              readOnly
              numberOfMonths={1}
              date={moment(predictionFormData.start_date)}
              onDateChange={(date) => onStartDateChange(date)}
              focused={isStartFocused}
              onFocusChange={({ focused }) => {
                try {
                  const account_type =
                    JSON.parse(localStorage.getItem("user_data"))[
                      "account_type"
                    ] || "";
                  if (account_type == "prepaid") {
                    window.$peasy.triggerEvent({
                      event_name: "warning",
                      event_params: { id: "2" },
                    });
                  }
                } catch (e) {
                  console.log("start date select event call error", e);
                }
                setIsStartFocused(focused);
              }}
              openDirection="down"
              isOutsideRange={() => false}
            />
          </div>
        </div>

        <div className="predictionform__input--container">
          <label className="predictionform__label">
            {t("campaign_endDate")}
          </label>
          <div className="campaign_endDate">
            <SingleDatePicker
              readOnly
              numberOfMonths={1}
              date={moment(predictionFormData.end_date)}
              onDateChange={(date) => onEndDateChange(date)}
              focused={isEndFocused}
              onFocusChange={({ focused }) => {
                setIsEndFocused(focused);
              }}
              openDirection="down"
              isOutsideRange={() => false}
            />
          </div>
        </div>

        <div className="predictionform__input--container">
          <label className="predictionform__label">
            {t("campaign_budget")}
          </label>
          <div className="budgetInput">
            <input
              id="budgetInput"
              value={predictionFormData.budget}
              onChange={(e) => onChange("budget", e.target.value)}
              className="predictionform__input"
              type="number"
              onBlur={() => editCampaignData()}
              disabled={!audiences || isBudgetDisabled() }
            />
          </div>
        </div>

        <div className="predictionform__input--container">
          <label className="predictionform__label">
            {t("campaign_target")}
          </label>
          <div className="campaign_target">
            <input
              value={predictionFormData.sales_goal}
              onChange={(e) => onChange("sales_goal", e.target.value)}
              className="predictionform__input"
              type="number"
              onBlur={() => editCampaignData()}
              disabled={!audiences}
            />
          </div>
        </div>
      </form>
      {notification &&
        notification.map((notify) => {
          if (notify.data.details.object_type == "Campaign") {
            return (
              <Joyride
                steps={[
                  {
                    target: `.predictionform__container`,
                    content: `${notify.data.details.message.transalation_key}`,
                  },
                ]}
                hideCloseButton={true}
                styles={{
                  options: {
                    arrowColor: "#8D02FA",
                    primaryColor: "#8D02FA",
                    beaconSize: 100,
                  },
                }}
              />
            );
          }
        })}
    </>
  );
};

export default PredictionForm;
