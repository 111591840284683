import React, { useEffect, useState } from "react";
import Axios from "axios";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { onCreateUserSubscription } from "../../../utils/network/lib/user";
import { onGetDefaultPackage } from "../../../utils/network/lib/misc";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ChargeBeeDetails({
  setVisibility,
  formData,
  navigation,
  setForm,
  isFreeUser,
}) {
  let query = useQuery();
  const { email, fullName, mobileNo, id } = formData;
  const { next } = navigation;
  const [packageReference, setPackageReference] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isFreeUser) {
      savePaymentDetails("", true);
      return;
    }
    let pReference = query.get("package");
    if (pReference) {
      setPackageReference(pReference);
      setLoading(false);
      return;
    }
    onGetDefaultPackage()
      .then((res) => {
        setPackageReference(res.data.data.reference);
        setLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const savePaymentDetails = async (paymentId, isFreeUser) => {
    try {
      let payload = {
        user_id: id,
        paymentId: paymentId,
        packageReference: packageReference,
      };
      if (isFreeUser) {
        payload.packageReference = "";
        payload["isfree"] = true;
      }
      const { data, status } = await onCreateUserSubscription(payload);
      if (status == 200) {
        const args = {
          dataLayer: {
            event_custom: "charegbee_plan",
            user_plan: packageReference,
            user_id: id,
            /* can pass more variables here if needed */
          },
        };
        TagManager.dataLayer(args);
        setForm({ target: { name: "isSuccess", value: true } });
        next();
        return;
      }
    } catch (err) {
    } finally {
    }
    toast.error("Failed to create subscription.");
  };

  const initChargebee = () => {
    let chargeBee = window.Chargebee.init({
      site: "peasy",
    });
    let product = chargeBee.initializeProduct(packageReference);
    let cart = chargeBee.getCart();
    let names = fullName.split(" ");
    let customer = {
      email: email,
      first_name: names[0],
      last_name: names[1],
      billing_address: {
        first_name: names[0],
        last_name: names[1],
        company: "",
        phone: mobileNo,
        line1: "",
        line2: "",
      },
    };
    cart.setCustomer(customer);
    cart.replaceProduct(product);
    chargeBee.setCheckoutCallbacks((cart) => {
      // you can define a custom callbacks based on cart object
      return {
        loaded: () => {
          setVisibility(false);
          console.log("checkout opened");
        },
        close: () => {
          setVisibility(true);
        },
        success: (hostedPageId) => {
          setVisibility(true);
          console.log(hostedPageId);
          setForm({ target: { name: "paymentId", value: hostedPageId } });
          //TODO:: call API to save details
          // next();
          savePaymentDetails(hostedPageId);
        },
        step: (value) => {
          // value -> which step in checkout
          console.log("Here", value);
        },
      };
    });
    cart.proceedToCheckout();
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h2>Identify Verification</h2>
        <br />
      </div>
      {loading ? (
        <div
          style={{
            height: "150px",
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, .7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "10",
          }}
        >
          <img
            style={{ width: "100px", height: "100px" }}
            src="/images/assets/loading-deploy.gif"
            alt=""
          />
        </div>
      ) : (
        <>
          <br />
          <div className="connect-acc-list title-label">
            Our systems ask for your card details so that we can verify the card
            holder's identity. There will be no charges made to your credit card
            without your explicit approval. 👏 We do promise.
          </div>
          <br />
        </>
      )}

      <div>
        <br />
        <div style={{ float: "right" }}>
          <button
            className="btn btn-outline-primary"
            disabled={loading}
            onClick={() => {
              initChargebee();
              // next();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default ChargeBeeDetails;
