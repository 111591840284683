import React, { Fragment } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import "../../../../translations/i18n";
import { withTranslation } from "react-i18next";

import VideoThumbnail from "react-video-thumbnail";
import { toast } from "react-toastify";
import { EVENT_TYPE_CREATIVE } from "../../../../utils/eventConstants";

class UploadFile extends React.Component {
  constructor() {
    super();
    this.state = {
      previewImage: [],
      validDropImage: false,
    };
    this.uploadedVideo = [];
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    const { previewImage } = this.state;
    this.props.handleAddClass(previewImage.length + acceptedFiles.length);
    if (previewImage.length + acceptedFiles.length > 10) {
      toast.error(this.props.t("campaign_uploadMin3"));
    } else {
      let isMov = acceptedFiles.filter((f) =>
        f.name.toLowerCase().includes(".mov")
      );

      if (isMov.length > 0) {
        toast.error(this.props.t("campaign_formatNotAccepted"));
        window.$peasy.triggerEvent({
          event_name: "error",
          event_params: {
            id: "11",
            type: EVENT_TYPE_CREATIVE,
            error_msg: "image/video",
          },
        });
        return;
      }

      let isImage = acceptedFiles.filter(
        (r) => r.type.split("/")[0] === "image"
      );
      let isVideo = acceptedFiles.filter(
        (r) => r.type.split("/")[0] === "video"
      );

      if (isImage.length) {
        this.checkFileSize(acceptedFiles);
      }

      if (isVideo.length) {
        // let self = this;
        acceptedFiles.map((file) => {
          if (file.type.split("/")[0] === "video") {
            let oReader = new FileReader();
            oReader.readAsArrayBuffer(file);
            oReader.onload = (e) => {
              console.log("e.target.result", e.target.result);
              let buffer = e.target.result;
              let videoBlob = new Blob([new Uint8Array(buffer)], {
                type: "video/mp4",
              });
              let url = window.URL.createObjectURL(videoBlob);
              if (
                file.type == "video/mp4" ||
                file.type == "video/ogg" ||
                file.type == "video/webm"
              ) {
                var videoElement = document.createElement("video");
                videoElement.preload = "metadata";

                videoElement.addEventListener("loadedmetadata", () => {
                  if (videoElement.duration <= 3600) {
                    var timer = setInterval(() => {
                      if (videoElement.readyState === 4) {
                        this.uploadedVideo = this.state.previewImage;
                        let previewUrl = this.uploadedVideo.concat({
                          preview: URL.createObjectURL(videoBlob),
                          acceptFile: acceptedFiles,
                          blobFile: file,
                          size: file.size,
                          duration: videoElement.duration,
                        });

                        this.uploadedVideo = [
                          ...this.state.previewImage,
                          previewUrl,
                        ];
                        this.setState(
                          {
                            previewImage: previewUrl,
                          },
                          () => {
                            this.uploadedVideo = this.state.previewImage;
                            console.log("");
                          }
                        );

                        clearInterval(timer);
                      }
                    });
                  } else {
                    toast.error(
                      `Video ${file.name} ${this.props.t(
                        "campaign_lessThan60Min"
                      )}`
                    );
                  }
                });
                videoElement.src = url;
              }
            };
          }
        });
      }
    }
  };

  // Image Validation function checking file
  checkFileSize = (acceptedFiles) => {
    let self = this;
    let fileupload = [];
    let imagePreviewUrl = [];

    acceptedFiles.map((file, i) => {
      if (file.type.split("/")[0] === "image") {
        let isValid = false;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          //Initiate the JavaScript Image object.
          let image = new Image();

          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;

          //Validate the File Height and Width.
          image.onload = function () {
            let height = this.height;
            let width = this.width;
            let widthRatio = width / height;
            let heightRatio = height / width;

            if (
              (widthRatio >= 1.91 && width >= 1200 && height >= 628) ||
              (width === height && width >= 1080)
            ) {
              isValid = true;
            } else {
              isValid = false;
            }

            fileupload[i] = isValid;

            if (isValid) {
              const { previewImage } = self.state;
              imagePreviewUrl = [
                ...previewImage,
                {
                  preview: URL.createObjectURL(file),
                  acceptFile: acceptedFiles,
                  blobFile: file,
                  width,
                  height,
                  widthRatio,
                  heightRatio,
                },
              ];

              this.uploadedVideo = imagePreviewUrl;
              self.setState(
                {
                  previewImage: imagePreviewUrl,
                },
                () => {
                  console.log("abc");
                }
              );
            } else {
              toast.error(
                `Image ${file.name} ${self.props.t("campaign_ratioBetween")} `
              );

              window.$peasy.triggerEvent({
                event_name: "error",
                event_params: {
                  id: "9",
                  type: EVENT_TYPE_CREATIVE,
                  error_msg: "image/video",
                },
              });
            }
          };
        };
      }
    });
  };

  handleSubmit = () => {
    const { previewImage } = this.state;
    const { carouselMediaId, isCarouselPopUp } = this.props;
    if (isCarouselPopUp) {
      let carousel = [];
      let SubmitData = [];
      previewImage.map((fileElement, index) => {
        let fileType = fileElement.blobFile.type.split("/")[0];
        if (fileType == "image") {
          carousel.push({
            image: fileElement.blobFile,
            position: index,
            cta: "",
            message: "",
            url: "",
            order: index + 1,
            type: 1,
            width: fileElement.width,
            height: fileElement.height,
            widthRatio: fileElement.widthRatio,
            heightRatio: fileElement.heightRatio,
          });
        } else if (fileType == "video") {
          carousel.push({
            video: fileElement.blobFile,
            position: index,
            cta: "",
            message: "",
            url: "",
            order: index + 1,
            type: 2,
            size: fileElement.size,
            duration: fileElement.duration,
          });
        }
      });
      SubmitData.push(carousel);
      this.props.updateCarouselInternalData(SubmitData, carouselMediaId);
      this.props.carouselPopVisible(false);
    } else {
      let SubmitData = [];
      let carousel = [];
      if (previewImage.length === 1) {
        previewImage.map((fileElement, index) => {
          let fileType = fileElement.blobFile.type.split("/")[0];
          if (fileType == "image") {
            SubmitData.push({
              image: fileElement.blobFile,
              width: fileElement.width,
              height: fileElement.height,
              widthRatio: fileElement.widthRatio,
              heightRatio: fileElement.heightRatio,
            });
            this.props.showImageCard(SubmitData);
          } else if (fileType == "video") {
            SubmitData.push({
              video: fileElement.blobFile,
              size: fileElement.size,
              duration: fileElement.duration,
            });
            this.props.showVideoCard(SubmitData);
          }
        });
        this.props.setIsPopupVisible(false);
      } else if (
        this.props.campaignObjective === "POST_ENGAGEMENT" &&
        previewImage.length > 1
      ) {
        toast.error(this.props.t("campaign_cantAddMoreThan1"));
      } else {
        if (previewImage.length >= 3 && previewImage.length <= 10) {
          previewImage.map((fileElement, index) => {
            let fileType = fileElement.blobFile.type.split("/")[0];
            if (fileType == "image") {
              carousel.push({
                image: fileElement.blobFile,
                position: index,
                cta: "",
                message: "",
                url: "",
                order: index + 1,
                type: 1,
                width: fileElement.width,
                height: fileElement.height,
                widthRatio: fileElement.widthRatio,
                heightRatio: fileElement.heightRatio,
              });
            } else if (fileType == "video") {
              carousel.push({
                video: fileElement.blobFile,
                position: index,
                cta: "",
                message: "",
                url: "",
                order: index + 1,
                type: 2,
                siz: fileElement.size,
                duration: fileElement.duration,
              });
            }
          });
          //console.log('submit data', SubmitData);
          SubmitData.push(carousel);
          this.props.showCarouselCard(SubmitData);
          this.props.setIsPopupVisible(false);
        } else {
          console.log(this.props.campaignObjective);
          toast.error(this.props.t("campaign_uploadMin3Or10"));
        }
      }
    }
  };

  dataURItoBlob(byteString, urlValue) {
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var bb = new Blob([ab]);
    urlValue.thumbnail = bb;
    return bb;
  }

  render() {
    const { previewImage } = this.state;
    const { addClass } = this.props;
    var ms = new MediaSource();
    let singleImageList;
    if (previewImage) {
      singleImageList = previewImage.map((urlValue, i) => {
        let fileType = urlValue.acceptFile[0].type.split("/")[0];
        if (fileType == "image") {
          return (
            <img
              key={i}
              src={urlValue.preview}
              style={{
                marginRight: "6px",
                width: 120,
                height: 100,
                padding: "6px",
                border: "1px solid black",
                marginBottom: "6px",
                verticalAlign: "bottom ",
              }}
            />
          );
        } else if (fileType == "video") {
          return (
            <React.Fragment>
              <video
                key={i}
                width="120"
                height="100"
                controls
                src={urlValue.preview}
                type={urlValue.acceptFile[0].type}
                style={{
                  marginRight: "6px",
                  padding: "6px",
                  border: "1px solid black",
                }}
              />
              <VideoThumbnail
                key={i + "thumb"}
                videoUrl={urlValue.preview}
                thumbnailHandler={(thumbnail) =>
                  this.dataURItoBlob(thumbnail, urlValue)
                }
                width={120}
                height={80}
                renderThumbnail={false}
              />
            </React.Fragment>
          );
        }
      });
    }

    return (
      <div>
        <div style={{ maxHeight: "400px", overflow: "auto" }}>
          <Dropzone
            onDrop={this.onDrop}
            accept="image/*,audio/*,video/*"
            noDragEventsBubbling={true}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames("dropzone", {
                    "dropzone--isActive": isDragActive,
                  })}
                  style={{
                    // height: "142px !important",
                    textAlign: "center",
                    // border: "1px solid black",
                    margin: "14px auto auto auto",
                    // width: "98%",
                  }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p
                      style={{
                        marginBottom: "45px",
                        marginTop: "45px",
                      }}
                    >
                      {this.props.t("campaign_dropFilesHere")}
                    </p>
                  ) : (
                    <>
                      <div className="uploadContent">
                        <div className="dragIcon">
                          <svg
                            width={78}
                            height={78}
                            viewBox="0 0 78 78"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M62.8875 32.63C60.6775 21.4175 50.83 13 39 13C29.6075 13 21.45 18.33 17.3875 26.13C7.605 27.17 0 35.4575 0 45.5C0 56.2575 8.7425 65 19.5 65H61.75C70.72 65 78 57.72 78 48.75C78 40.17 71.3375 33.215 62.8875 32.63ZM45.5 42.25V55.25H32.5V42.25H22.75L39 26L55.25 42.25H45.5Z"
                              fill="#8D00FA"
                            />
                          </svg>
                        </div>
                        <h6>{this.props.t("campaign_dragAndDrop")}</h6>
                        <p>
                          {this.props.t("campaign_or")}{" "}
                          <span className="highLight">
                            {this.props.t("campaign_browse")}
                          </span>{" "}
                          {this.props.t("campaign_toChooseFile")}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              );
            }}
          </Dropzone>
          <div className="uploadDetails">
            <div className="row">
              <div className="col-md-4">
                <div className="uploadDcontent">
                  <div className="uploadCicon">
                    <svg
                      width={47}
                      height={48}
                      viewBox="0 0 47 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.9188 4.78381H11.6756C9.53504 4.78381 7.78369 6.53516 7.78369 8.67571V35.9189C7.78369 38.0595 9.53504 39.8108 11.6756 39.8108H38.9188C41.0594 39.8108 42.8107 38.0595 42.8107 35.9189V8.67571C42.8107 6.53516 41.0594 4.78381 38.9188 4.78381ZM38.9188 35.9189H11.6756V8.67571H38.9188V35.9189ZM29.1113 22.8617L23.7599 29.7503L19.9459 25.1579L14.5945 32.0271H35.9999L29.1113 22.8617Z"
                        fill="#8D00FA"
                      />
                    </svg>
                  </div>
                  <div className="uploadcontentdetails">
                    <h5>{this.props.t("campaign_imgAds")}</h5>
                    {/* <p>- Upload 1 jpg or png file type</p>
                  <p>- Image ratio: 1.91:1 to 1:1</p>
                  <p>- Maximum file size: 10MB</p>
                  <p>
                    - Acceptable resolutions: 1,200 x 628px OR 1,080 x 1,080px
                  </p> */}
                    <p>
                      - Image: (IG, FB, Google) 1:1{" "}
                      {this.props.t("campaign_ratioAndMin")} 1080x1080px.
                    </p>
                    <p>
                      - (FB, Google) 1.91:1{" "}
                      {this.props.t("campaign_ratioAndMin")} 1200x628
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="uploadDcontent">
                  <div className="uploadCicon">
                    <svg
                      width={47}
                      height={47}
                      viewBox="0 0 47 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.9188 3.89191H11.6756C9.53504 3.89191 7.78369 5.64326 7.78369 7.7838V35.027C7.78369 37.1676 9.53504 38.9189 11.6756 38.9189H38.9188C41.0594 38.9189 42.8107 37.1676 42.8107 35.027V7.7838C42.8107 5.64326 41.0594 3.89191 38.9188 3.89191ZM38.9188 35.027H11.6756V7.7838H38.9188V35.027ZM29.1113 21.9697L23.7599 28.8584L19.9459 24.266L14.5945 31.1352H35.9999L29.1113 21.9697Z"
                        fill="#8D00FA"
                      />
                    </svg>
                  </div>
                  <div className="uploadcontentdetails">
                    <h5>{this.props.t("campaign_vdoAds")}</h5>
                    {/* <p>
                    - Upload 1 video file. Video file type: Supported file
                    formats
                  </p>
                  <p>- Maximum file size: 200MB</p>
                  <p>- Recommended maximum durations: 15 seconds</p>
                  <p>- Video Ratio: 16:9 to 9:16, 16:9 recommended</p> */}
                    <p>- {this.props.t("campaign_recommendMaxDuratn")}</p>
                    <p>- (FB) {this.props.t("campaign_maxDuration")}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="uploadDcontent">
                  {this.props.campaignObjective !== "POST_ENGAGEMENT" ? (
                    <>
                      <div className="uploadCicon">
                        <svg
                          width={47}
                          height={47}
                          viewBox="0 0 47 47"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M38.9188 3.89191H11.6756C9.53504 3.89191 7.78369 5.64326 7.78369 7.7838V35.027C7.78369 37.1676 9.53504 38.9189 11.6756 38.9189H38.9188C41.0594 38.9189 42.8107 37.1676 42.8107 35.027V7.7838C42.8107 5.64326 41.0594 3.89191 38.9188 3.89191ZM38.9188 35.027H11.6756V7.7838H38.9188V35.027ZM29.1113 21.9697L23.7599 28.8584L19.9459 24.266L14.5945 31.1352H35.9999L29.1113 21.9697Z"
                            fill="#8D00FA"
                          />
                        </svg>
                      </div>
                      <div className="uploadcontentdetails">
                        <h5>{this.props.t("campaign_carousalAds")}</h5>
                        {/* <p>
                    - Upload minimum 3 images or video files. Maximum 10 files
                  </p>
                  <p>- Image file type: jpg or png</p>
                  <p>- Video file type: Supported file formats</p> */}
                        <p>- 3-10 slides</p>
                        <p>- {this.props.t("campaign_IgRequiresRatio")}</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "98%",
              margin: "10px auto auto auto",
            }}
          >
            <ul className={addClass ? "datatSortable" : ""}>
              {previewImage.length
                ? previewImage.map((data, index) => {
                    let fileType = data.blobFile.type.split("/")[0];
                    if (fileType === "image") {
                      return (
                        <li key={index} className="uplaod-preview">
                          <img src={data.preview} alt="image preview" />
                        </li>
                      );
                    } else {
                      return (
                        <li key={index} className="uplaod-preview">
                          <video className="" autoPlay={true} loop muted={true}>
                            <source src={data.preview} type="video/mp4" />
                          </video>
                        </li>
                      );
                    }
                  })
                : ""}
            </ul>
          </div>
        </div>
        <ul className="modalFooterBtn">
          <li>
            <button
              onClick={() => {
                this.props.setCardsStepPost(null);
                this.props.setIsPopupVisible(false);
                this.props.carouselPopVisible(false);
              }}
              className="btn btn-outline-primary"
            >
              {this.props.t("campaign_cancel")}
            </button>
          </li>
          <li>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              {this.props.t("campaign_save")}
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default withTranslation()(UploadFile);
