import React, { useEffect, useState } from "react";
import Parser from "rss-parser";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import Articles from "./Articles";
import HorizontallScroll from "react-horizontal-scrolling";

const RSS = () => {
  let parser = new Parser({
    customFields: {
      item: ["title", "description", "link"],
    },
  });
  const [feed, setFeed] = useState({});
  useEffect(() => {
    (async () => {
      let feedData = await parser.parseURL(
        `${BASE_URL}api/v1/dashboard/rss/feed`
      );
      setFeed(feedData);
    })();
  }, []);
  return (
    <>
      {Object.keys(feed).length > 0 && (
        <HorizontallScroll>
          {feed.items.map((article, i) => (
            <Articles
              key={i}
              title={article.title}
              link={article.link}
              description={article.description}
            />
          ))}
        </HorizontallScroll>
      )}
    </>
  );
};

export default RSS;
