import { Box } from "@material-ui/core";
import { array } from "prop-types";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const CreateBrandPixels = ({
  fbevent,
  pixel_id,
  onChange,
  nextPageOnEnter,
  prepaid_fb_account_id,
  prevStep,
}) => {
  const { t } = useTranslation();

  const [pixelId, setPixelId] = useState(null);

  const [selectedPixelIds, setSelectedPixelIds] = useState([]);

  // useEffect(() => {
  //   http.get(`${BASE_URL}api/v1/getPixels/${prepaid_fb_account_id}`)
  //   .then(res => {
  //     if (res.data.data.pixelList.length === 0) nextPageOnEnter();
  //     else return setPixelEvents(res.data.data.pixelList)
  //   })
  //   .catch(err => toast.error(err.response.data.message))
  // }, []);

  // useEffect(() => {
  //   checkSelectedPixels();
  // }, [pixelEvents]);

  useEffect(() => {
    console.log(fbevent);
    let array = [];
    Object.keys(fbevent).map((pixel, index) => {
      array = [...array, [fbevent[pixel].name, fbevent[pixel].pixel_id]];
    });
    console.log(array);
    setSelectedPixelIds(array);
  }, [fbevent]);

  // `${fbevent[pixel].name} (${fbevent[pixel].pixel_id})`

  // checks if the pixels already present in the state
  // are in the pixel list coming from facebook
  // if not then deletes those pixels from the state

  // const checkSelectedPixels = () => {
  //   if (fbevent.length > 0 && pixelEvents.length > 0) {
  //     let dummyArray = [...fbevent];
  //     for (let i = 0; i < fbevent.length; i++) {
  //       let isInsideList = false;
  //       for (let j = 0; j < pixelEvents.length; j++) {
  //         if (pixelEvents[j].pixel_id === fbevent[i].pixel_id) {
  //           isInsideList = true;
  //           dummyArray.splice(i, 1, pixelEvents[j]);
  //           break;
  //         }
  //       }
  //       if (!isInsideList) {
  //         dummyArray.splice(i, 1, "");
  //       }
  //     }
  //     const filteredArray = dummyArray.filter((dummy) => dummy !== "");
  //     onChange("prepaid_pixel_events", filteredArray);
  //   }
  // };

  const getPixelNames = () => {
    let string = "";
    var selected = selectedPixelIds.map((pixel) => {
      if (pixel[1] === pixel_id) {
        string = string + `${pixel[0] + " " + pixel[1]},`;
        return string;
      }
    });
    return selected;
  };

  const selectPixel = (pixel) => {
    onChange("pixel_id", pixel[1]);
  };

  const onNextClick = () => {
    if (pixel_id.length === 0) return toast.error(t("brand_selectPixel"));
    nextPageOnEnter();
  };

  return (
    <React.Fragment>
      <Box className="createBrand-box">
        <div
          style={{ minHeight: "60px" }}
          className="tab-pane col-12 px-0 active "
          id="Step2"
        >
          <div className="form-group brand-custom-form brand-custom-dropdown mt-4">
            <label>{t("brand_pixelEvent")}</label>
            <p className="question-description mb-2">
            {t("brand_pixelDomainEventText")}{" "}
              <a
                target="_blank"
                href="https://peasy.ai/how-to-setup-your-facebook-pixel-and-event/"
                style={{ color: "#6f1ddf" }}
              >{t("brand_here")}
                
              </a>
            </p>

            <button
              type="button"
              className="form-control brand-dropdown"
              data-toggle="collapse"
              data-target="#catefory"
            >
              {pixel_id && pixel_id.length > 0
                ? getPixelNames()
                : t("brand_pixelBrand")}
              <i className="fa fa-caret-down float-right" />
            </button>
            <div id="catefory" className="collapse">
              <ul className="list-unstyled" id="facebook_adds">
                {selectedPixelIds.length > 0 &&
                  selectedPixelIds.map((val) => (
                    <li
                      onClick={() => {
                        selectPixel(val);
                        nextPageOnEnter();
                      }}
                      className="custom-control custom-radio dropdown-item"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        value={pixel_id}
                        checked={pixel_id && val[1] === pixel_id}
                      />
                      <label className="custom-control-label">
                        {val[0] + " " + val[1]}
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Box>

      <div style={{ left: "90%" }} className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              onClick={() => prevStep()}
              className="brandprofilecard__btn--white"
            >
              {t("brand_back")}
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              className="brandoverviewleftsection__btn--purple"
              onClick={() => onNextClick()}
            >
              {t("brand_next")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateBrandPixels;
