import { useState } from 'react';
import React from 'react';
import { Box, Button, FormControl, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 90,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  paper: {
    padding: theme.spacing(2),
    width: 500,
    // height: 600,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    '&.Mui-disabled':{
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.disabled
    },
  },
  edit_input_style:{
    display:"grid",
    gridTemplateColumns:"repeat(2,1fr)",
    gap:"10px"
  }
}));

export default function ProductEditModal({editProductInfo,setEditProductInfo}) {
  const classes = useStyles();
  const handleChange = (event) => {
    console.log(event.target.value );
  };
  console.log(editProductInfo,"editProductInfo");
  const handleInputChange = (e) => {
    if (e.target.value.length <= 255) {
      // setEditProductInfo({...editProductInfo,[e.target.name]:e.target.value})
    }
  };
  // title,description,price,currency,condition,availability,link,quantity,integration
  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
            <div className='d-flex justify-content-between py-2'>
                <Typography variant="h6">Editor</Typography>
                <Button onClick={()=>setEditProductInfo({})} variant="contained" color="primary">X</Button>
            </div>
            <TextField
                fullWidth
                className={classes.textField}
                label="Title"
                name="title"
                variant="outlined"
                size="small"
                value={editProductInfo.title}
                InputLabelProps={{ shrink: editProductInfo.title ? true : false }}
                InputProps={{maxLength:255}}
                onChange={handleInputChange}
                error={editProductInfo.title.length === 0 || editProductInfo.title.length > 255}
                helperText={editProductInfo.title.length === 0 ? 'Title is required.' : editProductInfo.title.length > 255 ? 'Title cannot be longer than 255 characters.' : ''}
            />
          <Box className={classes.edit_input_style}>
            <TextField
                fullWidth
                className={classes.textField}
                label="Quantity"
                variant="outlined"
                size="small"
                value={editProductInfo.quantity}
                InputLabelProps={{ shrink: editProductInfo.quantity > -1 ? true : false }}
                />
            <TextField
                fullWidth
                className={classes.textField}
                label="Price"
                variant="outlined"
                size="small"
                value={editProductInfo.price}
                InputLabelProps={{ shrink: editProductInfo.price ? true : false }}
            />
            <FormControl fullWidth size="small" >
                <InputLabel id="demo-simple-select-label0">Condition</InputLabel>
                <Select
                    labelId="demo-simple-select-label0"
                    id="demo-simple-select0"
                    value={editProductInfo.condition || ""}
                    InputLabelProps={{ shrink: editProductInfo.condition ? true : false }}
                    label="condition"
                    onChange={handleChange}
                >
                    <MenuItem value={'new'}>New</MenuItem>
                    <MenuItem value={'used'}>Used</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth size="small" className={classes.textField}>
                <InputLabel id="demo-simple-select-label1">Availability</InputLabel>
                <Select
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={editProductInfo.availability || ''}
                InputLabelProps={{ shrink: editProductInfo.availability ? true : false }}
                label="condition"
                onChange={handleChange}
                MenuProps={{MenuListProps: { disablePadding: true },PaperProps: {
                    style: {
                      pointerEvents:  "auto" ,
                    },
                  },}}
                >
                    <MenuItem value={'in stock'}>In Stock</MenuItem>
                    <MenuItem value={'hidden'}>Hidden</MenuItem>
                </Select>
            </FormControl> 
          </Box>
          <div className='d-flex'>
            <Typography className='mr-2' variant="subtitle1" gutterBottom>Integration: </Typography>
            <div>
                <Typography className='mr-2' variant="body1" gutterBottom>Facebook, Wocommerce: </Typography>
            </div>
          </div>
            <TextField
                style={{flexGrow:1}}
                className={classes.textField}
                label="Enter URL"
                variant="outlined"
                fullWidth
                size="small"
                value={editProductInfo.link}
                InputLabelProps={{ shrink: editProductInfo.link ? true : false }}
                // onChange={handleUrlChange}
                // error={error}
                // helperText={error ? "Invalid URL format" : ""}
            />

            <TextField
                fullWidth
                multiline
                rows={10}
                label="description"
                variant="outlined"
                size="small"
                value={editProductInfo.description}
                InputLabelProps={{ shrink: editProductInfo.description ? true : false }}
            />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={true}
          >
            Save
          </Button>
        </Paper>
    </div>
  );
}
