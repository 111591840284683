import { Typography, List, ListItem } from "@material-ui/core";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { InfiniteScrollLoader } from "../../../shared/InfiniteScroll/InfiniteScroll";
import { useTranslation } from "react-i18next";
import { Divider } from "@material-ui/core";

const CheckoutForm = ({
  amount,
  currency,
  successCallback,
  errorCallback,
  toppAmount,
}) => {
  const [statusMessage, setStatusMessage] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const options = { business: { name: "Peasy" } };
  console.log(toppAmount);
  const handleSubmit = async (event) => {
    setStatusMessage("Processing...");
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
       confirmParams: {
      return_url:window.location.href+"?callback=payment"
      }
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      setStatusMessage("Error Occured, redirecting...");
      errorCallback();
      console.log(result.error);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        setStatusMessage("Success redirecting...");
        successCallback();
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">{t("base_amount")}</Typography>
          <Typography variant="body2">
            {currency} {parseFloat(toppAmount).toFixed(2)}
          </Typography>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Typography variant="body2">{t("tax_amount")}</Typography>
          <Typography variant="body2">
            {currency}{" "}
            {(parseFloat(amount) - parseFloat(toppAmount)).toFixed(2)}
          </Typography>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "12px",
          }}
        >
          <Typography variant="body1">{t("amount_with_tax")}</Typography>
          <Typography variant="body1">
            {currency} {parseFloat(amount).toFixed(2)}
          </Typography>
        </div>
      </span>
      <Divider /> {loading && <InfiniteScrollLoader />}
      <br />
      <PaymentElement
        options={options}
        onReady={() => {
          setLoading(false);
        }}
      />
      <br />
      <button className={`btn btn-primary`}>Pay Now</button>
      <br />
      <span>{statusMessage}</span>
    </form>
  );
};

export default CheckoutForm;
