import React, { Component } from "react";
import Campaign from "./Campaign";

class StatisticsContainer extends Component {
  render() {
    return (
      <div className="dash_box_row width100">
        <div className="slider-row stats-slider">
          <Campaign {...this.props} />
        </div>
      </div>
    );
  }
}

export default StatisticsContainer;
