/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import "../../css/webflow.scss";
import { toast } from "react-toastify";
import RegistrationForm from "./RegistrationForm";

const RegisterPage = (props) => {
  toast.configure({
    position: toast.POSITION.BOTTOM_LEFT,
  });

  return (
    <React.Fragment>
      <div className="webflow-register">
        <nav className="navbar navbar-expand-lg">
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <div>
              <a
                href="/"
                className="navbar-brand"
                style={{ paddingTop: "0px" }}
              >
                <img
                  src="images/assets/logo.png"
                  alt="logo"
                  style={{ height: "100%" }}
                />
              </a>
            </div>
            <div style={{ marginTop: "18px", marginRight: "25px" }}>
              <RegistrationForm />
            </div>
          </div>
          {/* <div className="nav nav-item">
            <a
              href="/"
              className="navbar-brand"
              style={{ paddingTop: "0px", width: "200px", float: "left" }}
            >
              <img src="images/assets/logo.png" alt="logo" />
            </a>
          </div>
          <div style={{ position: "fixed", right: "20px" }}>
            <RegistrationForm />
          </div> */}
          {/* <div className="container-fluid container-fluid-negative-margin">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="nav nav-item">
                <a
                  href="/"
                  className="navbar-brand"
                  style={{ paddingTop: "0px" }}
                >
                  <img src="images/assets/logo.png" alt="logo" />
                </a>
              </div>
              <div style={{ justifySelf: "right" }}>
                <RegistrationForm />
              </div>
            </div>
          </div> */}
        </nav>

        <div
          className="container-fluid basic-layout"
          style={{ marginTop: "0px", paddingLeft: "0px" }}
        >
          <div>
            <div>
              <div className="hero-section">
                <div className="hero-wrapper">
                  <h1 className="title-headline">Simplify Digital Marketing</h1>
                  <p className="paragraph-2">
                    Peasy improves your Facebook and Google campaigns by 220% in
                    3 ways.
                  </p>
                  <a href="#" className="link-block w-inline-block">
                    <img
                      src="images/arrow-down.png"
                      alt=""
                      className="image-30"
                      data-ix="new-interaction"
                    />
                  </a>
                </div>
                <img
                  src="images/rocket.png"
                  srcSet="images/rocket-p-500.png 500w, images/rocket.png 600w"
                  sizes="(max-width: 479px) 83vw, (max-width: 767px) 40vw, 30vw"
                  alt=""
                  className="image-31"
                />
              </div>
              <div className="container-2 w-container">
                <div id="first-content" className="lead-section">
                  <h4 className="title">
                    1. All of your Facebook & Google Campaigns in 1 centralized
                    dashboard
                  </h4>
                  <p className="paragraph">
                    Setup and manage your Facebook & Google campaigns from
                    Peasy. Let Peasy recommend your media plan, setup the
                    campaign structures, distribute and control budget across
                    all channels.
                  </p>
                  <div
                    style={{ paddingTop: "56.17021276595745%" }}
                    id="w-node-bf4e70dd1c99-d95ee666"
                    className="w-video w-embed"
                  >
                    <iframe
                      className="embedly-embed"
                      src="https://www.youtube.com/embed/RSxTBht1Ov0"
                      scrolling="no"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen={true}
                    ></iframe>
                  </div>
                  <div className="feature-box-container">
                    <div className="feature-box">
                      <h5 className="feature-title">4 Simple steps</h5>
                      <div className="line"></div>
                      <p>
                        Simply input your campaign information, audience, budget
                        & creatives.
                      </p>
                    </div>
                    <div
                      id="w-node-bf4e70dd1ca0-d95ee666"
                      className="feature-box"
                    >
                      <h5 className="feature-title">
                        Automated Channel Configuration
                      </h5>
                      <div className="line"></div>
                      <p>
                        Just setup your campaign once. Peasy will configure it
                        on FB, IG & Google automatically.
                      </p>
                    </div>
                    <div className="feature-box">
                      <h5 className="feature-title">
                        Automated Audience Research
                      </h5>
                      <div className="line"></div>
                      <p>
                        Liberate yourself from tedious audience research. Peasy
                        Automated Audience Recommendation will create the right
                        audience targeting criteria based on your goal.
                      </p>
                    </div>
                    <div
                      id="w-node-bf4e70dd1caa-d95ee666"
                      className="feature-box"
                    >
                      <h5 className="feature-title">
                        Multi-channel ads in 1 platform
                      </h5>
                      <div className="line"></div>
                      <p>
                        Upload FB, IG & Google ad formats in 1 single platform.
                        Peasy will distribute it to the right channels.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lead-section">
                  <h4 className="title">
                    2. Achieve consistent Performance. Easily
                  </h4>
                  <p className="paragraph">
                    Getting your campaigns to perform has never been easier.
                    Peasy AI will analyze your data and provide results
                    prediction even before you begin. Once you’re satisfied with
                    Peasy’s prediction, leave the rest to our AI to tirelessly
                    monitor and deliver the promised performance.
                  </p>
                  <div
                    style={{ paddingTop: "56.17021276595745%" }}
                    id="w-node-c68f6e884463-d95ee666"
                    className="w-video w-embed"
                  >
                    <iframe
                      className="embedly-embed"
                      src="https://www.youtube.com/embed/dbfBwXunPbY"
                      scrolling="no"
                      framebBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen={true}
                    ></iframe>
                  </div>
                  <div className="feature-box-container">
                    <div className="feature-box">
                      <h5 className="feature-title">Audience Optimization</h5>
                      <div className="line"></div>
                      <p>
                        Expand your audience when volume is low and split test
                        when CPX is high.
                      </p>
                    </div>
                    <div className="feature-box">
                      <h5 className="feature-title">Channel Optimization</h5>
                      <div className="line"></div>
                      <p>
                        Peasy will automatically move budget from non-performing
                        to performing channels.
                      </p>
                    </div>
                    <div
                      id="w-node-c68f6e88446f-d95ee666"
                      className="feature-box"
                    >
                      <h5 className="feature-title">Bid Optimization</h5>
                      <div className="line"></div>
                      <p>
                        Peasy continuously monitors your ad auction, and adjust
                        bid to maximize your ROI.
                      </p>
                    </div>
                    <div
                      id="w-node-c68f6e884474-d95ee666"
                      className="feature-box"
                    >
                      <h5 className="feature-title">
                        Placement/Keyword Optimization
                      </h5>
                      <div className="line"></div>
                      <p>
                        Peasy will analyze your auction or placement to remove
                        fraudulent inventory, move budget from non-performing to
                        performing inventory.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lead-section">
                  <h4 className="title">
                    3. Integrated and holistic Reporting
                  </h4>
                  <p className="paragraph">
                    Peasy integrate data across your Facebook & Campaigns to
                    provide you with holistic insights. From the key, distilled
                    insights such as best customer segments, ideal path to
                    conversion, best performing messaging TO detailed reporting.
                  </p>
                  <div
                    style={{ paddingTop: "56.17021276595745%" }}
                    id="w-node-43eeca131bb8-d95ee666"
                    className="w-video w-embed"
                  >
                    <iframe
                      className="embedly-embed"
                      src="https://www.youtube.com/embed/NivJS3nbzVs"
                      scrolling="no"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen={true}
                    ></iframe>
                  </div>
                  <div className="feature-box-container">
                    <div className="feature-box">
                      <h5 className="feature-title">Strength</h5>
                      <div className="line"></div>
                      <p>
                        Who are your best segments? Which placement, channel,
                        timing and messaging resonate strongly with these
                        segments.
                      </p>
                    </div>
                    <div
                      id="w-node-c8062567ef06-d95ee666"
                      className="feature-box"
                    >
                      <h5 className="feature-title">Opportunities</h5>
                      <div className="line"></div>
                      <p>
                        Which sizeable customer segments will respond better to
                        your campaign if you implement Peasy’s recommendations.
                      </p>
                    </div>
                    <div className="feature-box">
                      <h5 className="feature-title">Weaknesses</h5>
                      <div className="line"></div>
                      <p>
                        Customer profiles who should be your furthest target to
                        scale.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lead-section">
                  <h1 className="heading-14">
                    Testimonial from our happy customers
                  </h1>
                  <div className="sign-up-testimony-box">
                    <div className="sign-up-testimony-container">
                      <p className="testimony-text">
                        "I have been struggling for product inquiries for years.
                        However, this story has ended after I met Peasy.ai.
                        Peasy opens the floodgate of enquiry to my business in
                        just a 2 days. It's time to cooperate with AI,
                        especially Peasy.ai"
                      </p>
                      <h5 className="testimony-name">
                        Allan, founder of health supplement e-commerce
                      </h5>
                    </div>
                    <div className="sign-up-testimony-container">
                      <p className="testimony-text">
                        "We manage to streamline our operation thanks to
                        Peasy.ai. Our campaign managers can spend less time on
                        ad operations and more time with customer servicing"
                      </p>
                      <h5 className="testimony-name">
                        Justin, founder of digital agency
                      </h5>
                    </div>
                    <div className="sign-up-testimony-container">
                      <p className="testimony-text">
                        "I didn’t thought of trying Google ads before. But Peasy
                        recommend to divide the budget between Facebook and
                        Google. We gave it a try and order for our biscuits went
                        up so much, our oven cannot catch up."
                      </p>
                      <h5 className="testimony-name">
                        Anis, founder of confectionery e-commerce
                      </h5>
                    </div>
                  </div>
                  <div className="lead-container">
                    <h3 className="heading-11">Our Partners</h3>
                    <div className="column wrap center">
                      <img
                        src="images/awspartner.png"
                        alt=""
                        className="image-partners"
                      />
                      <img
                        src="images/adwordsAPI.png"
                        alt=""
                        className="image-partners"
                      />
                      <img
                        src="images/facebookpartner.png"
                        alt=""
                        className="image-partners"
                      />
                      <img
                        src="images/instagram-partner.png"
                        alt=""
                        className="image-partners"
                      />
                      <img
                        src="images/appsflyer.png"
                        alt=""
                        className="image-partners"
                      />
                      <img
                        src="images/GA-partner.png"
                        alt=""
                        className="image-partners"
                      />
                    </div>
                  </div>
                  <div className="div-block-69">
                    <h1 className="heading-12">Supported Formats</h1>
                    <div className="big-case-study-platform-2">
                      <div className="columns-9 w-row">
                        <div className="w-col w-col-4 w-col-stack">
                          <div className="platform_wrapper-2">
                            <div className="div-block-64">
                              <div className="channel-title">
                                <img
                                  src="images/facebook_1facebook.png"
                                  width="34"
                                  height="34"
                                  alt=""
                                  className="image-11"
                                />
                                <h1 className="m bold">Facebook</h1>
                              </div>
                              <div className="platform">
                                <img src="images/tick_1tick.png" alt="" />
                                <h1 className="s bottom-0 left-16">
                                  Single Image
                                </h1>
                              </div>
                              <div className="platform">
                                <img src="images/tick_1tick.png" alt="" />
                                <h1 className="s bottom-0 left-16">Carousel</h1>
                              </div>
                              <div className="platform">
                                <img src="images/tick_1tick.png" alt="" />
                                <h1 className="s s bottom-0 left-16">
                                  Video ads
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-col w-col-4 w-col-stack">
                          <div className="platform_wrapper-2">
                            <div className="div-block-64">
                              <div className="channel-title">
                                <img
                                  src="images/GDN_1GDN.png"
                                  width="34"
                                  height="34"
                                  alt=""
                                  className="image-11"
                                />
                                <h1 className="m bold">Google</h1>
                              </div>
                              <div className="platform">
                                <img src="images/tick_1tick.png" alt="" />
                                <h1 className="s bottom-0 left-16">
                                  Google Display Network
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-col w-col-4 w-col-stack">
                          <div className="platform_wrapper-2">
                            <div className="div-block-64">
                              <div className="channel-title">
                                <img
                                  src="images/instagram_1instagram.png"
                                  width="34"
                                  height="34"
                                  alt=""
                                  className="image-11"
                                />
                                <h1 className="m bold">Instagram</h1>
                              </div>
                              <div className="platform">
                                <img src="images/tick_1tick.png" alt="" />
                                <h1 className="s bottom-0 left-16">
                                  Single Image
                                </h1>
                              </div>
                              <div className="platform">
                                <img src="images/tick_1tick.png" alt="" />
                                <h1 className="s bottom-0 left-16">Carousel</h1>
                              </div>
                              <div className="platform">
                                <img src="images/tick_1tick.png" alt="" />
                                <h1 className="s bottom-0 left-16">
                                  Video ads
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lead-section">
                  <h4 className="heading-12">
                    Starting from $49. <br />
                    Ready to get all the benefits?
                  </h4>
                  <div className="lead-container">
                    <div className="pricing-wrapper">
                      <h5 className="caption">BASIC</h5>
                      <div className="div-block-31">
                        <h1 className="xl btm-10">$49</h1>
                        <div className="price-description">Billed Monthly</div>
                      </div>
                      <h1 className="l bottom-8">$1,500</h1>
                      <div className="price-description regular">
                        Ad Spend/ month.
                        <br />
                        Renews every month
                      </div>
                      <div className="pop-up-group">
                        <div className="overlay">
                          <div id="pop-up-register" className="pop-up">
                            <a
                              id="btn-close"
                              href="#"
                              className="close_btn"
                              data-ix="close-pop-up"
                            >
                              X
                            </a>
                            <h1 className="heading">
                              Try Peasy Free up to $5,000
                            </h1>
                            <h4 className="heading-2">
                              No contract-no hanky panky
                            </h4>
                            <div
                              id="form-register-bottom"
                              className="html-embed-2 footer w-embed w-script"
                            >
                              <link
                                href="https://d1gwclp1pmzk26.cloudfront.net/agile/forms/v1/agile-form-min.css"
                                rel="stylesheet"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-wrapper">
                      <h5 className="caption">DELUXE</h5>
                      <div className="div-block-31">
                        <h1 className="xl btm-10">$55</h1>
                        <div className="price-description">Billed Monthly</div>
                      </div>
                      <h1 className="l bottom-8">$2,500</h1>
                      <div className="price-description regular">
                        Ad Spend/ month.
                        <br />
                        Renews every month
                      </div>
                      <div className="pop-up-group">
                        <div className="overlay">
                          <div id="pop-up-register" className="pop-up">
                            <a
                              id="btn-close"
                              href="#"
                              className="close_btn"
                              data-ix="close-pop-up"
                            >
                              X
                            </a>
                            <h1 className="heading">
                              Try Peasy Free up to $5,000
                            </h1>
                            <h4 className="heading-2">
                              No contract-no hanky panky
                            </h4>
                            <div
                              id="form-register-bottom"
                              className="html-embed-2 footer w-embed w-script"
                            >
                              <link
                                href="https://d1gwclp1pmzk26.cloudfront.net/agile/forms/v1/agile-form-min.css"
                                rel="stylesheet"
                              />
                              <form
                                className="form-view theme2"
                                style={{
                                  boxShadow: "none",
                                  width: "auto",
                                  position: "relative",
                                  padding: 0,
                                  height: "70px",
                                  backgroundColor: "transparent",
                                }}
                                id="agile-form"
                                action="https://peasy.agilecrm.com/formsubmit"
                                method="GET"
                              >
                                <fieldset>
                                  <legend className="agile-hide-formname">
                                    Email-only
                                  </legend>
                                  <div
                                    style={{
                                      display: "none",
                                      height: "0px",
                                      margin: 0,
                                      padding: 0,
                                      width: "0px",
                                      borderRadius: "80px",
                                    }}
                                  >
                                    <input
                                      type="hidden"
                                      id="_agile_form_name"
                                      name="_agile_form_name"
                                      value="Email-only"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_domain"
                                      name="_agile_domain"
                                      value="peasy"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_api"
                                      name="_agile_api"
                                      value="1ccjcq9m48mjtojtbirr180rd7"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_redirect_url"
                                      name="_agile_redirect_url"
                                      value="http://peasy.ai/my/join-beta.html"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_document_url"
                                      name="_agile_document_url"
                                      value=""
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_confirmation_msg"
                                      name="_agile_confirmation_msg"
                                      value=""
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_form_id_tags"
                                      name="tags"
                                      value="emailonly"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_form_id"
                                      name="_agile_form_id"
                                      value="5672749318012928"
                                    />
                                  </div>
                                  <div
                                    className="agile-group"
                                    style={{
                                      float: "left",
                                      width: "320px",
                                      height: "50px",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <label
                                      className="s thick"
                                      htmlFor="email"
                                    ></label>
                                    <div className="agile-field-xlarge agile-field">
                                      <input
                                        maxLength="250"
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="Enter your email"
                                        style={{
                                          borderRadius: "80px",
                                          borderWidth: "1px",
                                          paddingLeft: "16px",
                                        }}
                                        className="agile-height-default"
                                      />
                                    </div>
                                    <div className="agile-custom-clear"></div>
                                  </div>
                                  <div
                                    className="agile-group"
                                    style={{
                                      float: "right",
                                      margin: 0,
                                      padding: 0,
                                      position: "absolute",
                                      right: 0,
                                      height: "50px",
                                    }}
                                  >
                                    <div className="agile-field agile-button-field">
                                      <button
                                        type="submit"
                                        className="button s caps"
                                        style={{
                                          marginBottom: "0px",
                                          fontSize: "14px",
                                          width: "150px",
                                          height: "42px",
                                          borderRadius: "80px",
                                          backgroundColor: "#8d02fa",
                                          marginTop: "0px",
                                        }}
                                      >
                                        Join Beta
                                      </button>
                                    </div>
                                  </div>
                                </fieldset>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-wrapper">
                      <h5 className="caption">PREMIUM</h5>
                      <div className="div-block-31">
                        <h1 className="xl btm-10">$99</h1>
                        <div className="price-description">Billed Monthly</div>
                      </div>
                      <h1 className="l bottom-8">$5,000</h1>
                      <div className="price-description regular">
                        Ad Spend/ month.
                        <br />
                        Renews every month
                      </div>
                      <div className="pop-up-group">
                        <div className="overlay">
                          <div id="pop-up-register" className="pop-up">
                            <a
                              id="btn-close"
                              href="#"
                              className="close_btn"
                              data-ix="close-pop-up"
                            >
                              X
                            </a>
                            <h1 className="heading">
                              Try Peasy Free up to $5,000
                            </h1>
                            <h4 className="heading-2">
                              No contract-no hanky panky
                            </h4>
                            <div
                              id="form-register-bottom"
                              className="html-embed-2 footer w-embed w-script"
                            >
                              <link
                                href="https://d1gwclp1pmzk26.cloudfront.net/agile/forms/v1/agile-form-min.css"
                                rel="stylesheet"
                              />
                              <form
                                className="form-view theme2"
                                style={{
                                  boxShadow: "none",
                                  width: "auto",
                                  position: "relative",
                                  padding: 0,
                                  height: "70px",
                                  backgroundColor: "transparent",
                                }}
                                id="agile-form"
                                action="https://peasy.agilecrm.com/formsubmit"
                                method="GET"
                              >
                                <fieldset>
                                  <legend className="agile-hide-formname">
                                    Email-only
                                  </legend>
                                  <div
                                    style={{
                                      display: "none",
                                      height: "0px",
                                      margin: 0,
                                      padding: 0,
                                      width: "0px",
                                      borderRadius: "80px",
                                    }}
                                  >
                                    <input
                                      type="hidden"
                                      id="_agile_form_name"
                                      name="_agile_form_name"
                                      value="Email-only"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_domain"
                                      name="_agile_domain"
                                      value="peasy"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_api"
                                      name="_agile_api"
                                      value="1ccjcq9m48mjtojtbirr180rd7"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_redirect_url"
                                      name="_agile_redirect_url"
                                      value="http://peasy.ai/my/join-beta.html"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_document_url"
                                      name="_agile_document_url"
                                      value=""
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_confirmation_msg"
                                      name="_agile_confirmation_msg"
                                      value=""
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_form_id_tags"
                                      name="tags"
                                      value="emailonly"
                                    />
                                    <input
                                      type="hidden"
                                      id="_agile_form_id"
                                      name="_agile_form_id"
                                      value="5672749318012928"
                                    />
                                  </div>
                                  <div
                                    className="agile-group"
                                    style={{
                                      float: "left",
                                      width: "320px",
                                      height: "50px",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <label
                                      className="s thick"
                                      htmlFor="email"
                                    ></label>
                                    <div className="agile-field-xlarge agile-field">
                                      <input
                                        maxLength="250"
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="Enter your email"
                                        style={{
                                          borderRadius: "80px",
                                          borderWidth: "1px",
                                          paddingLeft: "16px",
                                        }}
                                        className="agile-height-default"
                                      />
                                    </div>
                                    <div className="agile-custom-clear"></div>
                                  </div>
                                  <div
                                    className="agile-group"
                                    style={{
                                      float: "right",
                                      margin: 0,
                                      padding: 0,
                                      position: "absolute",
                                      right: 0,
                                      height: "50px",
                                    }}
                                  >
                                    <div className="agile-field agile-button-field">
                                      <button
                                        type="submit"
                                        className="button s caps"
                                        style={{
                                          marginBottom: "0px",
                                          fontSize: "14px",
                                          width: "150px !important",
                                          height: "42px",
                                          borderRadius: "80px",
                                          backgroundColor: "#8d02fa",
                                          marginTop: "0px",
                                        }}
                                      >
                                        Join Beta
                                      </button>
                                    </div>
                                  </div>
                                </fieldset>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="heading-16">Features Include</h1>
                  <div className="feature-comparison-table">
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-tiny-3 w-col-small-3"></div>
                      <div className="w-col w-col-2 w-col-tiny-3 w-col-small-3">
                        <h4 className="plans-name">BASIC</h4>
                      </div>
                      <div className="w-col w-col-2 w-col-tiny-3 w-col-small-3">
                        <h4 className="plans-name">DELUXE</h4>
                      </div>
                      <div className="w-col w-col-2 w-col-tiny-3 w-col-small-3">
                        <h4 className="plans-name">PREMIUM</h4>
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          <strong>Recommended Media Planning</strong>.<br />
                          <span className="feature-desc-hidden">
                            Let Peasy recommend the optimum channel and budget
                            split
                          </span>
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          <strong>Automated Campaign Setup</strong>.<br />
                          <span className="feature-desc-hidden">
                            Peasy will automatically setup your Facebook, Google
                            and Instagram campaign for you
                          </span>
                          .
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          <strong>
                            Automated Audience Recommendation.
                            <br />
                          </strong>
                          <span className="feature-desc-hidden">
                            Peasy will recommend the best targeting profile to
                            achieve your campaign goals
                          </span>
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Support Leads (CPL) and Sales (CPA) campaigns
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/wrong.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Support Awareness (CPM), Consideration (CPC) and
                          Engagement (CPT) campaigns
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Support Facebook, Google and Instagram campaigns
                          <br />
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Support Remarketing campaigns
                          <br />
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Support Custom and Similar Audience
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          <strong>Centralized trafficking.</strong>
                          <br />
                          <span className="feature-desc-hidden">
                            Upload your ad once on Peasy, and Peasy will
                            distribute it across Facebook, Google &amp;
                            Instagram
                          </span>
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          <strong>Centralized Campaign Management.</strong>{" "}
                          <br />
                          <span className="feature-desc-hidden">
                            Review, edit and manage your Facebook &amp; Google
                            campaigns from one single dashboards
                          </span>
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          <strong>Automated Optimization.</strong> <br />
                          <span className="feature-desc-hidden">
                            Peasy will automatically monitor your campaign and
                            optimize the audience targeting, bid adjustment,
                            placement blacklisting and channel budget splitting
                            so that you can achieve the maximum CTR/Conversion
                            Rate/Install Rate/Engagement Rate at the lowest
                            budget
                          </span>
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          <strong>Powerful integrated reporting.</strong> <br />
                          <span className="feature-desc-hidden">
                            Peasy combine your Facebook and Google campaign
                            performance data into one wholesome report. Peasy
                            distill the data into actionable analysis and
                            insights
                          </span>
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>

                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Full, cloud based implementation. No installation
                          required
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          <strong>Peasy Academy</strong>.<br />
                          <span className="feature-desc-hidden">
                            Unrestricted access to our digital strategy,
                            tutorial and tips library to empower your digital
                            marketing capabilities. Regular webminars with our
                            panel of experts
                          </span>
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Customer support through Instant Email and Chat,
                          on-demand phone call with our Customer Success Manager
                          <br />
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Access to our regular events and one-to-one
                          consultation
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/wrong.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <img
                          src="images/tick_1tick.png"
                          alt=""
                          className="image-29"
                        />
                      </div>
                    </div>
                    <div className="feature-summary-column w-row">
                      <div className="w-col w-col-6 w-col-small-3 w-col-tiny-3">
                        <p className="feature-detail">
                          Number of connected Facebook &amp; Google accounts
                        </p>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <div>1</div>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <div>1</div>
                      </div>
                      <div className="w-col w-col-2 w-col-small-3 w-col-tiny-3">
                        <div>3</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="full-width plain ltd">
                <div className="column bottom-56 h footer">
                  <div className="column-panel footer top">
                    <div className="column h">
                      <div className="column-panel top">
                        <img
                          src="https://uploads-ssl.webflow.com/5c5040779bf84ca940f45e57/5c5040779bf84c517ef45e91_logo-green.svg"
                          alt=""
                          className="image-logo footer"
                        />
                      </div>
                      <div className="column-panel top footer">
                        <div className="xs-2">
                          MALAYSIA:
                          <br />
                          +603 7732 1661
                        </div>
                        <div className="xs-2">
                          Mitosis Sdn Bhd (670912-T)
                          <br />
                          Oval Damansara 19-3, 19th Floor
                          <br />
                          No. 685 Jalan Damansara
                          <br />
                          60000 Kuala Lumpur, Malaysia
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column-panel align-top footer">
                    <div className="column h">
                      <div className="column-panel top footer">
                        <div className="xs-2">
                          INDONESIA:
                          <br />
                          +622 1829 8412
                        </div>
                        <div className="xs-2">
                          PT Mitosis Cipta Indonesia 12/F Wisma <br />
                          Staco Jl Casablanca Kav 18 Menteng Dalam, Tebet <br />
                          Jakarta Selatan 12960, Indonesia. <br />
                        </div>
                      </div>
                      <div className="column-panel top footer">
                        <div className="s caps black">Contact</div>
                        <a
                          href="mailto:sales@peasy.ai"
                          className="xs-2 gray bottom-16 no-style"
                        >
                          sales@peasy.ai
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-panel top footer top-32 ipad-subscribe">
                    <div className="column-panel"></div>
                    <div className="s caps black">
                      Subscribe to peasy&#x27;s Blog
                    </div>
                    <div className="form-block w-form">
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>
                          Oops! Something went wrong while submitting the form.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column h">
                  <div className="column-panel">
                    <div className="column">
                      <div className="column-panel"></div>
                      <div className="column-panel top footer">
                        <div className="xs-2">
                          © Peasy. All rights reserved.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column-panel">
                    <div className="column"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegisterPage;
