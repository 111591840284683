import React, { Component } from "react";
// import Select from "react-select";
import moment from "moment";
import Select from "react-select";
import "./index.css";

const options = [
  { value: "CHANNEL_FACEBOOK", label: "Facebook" },
  { value: "CHANNEL_INSTAGRAM", label: "Instagram" },
  { value: "CHANNEL_GOOGLE", label: "Google" },
];

class TableReportContainer extends Component {
  state = {
    selectedOption: "CHANNEL_INSTAGRAM",
  };

  handleChange = (e) => {
    this.setState({ selectedOption: e.target.value });
  };

  render() {
    const { audience, channel, campaignMetricData, campaignDetailsData } =
      this.props;
    const data = campaignMetricData && campaignMetricData.data;
    const audiences = campaignDetailsData && campaignDetailsData.audiences;
    const table_report = data && data.audience_table_report;
    let audience_table_report = data && data.audience_table_report;
    const platformObj =
      campaignDetailsData &&
      campaignDetailsData.campaign &&
      campaignDetailsData.campaign.platform.toLowerCase();
    // let audience_table_report =
    //   audience && audience.id
    //     ? { [audience.id]: table_report[audience.id] }
    //     : table_report;
    // const { selectedOption } = this.state;
    return (
      <div class="cstm_container">
        <div class="insight_sec  width100 mob_data">
          {audience_table_report &&
            Object.keys(audience_table_report).map((key, i) => {
              let audi =
                Array.isArray(audiences) &&
                audiences.filter((item) => item.id === +key);
              return (
                <div class="camp_sec width100 data_table mob_dis_none" key={i}>
                  <div class="camp_lft">
                    <h3>{Array.isArray(audi) && audi[0] && audi[0].name}</h3>
                    <p>
                      {moment(
                        Array.isArray(audi) && audi[0] && audi[0].created_at
                      ).format("MMM YYYY")}
                    </p>
                  </div>
                  <div class="camp_rgt">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Channel</th>
                          {platformObj === "messenger" && (
                            <th>Conversations</th>
                          )}
                          <th>Impressions</th>
                          <th>Clicks</th>
                          <th>Engagements</th>
                          <th>Traffic</th>
                          <th>Conversion</th>
                          <th>Spend</th>
                          <th>CPM</th>
                          <th>
                            {platformObj === "messenger" ? "CPCN" : "CPC"}
                          </th>
                          <th>CTR</th>
                          <th>CPE</th>
                          <th>CPT</th>
                          <th>CPA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {options.map(({ value, label }) => {
                          let row =
                            audience_table_report &&
                            audience_table_report[key] &&
                            audience_table_report[key][value];
                          // if (
                          //   (channel && channel.value === value) ||
                          //   !channel.value
                          // )
                          return (
                            <tr>
                              <td class="voilet_clr">{label}</td>
                              {platformObj === "messenger" && (
                                <td>
                                  {(row && row.conversations.toString()) ||
                                    "0.00"}
                                </td>
                              )}
                              <td>
                                {(row && row.impressions.toString()) || "0.00"}
                              </td>
                              <td>
                                {(row && row.clicks.toString()) || "0.00"}
                              </td>
                              <td>
                                {(row && row.engagements.toString()) || "0.00"}
                              </td>
                              <td>
                                {(row && row.traffics.toString()) || "0.00"}
                              </td>
                              <td>
                                {(row && row.conversions.toString()) || "0.00"}
                              </td>
                              <td>
                                {(row && row.spend.toFixed(2).toString()) ||
                                  "0.00"}
                              </td>
                              <td>{(row && row.cpm.toString()) || "-"}</td>
                              <td>{(row && row.cpc.toString()) || "-"}</td>
                              <td>{(row && row.ctr.toString()) || "-"}</td>
                              <td>{(row && row.cpe.toString()) || "-"}</td>
                              <td>{(row && row.cpt.toString()) || "-"}</td>
                              <td>{(row && row.cpa.toString()) || "-"}</td>
                            </tr>
                          );
                          // else return null;
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}

          {audience_table_report &&
            Object.keys(audience_table_report).map((key, i) => {
              let row =
                audience_table_report &&
                audience_table_report[key] &&
                audience_table_report[key][this.state.selectedOption];
              let audi =
                Array.isArray(audiences) &&
                audiences.filter((item) => item.id === +key);
              return (
                <div class="camp_sec width100 data_table desk_dis_none">
                  <div class="camp_lft">
                    <h3>{Array.isArray(audi) && audi[0] && audi[0].name}</h3>
                    <p>
                      {moment(
                        Array.isArray(audi) && audi[0] && audi[0].created_at
                      ).format("MMM YYYY")}
                    </p>
                  </div>
                  <div class="camp_rgt">
                    <div class="camp_rgt_row">
                      <div class="camp_rgt_col">
                        <div class="camp_tab_cnt">
                          <p class="card_head">Channel</p>
                          <select
                            class="card_cnt voilet_clr"
                            onChange={this.handleChange}
                            value={this.state.selectedOption}
                          >
                            {options.map((option) => (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            ))}{" "}
                          </select>
                        </div>
                        <div class="camp_tab_cnt">
                          <p class="card_head">Traffic</p>
                          <p class="card_cnt">
                            {(row && row.traffics.toString()) || "0.00"}
                          </p>
                        </div>
                        <div class="camp_tab_cnt">
                          <p class="card_head">CPM</p>
                          <p class="card_cnt">
                            {(row && row.cpm.toString()) || "-"}
                          </p>
                        </div>
                        <div class="camp_tab_cnt">
                          <p class="card_head">CPT</p>
                          <p class="card_cnt">
                            {(row && row.cpt.toString()) || "-"}
                          </p>
                        </div>
                      </div>
                      <div class="camp_rgt_col">
                        <div class="camp_tab_cnt">
                          <p class="card_head">Impressions</p>
                          <p class="card_cnt">
                            {(row && row.impressions.toString()) || "0.00"}
                          </p>
                        </div>
                        <div class="camp_tab_cnt">
                          <p class="card_head">Conversion</p>
                          <p class="card_cnt">
                            {(row && row.conversions.toString()) || "0.00"}
                          </p>
                        </div>
                        <div class="camp_tab_cnt">
                          <p class="card_head">CPC</p>
                          <p class="card_cnt">
                            {(row && row.cpc.toString()) || "-"}
                          </p>
                        </div>
                        <div class="camp_tab_cnt">
                          <p class="card_head">CPA</p>
                          <p class="card_cnt">
                            {(row && row.cpa.toString()) || "-"}
                          </p>
                        </div>
                      </div>
                      <div class="camp_rgt_col">
                        <div class="camp_tab_cnt">
                          <p class="card_head">Clicks</p>
                          <p class="card_cnt">
                            {(row && row.clicks.toString()) || "0.00"}
                          </p>
                        </div>
                        <div class="camp_tab_cnt">
                          <p class="card_head">Spend</p>
                          <p class="card_cnt">
                            {(row && row.spend.toFixed(2).toString()) || "0.00"}
                          </p>
                        </div>
                        <div class="camp_tab_cnt">
                          <p class="card_head">CTR</p>
                          <p class="card_cnt">
                            {(row && row.ctr.toString()) || "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div class="camp_sec width100 data_table desk_dis_none">
            <div class="camp_lft">
              <h3>ABC Campaign</h3>
              <p>Sept 2020 - December 2020</p>
            </div>
            <div class="camp_rgt">
              <div class="camp_rgt_row">
                <div class="camp_rgt_col">
                  <div class="camp_tab_cnt">
                    <p class="card_head">Channel</p>
                    <select class="card_cnt voilet_clr">
                      <option>Google</option>
                      <option>Google</option>
                      <option>Google</option>
                      <option>Google</option>
                    </select>
                  </div>
                  <div class="camp_tab_cnt">
                    <p class="card_head">Traffic</p>
                    <p class="card_cnt">0.00</p>
                  </div>
                  <div class="camp_tab_cnt">
                    <p class="card_head">CPM</p>
                    <p class="card_cnt">-</p>
                  </div>
                  <div class="camp_tab_cnt">
                    <p class="card_head">CPT</p>
                    <p class="card_cnt">-</p>
                  </div>
                </div>
                <div class="camp_rgt_col">
                  <div class="camp_tab_cnt">
                    <p class="card_head">Impressions</p>
                    <p class="card_cnt">0.00</p>
                  </div>
                  <div class="camp_tab_cnt">
                    <p class="card_head">Conversion</p>
                    <p class="card_cnt">0.00</p>
                  </div>
                  <div class="camp_tab_cnt">
                    <p class="card_head">CPC</p>
                    <p class="card_cnt">-</p>
                  </div>
                  <div class="camp_tab_cnt">
                    <p class="card_head">CPA</p>
                    <p class="card_cnt">-</p>
                  </div>
                </div>
                <div class="camp_rgt_col">
                  <div class="camp_tab_cnt">
                    <p class="card_head">Clicks</p>
                    <p class="card_cnt">0.00</p>
                  </div>
                  <div class="camp_tab_cnt">
                    <p class="card_head">Clicks</p>
                    <p class="card_cnt">0.00</p>
                  </div>
                  <div class="camp_tab_cnt">
                    <p class="card_head">CTR</p>
                    <p class="card_cnt">-</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default TableReportContainer;
