import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import http from "../../../shared/http-request";
import { SaveCampaign } from "../campaignCreateFunctions";

import "react-toastify/dist/ReactToastify.css";

 class PreparePredictionCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width_progress: 0,
      IntPercent: 0,
      weekly_volume_change: "NA",
      recomm_cpa: "NA",
      recomm_weekly_volume: "NA",
      user_campaign_cpa: "NA",
      user_campaign_weekly_volume: "NA",
      cpa_change: "NA"
    };
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.gobackprevious = this.gobackprevious.bind(this);
    this.GetPredictionResponse = this.GetPredictionResponse.bind(this);
    this.getPredictionReport = this.getPredictionReport.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
  }

  redirectUser() {
    const { brandId_decode } = this.props.values;
    this.props.history.push(`/brand/overview/${brandId_decode}`)
    // window.location.href =
    //   this.props.BASE_URL + "brand/overview/" + brandId_decode;
  }

  saveAndContinue() {
    this.props.nextStep();
  }

  gobackprevious() {
    this.props.prevStep();
  }



  timer() {
    const WidthPercent = this.state.width_progress + 0.167;
    const IntWidth = parseInt(WidthPercent);

    if (WidthPercent < 98) {
      this.setState({
        width_progress: WidthPercent,
        IntPercent: IntWidth
      });
    }
  }

  getPredictionReport(taskId, campaignId) {
    // var token = "";
    // try {
    //   token = this.props.values.api_token;
    // } catch (err) {
    //   this.props.RefreshToken();
    //   this.getPredictionReport(taskId, campaignId);
    // }

    // if (token != undefined || token != "") {
      let config = {
        // headers: { Authorization: "Bearer " + token }
      };

      http
        .get(this.props.BASE_URL + "api/get-report?task_id=" + taskId, config)
        .then(response => {
          console.log(response);

          if (response.data.flag == -1) {
            console.log("Task queue in progress");
            setTimeout(
              () => this.getPredictionReport(taskId, campaignId),
              3000
            );
          } else if (response.data.flag == 0) {
            console.log("Request failed");
          } else {
            console.log("Report get success update state accordingly");
            this.setState({
              weekly_volume_change: response.data.data.weekly_volume_change,
              recomm_cpa: response.data.data.recomm_cpa,
              recomm_weekly_volume: response.data.data.recomm_weekly_volume,
              user_campaign_cpa: response.data.data.user_campaign_cpa,
              user_campaign_weekly_volume:
                response.data.data.user_campaign_weekly_volume,
              cpa_change: response.data.data.cpa_change
            });
          }
        })
        .catch(err => console.log(err));
    // }
  }

  GetPredictionResponse() {
    const intervalId = setInterval(this.timer.bind(this), 1000);

    var token = "";
    try {
      token = this.props.values.api_token;
    } catch (err) {
      this.props.RefreshToken();
      this.GetPredictionResponse();
    }

    if (token != undefined || token != "") {
      let config = {
        headers: { Authorization: "Bearer " + token }
      };

      let data_arr = this.props.values.selected_campaigns;
      let selected_location = this.props.values.locationSelectd;
      let CampaignId = Object.keys(data_arr);
      let SelectedLocation = Object.keys(selected_location);

      console.log(SelectedLocation);

      let bodyParameters = {
        value_data: JSON.stringify(CampaignId),
        selected_location: JSON.stringify(SelectedLocation),
        new_campaign_id: this.props.values.campaign_id,
        brand_id: this.props.values.brand_id,
        allData: JSON.stringify(this.props.values)
      };

      http.post(this.props.BASE_URL + "api/on-demand", bodyParameters, config)
        .then(response => {
          console.log(response);

          if (response.data.flag == 1) {
            // clearInterval(intervalId);

            this.setState({
              list: response.data.campaigns,
              loadlist: true,
              IntPercent: 100,
              width_progress: 100
            });
            // this.getPredictionReport(taskId, campaignId);
            const { brandId_decode } = this.props.values;
            this.props.history.push(`/brand/overview/${brandId_decode}`)
            // window.location.href =
            //   this.props.BASE_URL + "/brand/overview/" + brandId_decode;
          } else {
          }
        })

        .catch(err => console.log(err));
    }
  }

  componentDidMount() {
    //Save All Campaign Info
    SaveCampaign(
      this.props.values,
      this.props.RefreshToken,
      this.props.CheckObjective,
      this.props.handleChange,
      this.GetPredictionResponse
    );
  }

  render() {
    console.log(
      "render of prepare prediction campaign",
      this.props.values.campaign_sync
    );
    const { width_progress } = this.state;
    const width_progress_style = {
      width: width_progress + "%"
    };
    const { IntPercent } = this.state;

    return (
      <div className="w-100">
        <div className="preparing-sec-prt">
          <div className="row mt-4">
            <div className="col-12 text-center preparing-top-sec">
              <h4 className="mb-4">Preparing for the prediction</h4>
              {/* <div className="d-block mb-5 mt-3 row mx-0">
                <div className="progress ml-2 mr-2">
                    <div className="progress-bar" role="progressbar" style={  width_progress_style }  aria-valuenow={  width_progress_style } aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <span className="float-left progress-span mt-1">{ IntPercent }%</span>
                <span className="float-right progress-span mt-1">100%</span>
              </div> */}
              <p className="mb-4 mt-3">
                Like all good relationship, it requires time and patience.
                Please give our AI anywhere between 10-15 minutes to download,
                analyze and prepared your custom-made recommendations. <br />
                <strong>
                  We will notify you via email once the prediction is ready.
                </strong>
                <br /> Meanwhile check the information below to give insights
                for your campaign
              </p>
              <button
                onClick={this.redirectUser}
                className="btn btn-primary mb-3 mt-4"
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withRouter(PreparePredictionCampaign);
