import React, { Component } from "react";
import Shimmer from "../../../common/shimmer/Shimmer";
import "./ClientListingTable.css";
import LeadsRow from "./LeadsRow.jsx/LeadsRow";
import "../../../translations/i18n";
import { withTranslation } from "react-i18next";

const dummyData = [
  {
    id: 1,
    email: "asasdasd",
    predicted_score: "asasdasd",
    qualification: "asasdasd",
    campaign_id: "asasdasd",
    created_at: "asasdasd",
  },
];

class ClientListingTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: this.props.tableData,
      PREDICTED_SCORE: "fa fa-fw fa-sort",
      QUALIFICATION: "fa fa-fw fa-sort",
      DATE: "fa fa-fw fa-sort",
      PREDICTED_SCORE_ASC: false,
      QUALIFICATION_ASC: false,
      DATE_ASC: false,
    };
  }

  onQualificationChangeHandler = (id, data) => {
    this.props.updateQualificationChangeHandler(id, data);
  };

  renderInputFields = (input_name) => {
    if (this.state.isEditOn) {
      return (
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name={input_name}
            value={this.state[input_name]}
            onChange={this.onInputChangeHandler}
          />
        </div>
      );
    } else {
      return this.props.data[input_name];
    }
  };

  renderLeadListingTable() {
    const tableData = this.props.tableData;
    let rowDataArray = null;
    if (this.props.loadingData) {
      return (
        <React.Fragment>
          <tr>
            <td colSpan="6">
              <div className="clientlistingtable_wrapper">
                <div className="clientlistingtable_wrapper_shimmer__container animate" />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="6">
              <div className="clientlistingtable_wrapper">
                <div className="clientlistingtable_wrapper_shimmer__container animate" />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="6">
              <div className="clientlistingtable_wrapper">
                <div className="clientlistingtable_wrapper_shimmer__container animate" />
              </div>
            </td>
          </tr>
        </React.Fragment>
      );
    }
    if (tableData.length == 0) {
      return (
        <React.Fragment>
          <tr>
            <td colSpan="6">
              <div className="text-center">
                {this.props.t("clients_noDataToDisplay")}
              </div>
            </td>
          </tr>
        </React.Fragment>
      );
    }

    rowDataArray = tableData.map((data) => {
      return (
        <LeadsRow
          data={data}
          key={data.id}
          onQualificationChangeHandler={this.onQualificationChangeHandler}
          onApplyEdit={this.onApplyEdit}
          deleteValue={this.deleteValue}
        />
      );
    });

    // console.log(rowDataArray)
    return rowDataArray;
  }

  onApplyEdit = (data) => {
    this.props.onApplyEdit(data);
  };

  deleteValue = (id) => {
    this.props.deleteValue(id);
  };

  showActionsLinkModal(e, data) {
    e.preventDefault();
    // console.log(data)
  }

  onSortClicked = (sort) => {
    switch (sort) {
      case "PREDICTED_SCORE":
        if (this.state.PREDICTED_SCORE_ASC) {
          this.props.sortItems("PREDICTED_SCORE", "DESC");
          this.setState({
            PREDICTED_SCORE: "fa fa-fw fa-sort-desc",
            PREDICTED_SCORE_ASC: false,
          });
        } else {
          this.props.sortItems("PREDICTED_SCORE", "ASC");
          this.setState({
            PREDICTED_SCORE: "fa fa-fw fa-sort-asc",
            PREDICTED_SCORE_ASC: true,
          });
        }
        break;
      case "QUALIFICATION":
        if (this.state.QUALIFICATION_ASC) {
          this.props.sortItems("QUALIFICATION", "DESC");
          this.setState({
            QUALIFICATION: "fa fa-fw fa-sort-desc",
            QUALIFICATION_ASC: false,
          });
        } else {
          this.props.sortItems("QUALIFICATION", "ASC");
          this.setState({
            QUALIFICATION: "fa fa-fw fa-sort-asc",
            QUALIFICATION_ASC: true,
          });
        }
        break;
      case "DATE":
        if (this.state.DATE_ASC) {
          this.props.sortItems("DATE", "DESC");
          this.setState({ DATE: "fa fa-fw fa-sort-desc", DATE_ASC: false });
        } else {
          this.props.sortItems("DATE", "ASC");
          this.setState({ DATE: "fa fa-fw fa-sort-asc", DATE_ASC: true });
        }
        break;
    }
  };

  render() {
    return (
      <div className="table-responsive">
        <table className="table" id="lead_listing_table">
          <thead>
            <tr>
              {/* <th className="clientlistingtable_th_backgroud" scope="col">CLIENT NAME <i className="fa fa-fw fa-sort"></i></th>
                            <th className="clientlistingtable_th_backgroud" scope="col">PHONE</th> */}
              <th
                className="clientlistingtable_th_backgroud clientlistingtable_table_td_fixed"
                scope="col"
              >
                {this.props.t("clients_email")}
              </th>
              <th
                className="clientlistingtable_th_backgroud clientlistingtable_table_td_fixed"
                scope="col"
              >
                {this.props.t("clients_phone")}
              </th>
              <th className="clientlistingtable_th_backgroud" scope="col">
                {this.props.t("clients_predictedScore")}{" "}
                <i
                  style={{ cursor: "pointer" }}
                  className={this.state.PREDICTED_SCORE}
                  onClick={(e) => {
                    this.onSortClicked("PREDICTED_SCORE");
                  }}
                ></i>
              </th>
              <th className="clientlistingtable_th_backgroud" scope="col">
                {this.props.t("clients_qualification")}{" "}
                <i
                  style={{ cursor: "pointer" }}
                  className={this.state.QUALIFICATION}
                  onClick={(e) => {
                    this.onSortClicked("QUALIFICATION");
                  }}
                ></i>
              </th>
              <th className="clientlistingtable_th_backgroud" scope="col">
                {this.props.t("clients_campaign")}
              </th>
              <th className="clientlistingtable_th_backgroud" scope="col">
                {this.props.t("clients_date")}{" "}
                <i
                  style={{ cursor: "pointer" }}
                  className={this.state.DATE}
                  onClick={(e) => {
                    this.onSortClicked("DATE");
                  }}
                ></i>
              </th>
              <th className="clientlistingtable_th_backgroud" scope="col">
                {this.props.t("clients_details")}
              </th>
              {/* <th className="clientlistingtable_th_backgroud" scope="col">
                ACTION(S)s
              </th> */}
            </tr>
          </thead>
          <tbody>{this.renderLeadListingTable()}</tbody>
        </table>
      </div>
    );
  }
}
export default withTranslation()(ClientListingTable);
