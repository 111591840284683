import React, { useState } from "react";
import { toast } from "react-toastify";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

import {
  Container,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Grid,
} from "@material-ui/core";

function TargetCategory({ value, handleChange, goBack, goForward }) {
  const { t } = useTranslation();

  const [collapse_open, setCollapseOpen] = useState();

  const collapse = () => {
    setCollapseOpen(!collapse_open);
  };

  const checkEnter = (e) => {
    if (e.keyCode === 13) {
      validate();
    }
  };

  const validate = () => {
    if (value != "0") {
      goForward();
    } else {
      toast.error(t("campaign_selectChoice"), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const listStyles = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "3%",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const displayPlatformName = (name) => {
    if (name === 1) return "New";
    if (name === 2) return "Repeat";
    if (name === 3) return "Both";
  };
  const submitPlatform = (val) => {
    handleChange("target_category", val);
    // collapse();
  };
  return (
    <div className="outer_div w-100 animated" onKeyDown={(e) => checkEnter(e)}>
      <div className="col-12 px-0 tab-pane border-rounded p-3 mb-2" id="step6">
        <div className="form-group brand-custom-form brand-custom-dropdown">
          <label>{t("campaign_wantToTarget")}</label>
          <Grid container spacing={8} justify="center">
            <Grid item xs={12} sm={4} md={3}>
              <button
                className={value === 1 ? "button-card-focused" : "button-card"}
                onClick={(e) => {
                  submitPlatform(1);
                }}
              >
                <img src="/images/assets/target-category/new.svg" alt="" />
                <p className="button-card-heading mt-2">
                  {t("campaign_newCustomers")}</p>
                <p className="button-card-description mt-2">
                  {t("campaign_setToFindCustomers")}
                </p>
              </button>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <button
                // value="2"
                className={value === 2 ? "button-card-focused" : "button-card"}
                onClick={(e) => {
                  submitPlatform(2);
                }}
              >
                <img src="/images/assets/target-category/repeat.svg" alt="" />
                <p className="button-card-heading mt-2">
                  {t("campaign_driveRepeatSalesOrUpsell")} </p>

                <p className="button-card-description mt-2">
                  {t("campaign_setToExistingCustomers")}</p>
              </button>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <button
                className={value === 3 ? "button-card-focused" : "button-card"}
                onClick={(e) => {
                  submitPlatform(3);
                }}
              >
                <img src="/images/assets/target-category/both.svg" alt="" />
                <p className="button-card-heading mt-2">
                  {t("campaign_both")}</p>
                <p className="button-card-description mt-2">
                {t("campaign_setToNewExistingCustomers")}
                </p>
              </button>
            </Grid>
          </Grid>
          {/* <button
            type="button"
            className="form-control brand-dropdown"
            onClick={() => collapse()}
            autoFocus
          >
            {value === 0 ? "Select a category" : displayPlatformName(value)}
            <i className="fa fa-caret-down float-right"></i>
          </button>
          <div
            id="catefory"
            className={collapse_open == true ? "collapse show" : "collapse"}
          >
            <ul className="list-unstyled">
              <li style={listStyles} onClick={() => submitPlatform(1)}>
                New
              </li>
              <li style={listStyles} onClick={() => submitPlatform(2)}>
                Repeat
              </li>
              <li style={listStyles} onClick={() => submitPlatform(3)}>
                Both
              </li>
            </ul>
          </div> */}
        </div>
      </div>

      <div className="navination_bar">
        <div className="bar_search d-flex">
          <div className="above_navigation_box ml-auto">
            <button
              className="brandprofilecard__btn--white"
              onClick={() => {
                goBack();
              }}
            >{t("campaign_back")}
              
            </button>
          </div>
          <div className="below_navigation_box" style={{ marginRight: "0px" }}>
            <button
              className="brandoverviewleftsection__btn--purple"
              onClick={() => validate()}
            >{t("campaign_next")}
              
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TargetCategory;
