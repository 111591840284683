import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { withRouter } from "react-router-dom";
import http from "../../../shared/http-request";
import { BASE_URL } from "../../../StaticArray/StaticArray";

import CheckoutForm from "./CheckoutForm";
import { onStripePayment } from "../../../utils/network/lib/misc";
const stripePromise = loadStripe("pk_live_51GcRudIB8fU21ThgW0EcLP5MZ69oCYn9whXnlTT7GRFFjGLEZeI7G6sCwDRJ965h9QTmVCgN4r3bQwenMHlIFTAZ00a9oHmEha");

const CheckoutComponent = ({
  toppAmount,
  amount,
  currency,
  successCallback,
  errorCallback,
}) => {
  const [showCheckout, setShowCheckout] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(amount);

  const getClientSecret = async () => {
    onStripePayment({
      amount: amount,
      currency: currency,
    })
      .then((res) => {
        setClientSecret(res.data.clientSecret);
        setPaymentIntentId(res.data.paymentIntentId);
        setPaymentAmount(res.data.amount_after_tax);

        setShowCheckout(true);
      })
      .catch((err) => {
        setClientSecret(
          "pi_3KyWGzIB8fU21Thg1W8kIt4F_secret_OSuTfQOhQ8Q3SRddtWkx4czte"
        );
        setShowCheckout(true);
      });
  };
  useEffect(() => {
    console.log(amount, currency);
    getClientSecret();
  }, []);

  const appearance = {
    theme: "stripe",

    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      // See all possible variables below
    },
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance: appearance,
  };
  return (
    <div>
      {showCheckout && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            toppAmount={toppAmount}
            amount={paymentAmount}
            currency={currency}
            successCallback={successCallback}
            errorCallback={errorCallback}
          />
        </Elements>
      )}
    </div>
  );
};

export default withRouter(CheckoutComponent);
