import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translation";
import { TRANSLATIONS_MY } from "./my/translation";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: JSON.parse(localStorage.getItem("user_data")) ? JSON.parse(localStorage.getItem("user_data")).language : "en" , // default language
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      my: {
        translation: TRANSLATIONS_MY,
      },
    },
  });
