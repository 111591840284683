import React, { useState, useEffect } from "react";
import HighCharts from "../../../common/chart/ColumnBarChart";
import "./PostBoostChartComponent.css";
import { ErrorMessage } from "./errorMessage/ErrorMessage";
import { WarningOutlined } from "@material-ui/icons";
import SliderContainer from "../../../common/Slider";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  centerPadding: "20px",
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
  ],
};
const PostBoostChartComponent = ({
  campaignMetricData,
  predictionVolume,
  campaignException,
}) => {
  // state = {
  //   lineChartData: [],
  //   cpaComparisionData: [],
  //   salesVolumnData: [],
  //   isLoading: true,
  // };
  const { t } = useTranslation();

  const [lineChartData, setLineChartData] = useState([]);
  const [cpaComparisionData, setCpaComparisionData] = useState([]);
  const [salesVolumeData, setSalesVolumeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
    },
    title: {
      text: "",
      x: -20, //center
    },
    xAxis: {
      categories: [""],
      title: {
        text: t("campaign_comparison"),
      },
    },
    yAxis: {
      min: 0,
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      formatter: function () {
        return this.series.name + " :" + this.y;
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [],
  });
  const [lineChartOptions, setLineChartOptions] = useState({
    chart: {
      renderTo: "container",
      type: "spline",
    },
    title: {
      text: "",
      x: -20, //center
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: t("campaign_range"),
      },
      min: 0,
    },
    tooltip: {
      formatter: function () {
        return (
          t("campaign_valueFor") +
          this.series.name +
          t("campaign_on") +
          "</b><br/>" +
          this.x +
          ": " +
          this.y
        );
      },
    },
    loading: {
      hideDuration: 100,
    },
    series: [],
  });

  useEffect(() => {
    if (Object.keys(campaignMetricData).length === 0) {
      setIsLoading(true);
    }
    // debugger;
    if (campaignMetricData && campaignMetricData.data) {
      let LineChartOptions = lineChartOptions;
      LineChartOptions = {
        ...LineChartOptions,
        xAxis: {
          categories: getCategories(campaignMetricData.data.chart_data),
        },
        series: getSeries(campaignMetricData.data.chart_data),
      };
      let barCpaComparisionData = chartOptions;
      barCpaComparisionData = {
        ...barCpaComparisionData,
        series: getBarSeries(campaignMetricData.data.cpa_comparison_data),
      };
      let salesVolData = chartOptions;
      salesVolData = {
        ...salesVolData,
        series: getBarSeries(campaignMetricData.data.sales_vol_data),
      };
      setLineChartData(LineChartOptions);
      setCpaComparisionData(barCpaComparisionData);
      setSalesVolumeData(salesVolData);
      setIsLoading(false);
    }
  }, [campaignMetricData]);
  // Handle state and props change
  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log(nextProps);
  //   const { campaignMetricData } = nextProps;
  //   console.log("campaignMetricData ok =>", campaignMetricData);
  //   // Handle state change
  //   if (nextProps !== this.props) return true;
  //   if (nextProps.campaignException !== this.props.campaignException)
  //     return true;

  //   // Handle campaign metric data

  //   if (Object.keys(campaignMetricData).length !== 0) {
  //     console.log("campaignMetricData test5 =>", campaignMetricData);
  //     // Handle line chart data
  //     let lineChartOptions = LineChartOptions;
  //     lineChartOptions = {
  //       ...lineChartOptions,
  //       xAxis: {
  //         categories: this.getCategories(campaignMetricData.data.chart_data),
  //       },
  //       series: this.getSeries(campaignMetricData.data.chart_data),
  //     };

  //     // Handle cpa camparision bar chart data
  //     let barCpaComparisionData = chartOptions;
  //     barCpaComparisionData = {
  //       ...barCpaComparisionData,
  //       // xAxis: {
  //       //   categories: this.getBarChartCategories(
  //       //     campaignMetricData.data.cpa_comparison_data
  //       //   )
  //       // },
  //       series: this.getBarSeries(campaignMetricData.data.cpa_comparison_data),
  //     };

  //     // Handle sales volumn camparision bar chart data
  //     let salesVolData = chartOptions;
  //     salesVolData = {
  //       ...salesVolData,
  //       // xAxis: {
  //       //   categories: this.getBarChartCategories(
  //       //     campaignMetricData.data.cpa_comparison_data
  //       //   )
  //       // },
  //       series: this.getBarSeries(campaignMetricData.data.sales_vol_data),
  //     };

  //     this.setState({
  //       lineChartData: lineChartOptions,
  //       salesVolumnData: salesVolData,
  //       cpaComparisionData: barCpaComparisionData,
  //       isLoading: false,
  //     });

  //     return true;
  //   }

  //   // default return false
  //   return false;
  // }

  // Get bar chart data categories
  const getBarChartCategories = (barData) => {
    let categoryName = [];
    barData.length &&
      barData.map((val) => {
        categoryName = [...categoryName, val.name];
      });
    return categoryName;
  };

  // Get Barchart data series
  const getBarSeries = (barData) => {
    // console.log(predictionVolume);
    // console.log("getBarSeries barData=> ", barData);
    let seriesData = [];
    barData &&
      barData.length &&
      barData.map((val, index) => {
        // console.log("getBarSeries barData.map val1 =>", val);
        // if (val.value === "NaN" || val.value === NaN) {
        //   val.value = 0;
        // }
        seriesData = [...seriesData, { name: val.name, data: [val.value] }];
      });
    // console.log("getBarSeries seriesData2 =>", seriesData);
    return seriesData;
  };

  // Get line chart data series
  const getSeries = (chartData) => {
    const chartDataKeys = chartData ? Object.keys(chartData) : [];
    let categories =
      chartData &&
      chartDataKeys.length &&
      Object.keys(chartData[chartDataKeys[0]]);
    let series = [];
    series =
      Array.isArray(chartDataKeys) &&
      chartDataKeys.map((chartKey) => {
        let item = {
          name: chartKey,
          data:
            Array.isArray(categories) &&
            categories.map((key) => chartData[chartKey][key]),
        };
        return item;
      });
    return series;
  };

  // Get line chart data categories
  const getCategories = (chartData) => {
    const chartDataKeys = chartData ? Object.keys(chartData) : [];
    return (
      chartData &&
      chartDataKeys.length &&
      Object.keys(chartData[chartDataKeys[0]])
    );
  };

  // const { lineChartData, salesVolumnData, cpaComparisionData, isLoading } =
  //   this.state;
  return (
    <>
      <div className="postboostchartcomponent__container show_desktop">
        <div className="row">
          <div className="col-md-4">
            <div className="parentWrap">
              <div className="graph_col full_width">
                <h4 className="text-center">
                {t("campaign_ROIOverMonth")} 
                </h4>
                <div>
                  {isLoading ? (
                    campaignException ? (
                      <div className="postboost_error">
                        <WarningOutlined />
                      </div>
                    ) : (
                      <div style={{ marginTop: "2rem" }}>
                        <div className="postboostchartcomponent_loader"></div>
                      </div>
                    )
                  ) : lineChartData.series.length !== 0 ? (
                    <HighCharts options={lineChartData} />
                  ) : (
                    <ErrorMessage message={t("campaign_failedToLoad")} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="parentWrap">
              <div className="graph_col full_width">
                <h4 className="text-center">{t("campaign_CPAComparison")}</h4>
                <div>
                  {isLoading ? (
                    campaignException ? (
                      <div className="postboost_error">
                        <WarningOutlined />
                      </div>
                    ) : (
                      <div style={{ marginTop: "2rem" }}>
                        <div className="postboostchartcomponent_loader"></div>
                      </div>
                    )
                  ) : cpaComparisionData.series.length !== 0 ? (
                    <HighCharts options={cpaComparisionData} />
                  ) : (
                    <ErrorMessage message={t("campaign_failedToLoadCPAComparison")} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="parentWrap">
              <div className="graph_col full_width">
                <h4 className="text-center">{t("campaign_saleVolumeComparison")}</h4>
                <div>
                  {isLoading ? (
                    campaignException ? (
                      <div className="postboost_error">
                        <WarningOutlined />
                      </div>
                    ) : (
                      <div style={{ marginTop: "2rem" }}>
                        <div className="postboostchartcomponent_loader"></div>
                      </div>
                    )
                  ) : salesVolumeData.series.length !== 0 ? (
                    <HighCharts options={salesVolumeData} />
                  ) : (
                    <ErrorMessage message={t("campaign_failedToLoadSaleVolumeComparison")} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="graph_row w-100 mob_view mb-5">
        <SliderContainer config={settings}>
          <div className="parentWrap">
            <div className="graph_col" style={{ width: "385px" }}>
              <h4 className="text-center">
              {t("campaign_historicalCampaignROI")}
              </h4>
              <div>
                {isLoading ? (
                  campaignException ? (
                    <div className="postboost_error">
                      <WarningOutlined />
                    </div>
                  ) : (
                    <div style={{ marginTop: "2rem" }}>
                      <div className="postboostchartcomponent_loader"></div>
                    </div>
                  )
                ) : cpaComparisionData.series.length !== 0 ? (
                  <HighCharts options={cpaComparisionData} />
                ) : (
                  <ErrorMessage message={t("campaign_failedToLoadCPAComparison")} />
                )}
              </div>
            </div>
          </div>
          <div className="parentWrap">
            <div className="graph_col" style={{ width: "385px" }}>
              <h4 className="text-center">{t("campaign_CPAComparison")}</h4>
              <div>
                {isLoading ? (
                  campaignException ? (
                    <div className="postboost_error">
                      <WarningOutlined />
                    </div>
                  ) : (
                    <div style={{ marginTop: "2rem" }}>
                      <div className="postboostchartcomponent_loader"></div>
                    </div>
                  )
                ) : cpaComparisionData.series.length !== 0 ? (
                  <HighCharts options={cpaComparisionData} />
                ) : (
                  <ErrorMessage message={t("campaign_failedToLoadCPAComparison")} />
                )}
              </div>
            </div>
          </div>
          <div className="parentWrap">
            <div className="graph_col" style={{ width: "385px" }}>
              <h4 className="text-center">{t("campaign_saleVolumeComparison")}</h4>
              <div>
                {isLoading ? (
                  campaignException ? (
                    <div className="postboost_error">
                      <WarningOutlined />
                    </div>
                  ) : (
                    <div style={{ marginTop: "2rem" }}>
                      <div className="postboostchartcomponent_loader"></div>
                    </div>
                  )
                ) : salesVolumeData.series.length !== 0 ? (
                  <HighCharts options={salesVolumeData} />
                ) : (
                  <ErrorMessage message={t("campaign_failedToLoadSaleVolumeComparison")} />
                )}
              </div>
            </div>
          </div>
        </SliderContainer>
      </div>
    </>
  );
};

export { PostBoostChartComponent };
