import axios from "axios";
import { toast } from "react-toastify";
import * as types from "./actionTypes";
import { BASE_URL } from "../../StaticArray/StaticArray";
import {
  onAddFeature,
  onDeleteFeature,
  onGetAllPackageFeatures,
  onGetFeatureById,
  onUpdateFeature,
} from "../../utils/network/lib/feature";

export const getAllPackageFeatures = () => {
  return (dispatch) =>
    onGetAllPackageFeatures()
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.GET_ALL_FEATURES_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const getByFeatureId = (featureId) => {
  return (dispatch) =>
    onGetFeatureById(featureId)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.GET_CURRENT_FEATURE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const addFeature = ({ name, path }) => {
  let addFeatureReqPayload = {
    name,
    path,
  };
  return (dispatch) =>
    onAddFeature(addFeatureReqPayload)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.ADD_NEW_FEATURE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const updateFeature = ({ id, name, path }) => {
  let updateFeatureReqPayload = {
    name,
    path,
  };
  return (dispatch) =>
    onUpdateFeature(id, updateFeatureReqPayload)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: types.UPDATE_FEATURE_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};

export const deleteFeature = (featureId) => {
  return (dispatch) =>
    onDeleteFeature(featureId)
      .then((res) => {
        if (res.status === 204) {
          return dispatch({
            type: types.DELETE_FEATURE_SUCCESS,
            payload: featureId,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
};
