import React, { PureComponent } from 'react'
import "./topUpModal.css";
import {inviteSubUser} from '../../Axios/ApiRequests';

import Modal from "react-responsive-modal";

export class SubUserModal extends PureComponent {

    constructor(props) {
        super(props);
        this.state={
            name: "",
            email: "",
            is_loading: false,
            formValid: false
        }
    }

    async handleInviteUser() {
      const {closeModal, showNot} = this.props
      const {name, email} = this.state;
      this.setState({is_loading: true});
      try {
        const resp = await inviteSubUser({
          'name':name,
          'email': email
      })
     
      closeModal();
      showNot(resp);
      } catch (error) {

        closeModal();
      }
      this.setState({is_loading: false});
      this.setState({name: '', email: ""});
        
    }
      
      validateForm() {
        this.setState({formValid: (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && this.state.name!=="") });
      }

      handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, 
                      () => { this.validateForm() });
      }

      

    render() {
       const {openModal, closeModal, userData} =this.props;
        return (
          <div className="outer-model">
              
            <Modal  open={openModal} onClose={() => closeModal()} showCloseIcon={false}>
            <div className='model-content'>
        
            <h2>Invite User</h2>
              <div align="center">
                <div>
                    <label className="camp-creation-label">
                    Name
                    <span className="star-required">*</span>
                    </label>
                    <input type="text" className="text-field w-input" name="name" placeholder="Full name" value={this.state.name} onChange={(e) => this.handleUserInput(e)} />
                
                    <label className="camp-creation-label">
                    Email
                    <span className="star-required">*</span>
                    </label>
                    <input type="email" className="text-field w-input" name="email" value={this.state.email} onChange={(e) => this.handleUserInput(e)} placeholder="Email" />
                </div>
                      <div className="mt-16">
                        <div className="lead-container"><button onClick={()=>this.handleInviteUser()} disabled={this.state.is_loading || !this.state.formValid}  className="button w-button">{!this.state.is_loading ? 'Invite' : 'Please Wait!'}</button>
                        </div>
                      </div>
                      
                    </div>
                    </div>
            </Modal>
        
          </div>
        )
    }
}

export default SubUserModal

