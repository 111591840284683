import React, { useState, useEffect, useRef, useMemo } from "react";
import Select from "react-select";
import VideoThumbnail from "react-video-thumbnail";
import Logo from "../logo/Logo";
import { BASE_URL } from "../../../StaticArray/StaticArray";
import Description from "../description/Description";
import PhotoComponent from "../photoComponent/PhotoComponent";
import SmallErrorText from "../../../common/smallErrorText/SmallErrorText";
import PopupAlert from "../../../common/popupAlert/PopupAlert";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";

import "./VideoCard.css";

const VideoCard = ({
  pageDetails,
  audiences,
  ctaList,
  card,
  videoCardIds,
  changeVideoCardArray,
  deleteVideoCard,
  dataFromVideoCard,
  i,
  platform,
  setNotification,
  notification,
}) => {
  // States
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);

  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);

  const [showCtaOptions, setShowCtaOptions] = useState(false);

  const [cardData, setCardData] = useState({});

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [isThumbnail, setIsThumbnail] = useState(true);

  const [isErrorPopUp, setErrorPopUp] = useState(false);

  const [previousAudience, setPreviousAudience] = useState([]);

  const [errorHeading, setErrorHeading] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  //Effects

  // set options for audience list
  useEffect(() => {
    getAudienceOptions();
  }, [audiences]);

  useEffect(() => {
    decideCardData();
    console.log("cardData", card);
  }, [card]);

  useEffect(() => {
    setThumbnailWidth();
  }, []);

  //Functions

  const setThumbnailWidth = () => {
    try {
      const a = document.getElementsByClassName("react-thumbnail-generator");
      if (a.length === 0) throw false;
      setTimeout(() => {
        Array.from(a).forEach((el) => {
          el.firstChild.style.cssText = "width: 100%; min-height: 300px";
        });
      }, 500);
    } catch (err) {
      setTimeout(() => {
        setThumbnailWidth();
      }, 1000);
    }
  };

  const decideCardData = () => {
    const object = {
      unique_id: card.unique_id || card.id,
      thumbnail_object: card.thumbnail_object || null,
      thumbnail_path: card.thumbnail_path || "",
      file_object: card.file_object || null,
      path: card.path || "",
      headline: card.headline || "",
      description: card.description || "",
      message: card.message || "",
      cta: card.cta || null,
      final_url: card.final_url || "",
      ids: card.ids || null,
      audiences: card.audiences || null,
      errors: card.errors || {},
      status: card.status || {},
      duration: card.duration ? Math.round(card.duration) : "",
    };
    setCardData(object);
  };

  const getAudienceOptions = () => {
    if (audiences && audiences.length > 0) {
      let array = [];
      audiences.map((audience) => {
        if (
          !(
            audience.media_channel[7] &&
            audience.media_channel[7].budget_split > 0
          )
        ) {
          const object = {};
          object.value = audience.id;
          object.label = audience.name;
          array = [...array, object];
        }
      });
      setOptions(array);
    }
  };

  const onChange = (name, value, e) => {
    if (e) {
      if (previousAudience.length <= e.length) {
        // let filterData = e.filter(
        //   (o) => !previousAudience.find((o2) => o.value === o2.value)
        // );
        let selectedValue = e[0].value;
        let selectedAudience = audiences.find((r) => r.id === selectedValue);

        if (
          selectedAudience &&
          selectedAudience.media_channel[5] &&
          selectedAudience.media_channel[5].budget_split > 0 &&
          cardData.duration > 120
        ) {
          setErrorHeading(t("campaign_instaVdoValidatn"));
          setErrorMessage(t("campaign_vdoDuratnError"));
          setErrorPopUp(true);
        } else {
          setPreviousAudience(e);
          const obj = { ...cardData, [name]: value };
          setCardData(obj);
        }
      } else {
        setPreviousAudience(e);
        const obj = { ...cardData, [name]: value };
        setCardData(obj);
      }
    } else {
      const obj = { ...cardData, [name]: value };
      setCardData(obj);
    }
  };

  const onEditClick = () => {
    const index = videoCardIds.indexOf(cardData.unique_id);
    document.getElementsByClassName("input__file--video")[index].click();
  };

  const convertFileToUrl = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const onFileChange = async (file) => {
    const obj = {
      ...cardData,
      path: "",
      thumbnail_path: "",
      file_object: file,
    };
    const index = videoCardIds.indexOf(cardData.unique_id);
    changeVideoCardArray(index, obj);
    setIsThumbnail(false);
    setTimeout(() => {
      setIsThumbnail(true);
    }, 200);
  };

  const onDeleteClick = () => {
    const index = videoCardIds.indexOf(cardData.unique_id);
    deleteVideoCard(index);
  };

  const changeMainDataOnBlur = (object) => {
    const index = object
      ? videoCardIds.indexOf(object.unique_id)
      : videoCardIds.indexOf(cardData.unique_id);

    changeVideoCardArray(index, object || cardData);
  };

  const onThumbnailEditClick = () => {
    const index = videoCardIds.indexOf(cardData.unique_id);
    document.getElementsByClassName("input__thumbnail")[index].click();
  };

  const onThumbnailChange = async (file) => {
    const obj = { ...cardData, thumbnail_path: "", thumbnail_object: file };
    const index = videoCardIds.indexOf(cardData.unique_id);
    changeVideoCardArray(index, obj);
    await convertFileToUrl(file).then((newUrl) => {
      if (newUrl.length > 0) {
        const thumbnailContainer = document
          .getElementsByClassName("react-thumbnail-generator")
          .item(index);
        thumbnailContainer.firstChild.setAttribute("src", newUrl);
      }
    });
  };

  const changeCtaValue = (cta) => {
    let updatedValue = cta.replace("_", " ");
    return updatedValue;
  };

  // Getting audience function
  const getAudiencesFromIds = () => {
    let audienceData = [...new Set(cardData.audiences)];
    if (audienceData && audienceData.length > 0) {
      let selectedAudiences = [];
      audienceData.map((id) => {
        for (let i = 0; i < options.length; i++) {
          if (options[i].value === id)
            return (selectedAudiences = [...selectedAudiences, options[i]]);
        }
      });
      return selectedAudiences;
    }
    return [];
  };

  const reader = new FileReader();

  return (
    <React.Fragment>
      {showDeletePopup && (
        <PopupAlert
          heading={t("campaign_areYouSure")}
          body={t("campaign_sureYouWantToDeletePost")}
          onNoClick={setShowDeletePopup}
          onYesClick={onDeleteClick}
        />
      )}

      {isErrorPopUp && (
        <PopupAlert
          heading={errorHeading}
          body={errorMessage}
          onYesClick={() => {
            setErrorHeading("");
            setErrorMessage("");
            setErrorPopUp(false);
          }}
          isError={true}
        />
      )}

      {/* input file for editing the image. visibility hidden */}
      <input
        type="file"
        className="input__file--video"
        accept="video/mp4, video/mkv"
        onChange={(e) => onFileChange(e.target.files[0])}
        style={{ display: "none" }}
      />

      {/* input file for editing thumbnail. visibility hidden */}
      <input
        type="file"
        className="input__thumbnail"
        accept="image/png, image/jpeg"
        onChange={(e) => onThumbnailChange(e.target.files[0])}
        style={{ display: "none" }}
      />
      
      <div className="videocard__container">
      <div className={`videocard__container__${cardData.unique_id}`}>
        </div>
        <div style={{ display: "flex" }}>
          <Logo
            url={
              pageDetails.page_image ||
              "https://randomuser.me/api/portraits/women/50.jpg"
            }
            style={{ marginTop: "2%", marginLeft: "2%" }}
          />

          <div className="videocard__heading--container">
            <span className="videocard__heading--bold">{pageDetails.name}</span>
            <span className="videocard__issponsored">
              {t("campaign_sponsored")}
            </span>
          </div>
        </div>

        {/*<span className="videocard__subheading">
          <input
            className="videocard__input--headline"
            value={cardData.headline}
            type="text"
            placeholder="Headline"
            onChange={(e) => onChange("headline", e.target.value)}
            onBlur={() => {
              changeMainDataOnBlur();
            }}
          />
          {cardData.errors && cardData.errors.headline && (
            <SmallErrorText
              style={{
                display: "block",
                marginTop: "10px",
                backgroundColor: "#faebd7",
                width: "fit-content",
              }}
              text={cardData.errors.headline}
            />
          )}
        </span>*/}
        <Description
          text={cardData.headline}
          isEditable={isDescriptionEditable}
          onEditClick={() => setIsDescriptionEditable(true)}
          onChange={(name, value) => onChange(name, value)}
          onBlur={() => {
            setIsDescriptionEditable(false);
            changeMainDataOnBlur();
          }}
          commonStyle={{
            marginLeft: "5%",
            marginBottom: "10px",
            fontSize: "1.4rem",
            borderBottom: cardData.headline === "" ? "2px solid red" : "none",
          }}
        />
        {cardData.errors && cardData.errors.headline && (
          <SmallErrorText
            style={{
              display: "inline-block",
              backgroundColor: "rgb(250, 235, 215)",
              fontWeight: 500,
              fontSize: "12px",
              color: "rgb(145, 45, 43)",
              marginBottom: "10px",
              marginLeft: "5%",
            }}
            text={cardData.errors.headline}
          />
        )}

        <div
          style={{ position: "relative" }}
          className="videocard__photo--container"
        >
          <img
            className="videocard__button--play"
            src="/images/play-button.png"
            alt=""
          />
          {cardData.file_object && isThumbnail ? (
            <VideoThumbnail
              key={cardData.unique_id + "thumb"}
              videoUrl={window.URL.createObjectURL(cardData.file_object)}
              thumbnailHandler={(preview) => {
                const thumbnail = dataURLtoFile(preview, "thumbnail");
                const obj = { ...cardData, thumbnail_object: thumbnail };
                const index = videoCardIds.indexOf(cardData.unique_id);
                dataFromVideoCard(index, obj);
                setCardData(obj);
              }}
            />
          ) : cardData.path ? (
            ""
          ) : (
            t("campaign_loading")
          )}
          {cardData.path && (
            <PhotoComponent
              url={`${BASE_URL}thumbnail/adimage?path=${cardData.thumbnail_path}`}
            />
          )}{" "}
          <div id="videocard-layover" className="videocard__layover" />
          <button
            onClick={() => onEditClick()}
            style={{ top: "27%" }}
            className="videocard__btn--edit"
          >
            {t("campaign_editCreative")}
          </button>
          <button
            onClick={() => setShowDeletePopup(true)}
            style={{ top: "46%" }}
            className="videocard__btn--edit"
          >
            {t("campaign_delPost")}
          </button>{" "}
          <button
            style={{ top: "67%" }}
            className="videocard__btn--edit"
            onClick={() => onThumbnailEditClick()}
          >
            {t("camaign_editThumbnail")}
          </button>{" "}
        </div>

        <div
          style={{
            backgroundColor: "#E9EBED",
            paddingBottom: "1px",
            position: "relative",
            width: "100%",
            paddingTop: "20px",
            // height: "7rem",
          }}
        >
          <div className="videocard__url videocard__url--image-card">
            <input
              disabled={platform === "MESSENGER"}
              value={cardData.final_url}
              type="text"
              className="videocard__input--headline"
              placeholder={t("campaign_enterURL")}
              onChange={(e) => onChange("final_url", e.target.value)}
              onBlur={() => {
                changeMainDataOnBlur();
              }}
              style={{
                backgroundColor: "#E9EBED",
                borderBottom:
                  cardData.final_url === ""
                    ? "2px solid red"
                    : "2px solid #8d02fa",
              }}
            />
          </div>

          {cardData.errors && cardData.errors.url && (
            <SmallErrorText
              style={{
                display: "block",
                marginTop: "10px",
                backgroundColor: "#faebd7",
                width: "fit-content",
                marginLeft: "5%",
                fontWeight: 500,
              }}
              text={cardData.errors.url}
            />
          )}

          {cardData.errors && cardData.errors.cta && (
            <SmallErrorText
              style={{
                display: "block",
                marginTop: "10px",
                backgroundColor: "rgb(250, 235, 215)",
                fontSize: "12px",
                color: "rgb(145, 45, 43)",
                width: "fit-content",
                position: "absolute",
                right: "0%",
                top: "40px",
                fontWeight: 500,
              }}
              text={cardData.errors.cta}
            />
          )}

          <span className="videocard__span--message imagecard__subheading--image-card">
            <input
              className="videocard__input--headline"
              value={cardData.message}
              type="text"
              onChange={(e) => onChange("message", e.target.value)}
              onBlur={() => {
                changeMainDataOnBlur();
              }}
              style={{
                backgroundColor: "#E9EBED",
                width: "90%",
                borderBottom:
                  cardData.message === ""
                    ? "2px solid red"
                    : "2px solid #8d02fa",
              }}
              placeholder={t("campaign_enterCaption")}
            />
            {cardData.errors && cardData.errors.message && (
              <SmallErrorText
                style={{
                  display: "block",
                  marginTop: "10px",
                  backgroundColor: "#faebd7",
                  width: "fit-content",
                  fontWeight: 500,
                }}
                text={cardData.errors.message}
              />
            )}
          </span>
          <span
            className="imagecard__span--message"
            style={{ top: "60px", left: 0 }}
          >
            <input
              className="imagecard__input--headline"
              value={cardData.description}
              type="text"
              onChange={(e) => onChange("description", e.target.value)}
              onBlur={() => {
                changeMainDataOnBlur();
              }}
              style={{
                backgroundColor: "#E9EBED",
                borderBottom:
                  cardData.description === ""
                    ? "2px solid red"
                    : "2px solid #8d02fa",
              }}
              placeholder={t("campaign_enterDescription")}
            />

            {cardData.errors && cardData.errors.description && (
              <SmallErrorText
                style={{
                  display: "block",
                  marginTop: "10px",
                  backgroundColor: "#faebd7",
                  width: "fit-content",
                  fontWeight: 500,
                }}
                text={cardData.errors.description}
              />
            )}
          </span>
          <div className="videocard__btn--cta--container">
            <button
              style={{
                border: !cardData.cta ? "2px solid red" : "1px solid black",
              }}
              className="videocard__btn--cta"
              onClick={() => {
                if (showCtaOptions) {
                  setShowCtaOptions(false);
                } else setShowCtaOptions(true);
              }}
            >
              {cardData.cta
                ? changeCtaValue(cardData.cta)
                : t("campaign_selectCTA")}
            </button>

            {showCtaOptions && (
              <div>
                <div className="imagecard__ctaoptions--container">
                  {ctaList &&
                    ctaList.map((ctaa) => (
                      <span
                        onClick={() => {
                          let cta = ctaa.value;
                          const object = { ...cardData, cta };
                          setCardData(object);
                          setShowCtaOptions(false);
                          changeMainDataOnBlur(object);
                        }}
                      >
                        {changeCtaValue(ctaa.label)}
                      </span>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "94%",
            marginLeft: "3%",
            marginTop: "10px",
            fontSize: "13px",
            color: "rgb(101, 107, 115)",
            marginTop: "12px",
            fontWeight: "bold",
            fontSize: "10px",
            marginBottom: "5px",
          }}
        >
          <span>
            <img
              style={{ marginTop: "-5px" }}
              src={`${BASE_URL}images/thumb-up.png`}
              alt=""
            />
            {"   "}
            {cardData.likes || 0}
          </span>
          <span
            style={{
              marginLeft: "50%",
            }}
          >
            {cardData.comments || 0}
            {"   "} {t("campaign_comments")}
          </span>
          <span
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "100%",
              backgroundColor: "#000",
              display: "inline-block",
              marginTop: "5px",
            }}
          />

          <span>
            {cardData.shares || 0}
            {"   "} {t("campaign_shares")}
          </span>
        </div>

        <div className="videocard__likes--container">
          <span>
            <img
              src={`${BASE_URL}images/like.png`}
              className="videocard__img--like"
              alt=""
            />
            {t("campaign_like")}
          </span>
          <span>
            <img
              src={`${BASE_URL}images/comment.png`}
              className="videocard__img--like"
              alt=""
            />
            {t("campaign_comment")}
          </span>
          <span>
            <img
              src={`${BASE_URL}images/share.png`}
              className="videocard__img--like"
              alt=""
            />
            {t("campaign_share")}
          </span>
        </div>
        <div
          style={{
            paddingTop: "13px",
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <span
            style={{ marginTop: "18px", marginLeft: "5%", fontWeight: "bold" }}
          >
            {t("campaign_targeting")}:{" "}
          </span>

          <Select
            options={options}
            // value={
            //   dataToSubmit[cardData.id]
            //     ? showSelectedAudiences(dataToSubmit[cardData.id].audiences) || null
            //     : null
            // }
            value={getAudiencesFromIds()}
            noOptionsMessage={() => <span>{t("campaign_noAudience")}</span>}
            isMulti
            className="videocard__audience--selector"
            placeholder={t("campaign_audience")}
            onChange={(e) => {
              setTimeout(() => {
                let ids = [];
                if (e) e.map((audience) => (ids = [...ids, audience.value]));
                onChange("audiences", ids, e);
              }, 10);
            }}
            onBlur={() => changeMainDataOnBlur()}
          />

          {cardData.errors && cardData.errors.audiences && (
            <SmallErrorText
              style={{
                fontSize: "12px",
                color: "rgb(145, 45, 43)",
                marginLeft: "5%",
                marginTop: "7px",
                display: "block",
                width: "fit-content",
                fontWeight: 500,
                backgroundColor: "#faebd7",
              }}
              text={cardData.errors.audiences}
            />
          )}
        </div>
        </div>
      {notification  && cardData.status == 'ERROR' && ( notification.map((notify) => {
        
        if(notify.data.details.object_type == "AdVideo" && cardData && cardData.ids.includes(notify.data.details.object_id)) {
          
          return (
          <Joyride
            steps={[
              {
                target: `.videocard__container__${cardData.unique_id}`,
                content: `${notify.data.details.message.transalation_key}`,
              },
            ]}
            hideCloseButton={true}
            styles={{
              options: {
                arrowColor: '#8D02FA',
                primaryColor: '#8D02FA',
                beaconSize: 100,
              }}}
          />)
        }

      }))}
    
    
    </React.Fragment>
  );
};

export default VideoCard;
