import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";


const base = BASE_URL + VERSION_PREFIX
const prefix = base + "/audience"
const shortBase = VERSION_PREFIX

const shortBaseAudience = VERSION_PREFIX + "/audience"

export function getAudienceLanguage(payload) {
    return http.get(prefix + '/language', payload)
}
export function getAudienceLocation(payload) {
    return http.get(prefix + '/location', payload)
}

export function onGetAudienceData(id) {
    return http.get(prefix + '/' + id)
}
export function getListAudience(id) {
    return http.get(prefix + '/listAudience')
}
export function getListInterest(id) {
    return http.get(prefix + '/listInterest')
}

export function onUpdateAudience(id, audienceData) {
    return http.put(prefix + `/${id}`,
        audienceData
    )
}
export function onAddAudience(audienceData) {
    return http.post(prefix,
        audienceData
    )
}
export function onAudienceSearchInterest(payload) {
    return http.get(prefix + '/searchInterest',
        payload
    )
}

export function onAudienceInterestSuggestion(payload) {
    return http.get(prefix + '/interestSuggestion',
        payload
    )
}

export function onAudienceRecommendation(campaign_id) {
    return http.get(prefix + '/recommendation' + campaign_id
    )
}
export function onAudienceOverlap(payload) {
    return PeasyApi.post(shortBaseAudience + "/overlap", payload)
}


export function onGetCustomAudience(id) {
    return PeasyApi.get(shortBase + "/customaudiences/" + id)
}

export function onPostCustomAudience(payload) {
    return PeasyApi.post(shortBase + "/customaudiences/create", payload)
}

export function onCustomAudienceEstimatedLeads(payload) {
    return PeasyApi.post(shortBase + "/customaudiences/estimatedleads", payload)
}


export function onDeleteCustomAudience(id) {
    return PeasyApi.del(shortBase + "/customaudiences/delete/" + id)
}



export function onGetAudiences(payload) {
    return http.post(base + "/getAudiences", payload)
}