import React, { useState } from "react";
import { useTable, useBlockLayout } from "react-table";
import { FixedSizeList } from "react-window";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SelectPostTable.css";
import "../../../../translations/i18n";
import { useTranslation } from "react-i18next";

function Table({
  columns,
  data,
  decideSelectedPost,
  selectedPost,
  dataToSubmit,
}) {
  // Use the state and functions returned from useTable to build your UI

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style:
              selectedPost && row.original.id === selectedPost.id
                ? { ...style, backgroundColor: "#e3cef4", cursor: "pointer" }
                : { ...style, cursor: "pointer" },
          })}
          onClick={() => decideSelectedPost(row.original)}
          className="selectposttable__tr"
        >
          {row.cells.map((cell) => {
            return (
              <div {...cell.getCellProps()} className="selectposttable__td">
                {cell.render("Cell")}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedPost]
  );

  // Render the UI for your table
  return (
    <div
      {...getTableProps()}
      className="selectposttable__table"
      style={{ overflowX: "auto" }}
    >
      <div>
        {headerGroups.map((headerGroup) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className="selectposttable__tr"
          >
            {headerGroup.headers.map((column) => (
              <div {...column.getHeaderProps()} className="selectposttable__th">
                {column.render("Header")}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList
          // height={200}
          height={window.innerHeight * 0.3}
          itemCount={rows.length}
          itemSize={60}
          width={totalColumnsWidth * 1.02}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>
  );
}

const SelectPostTable = ({
  postsToShow,
  setIsPopupVisible,
  cardsToShow,
  setCardsToShow,
  editCardPosition,
  setEditCardPosition,
  setDataToSubmit,
  removeCardAtEdit,
  showCreateCardSteps,
  setCardsStepPost,
  dataToSubmit,
}) => {
  const { t } = useTranslation();

  const [selectedPost, setSelectedPost] = useState({});
  const [tableScrollTop, setTableScrollTop] = useState(0);

  const columns = React.useMemo(() => {
    if (postsToShow[0].platform === 1)
      return [
        {
          Header: t("campaign_FbPost"),
          Cell: (row) => {
            return (
              <div
                className="selectposttable__post--container"
                style={{ display: "flex" }}
              >
                <img src={row.row.original.img} />
                <span className="selectposttable__post--span">
                  {row.row.original.text || t("campaign_PostHasNoMsg")}
                </span>
              </div>
            );
          },
          width: Math.round(250),
        },
        {
          Header:  t("campaign_postID"),
          Cell: (row) => {
            return <span>{row.row.original.id.split("_").pop()}</span>;
          },
          width: Math.round(200),
        },
        {
          Header:  t("campaign_dataCreated"),
          accessor:  t("campaign_createdTime"),
          width: Math.round(150),
          getCellProps: () => {
            return {
              style: {
                paddingRight: "",
              },
            };
          },
        },
        {
          Header:  t("campaign_likes"),
          accessor: "likes",
          width: Math.round(100),
        },
        {
          Header:  t("campaign_comments"),
          accessor: "comments",
          width: Math.round(100),
        },
        {
          Header:  t("campaign_mediaType"),
          accessor: "type",
          width: Math.round(100),
        },
      ];

    if (postsToShow[0].platform === 4) return <div> {t("campaign_uploadImage")} </div>;

    if (postsToShow[0].platform === 2)
      return [
        {
          Header:  t("campaign_instaPost"),
          Cell: (row) => {
            return (
              <div style={{ display: "flex" }}>
                <img
                  style={{ width: "25px", height: "25px" }}
                  src={row.row.original.img}
                />
                <span
                  style={{
                    fontSize: "8px",
                    fontWeight: "bold",
                    marginLeft: "5px",
                  }}
                >
                  {row.row.original.text || t("campaign_postHasNoCaptn")}
                </span>
              </div>
            );
          },
          width: Math.round(250),
        },
        {
          Header:  t("campaign_postID"),
          Cell: (row) => {
            return <span>{row.row.original.id.split("_").pop()}</span>;
          },
          width: Math.round(window.innerWidth * 0.15),
        },
        {
          Header:  t("campaign_dataCreated"),
          accessor: "createdTime",
          width: Math.round(200),
          getCellProps: () => {
            return {
              style: {
                paddingRight: "",
              },
            };
          },
        },
        {
          Header:  t("campaign_likes"),
          accessor: "likes",
          width: Math.round(150),
        },
        {
          Header:  t("campaign_comments"),
          accessor: "comments",
          width: Math.round(100),
        },
        {
          Header:  t("campaign_mediaType"),
          accessor: "type",
          width: Math.round(100),
        },
      ];
  }, [postsToShow]);

  const decideSelectedPost = (post) => {
    setSelectedPost((initialPost) => {
      if (initialPost && initialPost.id === post.id) return {};
      else return post;
    });
  };

  const onContinueClick = () => {
    const shownCards = [...cardsToShow];
    const isCardAlreadyShown = shownCards.filter(
      (card) => card.id === selectedPost.id
    );
    if (!!isCardAlreadyShown.length)
      return toast.error(t("campaign_selAnotherPst")
        ,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
    // else {
    //   setIsPopupVisible(false);
    //   showCreateCardSteps(selectedPost);
    // }
    setIsPopupVisible(false);
    if (editCardPosition) {
      const cards = [...cardsToShow];
      cards.splice(editCardPosition, 1, selectedPost);
      setCardsToShow(cards);
      setDataToSubmit((data) => {
        const object = { ...data };
        delete object[removeCardAtEdit];
        return object;
      });
      setEditCardPosition(null);
    } else if (editCardPosition === 0) {
      const cards = [...cardsToShow];
      cards.splice(editCardPosition, 1, selectedPost);
      setCardsToShow(cards);
      setDataToSubmit((data) => {
        const object = { ...data };
        delete object[removeCardAtEdit];
        return object;
      });
      setEditCardPosition(null);
    } else {
      // showCreateCardSteps(selectedPost);
      setCardsToShow((cards) => [...cards, selectedPost]);
    }
  };

  const checkAlreadyShown = () => {
    const resultPosts = postsToShow.filter((post) => !dataToSubmit[post.id]);
    return resultPosts;
  };

  return (
    <div className="selectposttable__container">
      <Table
        columns={columns}
        data={checkAlreadyShown()}
        decideSelectedPost={decideSelectedPost}
        selectedPost={selectedPost}
        dataToSubmit={dataToSubmit}
        className="-striped -highlight"
      />
      <div className="selectpostpopup__container--btn">
        {/* <button
          className="selectpostpopup__btn--grey"
          onClick={() => {
            setCardsStepPost(null);
            setIsPopupVisible(false);
          }}
        >
          Cancel
        </button> */}
        {/* <button
        className="btn btn-primary"
          // className="selectpostpopup__btn--blue"
          disabled={Object.keys(selectedPost).length === 0}
          onClick={() => onContinueClick()}
        >
          Continue
        </button> */}
        <button
          className="btn btn-primary selectposttable__btn continue"
          style={{
            // width: "24%",
            height: "32px",
            borderRadius: "35px",
            paddingTop: "4px",
            fontWeight: "bold",
            float: "right",
            marginLeft: "3%",
          }}
          disabled={Object.keys(selectedPost).length === 0}
          onClick={() => onContinueClick()}
        >
           {t("campaign_continue")}
        </button>

        <button
          className="btn btn-outline-primary selectposttable__btn cancel"
          style={{
            // width: "17%",
            height: "32px",
            borderRadius: "35px",
            paddingTop: "4px",
            fontWeight: "bold",
            float: "right",
          }}
          onClick={() => {
            setCardsStepPost(null);
            setIsPopupVisible(false);
          }}
        >
           {t("campaign_back")}
        </button>
      </div>
    </div>
  );
};

export default SelectPostTable;
