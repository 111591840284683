import React, { Component } from "react";
import StepWrapper from "./StepWrapper";

class Content extends Component {
  constructor(props) {
    super(props);
    this.showhidden = this.showhidden.bind(this);
  }

  showhidden() {
    // alert(this.actionInput);
    // alert(localStorage.getItem('token_auth'));
  }

  render() {
    return (
      <div className="contentWrapper">
        <div className="container-fluid p-0 ">
          <div className="container brand-sec-prt">
            <div className="row">
              <div className="col-12 mt-5" onClick={this.showhidden}>
                <div id="regForm" className="d-block">
                  <StepWrapper />
                </div>
                <input
                  type="hidden"
                  value={(this.actionInput = "sldfkgsfjgsd;lfogsdofjgsodjf")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
