import { NotificationManager } from "react-notifications";
const errors = ["401", "400", "500"];
export const notify = (resp, message) => {
  if ("status" in resp) {
    if (resp.status === 200) {
      NotificationManager.success(message, "Successful!", 3000);
    } else if (resp.status === 204) {
      NotificationManager.success(message, "Successful!", 3000);
    } else if (resp.status === 422) {
      for (var err in resp.data.errors) {
        NotificationManager.error(resp.data.errors[err][0], "Error!", 3000);
      }
    }else if(resp.status === 401){
      NotificationManager.error("Session expired! Please login again!", "Error!", 3000);
    } else if (errors.includes(resp.status)) {
      NotificationManager.error(resp.data.message, "Error!", 3000);
    } else {
      NotificationManager.error(message, "Error!", 3000);
    }
  } else {
    NotificationManager.error("No Data Found", "Error!", 3000);
  }
};
