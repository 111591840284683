import React from "react";
import "./Shimmer.css";

const Shimmer = ({ style }) => (
  <div className="wrapper-shimmer" style={style}>
    <div className="shimmer__container animate" style={style} />
  </div>
);

export default Shimmer;
