import React from "react";
import ImageContainer from "../../../../common/Image";

const RetriveSuggestions = () => (
  <div class="frame_btm_wrap">
    <div class="frame_box_btm">
      <div class="frame_box_left">
        <ImageContainer
          width="56px"
          height="56px"
          src="insight.png"
          alt="insight"
        />
      </div>
      <div class="frame_box_rgt">
        <h3>Insights</h3>
        <h4> Peasy is very confident with this segments behaviour</h4>
      </div>
    </div>
    <div class="frame_box_btm">
      <div class="frame_box_left">
        <ImageContainer
          width="56px"
          height="56px"
          src="recommend.png"
          alt="recommend"
        />
      </div>
      <div class="frame_box_rgt">
        <h3>Recommendations</h3>
        <h4> Just sitback and relax!</h4>
      </div>
    </div>
  </div>
);

export default RetriveSuggestions;
