import { VERSION_PREFIX } from "../Constants";
import http from "../../../shared/http-request"
import { BASE_URL } from "../../../StaticArray/StaticArray";
import PeasyApi from "../../peasy.api";


const base = BASE_URL + VERSION_PREFIX
const shortBase = VERSION_PREFIX

export function onGetFunnelData(payload) {
    return PeasyApi.post(shortBase + '/getFunnelData', payload)
}


export function onUpdateFunnelData(payload) {
    return PeasyApi.post(shortBase + '/updateFunnelData', payload)
}

